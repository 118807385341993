import { mutate, gql } from "../../client";
import { InvestorMarginsDoc } from 'market-dto';

interface MutateInput {
    readonly data: {
        readonly investorMarginsDoc:InvestorMarginsDoc;
    }
}

interface MutateOutput {
    readonly upsert_investorMargins:{
        readonly returning: [{
            readonly investorMarginsDoc:InvestorMarginsDoc;
        }];
    }
}

const UPSERT = gql`
    mutation ($data: investorMargins_set_input) {
        upsert_investorMargins(set: $data) {
            errorMessage
            affectedRow
            returning {
                investorMarginsDoc
            }
        }
    }
`;

export const upsertInvestorMargins = async (doc:InvestorMarginsDoc):Promise<InvestorMarginsDoc | undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPSERT, {
        data: {
            investorMarginsDoc: doc
        }        
    })
    // const client = getApolloClient(false);
	// const result = await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: UPSERT,
	// 	variables: {
	// 		data: {
    //             investorMarginsDoc: doc
    //         }
	// 	}
    // });
    return result?.data?.upsert_investorMargins.returning[0].investorMarginsDoc;
}