import React from 'react';
import { Box } from '../box/box';
import { PopMenuRenderItem } from './pop-menu';

interface Props {
    readonly item:PopMenuRenderItem;
    readonly onClick:(event:any) => any;
}
export const PopMenuItem = ({ item, onClick }:Props) => {
    return (
        <>
            <Box
                onClick={onClick}
                py="1"
                px="2"
                borderSides={ item.dividerBelow ? 'bottom' : undefined }
                borderColor="menu"
                borderWidth="thin"
                fg="faded"
                fgHover="link"
                cursor="pointer"
                transition="default"
            >{ item.render }</Box>
        </>
    )
}
