import React, { useState, useEffect } from 'react';
import { Icon, Box, Flex, FlexVCenter, Button, Dropdown, DropdownItem, modal, Text } from 'mui';
import { tapeImporter, utils } from 'services';
import { getConfidenceLabel } from './get-confidence-label';

interface Props {
    readonly showAggCol:boolean;
    readonly matchResult:tapeImporter.NonAggMatchResult;
    readonly onSelect:(x:tapeImporter.NonAggMatchResult) => void;
}
export const MatchTableNonAggRow = ({
    showAggCol,
    matchResult,
    onSelect
}:Props) => {

    const { hashConfidence, sellerOrg, template } = matchResult;
    const aliases = sellerOrg?.aliases ?? [];

    return (
        <>
            <td style={{ backgroundColor: '#000', borderTop:'1px solid #000' }}>
                <Button
                    size="xs"
                    type="default"
                    onClick={() => onSelect(matchResult)}
                >Select</Button>
            </td>
            { showAggCol && <td><Text fg="faded">None</Text></td> }            
            <td>
                { sellerOrg && <Text>{ sellerOrg.name }</Text> }
                { !sellerOrg && (
                    <FlexVCenter>
                        <Icon name="alert-triangle" fg="accent" mr="half" />
                        <Box fg="faded">Unknown</Box>
                    </FlexVCenter>
                ) }
            </td>
            <td>{ getConfidenceLabel(hashConfidence) }</td>
            <td style={{ maxWidth: '300px'}}>{ aliases.join(', ') }</td>

        </>
    )
}