import { GQL, util } from 'market-dto';
import { fetchLoanSources } from 'api';

export const ensureLoanSources = async (sheet:GQL.LoanModelSheet):Promise<GQL.LoanModelSheet> => {

    const { loans } = sheet;
    // Check if we already have loan sources
    if (!loans || loans.length === 0 || loans[0].loanSource) return sheet;

    const loanSources = await fetchLoanSources(sheet.id);
    if (loanSources.length !== sheet.loans.length) {
        throw new Error('Loan sources failed to load' + loanSources.length + ' != ' + sheet.loans.length);
    }
    const byLoanId = util.toDict(loanSources, x => x.loanId);

    return {
        ...sheet,
        loans: sheet.loans.map(loan => {
            const src = byLoanId[loan.id];
            if (!src) throw new Error('could not find loan source for loan ' + loan.id);
            return {
                ...loan,
                loanSource: src
            }
        })
    }
}