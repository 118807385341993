import React, { useState, useEffect } from 'react';
import { Box, FlexVCenter, Text, Dropdown, DropdownItem } from 'mui';
import { mapping } from 'services';

interface Props {
    readonly item:mapping.RawAndTranslated;
    readonly canEdit:boolean;
    readonly canBeBlank:boolean;
    readonly enums:readonly string[];
    readonly getLabel?:(x:any) => string;
    readonly onChange:(v:string) => void;
}

export const TransEnumItem = ({ item, enums, canBeBlank, canEdit, getLabel, onChange }:Props) => {

    const val = item.translation.translated ? item.translation.val : '';

    const options:DropdownItem[] = enums.map(x => {
        return {
            label: getLabel ? getLabel(x) : x,
            value: x
        }
    })

    const valChange = (v:string | null) => {
        // just because they selected something, doesn't mean we override.
        // we only override if the global config would give us a different answer!
        // setVal(v);
        // alert(v);
        onChange(v ? v : '');
    }

    return (
        <FlexVCenter my="1">
            <Box flex="2" textAlign="right" mr="1"><Text ellipsis>{ item.raw }</Text></Box>
            <Box flex="3" mr="1">
                <Dropdown
                    disabled={!canEdit}
                    items={options}
                    value={options.find(x => x.value === val)}
                    setValue={valChange}
                    isClearable={canBeBlank}
                />
            </Box>
        </FlexVCenter>
    )
}
