import { mutate, stripInternals, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const INSERT = gql`
    mutation insertModel($objects: [model_insert_input!]) {
        insert_model(objects: $objects) {
            errorMessage
            affectedRow
            returning {
                ${ RETURNING }
            }
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.Model>]
}

interface MutateOutput {
    readonly insert_model: {
        readonly returning:[GQL.Model];
    }
}

export const insertModel = async (model:Partial<GQL.Model>):Promise<GQL.Model|undefined> => {
	const result =  await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [stripInternals(model)]
    })
    return result?.data?.insert_model.returning[0];
}
