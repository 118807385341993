import React from 'react';
import { Box, Flex, Icon, MXSpacingSize } from 'mui';
import { ExpEvalStatus } from 'exp';

interface Props {
    readonly evalStatus:ExpEvalStatus;
    readonly mx?:MXSpacingSize;
    readonly small?:boolean;
}
export const EditorPassFailCols = ({ evalStatus, mx, small }:Props) => {
    const { validSyntax, isBlank, canEval, evalResult } = evalStatus;
    return (
        <>
            <Flex justifyContent="center" mx={mx}>
                { !validSyntax && <Icon fg="danger" name="close" /> }
                { validSyntax && <Icon fg={isBlank ? 'faded' : 'success'} name="check" /> }
            </Flex>
            <Flex justifyContent="center" mx={mx} mono>
                { !canEval && <Icon fg="faded" name="minus" /> }
                { canEval && evalResult && <Box fg="success">Pass</Box> }
                { canEval && !evalResult && <Box fg="danger">Fail</Box> }
            </Flex>
        </>
    )
}

