import { useState } from 'react';
import { storage } from 'services';

export const useStickyStateWithDefault = <T>(key:string, defaultVal:T):[T, (x:T) => void] => {

    const [ val, setVal ] = useState<T>(() => {
        return storage.get(key) ?? defaultVal;
    })

    return [
        val,
        (x:T) => {
            storage.set(key, x);
            setVal(x)
        }
    ]
}