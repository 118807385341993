import React, { useState, useEffect } from 'react';
import { Box, Input } from 'mui';

interface Props {
    readonly taken:boolean;
    readonly id:string;
    readonly onChange:(x:string) => void;
    readonly onEnter:() => void;
    readonly focus:boolean;
}
export const ChecklistItemIdRow = ({ id, onChange, onEnter, taken, focus }:Props) => {

    const [ inputElem, setInputElem ] = useState<HTMLInputElement|null>(null);

    useEffect(() => {
        if (!inputElem || !focus) return;
        inputElem.focus();
    }, [inputElem, focus])

    const submit = () => {
        if (!inputElem) return;
        inputElem.blur();
        onEnter();
    }

    return (
        <>
            <Box fg="faded" fontSize="sm" textAlign="right">Id</Box>
            <Box>
                <Input
                    width="100%"
                    size="sm"
                    value={id}
                    setValue={onChange}
                    ref={setInputElem}
                    onEnter={submit}
                />
            </Box>
            <Box style={{ gridColumn: 'span 2' }} fg="danger" textAlign="center">
                { taken ? 'ID TAKEN' : '' }
            </Box>
        </>
    )
}