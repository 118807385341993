import { useEffect, useMemo } from "react"

const unsubscribeAll = (subs:any[]) => {
	return () => {
		subs.forEach((s:any) => {
			if (s && typeof s.unsubscribe === "function") {
				s.unsubscribe()
			}
		})
		subs = []
	}
}

export const useSubs = ():any[] => {
	const subs:any[] = useMemo(() => [], []);
	useEffect(() => {
		return () => unsubscribeAll(subs)()
	}, [subs]);
	return subs;
};
