import { query, gql, GQL } from "../../client";
import { LOAN_SOURCE_RETURNING } from './loan-source-returning';

const QUERY = gql`
    query ($where: loanSource_bool_exp!) {
        loanSource(where: $where) {
            ${ LOAN_SOURCE_RETURNING }
        }
    }
`;

interface QueryResult {
    readonly loanSource:Pick<GQL.LoanSource, "loanId"|"row"|"sourceMap">[];
}

interface QueryInput {
    readonly where:GQL.WhereString;
}

export const fetchLoanSources = async (sheetId:string):Promise<GQL.LoanSource[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            sheetId: {
                _eq: sheetId
            }
        }
    })
    return result.data.loanSource.map((x):GQL.LoanSource => {
        return {
            ...x,
            sheetId,
            partialLoan: {} // not needed
        }
    })
}

