import React, { useState } from 'react';
import { FlexVCenter, IntoPortal } from 'mui';

interface MenuTabProps {
    readonly label:string;
    readonly active:boolean;
    readonly onClick:() => void;
}
const MenuTab = ({ label, active, onClick }:MenuTabProps) => {
    return (
        <div
            onClick={onClick}
            className={active ? 'm-xls-tab-menu-item m-xls-active' : 'm-xls-tab-menu-item'}
        >{ label }</div>
    )
}

interface Props {
    readonly items:Array<{ readonly label:string, readonly val:string }>;
    readonly setTab:(x:string) => void;
    readonly tab:string;
}
export const PopOverMenuTabs = ({ items, setTab, tab }:Props) => {
    return (
        <IntoPortal id="pop-over-menu-tabs">
            <div className="m-xls-pop-tab-strip">
                <FlexVCenter css="m-xls-tab-menu">
                    { items.map((item, n) => (
                        <React.Fragment key={item.val}>
                            <MenuTab
                                onClick={() => setTab(item.val)}
                                label={item.label}
                                active={tab === item.val}
                            />
                            { n < items.length-1 && <span>&nbsp;|&nbsp;</span> }
                        </React.Fragment>
                    )) }
                </FlexVCenter>
            </div>
        </IntoPortal>
    )
}