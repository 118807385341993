import { BehaviorSubject, Observable } from 'rxjs';

/*

    Two varieties, ZState and ZStateList
        1. Simple obj you keep in memory, fetch/update
        2. List you keep in mempry, fetch entire list/update/insert specific items

*/

const clone = <T>(x:T):T => x === undefined ? undefined : JSON.parse(JSON.stringify(x));
const isDirty = <T>(a:T, b:T):boolean => JSON.stringify(a) !== JSON.stringify(b);

// maintain a list of all zstates created. This is for reseting them on logout.
const allZStates:Array<ZStateList<any>|ZState<any>> = [];

export const resetZStates = () => allZStates.forEach(z => z.resetToInitState());

export interface ZStateConfig<T> {
    readonly zid:string;
    readonly apiFetch?: (...args:any) => Promise<T | undefined>;
    readonly apiUpdate?: (x:Partial<T>) => Promise<any>;
    readonly initVal?:T;
    readonly onFetch?:(prev:T|undefined, curr:T|undefined) => void;
    readonly doNotResetOnLogout?:boolean;
}

export interface ZStateProperties<T> {
    readonly dirty:boolean;
    readonly fetchedObj?:T;
    readonly editObj?:T;
    readonly data?:T;
    readonly lastFetchArgs?:any[];
    readonly fetching:boolean;
    readonly updating:boolean;
    readonly error:boolean;
}

export interface ZStateListConfig<T> {
    // Specify either apiFetchList OR apiFetchDehydratedList
    // (if both specified, apiFetchList is ignored)
    readonly apiHydrateItem?:(item:T) => Promise<T>; // required when loading dehydrated lists
    readonly apiFetchDehydratedList?:(...args:any) => Promise<T[]|undefined>;
    readonly apiFetchList?:(...args:any) => Promise<T[]|undefined>;
    readonly apiUpdateListItem?:(x:Partial<T>) => Promise<any>;
    readonly apiInsertListItem?:(x:Partial<T>) => Promise<T|undefined>;
    readonly apiRemoveListItem?:(x:Partial<T>) => Promise<any>;
    readonly isEqual?:(a:Partial<T>, b:Partial<T>) => boolean; // required when loading dehydrated lists
    readonly createNewListItem?:() => T;
    readonly forceCommitsWhenNoChange?:boolean;
    readonly doNotResetOnLogout?:boolean;
}

export interface ZStateListProperties<T> {
    // what about activeItemPristine?
    readonly activeItemDirty:boolean;
    readonly activeItemIndex:number;
    readonly activeItemIsNew:boolean;
    readonly activeItemEditObj?:T;

    readonly list?:T[]; // this is never dirty.
    // readonly fetchList?:T[];
    readonly lastFetchArgs?:any[];
    readonly hydrating:boolean; // fetching the activeItem
    readonly fetching:boolean;
    readonly deleting:boolean;
    readonly updating:boolean;
    readonly error:boolean;
}


export const createZState = <T>(cfg:ZStateConfig<T>) => new ZState(cfg);
export const createZStateList = <T>(cfg:ZStateListConfig<T>) => new ZStateList(cfg);


// ---------------------------------------------------------------------------

export class ZStateList<T> {

    private _state:BehaviorSubject<ZStateListProperties<T>>;
    public state$:Observable<ZStateListProperties<T>>;

    private cfg:ZStateListConfig<T>;

    constructor(cfg:ZStateListConfig<T>) {
        this.cfg = cfg;
        this._state = new BehaviorSubject<ZStateListProperties<T>>(this.getInitProperties());
        this.state$ = this._state.asObservable();
        allZStates.push(this);
    }

    private getInitProperties():ZStateListProperties<T> {
        // zState lists never use init val...so, don't need cfg.
        return {
            fetching: false,
            updating: false,
            deleting: false,
            error: false,
            activeItemDirty: false,
            activeItemIndex: -1,
            activeItemIsNew: false,
            hydrating: false
        }
    }

    public resetToInitState() {
        if (this.cfg.doNotResetOnLogout) return;
        this._state.next(this.getInitProperties());
    }

    private _set(fn: (b:ZStateListProperties<T>) => ZStateListProperties<T>):ZStateListProperties<T> {
        const nextVal = fn(this._state.getValue());
        this._state.next(nextVal);
        return nextVal;
    }

    public get() {
        return this._state.getValue().list;
    }

    public getState() {
        return this._state.getValue();
    }

    public isFetching() {
        return this._state.getValue().fetching;
    }

    public isUpdating() {
        return this._state.getValue().updating;
    }

    public isDeleting() {
        return this._state.getValue().deleting;
    }

    public isBusy() {
        const val = this._state.getValue();
        return val.fetching || val.updating;
    }

    public isActiveItemDirty() {
        const val = this._state.getValue();
        return val.activeItemIndex !== -1 && val.activeItemDirty;
    }

    private lastFetchListArgs() {
        return this._state.getValue().lastFetchArgs ?? [];
    }

    private setListItem(index:number, item:T):T[] {
        const list = this.get();
        if (!list) throw new Error('Expected list to exist');
        return list.map((x, n) => n === index ? item : x);
    }

    // half-way there.
    private async callApiRemoveListItem(item:Partial<T>) {

        const fn = this.cfg.apiRemoveListItem;
        if (!fn) throw new Error('Expected apiRemoveListItem to exist');
        if (!item) throw new Error('Expected item to exist before posting remove');  
        const { isEqual } = this.cfg;
        if (!isEqual) throw new Error('Cannot remove list item without isEqual being defined');

        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                error: false,
                deleting: true
            }
        })
        const removeResult = await fn(item);
        if (!removeResult) {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    deleting: false,
                    error: true
                }
            })    
        } else {
            this._set((x):ZStateListProperties<T> => {
                const { list } = x;
                if (!list) throw new Error('Expected list');
                const nextList = list.filter(x => !isEqual(x, item));
                return {
                    ...x,
                    deleting: false,
                    list: nextList,
                    activeItemDirty: false,
                    activeItemIsNew: false,
                    activeItemIndex: -1,
                    activeItemEditObj: undefined
                }
            })
            return true;
        }
    }

    private async callApiInsertListItem(item:Partial<T>) {

        const fn = this.cfg.apiInsertListItem;

        if (!fn) throw new Error('Expected apiInsertListItem to exist');
        if (!item) throw new Error('Expected item to exist before posting udpate');

        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                error: false,
                updating: true
            }
        })
        const newItem = await fn(item);
        // TODO: ERROR CHECKING!
        if (!newItem) {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    updating: false,
                    error: true
                }
            })    
        } else {
            this._set((x):ZStateListProperties<T> => {
                const nextList = x.list ? x.list.concat(newItem) : [newItem];
                return {
                    ...x,
                    updating: false,
                    list: nextList,
                    ...x.activeItemIsNew ? {
                        activeItemDirty: false,
                        activeItemIsNew: false,
                        activeItemIndex: nextList.length-1,
                        activeItemEditObj: clone(newItem)
                    } : {}
                }
            })
            return newItem;
        }
    }

    private async callApiUpdateListItem(index:number, item:T) {

        // First update the server
        // Then, update the in-memory list
        
        const fn = this.cfg.apiUpdateListItem;

        if (!fn) throw new Error('Expected postUpdateListItem to exist');
        if (!item) throw new Error('Expected item to exist before posting udpate');

        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                updating: true
            }
        })
        const postResult = await fn(item);
        // TODO: ERROR CHECKING!
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                updating: false,
                list: this.setListItem(index, item),
                // They may not be using the active item feature. But if they are, update the edit object to new clone.
                activeItemDirty: false,
                activeItemEditObj: x.activeItemIndex === index ? clone(item) : undefined
            }
        })
    }

    public async apiInsertListItem(partialItem:Partial<T>) {
        // Now these require a refresh, because, server created ids and stuff.
        // TODO: establish the convention that ALL inserts always return that which was inserted,
        // so we can simply append here without another call.
        const list = this.get();
        if (!list) throw new Error('Expected list');
        if (this.isBusy()) {
            console.log('for now, you cannot insert if busy');
            return;
        }
        return await this.callApiInsertListItem(partialItem);
    }

    public async apiRemoveListItem(partialItem:Partial<T>) {
        const list = this.get();
        if (!list) throw new Error('Expected list');
        if (this.isBusy()) {
            console.log('for now, you cannot insert if busy');
            return;
        }
        return await this.callApiRemoveListItem(partialItem);
    }

    public async apiUpdateListItem(partialItem:Partial<T>) {
        const list = this.get();
        const isEq = this.cfg.isEqual;
        if (!list) throw new Error('Expected list');
        if (!isEq) throw new Error('Expected isEqual');
        const itemIndex = list.findIndex(x => isEq(x, partialItem))
        if (itemIndex === -1) throw new Error('Expected existing list item');
        if (this.isBusy()) {
            console.log('for now, you cannot update if busy');
            return;
        }
        const next = {
            ...list[itemIndex],
            ...partialItem
        }
        await this.callApiUpdateListItem(itemIndex, next);
    }

    private getPristineActiveItem() {
        const state = this._state.getValue();
        const { activeItemIndex:index, list, activeItemIsNew } = state;
        if (activeItemIsNew) {
            if (!this.cfg.createNewListItem) throw new Error('Expected createNewListItem');
            return this.cfg.createNewListItem();
        }
        if (!list) return undefined;
        if (index < 0 || index >= list.length) return undefined;
        return list[index];
    }

    public clearActiveItem() {
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                activeItemIndex: -1,
                activeItemDirty: false,
                activeItemEditObj: undefined,
                activeItemIsNew: false
            }
        })
    }

    public resetActiveItem() {
        const pristineItem = this.getPristineActiveItem();
        if (!pristineItem) return;
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                activeItemDirty: false,
                activeItemEditObj: clone(pristineItem)
            }
        })
    }

    // public patchActiveItemQuietly(patch:Partial<T>) {
    //     const pristineItem = this.getPristineActiveItem();
    //     if (!pristineItem) return;
    //     this._set((x):ZStateListProperties<T> => {
    //         return {
    //             ...x,
    //             activeItemEditObj: clone(pristineItem)
    //         }
    //     })
    // }

    private async hydrateItem(item:T, itemIndex:number) {
        const { isEqual, apiHydrateItem: apiFetchHydrateItem } = this.cfg;
        if (!apiFetchHydrateItem) throw new Error('Expected apiFetchHydrateItem');
        if (!isEqual) throw new Error('Expected isEqual');
        const fullItem = await apiFetchHydrateItem(item);
        // Now that we have the result, see if we still need it.
        const { activeItemIndex, list, activeItemDirty } = this._state.getValue();
        // Leave if for some reason user was clicking around doing crazy things instead of waiting.
        if (activeItemDirty) return;
        if (!list || itemIndex !== activeItemIndex) return;
        const activeItem = list[activeItemIndex];
        if (!isEqual(fullItem, activeItem)) return;
        if (!this.needsHydrating(activeItem)) return;
        // ok. we are still active, non-dirty item, and still need this hydration. good!

        this.updateActiveItemQuietly({ // updates both pristine and edit obj
            ...fullItem,
            _hydrated: true
        }, true)
    }

    private needsHydrating(item:T) {
        const { _hydrated } = item as any;
        // if property does not exist or is true, we do NOT need hydrating
        return _hydrated === undefined || _hydrated === true ? false : true;
    }

    public selectItemByIndex(selectIndex:number):boolean {
        // This is going to have the effect of RESETTING any dirty items that is currently selected.
        const state = this._state.getValue();
        const { activeItemIndex:index, list, activeItemDirty } = state;
        if (!list) throw new Error('Expected list');
        if (selectIndex < 0 || selectIndex >= list.length) throw new Error('Select index out of range');
        if (index !== -1 && (selectIndex < 0 || selectIndex >= list.length)) throw new Error('Select index out of range');
        const nextActiveItem = list[selectIndex];

        this._set((x):ZStateListProperties<T> => {
            const hydrating = this.needsHydrating(nextActiveItem);
            if (hydrating) this.hydrateItem(nextActiveItem, selectIndex); // async, but do not wait
            return {
                ...x,
                hydrating,
                activeItemIsNew: false,
                activeItemIndex: selectIndex,
                activeItemDirty: false,
                activeItemEditObj: clone(nextActiveItem)
            }
        })
        return true;
    }

    public selectNewItem() {
        const { createNewListItem } = this.cfg;
        if (!createNewListItem) throw new Error('Expected createNewListItem');
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                activeItemIndex: -1,
                activeItemIsNew: true,
                activeItemDirty: false,
                activeItemEditObj: createNewListItem()
            }
        })
    }

    public selectItem(item:T):boolean {
        const state = this._state.getValue();
        const { list } = state;
        const { isEqual } = this.cfg;
        if (!list) throw new Error('Expected list');
        if (!isEqual) throw new Error('Missing isEqual');
        const index = list.findIndex(x => isEqual(x, item))
        if (index !== -1) return this.selectItemByIndex(index);
        return false; // we didn't find it!
    }

    public async commitDirtyItem() {
        const state = this._state.getValue();
        const { activeItemDirty, activeItemIndex, activeItemEditObj, activeItemIsNew } = state;
        if (!activeItemEditObj) return;
        if (activeItemIsNew) {
            await this.callApiInsertListItem(activeItemEditObj);
        } else {
            if (!activeItemDirty && !this.cfg.forceCommitsWhenNoChange) {
                console.log('No need to update server--no change');
                return;
            }
            await this.callApiUpdateListItem(activeItemIndex, activeItemEditObj);
        }
    }

    public updateItemAndCommit(patch:Partial<T>) {
        // This is just a helper func.
        // I sometimes foreget if update is sync/async
        // This gives me confidence that it'll do both from hook
        this.updateItem(patch);
        this.commitDirtyItem();
    }

    public updateItem(patch:Partial<T>) {
        // Why was update making changes based on pristine??
        const pristineItem = this.getPristineActiveItem();
        const state = this._state.getValue();
        const { activeItemEditObj:currItem } = state;

        if (!currItem || !pristineItem) throw new Error('Expected activeItemEditObj curr/pristine');
        const nextItem = {
            ...currItem,
            ...patch
        }
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                activeItemEditObj: nextItem,
                activeItemDirty: isDirty(nextItem, pristineItem)
            }
        })
    }

    public updateActiveItemQuietly(patch:Partial<T>, clearHydrating?:boolean) {
        // udpate both pristine AND edit object of active item
        // NOTE: does NOT change dirty or not!
        const state = this._state.getValue();
        const { activeItemIndex:index, list, activeItemEditObj, activeItemIsNew } = state;
        if (activeItemIsNew) return; // unless this is a brand new item (for now, do not allow)
        if (!list) return;
        if (!activeItemEditObj) return;
        const nextList = list.map((item, n) => n === index ? {
            ...item,
            ...patch
        } : item)
        const nextEditObj = {
            ...activeItemEditObj,
            ...patch
        }
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                list: nextList,
                hydrating: clearHydrating ? false : x.hydrating,
                activeItemEditObj: nextEditObj
            }
        })
    }

    private async callFetchDehydratedList(quietlyRefresh:boolean, ...args:any) {
        // we'll only be loading a partial of the items.
        // however, for typing, for now, we're going to pretend we have everything.
        const fn = this.cfg.apiFetchDehydratedList!;
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                error: false,
                fetching: quietlyRefresh ? false : true,
                lastFetchArgs: args, // store last args (for refresh)
                // Let's not reset data...leave it to ui components themselves to hide if fetching
                // list: quietlyRefresh ? x.list : undefined
            }
        })
        const fetchResult = await fn(...args);
        if (fetchResult) {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    error: false,
                    fetching: false,
                    list: fetchResult.map(x => {
                        return {
                            ...x,
                            _hydrated: false
                        }
                    }),
                    activeItemDirty: false,
                    activeItemEditObj: undefined,
                    activeItemIndex: -1
                }
            })
        } else {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    fetching: false,
                    error: true
                }
            })
        }
    }

    private async callFetchHydratedList(quietlyRefresh:boolean, ...args:any) {
        // load everything.
        const fn = this.cfg.apiFetchList!;
        this._set((x):ZStateListProperties<T> => {
            return {
                ...x,
                error: false,
                fetching: quietlyRefresh ? false : true,
                lastFetchArgs: args, // store last args (for refresh)
                // Let's not reset data...leave it to ui components themselves to hide if fetching
                // list: quietlyRefresh ? x.list : undefined
            }
        })
        const fetchResult = await fn(...args);
        if (fetchResult) {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    error: false,
                    fetching: false,
                    list: fetchResult,
                    activeItemDirty: false,
                    activeItemEditObj: undefined,
                    activeItemIndex: -1
                }
            })
        } else {
            this._set((x):ZStateListProperties<T> => {
                return {
                    ...x,
                    fetching: false,
                    error: true
                }
            })
        }
    }

    private async callFetchList(quietlyRefresh:boolean, ...args:any) {
        // Fetching the list clears the active item!
        if (this.cfg.apiFetchList) return this.callFetchHydratedList(quietlyRefresh, ...args);
        if (this.cfg.apiFetchDehydratedList) return this.callFetchDehydratedList(quietlyRefresh, ...args);
        throw new Error('Expected fetchList to exist');
    }

    public needsFetch(args:any[]|undefined):boolean {
        return argsChanged(this._state.getValue().lastFetchArgs, args);
    }

    public async fetchList(...args:any) {

        const lastArgs = this._state.getValue().lastFetchArgs; // we want undefined vs []  to say true

        if (!argsChanged(lastArgs, args)) {
            console.log('Args did not change! do not re-fetch');
            return;
        }
        // if (this.isBusy()) {
        //     if (argsChanged(this.lastArgs(), args)) {
        //         return await this.callFetchList(false, ...args);    
        //     } else {
        //         console.log('Do not re-fetch, currently still fetching this');
        //     }
        // } else {
            await this.callFetchList(false, ...args);
        // }
    }

    public async refreshList() {
        if (this.isBusy()) {
            console.log('Still fetching. Do not refresh.');
        } else {
            await this.callFetchList(true, ...this.lastFetchListArgs());
        }
    }
}

// ---------------------------------------------------------------------------

export class ZState<T> {

    private cfg:ZStateConfig<T>;
    private _state:BehaviorSubject<ZStateProperties<T>>;
    public state$:Observable<ZStateProperties<T>>;

    constructor(cfg:ZStateConfig<T>) {
        this.cfg = cfg;
        this._state = new BehaviorSubject<ZStateProperties<T>>(this.getInitProperties(cfg));
        this.state$ = this._state.asObservable();
        allZStates.push(this);
    }

    private getInitProperties(cfg:ZStateConfig<T>):ZStateProperties<T> {
        return {
            fetching: false,
            updating: false,
            error: false,
            dirty: false,
            ...cfg.initVal && {
                data: cfg.initVal,
                fetchedObj: cfg.initVal,
                editObj: clone(cfg.initVal)
            }
        }
    }

    private _set(fn: (b:ZStateProperties<T>) => ZStateProperties<T>):ZStateProperties<T> {
        const nextVal = fn(this._state.getValue());
        this._state.next(nextVal);
        return nextVal;
    }

    public resetToInitState() {
        if (this.cfg.doNotResetOnLogout) return;
        this._state.next(this.getInitProperties(this.cfg));
    }

    public update(fn:(curr:T) => T) {
        // NOTE this assumes data is NOT NULL
        this._set((x):ZStateProperties<T> => {
            const val = fn(x.data!);
            return {
                ...x,
                data: val,
                editObj: val,
                dirty: isDirty(x.fetchedObj, val)
            }
        })
    }

    public getZid() {
        return this.cfg.zid;
    }

    public clear() {
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                data: undefined,
                editObj: undefined,
                dirty: false,
                lastFetchArgs: undefined
            }
        })
    }

    public merge(partial:Partial<T>) {
        const currVal = this.get();
        if (!currVal) return;
        const nextVal = {
            ...currVal,
            ...partial
        }
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                data: nextVal,
                editObj: nextVal,
                dirty: isDirty(x.fetchedObj, nextVal)
            }
        })
    }

    public set(val:T) {
        // NOTE this assumes data is NOT NULL
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                data: val,
                editObj: val,
                dirty: isDirty(x.fetchedObj, val)
            }
        })
    }

    public reset() {
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                data: x.fetchedObj,
                editObj: x.fetchedObj,
                dirty: false
            }
        })
    }

    // public setInitVal(nextInitVal:T) {
    //     // overwrite any existing initVal (this affects "reset" functionality)
    //     this.cfg = {
    //         ...this.cfg,
    //         initVal: nextInitVal
    //     }
    //     this.reset();
    // }

    // public reset() {
    //     this._set((x):ZStateProperties<T> => {
    //         return {
    //             ...x,
    //             data: this.cfg.initVal
    //         }
    //     })
    // }

    public get() {
        return this._state.getValue().data;
    }

    public getState() {
        return this._state.getValue();
    }

    public isFetching() {
        return this._state.getValue().fetching;
    }

    public isUpdating() {
        return this._state.getValue().updating;
    }

    public isBusy() {
        return this._state.getValue().fetching || this._state.getValue().updating;
    }

    public isDirty() {
        return this._state.getValue().dirty;
    }

    private lastArgs() {
        return this._state.getValue().lastFetchArgs ?? [];
    }

    public async commitChanges(skipBusyStates?:boolean) {

        if (!this.cfg.apiUpdate) return;
        const val = this._state.getValue();
        if (!val.dirty) { // !this.cfg.forceCommitsWhenNoChange
            console.log('zItem is not dirty, nothing to commit.')
            return;
        }
        const { editObj } = val;
        if (!editObj) throw new Error('Dirty object somehow does not exist!');

        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                error: false,
                updating: skipBusyStates ? false : true
            }
        })    

        const updateResult = await this.cfg.apiUpdate(editObj);

        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                error: false,
                updating: false,
                dirty: false,
                data: x.editObj,
                fetchedObj: x.editObj,
                editObj: clone(x.editObj)
            }
        })
        return updateResult;
    }

    public async commitOLD(x:Partial<T>) {
        if (!this.cfg.apiUpdate) return;
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                error: false,
                updating: true
            }
        })
        const updateResult = await this.cfg.apiUpdate(x);
        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                error: false,
                updating: false,
                dirty: false,
                data: x.editObj,
                fetchedObj: x.editObj,
                editObj: clone(x.editObj)
            }
        })
        return updateResult;
    }

    private async callFetch(quietlyRefresh:boolean, ...args:any) {
        const fn = this.cfg.apiFetch;
        if (!fn) throw new Error('Expected fetch fn');

        this._set((x):ZStateProperties<T> => {
            return {
                ...x,
                error: false,
                // do not change fetching if quietly refreshing
                fetching: quietlyRefresh ? x.fetching : true,
                lastFetchArgs: args,
                // do not change data if quietly refreshing
                // Actually, I don't think we should ever wipe current data.
                // It raises too many strange questions. components should stop showing if "fetching"
                // data: quietlyRefresh ? x.data : this.cfg.initVal
            }
        })

        const fetchResult = await fn(...args);
        if (fetchResult) {
            const prevVal = this.get();
            this._set((x):ZStateProperties<T> => {
                return {
                    ...x,
                    error: false,
                    fetching: false,
                    data: fetchResult,
                    fetchedObj: fetchResult,
                    dirty: false,
                    editObj: clone(fetchResult)
                }
            })
            if (this.cfg.onFetch) this.cfg.onFetch(prevVal, fetchResult);
        } else {
            this._set((x):ZStateProperties<T> => {
                return {
                    ...x,
                    fetching: false,
                    error: true
                }
            })
        }
        return fetchResult;
    }

    public needsFetch(args:any[]|undefined):boolean {
        const needsFetchResult = argsChanged(this._state.getValue().lastFetchArgs, args);
        // if (this.cfg.zid === 'current-sheet') {
        //     console.log(this.cfg.zid, 'Needs fetch result', needsFetchResult, this._state.getValue().lastFetchArgs, args)
        // }
        // return argsChanged(this._state.getValue().lastFetchArgs, args);
        return needsFetchResult;
    }

    public async fetch(...args:any) {

        const lastArgs = this._state.getValue().lastFetchArgs; // we want undefined vs []  to say true

        if (!argsChanged(lastArgs, args)) {
            // DO NOT FETCH
            console.log('Args have not changed--do not re-fetch (use refresh to force)');
            return;
        }

        if (this.isFetching()) {
            //if (argsChanged(this.lastArgs(), args)) {
                return await this.callFetch(false, ...args);    
            //} else {
              //  console.log('Do not re-fetch, currently still fetching this');
            //}            
        } else {
            return await this.callFetch(false, ...args);
        }
    }

    public async refresh() {
        if (this.isFetching()) {
            console.log('Still fetching. Do not refresh.');
        } else {
            return await this.callFetch(true, ...this.lastArgs());
        }
    }
}

const argsChanged = (prev?:any[], curr?:any[]) => {
    if (!curr) return false; // if curr nothing, say no change.
    if (!prev) return true;
    if (curr.length !== prev.length) return true;
    if (curr.length === 0) return false;
    for (let i=0; i < curr.length; i++) {
        if (!Object.is(curr[i], prev[i])) return true;
    }
    return false;
}