import React, { useState, useEffect } from 'react';
import { Checkbox, Icon, Box, Flex, FlexVCenter, Button, Dropdown, DropdownItem, modal, Text } from 'mui';
import { tapeImporter, sellers } from 'services';
import { ExpandedState } from './types';
import { labels } from 'market-dto';
import { setSelected } from './set-selected';
import { MatchTableRow } from './match-table-row';
import { useStickyStateWithDefault  } from 'hooks';

const confidenceLabel:{[x in tapeImporter.TemplateMatchConfidence]:string} = {
    "medium-high": "Medium",
    "very-high": "Very High",
    "high": "High",
    "highest": "Highest"
}

interface Props {
    readonly item:tapeImporter.BatchItem;
    readonly exState:ExpandedState;
    readonly setExState:(x:ExpandedState) => void;
}
export const MatchTable = ({ item, exState, setExState }:Props) => {

    const { matchResults, model } = exState.item;
    if (!matchResults) throw new Error('Expected matchResults');

    const [ showIds, setShowIds ] = useStickyStateWithDefault('match-table-show-template-ids', false);
    const showAggColumn = matchResults.some(p => p.aggregatorName);

    const { newSeller, selected } = exState;

    const selectMatch = (x:tapeImporter.UnassignedValidMatchResult) => {
        // take them into mapping OR sellers thing.
        setExState(exState.selected === x ? setSelected(item, exState) : setSelected(item, exState, x));
    }

    // shove the table over to the left x pixels to make up for the "select" column
    return (
        <>
            <table className="table table-sm" style={{ width:'auto', marginLeft:'-75px' }}>
                <thead>
                    <tr onContextMenu={e => { e.preventDefault(); setShowIds(!showIds) }}>
                        <th style={{ backgroundColor: '#000' }}>&nbsp;</th>
                        { showAggColumn && <th className="left">Aggregator</th> }
                        {/* if agg, there can be more than one seller found per match */}
                        <th className="left">{ showAggColumn ? 'Seller(s)' : 'Seller' }</th>
                        <th className="left">Confidence</th>
                        {/* <th className="left">{ labels.byOrgField('externalId') }</th>
                        <th className="left">{ labels.byOrgField('externalIdSource') }</th> */}
                        <th className="left">{ labels.byOrgField('aliases') }</th>
                        <th className="left">Overrides</th>
                        { showIds && <th>Template IDs</th> }
                    </tr>
                </thead>
                <tbody>

                { newSeller && (
                    <tr>
                        <td style={{ backgroundColor: '#000', borderTop:'1px solid #000' }}>
                            <Icon name="arrow-right" fg="accent" />
                        </td>
                        { showAggColumn && <td>&nbsp;</td> }
                        <td>{ newSeller.name }</td>
                        <td><Text fg="success">100%, Manual Selection</Text></td>
                        {/* <td>{ newSeller.externalId }</td>
                        <td>{ newSeller.externalIdSource }</td> */}
                        <td style={{ maxWidth: '300px'}}>{ newSeller.aliases?.join(', ') }</td>
                        <td>
                            &nbsp;
                        </td>
                        { showIds && <td></td> }
                    </tr>
                ) }

                { matchResults.map((x, n) => {
                    // invalid templates have "invalid" as their hash.
                    const dupe = x.templateOverrideHash !== 'invalid'
                        && matchResults.some((m, n2) => m.templateOverrideHash === x.templateOverrideHash && n !== n2)
                    return (
                        <MatchTableRow 
                            key={n}
                            showIds={showIds}
                            showAggColumn={showAggColumn}
                            matchResult={x}
                            model={model}
                            onSelect={selectMatch}
                            dupeTemplate={dupe}
                        />
                    )
                }) }
                </tbody>
            </table>
        </>
    )
}