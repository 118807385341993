import React from 'react';
import { JSONSchema } from 'market-dto';
import { SchemaUiItem } from './item';
import { KeyValueDict } from './schema-ui.service';

interface Props {
    readonly editting:boolean;
    readonly data:KeyValueDict;
    readonly items:Array<[string, JSONSchema]>;
    readonly onChange:(x:any) => void;
}
export const SchemaUiProperties = ({ items, onChange, data, editting }:Props) => {

    return (
        <>
            { items.map(([path, schema]) => (
                <SchemaUiItem
                    key={path}
                    path={path}
                    schema={schema}
                    data={data}
                    editting={editting}
                    setValue={val => onChange({ ...data, [path] : val })}
                />
            )) }
        </>
    )
}