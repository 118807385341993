import {
    GQL,
    RowsAndHeaders,
    Overrides,
    util,
    ImportModel
} from 'market-dto';
import { MappedSellerInfo } from '../types';
import { createLoansViaOverrides } from './create-loans';

// This answers the question: If we took the orides as we have them currently (user is messing around)
// what distinct sellers will we end up having found when applying mapping to this tape?
// There MUST be one-and-only-one distinct sellerName for this to be allowed to be from an aggregator.

export const getSellerInfoByApplyingOrides = (
    model:ImportModel,
    rowsAndHeaders:RowsAndHeaders,
    sellerOrgs:GQL.Org[],
    orides:Overrides
):MappedSellerInfo => {
    // We are not actually doing anything with these loan objects we're making (hence temporary tapeId)
    const { loans } = createLoansViaOverrides(model, "temporaryTapeId", rowsAndHeaders, sellerOrgs, orides);
    const distinctSellerNames = util.toDistinct(loans.map(loan => loan.sellerName).filter(x => x)) as string[];
    const distinctSellerOrgIds = util.toDistinct(loans.map(loan => loan.sellerOrgId).filter(x => x)) as string[];
    return {
        distinctSellerNames,
        distinctSellerOrgIds
    }
}