import React from 'react';
import { Button, IconButton, openConfirmModal } from 'mui';
import { EditableRuleTable, EditableRuleTableRow } from './param-table.service';
import { ParamTableRow } from './param-table-row';

// TODO :stop doing this string|number|undefined, add a type!  <-- YEAH, i second that, self.

interface Props {
    readonly table:EditableRuleTable;
    readonly allowDelete?:boolean;
    readonly onChange:(table:EditableRuleTable) => any;
    readonly createNewRow?:() => EditableRuleTableRow;
}
export const ParamTable = ({ table, allowDelete=true, createNewRow, onChange }:Props) => {

    const onRowValsChange = (rowIndex:number, vals:Array<string|number|undefined|null>) => {
        onChange({
            ...table,
            rows: table.rows.map((row, n) => n === rowIndex ? { ...row, vals:vals} : row)
        })
    }

    const append = () => {
        addAfter(table.rows.length-1);
    }

    const addAfter = (rowIndex:number) => {
        if (!createNewRow) return;
        const rows = table.rows.map(row =>  ({ ...row, editting: false })); // collapse any being editted
        // Now, insert row after one clicked
        onChange({
            ...table,
            rows: [...rows.slice(0, rowIndex+1), createNewRow(), ...rows.slice(rowIndex+1)]
        })
    }

    const removeRow = (rowIndex:number) => {
        openConfirmModal({
            title: 'Are you sure you want to remove this rule?',
            ok: () => {
                onChange({
                    ...table,
                    rows: table.rows.map((row, n) => n === rowIndex ? { ...row, deleted: true } : row)
                })
            }
        })
    }
    // if (!window.confirm('TODO: click ok to remove, click cancel to keep\n\nHey jeff, make a nice custom modal to replace confirm box')) return;    }

    const toggleEditting = (rowIndex:number) => {
        const row = table.rows[rowIndex];
        if (row.editting) {
            onChange({
                ...table,
                rows: table.rows.map((row, n) => n === rowIndex ? { ...row, editting: false } : row)
            })
        } else {
            onChange({
                ...table,
                rows: table.rows.map((row, n) => n === rowIndex ? { ...row, editting: true } : { ...row, editting: false })
            })
        }
    }

    return (
        <table className="table">
        <thead>
            <tr>
                { table.cols.map((col, n) => {
                    const css = col.type === 'float' || col.type === 'int' ? 'right' : 'left';
                    return <th key={n} className={css}>{ col.label }</th>
                })}
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            { table.rows.map((row, rowIndex) => {
                return !row.deleted && (
                    <ParamTableRow
                        key={'pr' + rowIndex}
                        vals={row.vals}
                        cols={table.cols}
                        allowDelete={allowDelete}
                        editting={row.editting}
                        onRemove={() => removeRow(rowIndex)}
                        onChange={vals => onRowValsChange(rowIndex, vals)}
                        toggleEditting={() => toggleEditting(rowIndex)}
                        onAddAfter={() => addAfter(rowIndex)}
                    />
                )}
            )}
        </tbody>
        <tfoot>
            <tr>
                <td colSpan={table.cols.length+1} className="right">
                    <IconButton name="plus" onClick={append}/>
                </td>
            </tr>
        </tfoot>
        </table>
    )
}