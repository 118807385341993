// @ts-ignore
const omitDeepArrayWalk = (arr:any[]) => {
	return arr.map(val => {
		if (Array.isArray(val)) return omitDeepArrayWalk(val);
		else if (typeof val === "object") return omitDeep(val);
		return val;
	});
}

const omitDeep = (obj:any) => {
	const keys = (obj === undefined || obj === null) ? []:  Object.keys(obj);
	const newObj:any = {};
	keys.forEach(k => {
		if (!k.startsWith("__")) {
			const val = obj[k];
			if (val instanceof Date) newObj[k] = val;
			else if (Array.isArray(val)) newObj[k] = omitDeepArrayWalk(val);
			else if (typeof val === "object" && val !== null)
				newObj[k] = omitDeep(val);
			else newObj[k] = val;
		}
	});
	return newObj;
}

export const stripInternals = <T> (item:T, keepId?:boolean):T => {
	// We cannot update or insert with "id" and/or "__typename" in the "xxxx_insert_input" style objects
	if (keepId) {
		const { __typename, _hydrated, entity, scopes, updatedAt, ...data } = item as any;
		return omitDeep(data) as T;
	} else {
		const { id, __typename, _hydrated, entity, scopes, updatedAt, ...data } = item as any;
		return omitDeep(data) as T;
	}
}
