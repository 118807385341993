import React, { useState, useRef } from 'react';
import { GQL } from 'market-dto';
import { alpha } from 'services';
import { Box, Icon, Text, FlexVCenter  } from 'mui';

interface Props {
    readonly flow:GQL.Flow;
    readonly onPop:(flow:GQL.Flow, elem:HTMLDivElement) => void;
}

export const FlowTableRecentCol = ({ flow, onPop }:Props) => {

    const ref = useRef<HTMLDivElement>(null);

    const go = () => {
        if (!ref.current) return;
        onPop(flow, ref.current);
    }

    const { recentActivity } = flow;
    const hasRun = recentActivity && (recentActivity.sellerSymbol || recentActivity.sellerOrgId);

    return <FlexVCenter
        ref={ref}
        justifyContent="flex-end"
        fg="default"
        fgHover="link"
        cursor="pointer"
        onClick={go}
    >
        { !hasRun && <Text fg="faded">None</Text> }
        { hasRun && (
            <>
                <Text>{ alpha.getFlowRunName(recentActivity!) }</Text>
                <Icon name="chevron-down" ml="1" />
            </>
        ) }
    </FlexVCenter>
}
