import { mutate, gql } from "../../client";

const MUTATE = gql`
    mutation {
        cleanroom_import_sellers_sync
    }
`;

export const importCleanroomSellers = async ():Promise<any | undefined> => {
    const result = await mutate(MUTATE, {});
    console.log('we got', JSON.stringify(result, null, 4));
    return result;
}
