import { mutate, gql } from "../../client";
import { Eligible } from 'raccoon-engine';

const UPSERT = gql`
    mutation ($data: simpleEligible_set_input) {
        upsert_simpleEligible(set: $data) {
            errorMessage
            affectedRow
            returning {
                rules
            }
        }
    }
`;

interface MutateInput {
    readonly data: {
        readonly rules: Eligible[];
    }
}

interface MutateOutput {
    readonly upsert_simpleEligible:{
        readonly returning:[{
            readonly rules:Eligible[];
        }];
    }
}

export const upsertSimpleRules = async (rules:Eligible[]):Promise<Eligible[]|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(UPSERT, {
        data: {
            rules: rules
        }
    })
    return result?.data?.upsert_simpleEligible.returning[0].rules;
}