import React from 'react';
import { FgColor, Box, Text, FormatAndPadEnd } from 'mui';
import { actMon } from 'services';
import { useObsWithDefault } from 'hooks';

const getFg = (item:actMon.ActivityLogItem):FgColor => {
    // changed my mind. too busy giving different values to different types.
    return 'default';
    // if (item.type === 'ERROR') return 'normal';
    // if (item.type === 'INFO') return 'normal';
    // if (item.type === 'ACTIVITY_STARTED') return 'normal';
    // if (item.type === 'ACTIVITY_ENDED') return 'normal';
    // if (item.type === 'ACTIVITY_HALT') return 'normal';    
}

const getPrefix = (item:actMon.ActivityLogItem) => {
    if (item.type === 'ERROR') return <Text fontWeight="bold" fg="danger">ERROR&nbsp;</Text>;
    if (item.type === 'QUEUED') return <Text fg="accent">Queued&nbsp;</Text>;
    if (item.type === 'ACTIVITY_STARTED') return <Text fg="success">Start&nbsp;</Text>;
    if (item.type === 'ACTIVITY_ENDED') return <Text fg="success">Done&nbsp;</Text>;
    if (item.type === 'ACTIVITY_HALT') return <Text fg="danger">Stop&nbsp;</Text>;
    return null;
}

export const ActivityLogs = () => {

    const logs = useObsWithDefault(actMon.logs, []);

    return <Box>

        { logs.map((item, n) => (
            <Box key={n} bg="transparent" bgHover="card" style={{ paddingBottom: '1px', paddingTop: '1px' }}>
                <Text fg="faded">
                    <FormatAndPadEnd padEnd={21} as="date_and_time_for_logs">{ item.timestamp }</FormatAndPadEnd>
                </Text>
                {/* <Text fg="faded" padEnd={15}>{ item.category }</Text> */}
                <Text fg="faded" padEnd={16}>{ item.identifier }</Text>
                <Text fg={getFg(item)}>
                    { getPrefix(item) }
                    { item.msg }
                </Text>
            </Box>
        )) }
        {/* <textarea readOnly value={JSON.stringify(logs, null, 4)}></textarea> */}
    </Box>
}
