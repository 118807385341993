import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const INSERT = gql`
    mutation insertLoan($loans: [loan_insert_input!])
    {
        insert_loan(objects: $loans) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly loans:GQL.Loan[];
}

interface MutateOutput {
    readonly insert_loan: {
        readonly returning:GQL.Loan[];
    }
}

export const insertLoans = async (loans:GQL.Loan[]):Promise<GQL.Loan[]|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(INSERT, { loans });
    return result?.data?.insert_loan.returning;    
}
