import React, { useLayoutEffect, useEffect, useState } from 'react';

export const useOnClickOutside = (ref:React.MutableRefObject<any>, handler: () => void) => {

    // use layout effect --- very important.
    // if you use normal useEffect, it'll jump in front of things like react dropdown's onchange ... 
    // and you'll end up closing whatever thing is open and not getting the change value,
    // or you'll overwrite it wth an old value, etc...
    // it's a lifecycle thing.

    useLayoutEffect(() => {
        // console.log('ref or handler changed');
        const listener = (event:MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            // console.log('we heard this', event);
            handler();
        }  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }
    }, [ref, handler])
}

export const useOnClickOutsideExcept = (ref:React.MutableRefObject<any>, ids:string[], handler: () => void) => {

    useLayoutEffect(() => {
        const listener = (event:MouseEvent | TouchEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            // also check any ids that were sent to us
            const found = ids.some(id => {
                const el = document.getElementById(id);
                return el?.contains(event.target as any);
            })
            if (found) return;
            handler();
        }  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }
    }, [ref, handler, ids])
}
