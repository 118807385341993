import React, { useState } from 'react';
import { Tags, Box } from 'mui';
import { rule } from 'services';

interface FieldEnumProps {
    readonly field:rule.RuleModelField;
    readonly onChange:(x:rule.RuleModelField) => void;
}
export const FieldEnum = ({ field, onChange }:FieldEnumProps) => {
    const tags = field.enums ?? [];
    return (
        <Box mb="2">
            <Box fontSize="lg" fontWeight="bold" mb="0">Enumeration Values</Box>
            <Tags
                width="100%"
                placeholder="Enter Enumeration"
                items={tags as string[]}
                onChange={arr => onChange({ ...field, enums: arr })}
            />
        </Box>
    )
}