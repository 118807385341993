import React from 'react';
import { Icon } from 'mui';

// This belongs in mui eventually.

interface ToggleSwitchProps {
    readonly left:boolean;
    readonly onToggle:(nextLeft:boolean) => void;
}
export const ToggleSwitch = ({ left, onToggle }:ToggleSwitchProps) => {
    return (
        <Icon
            cursor="pointer"
            name={left ? 'toggle-left' : 'toggle-right'}
            onClick={() => onToggle(!left)}
        />
    )
}