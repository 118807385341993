import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea, Button, Alert, Text } from 'mui';
import { useApiFetch } from 'hooks';
import { utils, proposal, sheets } from 'services';
import * as api from 'api';
import { useNavigate } from 'react-router-dom';

const toSearchArray = (s:string):string[] => {
    return utils.toDistinct(s.trim().split('\n').filter(x => x));
}

export const PasteHits = () => {

    const [ txt, setTxt ] = useState('');
    const firstTime = useRef(true);
    const navigate = useNavigate();

    const { data:success, load, loading, hasRun } = useApiFetch(async () => {
        if (!txt.trim()) return;
        const loanNumbers = toSearchArray(txt);
        if (loanNumbers.length === 0) return;
        const propDetails = await api.addHitOffers(loanNumbers, 5, true);

        if (propDetails) {
            sheets.updateHitInfo(propDetails);
            navigate('/tape/' + propDetails.sheetId + '/confirmation/import');
        } else {
            // ERROR -- handle this error with a toast or something
            return false;
        }

        // is this necessary???
        // GET RID OF THIS SEARCH STATE NONSENSE.
        return true;

        // const searchState:proposal.HitSearchState|undefined = propDetails ? {
        //     hitInfo: proposal.toHitInfo(propDetails),
        //     searched: loanNumbers
        // } : undefined;

        // return searchState;
    })

    // useEffect(() => {
    //     if (firstTime.current) {
    //         firstTime.current = false;
    //         proposal.searchState.update(x => undefined);
    //     } else {
    //         console.log('setting hit search state', searchState);
    //         proposal.searchState.update(x => searchState);
    //     }
    // }, [searchState])

    return (
        <>
            { !success && hasRun && (
                <Alert mb="2" icon="alert-triangle">
                    Error: Could not find loan number(s)!
                </Alert>
            ) }

            Copy and paste loan numbers into the area below.

            <Box mt="1">
                <Textarea
                    height="tall"
                    value={txt}
                    // onEnter={load}
                    setValue={setTxt}
                />
            </Box>

            <Box mt="1" textAlign="center">
                <Button
                    busy={loading}
                    onClick={load}
                    type={txt.trim() === '' ? 'disabled' : 'default'}
                >Continue</Button>
            </Box>
        </>
    )
}