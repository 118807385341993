import React, { useState } from 'react';
import { FlexVCenter, Icon, Box, DropdownItem, FrmInput, FrmDropdown, Button, RenderTabs, RenderMenuItem } from 'mui';
import { rule } from 'services';


interface FieldDetailsProps {
    readonly isNew:boolean;
    readonly allFields:rule.RuleModelField[];
    readonly field:rule.RuleModelField;
    readonly groups:string[];
    readonly onChange:(x:rule.RuleModelField) => void;
}
export const FieldDetails = ({ allFields, groups, field, onChange, isNew }:FieldDetailsProps) => {
    
    // const [ fieldId, setFieldId ] = useState<string>('');
    // const [ label, setLabel ] = useState<string>('');
    // const [ newGroups, setNewGroups ] = useState<string[]>([]);

    const groupOpts:DropdownItem[] = groups.map(x => {
        return {
            label: x,
            value: x
        }
    })

    return (
        <Box>

            <FrmInput
                width="100%"
                label="Field Id"
                value={field.fieldId}
                setValue={x => onChange({ ...field, fieldId: x })}
            />
            <FrmInput
                width="100%"
                label="Label"
                value={field.label}
                setValue={x => onChange({ ...field, label: x })}
            />
            <FrmDropdown
                width="100%"
                label="Group"
                isMulti={true}
                vals={field.groups}
                setValue={arr => onChange({ ...field, groups: arr })}
                items={groupOpts}
            />

        </Box>
    )
}