import { TransCfg, EnumHintDict } from 'market-dto';
import { utils } from '../index';

const getStringsNotIn = (arr1:string[], arr2:string[]):string[] => {
    // a litte nicer than just !includes cuz lowercase, dictionary...
    const d:any = {};
    arr2.forEach(x => d[x.toLowerCase()] = true);
    return arr1.filter(x => !d.hasOwnProperty(x.toLowerCase()));
}

export const getUnmentionedOverrideStrings = (distinctVals:string[], transCfg:TransCfg):string[] => {
    // ASSUMPTION: transCfg.param is type HintDict!
    if (!transCfg.override) return [];
    if (transCfg.override.to !== 'enumFromHintDict') {
        throw new Error('currently only supported for enumFromHintDict types');
    }
    // We are finding ALL exact override values specified in translation config...
    // ...so we can find those NOT mentioned in incoming spreadsheet
    const dict:EnumHintDict = transCfg.override.enumHintDict;
    const exacts = Object.keys(dict).map(k => dict[k]?.exact).filter(exact => exact !== undefined && Array.isArray(exact));
    const distinctOverrideExactVals = utils.toDistinct(exacts.flat()) as string[];
    return getStringsNotIn(distinctOverrideExactVals, distinctVals);
}