import React, { useState, useRef, useMemo, useLayoutEffect, useEffect } from 'react';
import { GQL } from 'market-dto';
import { utils } from 'services';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { XlsExportTableTheadTh } from './xls-export-table-thead-th';

const getLetter = (n:number):string => {
    const ordA = 'A'.charCodeAt(0);
    const ordZ = 'Z'.charCodeAt(0);
    const len = ordZ - 65 + 1;
    let s = "";
    while (n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
}

interface Props {
    readonly flowRun:GQL.FlowRun;
    readonly colCfgs:GQL.XlsExportCol[];
    readonly selectedColId:number;
    readonly showingPopFilter:boolean;
    readonly setSelectedColId:(x:number) => void;
    readonly selectedRowNo:number;
    readonly setSelectedRowNo:(n:number) => void;
    readonly setShowFilterPopper:(f:GQL.XlsExportCol, elem:HTMLElement) => void;
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly readOnly:boolean;
}

export const XlsExportTableThead = ({
    colCfgs,
    showingPopFilter,
    selectedColId,
    setSelectedColId,
    selectedRowNo,
    setSelectedRowNo,
    setShowFilterPopper,
    onColCfgsChange,
    readOnly
}:Props) => {

    const letters = colCfgs.map((f,n) => getLetter(n));

    const moveField = (from:number, to:number) => {
        if (readOnly)return;
        // console.log('from', from);
        // console.log('to', to);
        const fromIndex = colCfgs.findIndex(x => x.colId === from);
        const toIndex = colCfgs.findIndex(x => x.colId === to);
        onColCfgsChange(utils.moveItem(colCfgs, fromIndex, toIndex));
        setSelectedColId(-1);
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <thead>
                <tr>
                    <th className="sticky-col">&nbsp;</th>
                        { letters.map((letter, colNo) => {
                            return (
                                <XlsExportTableTheadTh
                                    readOnly={readOnly}
                                    key={letter}
                                    colCfg={colCfgs[colNo]}
                                    letter={letter}
                                    selectedColId={selectedColId}
                                    setSelectedColId={setSelectedColId}
                                    setShowFilterPopper={setShowFilterPopper}
                                    showingPopFilter={showingPopFilter}
                                    moveField={moveField}
                                />
                            )
                        }) }
                </tr>
            </thead>
        </DndProvider>
    )
}

