import React, { useState, useEffect } from 'react';
import { Box, Dropdown, DropdownItem, FlexVCenter, Button } from 'mui';
import { GQL, ImportModel } from 'market-dto';
import { Enum } from './enum';

const opts:DropdownItem<GQL.XlsExportColumnType>[] = [
    {
        label: "Enumeration",
        value: "CALC_ENUM"
    },
    {
        label: "Average",
        value: "CALC_AVG"
    }
]


interface Props {
    readonly model:ImportModel;
    readonly colCfgs:GQL.XlsExportCol[];
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
}

export const ExpanedModal = ({
    model,
    colCfgs,
    onColCfgsChange,
    selectedColId,
    setSelectedColId
}:Props) => {

    const [ type, setType ] = useState<GQL.XlsExportColumnType>();
    const [ confirmedType, setConfirmedType ] = useState<GQL.XlsExportColumnType>();

    const go = () => {
        if (!type) return;
        setConfirmedType(type);
    }

    return (
        <Box mt="1">
            { !confirmedType && (
                <FlexVCenter mt="2">
                    <Box flex="1" mr="1">
                        <Dropdown
                            placeholder="What kind of expanded column are we talking about here?"
                            items={opts}
                            value={opts.find(x => x.value === type)}
                            setValue={setType}
                        />
                    </Box>
                    <Button
                        type={type ? 'default' : 'disabled'}
                        onClick={go}
                    >Continue</Button>
                </FlexVCenter>
            ) }
            { confirmedType && (
                <Box>
                    <Enum
                        model={model}
                        colCfgs={colCfgs}
                        onColCfgsChange={onColCfgsChange}
                        selectedColId={selectedColId}
                        setSelectedColId={setSelectedColId}
                    />
                </Box>
            ) }
        </Box>
    )
}