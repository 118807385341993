import { mutate, gql } from "../../client";
import { SellerMarginsDoc } from 'market-dto';

interface MutateOutput {
    readonly upsert_sellerMargins:{
        readonly returning:[{
            readonly sellerMarginsDoc:SellerMarginsDoc;
        }];
    }
}

interface MutateInput {
    readonly data: {
        readonly sellerMarginsDoc:SellerMarginsDoc;
    }
}

const UPSERT = gql`
    mutation ($data: sellerMargins_set_input) {
        upsert_sellerMargins(set: $data) {
            errorMessage
            affectedRow
            returning {
                sellerMarginsDoc
            }
        }
    }
`;

export const upsertSellerMargins = async (marginsDoc:SellerMarginsDoc):Promise<SellerMarginsDoc | undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPSERT, {
        data: {
            sellerMarginsDoc: marginsDoc
        }        
    })
    // const client = getApolloClient(false);
	// const result = await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: UPSERT,
	// 	variables: {
	// 		data: {
    //             sellerMarginsDoc: marginsDoc
    //         }
	// 	}
    // });
    return result?.data?.upsert_sellerMargins.returning[0].sellerMarginsDoc;
}