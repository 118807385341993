import { Batch, BatchStep } from './types';

const orderDict:{[x in BatchStep]:number} = {

    PARSE_TAPES: 10,
    UI_GET_PASSWORD: 15,
    UI_SPECIFY_TABS_HEADERS_ROWS: 17,
    TAPE_INSERT: 19,
    SEARCH_TEMPLATES: 20,

    // UI_NEED_NEW_TEMPLATE: 30,
    UI_CONFIRM_TEMPLATES: 30,

    POST_CONFIRM: 40,
    DONE: 9999

}

export const isBeforeStep = (batch:Batch, step:BatchStep ) => {
    const a = orderDict[batch.step];
    const b = orderDict[step];
    return a < b;
}

export const isAfterOrAtStep = (batch:Batch, step:BatchStep ) => {
    const a = orderDict[batch.step];
    const b = orderDict[step];
    return a >= b;
}