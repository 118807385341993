import React from 'react';
import { RecentTapes } from './recent-tapes';
import { Menu } from './menu';
import { Route, Routes } from 'react-router-dom';
import { PortalPlaceholder, FlexVCenter } from 'mui';

export const Tapes = () => {
    return (
        <>
            <FlexVCenter justifyContent="space-between" mt="2">
                <Menu />
                <PortalPlaceholder id="tapes-menu-btn-portal"></PortalPlaceholder>
            </FlexVCenter>
            <Routes>
                <Route path="date/:dt" element={<RecentTapes />} />
                <Route path="" element={<RecentTapes />} />
            </Routes>
        </>
    )
}
