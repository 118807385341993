import { mutate, stripInternals, gql, GQL } from "../../client";
import { SAMPLE_FIELDS } from './returning';

const INSERT = gql`
    mutation insertModelSample($objects: [modelSample_insert_input!]) {
        insert_modelSample(objects: $objects) {
            errorMessage
            affectedRow
            returning {
                ${ SAMPLE_FIELDS }
            }
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.ModelSample>]
}

interface MutateOutput {
    readonly insert_modelSample: {
        readonly returning:[GQL.ModelSample];
    }
}

export const insertModelSample = async (sample:Partial<GQL.ModelSample>):Promise<GQL.ModelSample | undefined> => {
    // const client = getApolloClient(false);
	const result =  await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [stripInternals(sample)]
    })
    return result?.data?.insert_modelSample.returning[0];
}
