import React from "react";
import { Box, Button, FlexVCenter, IconButton, Input, Text, modal } from "mui";
import { FlowTable } from "./flow-table";
import { GQL } from "market-dto";
import { FlowListSelection } from './types';
import { useStickyStateWithDefault } from 'hooks';

interface Props {
	readonly flows:GQL.Flow[];
	readonly onSelect:(sel:FlowListSelection) => void;
	readonly onAdd:() => void;
}
export const ListFlows = ({ flows, onAdd, onSelect }:Props) => {

	const [ filter, setFilter ] = useStickyStateWithDefault('workflow-filter-text', '');
	const filterLower = filter.toLowerCase().trim();

	const filtered = flows.filter((x) => {
		return (
			x.name.toLowerCase().includes(filterLower) ||
			x.tags.some((tag) => tag.trim().toLowerCase().includes(filterLower))
		)
	})

	return (
		<>
			<FlexVCenter mb="2">
				<Box fontSize="lg">Workflows</Box>
				<Box ml="2" fg="faded" fontSize="sm">Define and execute custom pipelines</Box>
			</FlexVCenter>
			<FlexVCenter mb="2" justifyContent="space-between">
				<FlexVCenter>
					{ flows.length > 0 && (
						<>
							<Box mr="1">
								<Input
									appendIcon="search"
									placeholder="Search..."
									value={filter}
									setValue={setFilter}
								/>
							</Box>
							<Box fg="default">
								{ filtered.length }
								<Text fg="faded">
									&nbsp;Workflow{filtered.length === 1 ? "" : "s" }
								</Text>
							</Box>
						</>
					) }
				</FlexVCenter>
				<Box>
					<IconButton name="plus" onClick={onAdd} />
				</Box>
			</FlexVCenter>
			<FlowTable onSelect={onSelect} flows={filtered} />
		</>
	)
}
