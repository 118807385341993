import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const QUERY = gql`
    query ($where: where_trade_exp!) {
        trade(where: $where) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryOutput {
    readonly trade:[GQL.Trade];
}

// export const fetchTradeByProposalId = async (proposalId:string):Promise<GQL.Trade|undefined> => {
//     const result = await query<QueryInput, QueryOutput>(QUERY, { 
//         where: {
//             proposalId: {
//                 _eq: proposalId
//             }
//         }
//     })
//     return result.data.trade[0] ?? undefined;
// }

export const fetchTradeBySheetId = async (sheetId:string):Promise<GQL.Trade|undefined> => {
    const result = await query<QueryInput, QueryOutput>(QUERY, { 
        where: {
            sheetId: {
                _eq: sheetId
            }
        }
    })
    return result.data.trade[0] ?? undefined;
}