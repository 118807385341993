import React from 'react';
import { tapeImporter } from 'services';
import { Text, Box, Icon, FlexVCenter, Button } from 'mui';
import { Skipped } from './skipped';
import { NoMatches } from './no-matches';
import { MultipleMatches } from './multiple-matches';
import { SingleMatchNonAgg } from './single-match-non-agg';
import { SingleMatchAgg } from './single-match-agg';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
    readonly onLoad:(item:tapeImporter.BatchItem) => void;
}
export const SellerColumn = ({ batch, item, onLoad }:Props) => {

    const {
        assigned,
        matchResults,
        status
    } = item;

    if (item.error) return <Text fg="danger" fontSize="sm">{ item.errorMsg }</Text>
    if (item.skipped) return <Skipped item={item} batch={batch} />

    if (tapeImporter.isBeforeStatus(item, "INSERTING_TAPE")) return <></>;

    // Show a spinner for when we are inserting the tape + searching for the tape.
    if (tapeImporter.isBeforeOrAtStatus(item, "SEARCHING_TEMPLATES")) return <Icon name="loader" fg="loud" spinning={!batch.paused} />;

    if (item.assigned) return <Text fg="default" fontSize="sm">{ item.assigned.sellerOrg.name }</Text>

    // At this point, match Results must exist.
    if (!matchResults) throw new Error('Expected matchResults to exist');
    if (matchResults.length === 0) return <NoMatches item={item} batch={batch} />
    if (matchResults.length > 1) return <MultipleMatches item={item} />
    if (matchResults.length === 1) {
        if (matchResults[0].aggregatorName) {
            return <SingleMatchAgg matchResult={matchResults[0] as tapeImporter.AggMatchResult} item={item} />
        } else {
            return <SingleMatchNonAgg matchResult={matchResults[0]} item={item} />
        }        
    }

    return (
        <Box>not yet</Box>
    )
}


// import React from 'react';
// import { Icon, Link, Text, FlexVCenter, Box, Button, HoverIconBox } from 'mui';
// import { tapeImporter, sellers } from 'services';

// interface Props {
//     readonly batch:tapeImporter.Batch;
//     readonly item:tapeImporter.BatchItem;
//     readonly onLoad:(item:tapeImporter.BatchItem) => void;
// }
// export const ColSellerName = ({ batch, item, onLoad }:Props) => {

//     const {
//         finalOrgName,
//         assigned,
//         matchResults,
//         status,
//         userProvidedSellerOrgName // WHAT IS THIS
//     } = item;

//     if (tapeImporter.isBeforeStatus(item, "INSERTING_TAPE")) {
//         return <></>;
//     }

//     if (item.error) {
//         return <Text fg="danger" fontSize="sm">{ item.errorMsg }</Text>
//         // return <Text fg="danger" fontSize="sm">Tape cannot be processed</Text>
//     }

//     if (item.skipped) {
//         if (status === "TEMPLATES_FOUND" && matchResults) {
//             if (tapeImporter.isBeforeStep(batch, "POST_CONFIRM")) {
//                 return (
//                     <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
//                        <Text fg="really-faded" textStyle="italic">Skipped</Text> 
//                     </HoverIconBox>
//                 )
//             } else {
//                 // you can no longer unskip
//                 return <Text fg="really-faded" textStyle="italic">Skipped</Text> 
//             }
//         } else {
//             // Parse error or fetching tempatles error
//             return <Text fg="faded" textStyle="italic">Cannot process</Text>
//         }
//     }

//     // We show a spinner for when we are inserting the tape + searching for the tape.
//     if (tapeImporter.isBeforeOrAtStatus(item, "SEARCHING_TEMPLATES")) {
//         return <Icon name="loader" fg="loud" spinning={!batch.paused} />;
//     }

//     if (status === "NO_TEMPLATES_FOUND") {
//         return (
//             <>
//                 <Text fg="default" fontSize="sm" textStyle="italic">Unknown Tape Format</Text>
//                 &nbsp;&nbsp;
//                 <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Click to begin mapping</Link>
//             </>
//         )
//     }

//     if (status === "TEMPLATES_FOUND" && matchResults) {
//         if (matchResults.length === 1) {
//             // sellers
//             const match = matchResults[0];
//             const { template, aggregatorName } = match;
//             if (aggregatorName) {
//                 // we don't know which org you are.
//                 // we can make a guess based on column data! duh. do we have that?
//                 // match.matchState === "AGG_TEMPLATE_BUT_MUTLIPLE_SELLERS_IN_TAPE"

//             } else {
//                 // non-agg means one-and-only one in orgIds (we've already ensured sane template)
//                 // we KNOW who you are!
//                 const seller = sellers.getSellerById(template.orgIds[0]);
//                 if (!seller) throw new Error('should not be possible--template passed sanity test.');
//                 return (
//                     <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
//                         <FlexVCenter>
//                             <Box fg="default">{ seller.name }</Box>
//                         </FlexVCenter>
//                     </HoverIconBox>
//                 )
//             }
//             return (
//                 <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
//                     <FlexVCenter>
//                         <Box fg="default">todo: seller name here</Box>    
//                         { aggregatorName && <Box ml="1" fg="faded">(via { aggregatorName })</Box> }
//                     </FlexVCenter>
//                 </HoverIconBox>
//             )
//         } else {
//             // multiple
//             return (
//                 <FlexVCenter fontSize="sm">
//                     <Text fg="default" fontSize="sm" textStyle="italic">Multiple Sellers Found</Text>
//                     &nbsp;&nbsp;
//                     <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Input Required</Link>
//                 </FlexVCenter>
//             )
//         }
//     }

//     if (status === "TEMPLATE_ASSIGNED_AND_CONFIRMED" && assigned && batch.step === "UI_CONFIRM_TEMPLATES") {
//         const { aggregatorName } = assigned;
//         return (
//             <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
//                  <FlexVCenter>
//                     <Box fg="loud">TODO: seller name</Box>    
//                     { aggregatorName && <Box ml="1" fg="faded">(via { aggregatorName })</Box> }
//                 </FlexVCenter>
//             </HoverIconBox>
//         )
//     }

//     if (tapeImporter.isAfterOrAtStatus(item, "TEMPLATE_ASSIGNED_AND_CONFIRMED")) {
//         // At this point, seller can no longer be changed.
//         if (assigned) {
//             const { aggregatorName } = assigned;
//             // const { sellerName, aggregatorName } = tapeImporter.getSellerNames(assigned);
//             if (status === 'SUCCESS') {
//                 return (
//                     <FlexVCenter>
//                         <Link onClick={() => onLoad(item)}>TODO: seller name!</Link>    
//                         { aggregatorName && <Box ml="1" fg="faded">(via { aggregatorName })</Box> }
//                     </FlexVCenter>
//                 )
//             } else {
//                 return (
//                     <FlexVCenter>
//                         <Box fg="default">TODO: sellername</Box>    
//                         { aggregatorName && <Box ml="1" fg="faded">(via { aggregatorName })</Box> }
//                     </FlexVCenter>
//                 )
//             }
            
//         } else {
//             return <>None</>
//         }
//     }

//     // If you get here, you have fallen through the cracks.
//     return <>Error</>
// }


