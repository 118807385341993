import React from 'react';
import { Box, FlexVCenter, Icon, IconButton } from 'mui';
import { BasicSpacingProps } from "../box/box"
import { IconName } from "../icon/icon"

interface Props extends BasicSpacingProps {
	readonly children:any;
	readonly onClose?:() => void;
	readonly disableClose?:boolean; // but still show it
	readonly icon?:IconName;
}

export const CardTitle = ({ children, disableClose, onClose, icon, ...props }: Props) => (
	<FlexVCenter fg="loud" fontSize="xl" mb="2" {...props}>
		{ icon && <Icon name={icon} mr="1"/> }
		<Box flex="1">{children}</Box>
		{ onClose && (
			<FlexVCenter fontSize="md">
				<Icon
					mr="1"
					name="close"
					fg={disableClose ? 'faded' : 'default'}
					fgHover={disableClose ? 'faded' : 'loud'}
					cursor={disableClose ? 'default' : 'pointer'}
					onClick={disableClose ? undefined : onClose}
					inline
				/>
			</FlexVCenter>
		) }
	</FlexVCenter>
)


/*
justifyContent="space-between"  
    { onClose && <IconButton name="close" onClick={onClose} floatRight /> }
*/
