import React, { useState, useEffect, forwardRef } from 'react';
import { InputProps, NumericOptions } from './index';
import { getCss } from '../getCss';

// TODO: numeric input takes up most the logic here. SPLIT IT OUT INTO ITS OWN COMPONENT PLEASE.

/*
    You might be tempted to use <input type="number" />
    I gave up on it.
    A few reasons: 
        - min and max are only enforced when clicking the step up/down buttons, not when typing
        - onChange doesn't always fire ("0." will not fire on dot, yet leading 0s will fire!)
    So, type is "text" and we handle the number-ish-ness.
    -jeff
*/

const isPaddedZeros = (str:string, decimalChar:string):boolean => {
    const [_, decimals] = str.split(decimalChar);
    return decimals && decimals.match(/0+$/) ? true : false;
}

const isAtDelimiter = (num:number, str:string, decimalChar:string):boolean => {
    if (str.length < 1) return false;
    const lastIndex = str.length - 1;
    const char = str[lastIndex];
    return char === decimalChar && str.indexOf(char) === lastIndex;
}

const isIntermediateValue = (num:number, trimmed:string, numeric:NumericOptions) => {
    return (
        (numeric.min !== undefined && num < numeric.min) ||
        trimmed === '-' ||
        !numeric.int && isAtDelimiter(num, trimmed, '.') ||
        isPaddedZeros(trimmed, '.')
    )
}

const enforceMinAndMax = (v:number, numeric:NumericOptions):number => {
    if (numeric.hasOwnProperty('min') && v < numeric.min!) return numeric.min!;
    if (numeric.hasOwnProperty('max') && v > numeric.max!) return numeric.max!;
    return v;
}

const trimStr = (raw:string, numeric:NumericOptions) => {
    return numeric.int ? raw.replace(/\./g, '').trim() : raw.trim();
}


export const BasicInput = forwardRef((props:InputProps, ref:React.Ref<HTMLInputElement>) => {
    const {
        cy,
        visibleWhenReadOnly,
        value,
        placeholder,
        readonlyPlaceholder,
        autoSelect,
        onBlur,
        onChange,
        setValue,
        onEnter,
        onPaste,
        asPassword,
        numeric,
        width,
        align,
        size,
        hideForHeight,
        ...rest
    } = props;


// export const BasicInput = ({
//     value,
//     placeholder,
//     readonlyPlaceholder,
//     onBlur,
//     onChange,
//     setValue,
//     onEnter,
//     onPaste,
//     asPassword,
//     numeric,
//     width,
//     align,
//     size,
//     ...rest
// }:InputProps) => {

    // TODO: Split this into two: basic input and numeric input.
    // This internal state, useEffect, is only for numeric.

    const getInitStrValue = (x:string | number | undefined) => x === undefined || x === null ? '' : String(x);

    const [ strValue, setStrValue ] = useState<string>(() => getInitStrValue(value));

    useEffect(() => {
        setStrValue(getInitStrValue(value));
    }, [value]);

    const next = (e:null | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, val:any) => {
        if (setValue) setValue(val);
        if (e && onChange) onChange(e);
    }

    const numericChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, numeric:NumericOptions) => {

        const raw = e.target.value;
        const trimmed = trimStr(raw, numeric);
        const num = numeric.int ? Number.parseInt(trimmed) : Number.parseFloat(trimmed);

        if (trimmed === '') {
            setStrValue('');
            // I commented this out at some point. I really wanna know why:
            // next(e, numeric.hasOwnProperty('default') ? numeric.default : null);
            // Making a guess here...that it would suddenly pop up with a value when user was trying to simply clear field
            // to start over. so, we'll go immediately to null and only to default on blur!
            // June 9th 2021
            next(e, null); // tell parent null, only default (if defined) on blur/submit
            return;
        }

        if (isIntermediateValue(num, trimmed, numeric)) {
            // console.log('intermediate value--allow it local state only');
            setStrValue(trimmed);
        } else if (!isNaN(num) && num !== value) {
            // console.log('valid number, different than before!');
            next(e, num);
        } else if (trimmed != strValue) {
            // parseInt and parseFloat are way more forgiving than isNaN
            // So, we test the string again with isNaN, which is a much more strict check.
            // parse will say "1abc" is 1, but isNaN will say 1abc is NaN.
            if (!isNaN(+trimmed)) setStrValue(trimmed);
        }
    }

    const blur = () => {
        if (!numeric) {
            if (onBlur) onBlur();
            return;
        }
        const raw = strValue;
        const trimmed = trimStr(raw, numeric);
        if (trimmed === '') {
            next(null, numeric.hasOwnProperty('default') ? numeric.default : null);
            if (onBlur) onBlur();
            return;
        }
        const num = numeric.int ? Number.parseInt(trimmed) : Number.parseFloat(trimmed);
        if (isNaN(num)) {
            next(null, null);
            setStrValue('');
            if (onBlur) onBlur();
            return;
        }
        const boundedVal = enforceMinAndMax(num, numeric);
        if (boundedVal !== num) {
            next(null, boundedVal);
            if (onBlur) onBlur();
            return;
        }
        setStrValue(getInitStrValue(num));
        if (onBlur) onBlur();
    }

    const change = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (numeric) return numericChange(e, numeric);
        // 99% of the time we just want the value. So use setValue!
        next(e, e.target.value);
    }

    const keyDown = (e:React.KeyboardEvent) => {
        if (onEnter && e.key === 'Enter') onEnter();
    }

    const focus = (e:React.FocusEvent<HTMLInputElement>) => {
        if (autoSelect) e.target.select();
    }

    const type = asPassword ? 'password' : numeric ? 'text' : 'text'; // WAS number BUT THAT DOES WEIRD ONCHANGE STUFF
    const step = numeric?.step;
    const min = numeric?.min;
    const max = numeric?.max;
    const ph = rest.readOnly && readonlyPlaceholder ? readonlyPlaceholder : placeholder;

    // Build an object of conditionally declared properties to reuse getCss!
    const cssObj = {
        // ...(width && {width: width}),
        // ...(align && {align: align}),
        // ...(size && {size: size}),
        width,
        align,
        size,
        hideForHeight,          // GET RID OF THIS
        visibleWhenReadOnly
     }
    const css = `input ${getCss(cssObj)}`;

    return (
        <input
            ref={ref}
            data-cy={cy}
            // value={value === null || value === undefined ? '' : value}
            value={numeric ? strValue : (value ?? '')}
            className={css}
            placeholder={ph}
            type={type}
            spellCheck={false}
            // numeric stuff
            step={step}
            min={min}
            max={max}
            // events
            onFocus={focus}
            onKeyDown={keyDown}
            onChange={change}
            onPaste={onPaste}
            onBlur={blur}
            // everything else
            {...rest}
        />
    )
})
