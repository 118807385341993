import React, { useState, useEffect } from 'react';
import { Box, Input, Button, FlexVCenter, Text, Icon, modal } from 'mui';
import { rule } from 'services';
import { GQL } from 'market-dto';
import { useZStateList } from 'hooks';

interface Props {
    readonly item:GQL.Checklist;
}
export const CloneChecklistModal = ({ item }:Props) => {

    const [ name, setName ] = useState('');
    const { updating } = useZStateList(rule.zChecklists); // already fetched it or we couldn't be here.

    const go = async () => {
        if (name.trim() === '') return;
        const { id:omit, ...clist } = item;
        await rule.zChecklists.apiInsertListItem({
            ...clist,
            name
        })
        modal.close();
    }

    return (
        <>
            <Box mt="2">
                <Input
                    value={name}
                    setValue={setName}
                    placeholder="New Checklist Name"
                    onEnter={go}
                />
            </Box>
            <Box mt="2" textAlign="right">
                <Button
                    busy={updating}
                    type={name.trim() === '' ? 'disabled' : 'default'}
                    onClick={go}
                >Clone</Button>
            </Box>
        </>

    )
}