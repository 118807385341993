import React from 'react';
import { tapeImporter } from 'services';
import { Text, HoverIconBox } from 'mui';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
}
export const Skipped = ({ batch, item }:Props) => {

    const { matchResults } = item;

    if (batch.step === 'UI_CONFIRM_TEMPLATES' && matchResults) {
        // you may still un-skip!
        return (
            <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
                <Text fg="really-faded" textStyle="italic">Skipped</Text> 
            </HoverIconBox>
        )
    }
    return <Text fg="really-faded" textStyle="italic">Skipped</Text>
}