import React from 'react';
import { Box, Text, FlexVCenter, Icon } from 'mui';
import { NewFlowBlockItem } from './types';

interface BlockHeaderProps {
    readonly children:React.ReactNode;
    readonly onClick?:() => void;
}
const BlockHeader = ({ children, onClick }:BlockHeaderProps) => (
    <Box
        onClick={onClick}
        bg="card"
        style={{ width:'140px', height: '80px' }}
        p="1"
        m="1"
        fontSize="sm"
        roundedEdges="all"
        justifyContent="center"
    >{ children }</Box>
)

interface Props {
    readonly items:NewFlowBlockItem[];
}
export const NewFlowBlocks = ({ items }:Props) => {
    return (    
        <>
            <FlexVCenter>
                { items.map((item, n) => (
                    <React.Fragment key={n}>
                        <BlockHeader key={n} onClick={item.onClick}>
                            <FlexVCenter>
                                <Text fg="faded">{n+1}.</Text>
                                &nbsp;
                                <Text fg="default">{item.label}</Text>
                            </FlexVCenter>
                        </BlockHeader>
                        { n < items.length-1 && <Icon name="arrow-right" fg="faded" /> }
                    </React.Fragment>
                )) }
            </FlexVCenter>
        </>
    )
}