import { labels, LOAN_FIELDS, sheetMaker } from 'market-dto';
import { downloadFromArrayBuffer } from '../downloader';
import { format } from 'services';
import * as api from 'api';

export const genMergedTapeSheet = async (yyyymmdd?:string):Promise<number> => {
    // yyyymmdd CAN BE EMPTY (it means today), so, parse and then format again.
    const dt = yyyymmdd ? format.yyyymmddToDate(yyyymmdd) : new Date();
    const prefix = format.dateToYyyyMmDd(dt);

    // Now we are comfortable to pull all loans
    // It si quite possible later we need to pull more than just "loan",
    // i.e., we need to pull offers as well. Just join the offers and loans
    // At that time, we may need to create "view" somewhere

    const loans = await api.pullLoans(prefix);
    if (!loans) return 0;

    const headers = LOAN_FIELDS.map(fieldId => labels.byLoanField(fieldId as any));
    const rows = loans.map(loan => {
        return LOAN_FIELDS.map(fieldId => {
            const val = (loan as any)[fieldId] ?? null;
            return Array.isArray(val) ? val.join(', ') : val;
        })
    })

    const arrBuf = await sheetMaker.makeSheet([ headers, ...rows ], "Loans");
    const filename = prefix + '-loans.xlsx';
    downloadFromArrayBuffer(arrBuf, filename);
    return loans.length;
}
