import React from 'react';
import { Box, FlexVCenter, Heading, Button, IconButton } from 'mui';

interface Props {
    readonly title:string;
    readonly editting?:boolean;
    readonly dirty:boolean;
    readonly saving:boolean;
    readonly cancel:()=>any;
    readonly save:()=>any;
    readonly edit?:()=>any;
    readonly renderBelow?:React.ReactNode;
    readonly hideBorder?:boolean;
}
export const ConfigHeader = ({ hideBorder, title, editting, dirty, cancel, save, edit, saving, renderBelow }:Props) => {

    const showEditButton = edit && !editting;
    const showCancelAndSave = dirty || editting;

    return (
        <Box pb="1" borderSides={hideBorder ? undefined : 'bottom'} borderColor="alert" borderWidth="thick">
            <FlexVCenter>
                <Box fg="default" fontSize="lg">{ title }</Box>
                <FlexVCenter flex="1" justifyContent="flex-end" height="btn-row">
                    { showCancelAndSave && (
                        <>
                            <Button size="sm" type="link" onClick={cancel} mr="1">Cancel</Button>
                            <Button size="sm" type={dirty ? 'default' : 'disabled'} busy={saving} onClick={save}>Save</Button>
                        </>
                    )}
                    { showEditButton && (
                        <Button size="sm" type="link" onClick={edit}>Edit</Button>
                    )}
                </FlexVCenter>
            </FlexVCenter>
            { renderBelow }
        </Box>
    )
}