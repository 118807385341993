import React, { useState } from 'react';
import { Flex, Input, FrmInput, Box, SpinLabel, FlexVCenter, Button, BoundFrmInput } from 'mui';
import { zState, utils } from 'services';
import { useZState, useZStateList } from './_h';

interface TestThing {
    readonly name:string;
    readonly age:number;
    readonly pig?:string;
}

// option to fetch once, so we can do "ensureLoaded" or something.
// instead of hoisting it.
// that way, doesn't matter where you are, and will stop needless refreshes.

const sampleZ = zState.createZState({
    zid:'jeff',
    apiFetch: async ():Promise<TestThing> => {
        await utils.sleep(500);
        return {
            age: 354,
            name: 'Old Man:'
        }
    }
})

const sampleZList = zState.createZStateList({
    apiFetchList: async ():Promise<TestThing[]>  => {
        await utils.sleep(1500);
        return [{
            age: 354,
            name: 'Old Man:'
        }, {
            age: 123,
            name: 'Young Man',
            pig: 'dsaf'
        },{
            age: 456,
            name: 'har har Man',
            pig: 'ewew'
        }]
    },
    apiUpdateListItem: async (x:Partial<TestThing>) => {
        await utils.sleep(1500);
        return undefined;
    },
    apiInsertListItem: async (x:Partial<TestThing>) => {
        await utils.sleep(1500);
        return undefined;
    },
    isEqual: (a,b) => a.name === b.name
})

export const Jeff = () => {

    const [ pig, setPig ] = useState<TestThing>({
        name: 'jeff',
        age: 12
    })

    const { dirty, data, fetching } = useZState(sampleZ, {
        fetchArgs: []
    })

    const { editItem, editItemDirty, fetching:loadingList, data:list } = useZStateList(sampleZList, {
        fetchArgs: []
    })

    return (
        <>
        FRM:
        <FrmInput
            label="name"
            value={pig.name}
            setValue={x => setPig({ ...pig, name: x })}
        />
        <FrmInput
            label="pig"
            value={pig.pig}
            setValue={x => setPig({ ...pig, pig: x })}
        />
        <br/><br/>har har
        <BoundFrmInput
            label="name"
            bindTo={[pig, setPig, x => x.name]}
        />
        <BoundFrmInput
            label="pig"
            bindTo={[pig, setPig, x => x.pig]}
        />

        <br/><br/>
        <hr/><hr/>
        <Flex>
            <Box flex="1">
                jeff
                { dirty ? 'FORM IS DIRTY' : 'form NOT dirty' }
                <br/>
                { fetching ? 'FETCHING' : 'not fetching' }
                <br/>
                { data && (
                    <>
                        <FrmInput
                            label="Name"
                            value={data.name}
                            setValue={val => sampleZ.update(x => {
                                return {
                                    ...x,
                                    name: val
                                }
                            })}
                        />
                        <FrmInput
                            label="Age"
                            value={data.age}
                            numeric={{ int: true }}
                            setValue={val => sampleZ.update(x => {
                                return {
                                    ...x,
                                    age: val
                                }
                            })}
                        />
                        <Button
                            onClick={() => sampleZ.reset()}
                            type={dirty ? 'link' : 'disabled'}
                        >Reset</Button>
                    </>
                ) }

            </Box>
            <Box flex="1">
                { loadingList ? 'LOADING LIST' : 'not loading list' }
                { list && (
                    <Box>
                        The list exists.<br/>
                        { list.map((x, n) => (
                            <Box key={n} fgHover="link" cursor="pointer" onClick={() => sampleZList.selectItemByIndex(n) } >
                                click to load { x.name }
                            </Box>
                        )) }
                        <br/><br/>
                        { !editItem && 'No item selected yet.' }
                        { editItem && (
                            <Box>
                                EDIT: { editItem.name }
                                <br/><br/>
                                <FrmInput
                                    label="Name"
                                    value={editItem.name}
                                    setValue={val => sampleZList.updateItem({ name:val })}
                                />

                                <br/><br/>
                                <Button
                                    onClick={() => sampleZList.resetActiveItem()}
                                    type={editItemDirty ? 'link' : 'disabled'}
                                    mr="1"
                                >RESET</Button>
                                <Button onClick={() => sampleZList.clearActiveItem()}>Clear Selected</Button>
                            </Box>
                        ) }
                    </Box>
                ) }
                

            </Box>
        </Flex>
        </>
    )
}

/*
export const current = createZState({
    apiFetch: async (sheetId:string) => {
        const sheet = await api.fetchSheet(sheetId);
        if (!sheet) return undefined;
        // sheet.sheetSummary.sheetStatus
        const pricingInfo = toPricingInfo(sheet);
        const propDetails = canShowProposalTab(sheet) ? await fetchPropDetails(sheetId) : undefined;
        const hitInfo = propDetails ? toHitInfo(propDetails) : undefined;
        const trade = canShowTradeTab(sheet) ? await api.fetchTradeBySheetId(sheetId) : undefined;
        return {
            sheet,
            pricingInfo,
            hitInfo,
            trade
        }
    },
    onFetch: (prev:BigSheet|undefined, curr:BigSheet|undefined) => {
        if (!curr) return;
        const tapeIdChanged = !prev || !prev.sheet || prev.sheet.tapeId !== curr.sheet.tapeId;
        if (tapeIdChanged) {
            actLog("TAPE_LOADED", "INFO", curr.sheet.tapeId, "Sheet loaded");
        }
        // We need to know if offers have changed, and, we cannot rely on amount of offers!
        if (tapeIdChanged || offersChanged(prev!.sheet, curr.sheet)) {
            // any time total amount of offers changes...
            autoCheckAllBestOffers(curr.sheet);
        }
    }
})
*/