import React from 'react';
import { Col } from './adj-table.service';
import { AdjTableColVal } from './adj-table-col-val';


interface Props {
    readonly col:Col;
    readonly rowIndex:number;
    readonly colIndex:number;
    readonly editting:boolean;
    readonly setVal:(val:any, rowIndex:number, colIndex:number) => any;
}
export const AdjTableCol = ({ col, rowIndex, colIndex, editting, setVal }:Props) => {

    const css = [col.type, col.subType, col.val === 100 ? 'ignore' : null].filter(x => x).join(' ');

    return (
        <td
            className={css}
            colSpan={col.colSpan}
            rowSpan={col.rowSpan}
        ><AdjTableColVal
            editting={editting}
            col={col}
            setValue={x => setVal(x, rowIndex, colIndex)}
        />
        {/* <textarea readOnly value={JSON.stringify(col, null, 4)} /> */}
        </td>
    )
}