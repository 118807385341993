import { zModels } from './z-models';
import { toGqlModel } from './to-gql-model';

export const getModelById = (modelId?:string) => zModels.get()?.find(x => x.id === modelId);

export const getGQLModelById = (modelId?:string) => {
    const m = zModels.get()?.find(x => x.id === modelId);
    if (!m) return undefined;
    return toGqlModel(m);
}
