import React from 'react';
import { Box } from '../box/box';
import { Icon, IconProps } from '../icon/icon';

interface PulseIconProps extends IconProps {
    readonly pulseStyle: "primary" | "secondary";
}
export const PulseIcon = ({ fg, fgHover, cursor, pulseStyle, onClick, ...rest}:PulseIconProps) => {
    const css = "pulse-circle pulse-" + pulseStyle;
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pos="relative"
            css="pulse-circle-container"
            cursor={cursor}
            fg={fg}
            fgHover={fgHover}
            onClick={onClick}
        >
            <Icon  {...rest} />
            <div className={css} style={{ animationDelay: '0'}} />
            <div className={css} style={{ animationDelay: '.1s'}} />
        </Box>
    )
}
