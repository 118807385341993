import { GQL, CashWindow } from 'market-dto';
import { isSameDay } from 'date-fns';
import { utils } from 'services';


export const getBaseHoldingInterestItem = (
    offer:GQL.Offer,
    holdingInterestArray:CashWindow.HoldingInterest[]
):CashWindow.HoldingInterest|undefined => {
    const { acquisitionDate, notificationDate } = offer;
    if (!acquisitionDate || !notificationDate) throw new Error('Missing acquisitionDate or notificationDate on freddie offer');
    
    return holdingInterestArray.find(x => {
        return isSameDay(utils.ensureDate(acquisitionDate), x.acquisitionDate)
            && isSameDay(utils.ensureDate(notificationDate), x.target)
    })
}