import { FlatJSONSchemaField, FlatJSONSchemaType } from 'market-dto';

export interface ExpEvalStatus {
    // This is used by the UI as a shortcut
    readonly isBlank:boolean;
    readonly validSyntax:boolean;
    readonly canEval:boolean;
    readonly evalResult:ExpEvalResult;
}


export interface ExpOpDef {
    readonly name:string;
    readonly desc?:string;
    readonly compareTypes:ExpValueType[];
    readonly returnType:ExpValueType;
    readonly precedence?:number; // higher means, takes precedence
    readonly evalFn:(a:ExpEvalResult, b:ExpEvalResult) => ExpEvalResult;
}

export type ExpEvalResult = string|number|boolean|Array<string|number|boolean>;

export interface ExpWarning {
    readonly msg:string;
    readonly nodeId?:string;
}

export interface ExpParseResult {
    readonly node:ExpNode | null;
    readonly warnings:ExpWarning[];
}

export const EXP_INVALID_CHAR = "✖";
export const MAX_DISPLAYED_SENSE_OPTIONS = 5;



export type ExpUnaryOp = "-"; // only one for now. maybe forever.
export const EXP_OPS = [EXP_INVALID_CHAR, ">", "<", "!=", "==", ">=", "<=", "or", "and", "-", "+", "/", "*"] as const;
export type ExpOp = typeof EXP_OPS[number];
export const getOperators = () => EXP_OPS.filter(o => o !== EXP_INVALID_CHAR);
export const UNIARY_OPS = ['-']; // forget +, why would we need that?
// THESE ARE NEEDED for knowing when to terminate strings...
export const NON_ALPHA_OPS_FIRST_LETTERS = EXP_OPS
    .filter(x => x !== 'and' && x !== 'or')
    .map(x => x[0]);


export type ExpNode =
    | ExpExpressionNode 
    | ExpConstantNode
    | ExpStringConstantNode
    | ExpIdentifierNode
    | ExpFunctionCallNode
    | ExpParenScopedNode
    | ExpArrayNode
    | ExpUnaryNode
    | ExpListItemNode;

export interface ExpSimpleNode {
    readonly start:number;
    readonly end:number;
    readonly raw:string;
    // MUTABLE, set AFTER PARSING (2nd pass)
    id?:string;      // MUTABLE
    level?:number;   // MUTABLE
    valType?:ExpValueType|ExpValueType[];
    evalsTo?:ExpEvalResult;
    warning?:string;
    getParent?:() => ExpNode|null; // function to allow stringify (circular)
}

export interface ExpParenScopedNode extends ExpSimpleNode {
    readonly type:'ParenScopedNode';
    readonly endParenFound:boolean;
    readonly rawOpenParen:string;
    readonly rawCloseParen:string;
    readonly child:ExpNode;
}

export interface ExpUnaryNode extends ExpSimpleNode {
    readonly type:'UnaryNode';
    readonly op:ExpUnaryOp;
    readonly rawOp:string;
    readonly child:ExpNode;
}

export interface ExpFunctionCallNode extends ExpSimpleNode {
    readonly type:'FunctionCallNode';
    readonly isValidFnName:boolean;
    readonly fnName:string;
    readonly rawFnName:string;
    readonly endParenFound:boolean;
    readonly rawCloseParen:string;
    readonly args:ExpListItemNode[];
}

export interface ExpListItemNode extends ExpSimpleNode {
    readonly type:'ListItemNode';
    readonly item:ExpNode;
    readonly hasValidPrepend:boolean;
    readonly rawPrepend:string; // normally, a comma
    readonly prepend:string;
}

export interface ExpArrayNode extends ExpSimpleNode {
    readonly type:'ArrayNode';
    readonly rawOpenBracket:string;
    readonly rawCloseBracket:string;
    readonly endBracketFound:boolean;
    readonly items:ExpNode[];
}

export interface ExpExpressionNode extends ExpSimpleNode {
    readonly type:'ExpressionNode';
    readonly left:ExpNode;
    readonly right:ExpNode;
    readonly op:ExpOp;
    readonly rawOp:string; /* includes spaces */
    readonly isValidOp:boolean;
}

export interface ExpConstantNode extends ExpSimpleNode {
    readonly type:'ConstantNode';
    readonly value:number|string|boolean;
}

export interface ExpStringConstantNode extends ExpSimpleNode {
    readonly type:'StringConstantNode';
    readonly value:string;
    readonly endQuoteFound:boolean;
}

export interface ExpIdentifierNode extends ExpSimpleNode {
    readonly type:'IdentifierNode';
    readonly value:string;
    readonly isValid:boolean;
}









// THESE GET EXPORTED VIA INDEX
// (those in the OTHER type file do NOT)

export interface ExpressionEditorState {
    readonly exp:string;
    readonly isValid:boolean;
    readonly isTotallyValid:boolean; // MOVE TO THIS, it means TOTALLY VALID (no warnings)
    readonly isValidRegardlessOfMathJs:boolean;
    readonly caret:number;
    readonly isValidMathJs:boolean;
    readonly rootNode:ExpNode|null;
    readonly activeNode:ExpNode|null;
    readonly senseOpts:SenseOption[]|null;
    readonly warnings:ExpWarning[];
}

export interface SenseOption {
    readonly value:string;
    readonly type:"field" | "func" | "op" | "enum";
    readonly priority: "match" | "startsWith" | "hasSubStr" | "neither";
}

export interface SenseOptionIndexes {
    readonly start:number;
    readonly active:number;
}


export interface ExpContextField {
    readonly id:string;
    readonly label:string;
    readonly schema:FlatJSONSchemaField;
}

export interface ExpContext {
    readonly useMathJs?:boolean;
    readonly fieldByName:{[x:string]:ExpContextField};
    readonly fieldList:ExpContextField[];
    readonly funcByName:{[x:string]:ExpFunctionDef};
    readonly funcList:ExpFunctionDef[];
}


export type ExpValueType = "string"|"number"|"boolean"|"unknown"; // do NOT distinguish between float/int (enums are string)

export interface ExpFunctionDef {
    readonly name:string;
    readonly desc?:string;
    readonly returnType:ExpValueType;

    // Example: args:["string", "number"], restArgs:["number"]
    // would mean, you must have:  func(a,b, x1, x2, x3, ... xn)
    // where a is string, b is number, x# are all number
    // by convention, if args[0] is string, and restArgs are string, an enum check is done.
    // if args[0] has enums, restArgs string are assumed to be required to be one of those enums.

    readonly args:ExpValueType[][];
    readonly restArgs?:ExpValueType[];
    readonly evalFn?:(...args:any[]) => any; // typing unimportant for run-time eval fn
}


// This is used outside of exp but handy.
export interface ExpFieldWithValue extends ExpContextField {
    readonly value:any;
    readonly type:FlatJSONSchemaType | "enum"; // distintuish between string and enum for this
}