import React from 'react';
import { Box, Text } from 'mui';

interface Props {
    readonly ineligibleReasons:string[]|null;
}
export const NoOffers = ({ ineligibleReasons }:Props) => {

    // No Offers doesn't mean no offers, it means, no offers to display!
    // There could be invalid offers!
    
    return (
        <Box
            p="2"
            m="2"
            style={{ borderStyle: 'dashed' }}
            borderColor="alert"
            roundedEdges="all"
            borderSides="all"
            borderWidth="thick"
            fg="faded"
            textAlign="center"
        >
            { !ineligibleReasons ? 'No Offers' : (
                <>
                    <Text fg="danger">This loan is ineligible for the following reason{ ineligibleReasons.length > 1 ? 's' : ''}:</Text>
                    <Box mt="1">
                        { ineligibleReasons.map((x,n) => <Box key={n}>{ x }</Box> )}
                    </Box>
                </>
            ) }
        </Box>
    )
}