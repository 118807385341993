import React, { useState, useEffect } from 'react';
import { PasteHits } from './paste-hits';
import { proposal } from 'services';
import { Alert } from 'mui';
import { useZState } from 'hooks';
import { useNavigate } from 'react-router-dom';

const resultHasLoans = (x:proposal.HitSearchState|undefined) => {
    return x && x.hitInfo.proposalDetails.loans.length > 0;
}

export const ImportHits = () => {

    const navigate = useNavigate();
    // const { data:results } = useZState(proposal.searchState);
    const [ hitInfo, setHitInfo ] = useState<proposal.HitInfo>();

    const reset = () => {
        // proposal.searchState.update(x => undefined);
        setHitInfo(undefined);
    }

    // useEffect(() => {
    //     if (resultHasLoans(results)) {
    //         console.log('redirect to cuz rresults', results);
    //         navigate('/tape/' + results!.hitInfo.sheetSummary.sheetId + '/confirmation/import');
    //     }
    // }, [results])
    // { emptyProposal && <Alert icon="alert-triangle">Warning: Proposal found without loans</Alert> }
    // const emptyProposal = results && !resultHasLoans(results);
    const showDebugMsg = false; // urls.isLocalhost;

    return (
        <>
            { showDebugMsg && (
                <Alert>
                    STEPS TO SETUP FOR DEV<br/>
                    1. import tape<br/>
                    2. run pricing and finalize<br/>
                    3. copy from console log list of loan numbers<br/>
                    4. paste those into import hits, submit<br/>
                    5. copy paste from textarea below, save to _sampe-data.ts<br/>
                    6. now go to "hit debug" route, will reflect changes without addHits api call<br/>
                    {/* <textarea readOnly value={'export const data = ' + JSON.stringify(results, null, 4)}></textarea> */}
                </Alert>
            ) }
            
            <PasteHits />
        </>
    )
}