import { BehaviorSubject } from 'rxjs';
import { openConfirmModal } from './confirm-modal-container';
// import { Width, BgColor } from '../../types';

export interface ModalCfg {
    readonly render:React.ReactNode;
    readonly title?:string;
    readonly size?:"sm" | "md" | "lg" | "xl";
    // readonly applyHeaderBg?:boolean;
    readonly noHeaderBg?:boolean;
    readonly static?:boolean;
    readonly closeOnEsc?:boolean; // undefined counts as TRUE (which is a little strange)
    readonly hideCloseIcon?:boolean;
    readonly fixAtMaxHeight?:boolean;
    readonly maxHeight?: "md" | "lg"; // default to lg
    readonly disableClose?:boolean; // but still show it
    readonly onClose?:() => void;
}

const _modal$ = new BehaviorSubject<ModalCfg | null>(null);

// Change modal cfg properties while open
export const setModalCfg = (cfg:Partial<ModalCfg>) => {
    const curr = _modal$.getValue();
    if (!curr) return;
    _modal$.next({
        ...curr,
        ...cfg
    })
}

const disableClose = () => setModalCfg({ disableClose:true })
const enableClose = () => setModalCfg({ disableClose:false })

const closeModal = () => {
    const curr = _modal$.getValue();
    document.body.classList.remove('body-open-modal');
    document.body.classList.remove('modal-scroll-fix');
    _modal$.next(null);
    if (curr && curr.onClose) curr.onClose();
}

const openModal = (cfg:ModalCfg) => {
    if (cfg === null) {
        closeModal();
        return;
    }
    const hasVerticalScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
    // console.log(hasVerticalScrollbar, document.documentElement.scrollHeight, document.documentElement.clientHeight);
    document.body.classList.add('body-open-modal');
    if (hasVerticalScrollbar) document.body.classList.add('modal-scroll-fix');
    _modal$.next(cfg);
}

export const modal = {
    open: openModal,
    close: closeModal,
    disableClose,
    enableClose,
    confirm: openConfirmModal
}

export const modal$ = _modal$.asObservable();
