import React from 'react';
import { Box, FlexVCenter, Icon } from 'mui';

interface Props {
    readonly reasons:string[];
}
export const InvalidJson = ({ reasons }:Props) => {
    return (
        <>
            <Box mb="1" fontSize="lg" fg="loud">Invalid JSON</Box>
            { reasons.map((x, n) => (
                <FlexVCenter key={n} fg="default" fontSize="sm">
                    <Icon name="alert-triangle" fg="accent" />
                    <Box ml="1">{ x }</Box>
                </FlexVCenter>
            )) }
        </>
    )
}