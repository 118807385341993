// For now, we hardcode these. Also, why is agency simply called Agency?
// There must be a better way. API should tell me what these are upon logging in, as well as other essential stuff.
export const AGENCY_NAME = "Agency";
export const AGENCY_ID = "ck6az274k00052rojf3eteugs";
export const WF_NAME = "Wells Fargo";
export const WF_ID = "ck6az1oxr00032roj5erqgjbr";
export const FN_NAME = "FNMA(X)"; // this is debugging stuff to check against Big Sheet results
export const FN_ID = "ck6az274k00052rojf3eteugs";
export const FREDDIE_NAME = "Freddie";
export const FREDDIE_ID = "ckgwdr7qd000001mmaya5hzpy";
