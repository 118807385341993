import React, { useState, useEffect } from 'react';
import { modal, SpinLabel, IntoPortal, Button, Box, Text, FlexVCenter, Flex } from 'mui';
import { TopSummary } from './top-summary';
import { AboveCardsButtonSection } from './above-cards-button-section';
import { useApiFetch, useZState } from 'hooks';
import * as api from 'api';
import { sellers, utils, proposal, marketSettings } from 'services';
import { HitCards } from './cards/hit-cards';
import { CreateTradeModal } from './modals/create-trade-modal';
import { SetupErrorModal } from './modals/setup-error-modal';
import { DebugMsg } from 'components';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly onTradeCreated:() => void;
    readonly defaultShowHitsOnly?:boolean;
}
export const ProposalView = ({ hitInfo, onTradeCreated, defaultShowHitsOnly }:Props) => {

    const [ info, setHitInfo ] = useState<proposal.HitInfo>(() => hitInfo);
    const [ hitsOnly, setHitsOnly ] = useState(defaultShowHitsOnly ? true : false);
    const [ customizing, setCustomizing ] = useState(false);
    const [ optFields, setOptFields ] = useState<Array<keyof proposal.HitLoan>>(() => proposal.getOptFieldsFromStorage());
    const settings = marketSettings.getMarketSettings();

    // TODO: move this stuff into pricing folder under services (pull, refresh)
    const { load, loading:initLoading } = useApiFetch(async () => {
        // pulls data from server cache
        const investorProductIds = utils.toDistinct(info.loans.map(loan => loan.investorProductId!).filter(x => x));
        const priceSheet = await api.fetchCashWindow(investorProductIds);
        if (priceSheet) {
            setHitInfo(proposal.updateWithRealtimeCashwindow(info, priceSheet));
        }
    })
    const { load:forceRefresh, loading:refreshing } = useApiFetch(async () => {
        // forces refresh of server cache and pulls
        const investorProductIds = utils.toDistinct(info.loans.map(loan => loan.investorProductId!).filter(x => x));
        const priceSheet = await api.refreshCashWindow(investorProductIds);
        if (priceSheet) setHitInfo(proposal.updateWithRealtimeCashwindow(info, priceSheet));
    })

    useEffect(() => {
        // on init page load, we wanna fetch cash window
        load();
    }, [])

    useEffect(() => {
        proposal.setOptFieldsStorage(optFields);
    }, [optFields])
    
    const refreshingCashWindow = initLoading || refreshing;
    const { loans } = info;
    const displayLoans = hitsOnly ? loans.filter(loan => info.hitByLoanId[loan.id]) : loans;
    const canContinue = hitInfo.proposalDetails.status === 'HIT' && hitInfo.sheetSummary.sheetStatus === 'TRADING';

    const toggleChecked = (id:string, flag:boolean) => {
        setHitInfo({
            ...info,
            checkedByLoanId: {
                ...info.checkedByLoanId,
                [id]: flag
            }
        })
    }

    const moveField = (fromId:string, toId:string) => {
        console.log('move field from', fromId, 'to', toId);
        const from = optFields.findIndex(x => x === fromId);
        const to = optFields.findIndex(x => x === toId);
        setOptFields(utils.moveItem(optFields, from, to));
    }

    const addField = (fieldId:keyof proposal.HitLoan) => {
        setOptFields(optFields.concat(fieldId));
    }

    const removeField = (fieldId:keyof proposal.HitLoan) => {
        setOptFields(optFields.filter(x => x !== fieldId));
    }

    const openContinueModal = () => {
        const crEnv = settings ? settings['cleanroom.environment'] as string : '';
        const seller = sellers.getSellerById(info.proposalDetails.sellerOrgId);
        const { externalIdSource, externalId } = seller ?? {};
        if (crEnv.trim().toLowerCase() !== externalIdSource?.trim().toLowerCase() || !externalId) {
            console.log('env stuff:', crEnv, externalIdSource, externalId);
            modal.open({
                title: 'Create Trade - ' + info.proposalDetails.sellerName,
                static: true,
                size: "lg",
                render: <SetupErrorModal
                    crEnv={crEnv}
                    sellerEnv={externalIdSource}
                    sellerEnvId={externalId}
                    sellerName={info.proposalDetails.sellerName}
                />
            })
        } else {
            modal.open({
                title: 'Create Trade - ' + info.proposalDetails.sellerName,
                hideCloseIcon: true,
                static: true,
                size: "lg",
                fixAtMaxHeight: true,
                maxHeight: "md",
                render: <CreateTradeModal
                    hitInfo={hitInfo}
                    crEnv={crEnv}
                    hitLoanCount={loans.filter(loan => info.hitByLoanId[loan.id]).length}
                    counts={info.counts}
                    onTradeCreated={onTradeCreated}
                />
            })
        }
    }

    // We are relying on sellers and settings being loaded.
    // if (sellers.zSellers.isBusy()) return <SpinLabel my="2">Loading...</SpinLabel>

    return (
        <>                
            <AboveCardsButtonSection
                hitInfo={info}
                hitsOnly={hitsOnly}
                setHitsOnly={setHitsOnly}
                setCustomizing={setCustomizing}
                customizing={customizing}
                onRefresh={forceRefresh}
                refreshingCashWindow={refreshingCashWindow}
            />

            <HitCards
                items={displayLoans}
                optionalFields={optFields}
                toggleChecked={toggleChecked}
                moveField={moveField}
                onAdd={addField}
                onRemove={removeField}
                hitInfo={info}
                customizing={customizing}
                refreshingCashWindow={refreshingCashWindow}
            />

            <DebugMsg>
            Proposal Status:{ info.proposalDetails.status }</DebugMsg>
            
        </>
    )
}

