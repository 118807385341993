import { util } from "market-dto"
import { IConstruct, IQualifier } from "raccoon-engine"
import { BehaviorSubject, Subject } from "rxjs"
import { filter, map, share, tap } from "rxjs/operators"
import { installAndRetrieve, } from "./types"

export type SelectionEvent = { kind:"CONDITION" | "CANDIDATE", id:string }
	| { kind:"RESET", id:undefined }

// const idsOfConstructs = (constructs:IConstruct[]) => {
// 	return constructs.map((c) => c.threshold.id).join('|')
// }

// export const selectEventOps = (all:IConstruct[], unselected:IConstruct[]) => {
// 	let candidates = [...unselected]
// 	return (event:SelectionEvent):IConstruct[] => {
		
// 		if (event.kind === "CONDITION") {
// 			candidates = candidates.filter(c => c.threshold.id !== event.id)
// 			return candidates
// 		}
		
// 		if (event.kind === "CANDIDATE") {
// 			const found = all.find(c => c.threshold.id === event.id)
// 			if (found) {
// 				if (!candidates.some(c => c.threshold.id === event.id)) {
// 					candidates.push(deepClone(found))
// 				}
// 			}
// 			return candidates
// 		}
		
// 		if (event.kind === "RESET") {
// 			// to keep the same reference
// 			candidates.slice(0, candidates.length)
// 			all.forEach(e => candidates.push(e))
// 			return candidates
// 		}
		
// 		return []
// 	}
	
	
// }



const moveFromAtoB = (a:IConstruct[], b:IConstruct[], id:string) =>
{
	const foundInA = a.find(c => c.threshold.id === id)
	const foundInB = b.find(c => c.threshold.id === id)
	if (foundInA && !foundInB) {
		return { a: [...a.filter(c => c.threshold.id !== id)], b: [...b, util.deepClone(foundInA)] }
	} else {
		return { a, b }
	}
}


interface ConstructSnapshot {
	candidates:IConstruct[],
	selected:IConstruct[]
}

const moveAround = (event:SelectionEvent, selected:IConstruct[], candidates:IConstruct[]):ConstructSnapshot =>
{
	const { id, kind } = event
	if (kind === "CONDITION") {
		const { a, b } = moveFromAtoB(candidates, selected, id!)
		return { candidates: a, selected: b }
	}
	if (kind === "CANDIDATE") {
		const { a, b } = moveFromAtoB(selected, candidates, id!)
		return { candidates: b, selected: a }
	}
	return { selected, candidates }
}

const everythingExcept = (everything:IConstruct[], except:IConstruct[]) => {
	const ids = except.map(t => t.threshold.id)
	return everything.filter(c => !ids.includes(c.threshold.id))
}
export const createSelectionHubs = (starter:IQualifier[], everything:IConstruct[]) => {
	// use qualifiers to retrieve current value
	const qualifier$ = new BehaviorSubject<IQualifier[]>(starter)
	let qualifier = [...starter]
	const program$ = new BehaviorSubject<string>("")
	// a simultaneous view of selected and to-be-selected constructs
	const snapshot$ = new Subject<ConstructSnapshot>()
	const candidates$ = new BehaviorSubject<IConstruct[]>(everythingExcept(everything, []))
	const selected$:BehaviorSubject<IConstruct[]> = new BehaviorSubject<IConstruct[]>([])
	const hub$ = new Subject<SelectionEvent>()
	snapshot$.pipe(map((pair:ConstructSnapshot) => pair.candidates),).subscribe(candidates$)
	snapshot$.pipe(map((pair:ConstructSnapshot) => pair.selected)).subscribe(selected$)
	
	hub$.pipe(
		filter(e => e.kind !== "RESET"),
		map((e) => moveAround(e, selected$.getValue(), candidates$.getValue())),
		share()
	).subscribe(snapshot$)
	
	// when user select a new program. we need to make sure there is
	// an associates IQualifier is created
	// and to construct the snapshot$ accordingly
	program$.pipe(
		map(p => installAndRetrieve(qualifier, p).constructs),
		tap(p => console.log(`switch to ${p}`)),
		map(constructs => ({
			selected: constructs,
			candidates: everythingExcept(everything, constructs)
		})),
		share()
	).subscribe(snapshot$)
	
	// update the construct for the current program
	selected$.pipe(
		map((constructs:IConstruct[]) => {
			const nextQualifier = qualifier$.getValue();
			const found = nextQualifier.find(c => c.qualifiedFor === program$.getValue())
			if (found) {
				found.constructs = constructs
			}
			return nextQualifier
		}),
		tap(nextQualifier => {qualifier = nextQualifier})
	).subscribe(qualifier$)
	
	const deleteProgram = (programName:string) => {
		const nextQualifier = qualifier.filter(q => q.qualifiedFor !== programName)
		qualifier = nextQualifier
		// qualifier$.next(qualifier)
		return qualifier.map(q =>q.qualifiedFor).filter(q =>q)
	}
	
	const getCurrentQualifier = () => {return qualifier}
	return { selected$, candidates$, hub$, program$, qualifier$, deleteProgram, getCurrentQualifier }
}
