import React, { useState, useMemo, useEffect } from 'react';
import { Box, SpacingSize } from 'mui';
import { JSONSchema } from 'market-dto';
import { KeyValueDict, deref } from './schema-ui.service';
import { SchemaUiNode } from './node';
import { SchemaUiMainTitle } from './main-title';


interface Props {
    // readonly data:KeyValueDict;
    // readonly columns?:number;
    readonly portalId:string;
    readonly mb?:SpacingSize;

    readonly schema:JSONSchema;
    readonly onSave:() => void;
    readonly saving:boolean;
    readonly dirty:boolean;
    readonly editting:boolean;
    readonly reset:() => void;
    readonly data:KeyValueDict;
    readonly setEditting:(x:boolean) => void;
    readonly onChange:(x:KeyValueDict) => void;
}
export const JsonSchemaUi = ({
    schema:_schema,
    onSave,
    saving,
    portalId,
    mb,
    dirty,
    onChange,
    editting,
    reset,
    data,
    setEditting
}:Props) => {

    const [ schema, setSchema ] = useState<JSONSchema>();
    const [ filter, setFilter ] = useState('');

    useEffect(() => {
        setSchema(deref(_schema));
    }, [_schema])

    if (!schema) return null;
    return (
        <Box mb={mb}>
            <SchemaUiMainTitle
                saving={saving}
                dirty={dirty}
                editting={editting}
                filter={filter}
                onFilterChange={setFilter}
                schema={schema}
                portalId={portalId}
                onEdit={() => setEditting(true)}
                onCancel={() => {
                    reset();
                    setEditting(false);
                }}
                onSave={onSave}
            />
            <Box mt="1">
                <SchemaUiNode
                    data={data}
                    schema={schema}
                    onChange={onChange}
                    editting={editting}
                    filter={filter.trim().toLowerCase()}
                />
            </Box>
        </Box>
    )
}
