import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import * as api from 'api';
import { logActivity } from './log-activity';

export const apiInsertTape = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        switchMap(item => {
            // Do nothing if we didn't successfully parse!
            console.log('a0');
            if (item.status !== "PARSE_SUCCESS") return of(item.index);
            console.log('a1');
            return of(item).pipe(
                logActivity("Inserting tape"),
                updateStatus("INSERTING_TAPE"),
                switchMap(item => {
                    // Note: do not insert base64 or hash. Those come AFTER sanitized.
                    return from(api.insertTape(item.file.name, "", "")).pipe(
                        tap(res => {
                            if (!res) {
                                mergeBatchItem(item, { status: "INSERT_TAPE_FAILURE" });
                            } else {
                                mergeBatchItem(item, {
                                    status: "INSERT_TAPE_SUCCESS",
                                    tapeId: res.id
                                })
                            }
                        }),
                        catchError(err => {
                            mergeBatchItem(item, { status: "INSERT_TAPE_FAILURE" });
                            return of(item.index);
                        }),
                        map(x => item.index)
                    )
                })        
            )
        })
        // logActivity("Inserting tape"),
        // updateStatus("INSERTING_TAPE"),
        // switchMap(item => {
        //     // Note: do not insert base64 or hash. Those come AFTER sanitized.
        //     return from(api.insertTape(item.file.name, "", "")).pipe(
        //         tap(res => {
        //             if (!res) {
        //                 mergeBatchItem(item, { status: "INSERT_TAPE_FAILURE" });
        //             } else {
        //                 mergeBatchItem(item, {
        //                     status: "INSERT_TAPE_SUCCESS",
        //                     tapeId: res.id
        //                 })
        //             }
        //         }),
        //         catchError(err => {
        //             mergeBatchItem(item, { status: "INSERT_TAPE_FAILURE" });
        //             return of(item.index);
        //         }),
        //         map(x => item.index)
        //     )
        // })
    )
}