import { GQL } from 'market-dto';
import { SampleWithModel } from './types';
import { getModelById } from './get-model-by-id';

export const toSamplesWithModels = (samples?:GQL.ModelSample[]):SampleWithModel[] => {
    if (!samples) return [];
    return samples.map((s):SampleWithModel => {
        return {
            sample: s,
            model: getModelById(s.modelId)
        }
    })
}