import { mutate } from "../../../client";
import { gql } from "apollo-boost";
import { GQL } from 'market-dto';
import { FLOW_FIELDS } from './returning';
import { alpha } from 'services';

const UPDATE = gql`
    mutation updateFlow($set: flow_update_input!, $where: where_flow_exp) {
        update_flow(set: $set, where: $where) {
            affectedRow
            errorMessage
            returning { ${ FLOW_FIELDS } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Flow>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_flow: {
        readonly returning:[GQL.Flow];
    }
}

export const updateFlow = async (data:Partial<GQL.Flow>):Promise<GQL.Flow|undefined> => {
    const { id, name, inputStage, outputStage, eligibilityStage, tags } = data;
    if (!id) throw new Error('Expected id on partial flow');
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: {
            // only these can be updated.
            name, inputStage, outputStage, eligibilityStage, tags
        },
        where: {
            id: {
                _eq: id
            }
        }
    })
    const flow = result?.data?.update_flow.returning[0];
    return flow ? alpha.withFlattenedTasks(flow) : undefined;
}
