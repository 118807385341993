import React, { useState } from 'react';
import { Box, FlexVCenter, Format, Icon, IconButton, modal, HighlightText, Text } from 'mui';
import { GQL } from 'market-dto';
import { LogModal } from "./log-modal"
import { sheets } from 'services';
import { DisplayLoanValue } from 'components';
import { AigCompareModal } from './aig-compare-modal';

interface Props {
	readonly loan: GQL.Loan;
	readonly filters:sheets.LoanFilters;
}

export const LoanRow = ({ loan, filters }:Props) => {
	// Parent component has us is a CSS GRID.
	// Because siblings are direct descendants of a css grid, we must have 5 and only 5 containers below.
	// And no root element.
	const [ showMore, setShowMore ] = useState<boolean>(false);
	const toggle = () => setShowMore(!showMore);

	const compareOffers = () => {
		modal.open({
			title: 'Pricing Compare',
			size: 'md',
			render: <AigCompareModal loan={loan} />
		})
	}

	return (
		<>
			<Box roundedEdges="top-left" bg="card" p="2">
				<Box fontSize="lg" fg="loud" fgHover="link" cursor="pointer" onClick={compareOffers}>
					<DisplayLoanValue loan={loan} fieldId="id" filters={filters} />
				</Box>
				<Box fontSize="sm" fg="faded" ucase>Loan ID</Box>
			</Box>
			<Box bg="card" p="2">
				<Box fontSize="lg" fg="loud">
					<DisplayLoanValue loan={loan} fieldId="productCode" filters={filters} />
				</Box>
				<Box fontSize="sm" fg="faded">PRODUCT</Box>
			</Box>
			<Box p="2" bg="card">
				<Box fontSize="lg" fg="loud"><Format as="3_decimals">{loan.noteRate}</Format></Box>
				<Box fontSize="sm" fg="faded" ucase>
					Note Rate

				</Box>
			</Box>
			<Box p="2" bg="card">
				<Box fontSize="lg" fg="loud">
					<Format as="currency">{loan.currentBalance}</Format>
				</Box>
				<Box fontSize="sm" fg="faded">
					LTV <Format as="2_decimals">{loan.ltv}</Format>%
				</Box>

			</Box>
			<FlexVCenter p="2" roundedEdges="top-right" bg="card">
				<Box>
					<Box fontSize="lg" fg="loud">
						<Format as="3_decimals">{loan.dti}</Format>%
					</Box>
					<Box fontSize="sm" fg="faded">DTI</Box>
				</Box>
				<Box flex="1" fg="faded" textAlign="right">
				</Box>
			</FlexVCenter>
		</>
	)
}
