import React from 'react';
import { tapeImporter } from 'services';
import { Box, Link, FlexVCenter, HoverIconBox, Text } from 'mui';

interface Props {
    readonly matchResult:tapeImporter.AggMatchResult;
    readonly item:tapeImporter.BatchItem;
}
export const SingleMatchAgg = ({ matchResult, item }:Props) => {
    
    const { aggregatorName, matchState, sellerOrgsInBoth } = matchResult;
    if (!aggregatorName) throw new Error('Expected aggregatorName');

    // single template match is an agg template!

    if (matchState === 'AGG_EXACTLY_ONE_POSSIBLE_SELLER') {
        return (
            <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
                <FlexVCenter>
                    <Box fg="loud">{ sellerOrgsInBoth[0].name }</Box>    
                    <Box ml="1" fg="faded">(via { aggregatorName })</Box>
                </FlexVCenter>
            </HoverIconBox>
        )
    }

    if (matchState === "AGG_WITH_KNOWN_SELLER_BUT_NOT_IN_TEMPLATE" || matchState === "AGG_COULD_BE_ONE_OF_MULTIPLE_SELLERS") {
        // You could make the argument that these should be allowed to be auto-assigned
        // But we are going to require seller confirmation for now.
        // Also, there could be more than one known seller.
        // Treat it as "multiple" sellers
        return (
            <>
                <Text fg="default" fontSize="sm" textStyle="italic">Multiple Sellers</Text>
                &nbsp;&nbsp;
                <Text fg="faded" fontSize="sm" textStyle="italic">(via { aggregatorName })</Text>
                &nbsp;&nbsp;
                <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Input Required</Link>
            </>
        )
    }

    if (matchState === "AGG_WITH_UNKNOWN_SELLER_IN_TAPE") {
        return (
            <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
                <FlexVCenter>
                    <Box fg="default" fontSize="sm" textStyle="italic">Unknown Seller</Box>
                    <Box mx="1" fg="faded">(via { aggregatorName })</Box>
                    <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Input Required</Link>
                </FlexVCenter>
            </HoverIconBox>
        )        
    }
    // if (matchState === "AGG_TEMPLATE_WITH_UNKNOWN_SELLER_IN_TAPE") {
    //     if (!sellerOrg) throw new Error('expected sellerOrg to exist');

    // }
    throw new Error('Unhandled matchState on a single match agg: ' + matchState);

}