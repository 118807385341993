import React from 'react';
import { BasicSpacingProps, Box } from '../box/box';

interface Props extends BasicSpacingProps {
	readonly children:any;
}

export const ButtonGroup = ({ children, ...props }:Props) => {
	return <Box {...props} css="btn-group">{children}</Box>
}


// import React from 'react';
// import { Box, BoxProps } from '../box/box';
// import styled from '@emotion/styled';
// import * as theme from '../theme';
// import { ColorVariant } from '../theme/types';

// // Chakra has no button group.
// // By button group, i mean, continuous bar of buttons...a la pagination.

// // What color do we make the border? Good question.
// // Let's take from "borderColor" and if not there, "variant"
// // If neither there, use "primary"

// interface StyledButtonGroupProps {
//     readonly border: string;
// }
// // We assume there will be more than one. Else, it'll be dumb.
// const StyledButtonGroup = styled.div<StyledButtonGroupProps>`
//     & > button:first-of-type {
//         border: ${p => p.border};
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//         border-right: 0;
//     }
//     & > button:last-of-type {
//         border: ${p => p.border};
//         border-top-left-radius: 0;
//         border-bottom-left-radius: 0;
//     }
//     & > button:not(:last-of-type):not(:first-of-type) {
//         border: ${p => p.border};
//         border-radius: 0;
//         border-right: 0;
//     }
//     & > button:focus {
//         outline: 0;
//         box-shadow: none;
//     }
// `;
// export const ButtonGroup = ({ children, ...rest }: BoxProps) => {
//     // We MUST have a variant...so we know what dividing border color to use. Default to primary if nothing found
//     const variant: ColorVariant = rest.borderColor
//         ? rest.borderColor
//         : rest.variant
//         ? rest.variant
//         : 'primary';
//     const b = rest.border ? rest.border : '1px solid';
//     const border = b + ' ' + theme.getBoxBorderColorFromVariant(variant);
//     return (
//         <Box {...rest}>
//             <StyledButtonGroup border={border}>{children}</StyledButtonGroup>
//         </Box>
//     );
// };
