import React, { CSSProperties } from 'react';
import { Cursor, TextAlign } from '../../types';

// KEEP THIS TABLE COMPONENT SIMPLE.
// Resist the temptation to add lots of features to it.
// If you want lots of features, make a separate table component.
// Only use this for very simple things.

const renderCols = (
    rowIndex:number,
    cols:any[],
    headers:TableHeader[],
    onClick?:(rowIndex:number, colIndex:number) => void
) => {
    return cols.map((c,n) => (
        <td
            key={n}
            className={headers[n].alignData ?? 'left'}
            onClick={onClick ? () => onClick(rowIndex, n) : undefined}
            // onClick={headers[n].stopPropagation ? e => e.stopPropagation() : undefined}
        >{ c }</td>
    ))
}

export interface TableHeader {
    readonly label: React.ReactNode;
    readonly alignHeader?:TextAlign;
    readonly alignData?:TextAlign;
    readonly style?: CSSProperties;
    readonly onHeaderClick?:() => void;
    readonly stopPropagation?:boolean;
}

const getCursorCss = (n:number, rowCursor?:Cursor | Cursor[]) => {
    if (!rowCursor) return undefined;
    const c = Array.isArray(rowCursor) ? rowCursor[n] : rowCursor;
    return 'cursor-' + c;
}

export interface TableProps {
    readonly cy?:string;
    readonly headers:TableHeader[];
    readonly rows:React.ReactNode[][];
    readonly onClick?:(rowIndex:number, colIndex:number) => void;
    readonly rowCursor?:Cursor | Cursor[]; // either one for all, or, array...one item for each row
    readonly size?: "xs" | "sm" | "md";
}

export const Table = ({ cy, size, rows, headers, rowCursor, onClick }:TableProps) => {
    const cssArr = ['table'];
    if (size === 'sm') cssArr.push('table-sm');
    if (size === 'xs') cssArr.push('table-xs');
    const css = cssArr.join(' ');
    return (
        <table className={css} data-cy={cy}>
            <thead>
                <tr>
                    { headers.map((h, n) => (
                        <th
                            key={n}
                            onClick={h.onHeaderClick}
                            className={h.alignHeader ?? 'left'}
                            style={h.style}
                        >{ h.label }</th>
                    )) }
                </tr>
            </thead>
            <tbody>
                { rows.map((r, n) => (
                    <tr
                        key={n}
                        className={getCursorCss(n, rowCursor)}
                    >{ renderCols(n, r, headers, onClick) }</tr>
                ))}
            </tbody>
        </table>
    )
}
