import React, { useEffect, useState } from 'react';
import { Flex, FlexVCenter, Box, Button, Icon, GradientText, Text, CssGridBox, BgColor, SpacingSize } from 'mui';
import { proposal, format, utils } from 'services';
import { GQL, labels } from 'market-dto';
import { GenericSummaryBar, GenericSummaryGroup } from 'components';

const addDateItem = (g:GenericSummaryGroup, arr:Date[], label:string):void => {
    // THIS MUTATES g.items (and arr...the sort)
    if (arr.length === 1) {
        g.items.push({ top: format.toDate(arr[0]), bottom: label })
    } else if (arr.length > 1) {
        arr.sort(); // now ascending
        g.items.push({
            top: format.toDate(arr[0]) + ' - ' + format.toDate(arr[arr.length-1]),
            bottom: label
        })
    }
}

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly onClose?:() => void;
}
export const TopSummary = ({ hitInfo, onClose }:Props) => {

    const { sheetSummary:summary } = hitInfo;    

    const sum = hitInfo.loans.reduce((prev, curr) => {
        return prev + curr.currentBalance;
    }, 0);

    const acqDates = utils.toDistinct(hitInfo.loans.filter(x => x.acquisitionDate).map(x => x.acquisitionDate)) as Date[];
    const stlDates = utils.toDistinct(hitInfo.loans.filter(x => x.notificationDate).map(x => x.notificationDate)) as Date[];

    const g:GenericSummaryGroup = {
        items: [
            { top: sum, bottom: 'Amount' },
            { top: hitInfo.hitCount, bottom: 'Loans' }
        ]
    }

    addDateItem(g, acqDates, 'Aquisition');
    addDateItem(g, stlDates, 'Settlement');

    const groups:GenericSummaryGroup[] = [
        { items: [
            { top: summary.sheetId, bottom: labels.bySheetSummary('sheetId') },
            { top: summary.sellerName, bottom: labels.bySheetSummary('sellerName') },
        ] },
        g
    ]

    return (
        <GenericSummaryBar
            groups={groups}
            renderBtnArea={(
                <FlexVCenter justifyContent="flex-end" flex="1">
                    <Icon name="close" fg="faded" fgHover="link" onClick={onClose} cursor="pointer" mr="1" />
                </FlexVCenter>
            )}
        />
    )
}
