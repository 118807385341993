import { mutate, gql, GQL } from "api/client";
import { RETURNING } from './returning';

const MUTATE = gql`
    mutation upsertSettlementCalendar($objects: [settlementCalendar_update_input!]!) {
        set_settlementCalendar(objects: $objects) {
            ${ RETURNING }
        }
    }
`;

interface MutateInput {
    readonly objects:Array<Partial<GQL.SettlementCalendar>>;
}

interface MutateOutput {
    readonly set_settlementCalendar: {
        readonly returning:GQL.SettlementCalendar[];
    }
}

export const upsertSettlementCalendar = async (cals:Array<Partial<GQL.SettlementCalendar>>):Promise<GQL.SettlementCalendar[]|undefined> => {
    const calsWithoutIds = cals.map(cal => {
        return {
            ...cal,
            id: undefined
        }
    })
    // const { id, ...withoutId } = cal;
    const result = await mutate<MutateInput, MutateOutput>(MUTATE, { objects: calsWithoutIds })
    return result?.data?.set_settlementCalendar.returning;
    // if (!arr) return;
    // if (arr.length !== 1) return;
    // return arr[0];
}