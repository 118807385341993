import { useEffect, useState, useRef } from 'react';

const copy = <T>(x:T):T => {
    return x === undefined ? undefined : JSON.parse(JSON.stringify(x));
}

export interface UseApiCfg<T> {
    readonly fetch: () => Promise<T | undefined>;
    readonly update?: (x:T) => Promise<any>;
    readonly onPostUpdate?: (x:T) => void;
    readonly deps?:any[];
    readonly initEditting?:boolean;
}

export const useApi = <T = any>(cfg:UseApiCfg<T>) => {
    const isCancelled = useRef(false);
    const [ editObj, setEditObj ] = useState<T>();
    const [ fetchResult, setFetchResult ] = useState<T>();
    const [ fetching, setFetching ] = useState<boolean>(cfg.deps ? true : false);
    const [ updating, setUpdating ] = useState<boolean>(false);
    const [ editting, setEditting ] = useState<boolean>(cfg.initEditting ? true : false);
    const [ dirty, setDirty ] = useState<boolean>(false);

    const reset = () => {
        if (isCancelled.current) return;
        setEditObj(copy(fetchResult))
        setDirty(false);
        setEditting(false);
    }
    useEffect(reset, [fetchResult]);

    const load = async () => {
        if (isCancelled.current) return;
        setFetching(true);
        const result = await cfg.fetch();
        // the order matters here, set data before fetching to false.
        setFetchResult(result);
        setFetching(false);
    }

    const update = async () => {
        if (isCancelled.current) return;
        if (!cfg.update) throw new Error('Expected update function but one was not provided');
        if (!editObj) return;
        setUpdating(true);
        const result = await cfg.update(editObj);
        if (isCancelled.current) return;
        setUpdating(false);
        if (result === undefined) {
            // by convention, undefined means ERROR on update.
            return;
        }
        setFetchResult(editObj); // this will trigger reset
        if (cfg.onPostUpdate) cfg.onPostUpdate(editObj);
        return result;
    }

    // If NOT using the dirty/reset, you can pass data to udpate.
    // this means, you are not using the editObj
    const updateWithData = async (data:T) => {
        if (isCancelled.current) return;
        if (!cfg.update) throw new Error('Expected update function but one was not provided');
        setUpdating(true);
        const result = await cfg.update(data);
        if (isCancelled.current) return;
        setUpdating(false);
        if (result === undefined) {
            // by convention, undefined means ERROR on update.
            return;
        }
        setFetchResult(data); // this will trigger reset
        return result;
    }

    useEffect(() => {
        // Do NOT load unless dependencies were specified
        // (empty array specified means, run onload, obviously)
        if (cfg.deps && !cfg.deps.find(x => !x)) load();
    }, cfg.deps ?? [])

    useEffect(() => {
        return () => {
            isCancelled.current = true;
        }
    }, [])

    return {
        dirty,
        reset,
        refresh:() => load(),
        update,
        updateWithData,
        updating,
        fetching,
        data:editObj,
        editting,
        setEditting,
        onChange: (x:T) => {
            setEditObj(x);
            setDirty(true);
        }
    }
}

// TODO: MERGE useApi and useApi2


// NOW WITH EXPLICIT VIEW MODELS
// Note: it is a shame ts won't identify the "Translated" guy automatically.
// If we add the "= any" thing, we WILL spot the "Raw" automatically...
// but we have to tell it the types explicitily, unfortunately.

export interface UseApiCfg2<Raw, Translated> {
    readonly fetch: () => Promise<Raw | undefined>;
    readonly update: (x:Raw) => Promise<any>;
    readonly preUpdate: (x:Translated) => Raw;
    readonly postFetch: (x:Raw) => Translated;
    readonly deps:any[];
}

export const useApi2 = <Raw, Translated>(cfg:UseApiCfg2<Raw, Translated>) => {
    const isCancelled = useRef(false);
    const [ editObj, setEditObj ] = useState<Translated>();
    const [ fetchResult, setFetchResult ] = useState<Raw>();
    const [ fetching, setFetching ] = useState<boolean>(cfg.deps ? true : false);
    const [ updating, setUpdating ] = useState<boolean>(false);
    const [ editting, setEditting ] = useState<boolean>(false);
    const [ dirty, setDirty ] = useState<boolean>(false);

    const reset = () => {
        if (isCancelled.current) return;
        if (fetchResult) setEditObj(copy(cfg.postFetch(fetchResult)))
        setDirty(false);
        setEditting(false);
    }
    useEffect(reset, [fetchResult]);

    const load = async () => {
        if (isCancelled.current) return;
        setFetching(true);
        const result = await cfg.fetch();
        setFetchResult(result);
        setFetching(false);
    }

    const update = async () => {
        if (isCancelled.current) return;
        if (!cfg.update) throw new Error('Expected update function but one was not provided');
        if (!editObj) return;
        setUpdating(true);
        const raw = cfg.preUpdate(editObj);
        const result = await cfg.update(raw);
        if (isCancelled.current) return;
        setUpdating(false);
        if (result === undefined) {
            // by convention, undefined means ERROR on update.
            return;
        }
        setFetchResult(raw); // this will trigger reset
        return result;
    }

    // If NOT using the dirty/reset, you can pass data to udpate.
    // this means, you are not using the editObj
    const updateWithData = async (data?:Translated) => {
        if (isCancelled.current) return;
        if (!cfg.update) throw new Error('Expected update function but one was not provided');
        if (!data) return;
        setUpdating(true);
        const raw = cfg.preUpdate(data);
        const result = await cfg.update(raw);
        if (isCancelled.current) return;
        setUpdating(false);
        if (result === undefined) {
            // by convention, undefined means ERROR on update.
            return;
        }
        setFetchResult(raw); // this will trigger reset
        return result;
    }

    useEffect(() => {
        // Do NOT load unless dependencies were specified
        // (empty array specified means, run onload, obviously)
        if (cfg.deps && !cfg.deps.find(x => !x)) load();
    }, cfg.deps ?? [])

    useEffect(() => {
        return () => {
            isCancelled.current = true;
        }
    }, [])

    return {
        dirty,
        reset,
        update,
        updateWithData,
        updating,
        fetching,
        data:editObj,
        editting,
        setEditting,
        onChange: (x:Translated) => {
            setEditObj(x);
            setDirty(true);
        }
    }
}



// export interface UseApiCfg3<Raw, Translated> {
//     readonly fetch: () => Promise<Raw | undefined>;
//     readonly update: (x:Raw) => Promise<any>;
//     readonly preUpdate?: (x:Translated) => Raw;
//     readonly postFetch?: (x:Raw) => Translated;
//     readonly deps:any[];
// }

// export const useApi3 = <Raw, Translated>(cfg:UseApiCfg3<Raw, Translated>) => {
//     const isCancelled = useRef(false);
//     const [ editObj, setEditObj ] = useState<Translated>();
//     const [ fetchResult, setFetchResult ] = useState<Raw>();
//     const [ fetching, setFetching ] = useState<boolean>(cfg.deps ? true : false);
//     const [ updating, setUpdating ] = useState<boolean>(false);
//     const [ editting, setEditting ] = useState<boolean>(false);
//     const [ dirty, setDirty ] = useState<boolean>(false);

//     const reset = () => {
//         if (isCancelled.current) return;
//         if (fetchResult) setEditObj(cfg.postFetch ? copy(cfg.postFetch(fetchResult)) : fetchResult as any as Translated)
//         setDirty(false);
//         setEditting(false);
//     }
//     useEffect(reset, [fetchResult]);

//     const load = async () => {
//         if (isCancelled.current) return;
//         setFetching(true);
//         const result = await cfg.fetch();
//         setFetching(false);
//         setFetchResult(result);
//     }

//     const update = async () => {
//         if (isCancelled.current) return;
//         if (!cfg.update) throw new Error('Expected update function but one was not provided');
//         if (!editObj) return;
//         setUpdating(true);
//         const raw = cfg.preUpdate ? cfg.preUpdate(editObj) : editObj as any as Raw;
//         // const raw = cfg.preUpdate(editObj);
//         const result = await cfg.update(raw);
//         if (isCancelled.current) return;
//         setUpdating(false);
//         setFetchResult(raw); // this will trigger reset
//         return result;
//     }

//     // If NOT using the dirty/reset, you can pass data to udpate.
//     // this means, you are not using the editObj
//     const updateWithData = async (data?:Translated) => {
//         if (isCancelled.current) return;
//         if (!cfg.update) throw new Error('Expected update function but one was not provided');
//         if (!data) return;
//         setUpdating(true);
//         const raw = cfg.preUpdate ? cfg.preUpdate(data) : data as any as Raw;
//         const result = await cfg.update(raw);
//         if (isCancelled.current) return;
//         setUpdating(false);
//         setFetchResult(raw); // this will trigger reset
//         return result;
//     }

//     useEffect(() => {
//         // Do NOT load unless dependencies were specified
//         // (empty array specified means, run onload, obviously)
//         if (cfg.deps && !cfg.deps.find(x => !x)) load();
//     }, cfg.deps ?? [])

//     useEffect(() => {
//         return () => {
//             isCancelled.current = true;
//         }
//     }, [])

//     return {
//         dirty,
//         reset,
//         update,
//         updateWithData,
//         updating,
//         fetching,
//         data:editObj,
//         editting,
//         setEditting,
//         onChange: (x:Translated) => {
//             setEditObj(x);
//             setDirty(true);
//         }
//     }
// }