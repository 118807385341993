import React, { useEffect, useState } from 'react';
import { tapeImporter, utils, urls } from 'services';
import { Box, Link, Text, Icon, modal, CssGridBox, FlexVCenter, TextAlign, SpacingSize } from 'mui';
import { ColTapeId } from './col-tape-id';
import { ColFileName } from './col-file-name';
import { ColLoanCount } from './col-loan-count';
import { ColConfirmed } from './col-confirmed';
import { ColContainer } from './col-container';
import { ColEligibleCount } from './col-eligible-count';
import { Expanded } from './expanded/expanded';
import { SellerColumn } from './seller-column'
import { PasswordModal } from './password-modal';
import { TabsModal } from './tabs-modal/tabs-modal';

interface Props {
    readonly nextStep:() => void;
	readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
    readonly n:number;
    readonly onLoad:(x:tapeImporter.BatchItem) => void;
}
export const ImportTableRow = ({ batch, item, n, nextStep, onLoad }: Props) => {
    const colCount = 6;
    // The css grid here is such a special case, we are not generalizing it and making mui component out of it.

    const openPasswordModal = () => {
        modal.open({
            title: 'Tape Password',
            render: <PasswordModal batch={batch} nextStep={nextStep} />
        })
    }

    const openSpecifyTabs = () => {
        modal.open({
            title: 'Specify Tab(s)/Headers/Rows',
            size: 'lg',
            fixAtMaxHeight: true,
            maxHeight: 'lg',
            render: <TabsModal
                item={item}
                batch={batch}
                nextStep={nextStep}
            />
        })
    }

    const needPass = item.status === "PARSE_NEEDS_PASSWORD";
    const needSpecifyTabs = item.status === "PARSE_NEEDS_TABS_HEADERS_ROWS_SPECIFIED";

    return (
        <>
            <ColContainer item={item} isLeft n={n}>
                <ColTapeId item={item} batch={batch} onLoad={onLoad} />
            </ColContainer>
            <ColContainer item={item} n={n}>
                <ColFileName item={item} batch={batch} />
            </ColContainer>
            { needSpecifyTabs && (
                <>
                    <ColContainer item={item} n={n} colSpan={4} isRight fg="faded" fontSize="sm">
                        {/* <Icon name="alert-triangle" fg="accent" inline /> */}
                        {/* &nbsp;
                        <Text fg="accent">Need Tab/Headers Info</Text> */}
                        { batch.step === "UI_SPECIFY_TABS_HEADERS_ROWS" && (
                            <>
                                &nbsp;&nbsp;
                                <Link onClick={openSpecifyTabs}>Specify Tab(s)/Header/Rows to Use</Link>
                            </>
                        ) }
                        
                    </ColContainer>
                </>
            ) }
            { needPass && (
                <>
                    <ColContainer item={item} n={n} colSpan={4} isRight fg="faded" fontSize="sm">
                        <Icon name="alert-triangle" fg="accent" inline />
                        &nbsp;
                        <Text fg="accent">Tape is password protected!</Text>
                        { batch.step === "UI_GET_PASSWORD" && (
                            <>
                                &nbsp;&nbsp;
                                <Link onClick={openPasswordModal}>Provide Password</Link>
                            </>
                        ) }
                        
                    </ColContainer>
                </>
            ) }
            { (!needPass && !needSpecifyTabs) && (
                <>
                    <ColContainer item={item} justifyContent="flex-end" n={n}>
                        <ColLoanCount item={item} batch={batch} />
                    </ColContainer>
                    <ColContainer item={item} justifyContent="flex-end" n={n}>
                        <ColConfirmed item={item} batch={batch} />
                    </ColContainer>
                    <ColContainer item={item} n={n}>
                        <SellerColumn item={item} batch={batch} onLoad={onLoad} />
                    </ColContainer>
                    <ColContainer item={item} justifyContent="flex-end" isRight n={n}>
                        <ColEligibleCount item={item} batch={batch} />
                    </ColContainer>
                </>
            ) }

            { item.expanded && (
                <Box style={{ gridColumn: 'span ' + colCount }}>
                    <Expanded item={item} nextStep={nextStep} />
                </Box>
            ) }
        </>
    )
}
