import React from 'react';
import { Box, Alert } from 'mui';
import { mapping } from 'services';
import { TransEnum } from './trans-enum';
import { TransNonEnum } from './trans-non-enum';
import { OverrideItem, GQL, TransCfg, ModelItem, loanModel, format } from 'market-dto';

const toDistinct = (arr:string[]) => Array.from(new Set(arr));

interface Props {
    readonly ride:OverrideItem;
    readonly editField:mapping.Field;
    readonly fieldCfg:ModelItem.AnyType;
    readonly canEdit:boolean;
    readonly items:mapping.ColumnValuesAndMapping[];
    readonly onChange:(transCfg:TransCfg) => void;
}
export const TabTranslation = ({ ride, editField, fieldCfg, items, canEdit, onChange }:Props) => {

    if (!editField.cfg) throw new Error('Even derived fields MUST have base cfg entries if they are to have translation tab.');
    const transCfg = editField.cfg.translate;

    const mapsToIndex = editField.res.winner;
    if (mapsToIndex === -1) {
        return (
            <>
            <Alert icon="alert-triangle" m="2">
                This field is not yet mapped!<br/>
                Once mapped, you'll be able to modify translation values.
            </Alert>
            {/* <textarea readOnly value={JSON.stringify(field, null, 4)}></textarea> */}
            </>
        )
    }

    const dirtyCheck = () => {
        if (!canEdit) {
            return (
                <Alert icon="alert-triangle" m="2">
                    You have unsaved mapping changes.
                    Once those are saved, you'll be able to modify translation values.
                </Alert>
            )
        }    
    }

    const mappedToColName = items[mapsToIndex].colName;
    const mappedToValues = items[mapsToIndex].values;
    const distinctValues = toDistinct(mappedToValues);
    // const opts = transOptsByFieldId[field.id];




    // if (!opts) return <Box mt="2">None</Box>
    if (!transCfg) throw new Error ('translate opts exist for ' + editField.id + ' yet translate property missing. (is it missing in global cfg?)');

    if (fieldCfg.kind === 'enum') {
        // if (!transCfg) throw new Error ('translate opts exist for ' + field.id + ' yet translate property missing. (is it missing in global cfg?)');
        // formatByFormatterId[dictEntry.formatterId]
        return (
            <>
                { dirtyCheck() }
                <TransEnum
                    canEdit={canEdit}
                    distinctValues={distinctValues}
                    enums={Object.keys(fieldCfg.labels)}
                    getLabel={format.getFormatterByFormatterId(fieldCfg.formatterId).fn}
                    transCfg={transCfg}
                    onChange={onChange}
                />
            </>
        )
    } else {
        // So you can see, enums always can be translated.
        // Now, the rest, you gotta check for ConverTypes
        if (!fieldCfg.transOpts) {
            return <></>
        }
        return (
            <>
                { dirtyCheck() }
                <TransNonEnum
                    ride={ride}
                    canEdit={canEdit}
                    distinctValues={distinctValues}
                    entry={fieldCfg}
                    transCfg={transCfg}
                    onChange={onChange}
                />
            </>
        )
    }
    // } else if (opts.type === 'boolean') {
    //     return (
    //         <>
    //             { dirtyCheck() }
    //             <TransBool
    //                 canEdit={canEdit}
    //                 distinctValues={distinctValues}
    //                 opts={opts}
    //                 transCfg={transCfg}
    //                 onChange={onChange}
    //             />
    //         </>
    //     )
    // } else if (opts.type === 'boolWithEnum') {
    //     return <>
    //         { dirtyCheck() }
    //         <TransBoolWithEnum
    //             canEdit={canEdit}
    //             distinctValues={distinctValues}
    //             opts={opts}
    //             transCfg={transCfg}
    //             onChange={onChange}
    //         />
    //     </>
    // }
    return <></>

}