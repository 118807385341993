export const RETURNING = `
    id
    loanId
    investor
    investorId
    loanNumber
    investorProductId
    lockDate
    lockTerm
    acquisitionDate
    commitmentDays
    purchaseBy
    cashSettleDt
    stdDeliveryDt
    offeringPrice
    securityDate
    notificationDate
    priceTag
    selected
    coupon
    costOfFunds
    sellerMargin
    investorMargin
    poolingDays
    deleted
`;
