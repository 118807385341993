import { BatchItem } from '../types';
import { zBatch } from '../z-batch';
import { Observable, of, NEVER, EMPTY } from 'rxjs';
import { switchMap, withLatestFrom, tap, delay, first, map, takeWhile } from 'rxjs/operators';
import { mergeBatchItem } from '../merge-batch-item';
import { mergeBatch } from '../merge-batch';
import { pausedState$ } from '../observables';

// This is pausable.
// rxjs may seem like overkill for simple things, but, it's sooo useful for things like this.

// This is where the pausing magic happens.
// Every custom pipe we make calls this first.

export const getBatchItem = () => (index$:Observable<number>):Observable<BatchItem> => {
    return index$.pipe(
        // delay(1000),
        switchMap(index => {
            return pausedState$.pipe(
                map(({ paused, stepping }) => {
                    return {
                        paused,
                        stepping,
                        index
                    }
                })
            )
        }),
        withLatestFrom(zBatch.state$),
        map(([ x, batchState ]) => {
            if (!batchState.data) throw new Error('Missing Batch!');
            return {
                paused: x.paused,
                stepping: x.stepping,
                item: batchState.data.items.find(item => item.index === x.index)! // assume it is there
            }
        }),
        switchMap(({ paused, stepping, item }) => {    
            if (!paused || stepping) return of(item); // keep going.
            console.log('i am paused');
            return NEVER;
        }),
        first(),
        tap(item => {
            // console.log('<<  took one >>', item.index);
            mergeBatch({ stepping:false })
        })
    )
}