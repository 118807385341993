import { mutate, gql, GQL } from "../../client";

// Why is it called import_loan_sync?
// sync at the end normally means synchronous call.
// I'm calling it finalize loans.

const IMPORT = gql`
    mutation importLoanSourceSync($sheetId:String) {
        import_loan_source_sync(sheetId:$sheetId)
    }
`;

interface MutateInput {
    readonly sheetId:string;
}

interface MutateOutput {
    readonly import_loan_source_sync:{
        readonly summary:GQL.SheetSummary
    }
}

export const finalizeLoans = async (sheetId:string):Promise<GQL.SheetSummary|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(IMPORT, {
		sheetId: sheetId
	})
    return result?.data?.import_loan_source_sync?.summary;
}