import React from 'react';
import { ImportHits } from './import-hits/import-hits';
import { Route, Navigate, Routes } from 'react-router-dom';
import { ImportNavMenu } from './import-nav-menu';
import { loanModel, ImportModel } from 'market-dto';
import { TapeImporter } from 'components';
import { sheetSummaries } from 'services';

export const Import = () => {

    const onImport = () => sheetSummaries.byDay.refresh();

    return (
        <>
            <ImportNavMenu />
            <Routes>
                <Route path="tapes" element={
                    <TapeImporter
                        model={loanModel as any}
                        onTapeImportComplete={onImport}
                    />
                } />
                <Route path="hits" element={<ImportHits />} />
                <Route path="*" element={<Navigate to="tapes"/>} />
            </Routes>
        </>
    )
}