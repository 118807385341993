import React, { useEffect } from 'react';
import { GQL } from "market-dto"
import { Box, ModalSubHeader, SpinLabel, FlexVCenter, Flex, ModalTitle, Icon } from "mui"
import { fannie, freddie, profit, downloader } from "services"
import { HeatMapModalHeader } from "./heat-map-modal-header"
import { HeatMapContainer } from './heat-map-container';
import { useSafeState } from 'hooks';

interface HeatMapModalProps {
	readonly offer:GQL.Offer;
    readonly loan:GQL.Loan;
	readonly investor:"fannie"|"freddie";
	readonly removeGaps?:boolean;
	readonly removeWeekends?:boolean;
	readonly importData?:profit.HeatMapExportData;
}

export const HeatMapModal = ({ offer, loan, investor, removeGaps, removeWeekends, importData }:HeatMapModalProps) => {

	const [ refreshing, setRefreshing ] = useSafeState(false);
    const [ loading, setLoading ] = useSafeState(true);
	const [ holdingInfo, setHoldingInfo ] = useSafeState<profit.HoldingInfo|null>(null);

	const load = async (refreshCashWindow?:boolean) => {
		if (refreshCashWindow) {
			setRefreshing(true);
		} else {
			setLoading(true);
        }
        if (investor === 'fannie') {
			const hi = await fannie.getHoldingInfo(loan, offer, removeWeekends, refreshCashWindow);
			setHoldingInfo(removeGaps ? profit.removeEmptyHoldingRowsAndCols(hi) : hi);
        } else if (investor === 'freddie') {
			const hi = await freddie.getHoldingInfo(loan, offer, removeWeekends);
			setHoldingInfo(removeGaps ? profit.removeEmptyHoldingRowsAndCols(hi) : hi);
        }		
		if (refreshCashWindow) {
			setRefreshing(false);
		} else {
			setLoading(false);
		}
    }
    
    useEffect(() => {
		if (importData) {
			setHoldingInfo(importData.holdingInfo);
			setLoading(false);
		} else {
			load();
		}
    }, [importData])

	if (loading && importData) return null; // Don't show loading when importing from json file
	if (loading || !holdingInfo) {
		return (
			<Flex mt="3" alignItems="center" flexDirection="column">
				<SpinLabel>Extracting Cash Window Data...</SpinLabel>
				{ investor === 'freddie' && (
					// TODO: interactive mortgage trivia as user waits on freddie.
					<Box mt="3" fg="default" fontSize="sm">This may take a few minutes.</Box>
				) }
			</Flex>
		)
	}

	const download = () => {
		if (!holdingInfo) return;
		const obj:profit.HeatMapExportData = {
			today: Date.now(),
			investor,
			loan,
			offer,
			holdingInfo
		}
		downloader.downloadJsonFile(obj, 'heat-map.' + loan.id + '.' + investor + '.json');
	}

	return (
		<>
			<ModalTitle>
				<FlexVCenter justifyContent="space-between">
					<Box>Capability to Raise Price - { loan.id }</Box>
					{ !importData && (
						<Box fontSize="sm" mr="1">
							<Icon
								onClick={download}
								name="download"
								fg="default"
								fgHover="loud"
								cursor="pointer"
							/>
						</Box>
					) }
				</FlexVCenter>
			</ModalTitle>
			<ModalSubHeader>
				<HeatMapModalHeader
					investor={investor}
					loan={loan}
					offer={offer}
					basePrice={holdingInfo.basePrice}
					refreshing={refreshing}
					onRefresh={() => load(true)}
				/>
			</ModalSubHeader>
			<HeatMapContainer holdingInfo={holdingInfo} />
		</>
	)
}
