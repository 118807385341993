import React, { useState } from 'react';
import { Box, Text, Dropdown, DropdownItem, FlexVCenter, Button, Input } from 'mui';
import { GQL } from 'market-dto';
import { EnumConfirm } from './enum-confirm';
import { SrcColCfgAndEntry } from './types';

interface Props {
    readonly colCfgs:GQL.XlsExportCol[];
    readonly src:SrcColCfgAndEntry;
    readonly param:GQL.XlsExportCalcEnumColParam;
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
}
export const EnumGetLabel = ({
    colCfgs,
    src,
    param,
    onColCfgsChange,
    selectedColId,
    setSelectedColId
}:Props) => {

    const [ label, setLabel ] = useState('');
    const [ confirmedLabel, setConfirmedLabel ] = useState<string>();

    const go = () => {
        if (!label.trim()) return;
        setConfirmedLabel(label.trim());
    }

    return (
        <>
            <Box mt="1" pt="1" borderColor="alert" borderSides="top" borderWidth="thin">
                { !confirmedLabel && (
                    <FlexVCenter>
                        <Box flex="1" mr="1">
                            <Input
                                placeholder="What should we call this new column?"
                                value={label}
                                setValue={setLabel}
                            />
                        </Box>
                        <Button
                            onClick={go}
                            type={label.trim() ? 'default' : 'disabled'}
                        >Continue</Button>
                    </FlexVCenter>
                ) }
                { confirmedLabel && (
                    <>
                        <Box mb="1">
                            We will call this column <Text fg="loud">{ confirmedLabel }</Text>
                        </Box>
                        <EnumConfirm
                            colCfgs={colCfgs}
                            onColCfgsChange={onColCfgsChange}
                            label={label}
                            param={param}
                            src={src}
                            selectedColId={selectedColId}
                            setSelectedColId={setSelectedColId}
                        />
                    </>
                ) }
            </Box>

        </>

    )
}