import { createZState } from './z-state';
import { GQL } from "market-dto";
import * as api from 'api';
import { subDays, isWeekend } from 'date-fns';


const getYyyymmddFromId = (id:string) => id.substr(0, 8);

export interface SheetSummaryDetails {
    readonly summary:GQL.WithInternal<GQL.SheetSummary>;
    readonly checked:boolean;
}

type SheetSummaryDict = { [yyyymmdd:string]:Array<SheetSummaryDetails> };

export const daysBack = 7;

export const byDay = createZState<SheetSummaryDict>({
    zid: 'sheet-summary-by-day',
    initVal: {},
    apiFetch: async () => {
        const summaries = await api.fetchSheetSummariesSince(subDays(new Date(), daysBack));
        const dict:SheetSummaryDict = {};
        console.log('we loaded summaries', summaries);
        summaries.forEach(summary => {
            const yyyymmdd = getYyyymmddFromId(summary.id);
            if (!dict[yyyymmdd]) dict[yyyymmdd] = [];
            dict[yyyymmdd].push({
                summary,
                checked: false
            })
        })
        return dict;
    }
})

export const setChecked = (sheetId:string, flag:boolean) => {
    const yyyymmdd = sheetId.substr(0, 8);
    byDay.update(dict => {
        const summaries = dict[yyyymmdd];
        if (!summaries) return dict; // nothing to change
        return {
            ...dict,
            [yyyymmdd]: summaries.map(x => {
                return x.summary.sheetId !== sheetId ? x : {
                    ...x,
                    checked: flag
                }
            })
        }
    })
}

// export const setAllCheckedOnSameDay = (sheetId:string, flag:boolean) => {
//     const yyyymmdd = sheetId.substr(0, 8);
//     byDay.update(dict => {
//         const summaries = dict[yyyymmdd];
//         if (!summaries) return dict; // nothing to change
//         return {
//             ...dict,
//             [yyyymmdd]: summaries.map(x => {
//                 return {
//                     ...x,
//                     checked: flag
//                 }
//             })
//         }
//     })
// }


export const setAllCheckedByDay = (yyyymmdd:string, flag:boolean) => {
    byDay.update(dict => {
        const summaries = dict[yyyymmdd];
        if (!summaries) return dict; // nothing to change
        return {
            ...dict,
            [yyyymmdd]: summaries.map(x => {
                return {
                    ...x,
                    checked: flag
                }
            })
        }
    })
}
