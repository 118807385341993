import { FieldHint, HintedField } from "raccoon-engine"
import React from 'react';
import styled from "styled-components"


interface Args
{
	fieldHint?:FieldHint<any>
}


function getInterestedIn (props:Args) {
	const { fieldHint } = props
	return fieldHint?.interested || ""
}

const getColor = (props:Args) => {
	const field : HintedField = getInterestedIn(props)
	switch (field) {
		case "LLRA":
		case "LLPA":
		case "Price":
			return "transparent"
		case "FICO":
			return "#ff8a80"
		case "NoteRate":
		case "DTI":
			return "#ce93d8"
		case "LTV":
		case "CLTV":
			return "#c5cae9"
		case "LoanPurpose":
		case "PropertyType":
		case "DocType":
		case "OccupancyType":
			return "#07a"
		default:
			return "#0ca"
	}
}

export const HintDiv = styled.div<Args>`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }

  &::after {
    border-radius: 0.1em;
    border-width: 0.5em;
    border-right-color: ${props => getColor(props)};
    border-top-color: ${props => getColor(props)};
  }
`
