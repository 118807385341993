import { useEffect, useRef } from 'react';

// This interval will allow you to see component state.
// regular setInterval will not.

export const useInterval = (callback:any, delay:number) => {

    const savedCallback = useRef<any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

// TODO: why is callback any and not func typed?
export const useTimeout = (callback:any, delay:number, deps?:any[]) => {

    const savedCallback = useRef<any>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();
        if (delay > 0) {
            const id = setTimeout(tick, delay);
            return () => {
                // console.log('CLEAR TIMEOUT');
                clearTimeout(id);
            }
        }
    }, [delay, ...deps ?? []]);
}
