import React, { useState } from 'react';
import { useApiFetch } from 'hooks';
import {
    SpinLabel,
    Alert,
    Button,
    IntoPortal,
    FlexVCenter,
    Box,
    Input,
    modal,
    Text
} from 'mui';
import { GQL } from 'market-dto';
import * as api from 'api';
import { EditServicerModal } from './edit-servicer-modal';
import { ServicersTable } from './servicers-table';

export const Servicers = () => {

    const [ filter, setFilter ] = useState('');
    const { loading, data:servicers, loadWithoutBusyStates:refresh } = useApiFetch(api.fetchAllServicers, [])

    if (loading || !servicers) return <SpinLabel mt="2">Loading...</SpinLabel>

    const edit = (servicer:GQL.Servicer) => {
        modal.open({
            size: 'sm',
            render: <EditServicerModal servicer={servicer} isNew={false} refresh={refresh} />,
            title: 'Edit Servicer'
        })
    }

    const openAddServicerModal = () => {
        modal.open({
            size: 'sm',
            render: <EditServicerModal isNew refresh={refresh} />,
            title: 'Add Servicer'
        })
    }

    const filteredServicers = servicers.filter(x => {
        const ltxt = filter.trim().toLowerCase();
        if (!ltxt) return true;
        if (x.name && x.name.toLowerCase().includes(ltxt)) return true;
        if (x.symbol && x.symbol.toLowerCase().includes(ltxt)) return true;
        if (x.fannieServicerNumber && x.fannieServicerNumber.toLowerCase().includes(ltxt)) return true;
        return false;
    })

    return (
        <>
            <IntoPortal id="desk-portal">
                <FlexVCenter height="btn-row">
                    <Button type="default" size="sm" onClick={openAddServicerModal}>Add Servicer</Button>
                </FlexVCenter>
            </IntoPortal>

            <FlexVCenter mb="2" justifyContent="space-between">

                <FlexVCenter>
                    { servicers.length > 0 && (
                        <>
                            <Box mr="1">
                                <Input
                                    appendIcon="search"
                                    placeholder="Search..."
                                    value={filter}
                                    setValue={setFilter}
                                />
                            </Box>
                            <Box fg="default">
                                { filteredServicers.length }
                                <Text fg="faded">
                                    &nbsp;Servicer{filteredServicers.length === 1 ? '' : 's'}
                                </Text>
                            </Box>
                        </>
                    )}
                    
                </FlexVCenter>
            </FlexVCenter>

            { servicers.length === 0 && <Alert>No Servicers Found!</Alert> }
            { servicers.length > 0 && <ServicersTable filteredServicers={filteredServicers} onEdit={edit} /> }

        </>
    );
}
