import { useEffect, useState } from 'react';

export type BindTo<T> = [T, (x:T) => any, (x:T) => any];

export const useProxyDataOLD = <T>(bindTo:BindTo<T>) => {

    const [ valObj, setValObj ] = useState<{ value:any, setVal:(x:any) => any }>(() => {
        // So that we have no initial flicker, give it the value until the proxy is setup.
        return {
            value: bindTo[2](bindTo[0]),
            setVal: () => {}
        }
    });

    useEffect(() => {
        const [ data, setData, getter ] = bindTo;
        let copying = false;
        const proxyHandler = {
            get: (target:any, key:string):any => {
                if (copying) return target[key];
                if (typeof target[key] === 'object' && target[key] !== null && !Array.isArray(target[key])) {
                    return new Proxy(target[key], proxyHandler);
                }
                setValObj({
                    value: target[key],
                    setVal: (x:any) => {
                        target[key] = x; // yes, it is mutable.
                        copying = true;
                        setData(JSON.parse(JSON.stringify(proxy))); // copying touches all props, so, copying flag!
                        copying = false;
                    }
                });
                return target[key];
            }
        }
        const proxy = new Proxy(data, proxyHandler);
        getter(proxy);

    }, [bindTo])

    return valObj
}

type ArrFunc = (item:any, val:any) => any;

export const useProxyData = <T>(bindTo:BindTo<T>) => {

    // You might wonder, why not parse a "dot.notation.string".
    // BECAUASE I WANT INTELLISENSE

    const [ arrFuncs, setArrFuncs ] = useState<ArrFunc[]>([]);

    useEffect(() => {
        const [ data, setData, getter ] = bindTo;
        // A series of functions to BUILD A NEW OBJECT once we find out how to get to our value!
        const funcs:ArrFunc[] = [];
        // We use the proxy just once, to initially setup our funcs array.
        // the end result of the funcs array, a series of functions which call one another...which produces a NEW OBJECT
        // You call the first func in the array when setting value!
        const proxyHandler = {
            get: (target:any, key:string):any => {
                // this is recursive!
                // "a.b.c.d"
                // first you'll get a, then b, then c, finally, d .... as key!
                if (typeof target[key] === 'object' && target[key] !== null && !Array.isArray(target[key])) {
                    const n = funcs.length;
                    funcs.push((item, val) => {
                        return {
                            ...item,
                            [key]: funcs[n+1](item[key], val)
                        }
                    })
                    return new Proxy(target[key], proxyHandler);
                }
                funcs.push((item, val) => {
                    return {
                        ...item,
                        [key]: val
                    }
                })
                setArrFuncs(funcs);
                return target[key];
            }
        }
        const proxy = new Proxy(data, proxyHandler);
        getter(proxy);
    }, [])

    return {
        value: bindTo[2](bindTo[0]),
        setVal: (val:any) => bindTo[1](arrFuncs[0](bindTo[0], val))
    }
}