import { ExpandedState, ExpandedUiState } from './types';

// TODO: put this directly on ExState? We check it every render.
// I guess leave as it is. Not like it's much logic.

export const getUiState = (exState:ExpandedState):ExpandedUiState => {

    const { newSeller, sellerForAgg, newAggregatorName, selected, mappedForSellerOrg } = exState;

    if (selected) {
        if (!newSeller) {
            if (selected.aggregatorName) return "AGG_SELECTED__USE_AS_IS";
            return "NON_AGG_SELECTED__USE_AS_IS";
        } else {
            // selected AND new seller ...
            return selected.aggregatorName ? "AGG_SELETCED__NEW_SELLER" : "NON_AGG_SELECTED__NEW_SELLER";
        }
    } else {
        // if agg, check if we mapped a seller or if they gave us one.
        if (newAggregatorName && (sellerForAgg || mappedForSellerOrg)) {
            return "NONE_SELECTED__NEW_AGG";
        } else if (newSeller) {
            return "NONE_SELECTED__NEW_NON_AGG";
        } else {
            return "MISSING_SOMETHING";
        }
    }
}