import React from 'react';
import { Input, Button, IntoPortal, Box, FlexVCenter, Text, FileSelectButton } from 'mui';
import { ExpFieldWithValue } from 'exp';
import { downloader } from 'services';
import { GQL } from 'market-dto';

interface Props {
    readonly hideButtonsWhenPristine?:boolean;
    readonly onClose?:() => void;
    readonly onSave?:() => void;
    readonly onReset?:() => void;
    readonly busy:boolean;
    readonly dirty:boolean;
    readonly fields:ExpFieldWithValue[];
    readonly filterText:string;
    readonly setFilterText:(x:string) => void;
    readonly sample?:GQL.ModelSample;
}
export const FieldsWithValuesTableFilters = ({ sample, onClose, onSave, onReset, dirty, busy, fields, filterText, setFilterText, hideButtonsWhenPristine }:Props) => {

    const showSave = onSave && (!hideButtonsWhenPristine || dirty || busy);

    const download = () => {
        if (!sample) throw new Error('Expected sample');
        downloader.downloadJsonFile(sample, sample.id + '.sample.json');
    }

    return (
        <>
            <IntoPortal id="sample-fields-filter-area">
                <FlexVCenter>
                    <Input
                        placeholder="Filter Fields"
                        value={filterText}
                        setValue={setFilterText}
                        appendIcon="search"
                    />
                    <Box ml="1">
                        { fields.length }
                        &nbsp;
                        <Text fg="faded">Field{ fields.length === 1 ? '' : 's' }</Text>
                    </Box>
                </FlexVCenter>
            </IntoPortal>
            <IntoPortal id="sample-fields-button-area">
                <FlexVCenter>

                    { !hideButtonsWhenPristine && sample && (
                        // RANDOM in memory sample will have undefined for sample
                        // actually, hideButtonWhenPristine only comes from checklist ui, so, we're good...
                        <Button
                            size="sm"
                            type="link"
                            icon="download"
                            onClick={download}
                        >JSON</Button>
                    ) }

                    { onClose && (
                        <Button
                            size="sm"
                            type="link"
                            ml="1"
                            onClick={onClose}
                        >Close</Button>
                    ) }
                    { dirty && onReset && (
                        <Button
                            size="sm"
                            ml="1"
                            type="secondary"
                            onClick={onReset}
                        >Reset</Button>
                    ) }
                    { showSave && (
                        <Button
                            ml="1"
                            size="sm"
                            busy={busy}
                            type={dirty ? 'default' : 'disabled'}
                            onClick={onSave}
                        >Save Sample</Button>
                    ) }
                </FlexVCenter>
            </IntoPortal>
        </>
    )
}