import { ExpNode, SenseOption, ExpContext } from './exp-types';
import { parseExpression } from './parse-expression';
import { expNodeToString } from './exp-node-to-string';

export const acceptSenseOption = (
    expStr:string,
    activeNode:ExpNode,
    opt:SenseOption,
    ctx:ExpContext
):string => {

    // they hit enter or tab or something. accent current sense option.
    if (activeNode.type === 'StringConstantNode') {
        // Enums
        if (opt.type !== 'enum') throw new Error('Cannot accept invalid sense option type: ' + opt.type);

    } else if (activeNode.type === 'ExpressionNode') {
        // Operators
        if (opt.type !== 'op') throw new Error('Cannot accept invalid sense option type: ' + opt.type);

    } else if (activeNode.type === 'IdentifierNode') {
        // Fields or functions
        if (opt.type !== 'field' && opt.type !== 'func') throw new Error('Cannot accept invalid sense option type: ' + opt.type);

    }

    const tmpRootNode = setNodeValue(expStr, opt.value, opt.type === 'func', activeNode, ctx, false);
    // console.log('dude, ', JSON.stringify(tmpRootNode, null, 4));
    if (!tmpRootNode) throw new Error('how can this happen? no root node?');
    return expNodeToString(tmpRootNode, true);
}

const setNodeValue = (s:string, val:string, isFunc:boolean, activeNode:ExpNode, model:ExpContext, appendSpace:boolean):ExpNode|null => {
    // we don't bother walking the tree. we simply create the string with the new value and parse again!
    // if (activeNode.type !== 'IdentifierNode') throw new Error('Expected IdentifierNode');
    const appendChar = appendSpace ? ' ' : '';
    if (activeNode.type === 'IdentifierNode') {
        // before parser sees it's a func, it's an identifier!
        const arr = [
            s.substring(0, activeNode.start),
            ''.padStart(activeNode.raw.search(/\S|$/), ' '),
            val,
            isFunc ? '(' : appendChar,
            s.substring(activeNode.end)
        ]
        return parseExpression(arr.join(''), model).node;
    } else if (activeNode.type === 'ExpressionNode') {
        const arr = [
            s.substring(0, activeNode.left.end),
            // padStart is wrong, you want that number no moatter what.
            ''.padStart(activeNode.rawOp.search(/\S|$/), ' '),
            val,
            appendChar,
            s.substring(activeNode.right.start)
        ]
        return parseExpression(arr.join(''), model).node;
    } else if (activeNode.type === 'StringConstantNode') {
        const arr = [
            s.substring(0, activeNode.start),
            ''.padStart(activeNode.raw.search(/\S|$/), ' '),
            val,
            isFunc ? '(' : appendChar,
            s.substring(activeNode.end)
        ]
        return parseExpression(arr.join(''), model).node;
    }
    throw new Error('Unhandled node type: ' + activeNode.type);
}
