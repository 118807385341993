import { mutate, gql } from "../../client";

const RETURNING = `{ affectedRow }`;

export const zapTapes = async (ids: string []) => {
	const promises = ids.map(sheetId => {
		const deleteSingle = `
			delete_tape (where: {id : {_eq : "${sheetId}"}}) ${ RETURNING }
			delete_sheet (where: {id : {_eq : "${sheetId}"}}) ${ RETURNING }
			delete_sheetSummary (where: {sheetId : {_eq : "${sheetId}"}}) ${ RETURNING }
			delete_loan (where: {sheetId : {_eq : "${sheetId}"}}) ${ RETURNING }
			delete_offer (where: {sheetId : {_eq : "${sheetId}"}}) ${ RETURNING }
			delete_loanSource (where: {sheetId : {_eq : "${sheetId}"}}) ${ RETURNING }
		`;
		const deleteAll = `mutation { ${deleteSingle} }`;
		return mutate(gql`${deleteAll}`, {});
	})
	const start = Date.now();
	console.log('about to zap');
	await Promise.all(promises);
	console.log('all zapped, took:', Date.now() - start);
}
