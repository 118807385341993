import React, { useState } from 'react';
import { Box, Input, Button, modal } from 'mui';


//


interface NewGroupModalProps {
    readonly groups:string[];
    readonly onAdd:(name:string) => void;
}
export const NewGroupModal = ({ groups, onAdd }:NewGroupModalProps) => {

    const [ groupName, setGroupName ] = useState('');
    const [ nameTaken, setNameTaken ] = useState(false);

    const go = () => {
        const g = groupName.trim();
        if (g === '') return '';
        if (groups.map(x => x.toLowerCase()).includes(g)) {
            setNameTaken(true);
            return;
        }
        onAdd(groupName);
        modal.close();
    }
    return (
        <Box>
            <Input placeholder="New Group Name" autoFocus onEnter={go} value={groupName} setValue={setGroupName} />
            { nameTaken && <Box my="1" fg="danger">That group name is already taken!</Box> }
            <Box mt="1" textAlign="right">
                <Button
                    type={groupName.trim() === '' ? 'disabled' : 'default'}
                    onClick={go}
                >Add</Button>
            </Box>
        </Box>
    )
}