import { mutate, gql, GQL, stripInternals } from "../../client";
import { RETURNING, toTemplateAndConfidence } from './returning';
import { TemplateAndConfidence } from 'services/tape-importer';

const INSERT = gql`
    mutation insertTemplate($objects: [template_insert_input!]) {
        insert_template(objects: $objects) {
            errorMessage
            affectedRow
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.Template>]
}

interface MutateOutput {
    readonly insert_template: {
        readonly returning:[GQL.Template];
    }
}
// TemplateAndConfidence
export const insertTemplate = async (template:GQL.Template):Promise<GQL.Template|undefined> => {
    if (!template.orgIds) throw new Error('Expected orgIds');
	const result =  await mutate<MutateInput, MutateOutput>(INSERT, { objects: [stripInternals(template)] });
    return result?.data?.insert_template.returning[0];
    // if (!createdTemplate) return;
    // return toTemplateAndConfidence("highest", createdTemplate);
}
