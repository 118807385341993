import React from 'react';
import { Text, FgColor, FontSize } from 'mui';

interface Props {
    readonly fg?:FgColor;
    readonly fontSize?:FontSize;
}
export const AlphaLogo = ({ fg, fontSize }:Props) => {

    return (
        <Text fg={fg} fontSize={fontSize}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 640 230"
            >
                <g>
                    <path
                        d="m447.964996,48.070099l105.613007,0l-63.989014,191.870895c-12.425995,36.468002 -20.915985,58.845993 -25.471985,67.134018c16.566986,50.833984 40.588989,76.250977 72.065002,76.250977c37.276001,0 56.534973,-17.127991 57.776978,-51.385986l22.36499,0c-1.242004,32.046997 -10.042969,57.947998 -26.402954,77.700989c-16.360046,19.753998 -37.79303,29.631012 -64.300049,29.631012c-21.950958,0 -38.309967,-5.940002 -49.078979,-17.820007c-10.768005,-11.880005 -22.36499,-36.191986 -34.789978,-72.936005c-43.074005,60.503998 -113.483002,90.756012 -211.22702,90.756012c-66.681,0 -119.694992,-17.335999 -159.040993,-52.009003c-39.346001,-34.671997 -59.019101,-84.332001 -59.019101,-148.979004c0,-63.542999 21.536801,-112.995995 64.610402,-148.358597c43.0737,-35.362801 94.844696,-53.0443 155.313705,-53.0443c42.658997,0 77.656982,10.084 104.992004,30.251797c27.334991,20.1679 51.563995,53.044106 72.686981,98.629097l37.897003,-117.691792l0,0l0,0l0,-0.000103zm-54.67099,162.447906c-19.466003,-44.480011 -41.209991,-79.221008 -65.231995,-104.223007c-24.022003,-25.003098 -52.806,-37.504395 -86.354019,-37.504395c-75.792999,0 -113.689987,59.122398 -113.689987,177.366394c0,112.167007 36.23999,168.250015 108.720001,168.250015c62.953979,0 109.548004,-49.453003 139.781982,-148.358002l16.774017,-55.531006z"
                        fill="currentColor"
                    />
                </g>
            </svg>
       </Text>
    )
}
