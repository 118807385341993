import React from 'react';
import { WhenEnumInDetails } from './types';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const WhenInFailedToMap = ({ details }:Props) => {
    return (
        <div>But "{ details.whenLabel }" failed to map!</div>
    )
}