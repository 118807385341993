export const iThinkItIsNotUsed = true;

// import { GQL, Hashes, Overrides, loanModel, TemplateMapping } from 'market-dto';
// import { ToMap } from './types';

// // Use these two nee update/insert object create functions instead of topartial.
// export const toUpdateObjectByOrides = (orides:Overrides, template:GQL.Template):Partial<GQL.Template> => {
//     return {
//         ...template,
//         mapping: {
//             ver: loanModel.version,
//             overrides: orides
//         }
//     }
// }

// export const toInsertObjectByOrides = (
//     orides:Overrides,
//     hashes:Hashes,
//     sellerOrg:GQL.Org,
//     aggregatorName?:string
// ) => {
//     return {
//         mapping: {
//             ver: loanModel.version,
//             overrides: orides
//         },
//         aggregatorName,
//         orgIds: [sellerOrg.id],
//         hashes: [hashes.h0],
//         sequenceHashes: [hashes.h1],
//         orderHashes: [hashes.h2],
//         mappedHashes: [hashes.h3]
//     }
// }


// export const toPartialTemplate = (orides:Overrides, toMap:ToMap):Partial<GQL.Template> => {

//     const templateMapping:TemplateMapping = {
//         ver: loanModel.version,
//         overrides: orides
//     }

//     if (toMap.template) {
//         // HASHES DO NOT CHANGE -- they are set once by global config on insertion. that's it.
//         return {
//             ...toMap.template,
//             mapping: templateMapping
//         }
//     } else {
//         if (!toMap.sellerOrg) throw new Error('if no template supplied, sellerOrg MUST be supplied.');
//         const mappedByGlobalCfg = guess(toMap.headersAndRows, getMasterConfig());
//         const { h0, h1, h2, h3 } = createHashes(mappedByGlobalCfg, toMap.headersAndRows);
//         return {
//             mapping: templateMapping,
//             orgIds: [toMap.sellerOrg.id],
//             hashes: [h0],
//             sequenceHashes: [h1],
//             orderHashes: [h2],
//             mappedHashes: [h3]
//         }
//     }
// }
