import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';
import { RETURNING as LOAN_FIELDS } from '../loan/returning';
import { LOAN_SOURCE_RETURNING } from '../loan/loan-source-returning';
import { RETURNING as OFFER_FIELDS } from '../offer/returning';
import { RETURNING as SUMMARY_FIELDS } from '../sheet-summary/returning';
import { fetchLoanSources } from '../loan';

// loanSources { ${ LOAN_SOURCE_RETURNING } }

const QUERY = gql`
    query ($where: sheet_bool_exp!) {
        sheet(where: $where) {
            ${ RETURNING }
            offers { ${ OFFER_FIELDS } }
            loans { ${ LOAN_FIELDS } }
            sheetSummary { ${ SUMMARY_FIELDS } }
            seller
            {
                id
                name
                symbol
            }
        }
    }
`;

interface QueryResult {
    readonly sheet:[GQL.LoanModelSheet];
}

interface QueryInput {
    readonly where:GQL.WhereString;
}

export const fetchLoanModelSheet = async (sheetId:string):Promise<GQL.LoanModelSheet|undefined> => {

    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _eq: sheetId
            }
        }
    }, { delay: 0 }) // delay is for testing

    if (result.data.sheet.length !== 1) return undefined;
    const sheet = result.data.sheet[0];
    return prepareSheet(sheet);
}


// TODO: why is offeringPrice OPTIONAL? What is an offer without a price?
const sortByOfferingPrice = (a:GQL.Offer, b:GQL.Offer) => {
    return (b.offeringPrice ?? 0) - (a.offeringPrice ?? 0);
}

const prepareSheet = (sheet:GQL.LoanModelSheet):GQL.LoanModelSheet => {
    // Very important -- because we rely on indexes. The order matters.
    sheet.loans.sort((a, b) => a.rowNumber - b.rowNumber);

    const offers = sheet.offers.filter(offer => !offer.deleted);

    // console.log('SUMMARY', sheet.sheetSummary);
    return {
        ...sheet,
        offers: offers,
        loans: sheet.loans.map(loan => {
            return {
                ...loan,
                offers: offers.filter(offer => offer.loanId === loan.id).sort(sortByOfferingPrice)
            }
        })
    }
}
