import React, { useState, useEffect } from 'react';
import { Button, modal, Box, FlexVCenter, Flex, Checkbox, CssGridBox, Text, Dropdown, DropdownItem, LabelValueGrid } from 'mui';
import { DaysOfWeek, NthOfMonth, dayLabels } from './types';


interface NthOfMonthOptionProps {
    readonly label:string;
    readonly daysOfWeek:DaysOfWeek;
    readonly checked:boolean;
    readonly onToggle:(val:boolean) => void;
}
export const NthOfMonthOption = ({ label, daysOfWeek, checked, onToggle }:NthOfMonthOptionProps) => {

    const darr = Object.entries(daysOfWeek)
        .filter(arr => arr[1])
        .map((arr, n) => (dayLabels as any)[arr[0]])

    const enabled = darr.length > 0;

    return (
        <>
            <Checkbox
                readOnly={!enabled}
                checked={checked}
                onToggle={x => enabled && onToggle(x)}
            />
            <Box>
                <Text fontSize="md" fg={enabled ? 'faded' : 'really-faded'} onClick={() => enabled && onToggle(!checked)} cursor="pointer">
                    { label }&nbsp;
                    { darr.length > 0 && darr.map((x, n) => (
                        <React.Fragment key={n}>
                            { n > 0 && n === darr.length-1 && ' and ' }
                            { n > 0 && n < darr.length-1 && ', ' }
                            <Text fg={enabled ? 'default' : 'faded'}>{ x }</Text>
                        </React.Fragment>
                    )) }
                    &nbsp;of the month
                </Text>
            </Box>
        </>
    )
}