import { JSONSchema } from 'market-dto';
import { BasicMenuItem, DropdownItem } from 'mui';
import _deref from 'json-schema-deref-sync';
export const deref = (s:JSONSchema):JSONSchema => _deref(s); // just to give it typings

export const missingTitle = 'TITLE MISSING';

export type KeyValueDict = { [k:string]: any };

export interface SchemaUiConfig {
    readonly data:KeyValueDict;
    readonly editting:boolean;
    readonly saving:boolean;
    readonly dirty:boolean;
    readonly columns:number;
}

// export interface EditState {
//     readonly data:KeyValueDict;
//     readonly schema:JSONSchema;
//     readonly editting:boolean;
//     readonly saving:boolean;
//     readonly dirty:boolean;
//     readonly columns:number;
// }



// export const editState = zState.createZState<EditState>({});

// export const deref = (schema:JSONSchema):JSONSchema => _deref(schema) as JSONSchema;

// export const createInitEditState = (data:KeyValueDict, schema:JSONSchema, columns?:number):EditState => {
//     return {
//         data: JSON.parse(JSON.stringify(data)),
//         schema: deref(schema),
//         columns: columns ?? 1,
//         dirty: false,
//         editting: false,
//         saving: false
//     }
// }

export const getItemsFromEnums = (schema:JSONSchema):DropdownItem[] => {
    return schema.enum ? schema.enum.map((k):DropdownItem => {
        return {
            label: k,
            value: k
        }
    }) : [];
}

export const getItemsFromOneOf = (schema:JSONSchema):Array<DropdownItem> => {
    return schema.oneOf ? (schema.oneOf as Array<JSONSchema>).map(x => {
        return {
            label: x.title ? x.title : '',
            value: x.const
        }
    }) : [];
}


