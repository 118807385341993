import { util } from 'market-dto';
import { createLoans } from '../template-matching';
import {
    UserInputState,
    BatchItem,
    Generated,
    Assigned
} from '../types';
import { getUserDecision } from './get-user-decision';
import { sellerHasAlias } from './seller-has-alias';
import { zSellers } from 'services/sellers';
import { ensureTemplate } from './ensure-template';

export const getManualAssigned = async (item:BatchItem, userInput:UserInputState):Promise<Assigned> => {

    const { tapeId, rowsAndHeaders, sellers, model } = item;
    if (!rowsAndHeaders || !tapeId) throw new Error('Expected tapeId & rowsAndHeaders');

    const decision = getUserDecision(userInput);
    console.log('dECISON', decision);
    
    if (decision === "INVALID_CFG") throw new Error("Invalid userInput -- bad config");

    const template = await ensureTemplate(item, userInput, decision);    
    if (!template) throw new Error("Unable to update/insert/verify template");
    
    const { confirmedSeller, confirmedAggregatorName } = userInput;
    if (!confirmedSeller) throw new Error("Cannot assign without confirmed seller");

    const { sourceMap, loans } = createLoans(model, tapeId, rowsAndHeaders, sellers, template.mapping);
    const nextGenerated:Generated = {
        sourceMap,
        loans: loans.map(loan => {
            return {
                ...loan,
                // we are going to override whatever mapper gave us here, because they TOLD us who it is
                sellerOrgId: confirmedSeller.id
            } as any // TS is confused.
        })
    }
    // This is a bit strange--we return what we assign it to. instead of two steps.

    if (decision === "AGG" || decision === "CLONE_AGG" || decision === "NEW_AGG") {
        // If what they have mapped seller to gives us an alias that we don't know about, add it

        const distinctSellerNames = util.toDistinct(nextGenerated.loans.filter(x => x.sellerName).map(x => x.sellerName));
        if (distinctSellerNames.length === 1) {
            const alias = distinctSellerNames[0];
            if (!sellerHasAlias(confirmedSeller, alias)) {
                await zSellers.apiUpdateListItem({
                    id: confirmedSeller.id,
                    aliases: confirmedSeller.aliases.concat(alias)
                })
            }
        }
        return { 
            generated: nextGenerated,
            type: "agg",
            sellerOrg: confirmedSeller,
            aggregatorName: confirmedAggregatorName,
            template
        }
    }
    // Must be non-agg
    return { 
        generated: nextGenerated,
        type: "non-agg",
        sellerOrg: confirmedSeller,
        template
    }
}