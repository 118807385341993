import React, { useState, useEffect } from 'react';
import { Box } from '../box/box';
import { FlexVCenter } from '../flex/flex';
import { Input, InputProps } from '../input';
import { SpacingSize, InputSize, InputWidth } from '../../types';
import { Dropdown, DropdownProps } from '../dropdown/dropdown';

export interface FrmDropdownProps<T> extends DropdownProps<T> {
    readonly label:string;
    readonly desc?:string;
    readonly subLabel?:string; // these flip flop colors as normal label+desc
    readonly subDesc?:string;
    readonly mt?:SpacingSize;
    readonly size?:InputSize;
    readonly width?:InputWidth;
    readonly readOnly?:boolean;
}

export const FrmDropdown = <T,>({
    width,
    label,
    desc,
    subLabel,
    subDesc,
    readOnly,
    mt = "1",    // To override (set to zero) the margin on first item in a card
    ...props
}:FrmDropdownProps<T>) => {

    const labelMl:SpacingSize = props.size === "sm" ? "half" : "1";
    const controlMt:SpacingSize = props.size === "sm" ? "half" : "1";
    const w:InputWidth = width ?? (props.size === 'md' ? "frm-md" : "frm-sm");

    return (
        <Box mt={mt} mb="1">

            <FlexVCenter ml={labelMl}>
                <Box fg="form-label" fontSize="md">{ label }</Box>
                { desc && <Box fontSize="sm" fg="form-desc" ml="1">{ desc }</Box> }
            </FlexVCenter>

            { (subLabel || subDesc) && (
                <FlexVCenter ml={labelMl} fg="form-label" fontSize="sm" textStyle="italic">
                    { subLabel && <Box mr="1" fg="form-desc">{ subLabel }</Box> }
                    { subDesc && <Box fg="form-label">{ subDesc }</Box> }
                </FlexVCenter>
            ) }

            <Box mt={controlMt} fontSize="sm">
                <Dropdown
                    disabled={readOnly} // tODO: FIX THIS. move DROPDOWN TO READOLY (not disabled)
                    width={w}
                    {...props}
                />
            </Box>
        </Box>
    )
}