import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, Heading, IntoPortal, FlexVCenter, Alert, DropdownItem, Dropdown } from 'mui';
import { OverrideItem, Overrides, GQL, TransCfg, loanModel, labels, TransTo, ModelItem } from 'market-dto';

interface Props {
    readonly ride:OverrideItem;
    // readonly editField:mapping.Field;
    readonly canEdit:boolean;
    readonly distinctValues:string[];
    readonly transCfg:TransCfg;
    readonly entry:ModelItem.AnyType;
    readonly onChange:(transCfg:TransCfg) => void;
}
export const TransNonEnum = ({ entry, transCfg, canEdit, ride, onChange }:Props) => {

    const { transOpts } = entry;
    if (!transOpts) throw new Error('Expected transOpts');
    
    
    /*
        Escrow Waived looks like this:
        {
            "convert": "toBoolWithEnums",
            "param": [
                {
                    "value": true,
                    "hint": {
                        "patterns": [
                            "waived"
                        ]
                    }
                },
                {
                    "value": false,
                    "hint": {
                        "patterns": [
                            "not waived"
                        ]
                    }
                }
            ]
        }
    */

    // const [ transMethod, setTransMethod ] = useState<ConvertType>(() => transCfg.convert);

    const opts = transOpts.map((x):DropdownItem<TransTo> => {
        return {
            label: labels.byMappingTranslateType(x),
            value: x
        }
    })

    const val = transCfg.override?.to ?? transCfg.to;


    const change = (x:TransTo) => {

        // TODO: this will break if it requires a param!
        // This needs to be fixed for those times.
        const transOride = {
            ...transCfg.override ?? {},
            to: x
        } as TransCfg;

        onChange({
            ...transCfg,
            override: transOride
        })
    }

    return (
        <>
            <Box mt="2">
                <Dropdown
                    disabled={!canEdit}
                    placeholder="Select translation method"
                    items={opts}
                    value={opts.find(x => x.value === val)}
                    setValue={change}
                />
            </Box>

        </>
    )


}


