import React from 'react';
import { JSONSchema } from 'market-dto';
import { SchemaUiSection } from './section';
import { KeyValueDict } from './schema-ui.service';

interface Props {
    readonly data:KeyValueDict;
    readonly schema:JSONSchema;
    readonly filter:string;
    readonly editting:boolean;
    readonly onChange:(x:KeyValueDict) => void;
}
export const SchemaUiNode = ({ data, filter, schema, editting, onChange }:Props) => {    

    if (schema.properties) {
        // This IS a section
        return <SchemaUiSection filter={filter} schema={schema} onChange={onChange} data={data} editting={editting} />
    } else if (schema.allOf) {
        const sections = schema.allOf as JSONSchema[];
        return (
            <>
                { sections.map((sec, n) => (
                    <SchemaUiSection
                        key={n}
                        schema={sec}
                        onChange={onChange}
                        filter={filter}
                        editting={editting}
                        data={data}
                    />
                )) }
            </>
        )
    }
    return <div>Unsupported Schema Node
        <textarea readOnly value={JSON.stringify(schema, null, 4)} />
    </div>
}