import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { logActivity } from './log-activity';
import { fetchMatchingTemplates, examineMatch } from '../template-matching';
import { sellers } from 'services';
import { UnassignedValidMatchResult, UnassignedMatchResult } from '../types';


export const apiFetchMatchingTemplates = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        switchMap(item => {
            // There are times we are told to find templates again, even if we already were successful.
            // If that has happened, just jump out.
            console.log('a');
            if (item.status === 'TEMPLATE_ASSIGNED_AND_CONFIRMED') return of(item.index);
            const { rowsAndHeaders, defaultMapResult, tapeId, model } = item;
            console.log('b');
            if (!rowsAndHeaders || !defaultMapResult || !tapeId) {
                if (!rowsAndHeaders) console.log('missing rows and headers');
                if (!defaultMapResult) console.log('missing defaultMapResult');
                if (!tapeId) console.log('missing tapeId');
                console.log('hey, missing stuff');
                throw new Error('missing tape info');
            }
            console.log('c');
            return of(item).pipe(
                logActivity("Finding templates"),
                updateStatus("SEARCHING_TEMPLATES"),
                switchMap(item => from(fetchMatchingTemplates(model.modelType, defaultMapResult)).pipe(
                    // delay(2000), // fake delay so i can see it.
                    tap(items => {
                        console.log('d');

                        if (items.length === 0) {
                            mergeBatchItem(item, {
                                status: "NO_TEMPLATES_FOUND",
                                matchResults: []
                            })
                        }

                        // cannot read property some of null.

                        const sellerOrgs = sellers.zSellers.get();
                        if (!sellerOrgs) throw new Error('expected sellers to exist. in future, pass this in the stream maybe?');

                        console.log('e');

                        const matchResults = items.map(x => examineMatch({
                            tapeId,
                            model,
                            rowsAndHeaders,
                            sellers: sellerOrgs,
                            template: x.template,
                            hashConfidence: x.confidence
                        }))

                        const validResults = matchResults.filter(x => x.type !== "invalid") as UnassignedValidMatchResult[];
                        if (validResults.length === 0) {
                            mergeBatchItem(item, {
                                status: "NO_VALID_TEMPLATES_FOUND",
                                matchResults: [],
                                debugMatchResults: matchResults
                            })
                        } else {
                            mergeBatchItem(item, {
                                status: "TEMPLATES_FOUND",
                                matchResults: validResults,
                                debugMatchResults: matchResults
                            })
                        }
                    }),
                    map(x => item.index)
                )),
                catchError(err => {
                    mergeBatchItem(item, { error:true, errorMsg:String(err) });
                    return of(item.index);
                })
            )
        })
    )
}
