import { GQL, labels, PriceTag, util, format } from 'market-dto';
import { FN_NAME } from './org-ids';
import { debugColumnsHeaders } from './debug-column-headers';

const getAdditionalDebugFields = (tag:PriceTag):string[] => {
    const obj:any = tag.parameters || {};
    return debugColumnsHeaders.map(header => {
        const val = obj[header];
        // DO NOT FORMAT THEM. these are raw values from AIG spreadsheet for DEBUGGING.
        return val ? val : '';
    })
}

const formatPriceTagChildren = (types:string[], parent:PriceTag | undefined):any[] => {
    return types.map(type => {
        if (!parent) return null;
        const child = parent.tags.find(x => x.type === type);
        if (!child) return null;
        return format.toFourDecimals(child.price);
    })
}

const numDiff = (p1:PriceTag | undefined, p2:PriceTag | undefined):string|undefined => {
    if (p1 && p2) {
        console.log(p1.price, p2.price, p1.price - p2.price, format.toFourDecimals(p1.price - p2.price));
        return format.toFourDecimals(p1.price - p2.price);
    }
    return undefined;
}


const getDistinctPriceChildTypes = (offers:GQL.Offer[]):string[] => {
    const arr = offers.map(offer => {
        if (!offer.priceTag) return [];
        const cwParent = offer.priceTag.tags.find(x => x.type === 'CASH_WINDOW');
        if (!cwParent) return [];
        return cwParent.tags.filter(tag => tag.price > 0).map(tag => tag.type);
    })
    return util.toDistinct(arr.flat());
}

const getPricingDebugCols = (loan:GQL.Loan, childTypes1:string[], childTypes2:string[]):string[] => {

    // Return empty array if problems. We filter out empty array rows.

    console.log('loan offers', loan.offers);
    const offer1 = loan.offers?.find(x => x.investor === 'Fannie'); // yuck
    const offer2 = loan.offers?.find(x => x.investor === FN_NAME); // YUCK. comparing based on the name

    if (!offer1 || !offer2) return [];
    const ptag1 = offer1.priceTag; // Root price tag
    const ptag2 = offer2.priceTag;

    if (!ptag1 || !ptag2) return [];

    const cw1 = ptag1.tags.find(x => x.type === 'CASH_WINDOW');
    const cw2 = ptag2.tags.find(x => x.type === 'CASH_WINDOW');
    const interest1 = ptag1.tags.find(x => x.type === 'INTEREST_SPREAD');
    const interest2 = ptag2.tags.find(x => x.type === 'INTEREST_SPREAD');
    const llpa1 = ptag1.tags.find(x => x.type === 'LLPA');
    const llpa2 = ptag2.tags.find(x => x.type === 'LLPA');
    const srp1 = ptag1.tags.find(x => x.type === 'SRP');
    const srp2 = ptag2.tags.find(x => x.type === 'SRP');

    return [
        loan.id,
        loan.loanNumber,
        loan.rawProductCode,
        format.loanValue('loanPurpose', loan.loanPurpose),
        format.loanValue('noteRate', loan.noteRate),
        format.loanValue('term', loan.term),
        format.loanValue('currentBalance', loan.currentBalance),
        format.loanValue('fico', loan.fico),
        format.loanValue('state', loan.state),
        format.toFourDecimals(ptag1.price),
        format.toFourDecimals(ptag2.price),
        numDiff(ptag1, ptag2),
        cw1 ? format.toFourDecimals(cw1.price) : null,
        cw2 ? format.toFourDecimals(cw2.price) : null,
        numDiff(cw1, cw2),
        // price tag children HAD been here.
        interest1 ? format.toFourDecimals(interest1.price) : null,
        interest2 ? format.toFourDecimals(interest2.price) : null,
        numDiff(interest1, interest2),
        llpa1 ? format.toFourDecimals(llpa1.price) : null,
        llpa2 ? format.toFourDecimals(llpa2.price) : null,
        numDiff(llpa1, llpa2),
        srp1 ? format.toFourDecimals(srp1.price) : null,
        srp2 ? format.toFourDecimals(srp2.price) : null,
        numDiff(srp1, srp2),
        ...formatPriceTagChildren(childTypes1, cw1),
        ...formatPriceTagChildren(childTypes2, cw2),
        ...getAdditionalDebugFields(ptag2)
    ]
}


export const getPricingDebugHeadersAndRows = (sheet:GQL.LoanModelSheet) => {

    const { loans } = sheet;

    const mtradeOffers = loans
        .map(loan => loan.offers?.find(x => x.investor === 'Fannie')) // yuck
        .filter(x => x) as GQL.Offer[];
    const aigOffers = loans
        .map(loan => loan.offers?.find(x => x.investor === FN_NAME)) // yuck
        .filter(x => x) as GQL.Offer[];

    const childTypes1 = getDistinctPriceChildTypes(mtradeOffers);
    const childTypes2 = getDistinctPriceChildTypes(aigOffers);
    

    const headers = [
        labels.byLoanField('id'),
        labels.byLoanField('loanNumber'),
        labels.byLoanField('rawProductCode'),
        labels.byLoanField('loanPurpose'),
        labels.byLoanField('noteRate'),
        labels.byLoanField('term'),
        labels.byLoanField('currentBalance'),
        labels.byLoanField('fico'),
        labels.byLoanField('state'),
        'mTrade Price',
        'AIG Price',
        'Price Diff',
        'mTrade Cash Window',
        'AIG Cash Window',
        'Cash Window Diff',
        // child tags had been here
        'mTrade Interest Spread',
        'AIG Interest Spread',
        'Interest Spread Diff',
        'mTrade LLPA',
        'AIG LLPA',
        'LLPA Diff',
        'mTrade SRP',
        'AIG SRP',
        'SRP Diff',
        ...childTypes1.map(x => 'mTrade ' + x),
        ...childTypes2.map(x => 'AIG ' + x),
        ...debugColumnsHeaders.map(x => 'AIG ' + x.toUpperCase())
    ]

    const rows = sheet
        .loans
        .map(loan => getPricingDebugCols(loan, childTypes1, childTypes2))
        .filter(arr => arr.length > 0);

    return {
        headers,
        rows
    }

}
