import React from 'react';
import { Box, Button, modal } from 'mui';
import { tapeImporter } from 'services';

interface Props {
    readonly nextStep:() => void;
    readonly item:tapeImporter.BatchItem;
	readonly batch:tapeImporter.Batch;
}
export const TabsModal = ({ batch, item, nextStep }:Props) => {

    // What do we do...
    const tryThis = () => {
        tapeImporter.assumeFirstTab(item);
        nextStep();
        modal.close();
    }

    return (
        <Box mt="2">
            HERE WE GO<br/><br/>
            <textarea readOnly value={JSON.stringify(item.tabNameAndRows, null, 4)}></textarea>

            <Button
                onClick={tryThis}
            >Assume 1st tab, 1st row is headers, all rest is data</Button>
        </Box>
    )
}