import React, { useEffect, useState, useRef } from 'react';
import { EditableRuleTableCol } from './param-table.service';
import { IconButton } from 'mui';
import { ParamTableCol } from './param-table-col';
import { useOnClickOutside } from 'hooks';


// TODO: clean up types. i don't like seeing string|number|undefined everywhere. RowVal

interface Props {
    readonly cols:EditableRuleTableCol[];
    readonly editting:boolean;
    readonly vals:Array<string | number | undefined| null>;
    readonly allowDelete:boolean;
    readonly onChange:(vals:Array<string|number|undefined|null>) => void;
    readonly onRemove:() => void;
    readonly onAddAfter:() => void;
    readonly toggleEditting:() => void;
}
export const ParamTableRow = ({ cols, vals, editting, allowDelete, onChange, onRemove, onAddAfter, toggleEditting }:Props) => {

    const trRef = useRef<HTMLTableRowElement>(null);
    useOnClickOutside(trRef, () => {
        if (editting) {
            toggleEditting();
        }
    })
    
    const onColValueChange = (colIndex:number, newVal:string|number|null) => {
        onChange(vals.map((val, n) => n === colIndex ? newVal : val));
    }

    return (
        <tr ref={trRef}>
            { cols.map((col, n) => (
                <ParamTableCol
                    key={n}
                    col={col}
                    val={vals[n]}
                    editting={editting}
                    onChange={(val:string|number) => onColValueChange(n, val)}
                />
            ))}
            <td className="right">
                { editting && allowDelete && (
                    <>
                        <IconButton fg="faded" name="trash" onClick={onRemove} />
                        <IconButton name="plus" fg="default" onClick={onAddAfter} />
                    </>
                )}
                <IconButton name={editting ? 'close' : 'edit' } onClick={toggleEditting} />
            </td>
        </tr>
    )
}