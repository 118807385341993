import { Button, Flex, FrmDropdown, Input } from "mui"
import React, { useState } from 'react';
import { SimpleConstructEdit } from "./ui-hints"

interface EditorArgs {
	hint?:SimpleConstructEdit
	value?:any
	updateHandler?:Function
	cancelHandler?:Function
}

export const SimpleEditor = (props:EditorArgs) => {
	const { hint, value, updateHandler, cancelHandler } = props
	const [targetValue, setTargetValue] = useState(value)
	const onCancel = () => {
		if (cancelHandler) {
			cancelHandler()
		}
	}
	const onUpdate = () => {
		if (updateHandler) {
			updateHandler(targetValue)
		}
	}
	return (hint) ?
	       <div>
		       <Flex justifyContent="flex-end" mt="1">
			       <Button size="sm" type="link" onClick={onCancel} mr="1" icon="close"/>
		       </Flex>
		       {hint.type === 'float' && (
			       <Input
				       numeric={{ float: true }}
				       placeholder={hint.placeholder}
				       value={targetValue}
				       setValue={val => {setTargetValue(val)}}
			       />
		       )}
		       {hint.type === 'int' && (
			       <Input
				       numeric={{ int: true }}
				       placeholder={hint.placeholder}
				       value={targetValue}
				       setValue={val => {setTargetValue(val)}}
			       />
		       )}
		       {hint.type === 'dropdown' && (
			       <FrmDropdown
				       size="sm"
				       label=""
				       placeholder={hint.placeholder}
				       isMulti
				       items={hint.options!}
				       vals={targetValue}
				       setValue={val => {setTargetValue(val)}}
			       />
		       )}
		       <Flex justifyContent="flex-end" mt="1">
			       <Button size="sm" type="link" onClick={onUpdate} mr="1">update</Button>
		       </Flex>
	       </div>
	              : <></>
	
}
