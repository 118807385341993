import React from 'react';
import { Box } from '../box/box';
import { NavLink } from '../nav-link/nav-link';
import { Text } from '../text/text';
import { GroupedMenuGroupItem } from './index';
import { useMatch, useResolvedPath } from 'react-router-dom';

interface Props {
    readonly item:GroupedMenuGroupItem;
}
export const NavItem = ({ item }:Props) => {

    // Note: we might need useResolvedPath if we ever are outside the route context
    const active = useMatch(item.value);
    // const selected = useRouteMatch({ path: item.value })

    return (
        <Box
            py="half"
            fontSize="sm"
            cursor={active ? 'default': 'pointer'}
        >
            { active ? (
                <Text fg="loud">{ item.label }</Text>
            ) : (
                <NavLink to={item.value}>{ item.label }</NavLink>
            )}
        </Box>
    );
}
