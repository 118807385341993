import { GQL } from "market-dto";
import { isTrulyEligible } from '../loans';
import { isFannieEligible } from '../fannie';
import { isFreddieEligible } from '../freddie';
import { LoanFilters } from './types';

const passesEligibility = (loan:GQL.Loan, filters:LoanFilters) => {
    if (filters.eligibility === 'show-all') return true;
    // If you get this far, you MUST be truly eligible
    if (!isTrulyEligible(loan)) return false;
    if (filters.eligibility === 'any-eligible') return true;
    if (filters.eligibility === 'fannie-only') return isFannieEligible(loan);
    if (filters.eligibility === 'freddie-only') return isFreddieEligible(loan);
    return false;
}

const passesFilter = (loan:GQL.Loan, filters:LoanFilters) => {

    if (!passesEligibility(loan, filters)) return false;
    
	const txt = filters.text.trim().toLowerCase();
	if (txt === '') return true;
    const found = filters.fields.find(fieldId => {        
        const val = String(loan[fieldId]);
        if (val && val.trim().toLowerCase().includes(txt)) return true;
        return false;
    })
    return found ? true : false;
}

export const applyLoanFilters = (loans:GQL.Loan[], filters?:LoanFilters) => {
    if (!filters) return loans;
    return loans.filter(loan => passesFilter(loan, filters));
}
