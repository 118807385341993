import React from 'react';
import { WhenEnumInDetails } from './types';
import { WhenValuePassed } from './when-value-passed';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const Success = ({ details }:Props) => {
    return (
        <>
            <WhenValuePassed details={details} />
            <div>
                The underlying value for "{ details.takeLabel }" was found to be "{ details.takeVal }",&nbsp;
                { details.defaultUsed && (
                    <>
                        so we used the default value "{ details.finalVal }"
                    </>
                ) }
                { !details.defaultUsed && (
                    <>
                        and that's how we ended up with "{ details.finalVal }"!
                    </>
                ) }
            </div>
        </>
    )
}