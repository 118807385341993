import { mutate, stripInternals, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const INSERT = gql`
    mutation insertOrg($objects: [org_insert_input!]) {
        insert_org(objects: $objects) {
            errorMessage
            affectedRow
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateOutput {
    readonly insert_org: {
        readonly returning:[GQL.Org]; // expect just one
    }
}

interface MutateInput {
    readonly objects:[Partial<GQL.Org>]
}
export const insertOrg = async (org:Partial<GQL.Org>):Promise<GQL.Org | undefined> => {
	const result =  await mutate<MutateInput, MutateOutput>(INSERT, { objects: [stripInternals(org)] });
    return result?.data?.insert_org.returning[0];
}
