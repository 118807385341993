import { mergeBatchItem } from '../merge-batch-item';
import { BatchItem, BatchItemStatus } from '../types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const updateStatus = (status:BatchItemStatus) => (item$:Observable<BatchItem>):Observable<BatchItem> => {
    return item$.pipe(
        tap(item => mergeBatchItem(item, { status }))
    )
}

// Note: can also be written as:
// export const updateStatus = (status:BatchItemStatus) => tap<BatchItem>(item => updateBatchItemStatus(item, status))
