import { Box, Button } from "mui"
import {
	AdjTableBuilder, AdjustmentLookup, crossProductDelimiters, genCategoricalDelimiters,
	genLtvDelimiters, genTwoDimArray, getFicoDelimiters, getDelimitersLength
} from "raccoon-engine"
import React from 'react';

const onProductFicoByLtv = () => {
	const ltvDelimiters = genLtvDelimiters({})
	const ficoDelimiters = getFicoDelimiters({})
	const loanPrograms = ["Full", "Half"]
	const programDelimiters = genCategoricalDelimiters(`loan programs`, loanPrograms);
	console.log({ programDelimiters })
	const branchDelimiters = crossProductDelimiters(programDelimiters, [ficoDelimiters])
	console.log({branchDelimiters})
	const initialValues = genTwoDimArray(getDelimitersLength(branchDelimiters[0]), ltvDelimiters.ranges!.length)
	const table = new AdjTableBuilder()
		.title("Product Specified - Fico By LTV")
		.leaf("ltv")
		.leafDelimiters(ltvDelimiters)
		.branches(["loanProgram", "fico"])
		.branchDelimiters(branchDelimiters)
		.values(initialValues)
		.compose()
	const lookUp = new AdjustmentLookup(table)
	const result = lookUp.lookUp({ fico: 545, ltv: 0.77, loanProgram: "half" })
	console.log({ result, table })
}
const onFicoByLtv = () => {
	const ltvDelimiters = genLtvDelimiters({})
	const ficoDelimiters = getFicoDelimiters({})
	const table = new AdjTableBuilder()
		.title("LLPA/LLRA Fico By LTV")
		.leaf("ltv")
		.leafDelimiters(ltvDelimiters)
		.branches(["fico"])
		.branchDelimiters([ficoDelimiters])
		.values(genTwoDimArray(ficoDelimiters.ranges!.length, ltvDelimiters.ranges!.length))
		.compose()
	const lookUp = new AdjustmentLookup(table)
	const result = lookUp.lookUp({ fico: 545, ltv: 0.77 })
	console.log({ result, table })
}


export const ScaffoldFicoLtv = () => {
	return <Box mt="2" bg="card">
		
		<Box fontSize="lg" mb="2">LLPA/LLRA</Box>
		<div style={{ display: "flex" }}>
			<div>
				<Box fontWeight="bold">FICO by LTV<Button size="xs"
				                                          onClick={onFicoByLtv}>Select</Button></Box>
				<img
					src="https://res.cloudinary.com/twks/image/upload/v1614800392/mtrade/iShot2021-03-03_13.39.34_q4jols.png"
					alt=""/>
			</div>
			<div>
				<Box fontWeight="bold">LTV by FICO </Box>
				<img
					src="https://res.cloudinary.com/twks/image/upload/v1614801179/mtrade/iShot2021-03-03_13.52.51_le4ot9.png"
					alt={""}/>
			</div>
			<div>
				<Box fontWeight="bold">Program Specified - FICO by LTV
					<Button onClick={onProductFicoByLtv}
					        size="xs">Select</Button></Box>
				<img
					src="https://res.cloudinary.com/twks/image/upload/v1614801804/mtrade/iShot2021-03-03_14.03.14_nxyg6k.png"
					alt=""/>
			</div>
			<div>
				<Box fontWeight="bold">Program Specified - LTV by FICO
					</Box>
				<img
					src="https://res.cloudinary.com/twks/image/upload/v1614801886/mtrade/iShot2021-03-03_14.04.30_jgo0od.png"
					alt=""/>
			</div>
		</div>
	</Box>
}
