import { query, gql, GQL } from "../../client"
import { RETURNING } from './returning';

const QUERY = gql`
    query models($where: model_bool_exp!) {
        model(where: $where) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryResult {
    readonly model:GQL.Model[];
}

export const fetchAllModels = async ():Promise<GQL.Model[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {}
    })
    return result.data.model;
}
