import { gql, GQL, query } from "../../../client";
import { CONSTRUCT_RETURNING } from './construct-returning';

interface QueryInput {
	readonly where:GQL.WhereString;
}

interface QueryResult {
	readonly eligibilityClassifier:GQL.EligibilityClassifier[];
}

const QUERY = gql`
    query fetchEligibilityClassifiers($where: where_eligibilityClassifier_exp!) {
        eligibilityClassifier(where:$where) { ${CONSTRUCT_RETURNING} }
    }
`;

export const fetchEligibilityClassifier = async (id:string):Promise<GQL.EligibilityClassifier> => {
	const result = await query<QueryInput, QueryResult>(QUERY, { where: { id: { _eq: id } } })
	const [e, ...rest] = result.data.eligibilityClassifier
	return e
}
