import React from 'react';
import { format } from 'services';
import { Text, HighlightText } from 'mui';
import { sheets, viewLoans } from 'services';
import { DisplayLoanValue } from 'components'

interface Props {
    readonly row:viewLoans.LoanAndValuesAndSources;
    readonly field:viewLoans.LoanTableFieldConfig;
    readonly expanded:boolean;
    readonly filters:sheets.LoanFilters;
}
export const LoanTableColTop = ({ row, field, filters, expanded }:Props) => {
    const loan = row.loan;
    return (
        <td style={{ verticalAlign:'top' }}>
            <Text fg={expanded ? 'loud' : 'default'} fontSize="xs">
                <DisplayLoanValue loan={loan} fieldId={field.id} filters={filters} />
            </Text>
        </td>
    )
}