import { ExpNode, EXP_INVALID_CHAR } from './exp-types';
import { GenericWalkerOpts, genericWalker } from './generic-walker';

export const isValidExpNode = (node:ExpNode | null):boolean => {
    return genericWalker({
        parent: null,
        node,
        acc: true,
        lvl: 0,
        fn: (x:GenericWalkerOpts<boolean>) => {
            const { node, acc:valid, childAcc:childrenValid } = x;
            if (!valid) return false; // once invalid, always invalid.
            if (node === null) return true;
            if (childrenValid !== undefined && !childrenValid) return false; // KIDS INVALID
            switch (node.type) {
                case 'ArrayNode': return node.endBracketFound;
                case 'ConstantNode': return node.value !== EXP_INVALID_CHAR;
                case 'ExpressionNode': return node.isValidOp;
                case 'FunctionCallNode': return node.isValidFnName && node.endParenFound;
                case 'IdentifierNode': return node.isValid;
                case 'ListItemNode': return node.hasValidPrepend;
                case 'ParenScopedNode': return node.endParenFound;
                case 'StringConstantNode': return node.endQuoteFound;
                case 'UnaryNode': return true; // hmmm
            }
            return true;
        }
    })
}