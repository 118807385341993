import { RowsAndHeaders, PriceTag, createTag, sheetParser, compositionMode, PricingType } from 'market-dto';
import { debugColumnsHeaders } from './debug-column-headers';

type StringDict = { [k: string]: string };

type PriceTagAndDates = {
	readonly tag: PriceTag;
	readonly acquisitionDate: string;
	readonly securityDate: string;
}

type PriceTagAndDatesByLoanNumber = { [loanNumber: string]: PriceTagAndDates };


const NOT_FOUND_VAL = 0;

const getVal = (item: StringDict, k: string): number => {
	try {
		return (item as any).hasOwnProperty(k) ? parseFloat(item[k]) : NOT_FOUND_VAL;
	} catch (err) {
		return NOT_FOUND_VAL;
	}
}

const makeTag = (dict:StringDict, key:string, tagType:compositionMode, pricingType:PricingType, children?:string[]): PriceTag => {
	const tag = createTag({
		name: key,
		composition: tagType,
		price: getVal(dict, key),
		type: pricingType
	})
	if (children) {
		children.forEach(childKey => {
			tag.tags.push(createTag({
				name: childKey,
				composition: tagType, // doesn't matter, not used
				price: getVal(dict, childKey),
				type: pricingType// children will get this for now.
			}))
		})
	}
	return tag;
}



const stringDictToPriceTag = (rootName: string, dict: StringDict): PriceTag => {
	// TODO: "name" is given same value as "key". Add a lookup table for pretty names.
	const basePriceTag = makeTag(dict, "base px", "best", "CASH_WINDOW", ["min base px", "max base px"]);
	const spreadTag = makeTag(dict, "spread", "sum", "INTEREST_SPREAD", ["interest income", "interest expense"]);
	// We cannot get more fine-grained with delivery tag, because the excel formula accesses a different tab.
	const deliveryCreditTag = makeTag(dict, "delivery credit", "sum", "UNSPECIFIED");
	const srpTag = makeTag(dict, "srp", "sum", "SRP", ["gross srp", "no escrow adj"]);
	const llpaTag = makeTag(dict, "llpas", "sum", "LLPA", [
		"amdc",
		"risk based",
		"cashout refi",
		"sub finance",
		"invest property",
		"condo",
		"2 unit",
		"3-4 unit",
		"co-op",
		"second home",
		"high ltv",
		"low fico",
		"high bal",
		"high bal cor"
	]);
	// We cannot get more fine-grained with margin tag, because the excel formula accesses a different tab.
	const marginTag = makeTag(dict, "margin", "sum", "SELLER_MARGINS");
	const rootTag = createTag({
		name: rootName,
		composition: "sum",
		price: getVal(dict, 'market value'),
		type: "UNSPECIFIED"
	});
	rootTag.tags = [
		basePriceTag,
		spreadTag,
		deliveryCreditTag,
		srpTag,
		llpaTag,
		marginTag
	];
	return rootTag;
}


export const toPriceTagByLoanNumber = (rootName:string, rowsAndHeaders:RowsAndHeaders):PriceTagAndDatesByLoanNumber => {
	// This is just straight forward for now--NOTHING FANCY FOR NOW, no guessing...
	// Headers must have EXACT values. For now.
	const dicts: StringDict[] = rowsAndHeaders.rows.map(row => {
		let obj: any = {};
		rowsAndHeaders.headers.forEach((h, n) => obj[h] = row[n]);
		return obj;
	});
	const loanNumberFieldName = 'loan number'; // Rmemeber, always lower case for headers from parsed sheet!
	const result: PriceTagAndDatesByLoanNumber = {};
	dicts.forEach(dict => {
		// Not just does it have this property, but, a valid value?
		if (dict[loanNumberFieldName] && dict[loanNumberFieldName].trim()) {
			const priceTag = stringDictToPriceTag(rootName, dict);

			// Extra fields we want to hold onto in the price tag.
			const debugObj:any = {};
			debugColumnsHeaders.forEach(colName => debugObj[colName] = dict[colName]);
			priceTag.parameters = debugObj;

			result[dict[loanNumberFieldName].trim()] = {
				tag: priceTag,
				acquisitionDate: dict['acquisition date'],
				securityDate: dict['security date']
			}
		}
	});
	// console.log('this is the sheetToPriceTagByLoanNumber', result);
	return result;
}