export const isValidEmail = (s?:string):boolean => {
    if (!s) return false;
    // eslint-disable-next-line no-useless-escape
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(s);
}


const whiteListDomains = [
    "mtrade.com",
    "mtrade.net",
    "aig.com",
    "fannie.com"
]

export const isWhiteListed = (email:string):boolean => {
    const domain = email.split('@')[1];
    return whiteListDomains.includes(domain);
}
