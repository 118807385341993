import React, { useState } from 'react';
import { Box, CardTitle, Divider, Flex, FlexVCenter, Button, IconButton, IntoPortal, Input } from 'mui';
import { JSONSchema } from 'market-dto';

interface Props {
    readonly schema:JSONSchema;
    readonly portalId?:string;
    readonly onCancel?:() => any;
    readonly onSave?:() => any;
    readonly onEdit?:() => any;
    readonly onFilterChange:(s:string) => void;
    readonly filter:string;
    readonly saving:boolean;
    readonly editting:boolean;
    readonly dirty:boolean;
}
export const SchemaUiMainTitle = ({
    editting,
    dirty,
    saving,
    filter,
    schema,
    portalId,
    onCancel,
    onFilterChange,
    onSave,
    onEdit
}:Props) => {    

    const render = <>
        <Box pb="2" borderWidth="thick" borderSides="bottom" borderColor="alert">
            <Box>
                <Box fontSize="lg" fg="default" fontWeight="bold">{ schema.title ? schema.title : 'UNTITLED' }</Box>
                <Box fg="faded" fontSize="sm">{ schema.description }</Box>
            </Box>
            <FlexVCenter mt="1">
                <Box flex="1">
                    <Input value={filter} setValue={onFilterChange} appendIcon="search" placeholder="Search settings" />
                </Box>
                <FlexVCenter flex="1" justifyContent="flex-end">
                    { !editting && <IconButton name="edit" onClick={onEdit} /> }
                    { editting && (
                        <>
                            <Button
                                size="sm"
                                type="link"
                                onClick={onCancel}
                                mr="1"
                            >Cancel</Button>
                            <Button
                                size="sm"
                                onClick={dirty ? onSave : undefined}
                                type={dirty ? "default" : "disabled"}
                                busy={saving}
                            >Save</Button>
                        </>
                    )}
                </FlexVCenter>
            </FlexVCenter>
        </Box>
    </>

    return portalId ? <IntoPortal id={portalId}>{ render }</IntoPortal> : render;

}