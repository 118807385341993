import { query, gql } from "../../client";
import { WhenEligible } from 'raccoon-engine';

interface QueryResult {
    readonly maxLtvEligible: [{
        readonly rules:WhenEligible[];
    }];
}

const QUERY = gql`
    query {
        maxLtvEligible {
            rules
        }
    }
`;

export const fetchMaxLtvRules = async ():Promise<WhenEligible[]> => {
    const result = await query<any, QueryResult>(QUERY, {});
    return result.data.maxLtvEligible[0].rules;
}
