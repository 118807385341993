import React, { useState } from 'react';
import {
    Box,
    IconButton,
    usePageSortTable,
    SortableTableHeader,
    TablePageButtons
} from 'mui';
import { GQL, labels } from 'market-dto';
import { format } from 'services';

interface ServicersTableProps {
    readonly filteredServicers:GQL.Servicer[];
    readonly onEdit:(org:GQL.Servicer) => void;
}
export const ServicersTable = ({ filteredServicers, onEdit }:ServicersTableProps) => {

    const { sortBy, asc, buttons, page, totalPages, setPage, sortedBy, sortedRows, pageSortState:ps } = usePageSortTable<GQL.Servicer, keyof GQL.Servicer>({
        rows: filteredServicers,
        keyFn: row => JSON.stringify(row), // any change to anything in row, re-render
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    return (
        <Box mb="2">
            <table className="table">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render={labels.byServicerField('name')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="symbol" render={labels.byServicerField('symbol')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="fannieServicerNumber" render={labels.byServicerField('fannieServicerNumber')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="fee" render={labels.byServicerField('fee')} align="right" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="suspended" render={labels.byServicerField('suspended')} />
                        <th style={{ width:'1%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((row, n) => (
                        <tr key={row.id}>
                            <td>{ row.name }</td>
                            <td>{ row.symbol }</td>
                            <td>{ row.fannieServicerNumber }</td>
                            <td className="right">{ row.fee ? format.toCurrencyWithoutCents(row.fee) : 'NA' }</td>
                            <td>{ row.suspended ? 'Yes' : 'No' }</td>
                            <td className="right"><IconButton name="edit" size="sm" onClick={() => onEdit(row)} /></td>
                        </tr>
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </Box>
    )
}