import { CashWindow } from 'market-dto';
import { utils, format } from 'services';
import { differenceInCalendarDays, addDays, isWeekend } from 'date-fns';

const dtKey = (aquisitionDate:Date, deliveryDate:Date):string => format.dateToYyyyMmDd(aquisitionDate) + ':' + format.dateToYyyyMmDd(deliveryDate);

export const to2dHoldingInterestArray = (arr:CashWindow.HoldingInterest[], removeWeekends?:boolean):{
    aquisitionDates:Date[];
    deliveryDates:Date[];
    arr2d:Array<Array<CashWindow.HoldingInterest|null>>;
} => {

    const minAquisutionDateItem = utils.getMin(arr, x => x.acquisitionDate.getTime());
    const maxAquisutionDateItem = utils.getMax(arr, x => x.acquisitionDate.getTime());
    const minDeliveryDateItem = utils.getMin(arr, x => x.target.getTime());
    const maxDeliveryDateItem = utils.getMax(arr, x => x.target.getTime());

    if (!minAquisutionDateItem || !maxAquisutionDateItem || !minDeliveryDateItem || !maxDeliveryDateItem) {
        console.log('Something went wrong. Could not find min/max aquisition/delivery dates!');
        return {
            aquisitionDates: [],
            arr2d: [],
            deliveryDates: []
        }
    }

    // A dictionary of the holding interest items by a key composed of both dates
    const byDates = utils.toDict(arr, x => dtKey(x.acquisitionDate, x.target));

    // console.log('BY DATES', byDates);

    const x1 = minDeliveryDateItem.target;
    const x2 = maxDeliveryDateItem.target;
    const xDayDiff = differenceInCalendarDays(x2, x1);

    const y1 = minAquisutionDateItem.acquisitionDate;
    const y2 = maxAquisutionDateItem.acquisitionDate;
    const yDayDiff = differenceInCalendarDays(y2, y1);


    // First, find out the x axis days that we will display.
    const xDays = utils.range(xDayDiff).map(n => addDays(x1, n)).filter(d => !removeWeekends || !isWeekend(d));
    const yDays = utils.range(yDayDiff).map(n => addDays(y1, n)).filter(d => !removeWeekends || !isWeekend(d));

    const arr2d = yDays.map(aquisitionDate => {
        return xDays.map(deliveryDate => {
            const k = dtKey(aquisitionDate, deliveryDate);
            const item = byDates[k];
            if (!item) return null;
            if (item.interestSpreadPct <= 0) return null;
            return item;
        })
    })

    // console.log('--------------------------------------------', xDays, yDays, arr2d);

    return {
        aquisitionDates:yDays,
        deliveryDates:xDays,
        arr2d
    }
}