import { sheetMaker } from 'market-dto';
import { HitInfo, REQUIRED_FIELDS, getOptFieldsFromStorage, getHitFieldLabel, formatHitField } from '../proposal';
import { downloadFromArrayBuffer } from '../downloader';

export const genSellerConfirmationSheet = async (prefix:string, hitInfo:HitInfo) => {

    const { sellerName } = hitInfo.sheetSummary;

    const fields = [...REQUIRED_FIELDS, ...getOptFieldsFromStorage()].filter(x => x !== 'cashWindow');
    const headers = fields.map(fieldId => getHitFieldLabel(fieldId));

    const rows = hitInfo.loans.map(loan => {
        return fields.map(fieldId => {
            if (fieldId === 'productCode') {
                // special case for product code
                return loan.productCode + ' / ' + loan.investorProductId;
            }
            return formatHitField(fieldId, loan[fieldId])
        })
    })

    const arrBuf = await sheetMaker.makeSheet(rows, "Hit Loans");
    const filename = prefix + '-' + sellerName + '-confirmation.xlsx';
    downloadFromArrayBuffer(arrBuf, filename);
}