import { ExpContext, ExpNode, EXP_INVALID_CHAR, ExpValueType, ExpWarning, ExpParseResult } from './exp-types';
import { genericWalker, GenericWalkerOpts } from './generic-walker';
import { operatorDefByName } from './operator-defs';

/*
    THIS IS THE TYPE CHECKER
*/

export interface ValueAndType {
    readonly val?:number|string|boolean;
    readonly rawVal?:string|number|boolean;
    readonly type:ExpValueType;
}

const getTypeByModel = (id:string, expCtx:ExpContext):ExpValueType => {
    const t = expCtx.fieldByName[id];
    if (!t) return 'unknown';
    if (t.schema.type === 'integer') return 'number'; // weird exception
    return t.schema.type; // rest line up, one-to-one.
}

const retType = (node:ExpNode, valType:ExpValueType):ExpValueType => {
    node.valType = valType; // MUTABLE
    return valType;
}

const retWithWarning = (node:ExpNode, warnings:ExpWarning[], msg:string):ExpValueType => {
    warnings.push({
        nodeId: node.id,
        msg
    })
    node.warning = msg;
    node.valType = 'unknown';
    return node.valType;
}

export const typeChecker = (rootNode:ExpNode, expCtx:ExpContext):ExpParseResult => {

    const warnings:ExpWarning[] = [];
    genericWalker({
        acc: "boolean",
        initChildAcc: [], // was "unknown"
        lvl: 0,
        parent: null,
        node: rootNode,
        fn: (x:GenericWalkerOpts<ExpValueType|ExpValueType[]>):ExpValueType|ExpValueType[] => {
            const { acc, initChildAcc, fn, lvl, node, childAcc } = x;
            if (node === null) return 'unknown';
            
            switch (node.type) {
                case 'ConstantNode': {
                    if (node.value === EXP_INVALID_CHAR) return retType(node, 'unknown')
                    return retType(node, 'number')
                }
                case 'StringConstantNode': {
                    return retType(node, 'string')
                }
                case 'IdentifierNode': {
                    const idType = getTypeByModel(node.value, expCtx);
                    return retType(node, idType)
                }
                case 'ExpressionNode': {
                    if (!node.isValidOp) return retType(node, 'unknown');
                    if (Array.isArray(acc)) return retType(node, 'unknown');
                    if (acc === 'unknown') return retType(node, 'unknown');
                    if (!childAcc) return retType(node, 'unknown');
                    if (Array.isArray(childAcc)) return retType(node, 'unknown');
                    if (childAcc === 'unknown') return retType(node, 'unknown');
                    // Now we know we have value types left/right                    
                    if (acc !== childAcc) {
                        return retWithWarning(
                            node,
                            warnings,
                            'Mismatched types on sides of "' + node.op + '" (' + acc + ',' + childAcc + ')'
                        )
                    }
                    // at this point, acc type and child acc type are the same.
                    const opDef = operatorDefByName[node.op];
                    if (!opDef) throw new Error('How is isValidOp set to true? Missing op definition.');
                    if (!opDef.compareTypes.includes(acc)) {
                        // Note: "includes" on a tiny array might be faster than object lookup
                        return retWithWarning(node, warnings, 'Operator "' + node.op + '" cannot be used with data type ' + acc);
                    }
                    return retType(node, opDef.returnType);
                }
                case 'FunctionCallNode': {
                    const fn = expCtx.funcByName[node.fnName];
                    if (!fn) return retType(node, 'unknown');
                    if (!childAcc) return retType(node, 'unknown');
                    if (!Array.isArray(childAcc)) return retType(node, 'unknown');

                    if (fn.restArgs) {
                        // Must have at least args.length plus an additional rest arg
                        if (childAcc.length < fn.args.length+1) {
                            return retWithWarning(
                                node,
                                warnings,
                                `Function "${ fn.name }" expects at least ${ fn.args.length+1 } arguments (received ${ childAcc.length })`
                            )                        
                        }
                    } else {
                        if (fn.args.length !== childAcc.length) {
                            return retWithWarning(
                                node,
                                warnings,
                                `Function "${ fn.name }" expects ${ fn.args.length } argument${ fn.args.length === 1 ? '' : 's'} (received ${ childAcc.length })`
                            )                        
                        }
                    }

                    for (let i=0; i < childAcc.length; i++) {

                        const expectArr = i < fn.args.length ? fn.args[i] : fn.restArgs;
                        if (!expectArr) throw new Error('how can this happen?');
                        if (!expectArr.includes(childAcc[i])) {
                            return retWithWarning(
                                node,
                                warnings,
                                `Function "${ fn.name }" expects ${ expectArr.join(', ') } but received ${ childAcc[i] } type`
                            )
                        }
                    }
                    // You get here, you're good.
                    return retType(node, fn.returnType);
                }
                case 'ArrayNode': {
                    // TODO: ARRAYS!
                    return retType(node, 'unknown');
                }
                case 'ParenScopedNode': {
                    if (Array.isArray(childAcc)) return retType(node, 'unknown');
                    return childAcc ? retType(node, childAcc) : retType(node, 'unknown');
                }
                case 'ListItemNode': {
                    if (!acc) retType(node, 'unknown');
                    if (!childAcc) retType(node, 'unknown');
                    if (!Array.isArray(acc)) return retType(node, 'unknown');
                    if (Array.isArray(childAcc)) return retType(node, 'unknown');
                    // special case:
                    node.valType = childAcc;
                    return [...acc, childAcc!];
                }
                case 'UnaryNode': {
                    // can only be numeric (but child must be too)
                    if (acc !== 'number') {
                        return retWithWarning(node, warnings, 'Unary operator ' + node.op + ' can only be used with a number');
                    }
                    return retType(node, 'number');
                }
            }
            throw new Error('Unhandled node type');
        }
    })
    return {
        node: rootNode,
        warnings
    }
}