import { mutate, gql, GQL } from "../../client";

interface MutateOutput {
    readonly table:{
        readonly returning:[GQL.AdjTable];
    }
}

interface MutateInput {
    readonly data: Pick<GQL.AdjTable, "description" | "table">;
}

const getUpsertQuery = (name:GQL.AdjTableName) => {
    return gql`
        mutation ($data: ${ name }_set_input) {
            table: upsert_${ name }(set: $data) {
                errorMessage
                affectedRow
                returning {
                    id
                    description
                    table
                }
            }
        }
    `;
}

export const upsertAdjTable = async (name:GQL.AdjTableName, table:GQL.AdjTable) => {
    const result = await mutate<MutateInput, MutateOutput>(getUpsertQuery(name), {
        data: {
            description: table.description,
            table: table.table            
        }
    })
    // const client = getApolloClient(false);
	// const result = await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: getUpsertQuery(name),
	// 	variables: {
	// 		data: {
    //             description: table.description,
    //             table: table.table
    //         }
	// 	}
    // });
    return result?.data?.table.returning[0];
}