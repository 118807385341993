import React, { useState, useEffect, useRef } from 'react';
import { uiAdjTableToAdjustmentTable, adjustmentTableToUIAdjTable, UIAdjTable, Col, ColType } from './adj-table.service';
import { AdjustmentTable } from 'raccoon-engine';
import { AdjTableRow } from './adj-table-row';
import './adj-table.css';

interface ValuesArrayFormatting {
    readonly decimalPlaces:number;
}
interface Props {
    readonly table:AdjustmentTable;
    readonly onChange?:(t:AdjustmentTable) => any;
    readonly valuesFormatting?:ValuesArrayFormatting;
}
export const AdjTable = ({ table, onChange }:Props) => {

    console.log('TABLE', table);

    // const [ editting, setEditting ] = useState<boolean>(false);
    const [ uiTable, setUiTable ] = useState<UIAdjTable>();
    const [ editRow, setEditRow ] = useState<number>(-1);
    // const [ aTable, setATable ] = useState<AdjustmentTable>(table);

    const reset = () => {
        setUiTable(adjustmentTableToUIAdjTable(table));
        // setEditting(false);
    }

    useEffect(() => reset(), [table]);

    const setVal = (val:any, rowIndex:number, colIndex:number) => {
        const newUiTable = {
            ...uiTable,
            rows: uiTable!.rows.map((row, i) => i !== rowIndex ? row : row.map((col, j) => j !== colIndex ? col : {
                ...col,
                val: val
            }))
        }
        setUiTable(newUiTable);
        const newTable = uiAdjTableToAdjustmentTable(table, newUiTable);
        if (onChange) onChange(newTable);
        // setATable(newTable);
    }

    if (!uiTable) return null;

    // console.log('ui table', uiTable);

    return (
        <>
            {/* <textarea value={JSON.stringify(aTable, null, 4)} readOnly />
            <textarea value={JSON.stringify(uiTable, null, 4)} readOnly /> */}
            <table className="adj-table">
                <tbody>
                    { uiTable.rows.map((cols, rowIndex) => (
                        <AdjTableRow
                            key={'r' + rowIndex}
                            cols={cols}
                            rowIndex={rowIndex}
                            editting={editRow === rowIndex}
                            setEditRow={setEditRow}
                            setVal={setVal}
                        />
                    ))}
                </tbody>
            </table>
        </>
    )
}
