import React, { useState } from 'react';
import { Button, Box, Input, FlexVCenter, FrmInput } from 'mui';
import { GQL } from 'market-dto';
import { rule } from 'services';

interface Props {
    readonly model:rule.ModelInfo;
    readonly onChange:(name:string, description:string) => void;
}
export const EditModelNameModal = ({ model, onChange }:Props) => {

    const [ name, setName ] = useState(() => model.name);
    const [ desc, setDesc ] = useState(() => model.description);

    const canSubmit = name.trim() !== model.name.trim() || desc.trim() !== model.description.trim();    
    const submit = () => {
        if (!canSubmit) return;
        onChange(name.trim(), desc.trim());
    }

    return (
        <>
            <Box mt="2">
                <FrmInput width="100%" label="Name" value={name} setValue={setName} />
                <FrmInput width="100%" label="Description" value={desc} setValue={setDesc} />
            </Box>
            <Box mt="2" textAlign="right">
                <Button
                    onClick={submit}
                    type={canSubmit ? 'default' : 'disabled'}
                >Update</Button>
            </Box>
        </>
    )
}