export class ToastError extends Error {
    public toastMsg:string;
    constructor(msg:string) {
        super(msg);
        this.toastMsg = msg;
    }
}

export interface ApiCallOptions {
	readonly withCache?:boolean;
	readonly delay?:number;
    readonly useThisJwtInstead?:string;
    readonly doNotCatchCommonErrors?:boolean;
}