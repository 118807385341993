import React from 'react';
import { FlexVCenter, Box, Button, modal } from 'mui';
import { InvalidJson, FileDropArea } from 'components';
import { GQL } from 'market-dto';
import { downloader } from 'services';
import { useSafeState } from 'hooks';
import { upsertAdjTable } from 'api';

interface Props {
    readonly refreshTab:() => void;
    readonly isValidImportJson:(x:any) => string[];
    readonly buildExportObject:() => any;
    readonly exportJsonFilename:string;
    readonly label:string;
    readonly tables:ReadonlyArray<GQL.AgencySrpTableName | GQL.LlpaTableName>;
}
export const ImportExportModal = ({ refreshTab, isValidImportJson, buildExportObject, exportJsonFilename, label, tables }:Props) => {

    const [ exporting, setExporting ] = useSafeState(false);
    const [ importing, setImporting ] = useSafeState(false);

    const downloadJson = async () => {
        setExporting(true);
        const exportItem = await buildExportObject();
        // await utils.sleep(5000); // testing safeState
        downloader.downloadJsonFile(exportItem, exportJsonFilename);
        setExporting(false);
    }

    const onFileDrop = async (files:File[]) => {
        if (files.length !== 1) return;
        const file = files[0];
        const json = await file.text();
        let obj:any;
        try {
            obj = JSON.parse(json);
        } catch (err) {
            modal.confirm({
                title: 'Invalid JSON',
                hideCancel: true,
                ok: () => {}
            })
            return;
        }
        const invalidReasons = isValidImportJson(obj);
        if (invalidReasons.length > 0) {
            modal.confirm({
                title: <InvalidJson reasons={invalidReasons} />,
                hideCancel: true,
                ok: () => {}
            })
            return;
        } else {
            modal.disableClose();
            setImporting(true);
            await Promise.all(tables.map(tableName => {
                return upsertAdjTable(tableName, obj[tableName])
            }))
            setImporting(false);
            refreshTab();
            modal.close();
        }
    }

    return (
        <FlexVCenter>
            <Box flex="1" textAlign="center">
                <Button
                    busy={exporting}
                    onClick={downloadJson}
                    icon="download"
                >JSON</Button>
            </Box>
            <Box flex="1" mt="2">
                <FileDropArea
                    busy={importing}
                    onDrop={onFileDrop}
                    label={`Drop ${label} JSON here.`}
                    multi={false}
                    accept=".json"
                />
            </Box>
        </FlexVCenter>
    )
}
