import React, { useRef, useLayoutEffect } from 'react';
import { FlexVCenter, Icon, Box, PortalPlaceholder } from 'mui';

interface Props {
    readonly onClose:() => void;
    readonly children:React.ReactNode;
}
export const PopOverInner = ({ onClose, children }:Props) => {

    const closePop = () => {
        onClose();
    }

    const moverRef = useRef<HTMLDivElement>(null);
    const dragHandleRef = useRef<HTMLDivElement>(null);
    const offsetRef = useRef<[number, number]|null>(null);
    const initCoordsRef = useRef<[number, number]>([0,0]);

    useLayoutEffect(() => {

        const moverElem = moverRef.current;
        const dragHandleElem = dragHandleRef.current;
        if (!moverElem || !dragHandleElem) return;

        const mdown = (evt:MouseEvent) => {
            offsetRef.current = [evt.clientX, evt.clientY];
        }

        const mup = (evt:MouseEvent) => {
            if (offsetRef.current) {
                initCoordsRef.current = [parseInt(moverElem.style.left), parseInt(moverElem.style.top)];
                offsetRef.current = null;
            }
        }

        const mout = (evt:MouseEvent) => {
            if (offsetRef.current) {
                initCoordsRef.current = [parseInt(moverElem.style.left), parseInt(moverElem.style.top)];
                offsetRef.current = null;
            }
        }

        const mmove = (evt:MouseEvent) => {
            if (!offsetRef.current) return;
            const x = initCoordsRef.current[0] + (evt.clientX - offsetRef.current[0]);
            const y = initCoordsRef.current[1] + (evt.clientY - offsetRef.current[1]);
            moverElem.style.left = x + 'px';
            moverElem.style.top = y + 'px';
        }

        dragHandleElem.addEventListener('mousedown', mdown);
        window.addEventListener('mouseup', mup);
        window.addEventListener('mousemove', mmove);
        // dragHandle.addEventListener('mouseout', mout);
        
        return () => {
            dragHandleElem.removeEventListener('mousedown', mdown);
            window.removeEventListener('mouseup', mup);
            window.removeEventListener('mousemove', mmove);
            // dragHandle.removeEventListener('mouseout', mout);
        }
    }, [moverRef.current, dragHandleRef.current])

    return (
        <div ref={moverRef} style={{ position: 'absolute', left:0, top:0 }} className="m-xls-pop">

            <FlexVCenter css="m-xls-pop-filter-header" justifyContent="space-between">
                <Box
                    flex="1"
                    cursor="move"
                    ref={dragHandleRef}
                >
                    <PortalPlaceholder id="pop-over-header"></PortalPlaceholder>
                </Box>
                <Icon name="close" onClick={closePop} cursor="pointer" />
            </FlexVCenter>

            <PortalPlaceholder id="pop-over-menu-tabs" />

            <div className="m-xls-pop-mid-section">
                { children }
            </div>

            <PortalPlaceholder id="pop-over-footer" />

        </div>
    )
}
