import { ProposalDetails, toHitInfo } from 'services/proposal';
import { zCurrent } from './z-current';

export const updateHitInfo = (proposalDetails:ProposalDetails) => {
    // If they just imported hits on the current sheet, we wanna immediately update our hitInfo
    const curr = zCurrent.get();
    if (curr && curr.sheet.id === proposalDetails.sheetId) {
        zCurrent.update(x => {
            return {
                ...x,
                hitInfo: toHitInfo(proposalDetails)
            }
        })
    }
}
