import React, { useState, useEffect } from 'react';
import { LoanTableCol } from './loan-table-col';
import { LoanTableColTop } from './loan-table-col-top';
import { Box, Text, Icon } from 'mui';
import { sheets, viewLoans } from 'services';
import { getOfferState } from 'services/loans';

interface Props {
    readonly row:viewLoans.LoanAndValuesAndSources;
    readonly fields:viewLoans.LoanTableFieldConfig[];
    readonly filters:sheets.LoanFilters;
}
export const LoanTableRow = ({ row, fields, filters }:Props) => {

    const [ expanded, setExpanded ] = useState<boolean>((row.loan as any)._expanded);

    const toggleExpanded = () => {
        // mutate it! it's a special property anyway.
        (row.loan as any)._expanded = !expanded;
        setExpanded(!expanded);
    }

    useEffect(() => {
        // When underlying loan changes, reset expanded
        setExpanded((row.loan as any)._expanded);
    }, [row.loan.id])

    const { ineligibleReasons } = getOfferState(row.loan);

    // FOR now, leave it as-is...but...iamgine if a loan has 1 invalid offer only,
    // we say "inelgible", but, might it be eligible for diff agency? yes! However, if 100% of offers are invalid,
    // we are gonna label this as inelgible.

    return (
        <>
            <tr onClick={() => toggleExpanded()} style={{ cursor:'pointer' }}>
                <td>
                    <Text fg={ineligibleReasons ? 'danger': 'loud'} fontWeight={ineligibleReasons ? 'bold' : 'normal'}>
                        { ineligibleReasons ? 'Ineligible' : 'Eligible' }
                    </Text>
                </td>
                { fields.map((f, n) => <LoanTableColTop key={n} field={f} row={row} expanded={expanded} filters={filters} /> ) }
            </tr>
            { expanded && (
                <tr className="loan-table-details-row">
                    <td>
                        { ineligibleReasons && ineligibleReasons.map((x, n) => (
                            <Box key={n} fontSize="xs" fg="danger">{ x} </Box>
                        )) }
                    </td>
                    { fields.map((f, n) => <LoanTableCol key={n} field={f} row={row} /> ) }
                </tr>
            ) }
        </>
    )
}