import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { rule } from 'services';
import { useZStateList } from 'hooks';
import { RuleModelDetails } from './rule-model/rule-model-details';


export const Model = () => {

	const { id } = useParams();
    if (!id) throw 'how can there not be an id?';

    const { data, selectItem, editItem:model } = useZStateList(rule.zModels);

    useEffect(() => {
        const item = data?.find(x => x.id === id);
        if (item) selectItem(item);
    }, [data, id])

    if (!model) return null;

    return <RuleModelDetails/>
}
