import { Batch } from '../types';
import { mergeBatch } from '../merge-batch';
import { forkJoin, of } from 'rxjs';
import { tap, first, map, switchMap, filter, catchError } from 'rxjs/operators';
import {
    apiFetchMatchingTemplates,
    withBatchItems,
    withBatch
} from '../operators'
import { zBatch } from '../z-batch';

export const findTemplates$ = zBatch.state$.pipe(
    filter(x => !!x.data),
    first(),
    map(x => x.data as Batch),
    switchMap(b => {
        const activeItems = b.items.filter(item => !item.skipped && !item.error);
        console.log('active items', activeItems.length);
        return forkJoin(activeItems.map(item => of(item.index).pipe(
            apiFetchMatchingTemplates(),
        ))).pipe(
            // We now require user input
            withBatchItems(),
            tap(items => {
                const allConfirmed = items.every(item => item.status === 'TEMPLATE_ASSIGNED_AND_CONFIRMED');
                if (allConfirmed) {
                    // This should never come up.
                    mergeBatch({ step: "POST_CONFIRM" });
                } else {
                    mergeBatch({ step: "UI_CONFIRM_TEMPLATES" })
                }
            }),
            withBatch()
        )
    })    
)
