import React, { useState } from 'react';
import { Text, Box, modal, Button, Input, FlexVCenter, FrmInput, LabelValueGrid, TableLikeCssGrid, CssGridBox, Checkbox, ModalFooter } from 'mui';
import { pricing, sheetSummaries, fannie, freddie } from 'services';
import { useStickyStateWithDefault } from 'hooks';
import { PricingItem } from './pricing-item';

export interface PricingItemConfig {
    // TODO: make this interface EXTEND summary, just add new stuff to it, cuz, summary.summary is stupid.
    readonly summary:sheetSummaries.SheetSummaryDetails;
    readonly runFannie:boolean;
    readonly runFreddie:boolean;
    readonly defaultFannieMargin:number; // for when they blank out the input box or reset
    readonly defaultFreddieMargin:number;
    readonly fannieMargin:number;
    readonly freddieMargin:number;
}

interface Props {
    readonly summaries:sheetSummaries.SheetSummaryDetails[];
    readonly onRun:() => void;
}
export const PricingModal = ({ summaries, onRun }:Props) => {

    const [ costOfFunds, setCostOfFunds ] = useStickyStateWithDefault(pricing.getCostOfFundsStorageKey(), pricing.DEFAULT_COST_OF_FUNDS);
    const [ fannieApplyAllMargin, setFannieApplyAllMargin ] = useStickyStateWithDefault('fannie-apply-all-margin', pricing.DEFAULT_SELLER_MARGIN);
    const [ freddieApplyAllMargin, setFreddieApplyAllMargin ] = useStickyStateWithDefault('freddie-apply-all-margin', pricing.DEFAULT_SELLER_MARGIN);

    const [ items, setItems ] = useState<PricingItemConfig[]>(summaries.map(x => {
        const defaultFannieMargin = pricing.getLastMarginBySellerAndInvestor(x.summary.sellerOrgId, 'fannie');
        const defaultFreddieMargin = pricing.getLastMarginBySellerAndInvestor(x.summary.sellerOrgId, 'freddie');
        return {
            summary: x,
            runFannie: true,
            runFreddie: true,
            defaultFannieMargin,
            defaultFreddieMargin,
            fannieMargin: defaultFannieMargin,
            freddieMargin: defaultFreddieMargin
        }
    }))

    const run = () => {
        pricing.setLastCostOfFunds(costOfFunds);
        items.forEach(item => {
            // The following are async processes, but, we do NOT await them.
            if (item.runFannie) {
                pricing.setLastMarginBySellerAndInvestor(item.summary.summary.sellerOrgId, 'fannie', item.fannieMargin);
                // fannie.runFanniePricingDeferred(item.summary.summary.sheetId, costOfFunds, item.fannieMargin);
                fannie.enqueueFanniePricing(item.summary.summary.sheetId, costOfFunds, item.fannieMargin);
            }
            if (item.runFreddie) {
                pricing.setLastMarginBySellerAndInvestor(item.summary.summary.sellerOrgId, 'freddie', item.freddieMargin);
                // freddie.runFreddiePricingDeferred(item.summary.summary.sheetId, costOfFunds, item.freddieMargin);
                freddie.enqueueFreddiePricing(item.summary.summary.sheetId, costOfFunds, item.freddieMargin);
            }
        })
        onRun(); // tell parent we have started.
        modal.close();
    }

    const applyAllFreddie = () => {
        setItems(items.map((x, n) => {
            return {
                ...x,
                freddieMargin: freddieApplyAllMargin
            }
        }))
    }
    const applyAllFannie = () => {
        setItems(items.map((x, n) => {
            return {
                ...x,
                fannieMargin: fannieApplyAllMargin
            }
        }))
    }
    
    const updateItem = (val:PricingItemConfig, index:number) => {
        setItems(items.map((x, n) => n === index ? val : x));
    }

    const applyToAllLabel = 'Apply to All';
    const notAllFannieChecked = items.some(x => !x.runFannie);
    const notAllFreddieChecked = items.some(x => !x.runFreddie);
    const canRun = items.some(x => x.runFannie || x.runFreddie);

    const toggleAllFannie = () => {
        setItems(items.map(x => {
            return {
                ...x,
                runFannie: notAllFannieChecked
            }
        }))
    }

    const toggleAllFreddie = () => {
        setItems(items.map(x => {
            return {
                ...x,
                runFreddie: notAllFreddieChecked
            }
        }))
    }

    return (
        <Box mt="2">

            <FlexVCenter mb="1" mr="half">
                <Box textAlign="right" flex="1" mr="half">Cost of Funds</Box>
                <FlexVCenter ml="half">
                    <Box flex="1">
                        <Input
                            value={costOfFunds}
                            setValue={setCostOfFunds}
                            numeric={{ float:true }}
                            width="100%"
                            align="right"
                            appendIcon="percent"
                        />
                    </Box>
                    <Box ml="1">
                        {/*
                            This is a dummy button to match exactly the width of the real ones below.
                            Leave it here. Leave the text the same.
                        */}
                        <Button type="secondary" style={{ visibility:'hidden' }}>{ applyToAllLabel }</Button>
                    </Box>
                </FlexVCenter>
            </FlexVCenter>

            <FlexVCenter mb="1" mr="half">
                <Box textAlign="right" flex="1" mr="half"><Text fg="accent">Fannie</Text> Seller Margins</Box>
                <FlexVCenter ml="half">
                    <Box flex="1">
                        <Input
                            value={fannieApplyAllMargin}
                            setValue={setFannieApplyAllMargin}
                            numeric={{ float:true }}
                            width="100%"
                            align="right"
                            appendIcon="percent"
                        />
                    </Box>
                    <Box ml="1">
                        <Button type="secondary" onClick={applyAllFannie}>{ applyToAllLabel }</Button>
                    </Box>
                </FlexVCenter>
            </FlexVCenter>

            <FlexVCenter mb="1" mr="half">
                <Box textAlign="right" flex="1" mr="half"><Text fg="accent">Freddie</Text> Seller Margins</Box>
                <FlexVCenter ml="half">
                    <Box flex="1">
                        <Input
                            value={freddieApplyAllMargin}
                            setValue={setFreddieApplyAllMargin}
                            numeric={{ float:true }}
                            width="100%"
                            align="right"
                            appendIcon="percent"
                        />
                    </Box>
                    <Box ml="1">
                        <Button type="secondary" onClick={applyAllFreddie}>{ applyToAllLabel }</Button>
                    </Box>
                </FlexVCenter>
            </FlexVCenter>

            <CssGridBox cols={2} gap="0" width="100%" sameSize alignItems="center" mt="1">
                <Box
                    textAlign="right"
                    p="half"
                    borderColor="alert"
                    borderSides="bottom-and-right"
                    borderWidth="thin"
                    borderStyle="dashed"
                    mb="half"
                >Seller Margins</Box>
                <FlexVCenter
                    borderColor="alert"
                    borderSides="bottom"
                    borderWidth="thin"
                    borderStyle="dashed"
                    mb="half"
                >
                    <FlexVCenter flex="1" p="half" mr="1">
                        <Checkbox
                            mx="1"
                            cursor="pointer"
                            checked={!notAllFannieChecked}
                            onToggle={toggleAllFannie}
                        />
                        <Box cursor="pointer" onClick={toggleAllFannie}>Fannie</Box>
                    </FlexVCenter>
                    <FlexVCenter flex="1" p="half" mr="1">
                        <Checkbox
                            mx="1"
                            cursor="pointer"
                            checked={!notAllFreddieChecked}
                            onToggle={toggleAllFreddie}
                        />
                        <Box cursor="pointer" onClick={toggleAllFreddie}>Freddie</Box>
                    </FlexVCenter>
                </FlexVCenter>
                { items.map((x, n) => (
                    <PricingItem
                        key={n}
                        item={x}
                        onChange={val => updateItem(val, n)}
                    />
                )) }
                <Box
                    mt="half"
                    style={{ gridColumn: 'span 2' }}
                    borderColor="alert"
                    borderSides="top"
                    borderWidth="thin"
                    borderStyle="dashed"></Box>
            </CssGridBox>

            <ModalFooter>
                <Box textAlign="right" mb="2" mr="2">
                    <Button mr="1" type="link" onClick={modal.close}>Cancel</Button>
                    <Button
                        type={canRun ? 'default' : 'disabled'}
                        onClick={run}
                    >Run Pricing</Button>
                </Box>
            </ModalFooter>

        </Box>
    )
}

