import React, { useState, useEffect } from 'react';
import { Flex, FlexVCenter, Box, Text, Button, Icon } from 'mui';
import { GQL } from 'market-dto';
import { useApiFetch } from 'hooks';
import { profit } from 'services';
import * as api from 'api';
import { Loading } from 'components';
import { MultiLineChart } from './multi-line-chart';

const apiFetchCashWindow = api.toastOnErr(api.fetchCashWindow, "Unable to fetch cash window");

interface Props {
    readonly sheet:GQL.LoanModelSheet;
}
export const Charts = ({ sheet }:Props) => {

    const { data:loansAndPriceSheets, loading } = useApiFetch(async () => {
        const ids = profit.getDistinctInvestorProductIds(sheet);
        const apiResult = await apiFetchCashWindow(ids);
        if (!apiResult) {
            return; // todo: throw error
        }
        // console.log('len:', JSON.stringify(apiResult).length);
        const result = profit.toLoansAndPricesSheets(sheet, apiResult);
        if (result.length === 0) return; // todo: throw error
        return result;
    }, [sheet.id]);

    return (
        <Loading
            loading={loading}
            render={
                <Box my="2">
                    { loansAndPriceSheets && <MultiLineChart items={loansAndPriceSheets} /> }
                </Box>    
            }
        />
    )
}