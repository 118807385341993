import React, { useState, useEffect, useRef } from 'react';

export const useVerticalResize = (targetRef:React.RefObject<HTMLDivElement>) => {

    /*
        NOTE: i'm not entirely sure i have this correct...
        It works for the activity panel, but, would it work for a div in the middle of the page?
        Not sure if i have the right offset/page/y etc...however, definitely works for div pinned to bottom of window.
    */

    const [ handle, setHandleRef ] = useState<HTMLDivElement>();
    const isDown = useRef(false);
    const yStart = useRef(-1);
    const startingHeight = useRef(-1);

    const mouseMove = (evt:MouseEvent) => {
        if (!targetRef.current) return;
        const yDiff = yStart.current - evt.y;
        targetRef.current.style.height = (startingHeight.current + yDiff) + 'px';
    }

    const mouseUp = () => {
        isDown.current = false;
        document.removeEventListener('mousemove', mouseMove);
        document.removeEventListener('mouseup', mouseUp);
    }

    useEffect(() => {
        // we should now have our refs
        if (!targetRef.current || !handle) return;
        
        handle.style.userSelect = 'none';

        handle.onmousedown = (evt:MouseEvent) => {
            if (!targetRef.current) return;
            startingHeight.current = targetRef.current.offsetHeight;
            isDown.current = true;
            yStart.current = evt.y; // pageY, offsetY or y?
            document.addEventListener('mousemove', mouseMove, false);
            document.addEventListener('mouseup', mouseUp);
        }    

    }, [handle])

    return {
        setHandleRef
    }
}