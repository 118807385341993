import React from 'react';
import { Checkbox, Icon } from 'mui';
import { SheetStatus } from 'market-dto';
import { actMon, fannie, freddie } from 'services';

interface CheckboxColProps {
    readonly status:SheetStatus;
    readonly sheetId:string;
    readonly checked:boolean;
    readonly onToggle:(x:boolean) => void;
}
export const CheckboxCol = ({ sheetId, status, checked, onToggle }:CheckboxColProps) => {

    const busy = actMon.useJobsBusy([
        fannie.getFannieJobName(sheetId),
        freddie.getFreddieJobName(sheetId)
    ])

    if (status !== 'PRICING') return null;

    if (busy) return <Icon name="loader" spinning fg="loud" />;

    return (
        <Checkbox
            checked={checked}
            onToggle={onToggle}
        />
    )
}