import { GQL } from 'market-dto';
import { Generated, NonAggMatchResult, HashMatchConfidence } from '../types';
import { getTemplateOverrideHash } from './get-template-override-hash';

export const nonAggTemplate = (
    generated:Generated,
    template:GQL.Template,
    hashConfidence:HashMatchConfidence,
    sellers:GQL.Org[]
):NonAggMatchResult => {
    const sellerOrg = sellers.find(x => x.id === template.orgIds[0]);
    if (!sellerOrg) throw new Error('Expected sellerOrg to exist');
    return {
        type: "non-agg",
        generated,
        sellerOrg,
        matchState: "MATCHED_NON_AGG_TEMPLATE",
        template,
        templateOverrideHash: getTemplateOverrideHash(template),
        hashConfidence
    }
}