import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

export const QUERY = gql`
    query {
        org(where: {}) { ${ RETURNING } }
    }
`;

interface QueryResult {
    readonly orgs:GQL.Org[];
}

export const fetchAllOrgs = async ():Promise<GQL.Org[]> => {
    const result = await query<any, QueryResult>(QUERY, {});
    return result.data.orgs;
}
