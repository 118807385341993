import { mutate, gql } from "../../client"
import { CashWindow, format } from 'market-dto';
import { FreddieOptimizePriceResult } from './types';

interface QueryInput {
    readonly loanId:string;
}

interface QueryOutput {
    readonly sync_freddie_optimize_price:{
        readonly explored:CashWindow.HoldingInterest[];
    }
}
interface QueryOutput2 {
    readonly sync_freddie_optimize_price:FreddieOptimizePriceResult;
}

const MUTATION = gql`
    mutation syncFreddieOptimizePrice($loanId: String) {
        sync_freddie_optimize_price(loanId: $loanId)
    }
`;

const ensureDates = (x:FreddieOptimizePriceResult|undefined):FreddieOptimizePriceResult|undefined => {
    if (!x) return undefined;
    return {
        base: {
            ...x.base,
            acquisitionDate: format.ensureDate(x.base.acquisitionDate)
        },
        explored: x.explored.map(y => {
            return {
                ...y,
                acquisitionDate: format.ensureDate(y.acquisitionDate),
                lockedDate: format.ensureDate(y.lockedDate),
                target: format.ensureDate(y.target)
            }
        }),
        params: {
            ...x.params,
            aquisitionDate: format.ensureDate(x.params.aquisitionDate),
            lockedDate: format.ensureDate(x.params.lockedDate),
            deliveryDate: format.ensureDate(x.params.deliveryDate)
        }
    }
}

export const fetchFreddieHoldingInterest = async (loanId:string):Promise<FreddieOptimizePriceResult|undefined> => {
    const result = await mutate<QueryInput, QueryOutput2>(MUTATION, { loanId });
    const result2 = ensureDates(result?.data?.sync_freddie_optimize_price);
    console.log('RESULT2', result2);
    return result2;
}