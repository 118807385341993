import { query, gql, GQL } from "../../client";

interface QueryResult {
    readonly holiday: [GQL.Holiday];
}

const QUERY = gql`
    query holidays {
        holiday {
            market
        }
    }
`;

export const fetchAllHolidays = async ():Promise<GQL.HolidayDateAndDesc[]> => {
    const result = await query<any, QueryResult>(QUERY, {});
    // const client = getApolloClient(false);
    // const result = await client.query<QueryResult>({ query: QUERY });
    return result.data.holiday[0].market;
}



