import { GQL } from 'market-dto';

export const createEmptyChecklist = ():GQL.Checklist => {
    return {
        category: '',
        description: '',
        id: '',
        items: [],
        modelId: '',
        name: '',
        refId: '',
        tags: []
    }
}