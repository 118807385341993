import { mutate, gql, GQL } from "../../client";

const MUTATE = gql`
    mutation createProposalSync($sheetId: String, $offerIds: [String]) {
        createProposal_sync(sheetId:$sheetId, offerIds:$offerIds) {
            id
        }
    }
`;

interface MutateInput {
    readonly sheetId:string;
    readonly offerIds:string[];
}
interface MutateOutput {
    readonly createProposal_sync:GQL.Proposal;
}

export const createProposal = async (sheetId:string, offerIds:string[]):Promise<GQL.Proposal|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(MUTATE, {
        sheetId,
        offerIds
    })
	return result?.data?.createProposal_sync;
}
