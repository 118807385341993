import React, { useState, useEffect } from 'react';
import { HitFieldCashWindow } from './hit-field-cash-window';
import { HitFieldId } from './hit-field-id';
import { HitFieldProductCode } from './hit-field-product-code';
import { HitFieldGeneric } from './hit-field-generic';
import { proposal } from 'services';


interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly fieldId:keyof proposal.HitLoan;
    readonly loan:proposal.HitLoan;
    readonly customizing:boolean;
    readonly optional?:boolean;
    readonly refreshingCashWindow?:boolean;
    readonly toggleChecked:(id:string, flag:boolean) => void;
    readonly moveField?:(from:string, to:string) => void;
    readonly onRemove?:(fieldId:keyof proposal.HitLoan) => void;
}
export const HitFieldCard = ({
    hitInfo,
    fieldId,
    loan,
    toggleChecked,
    customizing,
    optional,
    refreshingCashWindow,
    moveField,
    onRemove
}:Props) => {

    if (fieldId === 'id') {
        return (
            <HitFieldId
                hitInfo={hitInfo}
                loan={loan}
                toggleChecked={toggleChecked}
                customizing={customizing}
            />
        )
    } else if (fieldId === 'productCode') {
        return (
            <HitFieldProductCode
                hitInfo={hitInfo}
                loan={loan}
                toggleChecked={toggleChecked}
                customizing={customizing}
            />
        )
    } else if (fieldId === 'cashWindow') {
        return (
            <HitFieldCashWindow
                hitInfo={hitInfo}
                loan={loan}
                refreshingCashWindow={refreshingCashWindow}
                customizing={customizing}
            />
        )
    } 
    return <HitFieldGeneric
        hitInfo={hitInfo}
        loan={loan}
        customizing={customizing}
        optional={optional}
        fieldId={fieldId}
        moveField={moveField}
        onRemove={onRemove}
    />

}