import React from 'react';
import { BgImageBox, Box, IntoPortal, NavButton } from 'mui';
import { NavBar } from "components";

interface imageSource {
	url: string;
	credit: string;
}


const imageSources: imageSource[] = [{
	url: "https://marketstatic.blob.core.windows.net/images/Jul19_30_882179026-1024x576.jpg?sp=rl&st=2020-06-01T18:35:08Z&se=2050-10-02T18:35:00Z&sv=2019-10-10&sr=b&sig=GVuN2agrAKWcSJmHoArK%2FzwHc2ggsiZNQ2QFiZbQIE8%3D",
	credit: "MirageC/Getty Images"
}, {
	url: "https://images.unsplash.com/photo-1544809011-e5a9eafbc8f0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
	credit: "Photo by Silvan Metzker on Unsplash"
}, {
	url: "https://marketstatic.blob.core.windows.net/images/image-from-rawpixel-id-67770-jpeg.jpg?sp=rl&st=2020-05-30T13:30:11Z&se=2058-11-28T13:30:00Z&sv=2019-10-10&sr=b&sig=M8uIGZvpxTLjCKR4g2Z1Oz91SbQ26QJt%2FiHTrxeQ62g%3D",
	credit: "rawpixel images"
}]

const randomImage = (): imageSource => {
	let n = imageSources.length;
	let c = Math.floor(Math.random() * n);
	return imageSources[c];
}

export const Landing = () => {
	const filter = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))`;
	let img = randomImage()
	const urlImage = `url('${img.url}')`;
	const background = `${filter}, ${urlImage}`;

	return (
		<>
			<NavBar/>
			<BgImageBox bgImageSrc={background}>
				<Box mr="3" textAlign="right" fontSize="2xl" fg="loud">

					<Box my="3" fontWeight="bold" style={{fontSize: "3rem"}}>Welcome to mStudio</Box>
					<Box my="3"> Everyday Matters.</Box>
					<Box my="3"> Redefine Best Execution.</Box>
					<Box my="3"> Connect Sellers &amp; Buyers.</Box>
					<Box my="3">
						<NavButton
							to="/login"
							type="loud"
							size="xl"
							cy="get-started"
						>Get Started!</NavButton>
					</Box>
				</Box>
				<div style={{position: "fixed", left: "1.5rem", bottom: "4rem"}}>{img.credit}
				</div>

			</BgImageBox>
			<IntoPortal id="sign-in-btn-area">
				<NavButton to="/login" type="link">Sign In</NavButton>
			</IntoPortal>
		</>
	);
}
