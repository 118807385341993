import { GQL, FlatJSONSchemaField } from 'market-dto';
import { ModelInfo, RuleModelField, RuleModelFiedlType } from './types';

const toFields = (model:GQL.Model):RuleModelField[] => {
    const req = model.schema.required ?? [];
    const properties = model.schema.properties ?? {};
    return Object.entries(properties)
        .map((arr, n):RuleModelField => {
            const fieldId = arr[0];
            const schema = arr[1];
            const groups = schema.tags ?? [];
            const label = schema.title ?? '';
            const type = (schema.type as RuleModelFiedlType) ?? 'string';
            return {
                index: n,
                fieldId,
                groups,
                label,
                type: type === 'string' && schema.enum ? 'enum' : type,
                required: req.includes(fieldId),
                enums: schema.enum
            }
        })
}

export const toModelInfo = (model:GQL.Model):ModelInfo => {
    return {
        id: model.id,
        refId: model.refId,
        groups: model.groups,
        name: model.name,
        description: model.description,
        fields:toFields(model)
    }
}