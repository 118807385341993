import React from 'react';
import { useApi } from 'hooks';
import {
    FrmCheckbox,
    Box,
    SpinLabel,
    BoundFrmInput,
    DropdownItem,
    FrmDropdown,
    IntoPortal,
    CssGridBox,
    InputSize
} from 'mui';
import { SimpleRulesFormData, RequiredSimpleField, simpleRulesToFormData, formDataToSimpleRules } from './simple.service';
import { ConfigHeader } from '../components/config-header';
import {
    // DOC_TYPES,
    // DocType,
    // AUS_TYPES,
    // AusType,
    // RATE_TYPES,
    // RateType,
    // LOAN_TYPES,
    // LoanType,
    // loanModel,
    // SpecialtyProgram,
    // SPECIALTY_PROGRAMS, PropertyType, PROPERTY_TYPES
    loanModel,
    labels,
    LoanModelEnums
} from 'market-dto';
import * as api from 'api';


export const Simple = () => {

    const {
        update:save,
        fetching:loading,
        updating:saving,
        dirty,
        data:editData,
        onChange,
        reset,
        editting,
        setEditting
    } = useApi<SimpleRulesFormData>({
        fetch: async () => simpleRulesToFormData(await api.fetchSimpleRules()),
        update: async x => await api.upsertSimpleRules(formDataToSimpleRules(x)),
        deps: [] // this means load immediately and just once.
    })

    const docTypeOpts = loanModel.getEnumerationDropdownOptions("docType");
    const ausTypeOpts = loanModel.getEnumerationDropdownOptions("aus");
    const rateTypeOpts = loanModel.getEnumerationDropdownOptions("rateType");
    const loanTypeOpts = loanModel.getEnumerationDropdownOptions("loanType");
    const propertyTypeOpts = loanModel.getEnumerationDropdownOptions("propertyType");
    const specialtyProgramOpts = loanModel.getEnumerationDropdownOptions("specialtyProgram");

    const requiredOpts:DropdownItem[] = [
        { label: 'LTV', value: 'ltv' },
        { label: 'CLTV', value: 'cltv' },
        { label: 'DTI', value: 'dti' }
    ]

    const none = (s:string) => '"' + s + '" will not be taken into account';
    const placeholder = (s:string) => 'Select eligible values for ' + s;

    if (loading || !editData) return <SpinLabel mt="2">Loading</SpinLabel>;

    const size:InputSize = "md";

    return (
        <Box>

            <IntoPortal id="config-route-top-area">
                <ConfigHeader
                    title="Simple Rules"
                    cancel={reset}
                    edit={()=>setEditting(true)}
                    dirty={dirty}
                    editting={editting}
                    save={save}
                    saving={saving}
                />
            </IntoPortal>

            <CssGridBox cols={2}>
        
                <FrmDropdown
                    size={size}
                    label="Required Fields"
                    placeholder="Select Required Fields"
                    isMulti
                    disabled={!editting}
                    items={requiredOpts}
                    vals={editData.requiredFields}
                    setValue={(x:RequiredSimpleField[]) => onChange({ ...editData, requiredFields: x})}
                />
                <BoundFrmInput
                    size={size}
                    readOnly={!editting}
                    label="Note Rate Multiplier"
                    numeric={{ float:true }}
                    bindTo={[ editData, onChange, x => x.noteRateMultiplier ]}
                />

                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('docType')}
                    readonlyPlaceholder={none(labels.byLoanField('docType'))}
                    // noneLabel={none(labels.byLoanField('docType'))}
                    placeholder={placeholder(labels.byLoanField('docType'))}
                    isMulti
                    disabled={!editting}
                    items={docTypeOpts}
                    vals={editData.docTypes}
                    setValue={x => onChange({ ...editData, docTypes: x})}                        
                />
                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('aus')}
                    readonlyPlaceholder={none(labels.byLoanField('aus'))}
                    // noneLabel={none(labels.byLoanField('aus'))}
                    placeholder={placeholder(labels.byLoanField('aus'))}
                    isMulti
                    disabled={!editting}
                    items={ausTypeOpts}
                    vals={editData.ausTypes}
                    setValue={x => onChange({ ...editData, ausTypes: x})}
                />
                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('rateType')}
                    readonlyPlaceholder={none(labels.byLoanField('rateType'))}
                    placeholder={placeholder(labels.byLoanField('rateType'))}
                    isMulti
                    disabled={!editting}
                    items={rateTypeOpts}
                    vals={editData.rateTypes}
                    setValue={x => onChange({ ...editData, rateTypes: x})}
                />
                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('loanType')}
                    readonlyPlaceholder={none(labels.byLoanField('loanType'))}
                    placeholder={placeholder(labels.byLoanField('loanType'))}
                    isMulti
                    disabled={!editting}
                    items={loanTypeOpts}
                    vals={editData.loanTypes}
                    setValue={x => onChange({ ...editData, loanTypes: x})}
                />
                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('specialtyProgram')}
                    readonlyPlaceholder={none(labels.byLoanField('specialtyProgram'))}
                    placeholder={placeholder(labels.byLoanField('specialtyProgram'))}
                    isMulti
                    disabled={!editting}
                    items={specialtyProgramOpts}
                    vals={editData.specialtyPrograms}
                    setValue={x => onChange({ ...editData, specialtyPrograms: x})}
                />
                <FrmDropdown
                    size={size}
                    label={labels.byLoanField('propertyType')}
                    readonlyPlaceholder={none(labels.byLoanField('propertyType'))}
                    placeholder={placeholder(labels.byLoanField('propertyType'))}
                    isMulti
                    disabled={!editting}
                    items={propertyTypeOpts}
                    vals={editData.propertyTypes}
                    setValue={x => onChange({ ...editData, propertyTypes: x})}
                />
                <BoundFrmInput
                    size={size}
                    readOnly={!editting}
                    label="Max Loan Amount"
                    numeric={{ int:true }}
                    bindTo={[ editData, onChange, x => x.maxLoanAmount ]}
                />
                <BoundFrmInput
                    size={size}
                    readOnly={!editting}
                    label={'Max ' + labels.byLoanField('dti')}
                    numeric={{ int:true }}
                    bindTo={[ editData, onChange, x => x.maxDti ]}
                />
                <BoundFrmInput
                    size={size}
                    readOnly={!editting}
                    label={'Max ' + labels.byLoanField('ltv')}
                    numeric={{ int:true }}
                    bindTo={[ editData, onChange, x => x.maxLtv ]}
                />
                <BoundFrmInput
                    size={size}
                    readOnly={!editting}
                    label={'Min ' + labels.byLoanField('fico')}
                    numeric={{ int:true }}
                    bindTo={[ editData, onChange, x => x.minFico ]}
                />
                <FrmCheckbox
                    size={size}
                    readOnly={!editting}
                    label="Allow Third Party Origination?"
                    checked={editData.allowTpo}
                    onToggle={x => onChange({ ...editData, allowTpo: x})}
                />
                <FrmCheckbox
                    size={size}
                    readOnly={!editting}
                    label="CLTV cannot be greater than LTV"
                    checked={editData.cltvGreaterLtv}
                    onToggle={x => onChange({ ...editData, cltvGreaterLtv: x})}
                />
            </CssGridBox>
        </Box>
    )
}
