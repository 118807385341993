import { BatchItem, Assigned } from '../types';

export const autoAssign = (item:BatchItem):Assigned => {

    // Take top suggestion. Don't change anything. No new aliases, no updating templates, no nothing.

    const { tapeId, sellers, matchResults } = item;
    if (!tapeId) throw new Error('Expected tapeId');

    if (!matchResults || matchResults.length === 0) throw new Error("Cannot auto assign--missing matchResult");

    const first = matchResults[0];

    const { generated, sellerOrg, template, aggregatorName } = first;

    if (!generated) throw new Error("Cannot auto-assign--missing generated loans");
    
    if (first.type === "agg") {
        if (!aggregatorName) throw new Error("Cannot auto-assign--missing aggregatorName on agg template");
        if (first.matchState !== "AGG_EXACTLY_ONE_POSSIBLE_SELLER") throw new Error("expected one possible seller");
        if (first.sellerOrgsInBoth.length !== 1) throw new Error('expectedsellersOrgsInBoth to have exactly 1 entry');
        return { 
            generated,
            type: "agg",
            sellerOrg: first.sellerOrgsInBoth[0],
            template,
            aggregatorName
        }
    }

    // non-aggs below:
    if (!sellerOrg) throw new Error("Cannot auto assign--missing seller");

    return { 
        generated: generated,
        type: "non-agg",
        sellerOrg: sellerOrg,
        template
    }
}