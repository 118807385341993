import { sheetMaker } from 'market-dto';
import { HitInfo } from '../proposal';
import { downloadFromArrayBuffer } from '../downloader';

export const genCommitmentSummary = async (prefix:string, hitInfo:HitInfo) => {
    const headers = [
        'Investor',
        'Product ID',
        'Total',
        'Loan Count',
        'Min Coupon',
        'Max Coupon'
    ]
    const rows = hitInfo.counts.map(group => {
        return [
            group.investor,
            group.investorProductId,
            group.sum,
            group.loanCount,
            group.minCoupon,
            group.maxCoupon
        ]
    })

    const arrBuf = await sheetMaker.makeSheet([ headers, ...rows ], "Commitment Summary");
    const filename = prefix + '-commitment-summary.xlsx';
    downloadFromArrayBuffer(arrBuf, filename);
}
