import React, { useState } from 'react';
import { GQL, ImportModel } from 'market-dto';
import { FormatEnum } from './format-enum';
import { FormatNonEnum } from './format-non-enum';

interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportMappedCol;
    readonly onChange:(x:GQL.XlsExportMappedCol) => void;
    readonly flowRun:GQL.FlowRun;
}
export const EditColFormatting = ({ colCfg, model, flowRun, onChange }:Props) => {

    const { fieldId } = colCfg;
    const field = model.getField(fieldId);

    if (field.kind === "enum") {
        const enumValLabels = model.getEnumerationDropdownOptions(fieldId);
        return (
            <FormatEnum
                colCfg={colCfg}
                onChange={onChange}
                enumValLabels={enumValLabels}
            />
        ) 
    } else {

        const formatterOpts = model.getFormatterDropdownOptions(fieldId);
        return (
            <FormatNonEnum
                model={model}
                formatterOpts={formatterOpts}
                colCfg={colCfg}
                onChange={onChange}
            />
        )
    }

    return (
        <div>
            what are we doing here...
        </div>
    )
}