import { useEffect, useState, useRef } from 'react';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

// Just trying this out.  See if i like it.

export const useRxState = <T>(bs:BehaviorSubject<T>):[T, (x:T) => void] => {
    const [ val, setVal ] = useState<T>(bs.getValue())
    useEffect(() => {
        const sub = bs.subscribe(setVal);
        return () => sub.unsubscribe();
    }, [bs])
    return [ val, (x:T) => bs.next(x) ];
}

// export const useRxState2 = <T>(getBs:() => BehaviorSubject<T>):[T, (x:T) => void] => {
    
//     const [ val, setVal ] = useState<T>(() => getBs().getValue())
//     useEffect(() => {
//         const sub = bs.subscribe(setVal);
//         return () => sub.unsubscribe();
//     }, [bs])
//     return [ val, (x:T) => bs.next(x) ];
// }

export const useObs = <T>(obs:Observable<T>):T | undefined => {
    const [ val, setVal ] = useState<T>();
    useEffect(() => {
        // we don't need to worry about isCancelled stuff, because, when we leave dom, we unsubscribe
        const sub = obs.subscribe(setVal);
        return () => sub.unsubscribe();
    }, [obs])
    return val;
}

export const useObsLoad = <T>(mainObs?:Observable<T>) => {
    const isCanceled = useRef(false);
    const sub = useRef<Subscription>();
    const [ data, setData ] = useState<T>();
    const [ busy, setBusy ] = useState(false);

    useEffect(() => {
        return () => {
            isCanceled.current = true;
            if (sub.current) sub.current.unsubscribe();
        }
    }, [])

    const cancel = () => {
        if (sub.current) sub.current.unsubscribe();
        if (isCanceled.current) return;
        setBusy(false);
    }

    return {
        load: (specificObs?:Observable<T>) => {
            const obs = specificObs ?? mainObs;
            if (!obs) return;
            if (sub.current) sub.current.unsubscribe();
            if (isCanceled.current) return;
            setBusy(true);
            sub.current = obs.subscribe(
                x => {
                    if (isCanceled.current) return;
                    setData(x)
                },
                () => {},
                () => {
                    if (isCanceled.current) return;
                    setBusy(false)
                }
            )
        },
        data,
        busy,
        cancel
    }
}

export const useObsWithDefault = <T>(obs:Observable<T>, defaultVal:T):T => {
    const [ val, setVal ] = useState<T>(defaultVal);
    useEffect(() => {
        const sub = obs.subscribe(setVal);
        return () => sub.unsubscribe();
    }, []);
    if (defaultVal !== undefined) return val as T; // force type on this
    return val;
}

