import React, { useState } from 'react';
import { Box, Button, FrmInput, modal, FlexVCenter, IconButton, Input } from 'mui';
import { GQL } from 'market-dto';

interface EditObj {
    readonly name:string;
    readonly description:string;
    readonly refId:string;
    readonly tags:string[];
}
const getEditObj = (checklist:GQL.Checklist):EditObj => {
    return {
        name: checklist.name ?? '',
        description: checklist.description ?? '',
        refId: checklist.refId ?? '',
        tags: checklist.tags ?? []
    }
}

interface Props {
    readonly checklist:GQL.Checklist;
    readonly onUpdate:(x:Partial<GQL.Checklist>) => void;
}
export const NameAndDescModal = ({ checklist, onUpdate }:Props) => {

    const [ initObj ] = useState<EditObj>(() => getEditObj(checklist));
    const [ editObj, setEditObj ] = useState<EditObj>(() => getEditObj(checklist));
    const dirty = JSON.stringify(initObj) !== JSON.stringify(editObj);

    // const dirty = checklist.name !== name
    //     || checklist.description !== desc
    //     || (checklist.refId ?? '') !== refId;

    const canSubmit = dirty && editObj.name.trim() !=='' && editObj.tags.every(tag => tag.trim());

    const submit = async () => {
        if (!canSubmit) return;
        onUpdate(editObj);
        modal.close();
    }

    const updateTag = (val:string, n:number) => {
        setEditObj({
            ...editObj,
            tags: editObj.tags.map((tag, m) => n === m ? val : tag )
        })
    }

    const removeTag = (n:number) => {
        setEditObj({
            ...editObj,
            tags: editObj.tags.filter((a, m) => n !== m)
        })
    }

    const addTag = () => {
        setEditObj({
            ...editObj,
            tags: editObj.tags.concat('')
        })
    }

    return (
        <>
            <FrmInput
                width="100%"
                label="Name"
                value={editObj.name}
                setValue={x => setEditObj({ ...editObj, name: x })}
            />
            <FrmInput
                width="100%"
                label="Description"
                value={editObj.description}
                setValue={x => setEditObj({ ...editObj, description: x })}
            />
            <FrmInput
                readOnly
                width="100%"
                label="Ref ID"
                value={editObj.refId}
                // setValue={x => setEditObj({ ...editObj, refId: x })}
            />

            <FlexVCenter ml="1" mb="1">
                <Box fg="faded">Tags</Box>
                <Box flex="1" textAlign="right">
                    <IconButton size="sm" name="plus" onClick={addTag} />
                </Box>
            </FlexVCenter>

            { editObj.tags.map((tag, n) => (
                <FlexVCenter mb="1" key={n}>
                    <Box flex="1" pr="1">
                        <Input
                            value={tag}
                            setValue={val => updateTag(val, n)}
                            placeholder="Tag"
                        />
                    </Box>
                    <Box textAlign="right">
                        <IconButton size="sm" name="trash" onClick={() => removeTag(n)}/>
                    </Box>
                </FlexVCenter>
            )) }

            <Box textAlign="right" mt="2">
                <Button
                    type={canSubmit ? 'default' : 'disabled'}
                    onClick={submit}
                >Update</Button>
            </Box>
        </>
    )
}