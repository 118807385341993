import React, { useEffect, useState } from 'react';
import { alpha } from 'services';
import { useParams, useSearchParams } from 'react-router-dom';
import { FlowRoutes } from './flow-routes';
import { Box } from 'mui';

export const Flow = () => {
    const { id } = useParams();
    const [ invalidFlow, setInvalidFlow ] = useState<boolean|null>(null);
    const [ qstring ] = useSearchParams({});
    const runId = qstring.get('runId');

    useEffect(() => {
        // this allows links to load a flow + a specific run from outside of that flow ui (the popper menu)
        if (runId) alpha.zCurrentRun.fetch(runId);
    }, [runId])

    useEffect(() => {
        // Flow id just changed.
        alpha.zCurrentRun.clear();
        const flow = alpha.ensureFlowSelected(id);
        setInvalidFlow(flow ? false : true);
    }, [id])

    if (invalidFlow === null) return <></>; // initial render--we haven't checked if we are loaded yet.
    if (invalidFlow) return <Box>Flow not found!</Box>;
    return <FlowRoutes />
}