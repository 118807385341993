import React, { useState } from 'react';
import { GQL, ImportModel, ModelItem } from 'market-dto';
import { PopOverMenuTabs } from '../components';
import { EditColHeader } from './edit-col-header';
import { EditColFilter } from './edit-col-filter';
import { EditColFormatting } from './edit-col-formatting';

const getMenuItems = (item:ModelItem.AnyType) => {
    return [
        { label: "Header", val: "header" },
        ...item.kind === 'enum' ? [{ label: "Filter", val: "filter" }] : [],
        { label: item.kind === 'enum' ? 'Translate' : 'Format', val: "format" }
    ]
}

interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportMappedCol;
    readonly flowRun:GQL.FlowRun;
    readonly onAccept:(x:GQL.XlsExportCol) => void;
    readonly onChange:(x:GQL.XlsExportCol) => void;
    readonly onClose:() => void;
}
export const MappedField = ({ model, colCfg, flowRun, onChange, onAccept, onClose }:Props) => {

    const { fieldId } = colCfg;
    const field = model.getField(fieldId);
    const menuItems = getMenuItems(field);
    const [ menuTab, setMenuTab ] = useState(menuItems[0].val);

    return (
        <>

            <PopOverMenuTabs
                items={menuItems}
                tab={menuTab}
                setTab={setMenuTab}
            />

            {/* <div className="m-xls-pop-mid-section"> */}

                { menuTab === 'header' && (
                    <EditColHeader
                        model={model}
                        colCfg={colCfg}
                        flowRun={flowRun}
                        onChange={onChange}
                        onAccept={onAccept}
                    />
                ) }
                { menuTab === 'filter' && (
                    <EditColFilter
                        model={model}
                        colCfg={colCfg}
                        flowRun={flowRun}
                        onChange={onChange}
                    />
                ) }
                { menuTab === 'format' && (
                    <EditColFormatting
                        model={model}
                        colCfg={colCfg}
                        onChange={onChange}
                        flowRun={flowRun}
                    />
                ) }

            {/* </div> */}
        </>
    )
}