import { mutate, gql, GQL } from "../../client"

export type InitialTape = Pick<GQL.Tape, 'id'|'base64Content'|'fileName'|'sellerOrgId'>;

const INSERT = gql`
    mutation insertTape($objects: [tape_insert_input!]) {
        insert_tape(objects: $objects) {
            errorMessage,
            returning {
                id
                base64Content
                fileName
                sellerOrgId
            }
        }
    }
`;

interface MutateOutput {
    readonly insert_tape:{
        readonly returning:[InitialTape];
    }
}

interface MutateInput {
    readonly objects:[{
        readonly base64Content:string;
        readonly fileName:string;
        readonly hash: string;
    }]
}

export const insertTape = async (fileName:string, base64:string, tapeHash: string):Promise<InitialTape|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [{
            base64Content: base64,
            fileName: fileName,
            hash: tapeHash
        }]
    })
    // result is undefined on error.
    return result?.data?.insert_tape.returning[0];    
    // } catch (err) {
    //     console.log('JEFF TESTING ERR', JSON.stringify(err, null, 4));
    //     throw new Error(err);
    // }
}
