import { GQL } from 'market-dto';

export const createEmptyModel = ():GQL.Model => {
    return {
        description: '',
        id: '',
        groups: [],
        name: '',
        schema: {
            properties: {},
            required: []
        },
        refId: ''
    }
}