import React from 'react';
import { WhenEnumInDetails } from './types';
import { WhenValuePassed } from './when-value-passed';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const TakeFromMappedButNotGoodEnough = ({ details }:Props) => {
    return (
        <>
            <WhenValuePassed details={details} />
            <div>However, the value for "{ details.takeLabel }" was found to be "{ details.takeVal }"</div>
            { details.couldHaveBeen && (
                <div>
                    (We were checking to see if perhaps we should map to one of the following: { details.couldHaveBeen.join(', ') })
                </div>
            ) }
        </>
    )
}