import * as api from 'api';
import { GQL } from 'market-dto';
import { createZStateList } from './z-state';

export const zSellers = createZStateList({
    apiFetchList: api.fetchAllSellers,
    apiUpdateListItem: api.updateOrg,
    apiInsertListItem: api.insertOrg,
    isEqual: (a,b) => a.id === b.id
})

export const load = () => {
    zSellers.fetchList();
}

export const getSellerById = (sellerOrgId?:string):GQL.Org|undefined => {
    if (!sellerOrgId) return undefined;
    return zSellers.get()?.find(x => x.id === sellerOrgId);
}

export const addAlias = (sellerOrgId:string, alias:string) => {
    const lcaseAlias = alias.trim().toLowerCase();
    const seller = getSellerById(sellerOrgId);
    if (!seller) throw new Error('Could not find seller');
    if (seller.aliases.some(a => a.trim().toLowerCase() === lcaseAlias)) return;
    const newAliases = seller.aliases.concat(lcaseAlias);
    zSellers.apiUpdateListItem({ ...seller, aliases: newAliases });
}
