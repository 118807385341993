import React from 'react';
import { RowsAndHeaders } from 'market-dto';

export const getClipboardTable = (e:React.ClipboardEvent):string => {
    try {
        return e.clipboardData.getData('text/html');
    }
    catch {
        return '';
    }
}


const nullToEmptyString = (x:string|null):string => x === null ? '' : x;

// For when we have users copy paste from spreadsheets
// Why is this in dto, this looks browser-ish
export const htmlTableStringToRowsAndHeaders = (s:string):RowsAndHeaders => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(s, 'text/html');
    const table = doc.getElementsByTagName('table')[0];
    const tableRows = Array.from(table.rows);
    const headers = Array.from(tableRows[0].children)
        .map(child =>  nullToEmptyString(child.textContent));

    const rows = tableRows
        .slice(1)
        .map(row => Array.from(row.children).map(child => nullToEmptyString(child.textContent)));

    return {
        headers,
        rows
    }
}