import { GQL } from 'market-dto';
import { utils } from 'services';

export const isValidImportItem = (obj:any):string[] => {
    
    const missingTables = GQL.AGENCY_SRP_TABLES.filter(tableName => !obj.hasOwnProperty(tableName));
    if (missingTables.length > 0) return missingTables.map(x => 'Missing root property: ' + x);

    return GQL.AGENCY_SRP_TABLES.map(tableName => {
        return utils.isValidSchema(obj[tableName], GQL.AdjTableSchema).map(x => tableName + ': ' + x);
    }).flat();

}