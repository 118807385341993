import React from 'react';
import { Box, BoxProps } from '../box/box';
import { HideShowAtWidth } from '../../types';
import { getCssWithPrefix } from '../getCss';

interface HideProps extends BoxProps {
    readonly hideAt:HideShowAtWidth;
}
export const Hide = ({ hideAt, ...rest}:HideProps) => {
    const css = getCssWithPrefix({ hideAt }, 'width');
    return <Box css={css} {...rest}/>
}

interface ShowProps extends BoxProps {
    readonly showAt:HideShowAtWidth;
}
export const Show = ({ showAt, ...rest}:ShowProps) => {
    const css = getCssWithPrefix({ showAt }, 'width');
    return <Box css={css} {...rest}/>
}