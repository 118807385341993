import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { mapping } from 'services';
import { Mapper } from 'components';
import { useApi } from 'hooks';
import * as api from 'api';
import { SpinLabel, Alert, NavLink, Text } from 'mui';


interface Props {
    readonly sheet:GQL.Sheet;
}
export const Mapping = ({ sheet }:Props) => {

    const [ toMap, setToMap ] = useState<mapping.ToMap>();

    const templateId = sheet.templateId;
    if (!templateId) throw new Error('Expected template id');

    const { fetching, data } = useApi({
        fetch: () => api.fetchSingleTemplate(templateId),
        deps: []
    })

    // useEffect(() => {
    //     if (!data) return;
    //     setToMap({
    //         headersAndRows: sheet.headersAndRows,
    //         // sheetId: sheet.id,
    //         plateAndOrg: data
    //     })
    // }, [data])

    return <div>
        { fetching && <SpinLabel>Loading...</SpinLabel> }
        { !fetching && !toMap && (
            <Alert icon="alert-triangle">
                I'm sorry, but mapping details are not currently available for this tape.
            </Alert>
        )}
        { !fetching && toMap && (
            <>
                <Alert mb="2">
                    Mapping temporarily disabled.
                    {/* This is a <Text fg="loud">temporarily readonly</Text> view of the mapping config used to parse this tape.<br/>
                    If you'd like to make changes to how tapes of this type are interpreted,
                    try the <NavLink to="/desk/mapping">Trading Desk Mapping</NavLink> page. */}
                </Alert>
                
                {/* <Mapper readOnly /> */}
            </>
        )}
        
    </div>
}