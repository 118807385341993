import React, { useState, useEffect } from 'react';
import { Table,	pageSortHeader, TableProps, usePageSortTable, Checkbox, FlexVCenter, SpinLabel, Text, Icon } from 'mui';
import { useNavigate } from 'react-router-dom';
import { format, utils, sheetSummaries, pricing } from "services";
import { GQL, LoanStats, SheetStatus, labels } from 'market-dto';
import { CheckboxCol } from './checkbox-col';
import { string } from 'mathjs';

const getEligibleCount = (x: LoanStats): number => {
	return x.eligibility && x.eligibility['Eligible'] ? x.eligibility['Eligible'] : 0
}

interface TapeSummaryTableRow {
	readonly id:string;
	// readonly runningAgencyPricing:boolean;
	readonly filename:string;
	readonly sellerName:string;
	readonly loanCount:number;
	readonly eligibleCount:number;
	readonly createdAt:string;
	readonly status:SheetStatus;
	readonly checked:boolean;
}

const getRowsFromSummaryDetails = (items:sheetSummaries.SheetSummaryDetails[]):TapeSummaryTableRow[] => {
	return items.map((x):TapeSummaryTableRow => {
		const summary = x.summary;
		return {
			id: summary.sheetId,
			// runningAgencyPricing: x.runningAgencyPricing,
			eligibleCount: getEligibleCount(summary.loanStats),
			filename: format.shortenFileName(summary.fileName),
			loanCount: summary.loanStats.loanCount ?? (summary.loanStats as any).count,
			sellerName: summary.sellerName,
			createdAt: format.toDate(summary.createdAt),
			status: summary.sheetStatus,
			checked: x.checked
		}
	})
}

interface Props {
	readonly details:sheetSummaries.SheetSummaryDetails[];
	readonly yyyymmdd:string;
}
export const SummaryTable = ({ details, yyyymmdd }:Props) => {

	const navigate = useNavigate();
	const rows = getRowsFromSummaryDetails(details);

	const toggle = (row:TapeSummaryTableRow) => {
		sheetSummaries.setChecked(row.id, !row.checked);
	}

	const pricingRows = rows.filter(x => x.status === 'PRICING'); // TODO: type status
	// const allChecked = !pricingRows.some(x => !selectedDict[x.id]);
	const allChecked = !pricingRows.some(x => !x.checked);

	const toggleAll = () => {
		if (rows.length < 1) return;
		sheetSummaries.setAllCheckedByDay(yyyymmdd, rows.some(x => !x.checked))
	}


	const { sortedRows, sortBy, pageSortState } = usePageSortTable<TapeSummaryTableRow, keyof TapeSummaryTableRow>({
		// Consider changing keyFrn to deps array. We need to know if things have changed, and thus, resort stuff.
		keyFn: row => row.id + String(row.checked),
		rows: rows,
		valFn: (row, colId) => row[colId]
	})

	const tableProps: TableProps = {
		rowCursor: 'pointer',
		headers: [
			{ label: pricingRows.length > 0 ? <Checkbox onToggle={toggleAll} checked={allChecked} /> : '' },
			pageSortHeader('Id', 'id', pageSortState, sortBy),
			pageSortHeader('Filename', 'filename', pageSortState, sortBy),
			pageSortHeader('Seller', 'sellerName', pageSortState, sortBy),
			pageSortHeader('Status', 'status', pageSortState, sortBy),
			// { label: '', alignData: 'left', alignHeader: 'left' },
			pageSortHeader('Loans', 'loanCount', pageSortState, sortBy, 'right', 'right'),
			pageSortHeader('Eligible', 'eligibleCount', pageSortState, sortBy, 'right', 'right'),
			pageSortHeader('Uploaded', 'createdAt', pageSortState, sortBy, 'right', 'right')
		],
		rows: sortedRows.map((row, n) => [
			<CheckboxCol
				status={row.status}
				sheetId={row.id}
				checked={row.checked}
				onToggle={() => toggle(row)}
			/>,
			row.id,
			row.filename,
			row.sellerName,
			labels.bySheetStatus(row.status),
			row.loanCount,
			row.eligibleCount,
			row.createdAt
		]),
		onClick: (rowIndex, colIndex) => {
			if (colIndex === 0) return; // checkbox column
			const summary = details[rowIndex].summary;
			if (!summary.id) return;
			navigate('/tape/' + summary.sheetId);
		}
	}

	return <Table {...tableProps} />
}
