import React, { useState, useEffect, useMemo } from 'react';
import { GQL, ImportModel } from 'market-dto';
import { utils } from 'services';

// if the run contains more than this amount of distinct values...no filter for you!
const MAX_DISTINCT_VALS = 25;

// // But isn't this just trying to get the same thing as useMemo?? YES!
// const useStateWithDeps = <T,K,>(x:T, fn:(x:T) => K) => {
//     // I didn't wanna run fn() on every render, but, i did wanna run it when x changed.
//     // (and i was never gonna use the setData--so, not quite the same as useMemo)
//     const [ data, setData ] = useState(() => fn(x));
//     useEffect(() => {
//         setData(fn(x))
//     }, [x])
//     return [ data, setData ];
// }

const createFilterSet = (colCfg:GQL.XlsExportCol) => {
    const s = new Set<any>();
    colCfg.filterVals.forEach(val => s.add(val));
    return s;
}

interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportCol;
    readonly flowRun:GQL.FlowRun;
    readonly onChange:(x:GQL.XlsExportCol) => void;
}
export const EditColFilter = ({ model, colCfg, flowRun, onChange }:Props) => {

    const filterSet = useMemo(() => createFilterSet(colCfg), [colCfg]);
    const setFilterSet = (nextSet:Set<any>) => {
        onChange({
            ...colCfg,
            filterVals: [...nextSet]
        })
    }

    const [ userVal, setUserVal ] = useState('');

    const toggle = (val:any) => {
        if (filterSet.has(val)) {
            filterSet.delete(val);
        } else {
            filterSet.add(val);
        }
        console.log(filterSet.size);
        
        // tell react to render. which means, we need a new set.
        // this is really no different than creating a new dict object with "val" as key, toggling true/false as value
        setFilterSet(new Set(filterSet));
    }

    if (colCfg.type !== "MAPPED") {
        return (
            <div style={{ marginTop: '4px' }}>
                Currently, filtering is only available for MAPPED col types.
            </div>
        )
    }

    const { fieldId } = colCfg;
    const arr = model.getEnumerationDropdownOptions(fieldId);

    if (arr) {
        // LoanField WITH ENUMS! Easiest case!
        return (
            <div>
                { arr.map(x => (
                    <div style={{ display:'flex', alignItems:'center' }} key={x.value}>
                        <div>
                            <input
                                checked={filterSet.has(x.value)}
                                onChange={() => toggle(x.value)}
                                type="checkbox" id={"chk-box-" + x.value}
                            />
                        </div>
                        <div>
                            <label htmlFor={"chk-box-" + x.value}>{ x.label }</label>
                        </div>
                    </div>
                )) }
            </div>
        )
    }

    const distinctVals = utils.toDistinct(flowRun.rows.map(x => x.loan[fieldId]));
    if (distinctVals.length < MAX_DISTINCT_VALS) {
        distinctVals.sort();
        return (
            <>
                <div>
                    { distinctVals.map((x, n) => (
                        <div style={{ display:'flex', alignItems:'center' }} key={n}>
                            <div>
                                <input
                                    type="checkbox"
                                    id={"chk-box-val-" + String(n)}
                                    checked={filterSet.has(x)}
                                    onChange={() => toggle(x)}
                                />
                            </div>
                            <div>
                                <label htmlFor={"chk-box-val-" + String(n)}>{ x }</label>
                            </div>
                        </div>
                    )) }

                </div>
                <div>
                    Enter user value:
                    <div style={{ display:'flex', alignItems:'center'}}>
                        <input
                            placeholder="Enter Value"
                            className="m-xls-input m-xls-bordered"
                            type="text"
                            value={userVal}
                            onChange={x => setUserVal(x.target.value)}
                        />
                        <button className="m-xls-btn m-xls-btn-gray">Go</button>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div>
                I'm sorry. there were just too many distinct values ({ distinctVals.length }) in this run.<br/>
                Do I say that you cannot filter by this field?<br/>
                Or only user values allowed?<br/>
                Or show everything?<br/>
            </div>
        )
    }

}