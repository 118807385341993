import React from 'react';
import { FrmDropdown, FrmInput, Text, Box } from 'mui';
import { JSONSchema } from 'market-dto';
import { missingTitle, getItemsFromOneOf } from './schema-ui.service';

const getRightSide = (min?:number, max?:number):React.ReactNode|undefined => {
    if (min == undefined && max == undefined) return;
    if (min !== undefined && max !== undefined) {
        return (
            <Box fg="faded" fontSize="sm" textStyle="italic">
                <Text fg="really-faded">Range:&nbsp;</Text>
                { min }
                <Text fg="really-faded">&nbsp;-&nbsp;</Text>
                { max }
            </Box>
        )
    }
    // one or the other
    return (
        <Box fg="faded" fontSize="sm">
            { min && <>
                <Text fg="really-faded">Min:</Text> { min }
            </> }
            { max && <>
                <Text fg="really-faded">Max:</Text> { max }
            </> }   
        </Box>
    )
}

interface Props {
    readonly int?:boolean;
    readonly val:number;
    readonly schema:JSONSchema;
    readonly editting:boolean;
    readonly setValue:(x:number)=>any;
}
export const SchemaUiItemNumber = ({ val, schema, setValue, int, editting }:Props) => {

    if (schema.oneOf) {
        // A non-multi (single item) dropdown
        // NOTE: this is just like what we do in "string" if also has a oneOf,
        // only difference being the underlying values here are numbers
        const items = getItemsFromOneOf(schema);
        return (
            <FrmDropdown
                size="sm"
                label={schema.title ? schema.title : missingTitle}
                desc={schema.description}
                subLabel={schema.sample ? 'Example:' : undefined}
                subDesc={schema.sample}
                disabled={!editting}
                items={items}
                value={items.find(x => x.value === val)}
                setValue={setValue}
            />
        )
    }

    const numType = int ? 'int' : 'float';
    // ...just a simple string input
    const pig = { min: schema.minimum, max: schema.maximum, [numType]:true };


    
    return (
        <Box ml="2">
            {/* num { val }
            <textarea readOnly value={JSON.stringify(pig, null, 4)} /> */}
            <FrmInput
                size="sm"
                readOnly={!editting}
                label={schema.title ? schema.title : missingTitle }
                desc={schema.description}
                subLabel={schema.sample ? 'Example:' : undefined}
                subDesc={schema.sample}
                numeric={{ min: schema.minimum, max: schema.maximum, [numType]:true }}
                value={val}
                // align="right"
                rightSide={getRightSide(schema.minimum, schema.maximum)}
                setValue={setValue}
            />
        </Box>
    )
}