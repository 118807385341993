import { GQL, SellerMarginsDoc } from 'market-dto';
import { SellerMarginData } from './types';

export const toSellerMarginData = (sellers:GQL.Org[], dict:SellerMarginsDoc):SellerMarginData => {
    const sellersWithMargins = sellers.map(seller => {
        return {
            ...seller,
            margin: dict[seller.id] ?? dict['default']
        }
    });
    return {
        defaultMargin: dict['default'],
        sellers: sellersWithMargins
    }
}