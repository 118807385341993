
export const resize = async (base64:string, maxWidth:number, maxHeight:number):Promise<string> => {
    const img = await getImage(base64);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d")!;    
    const dims = getTargetDims(img, maxWidth, maxHeight);
    canvas.width = dims.width;
    canvas.height = dims.height;
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, dims.width, dims.height);
    return canvas.toDataURL();
}

const getTargetDims = (img:HTMLImageElement, maxWidth:number, maxHeight:number) => {

    let targetWidth = img.width;
    let targetHeight = img.height;
    
    if (maxWidth > 0 && maxHeight > 0) {
        if (targetWidth > maxWidth) {
            targetWidth = maxWidth;
            targetHeight = Math.round(img.height * (maxWidth / img.width));
        }
        if (targetHeight > maxHeight) {
            targetHeight = maxHeight;
            targetWidth = Math.round(img.width * (maxHeight / img.height));
        }
    }
    return {
        width: targetWidth,
        height: targetHeight
    }
}

const getImage = (src:string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img);
        img.onerror = reject
        img.src = src
    })
}