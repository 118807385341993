import { mutate, query, gql, GQL } from "../../../client";
import { FLOW_FIELDS } from './returning';
import { alpha } from 'services';
/*
    type eligibilityClassifier_update_input {
        name: String
        cuid: String
        constructs: [construct_insert_input]
        tags: [String]
    }
*/
// sync_install_flow(
//     recipeName: String
//     ): JSON

const MUTATE = gql`
    mutation installFlow($recipeName: String, $flowName: String) {
        sync_install_flow(recipeName: $recipeName, flowName: $flowName) { ${ FLOW_FIELDS } }
    }
`;

interface UpsertOutput {
    readonly sync_install_flow:GQL.Flow;
}

interface UpsertInput {
    readonly recipeName:string;
    readonly flowName:string;
}
export const installFlow = async (recipeName:string, flowName:string):Promise<GQL.Flow|undefined> => {
    const result = await mutate<UpsertInput, UpsertOutput>(MUTATE, { recipeName, flowName })
    const flow = result?.data?.sync_install_flow;
    return flow ? alpha.withFlattenedTasks(flow) : undefined;
}
