import React, { useState, useEffect } from "react";
import { PublicRoutes } from "public-routes";
import { AuthRoutes } from "./routes/auth-routes";
import { useZState } from "hooks";
import { user } from "services";
import { sheetMaker } from 'market-dto';
import { BrowserRouter } from 'react-router-dom';
import { PortalPlaceholder, ModalContainer, MainSection, ConfirmModalContainer } from 'mui';
import { toast, ToastContainer } from 'react-toastify';
import { NavBar } from 'components';
import { actMon } from 'services';
import 'react-toastify/dist/ReactToastify.css'; // TODO: merge with m-ui
import 'mui/css/index.css';
// import 'ace-builds/webpack-resolver'; // NOTE: important if using json editor, fixes worker not found issue.

sheetMaker.setSheetLib("sheetjs"); // exceljs won't work with namespaced xml under the hood :(
toast.configure();
actMon.init();

type AuthState = "logged-in" | "logged-out" | "init-check";

export const App = () => {

	const { data:session } = useZState(user.zUser);
	const jwt = session?.jwt;
	const [ authState, setAuthState ] = useState<AuthState>("init-check");
	
	useEffect(() => {
		// any time our jwt changes, we must ask ourselves...
		// ...do we still have a jwt? if not, we are logged out!
		// Also, if this is your first time here, we must validate the jwt vs the server.
		// subsequent jwt changes do not need this...those jwts, we assume, came from renewing the jwt or logging in.
		if (authState === 'init-check') {
			// initial check--is our jwt any good?
			console.log('Init JWT check...');
			user.checkIfJwtIsStillValid().then(valid => {
				console.log('Init JWT valid:', valid);
				setAuthState(valid ? "logged-in" : "logged-out");
			})
		} else {
			setAuthState(jwt ? "logged-in" : "logged-out");
		}
	}, [jwt, authState])

	return (
		<>
			<BrowserRouter>
				{ (!session || authState === 'logged-out') && <PublicRoutes /> }
				{ (session && authState === 'logged-in') && (
					<>
						<NavBar/>
						<MainSection>
							<AuthRoutes />
						</MainSection>
					</>
				) }
			</BrowserRouter>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={true}
				className="toast-container"
				toastClassName="dark-toast"
				progressClassName=""
			/>
			<ModalContainer />
			<ConfirmModalContainer />
			<PortalPlaceholder id="$mui-popper" />
		</>
	)
}
