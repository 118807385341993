import React, { useState, useEffect } from 'react';
import { FileSelectButton, Box, Text, Button, modal, FlexVCenter, Input } from 'mui';
import { ModelsTable } from './models-table';
import { ModelModal } from './model-modal';
import { useZStateList } from 'hooks';
import { rule } from 'services';
import { UploadModal } from './upload-modal';

export const ListModels = () => {

    const [ filterText, setFilterText ] = useState('');
    const { data:unfilteredModels, clearItem, selectNewItem } = useZStateList(rule.zModels);

    const txt = filterText.trim().toLowerCase();
    const models = (unfilteredModels && unfilteredModels.filter(m => {
        return m.name.toLowerCase().includes(txt) || m.description.toLowerCase().includes(txt);
    })) || [];

    const addModelModal = () => {
        selectNewItem();
        modal.open({
            title: 'New Model',
            render: <ModelModal />,
            onClose: () => {
                clearItem();
            }
        })
    }

    const onUpload = async (files:File[]) => {
        if (!unfilteredModels) return;
        if (files.length !== 1) return;

        const file = files[0];
        const json = await file.text();

        modal.open({
            title: 'Upload Model',
            size:'md',
            render: <UploadModal json={json} existingModels={unfilteredModels} />
        })
    }


    return (
        <>

            {/* <Box fg="loud" fontSize="lg" fontWeight="bold" mb="2">Rule Models</Box> */}
            <FlexVCenter justifyContent="space-between" mb="2">
                <FlexVCenter>
                    <Input
                        placeholder="Filter Models"
                        appendIcon="search"
                        value={filterText}
                        setValue={setFilterText}
                    />
                    <Box fg="default" ml="1">
                        { models.length }
                        <Text fg="faded">&nbsp;Model{ models.length === 1 ? '' : 's' }</Text>
                    </Box>
                </FlexVCenter>
                <FlexVCenter justifyContent="flex-end">
                    <FileSelectButton
                        mr="1"
                        size="sm"
                        type="default"
                        onSelect={onUpload}
                        accept=".json"
                        multiple={false}
                        icon="upload"
                    >Upload</FileSelectButton>
                    <Button size="sm" icon="plus" onClick={addModelModal}>Add Model</Button>
                </FlexVCenter>
            </FlexVCenter>
            <ModelsTable models={models} />
        </>
    )
}