import React, { useState } from 'react';
import { Loading } from 'components';
import { ListFlows, NewFlow } from './list';
import { FlowListSelection } from './list/types';
import { alpha } from 'services';
import { useZStateList } from 'hooks';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { GQL } from 'market-dto';
import { Flow } from './flow';
import { Box } from 'mui';

export const Flows = () => {

    const nav = useNavigate();
    const { fetching, data } = useZStateList(alpha.zFlows, { fetchArgs: [] });
    const flows = data ?? [];

    const navToFlow = (item:GQL.Flow) => nav(item.id);
    const navToNew = () => nav('new');
    const navToList = () => nav('list');

	const select = ({ flow, runWithTapeId, runId }:FlowListSelection) => {
		if (!runId) nav(flow.id);
        if (runWithTapeId) nav(flow.id + '/run/' + runWithTapeId);
        if (runId) nav(flow.id + '/edit?runId=' + runId);
	}

    return (
        <Loading
            loading={fetching}
            render={
                <Box mt="2">
                    <Routes>
                        <Route path="list" element={<ListFlows flows={flows} onAdd={navToNew} onSelect={select}  />} />
                        <Route path="new" element={<NewFlow onBack={navToList} onAdded={navToFlow} />} />
                        <Route path=":id/*" element={<Flow />} />
                        <Route path="*" element={<Navigate to="list" />} />
                    </Routes>
                </Box>
            }
        />
    )
}