import { DropdownItem } from 'mui';
import { getAllModels } from './get-all-models';

export const getModelsAsDropdownOpts = () => {
    const models = getAllModels()
    return models ? models.map((x):DropdownItem => {
        return {
            label: x.name,
            value: x.id
        }
    }) : []
}
