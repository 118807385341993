import { GQL } from 'market-dto';
import { InvalidMatchState } from "../types";

export const templateSanityCheck = (template:GQL.Template, sellers:GQL.Org[]):InvalidMatchState|undefined => {
    if (!template.orgIds) {
        return "EMPTY_ORG_IDS_ON_TEMPLATE";
    }
    const missingSeller = template.orgIds.some(orgId => !sellers.find(seller => seller.id === orgId));
    if (missingSeller) {
        return "UNKNOWN_ORG_ID_IN_TEMPLATE";
    }
    if (template.orgIds.length === 0) {
        return "EMPTY_ORG_IDS_ON_TEMPLATE";
    }
    if (template.orgIds.length > 1 && !template.aggregatorName) {
        return "MULTIPLE_ORG_IDS_ON_NON_AGG_TEMPLATE";
    }
    return;
}