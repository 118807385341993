import { ExpNode } from './exp-types';

/*
    TO WALK THE TREE...YOU END UP WITH TWO ACCUMULATORS! NORMAL (things seen before) AND CHILD ACC!
    THIS ALLOWS FOR:  acc + "fn(" + childAcc + ")"  kind of things...
*/

export interface GenericWalkerOpts<T> {
    readonly parent:ExpNode|null;
    readonly node:ExpNode|null;
    readonly lvl:number;
    readonly acc:T;
    readonly childAcc?:T;
    readonly initChildAcc?:T;
    readonly fn:(x:GenericWalkerOpts<T>) => T;
}

export const genericWalker = <T>(w:GenericWalkerOpts<T>):T => {

    const { node, lvl, acc, childAcc, fn, initChildAcc, parent } = w;

    if (node === null) return fn(w);

    if (node.type === 'ParenScopedNode') {
        return fn({
            parent,
            node,
            acc,
            childAcc: genericWalker({
                parent: node,
                node: node.child,
                acc,
                lvl: lvl+1,
                initChildAcc,
                fn
            }),
            lvl,
            fn,
            initChildAcc
        })
    } else if (node.type === 'ExpressionNode') {        
        return fn({
            node,
            parent,
            acc: genericWalker({
                parent: node,
                node: node.left,
                fn,
                acc,
                lvl: lvl+1,
                initChildAcc
            }),
            childAcc: genericWalker({
                parent: node,
                node: node.right,
                fn,
                acc: initChildAcc !== undefined ? initChildAcc : acc,
                initChildAcc,
                lvl: lvl+1
            }),
            lvl,
            initChildAcc,
            fn
        })
    } else if (node.type === 'ConstantNode') {
        return fn({
            parent,
            node,
            acc,
            lvl,
            initChildAcc,
            fn
        })
    } else if (node.type === 'IdentifierNode') {
        return fn({
            parent,
            node,
            acc,
            lvl,
            initChildAcc,
            fn
        })
    } else if (node.type === 'FunctionCallNode') {
        let macc:T = initChildAcc !== undefined ? initChildAcc : acc;
        for (let i=0; i < node.args.length; i++) {
            macc = genericWalker({
                parent: node,
                node: node.args[i],
                fn,
                initChildAcc,
                acc: macc,
                lvl: lvl+1
            })
        }
        return fn({
            parent,
            node,
            acc,
            lvl,
            fn,
            initChildAcc,
            childAcc: macc
        })
    } else if (node.type === 'ArrayNode') {
        let macc:T = initChildAcc !== undefined ? initChildAcc : acc;
        for (let i=0; i < node.items.length; i++) {
            macc = genericWalker({
                parent: node,
                node: node.items[i],
                fn,
                initChildAcc,
                acc: macc,
                lvl: lvl+1
            })
        }
        return fn({
            parent,
            node,
            acc,
            lvl,
            fn,
            initChildAcc,
            childAcc: macc
        })
    } else if (node.type === 'UnaryNode') {
        const childAcc = genericWalker({
            parent: node,
            node: node.child,
            fn,
            initChildAcc,
            acc,
            lvl: lvl+1
        })
        return fn({
            parent,
            node,
            acc: childAcc,
            fn,
            initChildAcc,
            lvl
        })
    } else if (node.type === 'ListItemNode') {


        // OLD:
        // const listAcc = fn({
        //     parent,
        //     node,
        //     acc,
        //     lvl,
        //     fn,
        //     initChildAcc
        // })
        // return genericWalker({
        //     parent: node,
        //     node: node.item,
        //     fn,
        //     initChildAcc,
        //     acc:listAcc,
        //     lvl:lvl+1
        // })

        const childAcc = genericWalker({
            parent: node,
            node: node.item,
            fn,
            initChildAcc,
            acc:initChildAcc ?? acc,
            lvl: lvl+1
        })
        return fn({
            parent,
            node,
            acc,
            childAcc,
            fn,
            initChildAcc,
            lvl
        })
    } else if (node.type === 'StringConstantNode') {
        return fn({
            parent,
            node,
            acc,
            lvl,
            initChildAcc,
            fn
        })
    }
    console.log('DUDE', JSON.stringify(node, null, 4));
    throw new Error('Unhandled exp node type:' + (node as any).type);
}
