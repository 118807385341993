import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BatchItem } from '../types';
import { actLog } from '../../activity-monitor';
import { ActivityLogItem, ActivityMonitorJob, ActivityLogCategory, ActivityLogType } from '../../activity-monitor/types';

export const logActivity = (msg:string) => (item$:Observable<BatchItem>) => {
    return item$.pipe(
        tap(x => {
            actLog("TAPE_IMPORT", "INFO", x.tapeId ?? '-', msg);
        })
    )
}

