import { ExpContext, ExpressionEditorState, SenseOption } from './exp-types';
import * as mathJs from 'mathjs';
import { parseExpression } from './parse-expression';
import { getActiveNode } from './get-active-node';
import { getSenseOptions } from './get-sense-options';
import { isValidExpNode } from './is-valid-exp-node';

const parseWithMathJs = (exp:string):mathJs.MathNode | undefined => {
    try {
        return mathJs.parse(exp);
    }
    catch {
        return undefined;
    }
}

export const getInitExpEditorState = (expStr:string, expCtx:ExpContext) => {
    // We don't need funcs and fields and ops, those are for SENSE OPTIONS.
    // init state, sense is closed. This is for use OUTSIDE of editor.
    return getExpEditorState(expStr, 0, expCtx, [], []);
}


export const getExpEditorState = (
    expStr:string,
    caret:number,
    expCtx:ExpContext,
    fieldsAndFuncOptions:SenseOption[],
    opOptions:SenseOption[]
) => {
    const mathJsResult = parseWithMathJs(expStr);
    const isValidMathJs = mathJsResult ? true : false;
    const parseResult = parseExpression(expStr, expCtx);
    const { node:rootNode, warnings } = parseResult;
    const activeNode = getActiveNode(rootNode, caret);
    const senseOpts = getSenseOptions(activeNode, expCtx, fieldsAndFuncOptions, opOptions);
    // TODO: rename "regardless of math js" to "valid expTree" or something
    const isValidRegardlessOfMathJs = isValidExpNode(rootNode);

    const isTotallyValid = isValidRegardlessOfMathJs && warnings.length === 0 && rootNode?.valType !== 'unknown';
    // console.log('isValidRegardlessOfMathJs', isValidRegardlessOfMathJs);
    // console.log('isTotallyValid', isTotallyValid);
    // console.log('expStr', expStr);
    // console.log('mathJsResult', mathJsResult);
    // console.log('*********************************');
    const state:ExpressionEditorState = {
        caret,
        exp: expStr,
        // Both must be valid (valid jeffTree AND mathjs tree)
        isValidRegardlessOfMathJs,
        isTotallyValid: isTotallyValid && isValidMathJs, // why would totally valid exlude mathjs???
        isValid: isValidRegardlessOfMathJs && isValidMathJs,
        rootNode,
        isValidMathJs,
        activeNode,
        senseOpts,
        warnings
    }
    return state;
}