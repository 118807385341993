import React from 'react'
import { useParams } from 'react-router-dom';
import { sheets } from 'services';
import { useZState } from 'hooks';
import { Loading } from 'components';
import { TapeDetailsRoutes } from './tape-details-routes';

export const TapeDetails = () => {
	const { id } = useParams();
	if (!id) throw new Error('Expected id');
	const { fetching:loading, data:details } = useZState(sheets.zCurrent, { fetchArgs:[id] });
	return (
		<Loading
			loading={loading || !details}
			render={<TapeDetailsRoutes details={details!}/>}
		/>
	)
}