import React from 'react';
import { MatchTableAggRow } from './match-table-agg-row';
import { MatchTableNonAggRow } from './match-table-non-agg-row';
import { tapeImporter } from 'services';
import { ImportModel } from 'market-dto'
import { OverrideCol } from './override-col';

interface Props {
    readonly matchResult:tapeImporter.UnassignedValidMatchResult;
    readonly onSelect:(x:tapeImporter.UnassignedValidMatchResult) => void;
    readonly model:ImportModel;
    readonly dupeTemplate:boolean;
    readonly showAggColumn:boolean;
    readonly showIds:boolean;
}
export const MatchTableRow = ({
    dupeTemplate,
    matchResult,
    model,
    onSelect,
    showAggColumn,
    showIds
}:Props) => {
    return (
        <tr>
            { matchResult.type === 'agg' && (
                <MatchTableAggRow
                    matchResult={matchResult}
                    onSelect={onSelect}
                />
            ) }
            { matchResult.type === 'non-agg' && (
                <MatchTableNonAggRow
                    matchResult={matchResult}
                    onSelect={onSelect}
                    showAggCol={showAggColumn}
                />
            ) }
            <OverrideCol
                model={model}
                template={matchResult.template}
                dupe={dupeTemplate}
            />
            { showIds && <td>{ matchResult.template.id }</td> }
        </tr>   
    )
}