import { GQL, InvestorMarginsDoc } from 'market-dto';
import { InvestorAndMargins } from './types';

export const toInvestorMarginData = (investors:GQL.Org[], dict:InvestorMarginsDoc):InvestorAndMargins[] => {
    return investors.map(x => {
        if (!dict[x.id]) {
            // TODO: if this happens, we need to provide a DEFAULT MARGINS DOC
        }
        return {
            ...x,
            margins: dict[x.id] ?? {} // To prevent error, better to show blank screen
        }
    });
}