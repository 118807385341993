import { useOnClickOutside, useSubs } from "hooks"
import { Box, Flex } from "mui"
import React, {  useEffect, useRef, useState } from "react"
import { BehaviorSubject, Subject } from "rxjs"
import { SelectionEvent } from "./selection-hub"
import { SimpleEditor } from "./simple-editor"
import { IConstruct} from "raccoon-engine"
import {  readableDisplay, wording } from "./types"
import { getSimpleUiHint } from "./ui-hints"


interface ConditionEditorArgs {
	selected$:BehaviorSubject<IConstruct[]>,
	hub$:Subject<SelectionEvent>
}

interface ConstructArgs {
	construct:IConstruct,
	hub$:Subject<SelectionEvent>,
}

const ConstructEditor = (props:ConstructArgs) => {
	const { construct: { threshold: { id } }, hub$, construct } = props
	const onRemove = () => hub$.next({ kind: "CANDIDATE", id })
	const [editing, setEditing] = useState(false)
	const ref = useRef<HTMLTableRowElement>(null);
	const hint = getSimpleUiHint(construct.threshold.field as any)
	const editable = !construct.removeOnly || !!(hint)
	useOnClickOutside(ref, () => { setEditing(false)})
	const updateHandler = (replacement:any) => {
		if (construct && construct.threshold && construct.threshold.control) {
			construct.threshold.control.value = replacement
			setEditing(false)
		}
	}
	return (
		<div ref={ref} className="construct-editor">
			<Box>
				<Flex justifyContent="space-between">
					<Box fontSize="xs" fontWeight="bold">{construct.threshold.id}</Box>
					<div className="button-panel danger" onClick={onRemove}>remove</div>
				</Flex>
				{readableDisplay(construct)}
			</Box>
			{
				!editing && editable &&
				<div className="button-panel actionable"
				     onClick={() => setEditing(true)}>edit</div>
			}
			{
				editing && <SimpleEditor
					updateHandler={updateHandler}
					cancelHandler={() => setEditing(false)}
					value={construct.threshold.control?.value}
					hint={hint}/>
			}
		</div>)
}

export const ConditionEditor = (props:ConditionEditorArgs) => {
	const subs = useSubs()
	const { selected$, hub$ } = props
	const [constructs, setConstructs] = useState<IConstruct[]>([])
	useEffect(() => {
		const sub = selected$.subscribe((c) => {
			setConstructs(c)
		})
		subs.push(sub)
	}, [selected$, subs])
	return (<Box bg="inner-card" p="2">
		<Box fontWeight="bold" fontSize="sm">{wording.conditionTitle}</Box>
		{
			constructs.map((c, idx) => <div key={idx}>
				               <ConstructEditor construct={c} hub$={hub$}/>
			               </div>
			)
		}
	</Box>)
}
