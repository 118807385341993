import { RETURNING as SHEET_SUMMARY_RETURNING } from '../sheet-summary/returning';

/*
    Proposals do not need every loan field, or every offer field.
    Below we define which we need.
*/

export const PROPOSAL_OFFER_FIELDS = [
    "loanNumber",
    "offeringPrice",
    "priceTag",
    "investor",
    "investorProductId",
    "investorProductName",
    "commitmentDays",
    "acquisitionDate",
    "notificationDate",
    "servicerSymbol",
    "coupon"
] as const;
export type ProposalOfferField = typeof PROPOSAL_OFFER_FIELDS[number];

export const PROPOSAL_LOAN_FIELDS = [
    "loanNumber",
    "propertyType",
    "loanPurpose",
    "loanType",
    "id",
    "cltv",
    "currentBalance",
    "escrowWaived",
    "productCode",
    "dti",
    "ltv",
    "units",
    "state",
    "specialtyProgram",
    "rateType",
    "noteRate",
    "occupancy",
    "pmiType",
    "miCoverage",
    "uli"
] as const;
export type ProposalLoanField = typeof PROPOSAL_LOAN_FIELDS[number];

export const RETURNING = `
    id
    sheetId
    status
    sellerOrgId
    sellerName
    hitLoanIds
    loans {
        ${ PROPOSAL_LOAN_FIELDS.join('\n') }
    }
    offers {
        ${ PROPOSAL_OFFER_FIELDS.join('\n') }
    }
    sheetSummary { ${ SHEET_SUMMARY_RETURNING } }
`

export const PROPOSAL_FIELDS_ONLY = `
    id
    sheetId
    status
    sellerOrgId
    sellerName
    hitLoanIds
`