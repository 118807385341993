import { IConstruct } from "raccoon-engine";

type ConstructMap = Map<string, IConstruct>;

const buildConstructMap = (collection: IConstruct[]): ConstructMap => {
	const mapping: ConstructMap = new Map();
	collection.forEach((construct) => {
		const { id } = construct.threshold;
		mapping.set(id, construct);
	});
	return mapping;
};

export const cannedConstructs: IConstruct[] = [
	{
		threshold: {
			id: "cltv-vs-ltv",
			display: "CLTV cannot exceed LTV.",
			field: "cltv",
			boundary: "MAX",
			category: "ltv",
			control: { value: "ltv", isField: true },
		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "doc-type",
			display: `Document type needs to be one of following: $value`,
			field: "docType",
			boundary: "IN",
			category: "docType",
			control: { value: ["FULL", "STREAMLINED"] },
		},
	},
	{
		threshold: {
			id: "max-loanAmt",
			display: "The max loan amount is $value.",
			field: "originalBalance",
			boundary: "MAX",
			category: "loanAmt",
			control: { value: 300000 },
		},
	},
	{
		threshold: {
			id: "min-loanAmt",
			display: "The min loan amount is $value.",
			field: "originalBalance",
			boundary: "MIN",
			category: "loanAmt",
			control: { value: 80000 },
		},
	},
	{
		threshold: {
			id: "dti-required",
			display: "DTI is required.",
			field: "dti",
			boundary: "EXISTS",
			category: "dti",
			control: { value: true },
		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "min-fico",
			display: "The min FICO is $value.",
			field: "fico",
			boundary: "MIN",
			category: "fico",
			control: { value: 500 },
		},
	},
	{
		threshold: {
			id: "max-fico",
			display: "The max FICO is $value.",
			field: "fico",
			boundary: "MAX",
			category: "fico",
			control: { value: 780 },
		},
	},
	{
		threshold: {
			id: "max-dti",
			display: "The max DTI is $value.",
			field: "dti",
			boundary: "MAX",
			category: "dti",
			control: { value: 0.88 },
		},
	},
	{
		threshold: {
			id: "max-ltv",
			display: "The max LTV is $value.",
			field: "ltv",
			boundary: "MAX",
			category: "ltv",
			control: { value: 0.85 },
		},
	},
	{
		threshold: {
			id: "note-no-interpolate",
			display: "The note rate is a multiple of 1/8 of a percent (0.25%).",
			field: "noteRate",
			boundary: "MULTIPLE",
			category: "noteRate",
			control: { value: 0.125 },
		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "cltv-required",
			display: "CLTV is Required",
			field: "cltv",
			boundary: "EXISTS",
			category: "cltv",
			control: { value: true }

		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "ltv-required",
			display: "LTV is Required",
			field: "cltv",
			boundary: "EXISTS",
			category: "ltv",
			control: { value: true }

		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "dti-required",
			display: "DTI is Required",
			field: "dti",
			boundary: "EXISTS",
			category: "dti",
			control: { value: true }

		},
		removeOnly: true,
	},
	{
		threshold: {
			id: "aus-type",
			display: `AUS needs to be one of following: $value`,
			field: "aus",
			boundary: "IN",
			category: "aus",
			control: { value: ["DU", "LP"] },
		},
	},
	{
		threshold: {
			id: "rate-type",
			display: `Rate type needs to be one of following: $value`,
			field: "rateType",
			boundary: "IN",
			category: "rateType",
			control: { value: ["FIXED"] },
		},
	},
	{
		threshold: {
			id: "loan-type",
			display: `Loan type needs to be one of following: $value`,
			field: "loanType",
			boundary: "IN",
			category: "loanType",
			control: { value: [""] },
		},
	},
	{
		threshold: {
			id: "special-program",
			display: `Special loan program needs to be one of following: $value`,
			field: "specialtyProgram",
			boundary: "IN",
			category: "specialtyProgram",
			control: { value: ["IRRRL"] },
		},
	},
	{
		threshold: {
			id: "property-type",
			display: `Property type needs to be one of following: $value`,
			field: "propertyType",
			boundary: "IN",
			category: "propertyType",
			control: { value: ["SFD"] },
		},
	},
];

export const cannedConstructMap = buildConstructMap(cannedConstructs);




// 	"third-party-origination": {
// 		label: "Allow Third Party Origination",
// 		id: "third-party-origination",
// 		uiType: "simply-exists",
// 		includedText: "Third Party Origination is Allowed",
// 		field: "tpoFlag",
// 		boundary: "NOT",
// 		showing: false,
// 		threshold: null,
// 		control: { value: true }
// 	}
// }
