import React, { useState } from 'react';
import { MainSection, Box } from 'mui';
import { NavBar } from "components";
import { EnterEmail } from './enter-email';
import { EnterToken } from './enter-token';

export interface Session {
    readonly email:string;
    readonly sessionId:string;
}

export const Login = () => {

    const [ loginData, setLoginData ] = useState<Session>();

    return (
        <>
            <NavBar />
            <MainSection>
                <Box mt="2">
                    { !loginData && <EnterEmail onValidEmail={setLoginData} /> }
                    { loginData && <EnterToken loginData={loginData} reset={() => setLoginData(undefined)} /> } 
                </Box>
            </MainSection>
        </>
    );
}