import { GQL } from 'market-dto';
import { getOfferState } from './get-offer-state';

// If true reasons are null, you are eligible.
export const isTrulyEligible = (loan:GQL.Loan) => {
    // I have a small problem with this. if a loan has 1 invalid offer only,
    // we say "inelgible",but, might it be eligible for diff agency? yes!
    // FOR now, leave this.
    return getOfferState(loan).ineligibleReasons === null;
}
