import React from 'react';
import { IconButton, Icon, Button, Input, Box, FlexVCenter } from 'mui';

// TODO: this can be made generic and put in mui. It's almost there!
// (seller modal for aliases would use it too.)

interface Props {
    readonly label:React.ReactNode;
    readonly placeHolder?:string;
    readonly addTitle?:string;
    readonly items:string[];
    readonly canSave?:boolean; // if dirty, you might want it disabled
    readonly saving?:boolean;
    readonly onSave?:() => void; // if not defined, we don't show save button
    readonly onChange?:(tags:string[]) => void;
}
export const Tags = ({
    placeHolder,
    label,
    items,
    onChange,
    onSave,
    saving,
    canSave,
    addTitle
}:Props) => {

    const addNewItem = () => {
        if (onChange) onChange(items.concat(''));
    }

    const updateItem = (s:string, index:number) => {
        // yeah, not the most efficient. but...there won't be many tags.
        if (onChange) onChange(items.map((x, n) => n === index ? s : x));
    }

    const removeItem = (index:number) => {
        if (onChange) onChange(items.filter((x, n) => n !== index));
    }

    return (
        <>
            <FlexVCenter ml="1" mb="1">
                <Box fg="faded">{ label }</Box>
                <FlexVCenter flex="1" justifyContent="flex-end">
                    { onSave && (
                        <Button
                            type={canSave ? 'link' : 'disabled'}
                            busy={saving}
                            size="sm"
                            mr="1"
                            onClick={onSave}
                        >Save</Button>
                    ) }
                    <IconButton
                        size="sm"
                        name="plus"
                        onClick={addNewItem}
                    />
                </FlexVCenter>
            </FlexVCenter>
            { items.map((item, n) => (
                <FlexVCenter mb="1" key={n}>
                    <Box flex="1" pr="1">
                        <Input
                            value={item}
                            setValue={val => updateItem(val, n)}
                            placeholder={placeHolder}
                        />
                    </Box>
                    <Box textAlign="right">
                        <IconButton
                            size="sm"
                            name="trash"
                            title={addTitle}
                            onClick={() => removeItem(n)}
                        />
                    </Box>
                </FlexVCenter>
            )) }
        </>
    )
}
