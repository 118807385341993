import React, { useState } from 'react';
import { Box, Text, Dropdown, DropdownItem, FlexVCenter, Button, Input } from 'mui';
import { GQL, ImportModel } from 'market-dto';
import { getHeaderLabel } from '../get-header-label';
import { utils } from 'services';
import { EnumGetLabel } from './enum-get-label';
import { SrcColCfgAndEntry } from './types';

interface Props {
    readonly model:ImportModel;
    readonly colCfgs:GQL.XlsExportCol[];
    // readonly param:GQL.XlsExportCalcEnumColParam;
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
    readonly src:SrcColCfgAndEntry;
}
export const EnumGetValues = ({
    model,
    colCfgs,
    src,
    onColCfgsChange,
    selectedColId,
    setSelectedColId
}:Props) => {


    const [ dict, setDict ] = useState<{[x:string]:string}>({});
    const [ param, setParam ] = useState<GQL.XlsExportCalcEnumColParam>();

    const canAdd = Object.values(dict).filter(x => x.trim()).length > 0;

    const go = () => {
        if (!canAdd) return;
        const arr = Object.entries(dict).map(([ value, label ]) => {
            return {
                value,
                label
            }
        }).filter(x => x.label.trim())
        setParam(utils.toDict(arr, x => x.value));
    }

    return (
        <>
            <Box mb="1" pb="1" borderColor="alert" borderSides="bottom" borderWidth="thin">
                Based on <Text fg="loud">{ getHeaderLabel(model, src.cfg) }</Text>
            </Box>
            { !param && (
                <>
                    <Box pt="half" maxHeight="card-lg" overflow="auto">
                        <FlexVCenter fontSize="sm" mt="half">
                            <Box flex="1" mr="1" textAlign="right" fg="faded">Value</Box>
                            <Box flex="1" ml="1" fg="faded">Label</Box>
                        </FlexVCenter>
                        { Object.entries(src.entry.labels).map(([ enumVal, enumLabel ]) => {
                            return (
                                <FlexVCenter key={enumVal} fontSize="sm" mt="half">
                                    <Box flex="1" mr="1" textAlign="right">{ enumLabel }</Box>
                                    <Box flex="1" mx="1">
                                        <Input
                                            width="100%"
                                            size="sm"
                                            value={dict[enumVal] ?? ''}
                                            setValue={x => setDict({ ...dict, [enumVal]: x })}
                                        />
                                    </Box>
                                </FlexVCenter>
                            )
                        }) }
                    </Box>
                    <Box textAlign="center" pt="2">
                        <Button
                            type={canAdd ? 'default' : 'disabled'}
                            onClick={go}
                        >Continue</Button>
                    </Box>
                </>
            ) }
            { param && (
                <>
                    <Box pt="half" maxHeight="card-lg" overflow="auto">
                        <FlexVCenter fontSize="sm" mt="half">
                            <Box flex="1" mr="1" textAlign="right" fg="faded">Value</Box>
                            <Box flex="1" ml="1" fg="faded">Label</Box>
                        </FlexVCenter>
                        { Object.values(param).map(({ label, value }) => {
                            return (
                                <FlexVCenter key={value} fontSize="sm" mt="half">
                                    <Box flex="1" mr="1" textAlign="right">{ src.entry.labels[value] }</Box>
                                    <Box flex="1" mx="1">{ label }</Box>
                                </FlexVCenter>
                            )
                        }) }
                    </Box>
                    <EnumGetLabel
                        colCfgs={colCfgs}
                        onColCfgsChange={onColCfgsChange}
                        src={src}
                        param={param}
                        selectedColId={selectedColId}
                        setSelectedColId={setSelectedColId}
                    />
                </>
            ) }
        </>
    )
}