import { query, gql, GQL } from "../../client";

interface QueryResult {
    readonly mbsSchedule:[GQL.MbsSchedule];
}

const QUERY = gql`
    query mbsSchedule {
        mbsSchedule {
            id
            dates
        }
    }
`;

export const fetchMbsSchedules = async ():Promise<GQL.MbsSchedule | undefined> => {
    const result = await query<any, QueryResult>(QUERY, {});
    // const client = getApolloClient(false);
    // const result = await client.query<QueryResult>({ query: QUERY });
    return result.data.mbsSchedule[0];
}
