import React from 'react';
import { Box, FlexVCenter, Button, Input, Checkbox } from 'mui';
import { PricingItemConfig } from './pricing-modal';

interface Props {
    readonly item:PricingItemConfig;
    readonly onChange:(x:PricingItemConfig) => void;
}
export const PricingItem = ({ item: sellerInvestorOptions, onChange }:Props) => {
    return (
        <>
            <FlexVCenter
                textAlign="right"
                borderColor="alert"
                borderSides="right"
                borderWidth="thin"
                borderStyle="dashed"
                p="half"
                height="100%"
                justifyContent="flex-end"
            >{ sellerInvestorOptions.summary.summary.sellerName }</FlexVCenter>
            <FlexVCenter>
                <FlexVCenter flex="1" p="half" mr="1">
                    <Checkbox
                        mx="1"
                        cursor="pointer"
                        checked={sellerInvestorOptions.runFannie}
                        onToggle={x => onChange({ ...sellerInvestorOptions, runFannie: x })}
                    />
                    <Input
                        align="right"
                        numeric={{ float: true, default: sellerInvestorOptions.defaultFannieMargin }}
                        value={sellerInvestorOptions.fannieMargin}
                        setValue={x => onChange({ ...sellerInvestorOptions, fannieMargin: x })}
                        appendIcon="percent"
                    />
                </FlexVCenter>
                <FlexVCenter flex="1" p="half" mr="1">
                    <Checkbox
                        mx="1"
                        cursor="pointer"
                        checked={sellerInvestorOptions.runFreddie}
                        onToggle={x => onChange({ ...sellerInvestorOptions, runFreddie: x })}
                    />
                    <Input
                        align="right"
                        numeric={{ float: true, default: sellerInvestorOptions.defaultFreddieMargin }}
                        value={sellerInvestorOptions.freddieMargin}
                        setValue={x => onChange({ ...sellerInvestorOptions, freddieMargin: x })}
                        appendIcon="percent"
                    />
                </FlexVCenter>
            </FlexVCenter>
        </>
    )
}