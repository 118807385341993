import React from 'react';
import { Text, Box } from 'mui';
import { GQL } from 'market-dto';
import { tapeImporter  } from 'services';

interface Props {
    readonly flow:GQL.Flow;
    readonly isNewFlow:boolean;
    readonly batch?:tapeImporter.Batch;
    readonly tapeId?:string;
}
export const SubMsg = ({ flow, isNewFlow, batch, tapeId }:Props) => {

    if (tapeId) {
        return <>Runing tape <Text fg="loud">{ tapeId }</Text>...</>
    }
    return (
        <>
            { isNewFlow && !batch && (
                <>
                    <Box fg="loud" fontSize="md" mb="1">You're almost there!</Box>
                    { flow.recentActivity && (
                        <Box>
                            You are now ready to begin configuring your new workflow.
                        </Box>
                    ) }
                    { !flow.recentActivity && (
                        <Box>
                            Before we can move forward, please provide a sample tape against which you can begin to configure your new workflow.
                        </Box>
                    ) }
                </>
            ) }
            { !isNewFlow && !batch && (
                <Box>To run this workflow against a tape, simply drop one below.</Box>
            ) }
            { batch && (
                <Box>Importing Tape...</Box>
            ) }
        </>
    )
}