import * as api from 'api';
import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { logActivity } from './log-activity';
import { GQL } from 'market-dto';

export const apiInsertLoanSources = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        logActivity("Inserting Loan Sources"),
        updateStatus("INSERTING_LOAN_SOURCES"),
        switchMap(item => {

            const { tapeId, assigned, rowsAndHeaders } = item;
            if (!tapeId) throw new Error('Expected: tapeId');
            if (!rowsAndHeaders) throw new Error('Expected: rowsAndHeaders');
            if (!assigned || !assigned.generated) throw new Error('Expected: assigned and generated');
            const { loans, sourceMap } = assigned.generated;
        
            const loanSources:GQL.LoanSource[] = loans.map((loan, n):GQL.LoanSource => {
                return {
                    loanId: loan.id,
                    partialLoan: loan,
                    row: rowsAndHeaders.rows[n],
                    sheetId: tapeId,
                    sourceMap: sourceMap[n]
                }
            })

            return from(api.insertLoanSources(loanSources)).pipe(
                tap(result => {
                    mergeBatchItem(item, { status: result ? "INSERT_LOAN_SOURCES_SUCCESS" : "INSERT_LOAN_SOURCES_FAILURE" });
                }),
                map(x => item.index)
            )
        })
    )
}
