import React, { useState, useEffect } from 'react';
import { NavItem } from './nav-item';
import { Box, BasicSpacingProps } from '../box/box';
import { AnimBox } from '../anims/anim-box';
import { Icon } from '../icon/icon';
import { FlexVCenter } from '../flex/flex';
import { GroupedMenuGroup } from './index';
import { useLocation, matchPath } from 'react-router-dom';

const hasActiveChild = (path:string, group:GroupedMenuGroup):boolean => {
    // Isn't this just "active" ?
    return group.items.some(item => {
        return matchPath(item.value, path) ? true : false;
        // return matchPath(path, {
        //     path: item.value,
        //     exact: true
        // }) ? true : false;
    })
}

interface Props extends BasicSpacingProps {
    readonly group:GroupedMenuGroup;
}
export const NavGroup = ({ group, ...boxProps }:Props) => {

    const location = useLocation();
    const [ isOpen, setIsOpen ] = useState(() => hasActiveChild(location.pathname, group));
    useEffect(() => {
        // Only open--do not close--if route changes on us
        setIsOpen(isOpen || hasActiveChild(location.pathname, group));
    }, [location.pathname])

    return (        
        <Box {...boxProps}>
            <FlexVCenter cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
                <AnimBox anim={ isOpen ? "rotate-0-to-90" : "rotate-90-to-0" }>
                    <Icon name="chevron-right" fg="link" />
                </AnimBox>
                <Box ml="half" fg="default">{ group.label }</Box>
            </FlexVCenter>

            <AnimBox anim={isOpen ? 'expand-height' : 'collapse-height'}>
                <Box pl="3" mt="half">
                    { group.items.map((item, n) => (
                        <NavItem
                            key={n}
                            item={item}
                        />
                    ))}
                </Box>
            </AnimBox>
        </Box>
    )
}
