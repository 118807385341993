import { BatchItem } from '../types';
import { expandItem } from './expand-item';
import { collapseItem } from './collapse-item';

export const toggleExpanded = (item:BatchItem) => {
    if (item.expanded) {
        collapseItem(item);
    } else {
        expandItem(item);
    }
}