import { mutate, stripInternals } from "../../../client";
import { gql } from "apollo-boost";
import { GQL } from 'market-dto';
import { CONSTRUCT_RETURNING } from "./construct-returning";

const UPDATE = gql`
    mutation updateEligibilityClassifier($set: eligibilityClassifier_update_input!, $where: where_eligibilityClassifier_exp) {
        update_eligibilityClassifier(set: $set, where: $where) {
            affectedRow
            errorMessage
            returning { ${ CONSTRUCT_RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.EligibilityClassifier>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_eligibilityClassifier: {
        readonly returning:[GQL.EligibilityClassifier];
    }
}

export const setEligibilityClassifier = async (data:Partial<GQL.EligibilityClassifier>):Promise<GQL.EligibilityClassifier|undefined> => {
    const { id, ...remaining } = data;
    if (!id) throw new Error('Expected id!');
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(
            remaining),
        where: {
            id: {
                _eq: id
            }
        }
    })
    return result?.data?.update_eligibilityClassifier.returning[0];    
}
