import React from 'react';
import { Box, Checkbox, ModalTitle, modal } from 'mui';
import { FileDropArea, InvalidJson } from 'components';
import { useStickyStateWithDefault } from 'hooks';
import { pricing, actMon } from 'services';
import { GQL } from 'market-dto';

const addReplayJob = (arr:GQL.BatchStatus[]) => {
    const sheetId = "replay";
    const jobId = "replay";
    actMon.addJob({
        selectAfterCreation: true,
        jobName: "replay-pricing",
        label: "Replay",
        jobId,
        checkStatus: pricing.checkReplayPricingJobDone(arr, jobId, sheetId),
        timeoutSeconds: 600, // ten min timeout
        onComplete: () => {}
    })
}

export const PricingReplayModal = () => {

    const [ pricingDebug, setPricingDebug ] = useStickyStateWithDefault("pricing-debug", false);

    const drop = async (files:File[]) => {
        if (files.length !== 1) return;
        const file = files[0];
        const json = await file.text();
        try {
            const arr = JSON.parse(json) as GQL.BatchStatus[];
            const reasons:string[] = [];
            if (!Array.isArray(arr)) reasons.push('Not a valid array');
            if (Array.isArray(arr)) {
                const missingSummary = arr.some(item => !item.summary ? true : false);
                if (missingSummary) reasons.push('At least one item in array missing summary object');
                const missingLogs = arr.some(item => !item.logs || !Array.isArray(item.logs) ? true : false);
                if (missingLogs) reasons.push('At least one item in array missing logs array');
                const missingTimestamp = arr.some(item => !item.timestamp ? true : false);
                if (missingTimestamp) reasons.push('At least one item in array missing timestamp object');
            }
            if (reasons.length > 0) {
                modal.confirm({
                    title: <InvalidJson reasons={reasons} />,
                    hideCancel: true
                })
                return;
            }
            addReplayJob(arr);
            modal.close();
            actMon.openPanel();
        } catch (err) {
            modal.confirm({
                title: <InvalidJson reasons={['Invalid json']} />,
                hideCancel: true
            })
        }
    }

    return (
        <>
            <ModalTitle>Pricing Replay</ModalTitle>
            <Box mt="2">
                <Checkbox
                    mb="2"
                    checked={pricingDebug}
                    onToggle={setPricingDebug}
                    label="Download replay json upon pricing completion"
                />
                <FileDropArea
                    onDrop={drop}
                    multi={false}
                    accept=".json"
                    label="Drop Pricing Debug Replay JSON File"
                />
            </Box>
        </>
    )
}