import { GQL } from 'market-dto';
import { ExpandedState } from './types';

export const getConfirmedSeller = (exState:ExpandedState):GQL.Org|undefined => {
    const { selected, newAggregatorName, mappedForSellerOrg, sellerForAgg, newSeller } = exState;
    const isAgg = selected?.aggregatorName || newAggregatorName;
    if (isAgg) {
        // even if using an existing template, you must map to find seller (or be told)
        if (sellerForAgg) return sellerForAgg;
        if (mappedForSellerOrg) return mappedForSellerOrg;
    } else {
        // if non-agg, use what they tell us. if not, use template they are using org.
        return newSeller || selected?.sellerOrg;
    }
}