import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const QUERY = gql`
    query {
        settlementCalendar(where: {}) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryOutput {
    readonly settlementCalendar:GQL.SettlementCalendar[];
}

export const fetchAllSettlementCalendars = async ():Promise<GQL.SettlementCalendar[]|undefined> => {
    const result = await query<QueryInput, QueryOutput>(QUERY, { 
        where: {} // this means, ALL OF THEM
    })
    // return result.data.settlementCalendar.filter(x => !x.deleted);
    const cals = result.data.settlementCalendar.filter(x => !x.deleted);
    cals.sort((a, b) => {
        // asc (most future date last)
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
    })
    return cals;
}