import { query, gql, GQL } from "../../client";

interface QueryResult {
    readonly table: [GQL.AdjTable];
}

const getQuery = (name:GQL.AdjTableName) => {
    return gql`
        query {
            table: ${ name } {
                id
                description
                table
            }
        }
    `;
}

export const fetchAdjTable = async (tableName:GQL.AdjTableName):Promise<GQL.AdjTable> => {
    const result = await query<any, QueryResult>(getQuery(tableName), {})
    return result.data.table[0];
    // const client = getApolloClient(false);
    //const result = await client.query<QueryResult>({ query: getQuery(tableName) });
    //return result.data.table[0];
}
