import React from "react";
import { Box, Flex, GroupedNavMenu, PortalPlaceholder, GroupedMenuGroup } from "mui";
import { Route, Routes, Navigate } from "react-router-dom";
import { Simple } from "./simple/simple";
import { MaxLtv } from "./max-ltv/max-ltv";
import { Llpa } from "./llpa/llpa";
import { AgencySrp } from "./agency-srp/agency-srp";
import { InvestorMargins } from "./margins/investor"
import { SellerMargins } from "./margins/seller";
import { GeneralSettings } from "./general-settings/general-settings";
import { SettlementCalendar } from "./settlement-calendar/settlement-calendar";

const navGroups:GroupedMenuGroup[] = [
    {
        "label": "General",
        "items": [
            {
                "label": "Settings",
                "value": "/config/settings"
            },
            {
                "label": "Settlement Calendar",
                "value": "/config/settlementCalendar"
            },
            {
                "label": "Seller Margins",
                "value": "/config/sellerMargins"
            },
            {
                "label": "Investor Margins",
                "value": "/config/investorMargins"
            }
        ]
    },
    {
        "label": "Eligibility",
        "items": [
            {
                "label": "Simple Rules",
                "value": "/config/simpleRules"
            },
            {
                "label": "Max LTV Rules",
                "value": "/config/maxltv"
            }
        ]
    },
    {
        "label": "Adjustments",
        "items": [
            {
                "label": "LLPAs",
                "value": "/config/llpa"
            },
            {
                "label": "Agency SRP",
                "value": "/config/agencySRP"
            }
        ]
    }
]

export const Config = () => {
	return (
        <Flex>
            <Box mr="1">
                <Box pos="sticky-top-nav" zIndex="top-nav-bar" bg="body" pt="2">
                    <GroupedNavMenu groups={navGroups} mt="1" />
                </Box>
            </Box>
            <Box flex="1" ml="1">
                
                <Box pos="sticky-top-nav" zIndex="top-nav-bar" bg="body" pt="2">
                    <PortalPlaceholder id="config-route-top-area"></PortalPlaceholder>
                </Box>

                <Routes>
                    <Route path="settings/*" element={<GeneralSettings />} />
                    <Route path="settlementCalendar/*" element={<SettlementCalendar />} />
                    <Route path="sellerMargins/*" element={<SellerMargins />} />
                    <Route path="investorMargins/*" element={<InvestorMargins />} />
                    <Route path="simpleRules/*" element={<Simple />} />
                    <Route path="maxltv/*" element={<MaxLtv />} />
                    <Route path="llpa/*" element={<Llpa />} />
                    <Route path="agencySRP/*" element={<AgencySrp /> } />
                    <Navigate to="settings" />
                </Routes>

            </Box>
        </Flex>
	)
}
