import { Box, Button, CssGridBox } from "mui"
import {
	AdjTableBuilder, AdjustmentLookup, genNoteDeliAssertions, genTwoDimArray
} from "raccoon-engine"
import React from 'react';

interface rateSheetArgs {
	programs:string[]
	rateStart?:number
	rateEnd?:number
}


function makeRateSheet (args:rateSheetArgs)
{
	const { programs, rateStart, rateEnd } = args
	const assertions = genNoteDeliAssertions(rateStart || 0.5, rateEnd || 8)
	const table = new AdjTableBuilder()
		.title("Rate Sheet by Program and Note Rate")
		.leaf("loanProgram")
		.leafDelimiters({
			                type: "CATEGORY",
			                name: "Loan Program",
			                categories: [...programs],
		                })
		.branches(["noteRate"])
		.branchDelimiters([
			                  {
				                  type: "ASSERTION",
				                  name: "Note Rate",
				                  assertions: assertions,
			                  },
		                  ])
		.values(genTwoDimArray(assertions.length, programs.length))
		.compose()
	
	const lookup = new AdjustmentLookup(table)
	// test the lookup
	const result = lookup.lookUp({ noteRate: 1.5, loanProgram: "full" })
	if (!result.found) {
		throw new Error("what!!!, you can't find this entry!")
	}
	
	return { result, table }
}

export const ScaffoldNoteRate = () => {
	const programs = ["full", "half"]
	const onScaffold = () => {
		const result = makeRateSheet({ programs })
		console.log(result)
	}
	return <Box bg="card" p="2" mt="2" roundedEdges="all" shadow="card" fontSize="sm">
		<Box fontSize="lg">Rate Sheet</Box>
		<Box>Your rate sheet is organized as rate by loan program. Select this one.</Box>
		<CssGridBox cols={2} mt="2">
			<div>
				<img
					src="https://res.cloudinary.com/twks/image/upload/v1614802405/mtrade/iShot2021-03-03_14.13.00_zocvi5.png"
					alt=""/>
			</div>
			<div >
				<Box fontWeight="bold">
					Customization:
				</Box>
				<Box alignItems={"flex-start"}>
					Loan Programs: {JSON.stringify(programs)}
				</Box>
				<Box>
					Note rate range from 0.5 to 8
				</Box>
				<Button onClick={onScaffold}>Generate to Log</Button>
			</div>
		</CssGridBox>
	</Box>
}
