import { ThresholdOperator, WhenEligibleConstruct } from 'raccoon-engine';

export interface EditableRuleTableCol {
    readonly label:string;
    readonly type:"enum" | "int" | "float" | "const";
    readonly enums?: string[];
    readonly field:string;
}
export interface EditableRuleTableRow {
    readonly vals:Array<string | number | undefined | null>;
    readonly deleted:boolean;
    readonly editting:boolean;
}
export interface EditableRuleTable {
    readonly cols:Array<EditableRuleTableCol>;
    readonly rows:Array<EditableRuleTableRow>;
}

export const whenEligibleConstructsToRuleTableRows = (
    constructs:WhenEligibleConstruct[],
    cols:EditableRuleTableCol[]
):Array<EditableRuleTableRow> => {
    return constructs
        .map(x => {
            return {
                deleted: false,
                editting: false,
                vals: cols.map((col, n) => {
                    if (n === cols.length-1) {
                        // last one is always the threshold
                        return x.eligibility.threshold
                    } else {
                        return x.assertions.find(cond => cond.field === col.field)?.param
                    }
                })
            }
        });
}

export const ruleTableRowToWhenEligibleConstruct = (
    cols:EditableRuleTableCol[],
    row:EditableRuleTableRow,
    boundary:ThresholdOperator,
    field:string,
    category:string,
    rowIndex:number
):WhenEligibleConstruct => {
    // Last value, by convention, is the main value.  the so-called "threshold" value.
    const mainVal = row.vals[cols.length-1];
    const vals = row.vals.slice(0, -1);
    return {
        eligibility: {
            boundary: boundary,
            category: category,
            field: field,
            id: field + '-' + rowIndex,
            threshold: mainVal!!, // TODO: i'd rather not pretend it cannot be undefined!
            order: rowIndex
        },
        assertions: vals.map((val, colIndex) => {
            const col = cols[colIndex];
            return {
                field: col.field,
                operator: "equal", // TODO: is this always gonna be "equal"? If not, we'll have to put this in Col definition
                param: val
            }
        })
    }
}
