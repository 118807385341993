export const RETURNING = `
    id
    name
    externalId
    aliases
    symbol
    orgType
    externalIdSource
    externalId
`;
