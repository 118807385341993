import React from 'react';
import { FrmCheckbox, FrmCheckboxProps } from './frm-checkbox';
import { useProxyData, BindTo } from '../../use-proxy-data';

interface BoundFrmCheckboxProps<T extends {}> extends FrmCheckboxProps {
    readonly bindTo:BindTo<T>;
}
export const BoundFrmCheckbox = <T extends {}>({ bindTo, ...rest }:BoundFrmCheckboxProps<T>) => {
    const proxy = useProxyData(bindTo);
    return <FrmCheckbox checked={proxy.value} onToggle={proxy.setVal} {...rest} />
}
