import * as storage from '../storage';
import { zUser } from './z-user';

export const updateJwt = (jwt:string) => {
    zUser.update(ses => {
        if (!ses) throw new Error('Expected session');
        return {
            ...ses,
            jwt
        }
    })
    storage.set('jwt', jwt);
}