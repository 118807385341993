import * as api from 'api';
import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { logActivity } from './log-activity';

export const apiFinalizeLoans = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        logActivity("Finalizing loans"),
        updateStatus("FINALIZING_LOANS"),
        switchMap(item => {

            const { tapeId } = item;
            if (!tapeId) throw new Error('Expected: tapeId');
        
            return from(api.finalizeLoans(tapeId)).pipe(
                tap(summary => {
                    if (summary) {
                        mergeBatchItem(item, {
                            loanCount: summary.loanStats.loanCount,
                            eligibleCount: summary.loanStats.eligibility?.Eligible ?? 0,
                            // finalOrgName: summary.sellerName, /// What is the point of this?
                            status: "SUCCESS" // final status
                        })
                    } else {
                        mergeBatchItem(item, { status: "FINALIZE_LOANS_FAILURE" });
                    }
                }),
                map(x => item.index)
            )
        })
    )
}