import { GQL, labels, LOAN_FIELDS, PriceTag, LoanField, sheetMaker } from 'market-dto';
import { format, pricing, utils } from 'services';
import { CheckedOfferIdByLoan, ensureLoanSources } from '../sheets';
import { downloadFromArrayBuffer } from '../downloader';


// export const getOfferIds = (sheet:GQL.Sheet, dict:CheckedOfferIdByLoan):string[] => {
//     return Object.entries(dict).filter(arr => arr[1]).map(arr => arr[1]);
// }

export const genBidSheet = async (sheet:GQL.LoanModelSheet, dict:CheckedOfferIdByLoan, marketSettings:{[k:string]:string|number|boolean}) => {

    // Add these three, order:  title, {data}, contact, disclaimer
    const title = marketSettings['bidTape.title'] ?? '';
    const contact = marketSettings['bidTape.contact'] ?? '';
    const disclaimer = (marketSettings['bidTape.disclaimer'] ?? '') as string;
    
    const headers = ['No', 'Seller Name', 'Loan Number', 'Best Offer', 'Delivery Date', 'Settlement Date'];
    const rows = sheet.loans.map((loan, n) => {
        // const bestOfferIndex = offerByLoanId[loan.id] ?? 0;
        // const best = loan.offers && loan.offers.length > 0 ? loan.offers[bestOfferIndex] : undefined;
        const bestOfferId = dict[loan.id] ?? '';
        const best = bestOfferId ? loan.offers?.find(x => x.id === bestOfferId) : undefined;
        return [
            n + 1,
            loan.sellerName ?? sheet.seller.name,
            loan.loanNumber,
            best?.offeringPrice ?? null,
            best?.acquisitionDate ? format.toDate(best?.acquisitionDate) : null,
            best?.securityDate  ? format.toDate(best?.securityDate) : null
        ]                        
    })

    const disclaimerLines = disclaimer.split('\n').map(x => [x]); // nest it in an array, one column, many rows

    const rowsWithColspan:sheetMaker.SheetCol[][] = [
        [{ val: title, colSpan: headers.length-1 }],
        [],
        [],
        headers.map(h => ({ val: h })),
        ...rows.map(cols => cols.map(c => ({ val: c }))),
        [],
        [],
        [{ val: contact }],
        [],
        ...disclaimerLines.map(lines => lines.map(line => ({ val: line })))
    ]

    const arrBuf = await sheetMaker.makeSheetWithColSpans(rowsWithColspan, 'Bid List');
    const filename = sheet.id + '-bids.xlsx';
    downloadFromArrayBuffer(arrBuf, filename);

}
