import { gql } from "apollo-boost"
import { RETURNING } from "./returning"
import { GQL } from "market-dto"
import { query } from "../../client"

const QUERY = gql`
    query loan($where: loan_bool_exp!) {
        loan(where: $where, order_by: "id" ) { ${ RETURNING } }
    }
`;

interface QueryInput {
	readonly where:GQL.WhereString;
}
interface QueryOutput {
	readonly loan:GQL.Loan[];
}

export const pullLoans = async (prefix: string): Promise<GQL.Loan[] | undefined> => {
	const result = await query<QueryInput, QueryOutput>(QUERY, {
		where: {
			id: {
				_startsWith: prefix
			}
		}
	})
	return result.data?.loan;
}
