import React from 'react';
import { HitCard } from './hit-card';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { proposal } from 'services';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly items:proposal.HitLoan[];
    readonly toggleChecked: (id:string, flag:boolean) => void;
    readonly optionalFields:Array<keyof proposal.HitLoan>;
    readonly customizing:boolean;
    readonly refreshingCashWindow:boolean;
    readonly moveField:(from:string, to:string) => void;
    readonly onAdd:(fieldId:keyof proposal.HitLoan) => void;
    readonly onRemove:(fieldId:keyof proposal.HitLoan) => void;
}
export const HitCards = ({ items, hitInfo, toggleChecked, optionalFields, customizing, refreshingCashWindow, moveField, onAdd, onRemove }:Props) => {
    
    return <DndProvider backend={HTML5Backend}>
        { items.map(loan => (
            <HitCard
                cols={5}
                key={loan.id}
                hitInfo={hitInfo}
                loan={loan}
                optionalFields={optionalFields}
                toggleChecked={toggleChecked}
                onAdd={onAdd}
                onRemove={onRemove}
                moveField={moveField}
                customizing={customizing}
                refreshingCashWindow={refreshingCashWindow}
            />
        )) }
    </DndProvider>
}
