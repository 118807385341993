import { GQL, sheetParser } from 'market-dto';
import { toPriceTagByLoanNumber } from './to-price-tag-by-loan-number';
import { assignPricingForDemo } from './assign-pricing-for-demo';
import { tagsToOffers } from './tags-to-offers';
import * as api from 'api';
import { FN_ID, FN_NAME, WF_ID, WF_NAME } from './org-ids';
import * as sheets from '../sheets';
import { Observable, forkJoin, of, NEVER, from } from 'rxjs';
import { actLog } from '../activity-monitor';
import { isTrulyEligible } from '../loans';

const getBuffer = async (file: File) => {
	const abuf: ArrayBuffer = await new Promise((resolve) => {
		const reader = new FileReader();
		reader.onload = (e: any) => resolve(e.target.result);
		reader.readAsArrayBuffer(file);
	});
	return abuf;
}

const toOffers = async (
	sheet: GQL.LoanModelSheet,
	files: File[],
	isDemo: boolean,
	rootName: string,
	tabName: string,
	orgId: string,
	orgName: string
): Promise<GQL.Offer[]> => {
	const eligibleLoans = sheet.loans.filter(isTrulyEligible);
	const abuf = await getBuffer(files[0]);
	const rowsAndHeaders = await sheetParser.fromArrayBuffer(abuf, { tabName, startAtRow:5 });
	const initPriceTagByLoanNumber = toPriceTagByLoanNumber(rootName, rowsAndHeaders);
	const priceTagByLoanNumber = isDemo ?
		assignPricingForDemo(initPriceTagByLoanNumber, eligibleLoans) : initPriceTagByLoanNumber;
	const offers = tagsToOffers(eligibleLoans, priceTagByLoanNumber, orgId, orgName);
	if (offers.length > 0) {
		actLog('PRICING', 'INFO', sheet.id, `Uploading ${ offers.length } ${ orgName } offer${ offers.length !== 1 ? 's' : ''}`);
		await api.insertOffers(offers);
	}
	return offers;
}

export const runSpreadsheetPricingPromise = async (sheet:GQL.LoanModelSheet, files:File[], isDemo:boolean):Promise<boolean> => {
	actLog('PRICING', 'ACTIVITY_STARTED', sheet.id, 'Upload pricing ' + files[0].name);
	await Promise.allSettled([
		toOffers(sheet, files, isDemo, 'Wells Pricing', 'WF BID', WF_ID, WF_NAME),
		toOffers(sheet, files, isDemo, 'Fannie Pricing', 'FN BID', FN_ID, FN_NAME)
	])
	actLog('PRICING', 'ACTIVITY_ENDED', sheet.id, 'Upload pricing ' + files[0].name);
	await sheets.reloadIfCurrent(sheet.id);
	return true;
}

export const getUploadPricingObs = (sheet:GQL.LoanModelSheet, files:File[], isDemo:boolean):Observable<boolean> => {
	if (files.length !== 1) return NEVER;
	return from(runSpreadsheetPricingPromise(sheet, files, isDemo));
	// return of(true).pipe(
	// 	tap(() => {
	// 		actLog('PRICING', 'ACTIVITY_STARTED', sheet.id, 'Upload pricing ' + files[0].name);
	// 	},
	// 	switchMap(() => forkJoin([
	// 		toOffers(sheet, files, isDemo, 'Wells Pricing', 'WF BID', WF_ID, WF_NAME),
	// 		toOffers(sheet, files, isDemo, 'Fannie Pricing', 'FN BID', FN_ID, FN_NAME)
	// 	]).pipe(
	// 		tap(() => actLog('PRICING', 'ACTIVITY_ENDED', sheet.id, 'Upload pricing ' + files[0].name)),
	// 		switchMap(() => sheets.current.refresh()),
	// 		map(() => true)
	// 	))
	// ))
}
