// Add things here as needed.
// Dates, etc.

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const toCurrency = (x:number | string | undefined) => {
    if (x === undefined || x === null) return '';
    return currencyFormatter.format(typeof x === "number" ? x : parseFloat(x));
}

const currencyFormatterNoCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0 // error otherwise (yes, weird)
});

export const toCurrencyNoCents = (x:number | string | undefined) => {
    if (x === undefined || x === null) return '';
    return currencyFormatterNoCents.format(typeof x === "number" ? x : parseFloat(x));
}
