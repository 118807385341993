import { fetchAdjTable } from 'api';
import { GQL } from 'market-dto';
import { SrpImportExportItem } from './agency-srp-config';

export const buildExportItem = async () => {
    // Fetch from server every time (until we change this to cache stuff or zstate)
    let result:{[x in GQL.AgencySrpTableName]?:GQL.AdjTable } = {};
    await Promise.all(GQL.AGENCY_SRP_TABLES.map(async name => {
        result[name] = await fetchAdjTable(name);
    }))
    return result as SrpImportExportItem;
}
