import { createZState } from '../z-state';
import { LoanFilters } from './types';

export const zFilters = createZState<LoanFilters>({
    zid: 'sheet-filters',
    initVal: {
        eligibility: 'show-all',
        text: '',
        fields: ['loanNumber', 'productCode', 'id']
    }
})