import React, { useState } from 'react';
import { Box, FrmDropdown, DropdownItem, Button } from 'mui';
// import { RuleModelFiedlType, RULE_MODEL_FIELD_TYPES } from './types';
// import { testEnums  } from './j-domain';
// import { JDomainItemType  } from './types';
// import { FieldString } from './field-string';
import { FieldEnum } from './field-enum';
import { rule } from 'services';


const fieldTypeLabel:{ [x in rule.RuleModelFiedlType]:string } = {
    string: 'String',
    enum: 'Enumeration',
    boolean: 'Boolean',
    integer: 'Integer',
    number: 'Float'    
}

interface FieldTypesProps {
    readonly field:rule.RuleModelField;
    readonly onChange:(x:rule.RuleModelField) => void;
}
export const FieldTypes = ({ field, onChange }:FieldTypesProps) => {

    // const [ type, setType ] = useState<ruleModels.RuleModelFiedlType>('string');

    // const RULE_MODEL_FIELD_TYPES

    const typeOpts = rule.RULE_MODEL_FIELD_TYPES.map(x => {
        return {
            label: fieldTypeLabel[x],
            value: x
        }
    })

    return (
        <Box>
            <FrmDropdown
                width="100%"
                label="Field Type"
                value={typeOpts.find(x => x.value === field.type)}
                setValue={x => onChange({ ...field, type: x })}
                items={typeOpts}
            />        
            { field.type === 'enum' && (
                <FieldEnum field={field} onChange={onChange} />
            ) }
        </Box>
    )
}