import React from 'react';
import { Box } from 'mui';
import { actMon } from 'services';
import { PricingJob } from './pricing-job/pricing-job';
import { FlowJob } from './flow-job';

interface Props {
    readonly job:actMon.ActivityMonitorJob;
}
export const JobInfo = ({ job }:Props) => {
    
    const { jobName } = job;

    if (jobName.includes('fannie') || jobName.includes('freddie')  || jobName.includes('replay')) {
        return <PricingJob job={job} />
    } else if  (jobName.startsWith('flow:')) {
        return <PricingJob job={job} />
    }

    // This isn't the best way to do this. but it works for now.
    // The moment we have a non-pricing async job to monitor, this will likely change.
    // Perhaps the component to render jobs should be sent to act mon?
    // Or, just maintain a bunch of things here based on jobname...probably fine.

    return <Box>Unknown job type: { job.jobName }</Box>
}
