import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Text } from '../text/text';
import { useIsRouteActive } from '../../hooks/use-is-route-active';

interface Props {
    readonly children:any;
    readonly to:string;
    readonly strict?:boolean;
    readonly sensitive?:boolean;
    readonly exact?:boolean;
}
export const NavLink = ({ children, to, exact, ...rest }:Props) => {

    // const active = useMatch(to);
    const active = useIsRouteActive(to, exact);

    return (
        <Text
            fg={active ? 'loud' : 'faded'}
            fgHover={active ? 'loud' : 'link'}
            cursor={active ? 'default' : 'pointer'}
        ><RouterNavLink to={to} {...rest}>{ children }</RouterNavLink></Text>
    )
}