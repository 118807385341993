import { genericWalker, GenericWalkerOpts } from './generic-walker';
import { ExpNode, ExpExpressionNode, ExpParenScopedNode } from './exp-types';
import { operatorDefByName } from './operator-defs';

const getPrecedence = (node:ExpExpressionNode):number|undefined => {
    if (!node.isValidOp) return;
    return operatorDefByName[node.op]?.precedence;
}

// ExpressionNodes need to be moved around on occassion...when an expressionNode has a child expressionNode
// with precedence which calls for adjusting. (guy with right side child having lower precedence, swap em, sorta...)

const newExpressionNode = (node:ExpExpressionNode, left:ExpNode, right:ExpNode):ExpExpressionNode => {
    const result:ExpExpressionNode = {
        type: 'ExpressionNode',
        start: left.start,
        end: right.end,
        op: node.op,
        isValidOp: node.isValidOp,
        left: left,
        rawOp: node.rawOp,
        raw: left.raw + node.rawOp + right.raw,
        right: right
    }
    return result;
}

const newExpParenScopedNode = (node:ExpParenScopedNode, childNode:ExpNode):ExpParenScopedNode => {
    const parenNode:ExpParenScopedNode = {
        type: 'ParenScopedNode',
        start: node.start,
        end: node.end,
        endParenFound: node.endParenFound,
        rawOpenParen: node.rawOpenParen,
        rawCloseParen: node.rawCloseParen,
        raw: node.raw,
        child: childNode
    }
    return parenNode;
}

export const enforceOrderOfOps = (rootNode:ExpNode|null):ExpNode|null => {
    const newRoot = genericWalker({
        acc: null,
        initChildAcc: null,
        lvl: 0,
        parent: null,
        node: rootNode,
        fn: (x:GenericWalkerOpts<ExpNode|null>):ExpNode|null => {
            const { acc:left, initChildAcc, fn, lvl, node, childAcc:right, parent } = x;
            if (node === null) return null;
            if (node.type === 'ParenScopedNode') {
                // rebuild the paren node because child exp nodes may have had child nodes moved around (tree mutated)
                if (right) return newExpParenScopedNode(node, right);
                // throw new Error('is this possible');
                return node; // shouldn't even be possible!
            }
            if (node.type === 'ExpressionNode') {
                // Tree may not be totally valid yet! That's fine.
                if (!left) return node;
                if (!right) return node;
                if (right.type !== 'ExpressionNode') {
                    // Must rebuild, child may have changed!
                    return newExpressionNode(node, left, right);
                }

                const p1 = getPrecedence(node);
                if (!p1) return newExpressionNode(node, left, right);
                const p2 = getPrecedence(right);
                if (!p2) return newExpressionNode(node, left, right);

                if (p1 < p2) {
                    // detatch right's left, put it as current node's right, and make right's left current node.
                    // it's easy to see if you draw a little diagram.
                    const tmp = right.left;
                    const newLeftNode:ExpExpressionNode = {
                        type: 'ExpressionNode',
                        start: node.start,
                        end: tmp.end,
                        op: node.op,
                        rawOp: node.rawOp,
                        isValidOp: true,
                        left: left,
                        raw: left.raw + node.rawOp + tmp.raw,
                        right: tmp
                    }
                    const newNode:ExpExpressionNode = {
                        type: 'ExpressionNode',
                        start: left.start,
                        end: right.end,
                        op: right.op,
                        isValidOp: true,
                        left: newLeftNode,
                        rawOp: right.rawOp,
                        raw: newLeftNode.raw + right.rawOp + right.right.raw,
                        right: right.right,
                    }
                    return newNode;
                } else {
                    // even though you are fine and don't need to change position (order of operation wise),
                    // you must still be rebuilt, because a child node may have changed.
                    return newExpressionNode(node, left, right);
                }
            }
            return node;
        }
    })
    return newRoot;
}


// INITIAL SOLUTION WAS FORCE PARENS...no longer needed.

// export const normalizeNode = (rootNode:ExpNode|null, expModel:ExpModel):ExpNode|null => {
//     let n = 0;
//     let changesMade = false; // MUTABLE FLAG TO PREVENT INfINITE NORMALIZING!
//     const newRoot = genericWalker({
//         acc: null,
//         initChildAcc: null,
//         lvl: 0,
//         parent: null,
//         node: rootNode,
//         fn: (x:GenericWalkerOpts<ExpNode|null>):ExpNode|null => {
//             const { acc, initChildAcc, fn, lvl, node, childAcc, parent } = x;
//             if (node === null) return null;
//             if (node.type === 'ExpressionNode') {
//                 // Tree may not be totally valid yet! That's fine.
//                 if (!acc) return node;
//                 if (!childAcc) return node;
//                 if (childAcc.type === 'ExpressionNode') {
//                     changesMade = true;
//                     const newParenNode:ExpParenScopedNode = {
//                         // Don't worry about start/end, they will be fixed by converting to string and re-parsing
//                         type: 'ParenScopedNode',
//                         start: 0,
//                         end: 0,
//                         raw: '(',
//                         rawCloseParen: ')',
//                         rawOpenParen: '(',
//                         endParenFound: true,
//                         child: childAcc
//                     }
//                     return {
//                         ...node,
//                         right: newParenNode
//                     }
//                 }
//             }
//             return node;
//         }
        
//     })

    

//     if (!changesMade) return rootNode;

//     const str = expNodeToString(newRoot, false);
//     console.log('************************************help', JSON.stringify(newRoot, null, 4));
//     console.log('STR', str);
//     const resultNode = parseExpression(str, expModel);
//     return resultNode.node;
// }