import { GQL, sheetMaker, ImportModel } from 'market-dto';
import { downloader } from 'services';
import { toRowsAndFilteredColCfgs } from './to-rows-and-filterd-col-cfgs';
import { getHeaderLabel } from './get-header-label';
import { getFormattedVal } from './get-formatted-val';

export const genFlowRunOutputSheet = async (
    model:ImportModel,
    hideEmptyCols:boolean,
    flowRun:GQL.FlowRun,
    cfg:GQL.TaskXlsExportConfig
) => {

    const tabs = cfg.tabs.map((tab):sheetMaker.SheetTab => {
        const { colCfgs, name } = tab;

        const { rows, filteredColCfgs } = toRowsAndFilteredColCfgs(flowRun, colCfgs, hideEmptyCols);

        const headers = filteredColCfgs.map(f => getHeaderLabel(model, f));            
        const formattedRows = rows.map(row => filteredColCfgs.map(f => getFormattedVal(model, colCfgs, f, row)));
        const finalRows = [
            headers,
            ...formattedRows
        ]
        return {
            rows: finalRows,
            tabName: name
        }
    })

    /*

    const { rows, colCfgs:filteredColCfgs } = useMemo(() => {
        return toRowsAndColCfgs(flowRun, colCfgs, hideEmptyCols);
        // return flowRun.rows.filter(row => {
        //     const disqualified = fieldCfgs.some(fieldCfg => {
        //         if (fieldCfg.filterVals.length === 0) return false;
        //         const val = getUnformattedVal(fieldCfg, row) ?? '';
        //         return fieldCfg.filterVals.includes(val) ? false : true;
        //     })
        //     return !disqualified;
        // })
    }, [flowRun, colCfgs])
    */

    // const tab = cfg.tabs[0];
    // const { fieldCfgs } = tab;
    // const rows = toRows(flowRun, fieldCfgs);
    // const headers = fieldCfgs.map(f => getHeaderLabel(f));
    // const formattedRows = rows.map(row => fieldCfgs.map(f => String(getFormattedVal(f, row))));
    // const finalRows = [
    //     headers,
    //     ...formattedRows
    // ]
 
    const arrBuf = await sheetMaker.makeMultiTabSheet(tabs);
    const filename = 'flow-run.xlsx'; // todo: name
    downloader.downloadFromArrayBuffer(arrBuf, filename);
}