import React from 'react';
import { Box, Tabs } from 'mui';
import { JsonEditor, FileDropArea } from 'components';
import { useStickyStateWithDefault } from 'hooks';


interface Props {
    readonly setJson:(x:string) => void;
    readonly setJsonAndSubmit:(x:string) => void;
    readonly json:string;
    readonly tab:string;
    readonly setTab:(x:string) => void;
}
export const UploadModalTabs = ({ setJson, json, setJsonAndSubmit, tab, setTab }:Props) => {

    const onUpload = async (files:File[]) => {
        if (files.length !== 1) return;
        const file = files[0];
        const nextJson = await file.text();
        setJsonAndSubmit(nextJson);
    }

    return (
        <>
            <Tabs
                onSelect={setTab}
                selected={tab}
                items={[
                    { "label": "File", value: "file" },
                    { "label": "JSON", value: "json" }
                ]}
            />
            <Box mt="1" style={{ height:'440px' }}>
                { tab === 'file' && (
                    <Box pt="2">
                        <FileDropArea
                            label="Drop Checklist JSON Here"
                            multi={false}
                            accept=".json"
                            onDrop={onUpload}
                        />
                    </Box>
                ) }
                { tab === 'json' && (
                    <JsonEditor
                        initJson={json}
                        label="Edit Checklist"
                        saveLabel="Continue"
                        onChange={setJson}
                        onSave={setJsonAndSubmit}
                        updating={false}
                        height={400}
                    />
                ) }
            </Box>
        </>
    )
}