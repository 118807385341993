import { differenceInCalendarDays } from "date-fns";
import { CashWindow, GQL, format } from "market-dto";

export const composeOptimumParam = (
    offer:GQL.Offer,
    loan:Partial<GQL.Loan>,
    dailyPrices:CashWindow.ProductPriceSheet
) => {
	const optimumParam:CashWindow.BestExecParams = {
		coupon: offer.coupon || NaN,
		costOfFunds: offer.costOfFunds || NaN,
		balance: loan.currentBalance || NaN,
		acquisitionDate: format.ensureDate(offer.acquisitionDate!),
		noteRate: loan.noteRate || NaN,
		dailyPrice: dailyPrices,
		lockedDate: format.ensureDate(offer.lockDate!),
		deliveryDate: format.ensureDate(offer.notificationDate!),
		frontLeadTimeInDays: differenceInCalendarDays(
			format.ensureDate(offer.acquisitionDate!),
			format.ensureDate(offer.lockDate!)),
		poolingDays: offer.poolingDays || 0,
	}
	return optimumParam;
}