import React from 'react';
import { Box, CssGridBox } from 'mui';
import { proposal } from 'services';
import { HitFieldCard } from './hit-field-card';
import { useInViewport } from 'hooks';
import { HitFieldAdd } from './hit-field-add';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly loan:proposal.HitLoan;
    readonly optionalFields:Array<keyof proposal.HitLoan>;
    readonly cols:number;
    readonly toggleChecked:(id:string, flag:boolean) => void;
    readonly customizing:boolean;
    readonly refreshingCashWindow:boolean;
    readonly moveField:(from:string, to:string) => void;
    readonly onAdd:(fieldId:keyof proposal.HitLoan) => void;
    readonly onRemove:(fieldId:keyof proposal.HitLoan) => void;
    
}
export const HitCard = ({ cols, hitInfo, loan, optionalFields, toggleChecked, customizing, refreshingCashWindow, moveField, onAdd, onRemove }:Props) => {

    const fields = [...proposal.REQUIRED_FIELDS, ...optionalFields];
    // const rows = Math.ceil(fields.length / (cols-1));
    // readonly onAdd:(newField:keyof HitLoan) => void;

    const { ref, inView } = useInViewport();

    return (
        <Box mb="1" ref={ref} style={{ minHeight: '120px' }}>
            { inView && (
                <CssGridBox gap="0" rowGap="0" cols={cols} sameSize bg="card" roundedEdges="all" py="half" px="half">
                    { proposal.REQUIRED_FIELDS.map(fieldId => {
                        return (
                            <HitFieldCard
                                key={fieldId}
                                loan={loan}
                                fieldId={fieldId}
                                hitInfo={hitInfo}
                                toggleChecked={toggleChecked}
                                customizing={customizing}
                                refreshingCashWindow={refreshingCashWindow}
                            />
                        )
                    }) }
                    { optionalFields.map(fieldId => {
                        return (
                            <HitFieldCard
                                key={fieldId}
                                loan={loan}
                                fieldId={fieldId}
                                hitInfo={hitInfo}
                                toggleChecked={toggleChecked}
                                customizing={customizing}
                                moveField={moveField}
                                onRemove={onRemove}
                                optional
                            />
                        )
                    }) }
                    { customizing && (
                        <HitFieldAdd optionalFields={optionalFields} onAdd={onAdd} />
                    ) }
                </CssGridBox>
            ) }
        </Box>
    )
}
