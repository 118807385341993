import { FlatJSONSchema } from 'market-dto';
import { ExpContextField, ExpFieldWithValue } from './exp-types';

export const toFieldsFromSchema = (schema:FlatJSONSchema):ExpContextField[] => {
    if (!schema.properties) return [];
    return Object.entries(schema.properties).map(([key, value]):ExpContextField => {
        return {
            id: key,
            label: value.title || key,
            schema: value
        }
    })
}

