import React, { useState, useEffect } from 'react';
import { Flex, FlexVCenter, Button, modal, ModalFooter, Box, LabelValueGrid } from 'mui';
import { GQL, labels } from 'market-dto';
import { format, proposal } from 'services';

interface PairProps {
    readonly label:string;
    readonly value:React.ReactNode;
}
const Pair = ({ label, value }:PairProps) => {
    return <>
        <Box fontSize="sm" fg="faded" mr="half" textAlign="right">{ label }</Box>
        <Box fontSize="sm">{ value }</Box>
    </>
}

interface Props {
    readonly crEnv:string;
    readonly params:GQL.CreateTradeParams;
    readonly counts:proposal.SummaryGroup[];
    readonly hitLoanCount:number;
    readonly back:() => void;
    readonly creatingTrade:boolean;
    readonly createTrade:() =>void;
}
export const ConfirmSummary = ({ params, back, counts, hitLoanCount, crEnv, creatingTrade, createTrade }:Props) => {


    return (
        <>
            <Flex mt="2" justifyContent="space-around">
                <Box>
                    <LabelValueGrid>
                        <Pair label={labels.byTradeParam('sheetId')} value={params.sheetId} />
                        <Pair label={labels.byTradeParam('name')} value={params.name} />
                        <Pair label={labels.byTradeParam('servicer')} value={params.servicer} />
                        <Pair label={labels.byTradeParam('commitmentType')} value={labels.byCommitmentType(params.commitmentType)} />
                        <Pair label={labels.byTradeParam('servicing')} value={labels.byServicingType(params.servicing)} />
                        <Pair label={labels.byTradeParam('dayCountConvention')} value={labels.byDayCountConvention(params.dayCountConvention)} />
                        <Pair label={labels.byTradeParam('tradeDeliveryDate')} value={format.toDate(params.tradeDeliveryDate)} />
                        <Pair label={labels.byTradeParam('settlementDate')} value={format.toDate(params.settlementDate)} />
                    </LabelValueGrid>
                </Box>
                <Box>
                    <LabelValueGrid>
                        <Pair label="Hits" value={hitLoanCount} />
                        { counts.map((count, n) => (
                            <React.Fragment key={n}>
                                <Pair label={count.label + ' Total'} value={format.toCurrencyWithoutCents(count.sum)} />
                                <Pair label={count.label + ' Loans'} value={count.loanCount} />
                            </React.Fragment>
                        )) }
                        {/* <Pair label={counts[0].label + ' Total'} value={format.toCurrencyWithoutCents(counts[0].sum)} />
                        <Pair label={counts[0].label + ' Loans'} value={counts[0].loanCount} />
                        <Pair label={counts[1].label + ' Total'} value={format.toCurrencyWithoutCents(counts[1].sum)} />
                        <Pair label={counts[1].label + ' Loans'} value={counts[1].loanCount} /> */}
                        {/* <Box style={{ gridColumn:'span 2'}} borderSides="bottom" borderWidth="thin" borderColor="alert"></Box> */}
                    </LabelValueGrid>
                </Box>
            </Flex>
            <ModalFooter>
                <FlexVCenter flexDirection="column" justifyContent="center" mb="3">
                    <Button
                        busy={creatingTrade}
                        type="default"
                        size="lg"
                        onClick={createTrade}
                    >Confirm &amp; Create</Button>
                    <Box mt="2" >
                        <Button
                            type={creatingTrade ? 'disabled' : 'link' }
                            size="sm"
                            onClick={back}
                        >Back</Button>
                    </Box>
                </FlexVCenter>
            </ModalFooter>
       </>
    )
}

/*

    overall:
        trade name crtrade.name
        trade id    crtrade.tradeid

    // table:
    // loanId / loanNumber / Cleanroom ID / created checkbox

    {
        "created": true,
        "sheetId": "20200828-0016",
        "proposalId": "ckeekmz4h001c2rowhgii4r3y",
        "cleanRoomTrade": {
            "name": "unique2",
            "sellerId": 123,
            "tradeId": 439
        },
        "loans": [
            {
                "loanId": "20200828-0016-001",
                "loanNumber": "aqbbbuozznwl",
                "created": true,
                "cleanRoomLoan": {
                    "loanFileID": 26499    
                }
            },
            {
                "loanId": "20200828-0016-002",
                "loanNumber": "vfiufabyblxu",
                "created": true,
                "cleanRoomLoan": {
                    "loanFileID": 26500
                }
            }
        ]
    }





    {
        "data": {
            "cleanroom_create_trade_sync": {
                "created": false,
                "errorMessage": "Request failed with status code 400",
                "errorHint": [
                    "Encountered error during Bulk Trade Creation.",
                    "** Common reason is the trade name is not unique.",
                    "** tracking:",
                    "commitmentType: BULK",
                    "dayCountConvention: 2",
                    "fieldset: IndexDocumentsOnly",
                    "loanCount: 2",
                    "sellerId: 123dfg",
                    "start: 2020-08-28T16:28:03.394Z",
                    "sheetId: 20200828-0005",
                    "name: unique1",
                    "tradeDeliveryDate: 2020-09-04T16:27:14.784Z",
                    "servicer: asdf",
                    "servicing: RELEASED",
                    "settlementDate: 2020-09-17T16:27:14.784Z",
                    "commitmentNumber: tbd678",
                    "tradeNumber: \"202008280005\"",
                    ""
                ],
                "sheetId": "20200828-0005",
                "proposalId": "ckeeg10z4009p2toh3ev18pza"
            }
        }
    }
    {
        "data": {
            "cleanroom_create_trade_sync": {
                "created": true,
                "sheetId": "20200828-0005",
                "proposalId": "ckeeg10z4009p2toh3ev18pza",
                "cleanRoomTrade": {
                    "name": "unique1",
                    "sellerId": 123,
                    "tradeId": 434
                },
                "loans": [
                    {
                        "loanId": "20200828-0005-001",
                        "loanNumber": "mtwlbxrldgdg",
                        "created": true
                    },
                    {
                        "loanId": "20200828-0005-002",
                        "loanNumber": "xlfbgiakzwfc",
                        "created": true
                    }
                ]
            }
        }
    }
*/