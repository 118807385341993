import { mutate, gql, GQL, stripInternals } from "../../client";
import { RETURNING, toTemplateAndConfidence } from './returning';
import { TemplateAndConfidence } from 'services/tape-importer';

const UDPATE = gql`
    mutation updateTemplate($set: template_set_input, $where: template_bool_exp!) {
        update_template(set: $set, where: $where) {
            errorMessage
            affectedRow
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Template>;
    readonly where:GQL.WhereString;
}

export const updateTemplate = async (templateId:string, template:Partial<GQL.Template>):Promise<GQL.Template|undefined> => {
    // We don't care about the output...for now
	const result = await mutate<MutateInput, any>(UDPATE, {
        set: stripInternals(template),
        where: {
            id: {
                _eq: templateId
            }
        }
    })
    return result?.data?.update_template.returning[0];
    // const updatedTemplate = result?.data?.update_template.returning[0];
    // if (!updatedTemplate) return;
    // return toTemplateAndConfidence("highest", updatedTemplate);
}
