import { gql, GQL, query } from "../../../client";
import { RETURNING } from './returning';
import { ImportModel, ensureTaskXlsExportCfgIsCurrent } from 'market-dto';

interface QueryInput {
	readonly where:GQL.WhereString;
}

interface QueryResult {
	readonly xlsExport:[GQL.TaskXlsExport];
}

const QUERY = gql`
    query fetchTaskXlsExport($where: where_xlsExport_exp) {
        xlsExport(where:$where) { ${RETURNING} }
    }
`;

export const fetchTaskXlsExport = async (model:ImportModel, taskId:string):Promise<GQL.TaskXlsExport|undefined> => {
	const result = await query<QueryInput, QueryResult>(QUERY, {
		where: {
			id: {
				_eq: taskId
			}
		}
	})
	return ensureTaskXlsExportCfgIsCurrent(model, result.data.xlsExport[0]);
}