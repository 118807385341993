import { GQL, RowsAndHeaders, util, ImportModel } from 'market-dto';
import { createLoans } from './create-loans';
import { HashMatchConfidence, UnassignedMatchResult } from '../types';
import { templateSanityCheck } from './template-sanity-check';
import { nonAggTemplate } from './non-agg-template';
import { noSellersInAggTape } from './no-sellers-in-agg-tape';
import { multipleSellersInAggTape } from './multiple-sellers-in-agg-tape';
import { oneSellerNameInAggTape } from './one-seller-name-in-agg-tape';

interface ExamineMatchProps {
    readonly model:ImportModel;
    readonly tapeId:string;
    readonly rowsAndHeaders:RowsAndHeaders;
    readonly template:GQL.Template;
    readonly hashConfidence:HashMatchConfidence;
    readonly sellers:GQL.Org[];
}

export const examineMatch = ({
    model,
    tapeId,
    rowsAndHeaders,
    template,
    hashConfidence,
    sellers
}:ExamineMatchProps):UnassignedMatchResult => {

    try {
        // We KNOW that non-agg templates have one-and-only one orgId after this check.
        const problemState = templateSanityCheck(template, sellers);
        if (problemState) {
            return {
                type: "invalid",
                matchState: problemState,
                //canBeUsed: false,
                template,
                templateOverrideHash: 'invalid',
                hashConfidence
            }
        }

        const generated = createLoans(model, tapeId, rowsAndHeaders, sellers, template.mapping); // used to need hashes.

        if (!template.aggregatorName) {
            // No seller checks--this is not an aggregator template.
            return nonAggTemplate(generated, template, hashConfidence, sellers);
        }

        const distinctSellerNames = util.toDistinct(generated.loans.filter(x => x.sellerName).map(x => x.sellerName));
        if (distinctSellerNames.length === 0) {
            return noSellersInAggTape(generated, template, hashConfidence);
        } else if (distinctSellerNames.length > 1) {
            return multipleSellersInAggTape(template, hashConfidence);
        } else {
            return oneSellerNameInAggTape(generated, template, hashConfidence, sellers, distinctSellerNames[0]);
        }
    } catch (err) {
        console.log('this is a problem', err);
        throw new Error('har!');
    }
    
}