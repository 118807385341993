import { query, gql } from "../../client";

type Params = {[k:string]:string|number|boolean};

interface QueryResult {
    readonly marketSetting:[{
        readonly params:Params;
    }]
}

const QUERY = gql`
    query {
        marketSetting {
            params
        }
    }
`;

export const fetchMarketSettings = async ():Promise<Params|undefined> => {
    const result = await query<any, QueryResult>(QUERY, {});
    // const client = getApolloClient(false);
    // const result = await client.query<QueryResult>({ query: QUERY });
    if (result.data.marketSetting && result.data.marketSetting.length > 0) return result.data.marketSetting[0].params;
}
