import { GQL, labels, LoanField, sheetMaker } from 'market-dto';
import { ensureLoanSources } from '../sheets';
import { toLoansAndValuesAndSources, getFields } from '../view-loans';
import { downloadFromArrayBuffer } from '../downloader';
import { isValidFannieOffer } from '../fannie';
import { isValidFreddieOffer } from '../freddie';

interface OfferColCfg {
    readonly label:string;
    readonly property?:string;
    readonly type?:string;
}
const getOfferColVal = (cfg:OfferColCfg, offer?:GQL.Offer):any => {
    if (!offer) return null;
    if (cfg.property) return (offer as any)[cfg.property];
    if (cfg.type && offer.priceTag?.tags) {
        const tag = offer.priceTag.tags.find(x => x.type === cfg.type);
        if (tag) return tag.price;
    }
    return null;
}

const addUsed = (used:ColsUsedPerField, fieldId:LoanField, colName:string):void => {
    if (used[fieldId]) {
        if (used[fieldId]!.includes(colName)) return;
        used[fieldId]!.push(colName);
    } else {
        used[fieldId] = [colName];
    }
}

const fromUsed = (fieldId:LoanField, used:ColsUsedPerField):string[] => {
    // We return an array because we spread it. really, we just want string or no string,
    // but, we're populating a header array over here!
    if (!used[fieldId]) return [];
    return ['from: ' + used[fieldId]!.join(', ')];
}

type ColsUsedPerField = { [k in LoanField]?:string[] }


export const genLoansAndOffersSheet = async (_sheet:GQL.LoanModelSheet) => {
 
    const sheet = await ensureLoanSources(_sheet);
    const raw = toLoansAndValuesAndSources(sheet.loans);
    const used:ColsUsedPerField = {}

    const fields = getFields();
 
    // find out which source columns were used for all fields (direct and derived)
    raw.forEach(loanAndVs => {
        fields.forEach(field => {
            const mapInfo = loanAndVs.mapInfoByField[field.id];
            if (mapInfo && mapInfo.direct) {
                // const srcVal = mapInfo.direct.source[0].val;
                const srcCol = mapInfo.direct.cols[0].name;
                // const srcCol = mapInfo.direct.source[0].colName;
                addUsed(used, field.id, srcCol ?? 'unknown');
            }
        })
    })
 
    raw.forEach(loanAndVs => {
        fields.forEach(field => {            
            const mapInfo = loanAndVs.mapInfoByField[field.id];
            // Only count derived IF we ended up with a non-null, non-undefined value.
            const val = loanAndVs.loan[field.id];
            if (val === null || val === undefined) return;
            if (mapInfo && mapInfo.derive) {
                // This is not setup for multiple propNames, which CAN happen now.

                // const propNames = mapInfo.derived.source[mapInfo.derived.source.length-1].propName;
                // const propName = propNames && propNames.length > 0 ? propNames[0] : '';

                const last = mapInfo.derive[mapInfo.derive.length-1];
                const propName = last.deriveType === 'simple' && last.srcId;
                
                if (used[propName as LoanField] && used[propName as LoanField]!.length === 1) {
                    // this derived field comes from this direct field
                    addUsed(used, field.id, used[propName as LoanField]![0]);
                }
            }
        })
    })
    // now we know which columns were used, which means, we can generate HEADERS.

    const headers = fields.map(f => {
        return [
            labels.byLoanField(f.id),
            ...fromUsed(f.id, used)
        ]
    }).flat();
 
    const rows = raw.map(loanAndVs => {
        const cols:any[] = [];
        // Not map/filter because could be null, undefined...so push when we know.
        fields.forEach(field => {   

            // 2019-12-01T00:00:00.000Z
            // if (field.id === 'firstPaymentDueDate') console.log(loanAndVs.loan[field.id]);

            // DO NOT FORMAT FOR THE OUTPUT SHEET--give them underlying codes!
            cols.push(loanAndVs.loan[field.id]);
            // cols.push(format.loanValue(field.id, loanAndVs.loan[field.id]));

            if (!used[field.id]) return;
            const mapInfo = loanAndVs.mapInfoByField[field.id];
            if (!mapInfo) throw new Error('how can you NOT have mapInfo but you DO have used info?');            
            if (mapInfo.derive) {
                // cols.push(mapInfo.derived.source[mapInfo.derived.source.length-1].val);
                // const derivedVals = mapInfo.derived.source[mapInfo.derived.source.length-1].val;
                // cols.push(derivedVals && derivedVals.length > 0 ? derivedVals[0] : undefined);
                const last = mapInfo.derive[mapInfo.derive.length-1];
                cols.push(last.val?.trans);
            } else if (mapInfo.direct) {
                cols.push(mapInfo.direct.val?.trans);
                // const directVals = mapInfo.direct.source[0].val;
                // cols.push(directVals && directVals.length > 0 ? directVals[0] : undefined);
            }
        })
        if (cols.length !== headers.length) throw new Error('how cols length differ from headers?');
        return cols;
    })

    const { headers:mergedHeaders, rows:mergedRows } = insertOffersIntoHeadersAndRows(sheet, headers, rows, 1);
    const arrBuf = await sheetMaker.makeSheet([ mergedHeaders, ...mergedRows ], "Loans");
    const filename = sheet.id + '.xlsx';
    downloadFromArrayBuffer(arrBuf, filename);
    return true;
}
 
 
interface OfferColsByInvestorId {
    readonly investor:string;
    readonly headers:string[];
}

 
const insertOffersIntoHeadersAndRows = (sheet:GQL.LoanModelSheet, headers:string[], rows:any[][], n:number) => {
    // OK, we're about to make an output spreadsheet...
    // ...but...we want to include ALL the offer data that we have!
    // Where do we put it? right after n!

    // But first, let's make our own headers/rows!


    // find the FIRST offer per investor id
    const offerColsByInvestor:{ [k:string]:string[] } = {};
    // NEW TECHNIQUE -- always use our SAMPLE offer jsons so that the columns are the same, every time.
    // const fannieOffer = sampleFannie as any as GQL.Offer;
    // const freddieOffer = sampleFreddie as any as GQL.Offer;
    // const fannieOfferCols:string[] = [];
    // fannieOffer.priceTag?.tags.forEach(tag => {                    
    //     // Only special logic: if begins with TBA
    //     fannieOfferCols.push(tag.name.trim().toUpperCase().startsWith('TBA') ? 'TBA' : tag.name);
    // })
    // offerColsByInvestor['Fannie'] = fannieOfferCols;
    // console.log('fannie', JSON.stringify(offerColsByInvestor['Fannie']));
    // const freddieOfferCols:string[] = [];
    // freddieOffer.priceTag?.tags.forEach(tag => {                    
    //     // Only special logic: if begins with TBA
    //     freddieOfferCols.push(tag.name.trim().toUpperCase().startsWith('TBA') ? 'TBA' : tag.name);
    // })
    // offerColsByInvestor['Freddie'] = freddieOfferCols;
    // console.log('freddie', JSON.stringify(offerColsByInvestor['Freddie']));

    const baseCols:OfferColCfg[] = [
        {
            label: "Investor",
            property: "investor"
        },
        {
            label: "Offer",
            property: "offeringPrice"
        },
        {
            label: "Commitment Days",
            property: "commitmentDays"
        },
        {
            label: "Acquisition Date",
            property: "acquisitionDate"
        },
        {
            label: "Security Date",
            property: "securityDate"
        }
    ]
    const fannieColCfgs:OfferColCfg[] = [
        ...baseCols,
        {
            label: "TBA",
            type: "CASH_WINDOW"
        },
        {
            label: "Interest Spread",
            type: "INTEREST_SPREAD"
        },
        {
            label: "LLPA",
            type: "LLPA"
        },
        {
            label: "Seller Margin",
            type: "SELLER_MARGINS"
        },
        {
            label: "SRP", // OLD would have shown "SRP CMC - 246170070"
            type: "SRP"
        }
    ]
    const freddieColCfgs = [
        ...baseCols,
        {
            label: "Cash-All-In Pricing",
            type: "CASH_WINDOW"
        },
        {
            label: "Payup",
            type: "PAYUP"
        },
        {
            label: "Interest Spread",
            type: "INTEREST_SPREAD"
        },
        {
            label: "Seller Margin",
            type: "SELLER_MARGINS"
        },
        {
            label: "SRP", // OLD would have shown "SRP - Internal"
            type: "SRP"
        }
    ]

    const offerRows:any[][] = sheet.loans.map((loan, loanIndex) => {

        const fannieOffer = loan.offers?.find(offer => isValidFannieOffer(offer));
        const freddieOffer = loan.offers?.find(offer => isValidFreddieOffer(offer));

        return [
            ...fannieColCfgs.map(colCfg => getOfferColVal(colCfg, fannieOffer)),
            ...freddieColCfgs.map(colCfg => getOfferColVal(colCfg, freddieOffer))
        ]
    })

    const combinedOfferHeaders = [
        ...fannieColCfgs.map(colCfg => colCfg.label),
        ...freddieColCfgs.map(colCfg => colCfg.label),
    ]

    return {
        headers: [
            ...headers.slice(0, n),
            ...combinedOfferHeaders,
            ...headers.slice(n)
        ],
        rows: rows.map((cols, rowIndex) => {
            const offerCols = offerRows[rowIndex];
            return [...cols.slice(0, n), ...offerCols, ...cols.slice(n)]
        })
    }

/*
    fannie columns, static list: ["TBA","Interest Spread","LLPA","Seller Margin","SRP CMC - 246170070"]
    freddie columns, static list:  ["Cash-All-In Pricing","Payup","Interest Spread","Seller Margin","SRP - Internal"]


    is this safe?
    look for price tag where type == "CASH_WINDOW" instead of looking for "name starts with 'TBA'"


    This means, for every loan, i will check for fannie/freddie offer. if one exists, i'll look for those by name.
*/


    // sheet.loans.forEach(loan => {
    //     loan.offers?.forEach(offer => {
    //         if (offer.investor && !offerColsByInvestor[offer.investor]) {
    //             const offerCols:string[] = [];
    //             offer.priceTag?.tags.forEach(tag => {                    
    //                 // Only special logic: if begins with TBA
    //                 offerCols.push(tag.name.trim().toUpperCase().startsWith('TBA') ? 'TBA' : tag.name);
    //             })
    //             offerColsByInvestor[offer.investor] = offerCols;
    //         }
    //     })
    // })
 
    // const baseOfferCols = [
    //     'Investor',
    //     'Offer',
    //     'Commitment Days',
    //     'Acquisition Date',
    //     'Security Date'
    // ]
    // const invOfferCols:OfferColsByInvestorId[] = Object.keys(offerColsByInvestor)
    //     .map((investor:string):OfferColsByInvestorId => {
    //         return {
    //             investor,
    //             headers: offerColsByInvestor[investor]
    //         }
    //     })

    // let offerHeaders:string[] = [];
    // // MUTABLE. Gross.
    // for (let i=0; i < invOfferCols.length; i++) {
    //     if (i > 0) {
    //         offerHeaders = offerHeaders.concat(baseOfferCols.map(x => x + ' ' + (i+1)));
    //     } else {
    //         offerHeaders = offerHeaders.concat(baseOfferCols);
    //     }
    //     offerHeaders = offerHeaders.concat(invOfferCols[i].headers);
    // }
     
    // const offerRows:any[][] = sheet.loans.map((loan, loanIndex) => {
    //     const arr:any[] = [];
    //     for (let i=0; i < invOfferCols.length; i++) {
    //         // now use the new things...
    //         const investor = invOfferCols[i].investor;
    //         const offer = loan.offers?.find(x => x.investor === investor);
    //         if (!offer) {
    //             for (let j=0; j < baseOfferCols.length; j++) arr.push(null);
    //             invOfferCols[i].headers.forEach(x => arr.push(null));
    //         } else {
    //             if (offer.priceTag?.tags.length !== invOfferCols[i].headers.length) {
    //                 throw new Error('Offers with matching investorIds should have matching priceTag.tags lengths');
    //             }
    //             arr.push(offer.investor);
    //             arr.push(offer.offeringPrice);
    //             arr.push(offer.commitmentDays);
    //             arr.push(offer.acquisitionDate);
    //             arr.push(offer.securityDate);
    //             offer.priceTag.tags.forEach(x => arr.push(x.price));
    //         }
    //     }
    //     if (arr.length !== offerHeaders.length) {
    //         throw new Error('Mismatched offer data columns / offer header columns!');
    //     }
    //     return arr;
    // })
 

    // return {
    //     headers: [...headers.slice(0, n), ...offerHeaders, ...headers.slice(n)],
    //     rows: rows.map((cols, rowIndex) => {
    //         const offerCols = offerRows[rowIndex];
    //         return [...cols.slice(0, n), ...offerCols, ...cols.slice(n)]
    //     })
    // }
 
 }

