import React from 'react';
import { downloader, rule } from 'services';
import { Button, FlexVCenter, Box, modal, Icon } from 'mui';
import { FileDropArea, InvalidJson } from 'components';
import { useZStateList} from 'hooks';

interface Props {
    readonly setTab:(x:string) => void;
}
export const ImportExport = ({ setTab }:Props) => {

    const {
        editItem:checklist,
        updateItem,
    } = useZStateList(rule.zChecklists);

    const download = () => {
        downloader.downloadJsonFile(JSON.stringify(checklist, null, 4), 'checklist.json');
    }

    const uploadFile = async (files:File[]) => {
        if (files.length !== 1) return;
        const file = files[0];
        const json = await file.text();
        let obj:any;
        try {
            obj = JSON.parse(json);
        } catch (err) {
            modal.confirm({
                title: 'Invalid JSON',
                hideCancel: true,
                ok: () => {}
            })
        }
        const invalidReasons = rule.isValidChecklist(obj);
        if (invalidReasons.length === 0) {
            // do we what we gotta do
            // The json is VALID CHECKLIST JSON!
            // But, does the id match current checklist?
            if (obj.id !== checklist?.id) {
                modal.confirm({
                    title: 'Checklist id does not match!',
                    hideCancel: true,
                    ok: () => {}
                })
            } else {
                // Not sure if we should renumber, but why not.
                updateItem(rule.renumberChecklistItems(obj));
                modal.confirm({
                    title: <>
                        Checklist updated
                        <Box mt="1" fg="default" fontSize="sm">
                            Note: your changes have not yet been saved. You'll wanna review your changes and then commit them.
                        </Box>
                    </>,
                    hideCancel: true,
                    ok: () => setTab('items')
                })
            }
        } else {
            modal.confirm({
                title: <InvalidJson reasons={invalidReasons} />,
                hideCancel: true,
                ok: () => {}
            })
        }
    }

    return (
        <FlexVCenter width="card-lg">
            <Box flex="1" pr="3" borderSides="right" borderColor="alert" borderWidth="thin" borderStyle="dashed">
                <FileDropArea
                    multi={false}
                    label="Drop JSON here."
                    accept=".json"
                    onDrop={uploadFile}
                />
            </Box>
            <Box flex="1" pl="3">
                <Button
                    type="secondary"
                    onClick={download}
                >Download JSON</Button>                
            </Box>
        </FlexVCenter>
    )
}