import * as api from 'api';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, tap, shareReplay } from 'rxjs/operators';
import { addJob, actLog, updateLatestPollResult, updateJobParam, genJobId } from '../activity-monitor';
import { reloadIfCurrent } from '../sheets';
import { getFreddieJobName } from './get-freddie-job-name';
import { checkQueuedPricingJobDone, checkReplayPricingJobDone } from '../pricing';


const POLLING_TIMEOUT_SECONDS = 60 * 10;

const apiRunFreddiePricing = api.toastOnErr(api.runFreddiePricing, "Unable to run Freddie pricing at this time");
const apiRunFreddiePricing2 = api.toastOnErr(api.queueFreddiePricing, "Unable to run Freddie pricing at this time");

// export const runFreddiePricingDeferred = (sheetId:string, costOfFunds:number, sellerMargin:number) => {
//     // We no longer use deferred jobs. Now server has queues.
//     const jobId = getFreddieJobId(sheetId);
//     addDeferredJob({
//         jobId,
//         onWait: () => actLog("PRICING", "QUEUED", sheetId, 'Freddie pricing'),
//         kickOff: () => {
//             addJob({
//                 jobType: "freddie-pricing",
//                 label: sheetId,
//                 jobId,
//                 checkStatus: createJobObservable(jobId, sheetId, costOfFunds, sellerMargin),
//                 timeoutSeconds: POLLING_TIMEOUT_SECONDS,
//                 onComplete: () => reloadIfCurrent(sheetId)
//             })
//         }
//     })
// }

// export const pretendFreddie = () => {
//     const sheetId = "fake";
//     const jobId = getFreddieJobId(sheetId);
//     addJob({
//         jobType: "freddie-pricing",
//         label: "pretend",
//         jobId,
//         checkStatus: checkReplayPricingJobDone(jobId, sheetId),
//         timeoutSeconds: POLLING_TIMEOUT_SECONDS,
//         onComplete: () => reloadIfCurrent(sheetId)
//     })
// }

export const enqueueFreddiePricing = async (sheetId:string, costOfFunds:number, sellerMargin:number) => {
    // If you don't wanna wait for interval to grab the invocationId, call this.
    const jobName = getFreddieJobName(sheetId);
    const jobId = genJobId("freddie-" + sheetId);
    const batchId = await apiRunFreddiePricing2(sheetId, costOfFunds, sellerMargin);
    actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Freddie pricing ' + String(batchId));
    addJob({
        label: sheetId,
        serverBatchId: batchId,
        jobId,
        jobName,
        checkStatus: checkQueuedPricingJobDone('freddie', jobId, sheetId, batchId),
        timeoutSeconds: POLLING_TIMEOUT_SECONDS,
        onComplete: () => reloadIfCurrent(sheetId)
    })
}

// export const runFreddiePricing = async (sheetId:string, costOfFunds:number, sellerMargin:number) => {    
//     const jobId = getFreddieJobId(sheetId);
//     const invocationId = await apiRunFreddiePricing(sheetId, costOfFunds, sellerMargin);
//     actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Freddie pricing ' + String(invocationId));
//     addJob({
//         jobType: "freddie-pricing",
//         label: sheetId,
//         jobId,
//         checkStatus: checkIfDone(jobId, sheetId, invocationId),
//         timeoutSeconds: POLLING_TIMEOUT_SECONDS,
//         onComplete: () => reloadIfCurrent(sheetId)
//     })
// }

// const createJobObservable = (jobId:string, sheetId:string, costOfFunds:number, sellerMargin:number):Observable<JobStatus> => {
//     return requestInvocationId(sheetId, costOfFunds, sellerMargin).pipe(
//         switchMap(invocationId => checkIfDone(jobId, sheetId, invocationId))
//     )
// }

const requestInvocationId = (sheetId:string, costOfFunds:number, sellerMargin:number):Observable<string|undefined> => {
    return from(apiRunFreddiePricing(sheetId, costOfFunds, sellerMargin)).pipe(
        tap(invocationId => {
            actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Freddie pricing ' + String(invocationId));
            console.log('You will only see this once. Here is the invocation id:', invocationId);
        }),
        shareReplay(1) // <-- read below.
        // This shareReplay(1) ensures that once we have recieved the invocationId,
        // we do NOT re-request the invocationId (or we'd be generating endless invocation ids!)
        // From here on out, it's as if the stream begins, on subsequent polling intervals, AFTER the shareReplay
    )  
}

// const checkIfDone = (jobId:string, sheetId:string, invocationId?:string):Observable<JobStatus> => {
//     return of(invocationId).pipe(
//         switchMap(invocationId => {
//             if (!invocationId) {
//                 actLog("PRICING", "ERROR", sheetId, 'Freddie pricing failed to get invocation id');
//                 return of("FAILURE" as JobStatus);
//             }
//             updateJobParam(jobId, invocationId);
//             return from(api.fetchAgencyPricingStatus(invocationId)).pipe(
//                 utils.isNotNullOrUndefined(),
//                 tap((statusAndLogs:api.PricingStatusAndLogs) => {
//                     // console.log('HERE WE ARE (status and logs)', statusAndLogs)
//                     updateLatestPollResult(jobId, statusAndLogs);
//                 }),
//                 map((statusAndLogs:api.PricingStatusAndLogs):JobStatus => {
//                     switch (statusAndLogs.execution.status) {
//                         // This may seem strange, but, what server tells us simply happens to look a lot like
//                         // activity monitor's job statuses.
//                         case 'DONE': {
//                             actLog("PRICING", "ACTIVITY_ENDED", sheetId, 'Freddie pricing ' + invocationId);
//                             return 'SUCCESS';
//                         }
//                         case 'FAIL': {
//                             const reason = getLastLogLine(statusAndLogs);
//                             actLog("PRICING", "ERROR", sheetId, reason ? reason : 'Freddie pricing ' + invocationId);
//                             return 'FAILURE';
//                         }
//                         case 'QUEUE': {
//                             return 'BUSY';
//                         }
//                         case 'START': {
//                             return 'BUSY';
//                         }
//                     }
//                     actLog("PRICING", "ERROR", sheetId, 'Unhandled Freddie polling result');
//                     return 'FAILURE';
//                 })   
//             )
//         })
//     )
// }

// const getLastLogLine = (statusAndLogs:api.PricingStatusAndLogs):string => {
//     if (statusAndLogs.logs.length > 0) {
//         if (statusAndLogs.logs[0].lines && statusAndLogs.logs[0].lines.length > 0) {
//             return statusAndLogs.logs[0].lines[0];
//         }
//     }
//     return '';
// }

// const getAmIDoneObservable = (jobId:string, sheetId:string, invocationId:string):Observable<JobStatus> => {
//     // How do we know when we're done with freddie pricing? This tells us. It'll get called repeatedly.
//     return of(invocationId).pipe(
//         switchMap(x => api.fetchAgencyPricingStatus(x)),
//         utils.isNotNullOrUndefined(),
//         tap((statusAndLogs:api.PricingStatusAndLogs) => {
//             // console.log('HERE WE ARE (status and logs)', statusAndLogs)
//             updateLatestPollResult(jobId, statusAndLogs);
//         }),
//         map((statusAndLogs:api.PricingStatusAndLogs):JobStatus => {
//             switch (statusAndLogs.execution.status) {
//                 // This may seem strange, but, what server tells us simply happens to look a lot like
//                 // activity monitor's job statuses.
//                 case 'DONE': {
//                     actLog("PRICING", "ACTIVITY_ENDED", sheetId, 'Freddie pricing');
//                     return 'SUCCESS';
//                 }
//                 case 'FAIL': {
//                     const reason = getLastLogLine(statusAndLogs);
//                     actLog("PRICING", "ERROR", sheetId, reason ? reason : 'Freddie pricing');
//                     return 'FAILURE';
//                 }
//                 case 'QUEUE': {
//                     return 'BUSY';
//                 }
//                 case 'START': {
//                     return 'BUSY';
//                 }
//             }
//             actLog("PRICING", "ERROR", sheetId, 'Unhandled Freddie polling result');
//             return 'FAILURE';
//         })
//     )
// }