import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ChecklistEditor } from './checklist-editor/checklist-editor'

export const EditChecklist = () => {
    const navigate = useNavigate();
	const { id } = useParams();
    if (!id) throw 'how can there not be an id?';
    const close = () => {
        // TODO: make this relative to current path in case we move it.
        navigate('/rules/checklists');
    }
    return <ChecklistEditor id={id} onClose={close} />
}

