import React from 'react';
import { FlexVCenter, BoxProps, BorderSides, BorderColor } from 'mui';
import { tapeImporter, utils, urls } from 'services';

const getBorderSides = (n:number, expanded?:boolean, isLeft?:boolean, isRight?:boolean):BorderSides|undefined => {
    if (n === 0) {
        if (isLeft) return "top-bottom-and-left";
        if (isRight) return "top-bottom-and-right";
        return "top-and-bottom";
    }
    if (isLeft) return "bottom-and-left";
    if (isRight) return "bottom-and-right";
    return "bottom";

    // if (expanded) {
    //     if (isLeft) return "top-and-left";
    //     if (isRight) return "top-and-right";
    // }
    // return "top";
}

const getBorderColor = (n:number, expanded?:boolean):BorderColor => {
    return "alert";
    // if (expanded || n === 0) return "alert";
    // return "transparent";
}

interface Props extends BoxProps {
    readonly isLeft?:boolean;
    readonly isRight?:boolean;
    readonly n:number;
    readonly item:tapeImporter.BatchItem;
    readonly colSpan?:number;
}
export const ColContainer = ({ item, isLeft, isRight, n, colSpan, ...props }:Props) => {
    const { expanded } = item;
    const colSpanStyles = colSpan ? { gridColumn: 'span ' + colSpan } : {};
    return (
        <FlexVCenter
            p="1"
            style={colSpanStyles}
            borderSides={getBorderSides(n, expanded, isLeft, isRight)}
            borderColor={getBorderColor(n, expanded)}
            borderStyle="solid"
            borderWidth="thin"
            bg={expanded ? 'inner-card' : undefined}
            {...props}
        />
    )
}