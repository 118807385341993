import React from 'react';
import { DeriveMathSrcMap, ModelItem, ImportModel, SrcMap } from 'market-dto';
import { MathHeader } from './math-header';

interface Props {
    readonly side:"left"|"right";
    readonly model:ImportModel;
    readonly srcVal:any;
    // readonly field:ModelItem.AnyType;
    readonly srcField:ModelItem.AnyType;
    readonly sourceMap:SrcMap[];
}
export const MathFieldSrc = ({
    side,
    model,
    srcVal,
    srcField,
    sourceMap
}:Props) => {


    const mapped = sourceMap.find(x => x.id === srcField.fieldId);

    // no maping at all.
    if (!mapped) {
        return (
            <>
                <div>"{srcField.label}" failed to map!</div>
                {/* <div style={{ color: 'red' }}>
                    Future enhancement, a link to take you to "{srcField.label}"'s <i>How did we map it?</i>
                </div> */}
            </>
        )
    }
    // perhaps you mapped, but, you got garbage
    if (srcVal === undefined) {
        return (
            <>
                <div>"{srcField.label}" did not map to a proper value!</div>
                {/* <div style={{ color: 'red' }}>
                    Future enhancement, a link to take you to "{srcField.label}"'s <i>How did we map it?</i>
                </div> */}
            </>
        )
    }

    if (mapped.direct && mapped.direct.val?.trans !== undefined) {
        return <>
            <div>
                "{srcField.label}" was mapped from the column "{mapped.direct.cols[0].name}"
                and gave us the value {srcVal}
            </div>
        </>
    }
    
    if (!mapped.derive || mapped.derive.length === 0) {
        // this should NEVER HAPPPEN
        return <div>"{srcField.label}" somehow had the value "{srcVal}"</div>
    }

    // const last = mapped.derive[mapped.derive.length-1];

    return (
        <>
            <div>"{srcField.label}" was itself derived and gave us the value "{srcVal}"</div>
            {/* <div style={{ color: 'red' }}>
                Future enhancement, a link to take you to "{srcField.label}"'s <i>How did we map it?</i>
            </div> */}
        </>
    )
}

/*
{
    "deriveType": "math",
    "leftSrcId": "loanTermMonths",
    "op": "minus",
    "rightSrcId": "interestOnlyTerm",
    "leftVal": 360
}
*/