import React, { useState, useEffect } from 'react';
import {
    Button,
    IconButton,
    Input,
    Box,
    BoundFrmInput,
    FlexVCenter,
    modal,
    setModalCfg
} from 'mui';
import { GQL } from 'market-dto';
import { useApiUpdate } from 'hooks';
import * as api from 'api';

type EditOrg = Pick<GQL.Org, "aliases" | "name" | "orgType">;

const copy = (x:any) => JSON.parse(JSON.stringify(x)); // TODO: we have a proper deep copy?

const copyOrg = (org:GQL.Org|null):EditOrg => {
    const o:GQL.Org = copy(org); // we need it copied cuz of any non-primitives
    if (org) {
        // only those fields we can edit
        return {
            orgType: o.orgType,
            name: o.name,
            aliases: o.aliases
        }
    }
    // if null, it's a NEW ORG MODAL
    return {
        orgType: "Investor",
        name: "",
        aliases: []
    }
}


interface Props {
    readonly org:GQL.Org|null;
    readonly onSave:() => void;
}
export const InvestorModal = ({ org, onSave }:Props) => {

    const [ data, setData ] = useState<EditOrg>(() => copyOrg(org));

    // TODO: this will change to zstate

    const { busy, update } = useApiUpdate(async () => {
        if (org === null) {
            await api.insertOrg(data);
        } else {
            await api.updateOrg({ id: org.id, ...data });
        }
        onSave();
        modal.close();
    })

    const updateAlias = (val:string, n:number) => {
        setData({
            ...data,
            aliases: data.aliases.map((a, m) => n === m ? val : a )
        })
    }

    const removeAlias = (n:number) => {
        setData({
            ...data,
            aliases: data.aliases.filter((a, m) => n !== m)
        })
    }

    const addAlias = () => {
        setModalCfg({ static: true });
        setData({
            ...data,
            aliases: data.aliases.concat('')
        })
    }

    const canSave = data.name.trim() !== '' && data.aliases.length > 0;

    return (
        <Box>
            
            <BoundFrmInput
                width="100%"
                label="Investor Name"
                bindTo={[ data, setData, x => x.name ]}
            />

            <FlexVCenter ml="1" mb="1">
                <Box fg="faded">Aliases</Box>
                <Box flex="1" textAlign="right">
                    <IconButton size="sm" name="plus" onClick={addAlias} />
                </Box>
            </FlexVCenter>


            { data.aliases.map((alias, n) => (
                <FlexVCenter mb="1" key={n}>
                    <Box flex="1" pr="1">
                        <Input
                            width="100%"
                            value={alias}
                            setValue={val => updateAlias(val, n)}
                            placeholder="Alias"
                        />
                    </Box>
                    <Box textAlign="right">
                        <IconButton size="sm" name="trash" onClick={() => removeAlias(n)}/>
                    </Box>
                </FlexVCenter>
            )) }

            <Box textAlign="right" mt="3">
                <Button type="secondary" onClick={modal.close}>Cancel</Button>
                <Button
                    type={ canSave ? 'default' : 'disabled' }
                    ml="1"
                    onClick={update}
                    busy={busy}
                >Save</Button>
            </Box>

        </Box>
    )
}