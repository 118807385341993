import React from 'react';
import { FlexVCenter, Input, Box, Text } from 'mui';

interface Props {
    readonly filter:string;
    readonly setFilter:(x:string) => void;
    readonly count:number;
    readonly itemLabel:string;
    readonly placeHolder:string;
}
export const FilterForm = ({ itemLabel, count, filter, setFilter, placeHolder }:Props) => {
    // This was pulling double duty but now is solely for checklist items.
    return (
        <FlexVCenter mb="2">
            <Input
                placeholder={placeHolder}
                appendIcon="search"
                value={filter}
                setValue={setFilter}
                size="sm"
            />
            <Box fg="default" ml="1">
                { count }
                <Text fg="faded">&nbsp;{ itemLabel }{ count === 1 ? '' : 's' }</Text>
            </Box>
        </FlexVCenter>
    )
}