import { GQL, CashWindow } from "market-dto"
import { isValidFreddieOffer } from './is-valid-freddie-offer';
import { getBasePrice } from './get-base-price';
import { to2dHoldingInterestArray } from './to-2d-holding-interest-array';
import { HoldingInfo } from '../profit/types';
import { getBaseHoldingInterestItem } from './get-base-holding-interest-item';
import * as api from 'api';

export const getHoldingInfo = async (
    loan:GQL.Loan,
	offer:GQL.Offer,
	removeWeekends?:boolean
):Promise<HoldingInfo> => {
	if (!isValidFreddieOffer(offer)) throw new Error('Expected valid freddie offer!');
	
	const freddieHoldingInfo = await api.fetchFreddieHoldingInterest(loan.id);
	const arr1d = freddieHoldingInfo?.explored ?? [];
	const id = freddieHoldingInfo?.base.positionId ?? 'missing base id';

	console.log('base id, calc\'ed id', id, getBaseHoldingInterestItem(offer, arr1d)?.positionId ?? 'unknown calc id');

	const basePrice = getBasePrice(offer, arr1d);
	const { aquisitionDates, deliveryDates, arr2d } = to2dHoldingInterestArray(arr1d, removeWeekends);
    return {
		aquisitionDates,
		deliveryDates,
		holdingInterest: arr2d,
		basePrice
	}
}
