import React, { useState, useRef } from 'react';
import { shadeColor } from '../shade-tint';
import { SpectrumGridItemDetails } from './spectrum-grid';
import { usePopper } from 'react-popper';

const black = '#000000';
// const green = '#33ee44';
// const red = '#CC3333';

// const getColor = (
//     n:number | null,
//     baseline:number,
//     max:number,
//     min:number,
//     color1:string,
//     color2:string,
//     color3:string
// ):string => {
//     if (n === null) return 'transparent';
//     if (n === baseline) return color3;
//     if (n > baseline) {
//         console.log('n', n);
//         const p = n / max * 100;
//         return shadeColor(color1, p);
//     } else {
//         const p = n / min * 100;
//         return shadeColor(color2, p);
//     }
// }

interface SpectrumGridItemProps {
    readonly color?:string;
    readonly item:SpectrumGridItemDetails;
    readonly renderHover?:(item:SpectrumGridItemDetails) => React.ReactNode;
    readonly onHover?:(item:SpectrumGridItemDetails) => void;
    readonly onClick?:(item:SpectrumGridItemDetails) => void;
}
export const SpectrumGridItem = ({
    item,
    color,
    renderHover,
    onHover,
    onClick
}:SpectrumGridItemProps) => {

    const [ isHover, setIsHover ] = useState<boolean>(false);
    const [ referenceElement, setReferenceElement ] = useState<HTMLDivElement | null>(null);
    const [ popperElement, setPopperElement ] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'fixed'
    })

    const endHover = () => setIsHover(false);

    const doHover = () => {
        setIsHover(true);
        if (onHover) onHover(item);
    }
    const doOnClick = () => onClick && onClick(item);

    const cssArr = ['spectrum-grid-item'];
    if (item.isBaseline) cssArr.push('spectrum-baseline');
    if (item.isMin) cssArr.push('spectrum-min');
    if (item.isMax) cssArr.push('spectrum-max');
    if (item.isRowMin) cssArr.push('spectrum-row-min');
    if (item.isRowMax) cssArr.push('spectrum-row-max');


    // const oldBg = getColor(
    //     item.val,
    //     baseline,
    //     maxVal as number,
    //     minVal as number,
    //     green,
    //     red,
    //     black
    // )
    
    return <div
        ref={setReferenceElement}
        className={cssArr.join(' ')}
        onClick={doOnClick}
        onMouseOver={doHover}
        onMouseOut={endHover}
        style={{
            textAlign: 'center',
            position: 'relative',
            backgroundColor: color ?? black
        }}
    >
        { item.isBaseline && '0' }
        { item.isMax && '★' }
        { item.isRowMax && !item.isMax && !item.isBaseline && '$' }
        { isHover && item.val !== null && renderHover && (
            <div
                ref={setPopperElement} style={styles.popper} {...attributes.popper}
                className="spectrum-grid-hover-item"
            >{ renderHover(item) }</div>
        )}
    </div>
}
