import React from 'react';
import { Box, FlexVCenter, Input, Button, modal, IntoPortal, Text, Flex } from 'mui';
import { format } from 'services';
import { GQL} from 'market-dto';


interface CalFiltersProps {
    readonly filter:string;
    readonly onFilterChange:(x:string) => void;
    readonly onAdd:() => void;
    readonly onBulkAdd:() => void;
    readonly filterCount:number;
    readonly checkedDates:GQL.SettlementCalendar[];
    readonly onDelete:() => void;
    readonly deleting:boolean;
}
export const CalFilters = ({ filter, onFilterChange, onAdd, onBulkAdd, filterCount, checkedDates, onDelete, deleting }:CalFiltersProps) => {


    return (
        <IntoPortal id="config-route-top-area">

            <FlexVCenter justifyContent="space-between">
                <Box fg="default" fontSize="lg">
                    Settlement Calendar
                </Box>
                <FlexVCenter>
                    <Button
                        size="sm"
                        mr="1"
                        onClick={onBulkAdd}
                    >Bulk Add</Button>
                    <Button
                        size="sm"
                        onClick={onAdd}
                    >Add</Button>
                </FlexVCenter>
            </FlexVCenter>

            <Flex justifyContent="space-between" mt="1">

                <FlexVCenter>

                    <Input
                        // size="sm"
                        value={filter}
                        setValue={onFilterChange}
                        placeholder="Filter Dates"
                        appendIcon="search"
                    />
                    <Box fg="default" ml="1" mr="2">
                        { filterCount }&nbsp;
                        <Text fg="faded">{ format.plural("Date", filterCount) }</Text>
                        &nbsp;&nbsp;
                        { checkedDates.length }&nbsp;
                        <Text fg="faded">Selected</Text>
                    </Box>

                </FlexVCenter>
                <FlexVCenter>
                    { checkedDates.length > 0 && (
                        <Button
                            size="sm"
                            type="secondary"
                            icon="trash"
                            busy={deleting}
                            onClick={onDelete}
                        >Delete</Button>
                    ) }
                </FlexVCenter>

            </Flex>
        </IntoPortal>
    )
}