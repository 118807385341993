import { query, gql, GQL } from "../../client";
import { LOAN_SOURCE_RETURNING } from './loan-source-returning';

const QUERY = gql`
    query ($where: loanSource_bool_exp!) {
        loanSource(where: $where) {
            ${ LOAN_SOURCE_RETURNING }
        }
    }
`;

interface QueryResult {
    readonly loanSource:Pick<GQL.LoanSource, "loanId"|"row"|"sourceMap">[];
}

interface QueryInput {
    readonly where:GQL.WhereString;
}

export const fetchLoanSource = async (sheetId:string, loanId:string):Promise<GQL.LoanSource|undefined> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            sheetId: {
                _eq: sheetId
            },
            loanId: {
                _eq: loanId
            }
        }
    })
    const arr = result.data.loanSource;
    if (arr.length !== 1) return;
    const item = result.data.loanSource[0];
    return {
        ...item,
        sheetId,
        partialLoan: {} // not needed
    }
}

