import { labels } from 'market-dto';
import { HitLoan } from './types';

export const getHitFieldLabel = (fieldId:keyof HitLoan) => {
    // Most of these come from label service, but, some are unique to this UI.
    switch (fieldId) {
        case 'investorProductName': return 'Investor Product Name';
        case 'cashWindow': return 'Cash Window Price';
        case 'investor': return 'Investor';
        case 'servicerSymbol': return labels.byOfferField('servicerSymbol');
        case 'offeringPrice': return 'Offering Price';
        // case 'realtimeCashWindowAsOf': return 'As Of';
        // case 'realtimeCashWindowPrice': return 'Current Cash Window Price';
        case 'commitmentDays': return 'Commitment Days';
        // Everything else, use normal label service (it's a common loan field!)
        case 'cltv':
        case 'currentBalance':
        case 'dti':
        case 'escrowWaived':
        case 'loanNumber':
        case 'id':
        case 'loanPurpose':
        case 'loanType':
        case 'ltv':
        case 'miCoverage':
        case 'noteRate':
        case 'occupancy':        
        case 'pmiType':
        case 'productCode':
        case 'propertyType':
        case 'rateType':
        case 'specialtyProgram':
        case 'state':
        case 'uli':
        case 'units':
            return labels.byLoanField(fieldId);
    }
    throw new Error('field found without label');    
}