import { modal } from "mui"
import { Coordinate, CoordinateRange, DataTable, NumericalValue, InternalPricing } from 'raccoon-engine'
import React, { useMemo, useState } from 'react';
import { ConfigCell } from "./config-cell"
import { CsvCell } from "./csv-cell"


const origin:Coordinate = { x: 0, y: 0 }
type MouseAction = "down" | "up" | "none" | "move"


const originRange:CoordinateRange = { minX: 0, maxX: 0, maxY: 0, minY: 0 }

const composeRange = (c1:Coordinate, c2:Coordinate) => {
	const minX = Math.min(c1.x, c2.x)
	const maxX = Math.max(c1.x, c2.x)
	const minY = Math.min(c1.y, c2.y)
	const maxY = Math.max(c1.y, c2.y)
	return { minX, minY, maxX, maxY }
}

const selected = (range:CoordinateRange, c:Coordinate) => {
	const { minX, minY, maxX, maxY } = range
	return (c.x >= minX && c.x <= maxX && c.y >= minY && c.y <= maxY)
}

const getKey = ({ x, y }:{ x:number, y:number }) => x * 10000 + y
const rangeTracker = () => {
	let mode:MouseAction = "none"
	let startPosition:Coordinate = origin
	let range:CoordinateRange = originRange
	return (action:MouseAction, c:Coordinate) => {
		if (action === "down") {
			startPosition = c
			mode = "down"
		}
		if (action === "move") {
			if (mode === "none") {
				// startPosition = c
				return range
			}
		}
		if (action === "up") {
			mode = "none"
		}
		range = composeRange(startPosition, c)
		return range
	}
}

const toNumericalType = (moduleType:string) => {
	return (moduleType === "llpa")
	       ? "LLPA"
	       : (moduleType === "llra")
	         ? "LLRA" : "Price"
}

interface Args {
	id:string,
	module:InternalPricing;
	onUnsavedData:(id:string, csv:any[][], hints:any[][]) => void
}

export const CsvTable = (props:Args) => {
	const { module: { csv, hints }, id, onUnsavedData } = props;
	const [range, setRange] = useState<CoordinateRange>(originRange)
	const [changed, setChanged] = useState<boolean>(false)
	const { dataTable, hintTable } = useMemo(
		() => {
			return {
				dataTable: new DataTable({ data: csv }),
				hintTable: new DataTable({ data: hints })
			}
		},
		[csv, hints]
	)
	
	const tableStyle = { gridTemplateColumns: `repeat(${dataTable.width}, 1fr)` }
	const tracker = useMemo(() => {return rangeTracker()}, [])
	
	const onTrack = (a:MouseAction, c:Coordinate) => {
		setRange(tracker(a, c))
	}
	const onMouseDown = (c:Coordinate) => onTrack("down", c)
	const onMouseUp = (c:Coordinate) => onTrack("up", c)
	const onMouseOver = (c:Coordinate) => onTrack("move", c)
	const onChanged = () => {
		onUnsavedData(id, dataTable.rowColArray(), hintTable.rowColArray());
		setChanged(false)
	}
	const openConfig = (x:Coordinate) => {
		const numericalValue:NumericalValue = toNumericalType(props.module.type || "")
		modal.open(
			{
				render: <ConfigCell c={x} onClose={modal.close}
				                    onChanged={onChanged}
				                    numericalValue={numericalValue}
				                    dataTable={dataTable}
				                    hintTable={hintTable}/>,
				title: "Configuration",
				disableClose: false,
			})
	}
	
	return (<div>
		<div className="csv-table-grid" style={tableStyle}>
			{Array.from(dataTable.map()).map(d => <CsvCell
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onMouseOver={onMouseOver}
				key={getKey(d[1])} data={d[0]}
				selected={selected(range, d[1])}
				onConfig={openConfig}
				fieldHint={hintTable.getCoordinate(d[1])}
				y={d[1].y} x={d[1].x}/>)}
		</div>
	</div>)
}

