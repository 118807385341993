import React, { useState } from 'react';
import { GQL, labels, ImportModel } from 'market-dto';
import { FlexVCenter, Box } from 'mui';

interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportCol;
    readonly flowRun:GQL.FlowRun;
    readonly onChange:(x:GQL.XlsExportCol) => void;
    readonly onAccept:(x:GQL.XlsExportCol) => void;
}
export const EditColHeader = ({ colCfg, flowRun, model, onChange, onAccept }:Props) => {

    const defaultLabel = labels.byTaskXlsExportField(model, colCfg);

    // Local state label so if they type exactly same as default, override goes away, but, edit box doesn't zap
    const [ editLabel, _setEditLabel ] = useState<string>(() => colCfg.headerLabel ?? '');

    const setEditLabel = (x:string) => {
        _setEditLabel(x);
        
        const removeHeaderLabel = defaultLabel === x && !colCfg.isCalc;
        if (removeHeaderLabel) {
            const { headerLabel:removeMe, ...nextFieldCfg } = colCfg;
            onChange(nextFieldCfg as GQL.XlsExportCol);
        } else {
            onChange({ ...colCfg, headerLabel: x });
        }
    }

    const keyDown = (e:React.KeyboardEvent) => {
        if (e.key === 'Enter') onAccept(colCfg);
    }

    return (
        <div>
            <Box my="1">
                Column Header for <b>{ defaultLabel }</b>
            </Box>
            <Box my="1">
                <input
                    className="m-xls-input m-xls-bordered"
                    value={editLabel}
                    type="text"
                    onKeyDown={keyDown}
                    onChange={x => setEditLabel(x.target.value)}
                />
            </Box>

        </div>
    )
}