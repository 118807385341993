import { BatchItem } from '../types';
import { mergeBatchItem } from '../merge-batch-item';
import { onAssignedOrSkipped } from './on-assigned-or-skipped';
import { zBatch } from '../z-batch';
import { mergeBatch } from '../merge-batch';


export const applyPasswords = (item:BatchItem, password:string) => {
    mergeBatchItem(item, { filePassword:password });
    mergeBatch({ step: "PARSE_TAPES" });
}
