import React from 'react';
import { Box, BasicSpacingProps } from '../box/box';
import { NavGroup } from './nav-group';

export interface GroupedMenuGroupItem {
    readonly label:string;
    readonly value:any;
}
export interface GroupedMenuGroup {
    readonly label:string;
    readonly items:GroupedMenuGroupItem[];
}
interface GroupedMenuProps extends BasicSpacingProps {
    readonly groups:GroupedMenuGroup[];
    readonly selected?:any; /* the item.value selected */
    readonly onSelect?:(value:any) => void;
}

export const GroupedNavMenu = ({ groups, ...rest }:GroupedMenuProps) => {
    return (
        <Box {...rest}>
            { groups.map((group, n) => (
                <NavGroup
                    key={n}
                    mb={n < groups.length-1 ? '1' : undefined}
                    group={group}
                />
            ))}
        </Box>
    )
}