import { GQL } from 'market-dto';
import { RuleModelField, RuleModelFiedlType } from './types';

export const toRuleModelFields = (model:GQL.Model):RuleModelField[] => {
    // if (!model.schema.properties) throw new Error('Expected schema.properties');
    const req = model.schema.required ?? [];
    const properties = model.schema.properties ?? {};
    return Object.entries(properties)
        .map((arr, n):RuleModelField => {
            // if (arr[1].type === 'object') throw new Error('Nested objects not allowed in domain items');
            const fieldId = arr[0];
            const schema = arr[1];
            const groups = schema.tags ?? [];
            const label = schema.title ?? '';
            const type = (schema.type as RuleModelFiedlType) ?? 'string';
            // const type = schema.type ?? 'string';
            return {
                index: n,
                fieldId,
                // schema,   // WHAT IS THIS FOR?
                groups,
                label,
                type: type === 'string' && schema.enum ? 'enum' : type,
                required: req.includes(fieldId),
                enums: schema.enum,
                // labelByEnum: schema.labelByEnum // Might bring this back at some point?
            }
        })
}
