import { createZStateList } from '../z-state';
import { GQL } from 'market-dto';
import * as api from 'api';

export const zModelSamples = createZStateList({
    apiFetchList: api.fetchAllModelSamples,
    apiUpdateListItem: api.updateModelSample,
    apiInsertListItem: api.insertModelSample,
    isEqual: (a,b) => a.id === b.id,
    createNewListItem: ():GQL.ModelSample => {
        return {
            data: {},
            id: '',
            modelId: '',
            name: '',
            description: ''
        }
    },
    forceCommitsWhenNoChange: true // for testing
})