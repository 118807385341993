import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';
import { ModelType } from 'market-dto';

// Note: The mutation insert_sheet expects an ARRAY, even though, we will only send one.
// Applies to LoanModelSheet AND BidTapeModelSheet

type InsertObject = Pick<GQL.Sheet,
    'tapeId' |
    'modelType' |
    'fileName' |
    'headers' |
    'templateId' |
    'hash' |
    'sequenceHash' |
    'orderHash' |
    'mappedHash' |
    'sellerOrgId' |
    'aggregatorName'
>;

const INSERT = gql`
    mutation insertSheet($objects: [sheet_insert_input!]) {
        insert_sheet(objects: $objects) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateOutput {
    readonly insert_sheet: {
        readonly returning:[GQL.Sheet]; // expect just one
    }
}

interface MutateInput {
    readonly objects:[InsertObject]
}

interface InsertSheetProps {
    readonly tapeId:string;
    readonly modelType:ModelType;
    readonly fileName:string;
    readonly templateId:string;
    readonly headers:string[];
    readonly hash:string;
    readonly sequenceHash:string;
    readonly orderHash:string;
    readonly mappedHash:string;
    readonly sellerOrgId:string;
    readonly aggregatorName?:string;
}
export const insertSheet = async (props:InsertSheetProps):Promise<GQL.Sheet | undefined> => {
	const savedSheet = await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [props]
    })
    return savedSheet?.data?.insert_sheet.returning[0];
}
