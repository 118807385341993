import { GQL, CashWindow, format } from "market-dto"
import { exploreOptions } from './explore-options';
import { findPositions } from './find-positions';
import { HoldingInfo } from '../profit/types';
import * as api from 'api';
import { isWeekend } from "date-fns"
import { getBasePrice } from './get-base-price';

const invalidItemsToNull = (arr2d:CashWindow.HoldingInterest[][]):Array<Array<CashWindow.HoldingInterest|null>> => {
    return arr2d.map(innerArr => {
        return innerArr.map(x => {
            if (isNaN(x.interestSpreadPct)) return null;
            if (isNaN(x.couldSellFor)) return null;
            if (x.interestSpreadPct <= 0) return null;
            if (x.couldSellFor <= 0) return null;
            return x;
        })
    })
}

const stripWeekends = (arr2d:Array<Array<CashWindow.HoldingInterest>>):Array<Array<CashWindow.HoldingInterest>> => {
    return arr2d.map(innerArr => {
        return innerArr.filter(x => {
            const xDate = format.ensureDate(x.target);    
            return !isWeekend(xDate);
        })
    }).filter(innerArr => {
        const yDate = format.ensureDate(innerArr[0].acquisitionDate);
        return !isWeekend(yDate);
    })
}

export const getHoldingInfo = async (
    loan:GQL.Loan,
    offer:GQL.Offer,
    removeWeekends?:boolean,
    refreshCashWindow?:boolean
):Promise<HoldingInfo> => {

	const { investorProductId } = offer;
	if (!investorProductId) throw new Error('Expected investor product id!');

	console.log('investorProductId', investorProductId, refreshCashWindow);

	const result = refreshCashWindow ? 
		await api.refreshCashWindow([investorProductId]) :
		await api.fetchCashWindow([investorProductId]);

	if (!result) throw new Error('could not fetch cash window');
	if (result.cashWindow.length === 0) throw new Error('empty cash window');
	console.log('result', result);
	const dailyPrices = result.cashWindow[0];
	const possibleHoldings = findPositions(offer, loan, dailyPrices);
    console.log(possibleHoldings);
    

    // fannie gives us a 2d array with NO HOLES in it (includes weekends and NaN values)
    const explored = exploreOptions(offer, loan, dailyPrices);
    const arr2d = removeWeekends ? stripWeekends(explored) : explored;
    // const arr2d = stripWeekends(explored);
    const deliveryDatesWithoutWeekends = arr2d[0].map(x => format.ensureDate(x.target));
    const aquisitionDatesWithoutWeekends = arr2d.map(innerArr => format.ensureDate(innerArr[0].acquisitionDate));
    const finalArr2d = invalidItemsToNull(arr2d);
  
    return {
        holdingInterest: finalArr2d,
        aquisitionDates: aquisitionDatesWithoutWeekends,
        basePrice: getBasePrice(offer),
        deliveryDates: deliveryDatesWithoutWeekends
    }
    
}
