import { GQL, util } from 'market-dto';
import { Generated, AggMatchResult, HashMatchConfidence } from '../types';
import { findSellers } from './find-sellers';
import { getTemplateOverrideHash } from './get-template-override-hash';

export const oneSellerNameInAggTape = (
    generated:Generated,
    template:GQL.Template,
    hashConfidence:HashMatchConfidence,
    sellers:GQL.Org[],
    sellerNameInTape:string
):AggMatchResult => {
    // Good. exactly one seller name found in tape.
    // Now, did we map it to a real org?
    if (!template.aggregatorName) throw new Error("Expected aggregatorName on agg template");

    // Three lists of sellers:
    // 1. those from template.orgIds
    // 2. those that match the single distinct sellerName found in the tape.
    // 3. those in BOTH
    const orgsFoundFromSellerName = findSellers(sellers, sellerNameInTape);
    const orgIdDictFromTemplate = util.toDict(template.orgIds, x => x);
    const orgsFoundFromTemplate = sellers.filter(org => orgIdDictFromTemplate[org.id]);
    const orgsInBoth = orgsFoundFromSellerName.filter(org => orgIdDictFromTemplate[org.id]);

    // "Unknown seller" means ZERO in both. They will have to confirm it.
    /*
export type ValidAggMatchState = 
    | "AGG_EXACTLY_ONE_POSSIBLE_SELLER"                 // happy path
    | "AGG_COULD_BE_ONE_OF_MULTIPLE_SELLERS"            // multiple found in both (seller lists from tape, from template)
    | "AGG_WITH_UNKNOWN_SELLER_IN_TAPE"                 // 0 known sellers from tape value
    | "AGG_WITH_ONE_POSSIBLE_SELLER_IN_TAPE_BUT_NOT_TEMPLATE" // 1 known seller from tape value, but not in orgIds
    */

    if (orgsFoundFromSellerName.length === 0) {
        return {
            type: "agg",
            generated,
            sellerNameInTape,
            aggregatorName: template.aggregatorName,
            matchState: "AGG_WITH_UNKNOWN_SELLER_IN_TAPE",
            sellerOrgsInBoth: orgsInBoth,
            sellerOrgsFromSellerName: orgsFoundFromSellerName,
            sellerOrgsInTemplate: orgsFoundFromTemplate,
            template,
            templateOverrideHash: getTemplateOverrideHash(template),
            hashConfidence
        }
    }
    if (orgsInBoth.length === 0) {
        return {
            type: "agg",
            generated,
            sellerNameInTape,
            aggregatorName: template.aggregatorName,
            matchState: "AGG_WITH_KNOWN_SELLER_BUT_NOT_IN_TEMPLATE",
            sellerOrgsInBoth: orgsInBoth,
            sellerOrgsFromSellerName: orgsFoundFromSellerName,
            sellerOrgsInTemplate: orgsFoundFromTemplate,
            template,
            templateOverrideHash: getTemplateOverrideHash(template),
            hashConfidence
        }
    }
    if (orgsInBoth.length === 1) {
        // This allows for a happy path.
        return {
            type: "agg",
            generated,
            sellerNameInTape,
            aggregatorName: template.aggregatorName,
            matchState: "AGG_EXACTLY_ONE_POSSIBLE_SELLER",
            sellerOrgsInBoth: orgsInBoth,
            sellerOrgsFromSellerName: orgsFoundFromSellerName,
            sellerOrgsInTemplate: orgsFoundFromTemplate,
            template,
            templateOverrideHash: getTemplateOverrideHash(template),
            hashConfidence
        }
    }

    // You get here, we got multiple known.
    return {
        type: "agg",
        sellerNameInTape,
        aggregatorName: template.aggregatorName,
        matchState: "AGG_COULD_BE_ONE_OF_MULTIPLE_SELLERS",
        //canBeUsed: true,
        sellerOrgsInBoth: orgsInBoth,
        sellerOrgsFromSellerName: orgsFoundFromSellerName,
        sellerOrgsInTemplate: orgsFoundFromTemplate,
        template,
        templateOverrideHash: getTemplateOverrideHash(template),
        hashConfidence
    }
}