import React, { useState } from 'react';
import { GQL } from 'market-dto';
import { usePopper } from 'react-popper';
import { Box, Icon, Text, Button, Link, FlexVCenter, IconButton, SortableTableHeader, usePageSortTable, TablePageButtons } from 'mui';
import { FlowTableRecentCol } from './flow-table-recent-col';
import { PopMenu } from './pop-menu';
import { FlowListSelection } from './types';

const getTypeName = (x:string) => {
    if (!x) return '';
    return x.charAt(0).toUpperCase() + x.substr(1);
}

interface PopCfg {
    readonly flow:GQL.Flow;
    readonly elem:HTMLDivElement;
}

interface FlatDisplayFlow extends GQL.Flow {
    readonly executedAt:string;
}
interface Props {
    readonly flows:GQL.Flow[];
    readonly onSelect:(sel:FlowListSelection) => void;
}
export const FlowTable = ({ flows, onSelect }:Props) => {

    const [ popperElement, setPopperElement ] = useState<HTMLDivElement|null>(null);
    const [ popCfg, setPopCfg ] = useState<PopCfg|null>(null);
    // const [ popCfg, setPopFilterCfg ] = useState<PopFilterCfg>();

    const { styles, attributes } = usePopper(popCfg?.elem, popperElement, {
        // placement: 'bottom-start',
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-1, 6]
                }
            }
        ],
        strategy: 'absolute'
    })


    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<GQL.Flow, keyof FlatDisplayFlow>({
        rows: flows,
        keyFn: row => JSON.stringify(row), // any change to anything in row, re-render
        valFn: (row, colId) => {
            if (colId === 'tags') return row[colId].join(', ');
            if (colId === 'executedAt') return row.recentActivity ? row.recentActivity.executedAt : '';
            // if (colId === 'recipeName') return row.rec
            return row[colId];
        },
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const openPop = (flow:GQL.Flow, elem:HTMLDivElement) => setPopCfg({ flow, elem });
    const closePop = () => setPopCfg(null);

    return (
        <>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render="Name" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="tags" render="Tags" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="recipeName" render="Workflow Type" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="executedAt" render="Recent" align="right" />
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.length === 0 && (
                        <tr>
                            <td colSpan={4}>
                                <Box textStyle="italic" fg="faded">
                                    No workflows found
                                </Box>
                            </td>
                        </tr>
                    ) }
                    { sortedRows.map((flow, n) => (
                        <tr key={flow.id}>
                            <td>
                                <Link onClick={() => onSelect({ flow })}>{ flow.name }</Link>
                            </td>
                            <td>{ flow.tags.join(', ') }</td>
                            <td>{ getTypeName(flow.recipeName) }</td>
                            <td>
                                <FlowTableRecentCol flow={flow} onPop={openPop} />
                            </td>
                        </tr>
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />

            { popCfg && (
                <div style={{
                    position:'relative',
                    zIndex: 999999,
                    
                }}>
                    <div
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <PopMenu
                            onClose={closePop}
                            flow={popCfg.flow}
                            onSelect={onSelect}
                        />
                    </div>
                </div>
            ) }
        </>
    )
}
