import React from 'react';
import ReactSelect, { Theme, Styles } from 'react-select';
import { InputWidth, InputSize } from '../../types';

/* we must override a few styles for the react-select */
// padding: var(--space-half);
// width === "frm-sm" ? 'var(--input-width-frm-sm)' : width === "frm-md" ? 'var(--input-width-frm-md)' : '100%'

const getControlWidth = (width:InputWidth, isMulti:boolean):string => {
    if (width === '100%') return '100%';
    if (!isMulti) {
        if (width === 'frm-sm') return 'var(--input-width-frm-sm)';
        return 'var(--input-width-frm-md)';
    }
    if (width === 'frm-sm') return 'var(--multi-dropdown-width-frm-sm)';
    return 'var(--multi-dropdown-width-frm-md)';
}


const getStuff = (size:InputSize, width:InputWidth, disabled:boolean, isMulti:boolean):Styles => {

    return {
        menuList: (provided, state) => {
            // console.log('provided', provided);
            return {
                ...provided,

                zIndex: 'var(--zIndex-tooltips)' as any,
                backgroundColor: 'var(--bg50)',
                color: 'var(--fg-faded)'
            }
        },
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor: state.isFocused ? 'var(--bg30)' : 'transparent',
                color: state.isFocused ? 'var(--fg-loud)' : 'var(--fg-faded)'
            }
        },
        multiValueLabel: (base, state) => ({
            ...base,
            // padding:0,
            color: disabled ? 'var(--fg-faded)' : 'var(--fg)',
            paddingLeft: size === 'md' ? 'var(--space1)' : 'var(--space-half)',
            ...state.isDisabled ? {
                // 15 would be the bg behind it
                backgroundColor: 'var(--bg50)',
                paddingRight: size === 'md' ? 'var(--space1)' : 'var(--space-half)'
            } : {}            
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            cursor:'pointer',
            ...state.isDisabled ? {
                display: 'none'
            } : {}
        }),
        placeholder: base => ({
            ...base,
            // font sizes in vars are not working with this for some reason...must specify REM
            fontSize: size === "sm" ? '0.85rem' : '1rem',
            color: 'var(--fg-really-faded)',
            paddingLeft: size === "sm" ? '0' : '3px', // wow, 3. well, it works.
            ...disabled ? {
                fontStyle: 'italic',
            } : {},
        }),
        control: (base, state) => ({
            ...base,
            minHeight: 'initial',
            padding:0,
            margin:0,
            backgroundColor: state.isFocused ? 'var(--bg30)' : base.backgroundColor,
            ...disabled ? { borderColor: 'transparent' } : {},
            width: getControlWidth(width, isMulti),
            boxShadow: 'none' // removes that "extra" border
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: size === "sm" ? '0 var(--space-half)' : '0 var(--space1)',
            ...disabled ? { color: 'var(--fg-really-faded)' } : {},
            margin: 0
        }),
        indicatorSeparator: base => ({
            ...base,
            ...disabled ? { color: 'var(--fg-really-faded)' } : {},
        }),
        clearIndicator: base => ({
            ...base,
            padding: size === "sm" ? '0 var(--space-half)' : '0 var(--space1)',
            margin: 0,
        }),
        valueContainer: base => ({
            // this is the INPUT box
            ...base,
            paddingLeft: size === 'sm' ? '2px' : '4px',
            // margin: 0 // no effect
        }),
        input: base => ({
            ...base,
            // margin: size === 'sm' ? '0' : '4px' // no effect
            padding: size === 'sm' ? '1px' : '4px',
        })
    }
}

const smallStyles:Styles = {
    control: base => ({
        ...base,
        minHeight: 'initial',
        padding:0,
        margin:0,
        width: 'var(--input-width-frm-sm)'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
        margin: 0
    }),
    clearIndicator: base => ({
        ...base,
        padding: 0,
        margin: 0
    }),
    valueContainer: base => ({
        // this is the INPUT box
        ...base,
        padding: '2px',
        margin: 0
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding:0
    }),
    // indicatorSeparator: state => ({
    //     display: 'none',
    // }),
    // indicatorsContainer: (provided, state) => ({
    //     ...provided,
    //     height: '10px',
    // })
}

const mainCardStyles:Styles = {
    menuList: (provided, state) => {
        console.log('provided', provided);
        return {
            ...provided,
            zIndex: 'var(--zIndex-tooltips)' as any,
            backgroundColor: 'var(--bg50)',
            color: 'var(--fg-faded)'
        }
    },
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isFocused ? 'var(--bg30)' : 'transparent',
            color: state.isFocused ? 'var(--fg-loud)' : 'var(--fg-faded)'
        }
    }
}

const innerCardStyles:Styles = {
    menuList: (provided, state) => ({
        ...provided,
        zIndex: 'var(--zIndex-tooltips)' as any,
        backgroundColor: 'var(--bg20)',
        color: 'var(--fg-faded)',
    }),
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isFocused ? 'var(--bg40)' : 'transparent',
            color: state.isFocused ? 'var(--fg-loud)' : 'var(--fg-faded)'
        }
    }
}

const getStyles = (width?:InputWidth, disabled?:boolean):Styles => {
    // userInnerStyle ? innerCardStyles : mainCardStyles
    return {
        ...mainCardStyles,
        ...width === 'frm-sm' ? smallStyles : {}
    }
}

// Note: not all of these end up being used. That's fine.
const dropdownColors:any = {
    "primary": "var(--dd-primary)",
    "primary75": "var(--dd-primary75)",
    "primary50": "var(--dd-primary50)",
    "primary25": "var(--dd-primary25)",
    "danger": "var(--dd-danger)",
    "dangerLight": "var(--dd-dangerLight)",
    "neutral0": "var(--dd-neutral0)",
    "neutral5": "var(--dd-neutral5)",
    "neutral10": "var(--dd-neutral10)",
    "neutral20": "var(--dd-neutral20)",
    "neutral30": "var(--dd-neutral30)",
    "neutral40": "var(--dd-neutral40)",
    "neutral50": "var(--dd-neutral50)",
    "neutral60": "var(--dd-neutral60)",
    "neutral70": "var(--dd-neutral70)",
    "neutral80": "var(--dd-neutral80)",
    "neutral90": "var(--dd-neutral90)"
}

const setTheme = (th:Theme):Theme => {
    return {
        ...th,
        colors: {
            ...th.colors,
            ...dropdownColors
        }
    }
}

// const getValuesForReadonly = (items:Array<DropdownItem>, value?:Array<DropdownItem>, vals?:Array<any>) => {
//     const arr = vals ?
//         items.filter(x => vals.includes(x.value)).map(x => x.label) :
//         value ? value.map(x => x.label) : [];
//     return arr.join(', ');
// }
// const getValueForReadonly = (items:Array<DropdownItem>, value?:DropdownItem, vals?:Array<any>) => {
//     // For non-multi
//     if (vals) {
//         // ...is vals still an array? i think so, even for single.
//         const v = items.find(x => vals.includes(x.value));
//         return v ? v.label : '';
//     }
//     return value ? value.label : '';
// }


export interface DropdownItem<T=any> {
    readonly label:React.ReactNode;
    readonly value:T;
}
// interface Har {
//     readonly name:string;
// }
// const a:DropdownItem<Har> = {
//     label: "Asdf",
//     value: {
//         name: "sdf"
//     }
// }

type OnChangeSingle<T> = (val:DropdownItem<T>) => any;
type OnChangeMulti<T> = (vals:Array<DropdownItem<T>>) => any;

export interface DropdownProps<T> {
    readonly width?:InputWidth;
    readonly size?:InputSize;
    readonly disabled?:boolean;
    readonly isMulti?:boolean;
    readonly isClearable?:boolean;
    readonly placeholder?: string;
    readonly readonlyPlaceholder?: string;
    readonly items:DropdownItem<T>[];
    readonly vals?:Array<any>; // if you want to deal with values as input/output, not label/values
    readonly value?:DropdownItem<T> | DropdownItem<T>[];
    readonly onChange?: OnChangeSingle<T> | OnChangeMulti<T>;
    readonly setValue?: (x:any) => any; // if you want to deal with values as input/output, not label/values
    // readonly userInnerStyle?:boolean; // is this used?
}

export const Dropdown = <T,>({
    size = "md",
    width = "100%",
    vals,
    disabled,
    placeholder,
    readonlyPlaceholder,
    items,
    value,
    onChange,
    setValue,
    isMulti,
    isClearable
}:DropdownProps<T>) => {

    const _onChange = (x:any) => {
        if (onChange) onChange(x);
        if (setValue) {
            if (x === null) {
                setValue(isMulti ? [] : null);
                return;
            }
            setValue(isMulti ? (x as DropdownItem<T>[]).map(y => y.value) : x.value);
        }
    }


    // if (disabled) {
    //     const val = isMulti ?
    //         getValuesForReadonly(items, value as DropdownItem[], vals) :
    //         getValueForReadonly(items, value as DropdownItem, vals);

    //     // TODO: "None" should not be hard coded.
    //     // Also, we have to figure out a nice way to have consistent heights...<input> isn't doing it in all cases!
    //     // That matters when flipping between editting/readonly.
    //     if (width === 'frm-sm') {
    //         return (
    //             <>
    //                 { val && <Input readOnly={true} value={val} size="sm" width="xl" /> }
    //                 { !val && <FlexVCenter>
    //                     <NoneSmall label={noneLabel} />
    //                     <Input hideForHeight size="sm" width="xl" readOnly />
    //                 </FlexVCenter> }
    //             </>
    //         )
    //     } else {
    //         return (
    //             <>
    //                 { val && <Input readOnly={true} value={val} /> }
    //                 { !val && <None label={noneLabel} /> }
    //             </>
    //         )
    //     }
    // }


    const ph = disabled && readonlyPlaceholder ? readonlyPlaceholder : placeholder;

    return (
        <ReactSelect
            isDisabled={disabled}
            menuPosition="fixed" // In case dropdown in a modal, don't want it cut off
            className="m-dd"
            isMulti={!!isMulti}
            placeholder={ph}
            value={vals ? items.filter(x => vals.includes(x.value)) : value}
            onChange={_onChange}
            options={items}
            // styles={getStyles(width, disabled)}
            styles={getStuff(size, width, !!disabled, !!isMulti)}
            theme={setTheme}
            // innerStyle={innerCardStyles}
            isClearable={isClearable}
            // menuIsOpen={true} // for debugging
        />
    )
}
