import React from 'react';
import { FrmDropdown, FrmDropdownProps } from './frm-dropdown';
import { useProxyData, BindTo } from '../../use-proxy-data';

interface BoundFrmDropdownProps<T extends {},K> extends FrmDropdownProps<K> {
    readonly bindTo:BindTo<T>;
}
export const BoundFrmDropdown = <T extends {}, K>({ bindTo, ...props }:BoundFrmDropdownProps<T,K>) => {
    const proxy = useProxyData(bindTo);
    if (props.isMulti) {
        return <FrmDropdown vals={proxy.value} setValue={proxy.setVal} {...props} />
    } else {
        const val = props.items.find(x => x.value === proxy.value);
        return <FrmDropdown value={val} setValue={proxy.setVal} {...props} />
    }
}

// interface BoundDropdownProps<T extends {}> extends FormDropdownProps {
//     readonly bindTo:BindTo<T>;
// }
// export const BoundDropdown = <T extends {}>({ bindTo, items, isMulti, ...rest }:BoundDropdownProps<T>) => {
//     const proxy = useProxyData(bindTo);
//     if (isMulti) {
//         return <FormDropdown vals={proxy.value} setValue={proxy.setVal} items={items} isMulti={isMulti} {...rest} />
//     } else {
//         const val = items.find(x => x.value === proxy.value);
//         return <FormDropdown value={val} setValue={proxy.setVal} items={items} isMulti={isMulti} {...rest} />
//     }    
// }
