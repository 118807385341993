export const isCurrent = async ():Promise<boolean> => {
    if (document.location.hostname.includes('localhost')) return true;
    try {
        const res = await fetch('/ver.html', { cache: 'no-store' });
        const txt = await res.text();
        if (!txt) return true; // assume we are current if ver.html did not exist
        if (!process.env.REACT_APP_VERSION) return true; // assume current if react version not found
        if (txt !== process.env.REACT_APP_VERSION) return false; // WE ARE NOT CURRENT!
        return true;        
    } catch (err) {
        console.log('ERROR');
        return true; // Assume current on any errors
    }
}