import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

export const QUERY = gql`
    query org($where: org_bool_exp!) {
        org(where: $where) { ${ RETURNING } }
    }
`;

interface QueryResult {
    readonly org:GQL.Org[];
}
interface QueryInput {
    readonly where:GQL.WhereString;
}

export const fetchAllInvestors = async ():Promise<GQL.Org[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            orgType: {
                _eq: "Investor"
            }
        }
    })
    return result.data.org;
}
