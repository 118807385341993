import React from 'react';
import { GQL } from 'market-dto';
import { ExpandedEnum } from './expanded-enum';

interface Props {
    readonly colCfg:GQL.XlsExportCol;
    readonly flowRun:GQL.FlowRun;
    readonly onAccept:(x:GQL.XlsExportCol) => void;
    readonly onChange:(x:GQL.XlsExportCol) => void;
    readonly onClose:() => void;
}

// Calling this non-mapped as opposed to unmapped, because
// unmapped implies that we tried to map. It could be a calculated field.

export const NonMappedField = ({
    colCfg,
    flowRun,
    onAccept,
    onChange,
    onClose
}:Props) => {

    if (colCfg.type === "CALC_ENUM") {
        return <ExpandedEnum
            colCfg={colCfg}
            flowRun={flowRun}
            onAccept={onAccept}
            onChange={onChange}
            onClose={onClose}
        />
    }
    return (
        <div style={{ marginTop: '2px'}}>
            Cannot currently edit this type of expanded field.
            <br/>
            That is coming soon.
        </div>
    )
}