export const debugColumnsHeaders = [
	'base code',
	'bal code',
	'fico code',
	'occ code',
	'state code',
	'base px',
	'bal px',
	'fico px',
	'occ px',
	'state px',
	'commit code',
    'commit px',
    'amdc', // existing, this and above.
    'risk based',
    'cashout refi',
    'sub finance',
    'invest property',
    'condo',
    '2 unit',
    '3-4 unit',
    'co-op',
    'second home',
    'high ltv',
    'low fico',
    'high bal',
    'high bal cor',
    'total llpas',
    'frm io',
    'manu home'
]
