import React, { useState } from 'react';
import { user as userService, base64, images, urls } from 'services';
import { Icon, Box, Flex, toastError, Divider, Avatar, FlexVCenter, IconButton, Button, Heading, FrmInput, BoundFrmInput, Checkbox } from 'mui';
import { useZState, useStickyStateWithDefault } from 'hooks';

export const Account = () => {

    const {
        data:editObj,
        update,
        updateAndCommit,
        saving,
        reset,
        save,
        editting,
        setEditting
    } = useZState(userService.zUser);

    // You can actually NOT have a user if they logout while on the account screen!
    if (!editObj) throw new Error('Expected user');
    const { user } = editObj;

    const [ showDebugMsgs, setShowDebugMsgs ] = useState<boolean>(() => userService.getDebug());
    const [ showInvalidOffers, setShowInvalidOffers ] = useStickyStateWithDefault("show-invalid-offers", false);
    const [ showImportDebugArea, setShowImportDebugArea ] = useStickyStateWithDefault("debug-import", false);

    const toggleEditting = () => {
        // if (editting) setData(createFormData(user!)); // Reset the form
        if (editting) reset();
        setEditting(!editting);
    }

    const changeAvatar = async (file:File) => {
        const b64 = await base64.readFileAsBase64(file);
        if (!b64) {
            toastError("We were unable to process the image...try a different one.")
        } else {
            // We don't care about busy states for image changing. It updates or it doesn't.
            const resized64 = await images.resize(b64, 95, 95);
            updateAndCommit({
                ...editObj,
                user: {
                    ...editObj.user,
                    profilePhoto: resized64
                } 
            }, true)
        }
    }

    const onSave = async () => {
        if (saving || !editting) return;
        // await update(data);
        await save();
        setEditting(false);
    }

    const cancel = () => {
        toggleEditting();
    }

    return (        
        <Box mt="2" p="2" bg="body" width="card-md" mx="auto">

            <Flex mb="2" justifyContent="space-between">
                <FlexVCenter>
                    <Avatar
                        onSelectFile={changeAvatar}
                        src={user.profilePhoto}
                        name="Click to Change"
                        cursor="pointer"
                        borderColorHover="link"
                        borderColor="transparent"
                        borderSides="all"
                    />
                    <Heading ml="1" mb="0">My Profile</Heading>
                </FlexVCenter>
                <FlexVCenter height="btn-row">
                    { (editting || saving) && (
                        <>
                            <Button size="sm" type="link" onClick={cancel} mr="1">Cancel</Button>
                            <Button size="sm" onClick={onSave} busy={saving}>Save</Button>
                        </>
                    ) }
                    { !editting && <IconButton onClick={toggleEditting} type="link" name="edit" /> }
                    
                </FlexVCenter>
            </Flex>

            <Divider />

            <FrmInput
                width="100%"
                label="Email"
                readOnly={true}
                value={user!.email}
            />
            <BoundFrmInput
                width="100%"
                label="First Name"
                bindTo={[editObj, update, x => x.user.firstName ]}
                readOnly={!editting}
                placeholder="Enter First Name"
                readonlyPlaceholder="None"
                onEnter={onSave}
            />
            <BoundFrmInput
                width="100%"
                label="Last Name"
                bindTo={[editObj, update, x => x.user.lastName ]}
                readOnly={!editting}
                placeholder="Enter Last Name"
                readonlyPlaceholder="None"
                onEnter={onSave}
            />
            <Box mt="2" pt="2">
                <Checkbox
                    checked={showInvalidOffers}
                    onToggle={setShowInvalidOffers}
                    label="Show invalid offers"
                />
                { urls.isLocalhost && (
                    <>
                        <Checkbox
                            // not part of user hook. perhaps add it? dunno...
                            mt="1"
                            checked={showDebugMsgs}
                            onToggle={() => {
                                setShowDebugMsgs(!showDebugMsgs);
                                userService.toggleDebug();
                            }}
                            label="Show generic debug msgs (localhost only)"
                        />
                        <Checkbox
                            mt="1"
                            checked={showImportDebugArea}
                            onToggle={setShowImportDebugArea}
                            label="Show import debug area (localhost only)"
                        />
                    </>
                ) }
            </Box>
        </Box>
    )
}

