import { toCurrencyNoCents } from "mui/components/formatter"
import { IConstruct, IQualifier, ThresholdInput } from "raccoon-engine"

export interface IQualifierFor {
	qualifiedFor:string
}

export interface CannedThreshold extends ThresholdInput {
	display:string
}


// question: why do we need to know the detail? - duncan
type classifierTypeType = "SIMPLE" | "ASSERTION" | "MIXED"


export const installAndRetrieve = (qualifiers:IQualifier[], target:string) => {
	const found = qualifiers.find(qualifier => qualifier.qualifiedFor === target)
	if (found) return found
	const added = { qualifiedFor: target, constructs: [], classifierType: "MIXED" } as IQualifier
	qualifiers.push(added)
	return added
}

export const wording = {
	eligibilityTitle: "Purchasable Loan Requirement",
	eligibilityDescription: "You can define the overall conditions which loans must meet to be"
		+ " considered purchasable.",
	title: "Program Qualifier",
	description: `You can define loan programs and their qualifications.`,
	search: 'type and search the predefined conditions',
	searchTitle: 'Example Conditions you can choose from and modify',
	conditionTitle: 'Specified Conditions'
}

const moneyPattern = /(amt)|(amount)|(balance)/gim
const possibleMoneyField = (field:string) => {
	return field.match(moneyPattern)
}

const paramToString = (field:string, param:any):string => {
	if (typeof param === "string") return param
	if (Array.isArray(param)) {
		const parts = (param as any[]).map(e => {return paramToString(field, e)})
		return parts.join(", ")
	}
	if (typeof param === "number") {
		if (possibleMoneyField(field)) {
			return toCurrencyNoCents(param)
		}
		return param.toString()
	}
	return param.toString()
}

export const readableDisplay = (input:IConstruct) => {
	const { threshold: { display, control, field }, } = input
	const replacementToken = `$value`
	const { isField, value } = control || {}
	if (isField) return display
	const replacement = paramToString(field, value)
	return display?.replace(replacementToken, replacement)
}
