import React, { useState, useEffect } from 'react';
import { Box } from '../box/box';
import { FlexVCenter } from '../flex/flex';
import { Textarea } from '../input/textarea2';
import { TextareaProps } from '../input';
import { SpacingSize, InputWidth } from '../../types';

export interface FrmTextareaProps extends TextareaProps {
    readonly label:string;
    readonly desc?:string;
    readonly subLabel?:string;
    readonly subDesc?:string;
    readonly mt?:SpacingSize;
}
export const FrmTextarea = ({ label, desc, subLabel, subDesc, mt, ...props }:FrmTextareaProps) => {
    
    const labelMl:SpacingSize = props.size === "sm" ? "half" : "1";
    const controlMt:SpacingSize = props.size === "sm" ? "half" : "1";
    const w:InputWidth = props.width ?? (props.size === 'md' ? "frm-md" : "frm-sm");

    return (
        <Box mt={mt} mb="1">

            <FlexVCenter ml={labelMl}>
                <Box fg="form-label" fontSize="md">{ label }</Box>
                { desc && <Box fontSize="sm" fg="form-desc" ml="1">{ desc }</Box> }
            </FlexVCenter>

            { (subLabel || subDesc) && (
                <FlexVCenter ml={labelMl} fg="form-label" fontSize="sm" textStyle="italic">
                    { subLabel && <Box mr="1" fg="form-desc">{ subLabel }</Box> }
                    { subDesc && <Box fg="form-label">{ subDesc }</Box> }
                </FlexVCenter>
            ) }

            <Box mt={controlMt}>
                <Textarea
                    visibleWhenReadOnly
                    width={w}
                    {...props}
                />
            </Box>
        </Box>
    )
}
