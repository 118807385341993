import { Overrides, RowsAndHeaders, loanModel, bidTapeModel, ModelType, ImportModel } from 'market-dto';
import { Field, ColumnValuesAndMapping } from './types';
import { getFields } from './get-fields';

const getMappedToLabel = (found:Field[], headerIndex:number):string => {
    return found
        .filter(f => f.cfg && f.res && f.res.winner === headerIndex)
        .map(f => f.label)
        .join(', ');
}

interface AppliedMappingResults {
    readonly found:Field[];
    readonly notFound:Field[];
    readonly colValMapItems:ColumnValuesAndMapping[];
}
export const getAppliedMappingResults = (
    model:ImportModel,
    orides:Overrides,
    rowsAndHeaders:RowsAndHeaders
):AppliedMappingResults => {


    const mapped = model.tryMap({ orides, rowsAndHeaders });
    const fields = getFields(model, mapped);
    const found = fields.filter(x => x.res.winner >= 0);
    const notFound = fields.filter(x => x.res.winner === -1);
    const colValMapItems:ColumnValuesAndMapping[] = rowsAndHeaders.headers.map((h, headerIndex) => {
        return {
            colName: h,
            values: rowsAndHeaders.rows.map(row => row[headerIndex]),
            mappedTo: getMappedToLabel(found, headerIndex),
        }
    })
    return {
        found,
        notFound,
        colValMapItems
    }
}
