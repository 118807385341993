import { GQL, ImportModel } from 'market-dto';
import { Batch, BatchItem } from '../types';
import { zBatch } from '../z-batch';

export const addBatch = (
    model:ImportModel,
    sellers:GQL.Org[],
    files:File[],
    askAboutHeaders:boolean
) => {
    const items = files.map((file, n):BatchItem => {
        return {
            model,
            sellers,
            index: n,
            tapeId: '',
            status: 'AWAITING_PARSING',
            file,
            askAboutHeaders
        }
    })
    const batch:Batch = {
        model,
        stepping: false,
        paused: false,
        canceled: false,
        step: "PARSE_TAPES",
        processing: false,
        items
    }
    zBatch.set(batch);
}

