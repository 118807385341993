import React, { useState } from "react"
import { Hide, FlexVCenter } from "mui"
import { useInterval } from 'hooks';


export const DisplayTime = () => {
	const [ time, setTime ] = useState<Date>(new Date());
	useInterval(() => {
		setTime(new Date());
	}, 1000);
	return (
		<FlexVCenter>
			<Hide hideAt="lg">{ time.toLocaleDateString() }</Hide>
			<Hide hideAt="md" ml="1">{ time.toLocaleTimeString() }</Hide>
		</FlexVCenter>
	)
}