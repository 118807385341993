import React from 'react';
import { BasicSpacingProps } from '../box/box';
import { Flex } from '../flex/flex';
import { NavMenuItem } from '../../types';
import { FancyTabItem } from './fancy-tab-item';
import { TabItem } from './tab-item';

interface Props extends BasicSpacingProps {
    readonly items:NavMenuItem[];
    readonly fancy?:boolean;
}

export const NavTabs = ({ items, fancy, ...rest }:Props) => {
    return (
        <Flex {...rest}>
            { items.map((item, n) => {
                if (fancy) {
                    return <FancyTabItem key={n} item={item} />
                } else {
                    return <TabItem key={n} item={item} />
                }
            }) }
        </Flex>
    )
}
