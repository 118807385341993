import React, { useEffect, useState } from 'react';
import { Box, FlexVCenter, Button, Icon, Text, modal, ModalFooter } from 'mui';
import { GQL } from 'market-dto';
import { rule } from 'services';
import { InvalidJson } from 'components';
import { useZStateList } from 'hooks';

interface Props {
    readonly json:string;
    readonly existingChecklists:GQL.Checklist[];
    readonly back:() => void;
    readonly backToEdit:() => void;
}
export const UploadModalValidate = ({ back, backToEdit, json, existingChecklists }:Props) => {

    const [ checklist, setChecklist ] = useState<GQL.Checklist>();
    const [ existingChecklist, setExistingChecklist ] = useState<GQL.Checklist>();
    const [ reasons, setReasons ] = useState<string[]>([]);

    const {
        updating,
        selectNewItem:createEmptyChecklist,
        selectItem,
        updateItem:update,
        commitItem:save
    } = useZStateList(rule.zChecklists);

    const createNew = async () => {
        if (!checklist) throw new Error('Expected checklist');
        createEmptyChecklist(); // create empty new modelInfo
        update(rule.renumberChecklistItems(checklist));
        await save();
        modal.close();
    }

    const replaceChecklist = async () => {
        if (!checklist) throw new Error('Expected checklist');
        if (!existingChecklist) throw new Error('Expected existingChecklist');
        if (!selectItem(existingChecklist)) throw new Error('Expected existingChecklist to be found in zList');
        update(rule.renumberChecklistItems(checklist));
        await save();
        modal.close();
    }

    useEffect(() => {
        let obj:any;
        try {
            obj = JSON.parse(json);
        } catch (err) {
            setReasons(['Malformed JSON -- could not parse!']);
            return;
        }
        const invalidReasons = rule.isValidChecklist(obj);
        if (invalidReasons.length > 0) {
            setReasons(invalidReasons);
            return;
        }
        const model = rule.getModelById(obj.modelId);
        if (!model) {
            setReasons(['Invalid modelId specified: ' + obj.modelId]);
            return;
        }
        setChecklist(obj);

        const sameRefIdDiffId = existingChecklists.find(x => obj.refId && x.id !== obj.id && x.refId === obj.refId);
        if (sameRefIdDiffId) {
            setReasons(['Another checklist with the same refId already exists: ' + obj.refId]);
            return;
        }

        const m = existingChecklists.find(x => x.id === obj.id);
        if (m) {
            setExistingChecklist(m);
        } else {
            setExistingChecklist(undefined);
        }
    }, [json, existingChecklists])

    if (reasons.length > 0) {
        return (
            <>
                <Box mt="2">
                    <InvalidJson reasons={reasons} />
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            icon="arrow-left"
                            type="default"
                            onClick={backToEdit}
                        >Back</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    if (!checklist) return <></>;

    if (existingChecklist) {
        return (
            <>
                <Box mt="2">
                    A checklist already exists with the id you have provided!<br/>
                    Do you want to replace <Text fg="loud">{ existingChecklist.name || 'Untitled Checklist' }</Text>?
                </Box>
                <ModalFooter>
                    <FlexVCenter m="2" justifyContent="flex-end">
                        <Button
                            type="secondary"
                            icon="arrow-left"
                            mr="1"
                            onClick={back}
                        >Back</Button>
                        <Button
                            busy={updating}
                            type="default"
                            onClick={() => replaceChecklist()}
                        >Yes, Replace Checklist</Button>
                    </FlexVCenter>
                </ModalFooter>
            </>
        )
    }
    if (checklist?.id) {
        return (
            <>
                <Box mt="2">
                    Unknown checklist id (<Text fg="loud">{ checklist.id }</Text>) specified.<br/>
                    If you are trying to upload a new checklist, remove the id and try again.
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            type="default"
                            icon="arrow-left"
                            onClick={backToEdit}
                        >Back</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    return (
        <>
            <Box mt="2">
                This will create a NEW checklist, <Text fg="loud">{ checklist.name }</Text>.<br/>
                Are you sure you want to create a brand new checklist?
            </Box>
            <ModalFooter>
                <FlexVCenter m="2" justifyContent="flex-end">
                    <Button
                        type="secondary"
                        mr="1"
                        onClick={back}
                    >Back</Button>
                    <Button
                        busy={updating}
                        type="default"
                        onClick={() => createNew()}
                    >Yes, Create It</Button>
                </FlexVCenter>
            </ModalFooter>
        </>
    )
}

