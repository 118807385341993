import { useRef, useEffect } from 'react';

// check dep arrays changed using Object.is (same way react does...i think)
const checkDepsChanged = (curr?:any[], prev?:any[]) => {
    if (!curr) return false; // if curr nothing, say no change.
    if (!prev) return true; // init render
    if (curr.length !== prev.length) return true;
    if (curr.length === 0) return false;
    for (let i=0; i < curr.length; i++) {
        if (!Object.is(curr[i], prev[i])) return true;
    }
    return false;
}

export const useDepsChanged = (deps:any[]|undefined):boolean => {
    const lastDepsRef = useRef<any[]|undefined>(undefined);
    useEffect(() => {
        lastDepsRef.current = deps; // after every render, update last
    })
    return checkDepsChanged(deps, lastDepsRef.current)
}