import { Box, CssGridBox } from 'mui';
import { FieldHint, HintedField } from 'raccoon-engine';
import React from 'react';

interface Args<T extends HintedField>
{
	fieldHint?:FieldHint<T>
}

export const HintDetails = <T extends HintedField> (props:Args<T>) => {
	const { fieldHint } = props
	if (!fieldHint) return <></>
	const { interested } = fieldHint
	if (!interested) return <></>
	const entries = Object.entries(fieldHint).filter(e => e[0] !== "interested")
	const asText = (s:any) => {
		return JSON.stringify(s).replace(/"/g, "")
	}
	return (<Box p="3">
		<CssGridBox cols={2}>
			<Box fontWeight="thin">Interested</Box>
			<Box>{interested}</Box>
			{
				entries.map((e, i) => <React.Fragment key={i}>
					<Box fontWeight="thin">{e[0]} </Box>
					<Box>{asText(e[1])}</Box>
				</React.Fragment>)
			}
		</CssGridBox>
	</Box>)
	
}
