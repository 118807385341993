import React, { useRef, useState, useEffect } from 'react';
import { Text, Button, Box, FlexVCenter, Flex, Icon } from 'mui';
import { useSafeState } from 'hooks';
import { utils } from 'services';
// If you import these and don't use them, you'll get an error.
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver'; // NOTE: important if using json editor, fixes worker not found issue.
import "ace-builds/src-noconflict/mode-json";

// cobalt, dracula, nord_dark
// try them all here! https://ace.c9.io/build/kitchen-sink.html
import "ace-builds/src-noconflict/theme-dracula";

const isValidJson = (x:string) => {
    try {
        JSON.parse(x);
    } catch (err) {
        return false;
    }
    return true;
}


interface Props {
    readonly label:string;
    readonly updating:boolean;
    // Either you specify doc OR initJson
    readonly doc?:any;
    readonly initJson?:string;

    readonly onChange?:(x:string) => void;
    readonly onClose?:() => void;
    readonly onRefresh?:() => void;
    readonly onSave:(json:string) => void;
    readonly saveLabel?:string;
    readonly height?:number;
}
export const JsonEditor = ({
    label,
    doc,
    initJson,
    onChange,
    onClose,
    onRefresh,
    updating,
    onSave,
    height,
    saveLabel="Save"
}:Props) => {

    const [ validJson, setValidJson ] = useSafeState<boolean>(true);

    const [ localJson, setLocalJson ] = useSafeState<string>(() => initJson || '{}');
    
    const div = useRef<HTMLDivElement>(null);    
    const checkFn = useRef((x:string) => {});

    const h = height ?? (window.innerHeight - (div.current?.offsetTop ?? 100) - 16);

    const onValChange = (x:string) => {
        console.log('val change', x);
        setLocalJson(x);
        if (onChange) onChange(x);
        checkFn.current(x);
    }

    useEffect(() => {
        if (doc === undefined) return;
        const docJson = JSON.stringify(doc, null, 4);
        setLocalJson(docJson);
        setValidJson(true);
    }, [doc])

    useEffect(() => {
        // A debounced check to see if valid (so we aren't constantly json parsing)
        const chk = utils.debounce((s:string) => {
            setValidJson(isValidJson(s));
        }, 200);
        checkFn.current = chk;
    }, [])


    return (
        <>
            <Box mb="half">
                <Flex justifyContent="space-between" alignItems="flex-end">
                    <Box fg="default" flex="1">{ label }</Box>
                    <FlexVCenter flex="1" justifyContent="center">
                        { validJson && (
                            <>
                                <Icon fg="success" name="check" mr="half" />
                                Valid JSON
                            </>
                        ) }
                        { !validJson && (
                            <>
                                <Icon fg="danger" name="alert-triangle" mr="half"/>
                                <Text fg="danger">Invalid JSON</Text>
                            </>
                        ) }
                    </FlexVCenter>
                    <FlexVCenter flex="1" justifyContent="flex-end">
                        { onClose && (
                            <Button
                                size="xs"
                                mr="1"
                                type="secondary"
                                onClick={onClose}
                            >Close</Button>
                        ) }
                        { onRefresh && (
                            <Button
                                size="xs"
                                mr="1"
                                type="secondary"
                                onClick={onRefresh}
                            >Reload</Button>
                        ) }
                        <Button
                            busy={updating}
                            onClick={() => onSave(localJson)}
                            size="xs"
                            type={validJson ? 'default' : 'disabled'}
                        >{ saveLabel }</Button>
                    </FlexVCenter>
                    
                </Flex>
            </Box>

            <Box flex="1">
                <Box pos="relative" ref={div} bg="card">
                    <AceEditor
                        style={{ border: '2px solid #000' }}
                        width="100%"
                        height={h + 'px'}
                        fontSize={16}
                        mode="json"
                        theme="dracula"
                        value={localJson}
                        onChange={onValChange}
                    />
                </Box>
            </Box>
        </>
    )
}
