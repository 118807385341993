import React, { useState } from 'react';
import { useZStateList } from 'hooks';
import { rule } from 'services';
import { SamplesTable } from './samples-table';
import { SamplesTableFilters } from './samples-table-filters';

export const Samples = () => {

    const [ filterText, setFilterText ] = useState('');
    const { data:samples } = useZStateList(rule.zModelSamples);
    if (!samples) throw new Error('Expected samples');

    const unfiltered = rule.toSamplesWithModels(samples);
    const txt = filterText.trim().toLowerCase();
    // On every render for now...if gets slow, memo, or, in useeffect
    const filtered = unfiltered.filter(x => {
        return x.sample.name.toLowerCase().includes(txt)
            || x.sample.description?.toLowerCase().includes(txt)
            || x.model?.name.toLowerCase().includes(txt);
    })

    return (
        <>
            <SamplesTableFilters
                samples={filtered}
                filterText={filterText}
                setFilterText={setFilterText}
            />
            <SamplesTable samples={filtered} />
        </>
    )
}