import { Box } from "mui/components/box/box"
import { DataTable, InternalPricing } from "raccoon-engine"
import React, { useMemo } from 'react';
import { PricingCsvViewer, } from "routes/alpha/flows/flow/edit/tasks/pricing/pricing-cvs-viewer"
import { pricingModules } from './sample-data/llpa-data-table'

export const CellHost = () => {
	const module:InternalPricing = pricingModules[0] as any
	const { csv, hints } = module
	const { dataTable, hintTable } = useMemo(
		() => {
			return {
				dataTable: new DataTable({ data: csv }),
				hintTable: new DataTable({ data: hints })
			}
		},
		[csv, hints]
	)
	const onRemove = () => {}
	const onUnsavedData = () => {}
	return <Box>
		
		<PricingCsvViewer onUnsavedData={onUnsavedData} onRemove={onRemove}
		                  module={module}/>
	</Box>
}

