import { Box, Flex, IconButton, BloombergPad } from "mui";
import React, { useState } from "react";
import { CsvTable } from "./csv-table"
import tableSvg from './svg/table-color.svg'
import {InternalPricing} from  'raccoon-engine'

interface Args {
	module:InternalPricing;
	onRemove:Function;
	onUnsavedData:(id:string, csv:any[][], hints:any[][]) => void
}


export const PricingCsvViewer = (props:Args) => {
	const [expanded, setExpanded] = useState(false);
	const {
		module: { id, type, csv },
		onRemove,
	} = props;
	
	return (
		<Box bg="card" p="3">
			<Flex flexDirection="row" justifyContent="flex-start">
				{expanded && (
					<IconButton
						name="chevron-up"
						onClick={() => setExpanded(false)}
					/>
				)}
				{!expanded && (
					<IconButton
						name="chevron-down"
						onClick={() => setExpanded(true)}
					/>
				)}
				<Box ml="2">
					<BloombergPad
						category="type"
						title={type!.toUpperCase()}
						size="sm"
					/>
				</Box>
				<Box ml="2">
					<BloombergPad category="ID" title={id} size="sm"/>
				</Box>
				<Flex
					flexDirection="row"
					justifyContent="flex-end"
					style={{ flexGrow: 1 }}
				>
					<IconButton name="trash" onClick={() => onRemove()}/>
				</Flex>
			</Flex>
			
			{expanded && (
				<div className="data-panel">
					<Box bg="inner-card" p="3" mt="2">
						<div className="instruction">
							<img className="module-icon" src={tableSvg} alt="icon"/>
							<div>
								We are trying our best to determine the data. The
								goal is to correctly interpret as conditions. Please
								look close on our interpretation and run some test.
							
							</div>
						</div>
					</Box>
					
					<div className="raw-data">
						<CsvTable id={id}
						          onUnsavedData={props.onUnsavedData} module={props.module}/>
					</div>
				
				</div>
			)}
		</Box>
	);
};


