import React, { useState, useEffect } from 'react';
import {
    usePageSortTable,
    TablePageButtons,
    SortableTableHeader,
    Box,
    FlexVCenter,
    Icon,
    modal
}  from 'mui';

import { CheckItem } from 'raccoon-engine';
import { ExpContext } from 'exp';
import { GQL } from 'market-dto';
import { ChecklistTableExp } from './checklist-table-exp';
import { ConclusionItem } from './conclusion-item';
import { ConclusionDict } from './types';
import { ChecklistTableRow } from './checklist-table-row';

interface Props {
    readonly filteredItems:CheckItem[];
    readonly sampleData:any;
    readonly checklist:GQL.Checklist;
    readonly expCtx:ExpContext;
    readonly onSelect:(item:CheckItem) => void;
    readonly onClone:(item:CheckItem, intoIndex:number) => void;
}
export const ChecklistTable = ({ checklist, expCtx, sampleData, filteredItems, onSelect, onClone }:Props) => {

    const [ allItems, setAllItems ] = useState<CheckItem[]>([]);
    const [ nHover, setNHover ] = useState(-1);

    useEffect(() => {
        setAllItems(checklist.items);
    }, [checklist])

    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<CheckItem, keyof CheckItem>({
        rows: filteredItems,
        keyFn: row => {
            // another way of saying, we depend on everything
            return JSON.stringify(row)
        },
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 10
        }
    })

    if (!expCtx) return null;

    const confirmClone = (item:CheckItem, itemIndex:number) => {
        modal.confirm({
            title: 'Are you sure you want to clone this checklist item?',
            cancelLabel: 'Nevermind',
            okLabel: 'Yes',
            ok: () => onClone(item, itemIndex+1)
        })
    }

    return (
        <>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="id" render="Id" />
                        <th align="left">Expressions</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.length == 0 && (
                        <tr>
                            <td colSpan={2}>
                                <Box fg="faded" textStyle="italic">No checklist items to display</Box>
                            </td>
                        </tr>
                     ) }
                    { sortedRows.length > 0 && sortedRows.map((item, n) => (
                        <ChecklistTableRow
                            confirmClone={confirmClone}
                            expCtx={expCtx}
                            item={item}
                            n={n}
                            nHover={nHover}
                            onSelect={onSelect}
                            sampleData={sampleData}
                            setNHover={setNHover}
                            key={item.id}
                        />
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}


/*
                        return (
                            <tr key={item.id} className="valign-top">
                                <td style={{ width: '12%', paddingRight:0 }}>
                                    <FlexVCenter
                                        justifyContent="space-between"
                                        style={{ whiteSpace:'nowrap' }}
                                        onMouseOver={() => setNHover(n)}
                                        onMouseOut={() => setNHover(-1)}
                                    >
                                        <Box
                                            mt="half"
                                            fgHover="link"
                                            cursor="pointer"
                                            onClick={() => onSelect(item)}
                                        >{ item.id }</Box>
                                        <Box visibility={n === nHover ? 'visible' : 'hidden'}>
                                            <Icon
                                                ml="half"
                                                title="Clone Item"
                                                name="copy"
                                                cursor="pointer"
                                                fg="default"
                                                fgHover="loud"
                                                onClick={() => confirmClone(item, n)}
                                            />
                                        </Box>
                                    </FlexVCenter>
                                </td>
                                <td>
                                    { item.preconditions && item.preconditions.map((expStr, n) => {
                                        if (n === item.preconditions!.length - 1) {
                                            // Only last one should have the border below it.
                                            return (
                                                <FlexVCenter
                                                    key={n}
                                                    borderColor="alert"
                                                    borderSides="bottom"
                                                    borderWidth="thin"
                                                    pb="1"
                                                    mb="1"
                                                >
                                                    <ChecklistTableExp
                                                        expStr={expStr}
                                                        innerLabel="Precondition"
                                                        expCtx={expCtx}
                                                        runData={sampleData}
                                                    />
                                                </FlexVCenter>
                                            )
                                        } else {
                                            return (
                                                <ChecklistTableExp
                                                    key={n}
                                                    expStr={expStr}
                                                    innerLabel="Precondition"
                                                    expCtx={expCtx}
                                                    runData={sampleData}
                                                />
                                            )
                                        }
                                    }) }

                                    { item.expressions.map((expStr, n) => (
                                        <ChecklistTableExp
                                            key={n}
                                            expStr={expStr}
                                            expCtx={expCtx}
                                            runData={sampleData}
                                        />
                                    )) }

                                    { item.conclusion && Object.keys(item.conclusion).length > 0 && (
                                        <Box
                                            borderColor="alert"
                                            borderSides="top"
                                            borderWidth="thin"
                                            pt="1"
                                            mt="1"
                                        >
                                            { Object.entries(item.conclusion as ConclusionDict).map(([ id, val ]) => (
                                                <ConclusionItem key={id} id={id} val={val} expCtx={expCtx} />
                                            )) }
                                        </Box>
                                    ) }
                                    { item.elseConclusion && Object.keys(item.elseConclusion).length > 0 && (
                                        <Box
                                            borderColor="alert"
                                            borderSides="top"
                                            borderWidth="thin"
                                            pt="1"
                                            mt="1"
                                        >
                                            { Object.entries(item.elseConclusion as ConclusionDict).map(([ id, val ], n) => (
                                                <FlexVCenter key={id}>
                                                    <Box fontSize="sm" fg="faded" mr="half" textStyle="italic">else</Box>
                                                    <ConclusionItem id={id} val={val} expCtx={expCtx} />
                                                </FlexVCenter>
                                            )) }
                                        </Box>
                                    ) }
                                </td>
                            </tr>
                        )

*/