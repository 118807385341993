import { ExpContextField, ExpFieldWithValue } from './exp-types';
import { FlatJSONSchema, FlatJSONSchemaField } from 'market-dto';
import { toFieldsFromSchema } from './to-fields-from-schema';

const randomString = () => {
    const arr = [];
    for (let i=0; i < 10; i++) arr.push(String.fromCharCode(97+Math.floor(Math.random() * 26)))
    return arr.join('');
}

// dumb stupid sample data generator
const getVal = (f:FlatJSONSchemaField):string|number|boolean => {
    switch (f.type) {
        case 'string': {
            if (f.enum) {
                return f.enum[Math.floor(Math.random() * f.enum.length)];
            } else {
                return randomString();
            }
        }
        case 'number': {
            return Math.random() * 10000;
        }
        case 'integer': {
            return Math.floor(Math.random() * 1000);
        }
        case 'boolean': {
            return Math.random() >= 0.5;
        }
    }
    return '';
}

export const genRandomSampleData = (schema:FlatJSONSchema):any => {
    const fields = toFieldsFromSchema(schema);
    const data:{[x:string]:string|number|boolean} = {};
    fields.forEach(f => {
        const { schema } = f;
        if (!schema.type) throw new Error('Missing type on field!');
        data[f.id] = getVal(schema);
    })
    return data;
}

