import React from 'react';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';
import { Box } from 'mui';
import { ListModels } from './models/list-models';
import { RuleMainMenu } from './rule-main-menu';
import { EditChecklist } from './checklists/edit-checklist';
import { ListChecklists } from './checklists/list-checklists';
import { Model } from './models/model';
import { Samples } from './samples/samples';
import { EditSample } from './samples/edit-sample';
import { RuleModelDetails } from './models/rule-model/rule-model-details';

export const RulesRoutes = () => {


    // const match = useRouteMatch();
    const asdf = useMatch("models/:id");
    const hasModelId:boolean = useMatch("models/:id") ? true : false;
    const hasChecklistId:boolean = useMatch("checklists/:id") ? true : false;

    // I want to hide the main menu if we are drilled down into a specific model or checklist
    // const hasModelId:boolean = useRouteMatch({
    //     path: `${ match.path }/models/:id`,
    //     strict: true,
    //     exact: false
    // }) ? true : false;

    // const hasChecklistId:boolean = useRouteMatch({
    //     path: `${ match.path }/checklists/:id`,
    //     strict: true,
    //     exact: false
    // }) ? true : false;

    const showMainMenu = !hasModelId && !hasChecklistId;

    return (
        <Box my="2">
            { showMainMenu && <RuleMainMenu /> }
            <Routes>
                <Route path="samples" element={<Samples />} />
                <Route path="samples/:id" element={<EditSample />} />
                <Route path="models" element={<ListModels />} />
                <Route path="models/:id" element={<Model />} />
                {/* i stoppd heree */}
                <Route path="checklists" element={<ListChecklists />} />
                <Route path="checklists/:id" element={<EditChecklist />} />
                <Route path="*" element={<Navigate to="checklists" />}/>
                {/* <Route path={`${ match.path }`} exact render={ () => <Redirect to={`${ match.path }/checklists`} exact /> } /> */}
            </Routes>
        </Box>
    )
}



