import React, { useRef } from 'react';
import { IconButton } from 'mui';
import { Col } from './adj-table.service';
import { useOnClickOutside } from 'hooks';
import { AdjTableCol } from './adj-table-col';


interface Props {
    readonly cols:Array<Col>;
    readonly rowIndex:number;
    readonly editting:boolean;
    readonly setEditRow:(rowIndex:number) => any;
    readonly setVal:(val:any, rowIndex:number, colIndex:number) => any;
}

export const AdjTableRow = ({ cols, rowIndex, editting, setEditRow, setVal }:Props) => {

    const trRef = useRef<HTMLTableRowElement>(null);
    useOnClickOutside(trRef, () => {
        if (editting) setEditRow(-1);
    })

    if (cols.length === 0) throw 'empty row';

    const lastCol = cols[cols.length-1];
    // TODO: showEdit needs to be more fancy. What if that leaf val isn't editable?
    const showEdit = lastCol.type === 'leaf-val' || lastCol.type === 'branch-val'  || lastCol.type === 'val';

    return (
        <tr key={'r' + rowIndex} ref={trRef}>
            { cols.map((col, colIndex) => (
                <AdjTableCol
                    key={'r' + rowIndex + 'c' + colIndex}
                    col={col}
                    colIndex={colIndex}
                    rowIndex={rowIndex}
                    editting={editting}
                    setVal={setVal}
                />
            ))}
            { showEdit && (
                <td className="edit-cell">
                    <IconButton name="edit" onClick={() => setEditRow(rowIndex)} />
                </td>
            )}
        </tr>
    )
}