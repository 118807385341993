import React, { useState, useEffect } from 'react';
import { Box, Button, TableLikeGrid, FlexVCenter, Icon, openConfirmModal, Text, Input, Dropdown, DropdownItem } from 'mui';
import { ExpContext, ExpressionEditorState, ExpEvalStatus, getInitExpEditorState, ExpWarning } from 'exp';
import { EditorContainer } from './editor-container';
import { EditorPassFailCols } from './editor-pass-fail-cols';
import { ChecklistItemIdRow } from './checklist-item-id-row';
import { ChecklistItemDescRow } from './checklist-item-desc-row';
import { CheckItem } from 'raccoon-engine';
import { Warnings } from './warnings';
import { GQL } from 'market-dto';
import { getNextSequentialId } from './get-next-sequential-id';
import { ConclusionSection } from './conclusion-section';
import { ConclusionDict } from './types';
import { ExpSubmitValues } from './types';

interface ExpDetails {
    readonly keyIndex:number; // For react to know "this is unique" despite looking like what it had been...re-render
    readonly isPreCond:boolean;
    readonly expIndex:number;
    readonly expState:ExpressionEditorState;
    readonly initVal:string;
    readonly evalStatus?:ExpEvalStatus;
}

// TODO: change meaning of focusIndex

const getWarnings = (focusIndex:number|null, combinedExpDetails:ExpDetails[]):ExpWarning[] => {
    if (focusIndex === null) return [];
    if (focusIndex === -2) return [];
    if (focusIndex === -1) return [];
    return combinedExpDetails[focusIndex].expState.warnings;
    // if (focusIndex === -1) return preCond.expState.warnings;
    // return exps[focusIndex].expState.warnings;
}

const toExpDetails = (expStr:string, expIndex:number, expCtx:ExpContext, isPreCond:boolean):ExpDetails => {
    return {
        isPreCond,
        keyIndex:getKeyIndex(),
        expIndex,
        expState:getInitExpEditorState(expStr, expCtx),
        initVal:expStr
    }
}

let keyIndex = 0;
const getKeyIndex = ():number => {
    keyIndex++;
    return keyIndex;
}

interface Props {
    readonly checklist:GQL.Checklist;
    readonly editItem?:CheckItem;
    readonly expCtx:ExpContext;
    readonly sampleData:any;
    readonly onCancel:() => void;
    readonly onRemove:(item:CheckItem) => void;
    readonly onSubmit:(props:ExpSubmitValues
        // id:string,
        // desc:string,
        // preConds:string[],
        // exps:string[],
        // conclusion:ConclusionDict,
        // elseConclusion:ConclusionDict,
        // editItem?:CheckItem
    ) => void;
}
export const ChecklistItemLogicEditor = ({ checklist, expCtx, sampleData, onSubmit, editItem, onCancel, onRemove }:Props) => {

    // We must rethink this.
    // focus index:
    // -2 == id input
    // -1 == precondition <-- GET RID OF THIS
    // 0 through n == expression 0 through n (acutally, this all breaks now...)

    const [ focusIndex, setFocusIndex ] = useState<number | null>(-2);
    const [ checklistItemId, setChecklistItemId ] = useState<string>('');
    const [ checklistItemDesc, setChecklistItemDesc ] = useState<string>('');
    const [ conclusion, setConclusion ] = useState<ConclusionDict>({});
    const [ elseConclusion, setElseConclusion ] = useState<ConclusionDict>({});
    const [ validId, setValidId ] = useState<boolean>(false);

    const createDetails = (expStr:string, expIndex:number, isPreCond:boolean):ExpDetails => {
        return {
            isPreCond,
            initVal: expStr,
            expIndex,
            keyIndex: getKeyIndex(),
            expState: getInitExpEditorState(expStr, expCtx)
        }
    }

    // const [ preConds, setPreConds ] = useState<ExpDetails[]>([createDetails('', 0, true)]);
    const [ exps, setExps ] = useState<ExpDetails[]>([
        createDetails('', 1, true), // precond
        createDetails('', 1, false) // normal condition
    ])

    useEffect(() => {
        if (editItem) {
            // take these preconditions and turn em into expdetails
            setFocusIndex(-2); // focus back on ID
            // setPreConds(toExpDetails(editItem.precondition ?? '', -1, expCtx));
            const preconditions = editItem.preconditions ?? [''];

            // setPreConds(arr.map((x, n) => toExpDetails(x, n, expCtx, true)));

            setExps([
                ...preconditions.map((x, n) => toExpDetails(x, n, expCtx, true)),
                ...editItem.expressions.map((x, n) => toExpDetails(x, n + preconditions.length, expCtx, false))
            ])

            setChecklistItemId(editItem.id);
            setChecklistItemDesc(editItem.description ?? '');
            setConclusion(editItem.conclusion ?? {});
            setElseConclusion(editItem.elseConclusion ?? {});
        } else {
            reset();
        }
    }, [editItem, checklist])

    useEffect(() => {
        const trimmed = checklistItemId.trim().toLowerCase();
        const takenIds = checklist.items
            .filter(item => item.id !== editItem?.id)
            .map(item => item.id.trim().toLowerCase());
        setValidId(trimmed ? !takenIds.includes(trimmed) : false);
    }, [checklistItemId])

    const reset = () => {
        setFocusIndex(-2); // focus back on ID

        // setPreConds([createDetails('', -1, true)]);

        setExps([
            createDetails('', 0, true), // precond
            createDetails('', 1, false) // normal condition
        ])
        // setExps([createDetails('', 0, true)]);

        const takenIds = checklist.items
            .filter(item => item.id !== editItem?.id)
            .map(item => item.id.trim().toLowerCase());
        const nextId = getNextSequentialId(takenIds);
        setChecklistItemId(nextId);
        setChecklistItemDesc('');
        setConclusion({});
        setElseConclusion({});
    }

    const cancel = () => {
        if (editItem) onCancel();
        reset();
    }

    // no longer needed, preconds and exps are now ONE.

    // const preCondChange = (exp:ExpDetails, expState:ExpressionEditorState, evalStatus:ExpEvalStatus) => {
    //     // setPreCond({ ...preCond, expState: x, evalStatus })
    //     // This just changed!
    //     setPreConds(preConds.map((x):ExpDetails => x !== exp ? x : { ...exp, expState, evalStatus }));
    // }

    const expChange = (exp:ExpDetails, expState:ExpressionEditorState, evalStatus:ExpEvalStatus) => {
        setExps(exps.map((x):ExpDetails => x !== exp ? x : { ...exp, expState, evalStatus }));
    }

    // Special merging of eval statuses for overall eval status
    const allStatuses = [
        // preCond.evalStatus,
        // ...preConds.map(x => x.evalStatus),
        ...exps.map(x => x.evalStatus)
    ].filter(x => x) as ExpEvalStatus[];

    const isBlank = allStatuses.some(x => !x.isBlank) ? false : true;
    const canEval = !isBlank && !allStatuses.some(x => !x.canEval && !x.isBlank);
    const validSyntax = !allStatuses.some(x => !x.validSyntax);
    // const validId = checklistItemId.trim() !== '';
    const canSubmit = canEval && validId && exps.some(x => x.evalStatus && !x.evalStatus.isBlank);

    const evalStatus:ExpEvalStatus = {
        isBlank,
        canEval,
        validSyntax,
        // Now AND all the eval results together!
        evalResult: allStatuses.some(x => !x.evalResult && !x.isBlank) ? false : true
    }

    const precondCount = exps.filter(x => x.isPreCond).length;

    const expSubmit = (exp:ExpDetails, expIndex:number, tabKey:boolean, ctrlDown:boolean, shiftDown:boolean) => {

        // ctrl enter goes here i think...?
        console.log('tab key is here, how about ctrl held down?');

        if (expIndex === exps.length-1) {
            // Last one ... are we valid, and, are we leaving a blank one?
            if (canSubmit && exp.evalStatus && exp.evalStatus.isBlank) {
                submit();
                return;
            } else {
                setExps(exps.concat(createDetails('', expIndex+1, false)));
            }
        }
        // Are we in the last precondition? If so, insert a new precondition if ctrlDown.
        if (precondCount-1 === expIndex && !tabKey && (ctrlDown || shiftDown)) {
            setExps([
                ...exps.filter(x => x.isPreCond),
                createDetails('', expIndex+1, true),
                ...exps.filter(x => !x.isPreCond)
            ])
        }

        setFocusIndex(expIndex+1);
    }

    

    const submit = () => {
        if (!canSubmit) return;

        const preConds = exps.filter(x => x.isPreCond);
        const conds = exps.filter(x => !x.isPreCond);

        console.log('else conclusion:', elseConclusion);

        onSubmit({
            id: checklistItemId,
            description: checklistItemDesc.trim(),
            preConds: preConds.map(x => x.expState!.exp).filter(x => x.trim()),
            exps: conds.map(x => x.expState!.exp).filter(x => x.trim()),
            conclusion,
            elseConclusion,
            editItem
        })

        if (!editItem) reset();
    }

    const blurFrom = (n:number) => setFocusIndex(null);

    const trash = () => {
        if (!editItem) return;
        openConfirmModal({
            title: <>
                <Box fg="default">
                    Are you sure you want to remove
                </Box>
                <Box fg="default" mt="1">
                    <Text fg="loud" fontWeight="bold">{ editItem.id }</Text>?
                </Box>
            </>,
            ok: () => onRemove(editItem)
        })
    }

    const warnings = getWarnings(focusIndex, exps);
    const lastAndBlank = focusIndex === exps.length-1 && exps[focusIndex].expState.exp.trim() === '';
    const inPrecond = focusIndex !== null && focusIndex >= 0 && exps[focusIndex].isPreCond;




    return (
        <>
            <Warnings warnings={warnings} />

            <FlexVCenter bg="inner-card" roundedEdges="top" p="1" justifyContent="space-between">
                <Box>
                    { editItem ? 'Edit ' : 'New ' } Checklist Item
                </Box>
                <Box mr="half">
                    { editItem && (
                        <Icon name="trash" fg="faded" fgHover="link" onClick={trash} cursor="pointer" />
                    ) }
                </Box>
            </FlexVCenter>
            <Box bg="card" roundedEdges="bottom" mb="2" p="2">

                <TableLikeGrid colSizes={['auto', '*', 'auto', 'auto']}>

                    <ChecklistItemIdRow
                        id={checklistItemId}
                        onChange={x => { setChecklistItemId(x); }}
                        onEnter={() => setFocusIndex(0)}
                        taken={!validId && checklistItemId.trim() !== ''}
                        focus={focusIndex == -2}
                    />

                    <ChecklistItemDescRow
                        desc={checklistItemDesc}
                        onChange={x => { setChecklistItemDesc(x); }}
                        onEnter={() => setFocusIndex(0)}
                        // focus={focusIndex == -2}
                    />

                    <Box>&nbsp;</Box>
                    <Box pl="1" fontSize="sm" fg="faded">Logic</Box>
                    <Box fontSize="sm" fg="faded">Syntax</Box>
                    <Box fontSize="sm" fg="faded">Result</Box>

                    {/* <EditorContainer
                        key={preCond.keyIndex}
                        onBlur={() => blurFrom(-1)}
                        onFocus={() => setFocusIndex(-1)}
                        onChange={preCondChange}
                        onSubmit={() => setFocusIndex(0)}
                        expCtx={expCtx}
                        focus={focusIndex === -1}
                        initVal={preCond.initVal}
                        label="Precondition"
                        runData={sampleData}
                    /> */}

                    { exps.map((exp, expIndex) => (
                        <EditorContainer
                            key={exp.keyIndex}
                            onBlur={() => blurFrom(expIndex)}
                            onFocus={() => setFocusIndex(expIndex)}
                            onChange={(expState, evalStatus) => expChange(exp, expState, evalStatus)}
                            onSubmit={(tabKey, ctrlDown, shiftDown) => expSubmit(exp, expIndex, tabKey, ctrlDown, shiftDown)}
                            expCtx={expCtx}
                            focus={focusIndex === expIndex}
                            initVal={exp.initVal}
                            label={exp.isPreCond ? 'Precondition ' + (expIndex+1) : 'Expression ' + (expIndex + 1 - precondCount)}
                            runData={sampleData}
                        />
                    )) }

                    <Box></Box>
                    <FlexVCenter justifyContent="space-between">
                        <Box flex="1" textAlign="left"  fg="faded" ml="1" textStyle="italic" fontSize="sm">
                            { canSubmit && lastAndBlank && 'Enter to submit' }
                            { inPrecond && 'Shift-enter to add precondition' }
                        </Box>
                        <Box flex="1" textAlign="right">
                            <Button type="link" size="sm" onClick={cancel}>
                                { editItem ? 'Cancel' : 'Clear' }
                            </Button>
                            <Button
                                type={canSubmit ? 'default' : 'disabled'}
                                size="sm"
                                ml="1"
                                onClick={submit}
                            >{ editItem ? 'Update' : 'Add' }</Button>
                        </Box>
                    </FlexVCenter>
                    <EditorPassFailCols evalStatus={evalStatus} />

                    <ConclusionSection
                        label="Conclusions"
                        expCtx={expCtx}
                        conclusion={conclusion}
                        setConclusion={setConclusion}
                    />

                    <ConclusionSection
                        label="Else Conclusions"
                        expCtx={expCtx}
                        conclusion={elseConclusion}
                        setConclusion={setElseConclusion}
                    />

                </TableLikeGrid>
                
            </Box>
        </>
    )
}