import React, { useState } from 'react';
import { useZStateList } from 'hooks';
import {
    SpinLabel,
    Alert,
    Button,
    IntoPortal,
    FlexVCenter,
    Box,
    Input,
    modal,
    Link,
    Text
} from 'mui';
import { SellerModal } from './seller-modal';
import { GQL } from 'market-dto';
import { sellers, marketSettings } from 'services';
import { ImportModal } from './import-modal';
import * as api from 'api';
import { useApiUpdate } from 'hooks';
import { SellersTable } from './sellers-table';

export const Sellers = () => {

    const { busy:importingFromCleanroom, update:importFromCleanroom } = useApiUpdate(async () => {
        const result = await api.importCleanroomSellers();
        await sellers.zSellers.refreshList();
    })
    const { fetching:loading, data:orgs } = useZStateList(sellers.zSellers);

    const [ filter, setFilter ] = useState<string>('');
    

    if (loading || !orgs) return <SpinLabel>Loading...</SpinLabel>

    const edit = (org:GQL.Org) => {
        modal.open({
            render: <SellerModal org={org} />,
            title: 'Seller Info'
        })
    }

    const openAddSellerModal = () => {
        modal.open({
            render: <SellerModal org={null} />,
            title: 'Seller Info'
        })
    }

    const openImportModal = () => {
        modal.open({
            title: 'Import Sellers',
            render: <ImportModal onImport={() => alert('a')} />,
            size: 'lg'
        })
    }

    const filterLower = filter.toLowerCase().trim();
    const filteredSellers = orgs.filter(x => {
        return x.name.toLowerCase().includes(filterLower) ||
            x.aliases.some(alias => alias.toLowerCase().includes(filterLower))
    })
    const hasValidCr = marketSettings.hasValidCREnvironment();

    return (
        <>
            <IntoPortal id="desk-portal">
                <FlexVCenter height="btn-row">

                        {/* <FlexVCenter
                            fontSize="sm"
                            bg="body"
                            py="half"
                            px="1"
                            borderSides="all"
                            borderColor="inner-card-bg"
                            borderStyle="solid"
                            borderWidth="thick"
                            roundedEdges="all"
                        >
                            <Box fg="normal">PROD</Box>
                            <Box>
                                <Icon name="share" fg="faded" ml="1" />
                            </Box>
                            
                        </FlexVCenter> */}
                    { hasValidCr && (
                        <Button
                            mr="1"
                            size="sm"
                            type="secondary"
                            onClick={importFromCleanroom}
                            busy={importingFromCleanroom}
                        >Import from Clean Room</Button>
                    ) }
                    <Button type="default" size="sm" onClick={openAddSellerModal}>Add Seller</Button>
                </FlexVCenter>
            </IntoPortal>

            <FlexVCenter mb="2" justifyContent="space-between">

                <FlexVCenter>
                    { orgs.length > 0 && (
                        <>
                            <Box mr="1">
                                <Input
                                    appendIcon="search"
                                    placeholder="Search..."
                                    value={filter}
                                    setValue={setFilter}
                                />
                            </Box>
                            <Box fg="default">
                                { filteredSellers.length }
                                <Text fg="faded">
                                    &nbsp;Seller{filteredSellers.length === 1 ? '' : 's'}
                                </Text>
                            </Box>
                        </>
                    )}
                    
                </FlexVCenter>
                <Box textAlign="right">
                    You can also <Link onClick={openImportModal}>import data from an existing spreadsheet</Link>.
                </Box>
            </FlexVCenter>

            { orgs.length === 0 && <Alert>No Sellers Found!</Alert> }
            { orgs.length > 0 && <SellersTable filteredSellers={filteredSellers} onEdit={edit} /> }

        </>
    );
}
