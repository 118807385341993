import { GQL, sheetMaker } from 'market-dto';
import { getPricingDebugHeadersAndRows } from '../pricing';
import { downloadFromArrayBuffer } from '../downloader';


export const genComparisonSheet = async (sheet:GQL.LoanModelSheet) => {
    const { headers, rows } = getPricingDebugHeadersAndRows(sheet);
    const arrBuf = await sheetMaker.makeSheet([ headers, ...rows ], "Pricing Comparison");
    const filename = sheet.id + '-pricing-comparison';
    downloadFromArrayBuffer(arrBuf, filename);
}