import { getJwt } from './get-jwt';
import { logout } from './logout';
import { hasJwtExpired } from './has-jwt-expired';

export const checkJwtExpired = () => {
    const jwt = getJwt();
    if (hasJwtExpired(jwt)) {
        logout();
    }
}

