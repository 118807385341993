import React, { useState, useEffect } from 'react';
import {
    Button,
    IconButton,
    Input,
    Box,
    BoundFrmInput,
    FlexVCenter,
    modal,
    setModalCfg
} from 'mui';
import { GQL } from 'market-dto';
import { useZStateList } from 'hooks';
import { sellers } from 'services';
import { labels } from 'market-dto';

type EditOrg = Pick<GQL.Org, "id" | "aliases" | "name" | "orgType" | "externalId" | "externalIdSource" | "symbol">;

const makeCopy = (x:any) => JSON.parse(JSON.stringify(x)); // TODO: we have a proper deep copy?


const getInitEditOrg = (org:GQL.Org|null):EditOrg => {
    if (org) return makeCopy(org);
    return {
        id: 'new',
        orgType: "Seller",
        name: '',
        aliases: [''],
        symbol: '',
        externalId: '',
        externalIdSource: ''
    }
}

interface Props {
    readonly org:GQL.Org|null;
}
export const SellerModal = ({ org }:Props) => {

    const [ data, setData ] = useState<EditOrg>(() => getInitEditOrg(org));

    const { updating:busy } = useZStateList(sellers.zSellers);

    const update = async () => {
        if (org === null) {
            await sellers.zSellers.apiInsertListItem(data);
        } else {
            await sellers.zSellers.apiUpdateListItem(data);
        }
        modal.close();
    }

    const updateAlias = (val:string, n:number) => {
        setData({
            ...data,
            aliases: data.aliases.map((a, m) => n === m ? val : a )
        })
    }

    const removeAlias = (n:number) => {
        setData({
            ...data,
            aliases: data.aliases.filter((a, m) => n !== m)
        })
    }

    const addAlias = () => {
        setModalCfg({ static: true });
        setData({
            ...data,
            aliases: data.aliases.concat('')
        })
    }

    const canSave = data.symbol && data.symbol.trim() !== '' && data.name.trim() !== '' && data.aliases.length > 0;

    return (
        <Box>
            
            <BoundFrmInput
                width="100%"
                label="Seller Name"
                bindTo={[ data, setData, x => x.name ]}
            />
            <BoundFrmInput
                width="100%"
                label={labels.byOrgField('symbol')}
                bindTo={[ data, setData, x => x.symbol ]}
            />
            <BoundFrmInput
                width="100%"
                label={labels.byOrgField('externalId')}
                bindTo={[ data, setData, x => x.externalId ]}
            />
            <BoundFrmInput
                width="100%"
                label={labels.byOrgField('externalIdSource')}
                bindTo={[ data, setData, x => x.externalIdSource ]}
            />

            <FlexVCenter ml="1" mb="1">
                <Box fg="faded">Aliases</Box>
                <Box flex="1" textAlign="right">
                    <IconButton size="sm" name="plus" onClick={addAlias} />
                </Box>
            </FlexVCenter>


            { data.aliases.map((alias, n) => (
                <FlexVCenter mb="1" key={n}>
                    <Box flex="1" pr="1">
                        <Input
                            value={alias}
                            setValue={val => updateAlias(val, n)}
                            placeholder="Alias"
                        />
                    </Box>
                    <Box textAlign="right">
                        <IconButton size="sm" name="trash" onClick={() => removeAlias(n)}/>
                    </Box>
                </FlexVCenter>
            )) }

            <FlexVCenter justifyContent="flex-end" mt="3">
                <Button type="secondary" onClick={modal.close}>Cancel</Button>
                <Button
                    type={ canSave ? 'default' : 'disabled' }
                    ml="1"
                    onClick={update}
                    busy={busy}
                >Save</Button>
            </FlexVCenter>

        </Box>
    )
}