import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';
import { LOAN_SOURCE_RETURNING } from '../loan/loan-source-returning';
import { util } from 'market-dto';


const QUERY = gql`
    query ($where: sheet_bool_exp!) {
        sheet(where: $where) {
            ${ RETURNING }
            loanSources {
                loanId
                row
            }
            seller
            {
                id
                name
                symbol
            }
        }
    }
`;

interface QueryResult {
    readonly sheet:[GQL.BidTapeModelSheet];
}

interface QueryInput {
    readonly where:GQL.WhereString;
}

export const fetchBidTapeModelSheet = async (sheetId:string):Promise<GQL.BidTapeModelSheet|undefined> => {

    // We're not actually pulling the loan docs!
    // And we're ONLY pulling the rows from loan source, not everything!
    // Just enough to recreate the original tape.

    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _eq: sheetId
            }
        }
    }, { delay: 0 }) // delay is for testing

    if (result.data.sheet.length !== 1) return;
    // Let's put the loanSouces there.
    return prepareSheet(result.data.sheet[0]);
}

const prepareSheet = (sheet:GQL.BidTapeModelSheet):GQL.BidTapeModelSheet => {
    return {
        ...sheet,
        loans: [], // we don't really care about this for now
        loanSources: util.sort(sheet.loanSources, x => x.loanId, "asc")
    }
}
