import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { FiltersAndButtons } from './filters-and-buttons';
import { LoanCard } from './loan-card/loan-card';
import { sheets, utils } from 'services';
import { useZState } from 'hooks';
import { IntoPortal } from 'mui';
import { HorizontalTapeSummary } from 'components';
import { PricingInfo } from 'services/pricing';

interface Props {
    readonly sheet:GQL.LoanModelSheet;
    readonly pricingInfo:PricingInfo;
}
export const Pricing = ({ sheet, pricingInfo }:Props) => {

    const { data:filters } = useZState(sheets.zFilters);
    const { data:checkedOffers } = useZState(sheets.zCheckedOffers);

    if (!filters) throw new Error('Expected filters');

    // You might ask, why not filtered loans in a z-state?
    // Good question. For now, just filters and current sheet (loans) in z-states.
    // Which means, components must filter and pass down the data.
    // Instead, we could add to filters the actual filtered loan array.

    const loans = sheets.applyLoanFilters(sheet.loans, filters);
    const canCheck = sheet.sheetSummary.sheetStatus === 'PRICING';

    // temporary until paste hits stuff is nice
    const checkedLoans = checkedOffers && loans.filter(loan => {
        return loan.offers && loan.offers.some(offer => {
            return (canCheck && offer.id === checkedOffers[loan.id]) || offer.selected
        })
    })
    const loanNumbersForPasting = checkedLoans ? checkedLoans.map(x => x.loanNumber).join('\n') : '';
    console.log('loanNumbers of those CHECKED loans (for pasting into hits)');
    console.log(loanNumbersForPasting);

    return (
        <>
            <IntoPortal id="tape-details-top-summary">
                { sheet && ( <HorizontalTapeSummary summary={sheet.sheetSummary!} />) }
            </IntoPortal>
            <FiltersAndButtons sheet={sheet} pricingInfo={pricingInfo} count={loans.length} />
            { loans.map(loan => <LoanCard key={loan.id} loan={loan} filters={filters} canCheck={canCheck} /> ) }
        </>
    )
}