import { query, gql } from "../../client"
import { StreamLog } from 'market-dto';


// i dislike "status.status", so, we rename "Status" to "execution" as it comes in

export interface PricingStatusAndLogs {
    readonly execution:StreamLog.Execution;
    readonly logs:StreamLog.Entry[];
}

interface GqlPricingStatusAndLogs {    
    readonly status:StreamLog.Execution;
    readonly logs:StreamLog.Entry[];
}

const QUERY = gql`
    query ($invocationId:String) {
        sync_invocation(invocationId: $invocationId)
    }
`;

interface QueryInput {
    readonly invocationId:string;
}

interface QueryOutput {
    readonly sync_invocation:GqlPricingStatusAndLogs;
}

export const fetchAgencyPricingStatus = async (invocationId:string):Promise<PricingStatusAndLogs | undefined> => {
    console.log('fetchAgencyPricingStatus pre');
    const result = await query<QueryInput, QueryOutput>(QUERY, { invocationId })
    if (!result.data.sync_invocation) return;
    console.log('fetchAgencyPricingStatus post');
    return {
        logs: result.data.sync_invocation.logs,
        execution: result.data.sync_invocation.status
    }
}
