import React from 'react';
import { Box } from '../box/box';
import { BgColor, SpacingSize, FontSize } from '../../types';

interface Props {
    readonly children:any;
    readonly bg?:BgColor;
    readonly p?:SpacingSize;
    readonly fontSize?:FontSize;
}
export const CodeBlock = ({ bg="card", p="2", fontSize, children }:Props) => {
    // For preserving whitespace, basically.
    // Not doing code coloring...but, we could! here is where we would.
    return (
        <Box bg={bg} p={p} fontSize={fontSize} roundedEdges="all" css="code-block">{ children }</Box>
    )
}
