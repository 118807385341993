import { useState, useCallback, useLayoutEffect, useRef } from "react";

export const useInViewport = () => {

    /*
        This is tricky.
        It was made specifically for loan cards, so the pricing page isn't slow when we filter loans.
        A few notes:

        1. Once a loan card is in the viewport, put it in the dom and never say it's NOT in the viewport
            in other words, once fully rendered via being in viewport, STAY fully rendered...
            the price has been incurred at that point.

        2. Check if we are in the viewport any time:
            a. any useLayoutEffect (any dom changes) are made to the component
            b. window scroll
            c. window resize

        3. Ignore horizontal off-screen-ness. just vertical. assume horizontally off-screen is ON-screen.

        It might be heavy handed with the useLayoutEffect checking every single time...
        A future enhancement could be to debounce the check...if that's ever a problem.

    */

    const [ inView, setInView ] = useState(false);
    const [ lastHeight, setLastHeight ] = useState(0);
    const onScrollResize = useRef<() => void>();
    const lastInView = useRef<boolean>(false);

    useLayoutEffect(() => {
        // any dom changes...and we hear about it here.
        if (onScrollResize.current) onScrollResize.current();
    })

    const ref = useCallback((div:HTMLDivElement) => {
        if (div) {
            // added to dom
            onScrollResize.current = () => check(div);
            window.addEventListener('scroll', onScrollResize.current);
            check(div);
        } else {
            // removed from dom
            if (onScrollResize.current) {
                window.removeEventListener('scroll', onScrollResize.current);
            }
        }
    }, [])

    const check = (div:HTMLDivElement) => {
        const rect = div.getBoundingClientRect();
        const vp = { top: 0, left: 0, bottom: window.innerHeight, right: window.innerWidth };
        const inY = (rect.top >= vp.top && rect.top <= vp.bottom) || (rect.bottom <= vp.bottom && rect.bottom >= vp.top);
        // Ignore left and right for now.
        if (inY) setLastHeight(rect.height);
        const result = lastInView.current || inY;
        lastInView.current = result;
        setInView(result); // never go back to false! once in viewport, stay in dom
    }

    return {
        ref,
        inView,
        lastHeight
    }
}
