import React, { useState, useEffect, useRef } from 'react';
import { Box, ButtonGroup, Button, Flex, Icon, usePageSortTable } from 'mui';
import { LoanTable } from './loan-table';
import { sheets, viewLoans } from 'services';
import { LoanField, GQL } from 'market-dto';
import { LoanTablePageButtons } from './loan-table-page-buttons';

const LOANS_PER_PAGE = 15;
const MAX_BUTTONS = 9; // keep it an odd number

interface Props {
    readonly sheet:GQL.Sheet;
    readonly loans:GQL.Loan[];
    readonly filters:sheets.LoanFilters;
}
export const LoanTableContainer = ({ sheet, loans, filters }:Props) => {

    const loansVs = viewLoans.toLoansAndValuesAndSources(loans);
    const tableRef = useRef<HTMLTableElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const pageSorted = usePageSortTable<viewLoans.LoanAndValuesAndSources, LoanField>({
        rows: loansVs,
        valFn: (row, colId) => row.loan[colId],
        keyFn: row => row.loan.id,
        paging: {
            maxButtons: MAX_BUTTONS,
            maxRowsPerPage: LOANS_PER_PAGE
        }
    })
    const { asc, sortedBy, sortBy, sortedRows, page, setPage, totalPages, buttons } = pageSorted;

    // useEffect(() => {
    //     if (!tableRef || !tableRef.current) return;
    //     if (!scrollRef || !scrollRef.current) return;
    //     const rect = tableRef.current.getBoundingClientRect();
    //     const vh = document.documentElement.clientHeight;
    //     const fudge = 16; // TODO: perhaps make this space2 + eventual footer height?
    //     scrollRef.current.style.maxHeight = (vh - rect.top - fudge) + 'px';
    // }, [tableRef, scrollRef]);

    return (
        <>
            <Box overflow="auto" mt="2" mb="2" ref={scrollRef}>
                <LoanTable loansVs={sortedRows} filters={filters} asc={asc} sortBy={sortBy} sortedBy={sortedBy} />
            </Box>
            <LoanTablePageButtons
                totalPages={totalPages}
                buttons={buttons}
                page={page}
                setPage={setPage}
            />
        </>
    )
}