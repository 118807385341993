import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Icon, SpinLabel } from 'mui';

interface Props {
	readonly cy?:string;
	readonly label?:string;
	readonly busy?:boolean;
	readonly multi?:boolean;
	readonly accept?:string;
	readonly onDrop:(files:File[]) => any;
}
export const FileDropArea = ({ cy, onDrop, multi, busy, accept, label="Drop tape here." }: Props) => {

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept,
		multiple: multi ? true : false
	})

	return (
		<span {...getRootProps()} style={{outline: 'none'}} data-cy={cy}>
            <Flex
	            flexDirection="column"
	            justifyContent="center"
	            alignItems="center"
	            borderSides="all"
	            roundedEdges="all"
	            height="card-sm"
	            p="2"
	            cursor="pointer"
	            bg={isDragActive ? "menu-item-active" : "transparent"}
	            borderColor={isDragActive ? "menu-active" : "alert"}
            >
                { busy && <SpinLabel>Loading...</SpinLabel> }
	            { !busy && (
		            <>
			            <input {...getInputProps()} /> {/* hidden input */}

			            <Box fontSize="xl"><Icon name="upload"/></Box>
			            <Box fontSize="2xl">{ label }</Box>
			            <Box fontSize="md">(or just click to upload)</Box>
		            </>
	            ) }
            </Flex>
        </span>
	)
}
