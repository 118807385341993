import React, { useState } from 'react';
import { FlexVCenter, Flex } from '../flex/flex';
import { Icon } from '../icon/icon';
import { Box } from '../box/box';
import { Input } from '../input/input';
import { InputWidth } from '../../types';

interface Props {
    readonly placeholder?:string;
    readonly width?:InputWidth;
    readonly items:string[];
    readonly onChange:(vals:string[]) => void;
}
export const Tags = ({ items, onChange, placeholder, width }:Props) => {
    
    const [ newTag, setNewTag ] = useState<string>('');
    const [ tagExistsIndex, setTagExistsIndex ] = useState<number>(-1);

    const valChange = (x:string) => {
        setTagExistsIndex(-1);
        setNewTag(x);
    }

    const addTagEnter = () => {
        const val = newTag.trim();
        if (val === '') {
            setTagExistsIndex(-1);
            return;
        }
        const lval = val.toLowerCase();
        const n = items.findIndex(x => x.toLowerCase() === lval);
        if (n > -1) {
            setTagExistsIndex(n);
            return;
        }
        setNewTag('');
        setTagExistsIndex(-1);
        onChange(items.concat(val));
    }

    const removeTag = (n:number) => {
        setTagExistsIndex(-1);
        onChange([...items.slice(0, n), ...items.slice(n + 1)]);
    }

    return (
            <>
            { items.length === 0 && (
                <Box fg="faded" textStyle="italic" mb="1">None</Box>
            ) }
            { items.length > 0 && (
                <Flex flexWrap="wrap" mb="half">
                    { items.map((tag, n) => {
                        return <FlexVCenter
                            key={n}
                            borderColor="card-bg"
                            borderStyle="solid"
                            borderWidth="thin"
                            borderSides="all"
                            bg="inner-card"
                            py="half"
                            px="1"
                            fontSize="sm"
                            roundedEdges="all"
                            mr="1"
                            my="half"
                            fg={tagExistsIndex === n ? 'danger' : 'default'}
                        >
                            <Box style={{ whiteSpace:'pre' }}>{ tag }</Box>
                            <Box fontSize="sm" fg="faded" ml="1" fgHover="link" cursor="pointer">
                                <Icon name="close" onClick={() => removeTag(n)} />
                            </Box>
                        </FlexVCenter>
                    }) }

                </Flex>
            ) }
            <Input
                width={width}
                placeholder={placeholder ?? 'Enter New Tag'}
                value={newTag}
                setValue={valChange}
                onEnter={addTagEnter}
            />
        </>
   )
}