import { getJobById } from './get-job-by-id';

const makeId = (s:string, n:number) => s + (n === 1 ? '' : '-' + n);

export const genJobId = (prefix:string):string => {
    // Count how many jobs begin with this prefix, add 1 to it if any exist, make that the id.
    // harhar, harhar-2, harhar-3, etc
    let n = 1;
    while (getJobById(makeId(prefix, n))) n++;
    return makeId(prefix, n);
}