import React, { useMemo } from 'react';
import { GQL } from 'market-dto';
import { fetchEligibilityClassifier, setEligibilityClassifier } from "api"
import { stripInternals } from "api/client"
import { Loading } from 'components';
import { useApiFetch, useApiUpdate } from "hooks"
import { Box, Button, Flex } from "mui"
import { IQualifier, IConstruct } from "raccoon-engine"
import { cannedConstructs } from "./canned-condition"
import { ConditionEditor } from "./condition-editor"
import { ConditionSelector } from "./condition-selector"
import { createSelectionHubs } from "./selection-hub"
import {  wording } from "./types";
import "./qualifier.scss"

const makeEliQualifier = (constructs:IConstruct[]):IQualifier[] => {
	return [{ qualifiedFor: "eligibility", constructs: constructs, classifierType: "MIXED" }]
}

interface Props {
	readonly task:GQL.FlatTask;
}
export const EligibilityClassifier = ({ task }:Props) => {	
	const { taskId } = task;
	const {
		loading,
		data: classifierData,
	} = useApiFetch(() => fetchEligibilityClassifier(taskId), [taskId]);
	
	const { busy: saving, update } = useApiUpdate(setEligibilityClassifier);
	
	const { candidates$, hub$, selected$, qualifier$ } = useMemo(() => {
		const starterQualifier = makeEliQualifier(classifierData?.constructs as any || [])
		const {
			candidates$,
			program$,
			hub$,
			selected$,
			qualifier$
		} = createSelectionHubs(starterQualifier, cannedConstructs)
		program$.next("eligibility")
		return { candidates$, hub$, selected$, qualifier$ }
	}, [classifierData])
	
	const onSave = async () => {
		const q = qualifier$.getValue().find(x => x.qualifiedFor === "eligibility")
		const patch = stripInternals({ ...classifierData, constructs: q?.constructs || [] }, true)
		const result = await update(patch as any)
	}
	
	return <Loading loading={loading} render={<>
		<Box bg="card" p="2" roundedEdges="all" shadow="card" fontSize="md">
			<Box fg="loud" fontWeight="bold" fontSize={"md"}>{wording.eligibilityTitle}</Box>
			<Box fg="form-label" fontWeight="thin"> {wording.eligibilityDescription}</Box>
			<Flex justifyContent="flex-end">
				<Button busy={saving} size="sm" type="default" onClick={onSave}>Save</Button>
			</Flex>

			<div className="qualifier-panel qualifier-panel-layout">
				<ConditionEditor selected$={selected$} hub$={hub$}/>
				<ConditionSelector candidates$={candidates$} hub$={hub$}/>
			</div>
		</Box>
	</>}/>
}
