import React from 'react';
import { FlexVCenter, Box, Icon, Button } from 'mui';
import { FlowName } from './flow-name';
import { GQL } from 'market-dto';
import { tapeImporter, alpha } from 'services';
import { useZState } from 'hooks';
import { SubMsg } from './sub-msg';

interface Props {
    readonly closeFlow:() => void;
    readonly readOnly?:boolean;
    readonly onEdit?:() => void;
    readonly flow:GQL.Flow;
    readonly isNewFlow:boolean;
    readonly batch?:tapeImporter.Batch;
    readonly tapeId?:string;
}
export const SummaryBar = ({
    isNewFlow,
    closeFlow,
    readOnly,
    onEdit,
    flow,
    batch,
    tapeId
}:Props) => {

    const { data:runState } = useZState(alpha.zRunState);
    const renderUnder = (tapeId || batch || isNewFlow) && runState?.status !== "DONE";

    return (
        <>
            <FlexVCenter
                bg="card"
                roundedEdges={renderUnder ? 'top' : 'all'}
                mb={renderUnder ? '0' : '2'}
                py="1"
                alignItems="stretch"
            >
                <FlexVCenter style={{ minHeight: '40px' }} px="2">
                    { isNewFlow && (
                        <Box
                            bg="body"
                            fontSize="sm"
                            fg="accent"
                            px="1"
                            mr="1"
                            roundedEdges="all"
                        >New</Box>
                    ) }
                    <FlowName flow={flow} readOnly={readOnly} />
                </FlexVCenter>
                <FlexVCenter justifyContent="flex-end" flex="1" mx="2">
                    { onEdit && (
                        <Button
                            mr="1"
                            // icon="edit"
                            type="link"
                            size="sm"
                            onClick={onEdit}
                        >Edit</Button>
                    ) }
                    <FlexVCenter
                        height="100%"
                        pl="2"
                        borderSides="left"
                        borderColor="inner-card-bg"
                        borderWidth="thin"
                    >
                        <Icon
                            name="close"
                            cursor="pointer"
                            fg="faded"
                            fgHover="link"
                            onClick={closeFlow}
                        />
                    </FlexVCenter>
                </FlexVCenter>
            </FlexVCenter>
            { renderUnder && (
                <FlexVCenter
                    bg="inner-card"
                    roundedEdges="bottom"
                    mb="2"
                    py="1"
                    alignItems="stretch"
                >
                    <Box py="1" px="2">
                        <SubMsg
                            flow={flow}
                            isNewFlow={isNewFlow}
                            batch={batch}
                            tapeId={tapeId}
                        />
                    </Box>
                </FlexVCenter>
            ) }
        </>
    )
}