import { GQL } from 'market-dto';

export const labels:{ [k in GQL.AgencySrpTableName]:string } = {
    srpFicoAdjustments: "Fico",
    srpLoanAmountAdjustments: "Loan Amount",
    srpLtvAdjustments: "LTV",
    srpRateAdjustments: "Rate",
    srpStateAdjustments: "State",
    srpProductAdjustments: "Product"
}

export interface SrpImportExportItem {
    readonly srpFicoAdjustments:GQL.AdjTable;
    readonly srpLoanAmountAdjustments:GQL.AdjTable;
    readonly srpLtvAdjustments:GQL.AdjTable;
    readonly srpRateAdjustments:GQL.AdjTable;
    readonly srpStateAdjustments:GQL.AdjTable;
}