import React from 'react';
import { FlexVCenter, Button, Icon, Box, Flex, modal, Link } from 'mui';
import { HeatMapViewerModal } from './heat-map-viewer-modal';
import { PricingReplayModal } from './pricing-rereplay-modal';

export const Tools = () => {

    const openHeatMapViewerModal = () => {
        modal.open({
            size: 'xl',
            render: <HeatMapViewerModal />
        })
    }

    const openPricingReplayModal = () => {
        modal.open({
            size: 'md',
            render: <PricingReplayModal />
        })
    }
    return (
        <>
        Here you will find a small collection of debugging tools.
        <ul>
            <li>
                <Link onClick={openHeatMapViewerModal}>Heat Map Viewer</Link>
            </li>
            <li>
                <Link onClick={openPricingReplayModal}>Pricing Replay</Link>
            </li>
            {/* <li>
                <Link onClick={() => modal.confirm({ title: 'There is nothing else', hideCancel: true })}>Tape Mapper</Link>
            </li>
            <li>
                <Link onClick={() => modal.confirm({ title: 'There is nothing else', hideCancel: true })}>Brett Estes</Link>
            </li> */}
        </ul>
        </>
    )
}