import React, { useState } from 'react';
import { ExpContextField } from 'exp';
import { usePageSortTable, TablePageButtons, SortableTableHeader, Icon, Box, FlexVCenter } from 'mui';
import { FlatSchemaSimpleInput } from '../components/flat-schema-simple-input';
import { ExpFieldWithValue } from 'exp';


const sampleDataTableValue = (f:FieldAndData) => {
    if (f.schema.type === 'boolean') {
        return f.value ? 'True' : 'False';
    }
    return f.value;
}

interface FieldAndData extends ExpContextField {
    readonly value:any;
}

interface Props {
    readonly fields:ExpFieldWithValue[];
    readonly onValueChange:(id:string, val:any) => void;
}
export const FieldsWithValuesTable = ({ fields, onValueChange }:Props) => {

    const [ editting, setEditting ] = useState(''); // id of field editting

    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<ExpFieldWithValue, keyof ExpFieldWithValue>({
        rows: fields,
        keyFn: row => row.id + ':' + String(row.value), // all deps in a string
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 10
        }
    })

    const editField = (f:ExpFieldWithValue) => {
        setEditting(f.id);
    }

    const setFieldValue = (row:ExpFieldWithValue, val:string|number|boolean) => {
        onValueChange(row.id, val);
        setEditting('');
    }

    return (
        <>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="id" render="Field ID" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="label" render="Label" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="type" render="Type" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="value" render="Value" />
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.length === 0 && (
                        <tr>
                            <td colSpan={4}>
                                <Box fg="faded" textStyle="italic">No fields to display</Box>
                            </td>
                        </tr>
                     ) }
                    { sortedRows.length > 0 && sortedRows.map(row => {
                        const isEditting = editting == row.id;
                        return (
                            <tr key={row.id}>
                                <td>{ row.id }</td>
                                <td>{ row.label }</td>
                                <td>{ row.type }</td>
                                <td>

                                    <FlexVCenter justifyContent="space-between">
                                        <Box flex="1">
                                            { !isEditting && sampleDataTableValue(row) }
                                            { isEditting && (
                                                <FlatSchemaSimpleInput
                                                    w100
                                                    field={row.schema}
                                                    value={row.value}
                                                    setValue={val => setFieldValue(row, val)}
                                                />
                                            ) }
                                        </Box>
                                        <Box>
                                            { !isEditting && (
                                                <Icon name="edit" cursor="pointer" onClick={() => editField(row) } />
                                            ) }
                                        </Box>
                                    </FlexVCenter>
                                </td>
                            </tr>
                        )
                    }) }
                </tbody>
            </table>
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}