import React from 'react';
import { ImportModel, ModelItem, GQL } from 'market-dto';
import { PopOverFooter, PopOverHeader, XlsBtn, closePopOver } from '../components';
import { fetchLoanSource } from 'api';
import { useApi } from 'hooks';
import { SrcMapDerive } from './src-map-derive';

interface Props {
    readonly model:ImportModel;
    readonly field:ModelItem.AnyType;
    readonly loanSource:GQL.LoanSource;
}
export const SrcMapView = ({ model, field, loanSource }:Props) => {

    const { sourceMap } = loanSource;
    const fieldSrcMap = sourceMap.find(x => x.id === field.fieldId);

    if (!fieldSrcMap) return <>Source map for field { field.fieldId } not found!</>

    const triedDirect = field.mapCfg?.direct ? true : false;
    const { derive, direct, val } = fieldSrcMap;

    return (
        <>
            {/* <div>Field ID: { field.fieldId }</div> */}
            {/* { val && (
                <>
                    <div>Unformatted Value: { val?.trans }</div>
                    <div>How did we get this value?</div>
                </>
             ) }
            { !val && (
                <>
                    <div>We could not map this field.</div>
                    <div>Here's what we tried...</div>
                </>
            ) } */}
            { triedDirect && (
                <>
                    <div>We tried to map directly to a particular column.</div>
                    { !direct && <div>...but we didn't find one!</div> }
                    { direct && (
                        <>
                            { !derive && <div>And we succeeded!</div> }
                            { derive && <div>We found a column, but couldn't use the value found.</div> }
                            
                            <div>The column we found was: "{ direct.cols[0].name }"</div>
                            <div>The raw value found was: "{ val?.raw }"</div>
                            { direct.cols.length > 1 && (
                                <div>
                                    We also considered mapping from the following columns:
                                    { direct.cols.map(x => '"' + x + '"').join(', ') }
                                </div>
                            ) }
                            { direct.tiebreaker && direct.tiebreaker !== 'default' && (
                                <div>The tie-breaker was: { direct.tiebreaker }</div>
                            ) }
                        </>
                    ) }
                </>
            ) }
            { derive && derive.length > 0 && (
                <>
                    { triedDirect && <div>So, we next tried to derive this field from other fields...</div> }
                    { !triedDirect && <div>We tried to derive this field from other fields...</div> }
                    
                    { val && <div>And we succeeded!</div> }
                    { !val && <div>But we failed.</div> }
                    { derive.map((d,n) => (
                        <SrcMapDerive
                            key={n}
                            model={model}
                            field={field}
                            n={n}
                            derive={d}
                            sourceMap={sourceMap}
                        />
                    )) }
                </>
            ) }
        </>
        
    )
}