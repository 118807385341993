import { saveAs } from 'file-saver';

export const arrBufToBase64 = (buf:ArrayBuffer):string => {
    const isArrBuff = toString.call(buf) === '[object ArrayBuffer]';
    if (!isArrBuff) throw new Error('Expected array buffer');
    const u8 = new Uint8Array(buf);
    return btoa(u8.reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

export const base64ToDownload = (base64:string, asFilename:string, mimeString?:string) => {
    const blob = base64toBlob(base64, mimeString ?? '');
    saveAs(blob, asFilename);
}

export const base64toBlob = (base64Data:string, contentType:string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);

        let bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType || '' });
}

export const readFileAsBase64 = (file:File):Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result ? reader.result.toString() : '');
        }
        reader.onerror = () => resolve('');
        reader.readAsDataURL(file);
    });
}
