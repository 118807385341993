import React from 'react';
import { GQL } from 'market-dto';
import { Icon } from 'mui';

interface Props {
    readonly tab:GQL.TaskXlsExportTabConfig;
    readonly tabs:GQL.TaskXlsExportTabConfig[];
    readonly onSelectTab:(tabCfg:GQL.TaskXlsExportTabConfig) => void;
    readonly onAddNewTab:() => void;
    readonly adding:boolean;
    readonly readOnly:boolean;
}
export const XlsExportTableTabs = ({
    readOnly,
    tabs,
    tab,
    onSelectTab,
    onAddNewTab,
    adding
}:Props) => {

    return (
        <div className="m-xls-tabs">
            { tabs.map((t, n) => (
                <div
                    key={n}
                    onClick={() => onSelectTab(t)}
                    className={t === tab && !adding ? 'm-xls-active-tab' : undefined}
                >{ t.name }</div>
            )) }
            { !adding && !readOnly && (
                <span className="m-xls-add-tab">
                    <Icon
                        name="plus-circle"
                        cursor="pointer"
                        onClick={onAddNewTab}
                    />
                </span>
            ) }
        </div>
    )
}