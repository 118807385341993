import { createZStateList } from '../z-state';
import { toModelInfo } from './to-model-info';
import { toGqlModel } from './to-gql-model';
import { ModelInfo } from './types';
import { createEmptyModel } from './create-empty-model';
import * as api from 'api';

export const zModels = createZStateList({
    apiFetchList: async () => {
        const result = await api.fetchAllModels();
        if (!result) return [];
        const infos = result.map(x => toModelInfo(x));
        return infos;
    },
    apiUpdateListItem: async (m:Partial<ModelInfo>) => {
        // The partial is weird. is it because of "id" and internal _fields being stripped?
        await api.updateModel(toGqlModel(m as ModelInfo));
        return m;
    },
    apiInsertListItem: async (m:Partial<ModelInfo>) => {
        const gqlModel = await api.insertModel(toGqlModel(m as ModelInfo));
        return gqlModel ? toModelInfo(gqlModel) : undefined;
    },
    isEqual: (a,b) => a.id === b.id,
    createNewListItem: () => toModelInfo(createEmptyModel())
})