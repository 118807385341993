import { gql, GQL, query } from "../../../client";
import { PRICING_MODULE_RETURNING } from "./returning"


interface QueryInput {
	readonly where:GQL.WhereString;
}

interface QueryResult {
	readonly pricingModule: GQL.PricingModule[];
}

const QUERY = gql`
    query fetchPricingModule($where: where_pricingModule_exp!) {
        pricingModule(where:$where) { ${PRICING_MODULE_RETURNING} }
    }
`;

export const fetchPricingModule = async (id:string):Promise<GQL.PricingModule> => {
	const result = await query<QueryInput, QueryResult>(QUERY, { where: { id: { _eq: id } } })
	const [e, ...rest] = result.data.pricingModule
	return e
}
