import { MinimalLoan, MapResult, ModelType } from 'market-dto';
import * as api from 'api';
import { TemplateAndConfidence } from '../types';

export const fetchMatchingTemplates = async (
    modelType:ModelType,
    mapped:MapResult<MinimalLoan>
):Promise<TemplateAndConfidence[]> => {    
    const templatesAndConf = await api.fetchAllMatchingTemplates(modelType, mapped.hashes);
    return templatesAndConf;
}

