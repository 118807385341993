import React, { useRef, useLayoutEffect, useEffect, useState, CSSProperties } from 'react';
import { Box, BoxProps } from '../box/box';
import { getCss } from '../getCss';
import { usePrevious } from '../../hooks/use-previous';
import {
    Anim, AnimDelay
} from '../../types';

const DURATION = 200; /* should match what is found in css */

interface AnimBoxProps extends BoxProps {
    readonly anim:Anim;
    readonly animDelay?:AnimDelay;
    readonly animDuration?:number; // <--- needs enum to enforce similar durations?
    readonly animOnInit?:boolean; // by default we do not animate initially
}
export const AnimBox = ({ anim, animDelay, animOnInit, animDuration=DURATION, ...boxProps }:AnimBoxProps) => {

    const lastAnim = usePrevious(anim);
    
    if (anim === 'collapse-height' || anim === 'expand-height') {
        // special case for collapse and expand (because height auto is special)
        return <ExpandCollapse anim={anim} animOnInit={animOnInit} animDuration={animDuration} {...boxProps} />
    }

    // Do not animate if no lastAnim (only on change)
    const showAsFinal = !animOnInit && (!lastAnim || lastAnim === anim);
    if (showAsFinal) return <Box css={"anim-final-" + anim} {...boxProps} />

    const css = getCss({
        anim,
        animDelay
    })
    return <Box {...boxProps} css={css} />
}


const ExpandCollapse = ({ anim, animOnInit, animDuration, ...boxProps }:AnimBoxProps) => {

    // Beacuse height animation from "auto" is a pain.

    const lastAnim = usePrevious(anim);
    const ref = useRef<HTMLDivElement>(null);
    const firstTime = useRef<boolean>(true);
    const showAsFinal = !animOnInit && (!lastAnim || lastAnim === anim);
    const [ height, setHeight ] = useState<number>(-1);
    // The trick any animation library must do: measure the component height off-screen
    const [ initStyles, setInitStyles ] = useState<CSSProperties | null>({
        position: 'absolute',
        left: '-99999px',
        top: '-99999px'
    })

    useLayoutEffect(() => {
        if (!ref || !ref.current) return;
        setHeight(ref.current.clientHeight);
        setInitStyles(null);
    }, [ref.current])

    useEffect(() => {
        if (!ref || !ref.current) return;
        if (height === -1) return;
        const d = ref.current;
        const h = d.clientHeight;
        if (showAsFinal) {
            console.log('show as final');
            if (anim === 'collapse-height') {
                d.style.height = '0';
            } else {
                d.style.height = height + 'px';
            }
            setInitStyles(null); // put is back where we belong after having measured.
        } else if (anim === 'collapse-height') {
            d.animate([
                { height: h + 'px' },
                { height: '0px' }
            ], { duration: animDuration, fill:'forwards' })
        } else if (anim === 'expand-height') {
            if (firstTime.current && animOnInit) {
                firstTime.current = false;
                // console.log('first time', height, animDuration);
                d.animate(
                    [
                        { height: '0px' },
                        { height: height + 'px' }
                    ],
                    { duration: animDuration, fill:'forwards' }
                )
            } else {
                d.animate(
                    [
                        { height: h + 'px' },
                        { height: height + 'px' }
                    ],
                    { duration: animDuration, fill:'forwards' }
                )
            }
        }
    }, [ref.current, height, anim, showAsFinal])

    return <Box ref={ref} {...boxProps} style={initStyles || {}} overflow="hidden" />
}