import * as api from 'api';
import { useApiUpdate } from 'hooks';
import { GQL } from 'market-dto';
import {
	BoundFrmCheckbox, BoundFrmDropdown, BoundFrmInput, Button, DropdownItem, FlexVCenter, modal
} from 'mui';
import React, { useState } from 'react';
import { user as userService, validate } from 'services';

const copy = (x:any) => JSON.parse(JSON.stringify(x));

type MUser = Partial<GQL.User>;

const copyUser = (user:MUser | null):MUser => {
	const u:MUser = copy(user); // we need it copied cuz of any non-primitives that we copy over (roles, for example)
	if (user) {
		return {
			firstName: u.firstName,
			lastName: u.lastName,
			// phone: u.phone,
			email: u.email,
			active: u.active,
			roles: u.roles
		}
	}
	// if null, it's a NEW USER MODAL
	const self = userService.getUser(); // FOR NOW. better would be take from the hook i guess?
	return {
		email: "",
		firstName: "",
		lastName: "",
		orgId: self?.orgId,
		eulaVersion: "",
		// phone: "",
		roles: ["jr"],
		active: true
	}
}

interface Props {
	readonly user:GQL.User | null;
	readonly onSave:() => void;
}

export const UserModal = ({ user, onSave }:Props) => {
	
	const isNew:boolean = user === null;
	
	const [editUser, setEditUser] = useState<MUser>(() => copyUser(user));
	const { busy, update: save } = useApiUpdate(async () => {
		if (!editUser) return;
		if (user === null) {
			await api.insertUser(editUser);
		} else {
			await api.updateUser(user.id, editUser);
		}
		onSave();
		modal.close();
	})
	
	const roleOpts:DropdownItem[] = [
		{ label: "Manager", value: "manager" },
		{ label: "Regular User", value: "regular_user" },
		{ label: "API", value: "api" }
	]
	
	if (!editUser) return null;
	
	const canSave = editUser.lastName?.trim()
		&& editUser.firstName?.trim()
		&& (validate.isValidEmail(editUser.email))
		&& editUser.roles
		&& editUser.roles?.length > 0;
	
	return (
		<>
			<BoundFrmDropdown
				width="100%"
				label="Roles"
				isMulti={true}
				items={roleOpts}
				bindTo={[editUser, setEditUser, x => x.roles]}
			/>
			<BoundFrmInput
				width="100%"
				label="Email ({user}@domain or api-{token}@domain)"
				bindTo={[editUser, setEditUser, x => x.email]}
			/>
			{/* <BoundFormInput<MUser>
                label="Phone Number"
                bindTo={[editUser, setEditUser, x => x.phone ]}
            /> */}
			<BoundFrmInput
				width="100%"
				label="First Name"
				bindTo={[editUser, setEditUser, x => x.firstName]}
			/>
			<BoundFrmInput
				width="100%"
				label="Last Name | API System"
				bindTo={[editUser, setEditUser, x => x.lastName]}
			/>
			<BoundFrmCheckbox
				label="Active"
				bindTo={[editUser, setEditUser, x => x.active]}
			/>
			<FlexVCenter mt="2" justifyContent="flex-end">
				<Button type="secondary" onClick={modal.close}>Cancel</Button>
				<Button
					type={canSave ? 'default' : 'disabled'}
					ml="1"
					busy={busy}
					onClick={save}
				>{isNew ? 'Insert New User' : 'Save User'}</Button>
			</FlexVCenter>
		</>
	);
}
