
// NEW: Stop including "{" "}" with the returning...
// cuz there are times we need to add to 'em
export const RETURNING = `
    id
    refId
    name
    description
    groups
    schema
`;


export const SAMPLE_FIELDS = `
    id
    name
    description
    modelId
    data
`;

