import React, { useState , useEffect } from 'react';
import { Flex, Box, FlexVCenter, PortalPlaceholder } from 'mui';
import { ChecklistTable } from './checklist-table';
import { ChecklistItemLogicEditor } from './checklist-item-logic-editor';
import { GQL } from 'market-dto';
import { CheckItem } from 'raccoon-engine';
import { FilterForm } from './filter-form';
import { ExpContext, ExpFieldWithValue } from 'exp';
import { ExpSubmitValues } from './types';

interface Props {
    readonly model:GQL.Model;
    readonly checklist:GQL.Checklist;
    readonly sampleData:any;
    readonly fieldsWithValues:ExpFieldWithValue[];
    readonly ctx:ExpContext;
    readonly editItem:CheckItem|undefined;
    readonly setEditItem:(x:CheckItem|undefined) => void;
    readonly onClone:(item:CheckItem, intoIndex:number) => void;
    readonly onRemove:(removeItem:CheckItem) => void;
    readonly onSubmit:(values:ExpSubmitValues) => void;
}
export const ItemsTab = ({
    ctx,
    editItem,
    setEditItem,
    onClone,
    sampleData,
    checklist,
    onRemove,
    onSubmit
}:Props) => {

    const [ filterText, setFilterText ] = useState('');
    const [ filteredItems, setFilteredItems ] = useState<CheckItem[]>([]);

    const submit = (props:ExpSubmitValues) => {
        onSubmit(props);
        setEditItem(undefined);
    }

    useEffect(() => {
        const txt = filterText.trim().toLowerCase();
        setFilteredItems(checklist.items.filter(x => {
            if (txt === '') return true;
            if (x.id.trim().toLowerCase().indexOf(txt) !== -1) return true;
            if (x.expressions.some(expStr =>  expStr.toLowerCase().indexOf(txt) !== -1)) return true;
            if (x.preconditions && x.preconditions.some(expStr =>  expStr.toLowerCase().indexOf(txt) !== -1)) return true;
            return false;
        }))
    }, [checklist, filterText])

    return (
        
        <>
            <FlexVCenter>
                <Box flex="2" mr="1">
                    <FilterForm
                        count={filteredItems.length}
                        itemLabel="Checklist Item"
                        placeHolder="Filter Items"
                        filter={filterText}
                        setFilter={setFilterText}
                    />
                </Box>
                <Box flex="3" ml="1">
                    <PortalPlaceholder id="exp-warning-area" />
                </Box>
            </FlexVCenter>

            <Flex>
                <Box flex="2" mr="1">
                    <ChecklistTable
                        onSelect={setEditItem}
                        onClone={onClone}
                        filteredItems={filteredItems}
                        sampleData={sampleData}
                        checklist={checklist}
                        expCtx={ctx}
                    />
                </Box>
                <Box flex="3" ml="1">
                    <ChecklistItemLogicEditor
                        checklist={checklist}
                        editItem={editItem}
                        expCtx={ctx}
                        onRemove={onRemove}
                        onCancel={() => setEditItem(undefined)}
                        onSubmit={submit}
                        sampleData={sampleData}
                    />
                </Box>
            </Flex>
        </>
    )
}