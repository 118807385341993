import React, { useEffect } from 'react';
import { GQL } from 'market-dto';
import { Loading, GenericSummaryBar } from 'components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NavAndBtnArea } from './nav-and-btn-area';
import { Runs } from './runs';
import { Details } from './details';
import { Tasks } from './tasks';
import { useZState } from 'hooks';
import { alpha } from 'services';
import { SummaryBar } from '../summary-bar';

interface Props {
    readonly flow:GQL.Flow;
    readonly closeFlow:() => void;
}
export const Edit = ({ flow, closeFlow }:Props) => {

    // We must have a run in-memory for all the editting.
    // So, when flow id changes, grab the most recent.
    const { data:flowRun, fetching } = useZState(alpha.zCurrentRun);
    useEffect(() => {
        alpha.zCurrentRun.fetch(flow.recentActivity?.runId)
    }, [flow.id])

    return (
        <>
            <SummaryBar
                closeFlow={closeFlow}
                flow={flow}
                isNewFlow={false}
            />
            <Loading
                loading={fetching || !flowRun}
                render={
                    <>
                        <NavAndBtnArea />
                        <Routes>
                            <Route path="runs/*" element={<Runs flow={flow} />} />
                            <Route path="details/*" element={<Details flow={flow} />} />
                            <Route path="task/:taskIndex" element={<Tasks flow={flow} flowRun={flowRun!} />} />
                            <Route path="*" element={<Navigate to="task/1" />} />
                        </Routes>
                    </>
                }
            />
        </>
    )

}