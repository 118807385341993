import { GQL } from "market-dto";
import { BasicMenuItem, Box, Button, Flex, Tabs } from "mui";
import React, { useMemo, useState } from "react";
import { addPricingModule, ensureStructure, removePricingModule } from "./internal-pricing";
import { CutAndPaste } from "./pricing-cut-and-paste"
import { PricingCsvViewer } from "./pricing-cvs-viewer"
import "./pricing.scss";
import { TryRun } from "./try-run"
import {InternalPricing} from 'raccoon-engine'
import { wording } from "./wording";


interface Args {
	readonly flowRun:GQL.FlowRun;
	readonly data:GQL.PricingModule;
	readonly saving:boolean;
	readonly dirty:boolean;
	readonly onReset:() => void;
	readonly onChange:(x:GQL.PricingModule) => void;
	readonly onSave:() => void;
}


export const PricingModuleBody = (props:Args) => {
	const { flowRun, data, saving, dirty, onReset, onChange, onSave, } = props
	const { content: { type, csv }, content } = data;
	const modules = useMemo(() => {
		const config = ensureStructure(content)
		return config.modules
	}, [content])
	
	const notifyUnsaved = () => {
		onChange({ ...data })
	}
	
	const onUnsavedData = (id:string, csv:any[][], hints:any[][]) => {
		const found = modules.find(m => m.id === id)
		if (found) {
			found.csv = csv
			found.hints = hints
		}
		console.log('we are saving the data', hints)
		notifyUnsaved()
	}
	
	const onAdding = (extraModule:InternalPricing) => {
		addPricingModule(modules, extraModule)
		notifyUnsaved()
	}
	const onDelete = (id:string) => () => {
		removePricingModule(modules, id)
		notifyUnsaved()
	}
	
	const [tab, setTab] = useState("data")
	const tabItems:BasicMenuItem[] = [
		{
			label: "Rates and Adjustments",
			value: "data"
		},
		{
			label: "Test Drive",
			value: "test"
		},
		{
			label: "+ Rate/LLPA/LLRA",
			value: "addAdjustment"
		},
	]
	
	
	const onTest = () => {
		// first. let's get the ratesheet 
		// const ratesheet = findRateSheet(modules)
		// if (!ratesheet) { return }
		// const { hints } = ratesheet
		// const lookup = new HintTable({ data: hints })
		// flowRun.rows
		//        .filter((r, i) => i === 0)
		//        .forEach((pkg) => {
		// 	       const matched = lookup.lookup(pkg)
		// 	       console.log('matched-result', matched)
		//        });
		
		// const instance = new DataTable({ data: csv });
		// const matcher = new Matcher();
		// matcher.withTable(instance);
		// const prices = flowRun.rows.map((pkg) => matcher.findPrice(pkg));
		// setMatchPrices(prices);
	};
	
	
	return (
		<Box p="2" roundedEdges="all" shadow="card" fontSize="md">
			<Box fg="loud" fontWeight="bold" fontSize={"md"}>
				{wording.title}
			</Box>
			<Box fg="form-label" fontWeight="thin">
				{" "}
				{wording.description}
			</Box>
			
			{dirty && (
				<Flex
					flexDirection="row"
					justifyContent="flex-end"
					style={{ flex: "auto" }}
				>
					<Button
						busy={saving}
						size="sm"
						type="default"
						onClick={onSave}
						style={{ alignSelf: "flex-end" }}
					>
						Save
					</Button>
				</Flex>
			)}
			
			<Tabs items={tabItems} selected={tab} onSelect={setTab} mb="2" mt="2"/>
			
			{tab === "data" &&
			modules.map((m, idx) => {
				return <Box mt="2" key={idx}>
					<PricingCsvViewer onUnsavedData={onUnsavedData} onRemove={onDelete(m.id)}
					                  module={m}/></Box>
				
			})
			}
			{tab === "test" && <div>

				<div>
					<TryRun subjects={flowRun.rows} modules={modules}/>
				</div>
			</div>}
			
			{tab === "addAdjustment" && <CutAndPaste onAdding={onAdding}/>}
		
		
		</Box>
	);
};
