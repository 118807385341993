export interface DaysOfWeek {
    readonly mon:boolean;
    readonly tue:boolean;
    readonly wed:boolean;
    readonly thu:boolean;
    readonly fri:boolean;
}

export interface NthOfMonth {
    readonly first:boolean;
    readonly second:boolean;
    readonly third:boolean;
    readonly fourth:boolean;
}

export const dayLabels:{ [x in keyof DaysOfWeek]:string } = {
    "mon": "Monday",
    "tue": "Tuesday",
    "wed": "Wednesday",
    "thu": "Thursday",
    "fri": "Friday",
}
