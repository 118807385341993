import { GQL, util } from 'market-dto';

const MIN_OFFER_PRICE_REQUIRED_FOR_AUTO_SELECT = 10;

// TODO: move to loans

export const getLoansBestOffer = (loan:GQL.Loan):string => {
    if (!loan.offers) return '';
    const maxItem = util.getMax(loan.offers, x => x.offeringPrice);
    if (maxItem && maxItem.offeringPrice! >= MIN_OFFER_PRICE_REQUIRED_FOR_AUTO_SELECT) return maxItem.id;
    return '';
}
