import { query, gql, GQL } from '../../client';

interface QueryInput {
    readonly products:string[];
}

interface QueryOutput {
    readonly sync_cash_window:GQL.PriceSheet;
}

const QUERY = gql`
    query syncCashWindow($products: [String]) {
        sync_cash_window(productIds: $products)
    }
`;

export const fetchCashWindow = async (inevstorProductIds:string[]):Promise<GQL.PriceSheet|undefined> => {
    const start = new Date().getTime();
    const result = await query<QueryInput, QueryOutput>(QUERY, { products: inevstorProductIds })
    console.log('took', new Date().getTime() - start);
    return result.data?.sync_cash_window;
}
