import { GQL } from 'market-dto';
import { FgColor} from 'mui';

const labelSize = 12;

export const stageCfg:{ [x in GQL.BatchLogItemStage]:{ label:string, fg:FgColor }} = {
    cleanup: { label: "Cleaning up".padEnd(labelSize, ' '), fg: "loud" },
    complete: { label: "Completed".padEnd(labelSize, ' '), fg: "success" },
    fail: { label: "Error".padEnd(labelSize, ' '), fg: "danger" },
    init: { label: "Initializing".padEnd(labelSize, ' '), fg: "loud" },
    preparation: { label: "Preparing".padEnd(labelSize, ' '), fg: "loud" },
    process: { label: "Processing".padEnd(labelSize, ' '), fg: "accent" },
}
