import { mutate, gql, GQL, stripInternals } from "../../client";
import { RETURNING } from './returning';

const UPDATE = gql`
    mutation updateChecklist($set: checklist_set_input, $where: checklist_bool_exp!) {
        update_checklist(set: $set, where: $where) {
            errorMessage
            affectedRow
            returning {
                ${ RETURNING }
            }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Checklist>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_checklist: {
        readonly returning:[GQL.Checklist];
    }
}

export const updateChecklist = async (checklist:Partial<GQL.Checklist>):Promise<GQL.Checklist|undefined> => {
    if (!checklist.id) throw new Error('Expected id');    
    // const client = getApolloClient(false);
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(checklist),
        where: {
            id: {
                _eq: checklist.id
            }
        }
    })
    // const result =  await client.mutate<MutateOutput, MutateInput>({
	// const result =  await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: UPDATE,
	// 	variables: {
    //         set: stripInternals(checklist),
    //         where: {
    //             id: {
    //                 _eq: checklist.id
    //             }
    //         }
	// 	}
    // })
    return result?.data?.update_checklist.returning[0];
}
