import React from 'react';
import { BasicMenuItem, modal } from 'mui';
import { AdjGroup} from '../components/adj-group';
import { ImportExportModal } from '../components/import-export-modal';
import { GQL } from 'market-dto';
import { labels } from './agency-srp-config';
import { buildExportItem } from './build-export-item';
import { isValidImportItem } from './is-valid-import-item';

const showImportExportModal = (refreshTab:() => void) => {
    modal.open({
        title: 'Import / Export Agency SRP JSON',
        render: <ImportExportModal
            refreshTab={refreshTab}
            buildExportObject={buildExportItem}
            exportJsonFilename="srp.json"
            isValidImportJson={isValidImportItem}
            label="SRP"
            tables={GQL.AGENCY_SRP_TABLES}
        />
    })
}

const tabs:Array<BasicMenuItem> = GQL.AGENCY_SRP_TABLES.map((x, n) => ({ label: labels[x], value: x }));

export const AgencySrp = () => <AdjGroup tabs={tabs} onImportExport={showImportExportModal} />
