import { query, gql, GQL } from "../../client";
import { RETURNING, toTemplateAndConfidence } from './returning';
import { TemplateAndConfidence } from 'services/tape-importer';

const QUERY = gql`
    query ($where: template_bool_exp!) {
        template(where: $where) { ${ RETURNING } }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryOutput {
    readonly template:[GQL.Template];
}

export const fetchSingleTemplate = async (templateId:string):Promise<TemplateAndConfidence|undefined> => {
    const result = await query<QueryInput, QueryOutput>(QUERY, { 
        where: {
            id: {
                _eq: templateId
            }
        }
    })
    if (!result.data?.template) return;
    if (result.data.template.length !== 1) return;
    return toTemplateAndConfidence("highest", result.data.template[0]);
}
