import { GQL, LoanField } from 'market-dto';

export const getCalcEnumVal = (
    fieldCfgs:GQL.XlsExportCol[],
    f:GQL.XlsExportCalcEnumCol,
    row:GQL.FlowRunRow
) => {
    const fieldId = f.source;
    const rawSrcVal = row.loan[fieldId as LoanField];
    const p = f.param[String(rawSrcVal)];
    return p && p.label ? p.label : '';
}