import { useEffect, useState, useRef } from 'react';
import { zState } from 'services';
import { useDepsChanged } from 'hooks/use-deps-changed';

// const copy = <T>(x:T):T => {
//     return x === undefined ? undefined : JSON.parse(JSON.stringify(x));
// }

interface ZHookCfg<T> {
    readonly fetchArgs?:any[];
    readonly debug?:string;
}

export const useZState = <T>(z:zState.ZState<T>, cfg:ZHookCfg<T> = {}) => {

    const [ val, setVal ] = useState<zState.ZStateProperties<T>>(() => z.getState());
    const { fetchArgs } = cfg;
    const aboutToFetch = useDepsChanged(fetchArgs);

    // maybe some other time.
    // useEffect(() => {
    //     if (cfg.initVal) z.setInitVal(cfg.initVal);
    // }, [])

    useEffect(() => {
        // This will produce a warning, but it's the correct way to say:
        // fetch init when args exist, and fetch subsequently when args change.
        if (fetchArgs) z.fetch(...fetchArgs);
    }, [...fetchArgs ?? []])

    useEffect(() => {
        const sub = z.state$.subscribe(x => {
            if (cfg && cfg.debug) console.log('x-->', cfg.debug, x);
            setVal(x);
        })
        return () => sub.unsubscribe();
    }, [])

    return {
        // We don't wanna wait for the api call to be kicked off (in useEffect, after 1st render) to know
        // That we are "fetching". Hence "aboutToFetch".
        // So, we need to say "fetching" before we tell zstate to fetch!
        // AND...we must treat calling apis as "side effects" because in dev mode, it'll run twice...
        // just to rub your nose in it (so you can find where you have things that shouldn't be in render function)
        // Also, what if rxjs subscribe doesn't come in on next render (i've NEVER seen it, perhaps impossible)
        // But by just grabbing z.isFetching(), we bypass that. So, this should work well.
        fetching: aboutToFetch || z.isFetching(),
        error: val.error,
        data: val.editObj,
        dirty: val.dirty
    }
}

interface ZHookListCfg<T> {
    readonly fetchArgs?:any[];
    readonly debug?:string;
}

export const useZStateList = <T>(z:zState.ZStateList<T>, cfg:ZHookListCfg<T> = {} ) => {

    const [ val, setVal ] = useState<zState.ZStateListProperties<T>>(() => z.getState());
    const { fetchArgs } = cfg;
    const aboutToFetch = useDepsChanged(fetchArgs);

    useEffect(() => {
        // This will produce a warning, but it's the correct way to say:
        // fetch init when args exist, and fetch subsequently when args change.
        if (fetchArgs) z.fetchList(...fetchArgs);
    }, [...fetchArgs ?? []])

    useEffect(() => {
        const sub = z.state$.subscribe(setVal);
        return () => sub.unsubscribe();
    }, [])

    const fetching = aboutToFetch || z.isFetching();
    const updating = val ? val.updating : false;
    const error = val ? val.error : false;
    const data = val ? val.list : undefined // TODO: default value? [] ? 

    return {
        fetching: fetching,
        updating: updating,
        busy: fetching || updating,
        error,
        data,
        editItem: val?.activeItemEditObj,
        editItemDirty: val?.activeItemDirty ?? false
    }
}
