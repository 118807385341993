import React, { useState } from 'react';
import { Box, Flex, FlexVCenter, Format, IconButton, Checkbox, modal, Text } from 'mui';
import { GQL, labels } from 'market-dto';
import { PriceTagDetails } from './price-tag-details';
import { format, sheets, fannie, freddie } from 'services';
import { HeatMapModal } from 'components';
import { useZState } from 'hooks';

const getIsChecked = (offer:GQL.Offer, checkedOffers:sheets.CheckedOfferIdByLoan|undefined, canCheck:boolean) => {
	// canCheck is set by status
	if (canCheck) return checkedOffers ? checkedOffers[offer.loanId] === offer.id: false;
	return offer.selected;
}


interface Props {
	readonly offer:GQL.Offer;
	readonly loan:GQL.Loan;
	readonly isLast:boolean;
	readonly offerIndex:number; // if you are 0, you are the highest one!
	readonly canCheck:boolean;
}

export const OfferRow = ({ offer, loan, isLast, offerIndex, canCheck }: Props) => {

	// You are in a CSS GRID...you must have 4 and only 4 sibling root containers below.
	const [ selected, setSelected ] = useState(false);
	const { data:checkedOffers } = useZState(sheets.zCheckedOffers);

	const openHeatMapModal = (investor:"fannie"|"freddie") => modal.open({
		render: <HeatMapModal
			offer={offer}
			loan={loan}
			investor={investor}
			removeGaps={true}
			removeWeekends={true}
		/>,
		// title: `Capability to Raise Price - ${offer.loanId}`,
		size: "xl",
		fixAtMaxHeight: true
	})

	const toggleSelected = () => setSelected(!selected);

	const checked = getIsChecked(offer, checkedOffers, canCheck);

	const toggleChecked = () => {
		if (!canCheck) return;
		sheets.zCheckedOffers.update(dict => {
			return {
				...dict,
				[offer.loanId]: checked ? '' : offer.id
			}
		})
	}

	return (
		<>
			<Box
				p="2"
				bg={selected ? "body" : 'inner-card'}
				borderSides="top-and-left"
				borderWidth="thick"
				borderColor="inner-card-bg"
			>

				{/* Special -2px because of the BORDER thickness (only on the FIRST one!)  */}
				<Flex style={{marginLeft: '-2px', marginTop:'-2px'}} justifyContent="space-between">
					<Box>
						<FlexVCenter fontSize="lg">
							<Checkbox checked={checked} onToggle={toggleChecked} mr="1" cursor={canCheck ? 'pointer' : 'default'} />
							<Text fg={checked ? 'success' : 'loud'} onClick={toggleChecked} cursor={canCheck ? 'pointer' : 'default'}>
								<Format as="4_decimals">{offer.offeringPrice}</Format>&nbsp;
							</Text>
						</FlexVCenter>
						<Box fontSize="sm" fg={checked ? 'faded' : 'faded'} ucase>
							{ offer.investor }
						</Box>
					</Box>
					<Box>
						{ fannie.isValidFannieOffer(offer) && (
							<IconButton size="sm" name="bar-chart" onClick={() => openHeatMapModal('fannie')}/>
						)}
						{ freddie.isValidFreddieOffer(offer) && (
							<IconButton size="sm" name="bar-chart" onClick={() => openHeatMapModal('freddie')}/>
						)}
						<IconButton size="sm" name={selected ? "close" : "more-horizontal"} onClick={toggleSelected}/>
					</Box>
				</Flex>

			</Box>
			<Box p="2">
				<Box fontSize="lg" fg="loud">{offer.commitmentDays}&nbsp;</Box>
				<Box fontSize="sm" fg="faded" ucase>{ labels.byOfferField('commitmentDays') }</Box>
			</Box>
			<Box p="2">
				<Box fontSize="lg" fg="loud">
					{format.datePartOfISO(offer.acquisitionDate)}&nbsp;
				</Box>
				<Box fontSize="sm" fg="faded" ucase>{ labels.byOfferField('acquisitionDate') }</Box>
			</Box>
			<FlexVCenter p="2" alignItems="flex-start">
				<Box>
					<Box fontSize="lg" fg="loud">
						{format.datePartOfISO(offer.notificationDate)}&nbsp;
					</Box>
					<Box fontSize="sm" fg="faded" ucase>{ labels.byOfferField("notificationDate") }</Box>
				</Box>
				{/*<Box flex="1" textAlign="right">*/}
				{/*    <IconButton name={showDetails ? "close" : "more-horizontal"} onClick={onToggleDetails} />*/}
				{/*</Box>*/}
			</FlexVCenter>
			{selected && offer.priceTag && (
				<Box style={{gridColumnEnd: 'span 4'}}>
					<PriceTagDetails tag={offer.priceTag} isLast={isLast}/>
				</Box>
			)}
		</>
	)
}
