import React from 'react';
import { ExpContext } from './exp-types';
import { parseExpression } from './parse-expression';
import { expNodeToHtml } from './exp-node-to-html';

interface Props {
    readonly exp:string;
    readonly expCtx:ExpContext;
    readonly small?:boolean;
    readonly allowWrap?:boolean;
}
export const ExpStrToHtml = ({ exp, expCtx, small, allowWrap }:Props) => {
    const cssArr = ['ee-to-html-container'];
    if (small) cssArr.push('small');
    if (allowWrap) cssArr.push('allow-wrap');
    return <div className={cssArr.join(' ')}>
        { expNodeToHtml(parseExpression(exp, expCtx).node, null) }
    </div>
}
