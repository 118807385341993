import React from 'react';
import { WhenEnumInDetails } from './types';
import { WhenValuePassed } from './when-value-passed';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const TakeFromFailedToMap = ({ details }:Props) => {
    return (
        <>
            <WhenValuePassed details={details} />
            <div>However, we were unable to map { details.takeLabel }.</div>
        </>
    )
}