import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, Text, FlexVCenter, Tabs, BasicMenuItem, Input, ModalSubHeader, PortalPlaceholder, ModalFooter, Box, Button, Icon, ModalTitle, modal } from 'mui';
import { mapping, storage } from 'services';
import { TabMapping } from './tab-mapping';
import { TabTranslation } from './tab-translation';
import { TabAdvanced } from './tab-advanced';
import {
    // loanModel,
    RowsAndHeaders,
    Overrides,
    OverrideItem,
    TransCfg,
    ImportModel,
    // TranslationConfig,
    // getTheDictionaryItemById,
    // MapOverrideDict,
    // MapOverride,
    // getMasterConfig,
    // mergeOverrides,
} from 'market-dto';
import { usePrevious } from 'hooks';
import { motion, MotionProps } from 'framer-motion';

// const globalCfg = getMasterConfig();

const getMotionProps = (tab:string, lastTab:string | undefined):MotionProps => {
    if (!lastTab) {
        // This means first render
        return {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity:0, x:'-100vw' },
            transition: { duration: 0.12, delay: 0.12 } // TODO: ensure same as modal open anim timing
        }
    }
    const dir = tab !== 'mapping'; // This logic will change if new tabs added!
    return {
        initial: { x: dir ? '100vw' : '-100vw' },
        animate: { x: 0 },
        exit: { x: dir ? '-100vw' : '100vw' },
        transition: { duration: 0.25 }
    }
}

const hideEmptyKey = 'mapping-hide-empty';

interface MapModalProps {
    readonly model:ImportModel;
    readonly field:mapping.Field;
    readonly orides:Overrides;
    readonly items:mapping.ColumnValuesAndMapping[];
    readonly rowsAndHeaders:RowsAndHeaders;
    readonly onUpdate:(oride:Overrides) => void;
}
export const MapModal = ({ model, field:initField, orides, items, rowsAndHeaders, onUpdate }:MapModalProps) => {

    /*
        There are three mapConfigs:
            1. initial one
            2. edit one (changes that have not been confirmed)
            3. confirmed one (changes have been confirmed, but, modal still open)
    */

    const [ tab, setTab ] = useState<string>('mapping'); // default to first tab
    const lastTab = usePrevious(tab);

    const [ hideEmpty, setHideEmpty ] = useState<boolean>(() => !!storage.get(hideEmptyKey));
    const [ dirtyMap, setDirtyMap ] = useState<boolean>(false);
    const [ dirtyTrans, setDirtyTrans ] = useState<boolean>(false);
    const [ confirmedORides, setConfirmedORides ] = useState<Overrides>(orides);
    const [ editORides, setEditORides ] = useState<Overrides>(orides);
    const [ filterText, setFilterText ] = useState('');

    // const mapCfg = mergeOverrides(globalCfg, editORides); 

    const toggleHideEmpty = (flag:boolean) => {
        setHideEmpty(flag);
        storage.set(hideEmptyKey, flag);
    }

    // useEffect(() => {
    //     // Not really necessary to be in a useEffect, but, prevents needless merging i suppose...
    //     setMapCfg(mergeOverrides(globalCfg, editOrides));
    // }, [editOrides])


    const onNeverMapThis = () => {
        setDirtyMap(true);
        setEditORides(mapping.setMappingOverrideColumn(confirmedORides, initField.id, '__DO_NOT_MAP'));
    }

    const onRevertToDefaultMapping = () => {
        setDirtyMap(true);
        setEditORides(mapping.removeMappingOverrideColumn(confirmedORides, initField.id));
    }

    const onChangeMapping = (colIndex:number) => {
        setDirtyMap(true);
        const colName = rowsAndHeaders.headers[colIndex];
        setEditORides(mapping.setMappingOverrideColumn(confirmedORides, initField.id, colName));
        // setEditMapCfg(updateMapConfig(confirmedMapCfg, initField.id, colName));
    }

    const onChangeTranslation = (transCfg:TransCfg) => {
        setDirtyTrans(true);
        const a = mapping.setMappingOverrideTranslate(confirmedORides, initField.id, transCfg);
        setEditORides(mapping.setMappingOverrideTranslate(confirmedORides, initField.id, transCfg));
    }

    // This forces a tryMap (the call to getField)
    // TODO: this can be made more efficient! shouldn't map every time (maybe just memo it)
    const editField = mapping.getField(model, initField.id, editORides, rowsAndHeaders);
    if (!editField) throw new Error('Expected: editField');

    const reset = () => {
        setEditORides(confirmedORides);
        setDirtyMap(false);
        setDirtyTrans(false);
    }

    const update = () => {
        onUpdate(editORides);
        setConfirmedORides(editORides);
        setDirtyMap(false);
        setDirtyTrans(false);
    }

    const updateAndClose = () => {
        update();
        modal.close();
    }

    const unmappedOnPurpose = editField.cfg?.hint.never ? true : false;
    const isDirty = dirtyMap || dirtyTrans;
    const fieldCfg = model.getField(initField.id);
    const ride:OverrideItem = editORides[initField.id] ?? {};
    const canTrans = fieldCfg?.kind === 'enum' || Boolean(fieldCfg?.transOpts);

    const tabItems:BasicMenuItem[] = [
        {
            label: `Mapping${ dirtyMap ? ' *' : ''}`,
            value: 'mapping'
        },
        ...canTrans ? [{
            label: `Translation${ dirtyTrans ? ' *' : ''}`,
            value: 'translation'
        }] : [],
        {
            label: 'Advanced',
            value: 'advanced'
        }
    ]

    const motionProps = getMotionProps(tab, lastTab);
    return (
        <>
            <ModalTitle>
                <FlexVCenter>
                    <Text fg="loud">{ editField.label }</Text>
                    { editField.res.winnerLabel && (
                        <>
                            <Icon fg="default" name="arrow-left" inline mx="1" />
                            <Text fg="default">{ editField.res.winnerLabel }</Text>
                        </>
                    ) }
                    { unmappedOnPurpose && (
                        <Box ml="1" fontSize="sm" fg="faded">Intentionally Unmapped</Box>
                    ) }
                </FlexVCenter>
            </ModalTitle>
            <ModalSubHeader>

                <FlexVCenter justifyContent="space-between" mt="2" mx="2">
                    {/* mt="2" ml="2"  */}
                    { tabItems.length > 0 && <Tabs selected={tab} items={tabItems} onSelect={setTab} /> }


                    { tab === 'mapping' && (
                        <FlexVCenter justifyContent="flex-end" fontSize="sm">

                            <Checkbox label="Hide Empty Columns" checked={hideEmpty} onToggle={toggleHideEmpty} mr="1" />
                            <Input
                                size="sm"
                                width="lg"
                                appendIcon="search"
                                placeholder="Filter Columns"
                                value={filterText}
                                setValue={setFilterText}
                            />
                        </FlexVCenter>
                    ) }
                    {/* <IconButton name="more-horizontal" /> */}
                </FlexVCenter>

                <PortalPlaceholder id={mapping.ui.subHeaderPortalId} />
            </ModalSubHeader>

            { tab === 'mapping' && (
                <motion.div {...motionProps}>
                    <TabMapping
                        field={editField}
                        filterText={filterText.trim().toUpperCase()}
                        hideEmpty={hideEmpty}
                        initField={initField}
                        items={items}
                        onChange={onChangeMapping}
                    />
                </motion.div>
            ) }
            { tab === 'translation' && (
                <motion.div {...motionProps}>
                    <TabTranslation
                        ride={ride}
                        editField={editField}
                        fieldCfg={fieldCfg!}
                        canEdit={!dirtyMap}
                        items={items}
                        onChange={onChangeTranslation}
                    />
                </motion.div>
            ) }
            { tab === 'advanced' && (
                <motion.div {...motionProps}>
                    <TabAdvanced
                        mappedTo={editField.res.winnerLabel}
                        // unmappedOnPurpose={unmappedOnPurpose}
                        ride={ride}
                        onNeverMapThis={onNeverMapThis}
                        onRevertToDefaultMapping={onRevertToDefaultMapping}
                        // field={editField}
                        // hideEmpty={hideEmpty}
                        // initField={initField}
                        // items={items}
                        // onChange={onChangeMapping}
                    />
                </motion.div>
            ) }

            <ModalFooter>
                { isDirty && (
                    // TODO: if we like this bg-modal-header color, it needs a type!
                    <Box textAlign="right" py="1" pr="2" css="expand-in bg-modal-header">
                        <Button type="link" onClick={reset}>Reset</Button>
                        <Button ml="1" onClick={update}>Update</Button>
                        <Button ml="1" onClick={updateAndClose}>Update &amp; Close</Button>
                    </Box>
                )}
            </ModalFooter>

        </>
    )
}