import React from 'react';
import { tapeImporter } from 'services';
import { Text, Link } from 'mui';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
}
export const NoMatches = ({ batch, item }:Props) => {
    return (
        <>
            <Text fg="default" fontSize="sm" textStyle="italic">Unknown Tape Format</Text>
            &nbsp;&nbsp;
            <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Click to begin mapping</Link>
        </>
    )
}