import React from 'react';
import { rule } from 'services';
import { useZStateList } from 'hooks';
import { Loading } from 'components';
import { RulesRoutes } from './rules-routes';

export const Rules = () => {
    // Load em all up-front. For now. If things get big, worry about it then.
    const { fetching:loadingModels } = useZStateList(rule.zModels, { fetchArgs: [] });
    const { fetching:loadingChecklists } = useZStateList(rule.zChecklists, { fetchArgs: [] });
    const { fetching:loadingSamples } = useZStateList(rule.zModelSamples, { fetchArgs: [] });
    const loading = loadingModels || loadingChecklists || loadingSamples;
    return (
        <Loading
            loading={loading}
            render={<RulesRoutes />}
        />
    )
}



