import React from 'react';
import {
    modal,
    usePageSortTable,
    Icon,
    Text,
    TablePageButtons,
    SortableTableHeader 
} from 'mui';
import { rule } from 'services';
import { FieldModal } from './field-modal';

interface Props {
    readonly allFields:rule.RuleModelField[];
    readonly filterFields:rule.RuleModelField[];
    readonly groups:string[];
    readonly onFieldChange:(field:rule.RuleModelField) => void;
}
export const RuleModelFieldTable = ({ allFields, filterFields, groups, onFieldChange }:Props) => {

    const {
        sortedRows,
        buttons,
        pageSortState,
        sortBy,
        page,
        setPage,
        totalPages
    } = usePageSortTable<rule.RuleModelField, keyof rule.RuleModelField>({
        rows: filterFields,
        keyFn: x => x.fieldId + '^' + x.label + '^' + x.type, // TODO: make array of deps instead of this.
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const editModal = (field:rule.RuleModelField) => {
        modal.open({
            title: 'Edit Field',
            render: <FieldModal
                groups={groups}
                allFields={allFields}
                field={field}
                isNew={false}
                onSubmit={onFieldChange}
            />
        })
    }

    return (
        <>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <SortableTableHeader render="Field Id" ps={pageSortState} colId="fieldId" sortBy={sortBy} />
                        <SortableTableHeader render="Label" ps={pageSortState} colId="label" sortBy={sortBy} />
                        <SortableTableHeader render="Type" ps={pageSortState} colId="type" sortBy={sortBy} />
                        <th style={{ width:'1%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.length === 0 && (
                        <tr>
                            <td colSpan={4}><Text textStyle="italic">No fields to display!</Text></td>
                        </tr>
                    ) }
                    { sortedRows.length > 0 && sortedRows.map((x, n) => {
                        return <tr key={n}>
                            <td>{ x.fieldId }</td>
                            <td>{ x.label }</td>
                            <td>{ rule.ruleModelFieldTypeLabel[x.type] }</td>
                            <td align="center">
                                <Icon
                                    name="edit"
                                    fg="default"
                                    fgHover="loud"
                                    cursor="pointer"
                                    onClick={() => editModal(x)}
                                />
                            </td>
                        </tr>
                    }) }
                </tbody>
            </table>
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}