import { GQL } from 'market-dto';
import { PriceTagAndDatesByLoanNumber } from './types';

export const tagsToOffers = (
	loans: GQL.Loan[],
	tagAndDatesByLoanNumber: PriceTagAndDatesByLoanNumber,
	investorId: string,
	investorName: string
): Array<GQL.Offer> => {
	return loans.map(loan => {
		const x = tagAndDatesByLoanNumber[loan.loanNumber];
		if (!x) return null;
		return {
			sheetId: loan.sheetId,
			loanId: loan.id,
			investorId: investorId,
			investor: investorName,
			loanNumber: loan.loanNumber,
			priceTag: x.tag,
			offeringPrice: x.tag.price,
			// I have to do this:
			...(x.acquisitionDate ? {acquisitionDate: new Date(x.acquisitionDate)} : {}),
			...(x.securityDate ? {securityDate: new Date(x.securityDate)} : {}),
			// Instead of: (because empty string is falsey, just as good as not there)
			// acquisitionDate: tag.acquisitionDate,
			// securityDate: tag.securityDate,
			selected: false
		} as GQL.Offer
	}).filter(x => x !== null) as GQL.Offer[];
}
