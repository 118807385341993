import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { ActivityMonitorJob } from '../types';

// I removed queued job feature. Now server has queues.

type JobDict = {[jobId:string]:ActivityMonitorJob};

export const _jobs = new BehaviorSubject<JobDict>({});
export const _selectedJobId = new BehaviorSubject<string>('');

export const allJobs$ = _jobs.pipe(map(dict => Object.values(dict)));
export const activeJobs$ = _jobs.pipe(map(dict => Object.values(dict).filter(x => x.active)));
export const inactiveJobs$ = _jobs.pipe(map(dict => Object.values(dict).filter(x => !x.active)));

export const selectedJob$ = combineLatest([_jobs, _selectedJobId]).pipe(
    map(([ dict, selectedId ]):ActivityMonitorJob|null => {
        if (selectedId && dict[selectedId]) return dict[selectedId];
        return null;
    }),
    distinctUntilChanged() // don't emit unless the actual selected job changes
)