import React from 'react';
import { FlexVCenter } from '../flex/flex';
import { TopNavMenuItem } from './top-nav-menu-item';

export interface TopNavMenuItemProps {
    readonly label:React.ReactNode;
    readonly to:string;
    readonly matchPath:string;
}

interface Props {
    readonly items:TopNavMenuItemProps[];
    readonly cy?:string;
}
export const TopNavMenu = ({ items, cy }:Props) => {
    return (
        <FlexVCenter height="100%" cy={cy}>
            { items.map((item, n) => <TopNavMenuItem
                key={n}
                label={item.label}
                to={item.to}
                matchPath={item.matchPath}
            /> ) }
        </FlexVCenter>
    )
}

