import React from 'react';
import { Button, ButtonProps } from '../button/button';
import { useNavigate } from 'react-router-dom';

interface Props extends ButtonProps {
    readonly to:string;
}
export const NavButton = ({ to, ...props }: Props) => {
    const navigate = useNavigate();
    const clk = () => navigate(to);
    return <Button onClick={clk} {...props} />
}
