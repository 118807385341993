import React from 'react';
import { sheets } from 'services';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Text, Flex, Icon } from 'mui';


export const Error = () => {
    const navigate = useNavigate();
    const close = () => navigate('/tapes');
    return (
        <Alert mt="2" icon="alert-triangle">
            <Flex justifyContent="space-between">
                <Box>
                    There is a problem with some of the data related to this tape.
                    <Box fg="faded" mt="2">
                        Please contact mTrade with the following details:<br/>
                        Tape ID <Text fg="default">{ sheets.zCurrent.get()?.sheet.id }</Text>
                    </Box>
                </Box>
                <Icon name="close" fg="default" fgHover="link" onClick={close} cursor="pointer" />
            </Flex>
        </Alert>
    )
}