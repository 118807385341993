import { mutate, stripInternals, gql, GQL } from '../../client';
import { RETURNING } from './returning';

const INSERT = gql`
    mutation insertChecklist($objects: [checklist_insert_input!]) {
        insert_checklist(objects: $objects) {
            errorMessage
            affectedRow
            returning {
                ${ RETURNING }
            }
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.Checklist>]
}

interface MutateOutput {
    readonly insert_checklist: {
        readonly returning:[GQL.Checklist];
    }
}

export const insertChecklist = async (checklist:Partial<GQL.Checklist>):Promise<GQL.Checklist | undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [stripInternals(checklist)]
    })
    // const client = getApolloClient(false);
	// const result =  await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: INSERT,
	// 	variables: {
	// 		objects: [stripInternals(checklist)]
	// 	}
    // })
    return result?.data?.insert_checklist.returning[0];
}
