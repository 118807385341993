import { UserInputState, BatchItem } from '../types';
import { mergeBatchItem } from '../merge-batch-item';
import { getManualAssigned } from './get-manual-assigned';
import { onAssignedOrSkipped } from '../commands/on-assigned-or-skipped';

export const manualAssign = async (item:BatchItem, userInput:UserInputState) => {
    const assigned = await getManualAssigned(item, userInput);
    mergeBatchItem(item, {
        assigned,
        status: "TEMPLATE_ASSIGNED_AND_CONFIRMED",
        expanded: false
    })
    onAssignedOrSkipped();
}