import { query, withoutDeleted, gql, GQL } from "../../client"
import { format } from 'market-dto';
import { RETURNING } from './returning';


const QUERY = gql`
    query ($where: sheetSummary_bool_exp!) {
        sheetSummary(where: $where) { ${ RETURNING } }
    }
`;

interface QueryResult {
    readonly sheetSummary:Array<GQL.WithInternal<GQL.SheetSummary>>;
}


interface QueryInput {
    readonly where:GQL.WhereWhatever;
}

export const fetchSheetSummaries = async (dt:Date):Promise<Array<GQL.WithInternal<GQL.SheetSummary>>> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _startsWith: format.dateToYyyyMmDd(dt)
            },
            modelType: {
                _eq: "LOAN_MODEL"
            }
        }
    })
    return withoutDeleted(result.data.sheetSummary).sort((a, b) => {
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
    })
}

export const fetchSheetSummariesSince = async (dt:Date):Promise<Array<GQL.WithInternal<GQL.SheetSummary>>> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _gt: format.dateToYyyyMmDd(dt)
            },
            modelType: {
                _eq: "LOAN_MODEL"
            }
        }
    })
    return withoutDeleted(result.data.sheetSummary).sort((a, b) => {
        // id DESC, which means, most recent will be first in array.
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
    })
}