import React, { useState, useEffect } from 'react';
import { Box, Input } from 'mui';

interface Props {
    readonly desc:string;
    readonly onChange:(x:string) => void;
    readonly onEnter:() => void;
    //readonly focus:boolean;
}
export const ChecklistItemDescRow = ({ desc, onChange, onEnter }:Props) => {

    const [ inputElem, setInputElem ] = useState<HTMLInputElement|null>(null);

    // useEffect(() => {
    //     if (!inputElem || !focus) return;
    //     inputElem.focus();
    // }, [inputElem, focus])

    const submit = () => {
        if (!inputElem) return;
        inputElem.blur();
        onEnter();
    }

    return (
        <>
            <Box fg="faded" fontSize="sm" textAlign="right">Desc</Box>
            <Box>
                <Input
                    width="100%"
                    size="sm"
                    value={desc}
                    setValue={onChange}
                    ref={setInputElem}
                    onEnter={submit}
                />
            </Box>
            <Box style={{ gridColumn: 'span 2' }}></Box>
        </>
    )
}