import { tapeImporter, mapping } from 'services';
import { ExpandedState } from './types';

export interface MappingNeeds {
    readonly canSave:boolean;
    readonly highlightSellerName:boolean;
    readonly invalidSellerMapped:boolean;
    readonly isUnknownSeller:boolean;
    readonly needsSellerMapped:boolean;
}
export const getMappingNeeds = (
    isAgg:boolean,
    exState:ExpandedState


):MappingNeeds => {

    const { item, orides, sellerForAgg, sellerAliasForAgg, mappedSellerInfo, mappedForSellerOrg, selected } = exState;
    const { tapeId, rowsAndHeaders, sellers, matchResults } = item;

    if (!tapeId) throw new Error('expected tapeId');
    if (!rowsAndHeaders) throw new Error('expected rowsAndHeaders');
    // const { rowsAndHeaders } = tapeInfo;
    // if (!rowsAndHeaders) throw new Error('expected rows and headers');
    
    const { distinctSellerOrgIds, distinctSellerNames } = mappedSellerInfo;

    if (!isAgg) {
        return {
            canSave: true,
            // below don't apply to non-agg
            highlightSellerName: false,
            invalidSellerMapped: false,
            isUnknownSeller: false,
            needsSellerMapped: false

        }
    }

    const needsSellerMapped = distinctSellerNames.length === 0;
    const invalidSellerMapped = distinctSellerNames.length > 1;
    const highlightSellerName = needsSellerMapped || invalidSellerMapped;
    const isUnknownSeller = distinctSellerNames.length === 1 && distinctSellerOrgIds.length === 0;
    const needsUnknownSellerAssignedToOrg = isUnknownSeller && !sellerForAgg;    
    const canSave = !highlightSellerName && !needsUnknownSellerAssignedToOrg;
    return {
        canSave,
        highlightSellerName,
        invalidSellerMapped,
        isUnknownSeller,
        needsSellerMapped
    }
}