import React from 'react';
import { Button, FlexVCenter, NavTabs, NavMenuItem, IntoPortal, Icon } from 'mui';
import { useNavigate } from 'react-router-dom';

export const NavAndBtnArea = () => {

    const nav = useNavigate();
    const doneEditting = () => nav('..');
	
	const navItems:NavMenuItem[] = [
		{ label: "Config", to: "task" },
		{ label: "History", to: "runs" },
		{ label: "Details", to: "details" }
	]

    return (
        <FlexVCenter my="1" justifyContent="space-between" height="btn-row">
            <NavTabs items={navItems}/>
            <FlexVCenter>
                <Button
                    size="sm"
                    type="link"
                    onClick={doneEditting}
                    icon="run"
                >New Run</Button>
            </FlexVCenter>
        </FlexVCenter>
    )
}