import React from 'react';
import { JSONSchema } from 'market-dto';
import { SchemaUiArray } from './item-array';
import { SchemaUiItemString } from './item-string';
import { SchemaUiItemNumber } from './item-number';
import { SchemaUiItemBool } from './item-bool';
import { KeyValueDict } from './schema-ui.service';
import { DebugMsg } from '../debug-msg/debug-msg';

const render = (schema:JSONSchema, setValue:(val:any) => void, editting:boolean, val:any) => {
    switch (schema.type) {
        case 'array':
            return <SchemaUiArray setValue={setValue} schema={schema} val={val} editting={editting} />
        case 'string':
            return <SchemaUiItemString setValue={setValue} schema={schema} val={val} editting={editting} />
        case 'number':
            return <SchemaUiItemNumber setValue={setValue} schema={schema} val={val} editting={editting} />
        case 'boolean':
            return <SchemaUiItemBool setValue={setValue} schema={schema} val={val} editting={editting} />
        case 'integer':
            return <SchemaUiItemNumber setValue={setValue} schema={schema} val={val} int={true} editting={editting} />
    }
    return null;
}


interface Props {
    readonly editting:boolean;
    readonly path:string;
    readonly schema:JSONSchema;
    readonly data:KeyValueDict;
    readonly setValue:(val:any) => void;
}
export const SchemaUiItem = ({ path, schema, editting, data, setValue }:Props) => {
    const val = data.hasOwnProperty(path) ? data[path] : schema.default;
    return <>
        <DebugMsg>{ path }</DebugMsg>
        { render(schema, setValue, editting, val) }
    </>
}