import { query, gql, GQL } from "../../../client";
import { RUN_LIST_FIELDS } from './returning';
import { subDays, startOfDay } from 'date-fns';

interface QueryResult {
    readonly run:Array<Omit<GQL.FlowRun, "rows">>;
}

interface QueryInput {
    readonly where:GQL.WhereString;
}

const QUERY =  gql`
    query getRuns($where: where_run_exp) {
        run(where: $where) {
            ${ RUN_LIST_FIELDS }
        }
    }
`;

export const fetchRecentRunListByFlowId = async (flowId:string, daysAgo:number):Promise<Array<Omit<GQL.FlowRun, "rows">>> => {

    const sinceEndedAt = startOfDay(subDays(new Date(), daysAgo));

    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            flowId: {
                _eq: flowId
            },
            endedAt: {
                _gt: sinceEndedAt.toISOString()
            }
        }
    })
    if (result.data.run) {
        result.data.run.sort((a, b) => {
            if (a.startedAt < b.startedAt) return 1;
            if (a.startedAt > b.startedAt) return -1;
            return 0;
        })
    }
    return result.data.run;
}
