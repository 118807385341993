import cuid from "cuid"
import { Box, Button, CssGridBox, toastError } from "mui";
import {
	hintsFromCsv, InternalPricing, isLikelyATable, parseCSV, PricingTableType
} from "raccoon-engine"
import React, { useMemo, useState } from 'react';
import { InternalPricingFunc, } from "./internal-pricing"

const asTable = require("as-table")


interface Args {
	onAdding:InternalPricingFunc
}

const genId = (type:PricingTableType) => {
	return (type === "ratesheet") ? 'ratesheet' : cuid();
}
export const CutAndPaste = (props:Args) => {
	const { onAdding } = props
	const [csv, setCSV] = useState<any[][]>([])
	
	const onPaste = async (type:PricingTableType) => {
		const text = await navigator.clipboard.readText();
		const table = parseCSV(text);
		const isTable = isLikelyATable(table);
		if (!isTable || table.length < 3) {
			toastError("The data in clipboard is not like a table.");
		} else {
			const internal:InternalPricing = {
				type, csv: table,
				hints: hintsFromCsv(table),
				id: genId(type)
			};
			onAdding(internal)
			console.log("*****", internal)
			setCSV(table)
		}
	}
	
	
	const lines = useMemo(() => {return asTable(csv).split("\n");}, [csv]);
	
	return <div>
		<Box fontWeight="bold">
			Instruction
		</Box>
		<p>
			Some instructions and visual example go here. We accept a cut-and-paste content from
			the
			Excel. Please make sure the content matching one of the following formats:
		</p>
		
		<CssGridBox cols={4} mt="2" gap="2">
			<img className="cut-and-paste-img"
			     src="https://res.cloudinary.com/twks/image/upload/v1617896343/mtrade/iShot2021-04-08_10.38.45_jmonbu.png"
			     alt=""/>
			<img
				className="cut-and-paste-img"
				src="https://res.cloudinary.com/twks/image/upload/v1617895391/mtrade/iShot2021-04-08_10.21.57_eavqzo.png"
				alt=""/>
		</CssGridBox>
		
		<p>
			Make sure you have selected the proper range of data, and click one of the paste buttons
			below.
		</p>
		
		<Box bg="inner-card" p="3">
			<div>
				<Button size="sm" type="link" onClick={async () => onPaste("ratesheet")}>As the rate
					sheet</Button>
				<Button size="sm" type="link" onClick={async () => onPaste("llpa")}>As LLPA
					Table</Button>
				<Button size="sm" type="link" onClick={async () => onPaste("llra")}>As LLRA
					TAble</Button>
			</div>
			<div className="raw-data">
				{lines.map((line:any, idx:number) => <div className="mono-line"
				                                          key={idx}>{line}</div>)}
			</div>
		
		</Box>
	
	
	</div>
}
