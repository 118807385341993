import { GQL } from 'market-dto';
import { getUnformattedVal } from './get-unformatted-val';


interface ToRowsAndFilteredColCfgsResult {
    readonly rows:GQL.FlowRunRow[];
    readonly filteredColCfgs:GQL.XlsExportCol[];
    readonly emptyById:{[fieldId:string]:true};
}
export const toRowsAndFilteredColCfgs = (
    flowRun:GQL.FlowRun,
    fieldCfgs:GQL.XlsExportCol[],
    hideEmptyCols:boolean
):ToRowsAndFilteredColCfgsResult => {

    // 1. We filter out those columns which are marked as excluded by the user
    // 2. We filter out columns that have no data if hideEmptyCols is true

    // IMPORTANT SUBTLE POINT:
    // left side NEVER excludes those flagged for exlusion, BUT, it DOES exclude those when user has said "hide empty"

    const allFieldCfgs = fieldCfgs;
    const allRows = flowRun.rows;
    const nonExcludedFieldCfgs = fieldCfgs.filter(x => !x.exclude);
    const emptyById:{[fieldId:string]:true} = {};

    allFieldCfgs.forEach(fieldCfg => {
        if (allRows.every(row => !getUnformattedVal(nonExcludedFieldCfgs, fieldCfg, row))) {
            emptyById[fieldCfg.colId] = true;
        }
    })
    
    const nonExcludedRows = flowRun.rows.filter(row => {
        const disqualified = nonExcludedFieldCfgs.some(fieldCfg => {
            if (fieldCfg.filterVals.length === 0) return false;
            const val = getUnformattedVal(nonExcludedFieldCfgs, fieldCfg, row) ?? '';
            return fieldCfg.filterVals.includes(val) ? false : true;
        })
        return !disqualified;
    })

    if (!hideEmptyCols) {
        return {
            rows: nonExcludedRows,
            filteredColCfgs: nonExcludedFieldCfgs,
            emptyById
        }
    }

    const filteredColCfgs = nonExcludedFieldCfgs.filter(fieldCfg => {
        return nonExcludedRows.some(row => getUnformattedVal(nonExcludedFieldCfgs, fieldCfg, row))
    })

    // const leftSideColsCfgs = allFieldCfgs.filter(fieldCfg => {
    //     return allRows.some(row => getUnformattedVal(allFieldCfgs, fieldCfg, row))
    // })

    return {
        rows: nonExcludedRows,
        filteredColCfgs,
        emptyById
    }

}