import { saveAs } from 'file-saver';
import { sheetMaker } from 'market-dto';

export const downloadFromArrayBuffer = (arrBuf:ArrayBuffer, filename:string) => {
    saveAs(new Blob([arrBuf], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" }), filename);
}

export const downloadFromBlob = (blob:Blob, filename:string) => saveAs(blob, filename);

export const downloadJsonFile = (val:any, filename:string) => {
    const json = typeof val === 'string' ? val : JSON.stringify(val, null, 4);
    const blob = new Blob([json], { type: 'text/json;charset=utf-8' });
    saveAs(blob, filename);
}

export const downloadCsvFile = (rows:string[][], filename:string) => {
    const csvText = rows.map(cols => {
        return cols.map(col => {
            return '"' + col.replace(/"/g, '""') + '"';
        }).join(',');
    }).join('\n');
    const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
}

export const downloadSimpleXLSX = async (headers:string[], rows:string[][], tabName:string = "Tab1", filename:string) => {
    const arrBuf = await sheetMaker.makeSheet([ headers, ...rows ], tabName);
    downloadFromArrayBuffer(arrBuf, filename);
}