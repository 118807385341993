import React, { useState } from 'react';
import { Box, Dropdown, DropdownItem, FlexVCenter, Button } from 'mui';
import { GQL, ImportModel } from 'market-dto';
import { getHeaderLabel } from '../get-header-label';
import { EnumGetValues } from './enum-get-values';
import { SrcColCfgAndEntry } from './types';

interface Props {
    readonly model:ImportModel;
    readonly colCfgs:GQL.XlsExportCol[];
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
}
export const Enum = ({
    model,
    colCfgs,
    onColCfgsChange,
    selectedColId,
    setSelectedColId
}:Props) => {

    const [ src, setSrc ] = useState<GQL.XlsExportMappedCol>();
    const [ confirmedSrc, setConfirmedSrc ] = useState<SrcColCfgAndEntry>();

    // These are our source options.
    const [ opts ] = useState<DropdownItem<GQL.XlsExportMappedCol>[]>(() => {
        return colCfgs
            .filter(x => {
                if (x.type !== "MAPPED") return false;
                const { fieldId } = x;
                const entry = model.getField(fieldId);
                if (!entry) return false;
                if (entry.kind !== "enum") return false;
                return true;
            })
            .map(x => {
                return {
                    label: getHeaderLabel(model, x),
                    value: x as GQL.XlsExportMappedCol // filter has enforced this
                }
            })
    })


    const go = () => {
        if (!src) return;
        if (src.type !== "MAPPED") return; // shouldn't be possible
        const { fieldId } = src;
        const entry = model.getField(fieldId);
        if (!entry || entry.kind !== "enum") return false;
        setConfirmedSrc({
            entry: entry,
            cfg: src
        })
        
    }
    
    return (
        <Box>
            <Box mb="1" pb="1" borderColor="alert" borderSides="bottom" borderWidth="thin">
                Expanded Enumeration Column
            </Box>
            { !confirmedSrc && (
                <FlexVCenter pt="1">
                    <Box flex="1" mr="1">
                        <Dropdown
                            placeholder="Which field will we be basing this on?"
                            items={opts}
                            value={opts.find(x => x.value === src)}
                            setValue={setSrc}
                        />
                    </Box>
                    <Button
                        type={src ? 'default' : 'disabled'}
                        onClick={go}
                    >Continue</Button>
                </FlexVCenter>
            ) }
            { confirmedSrc && (
                <EnumGetValues
                    model={model}
                    colCfgs={colCfgs}
                    src={confirmedSrc}
                    onColCfgsChange={onColCfgsChange}
                    selectedColId={selectedColId}
                    setSelectedColId={setSelectedColId}
                />
            ) }
        </Box>
    )
}