import React, { useState, useEffect, useRef } from 'react';
import { SpinLabel, Box, Button, Dropdown, DropdownItem, RenderMenuItem, RenderTabs, FlexVCenter, Icon, modal, Input, Text } from 'mui';
import { useNavigate } from "react-router-dom";
import { rule, downloader } from 'services';
import { NewGroupModal } from './new-group-modal';
import { FieldModal } from './field-modal';
import { RuleModelFieldTable } from './rule-model-field-table';
import { EditModelNameModal } from './edit-model-name-modal';
import { useZStateList } from 'hooks';

export const RuleModelDetails = () => {

    const {
        editItem:model,
        updateItem,
        updating,
        resetItem:reset,
        editItemDirty:dirty,
        commitItem
    } = useZStateList(rule.zModels);
    if (!model) throw new Error('Expected model');

    const navigate = useNavigate();
    // For each model, show the rules.
    const [ groupTab, setGroupTab ] = useState('__all');
    const [ filterText, setFilterText ] = useState('');

    const { data:allSamples } = useZStateList(rule.zModelSamples);
    const samples = allSamples ? allSamples.filter(x => x.modelId === model.id) : [];

    const lcaseFilterText = filterText.trim().toLowerCase();
    const filteredFields = model.fields.filter(f => {
        if (groupTab !== '__all' && !f.groups.includes(groupTab)) return false;
        return f.fieldId.toLowerCase().includes(lcaseFilterText) ||
            f.label.toLowerCase().includes(lcaseFilterText);
    })

    const opts:DropdownItem[] = [
        {
            label: 'All Fields',
            value: '__all'
        },
        ...model.groups.map(x => {
            return {
                label: x,
                value: x
            }
        })
    ]

    const openAddGroupModal = () => {
        modal.open({
            title: 'Add New Group',
            size: 'sm',
            render: <NewGroupModal
                groups={model.groups}
                onAdd={x => updateItem({ groups: model.groups.concat(x) })}
            />
        })
    }

    const onFieldAdd = (field:rule.RuleModelField) => {
        updateItem({ fields: model.fields.concat({ ...field, index: model.fields.length }) })
    }

    const onFieldChange = (field:rule.RuleModelField) => {
        updateItem({ fields: model.fields.map(x => x.index !== field.index ? x : field) })
    }

    const openAddFieldModal = () => {
        modal.open({
            title: 'New Field',
            render: <FieldModal
                groups={model.groups}
                allFields={model.fields}
                field={rule.createNewField()}
                isNew={true}
                onSubmit={onFieldAdd}
            />
        })
    }

    const close = () => navigate('/rules/models');

    const saveModel = () => {
        if (!dirty) return;
        commitItem();
    }


    const setNameAndDesc = (name:string, desc:string) => {
        updateItem({ name, description: desc });
        modal.close();
    }

    const openNameModal = () => {
        modal.open({
            title: 'Model Name & Description',
            render: <EditModelNameModal model={model} onChange={setNameAndDesc} />
        })
    }
    
    // const goSamples = () => {
    //     history.push('/rules/samples');
    // }

    const downloadJson = () => {
        downloader.downloadJsonFile(rule.toGqlModel(model), model.id + ".model.json");
    }

    return (
        <Box>
            <FlexVCenter justifyContent="space-between">
                <FlexVCenter fg="default" fontSize="md">
                    <Text fg="faded">Model: </Text>
                    &nbsp;{ model.name }&nbsp;
                    { model.description && (
                        <Text fg="faded" fontSize="sm" textStyle="italic"> - { model.description }</Text>
                    ) }
                    <Icon ml="1" name="edit" cursor="pointer" fg="faded" fgHover="loud" onClick={openNameModal}/>
                </FlexVCenter>
                <Box>
                    <Button ml="1" onClick={close} size="sm" type="link">Close</Button>
                    { dirty && (
                        <Button
                            ml="1"
                            onClick={reset}
                            size="sm"
                            type="secondary"
                        >Reset</Button>
                    ) }
                    <Button
                        ml="1"
                        size="sm"
                        busy={updating}
                        onClick={saveModel}
                        type={dirty ? 'default' : 'disabled'}
                    >Save Model</Button>
                </Box>
            </FlexVCenter>

            <FlexVCenter my="2" justifyContent="space-between">
                <FlexVCenter>
                    <Input
                        placeholder="Filter"
                        value={filterText}
                        setValue={setFilterText}
                        appendIcon="search"
                    />
                    <Box ml="1">
                        { filteredFields.length }
                        &nbsp;
                        <Text fg="faded">Field{ filteredFields.length === 1 ? '' : 's' }</Text>
                    </Box>
                    {/* <Box ml="2">
                        <Box onClick={goSamples} cursor="pointer">
                            { samples.length }&nbsp;
                            <Text fg="faded" fgHover="link">{ format.plural("Sample", samples.length) }</Text>
                        </Box>
                    </Box> */}
                </FlexVCenter>
                <FlexVCenter>
                    <Button
                        mr="1"
                        size="sm"
                        type="link"
                        icon="download"
                        onClick={downloadJson}
                    >JSON</Button>
                    <Button
                        mr="1"
                        size="sm"
                        type="link"
                        onClick={openAddGroupModal}
                    >Add Group</Button>
                    <Button
                        mr="1"
                        size="sm"
                        type="link"
                        onClick={openAddFieldModal}
                    >Add Field</Button>
                    <Dropdown
                        size="sm"
                        width="frm-sm"
                        value={opts.find(x => x.value === groupTab)}
                        setValue={setGroupTab}
                        items={opts}
                    />

                </FlexVCenter>
            </FlexVCenter>

            <RuleModelFieldTable
                allFields={model.fields}
                filterFields={filteredFields}
                groups={model.groups}
                onFieldChange={onFieldChange}
            />

        </Box>
    )
}
