import React from 'react';
import { NavLink as RouterNavLink, useResolvedPath, useMatch } from 'react-router-dom';
import { Box } from '../box/box';
import { TabItemProps } from './types';

export const TabItem = ({ item }:TabItemProps) => {

    const { label, to, onSelect, exact, absoluteMatchPath } = item;

    // "to" is likely relative
    // Could be "dog" or "../dog" or it could be absolute "/animals/dog"
    // useResolvedPath saves us the trouble of caring.
    const resolvedTo = useResolvedPath(exact ? to : to + '/*');
    const matchAgainst = absoluteMatchPath ? absoluteMatchPath : resolvedTo.pathname;
    const active = useMatch(matchAgainst) ? true : false;

    if (onSelect) {
        return (
            <Box
                onClick={() => onSelect && onSelect()}
                fg="faded"
                fgHover="link"
                px="2"
                pb="1"
                cursor="pointer"
                borderColor="menu"
                borderSides="bottom"
                borderWidth="thin"
                borderColorHover="menu-hover"
                transition="default"
            >{ label }</Box>            
        )
    }

    if (active) {
        return (
            <Box
                fg="loud"
                px="2"
                pb="1"
                borderColor="menu-active"
                borderSides="bottom"
                borderWidth="thin"
                transition="default"
            >{ label }</Box>
        );
    }
    return (
        <RouterNavLink to={to}>
            <Box
                fg="faded"
                fgHover="link"
                px="2"
                pb="1"
                cursor="pointer"
                borderColor="menu"
                borderSides="bottom"
                borderWidth="thin"
                borderColorHover="menu-hover"
                transition="default"
            >{ label }</Box>
        </RouterNavLink>
    )
}
