import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SampleDataContainer } from './sample-data-container';
import { toFieldsWithValues, ExpFieldWithValue } from 'exp';
import { FlexVCenter, PortalPlaceholder, Box, Text, Icon, modal } from 'mui';
import { useZStateList } from 'hooks';
import { rule } from 'services';
import { SampleModal } from './sample-modal';

export const EditSample = () => {
	const { id } = useParams();
    if (!id) throw new Error('how can there not be an id?');

    const navigate = useNavigate();

    const [ fields, setFields ] = useState<ExpFieldWithValue[]>([]);

    const {
        data:samples,
        selectItem,
        editItem:sample,
        updateItem,
        commitItem,
        editItemDirty:dirty,
        resetItem,
        updating
    } = useZStateList(rule.zModelSamples);

    useEffect(() => {
        const sample = samples?.find(x => x.id === id);
        if (sample) selectItem(sample);
    }, [id])

    useEffect(() => {
        const { modelId, data } = sample ?? {};
        const model = rule.getGQLModelById(modelId);
        if (!model) {
            setFields([]);
            return;    
        }
        setFields(toFieldsWithValues(model.schema, data ?? {}));
    }, [sample])

    const onSampleDataChange = (nextData:any) => {
        console.log('setting the thing', JSON.stringify(nextData, null, 4));
        updateItem({ data: nextData });
    }

    const editName = () => {
        modal.open({
            title: 'Edit Sample',
            render: <SampleModal />
        })
    }

    if (!sample) return <></>;

    return (
        <>
            <FlexVCenter mb="2">
                <FlexVCenter>
                    Sample Data:&nbsp;
                    <Text fg="loud">{ sample.name }</Text>                    
                </FlexVCenter>
                <Icon ml="1" name="edit" cursor="pointer" fg="default" fgHover="loud" onClick={editName} />
            </FlexVCenter>
            
            <FlexVCenter justifyContent="space-between" mb="2">
                <Box flex="1">
                    <PortalPlaceholder id="sample-fields-filter-area" />
                </Box>
                <FlexVCenter flex="1" justifyContent="flex-end">
                    <PortalPlaceholder id="sample-fields-button-area" />
                </FlexVCenter>
            </FlexVCenter>

            <SampleDataContainer
                onClose={() => navigate('/rules/samples')}
                onReset={resetItem}
                onSave={commitItem}
                dirty={dirty}
                updating={updating}
                fieldsWithValues={fields}
                sample={sample}
                sampleData={sample.data ?? {}}
                setSampleData={onSampleDataChange}
            />
        </>
    )
}
 