import {
    MinimalLoan,
    GQL,
    RowsAndHeaders,
    TemplateMapping,
    Overrides,
    ImportModel
} from 'market-dto';
import { isLocalhost } from '../../urls';
import { Generated } from '../types';

const assignLoanIds = (tapeId:string, loans:MinimalLoan[]):MinimalLoan[] => {
    return loans.map((loan, n) => {
        const loanId = tapeId + '-' + (n+1).toString().padStart(3, "0");
        return {
            ...loan,
            id: loanId
        }
    })
}

const randomString = () => {
    const arr = [];
    for (let i=0; i < 12; i++) arr.push(String.fromCharCode(97+Math.floor(Math.random() * 26)))
    return arr.join('');
}

const loansWithUniqueLoanNumbers = (model:ImportModel, loans:MinimalLoan[]):MinimalLoan[] => {    
    return isLocalhost && model.modelType === "LOAN_MODEL" ? loans.map(loan => {
        return {
            ...loan,
            loanNumber: randomString()
        }
    }) : loans;
}


const findMatchingSeller = (sellerOrgs:GQL.Org[], sellerNameOrAlias:string):GQL.Org|undefined => {
    if (typeof sellerNameOrAlias !== 'string') return undefined;
    const val = sellerNameOrAlias.trim().toLowerCase();
    if (!val) return undefined;
    return sellerOrgs.find(org => {
        return org.name.trim().toLowerCase() === val || org.aliases.find(a => a.trim().toLowerCase() === val)
    })
}

const loansWithSellerId = (loans:MinimalLoan[], sellerOrgs:GQL.Org[]):MinimalLoan[] => {
    return loans.map(loan => {
        const seller = findMatchingSeller(sellerOrgs, loan.sellerName);
        return {
            ...loan,
            sellerOrgId: seller ? seller.id : ''
        }
    })
}

// const toGeneratedLoanModel = (tapeId:string, sellerOrgs:GQL.Org[], mapped:MapResult<GQL.Loan>):Generated => {
//     const { items:rawLoans, sourceMap } = mapped;
//     const loans = assignLoanIds(tapeId, loansWithSellerId(loansWithUniqueLoanNumbers(rawLoans), sellerOrgs));
//     return {
//         loans,
//         sourceMap
//     }
// }

// const toGeneratedBidTapeModel = (tapeId:string, sellerOrgs:GQL.Org[], mapped:MapResult<BidTapeModel>):Generated => {
//     const { items:rawLoans, sourceMap } = mapped;
//     const loans = assignLoanIds(tapeId, loansWithSellerId(rawLoans, sellerOrgs));
//     return {
//         loans,
//         sourceMap
//     }
// }

export const createLoans = (
    model:ImportModel,
    tapeId:string,
    rowsAndHeaders:RowsAndHeaders,
    sellerOrgs:GQL.Org[],
    templateMapping?:TemplateMapping
):Generated => {
    const { sourceMap, items } = model.tryMap({ rowsAndHeaders, templateMapping });
    const loans = assignLoanIds(tapeId, loansWithSellerId(loansWithUniqueLoanNumbers(model, items), sellerOrgs));
    return {
        loans,
        sourceMap
    }
    // switch (modelType) {
    //     case "LOAN_MODEL": {
    //         const mapped = loanModel.tryMap({ rowsAndHeaders, templateMapping });
    //         return toGeneratedLoanModel(tapeId, sellerOrgs, mapped);
    //     }
    //     case "BID_TAPE_MODEL": {
    //         const mapped = bidTapeModel.tryMap({ rowsAndHeaders, templateMapping });
    //         return toGeneratedBidTapeModel(tapeId, sellerOrgs, mapped);
    //     }
    // }
    // throw new Error('Unhandled model type');
}

export const createLoansViaOverrides = (
    model:ImportModel,
    tapeId:string,
    rowsAndHeaders:RowsAndHeaders,
    sellerOrgs:GQL.Org[],
    orides:Overrides
):Generated => {
    const { sourceMap, items } = model.tryMap({ rowsAndHeaders, orides });
    const loans = assignLoanIds(tapeId, loansWithSellerId(loansWithUniqueLoanNumbers(model, items), sellerOrgs));
    return {
        loans,
        sourceMap
    }
    // switch (modelType) {
    //     case "LOAN_MODEL": {
    //         const mapped = loanModel.tryMap({ rowsAndHeaders, orides });
    //         return toGeneratedLoanModel(tapeId, sellerOrgs, mapped);
    //     }
    //     case "BID_TAPE_MODEL": {
    //         const mapped = bidTapeModel.tryMap({ rowsAndHeaders, orides });
    //         return toGeneratedBidTapeModel(tapeId, sellerOrgs, mapped);
    //     }
    // }
    // throw new Error('Unhandled model type');
}

