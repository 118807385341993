import React, { useState, forwardRef } from 'react';
import { ExpressionEditor } from './expression-editor';
import { ExpContext, ExpressionEditorState } from './exp-types';
import { DebugExp } from './debug-exp';

interface Props {
    readonly debug?:boolean;
    readonly initVal:string;
    readonly expCtx:ExpContext;
    readonly autoFocus?:boolean;
    readonly onChange?:(expState:ExpressionEditorState) => void;
    readonly onSubmit?:(tabKey:boolean, ctrlDown:boolean, shiftDown:boolean) => void;
    readonly onFocus?:() => void;
    readonly onBlur?:() => void;
    readonly clearOnValidSubmit?:boolean;
}
export const Editor = forwardRef((props:Props, ref:React.Ref<HTMLInputElement>) => {

    const { clearOnValidSubmit, onSubmit, initVal, onChange, autoFocus, expCtx, debug, onFocus, onBlur } = props;

    const [ expState, setExpState ] = useState<ExpressionEditorState>({
        caret: 0,
        exp: initVal,
        isValid: true,
        isTotallyValid: true,
        isValidMathJs: true,
        isValidRegardlessOfMathJs: true,
        rootNode: null,
        activeNode: null,
        senseOpts: null,
        warnings: []
    })

    const onEnter = (x:ExpressionEditorState, ctrlDown:boolean, shiftDown:boolean) => {
        if (onSubmit) onSubmit(false, ctrlDown, shiftDown);
    }

    const onTab = (x:ExpressionEditorState) => {
        if (onSubmit) onSubmit(true, false, false); // ctrl/shift might be down, but, we don't care when tabbing. (for now)
    }

    const expChange = (x:ExpressionEditorState) => {
        setExpState(x);
        if (onChange) onChange(x);
    }

    return <>
        { debug && <DebugExp expState={expState} expCtx={expCtx} /> }
        <ExpressionEditor
            onFocus={onFocus}
            autoFocus={autoFocus}
            ref={ref}
            initVal={expState.exp}
            expCtx={expCtx}
            onChange={expChange}
            onEnter={onEnter}
            onTab={onTab}
            onBlur={onBlur}
            clearOnValidSubmit={clearOnValidSubmit}
        />        
    </>

})
