import { Box, Button, CssGridBox } from "mui"
import {
	Conventions, Coordinate, DataTable, findByConvention, isFico, isLtv, isTerm, NumericalValue,
	wildGuessingNumber
} from "raccoon-engine"
import React, { useEffect, useState } from 'react';
import { HintDetails } from "./hint-details"
import tableCell from './svg/table-cell.svg'
import tableCol from './svg/table-column.svg'
import tableData from './svg/table-data.svg'
import tableRow from './svg/table-row.svg'
import { findImpacted, guessOrientation, OrientationType, withoutDigits } from "./utils";

interface Args {
	c:Coordinate,
	dataTable:DataTable
	hintTable:DataTable
	onClose:Function
	numericalValue:NumericalValue
	onChanged:Function
}

export const ConfigCell = (props:Args) => {
	const { c: { x, y }, dataTable, hintTable, onChanged, onClose } = props
	const [orientation, setOrientation] = useState<OrientationType>("Cell")
	const noDigit = withoutDigits(dataTable.getPosition(x, y))
	useEffect(() => {
		let determination = guessOrientation(dataTable, x, y)
		setOrientation(determination)
	}, [x, y, dataTable])
	const cln = (s:OrientationType) => ["hint-option",
		((s === orientation) ? "hint-option-selected" : "")].join(" ")
	const changedAndClose = () => {
		onChanged();
		onClose()
	}
	
	const applyTo = (f:Function) => {
		const impacts = findImpacted(orientation, x, y, dataTable)
		impacts.forEach(c => {
			const { x, y } = c
			hintTable.fillPosition(x, y, f(dataTable.getPosition(x, y)))
		})
		changedAndClose()
	}
	
	
	const onSetDataCorner = () => applyTo((s:string) => ({
		interested: props.numericalValue, value: wildGuessingNumber(s)
	}))
	
	const fitColumnOrCell = orientation === "Column" || orientation === "Cell"
	const switchTo = (o:OrientationType) => setOrientation(o)
	
	const onSetFico = () => {applyTo(isFico)}
	const onSetProduct = () => applyTo((s:string) => ({ interested: "Product", name: s }))
	const onSetRate = () => applyTo((s:string) => ({
		interested: "NoteRate", value: wildGuessingNumber(s)
	}))
	const onConvention = () => applyTo((s:string) => {
		return findByConvention(s, Conventions,)
	})
	
	
	return <>
		<Box>
			<Box>
				<HintDetails fieldHint={hintTable.getPosition(x, y)}/>
			</Box>
			<Box p="3" fg="faded">
				Based on the location and the data, we think you are specifying
				data in the following option (...more instructions to come)
			</Box>
			<CssGridBox cols={4}>
				<div className={cln("Cell")} onClick={() => switchTo("Cell")}>
					<img className="module-icon" src={tableCell} alt=""/>
					<Box textAlign="center">
						Cell
					</Box>
				</div>
				<div className={cln("Column")} onClick={() => switchTo("Column")}>
					<img className="module-icon" src={tableCol} alt=""/>
					<Box textAlign="center">
						Column
					</Box>
				</div>
				<div className={cln("Row")} onClick={() => switchTo("Row")}>
					<img className="module-icon" src={tableRow} alt=""/>
					<Box textAlign="center">
						Row
					</Box>
				</div>
				<div className={cln("Corner")} onClick={() => switchTo("Corner")}>
					<img className="module-icon" src={tableData} alt=""/>
					<Box textAlign="center">
						Corner
					</Box>
				</div>
			</CssGridBox>
			
			<CssGridBox cols={3} mt="2">
				
				{
					orientation === "Corner" && <Button type="link" onClick={onSetDataCorner}>
						As Data Table
					</Button>
				}
				
				{
					orientation !== "Corner" && <>
						<Button type="link" onClick={onSetFico}>
							As FICO
						</Button>
						<Button type="link" onClick={() => applyTo((s:string) => (isLtv(s)))}>
							LTV
						</Button>
						<Button type="link" onClick={() => applyTo((s:string) => (isTerm(s)))}>
							Term
						</Button>
						<Button type="link" onClick={onConvention}>
							Convention
						</Button>
					</>
				}
				{
					fitColumnOrCell && noDigit && <>
						<Button type="link" onClick={onSetProduct}>
							Product
						</Button>
						<Button type="link" onClick={onSetRate}>
							Note Rate
						</Button>


					</>
				}
			</CssGridBox>
		</Box>
	</>
}
