import React from 'react';
import { Box, IntoPortal }from 'mui';
import { ExpWarning  }from 'exp';

interface Props {
    readonly warnings:ExpWarning[];
} 
export const Warnings = ({ warnings }:Props) => {
    if (warnings.length === 0) return null;

    return (
        <IntoPortal id="exp-warning-area">
            <Box fontSize="sm" fg="danger">{ warnings.map(x => x.msg).join(', ') }</Box>
        </IntoPortal>
    )
}