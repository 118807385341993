
interface EndPointUrl {
	spa: string;
	apiUrl: string;
}
const endpointsTables: EndPointUrl[] = [
	{
		spa: "alpha",
		apiUrl:
			"https://dz-stg-eus-mstudio-apimgntsvc.azure-api.net/DZ-STG-EUS-MSTUDIO-GQL-FUNC/Graphql",
	},
	{
		spa: "sales",
		apiUrl: "https://dz-sales-eus-mstudio-apimgntsvc.azure-api.net/DZ-SALES-EUS-MSTUDIO-GQL-FUNC-APP/Graphql",
	},
	{
		spa: "www",
		apiUrl: "https://dz-prod-eus-mstudio-apimgntsvc.azure-api.net/DZ-PROD-EUS-MSTUDIO-GQL-FUNC-APP/Graphql",
	},
	{
		spa: "try",
		apiUrl: "https://express-market.azure-api.net/czmrktgqlfunc/Graphql",
	},
	{
		spa: "*",
		apiUrl: "https://express-market.azure-api.net/czmrktgqlfunc/Graphql",
	},
];

export const getApiUrl = () => {
	const domain = window.location.hostname.toLowerCase();
	const found = endpointsTables.find((endPoint) =>
		domain.includes(endPoint.spa)
	);
	return found
		? found.apiUrl
		: "https://express-market.azure-api.net/czmrktgqlfunc/Graphql";
};

export const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

export const isTry = window.location.hostname.startsWith('try.');
export const isTryOrLocalhost = isTry || isLocalhost;
