import { FieldHint, HintedField } from "raccoon-engine"
import React from 'react';
import { isPriceValue } from "./utils"
import { HintDisplay } from "./hint-display";
import { HintDiv } from "./hint-div"


interface Args<T extends HintedField> {
	data:string
	x:number
	y:number
	key:any
	selected?:boolean
	onMouseDown:Function
	onMouseUp:Function
	onMouseOver:Function
	onConfig:Function
	fieldHint?:FieldHint<T>
}

export const CsvCell = <T extends HintedField> (props:Args<T>) => {
	const {
		data, x, y, onMouseDown,
		onMouseUp, onMouseOver, onConfig,
		selected, fieldHint
	} = props
	
	const { interested } = (fieldHint || {}) as any
	const isPrice = isPriceValue(interested)
	const noHint = (!interested)
	
	const cln = ["cvs-cell "
		, ((selected) ? " selected" : "")
		, (noHint ? " no-hint" : "")
	].join(" ")
	
	const dataCln = [(isPrice ? "cvs-cell-price" : "")].join(" ")
	
	
	if (!data) {
		return <div>{""}</div>
	}
	
	
	return <div className={cln}
	            onClick={() => onConfig({ x, y })}
	            onMouseDown={() => onMouseDown({ x, y })}
	            onMouseUp={() => onMouseUp({ x, y })}
	            onMouseOver={() => onMouseOver({ x, y })}>
		<HintDiv className="control-row" fieldHint={fieldHint}>
			<div><HintDisplay fieldHint={fieldHint}/></div>
			{/*<div className="control">*/}
			{/*	<Icon name="settings" onClick={() => onConfig({ x, y })}/>*/}
			{/*</div>*/}
		</HintDiv>
		<div className={dataCln}>{data}</div>
	</div>
}
