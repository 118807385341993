import React from 'react';
import { Box, Text, Icon } from 'mui';
import { tapeImporter } from 'services';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
}
export const ColConfirmed = ({ item, batch }:Props) => {

    if (tapeImporter.isBeforeStep(batch, "UI_CONFIRM_TEMPLATES")) return <></>

    // if (tapeImporter.isBeforeOrAtStatus(item, "SEARCHING_TEMPLATES")) {
    //     return <></>
    // }

    if (item.skipped || item.error) {
        return <Icon name="close" fg="danger" />
    }

    if (tapeImporter.isBeforeStatus(item, "TEMPLATE_ASSIGNED_AND_CONFIRMED")) {
        return <Icon name="check" fg="really-faded" />
    }

    return <Icon name="check" fg="success" />
}