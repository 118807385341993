import React, { useState, useEffect } from 'react';
import { Box, Text, IntoPortal, Button } from 'mui';
import { useNavigate } from 'react-router-dom';
import { GQL } from 'market-dto';
import { useParams } from 'react-router-dom';
import { FlowRunWithTapeId } from './flow-run-with-tape-id';
import { SummaryBar } from './summary-bar';

interface Props {
    readonly flow:GQL.Flow;
    readonly closeFlow:() => void;
}
export const AutoRun = ({ flow, closeFlow }:Props) => {

    const { tapeId } = useParams();
    const nav = useNavigate();
    const edit = () => nav('../../edit');

    return (
        <>
            <SummaryBar
                closeFlow={closeFlow}
                isNewFlow={false}
                onEdit={edit}
                readOnly={true}
                flow={flow}
                tapeId={tapeId}
            />
            <Box mt="2">
                <FlowRunWithTapeId
                    flow={flow}
                    tapeId={tapeId}
                />
            </Box>
        </>
    )
}