import React, { useState } from 'react';
import { Box, Flex, FlexVCenter, Button, Icon, ModalTitle, modal } from 'mui';
import { FileDropArea, HeatMapModal, InvalidJson } from 'components';
import { useSafeState } from 'hooks';
import { profit } from 'services';

// HeatMapModal
export const HeatMapViewerModal = () => {

    const [ heatMapData, setHeatMapData ] = useSafeState<profit.HeatMapExportData|null>(null);

    const drop = async (files:File[]) => {
        if (files.length !== 1) return;
        const file = files[0];
        const json = await file.text();
        try {
            const obj = JSON.parse(json) as profit.HeatMapExportData;
            const reasons:string[] = [];
            if (!obj.offer) reasons.push('Missing offer info');
            if (!obj.loan) reasons.push('Missing loan info');
            if (!obj.investor) reasons.push('Missing investor info');
            if (!obj.holdingInfo) reasons.push('Missing holding info');
            if (!obj.today) reasons.push('Missing datestamp');
            if (reasons.length > 0) {
                modal.confirm({
                    title: <InvalidJson reasons={reasons} />,
                    hideCancel: true
                })
                return;
            }
            setHeatMapData(obj);
        } catch (err) {
            modal.confirm({
                title: <InvalidJson reasons={['Invalid json']} />,
                hideCancel: true
            })
        }
    }

    return (
        <>
            { !heatMapData && (
                <>
                    <ModalTitle>Heat Map Viewer</ModalTitle>
                    <Box mt="2">
                        <FileDropArea
                            onDrop={drop}
                            multi={false}
                            accept=".json"
                            label="Drop Heat Map JSON File"
                        />
                    </Box>
                </>
            ) }
            { heatMapData && (
                <HeatMapModal
                    offer={heatMapData.offer}
                    removeGaps={true}
                    removeWeekends={true}
                    importData={heatMapData}
                    investor={heatMapData.investor}
                    loan={heatMapData.loan}
                />
            ) }
        </>
    )
}