import React from 'react';
import { ButtonGroup, Button } from 'mui';

interface Props {
    readonly page:number;
    readonly setPage:(x:number) => void;
    readonly totalPages:number;
    readonly buttons:number[];
}
export const LoanTablePageButtons = ({ page, setPage, totalPages, buttons }:Props) => {
    return (
        <>
            { totalPages > 1 && (
                <ButtonGroup mb="2">
                    { buttons[0] > 0 && (
                        <Button size="sm" type="link" onClick={() => setPage(0)} icon="dbl-chevron-left" />
                    )}
                    { buttons.map(n => (
                        <Button
                            key={n}
                            size="sm"
                            type={page === n ? 'default' : 'link'}
                            onClick={() => setPage(n)}
                        >{n + 1 }</Button>
                    ))}
                    { buttons[buttons.length-1] < totalPages-1 && (
                        <Button size="sm" type="link" onClick={() => setPage(totalPages-1)} icon="dbl-chevron-right" />
                    )}
                </ButtonGroup>
            )}
        </>
    )
}