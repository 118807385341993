import React from 'react';
import { Box, modal, Button, FrmInput, BoundFrmInput, BoundFrmDropdown } from 'mui';
import { rule } from 'services';
import { useZStateList } from 'hooks';

export const SampleModal = () => {

    const {
        editItem:sample,
        updateItem:update,
        updating,
        commitItem:save,
        editItemDirty:dirty,
        isNew
    } = useZStateList(rule.zModelSamples);

    if (!sample) throw new Error('Expected sample');
    const opts = rule.getModelsAsDropdownOpts();
    const canSubmit = sample.modelId && sample.name.trim();
    const submit = async () => {
        if (!canSubmit) return;
        if (!isNew) {
            // If not new, just close the modal. Commit button is elsewhere.
            modal.close();
            return;
        }
        await save();
        modal.close();
    }

    return (
        <>
            <BoundFrmInput
                width="100%"
                label="Name"
                bindTo={[ sample, update, x => x.name ]}
            />
            <BoundFrmDropdown
                width="100%"
                label="Model"
                items={opts}
                bindTo={[ sample, update, x => x.modelId ]}
            />
            <Box mt="2" textAlign="right">
                <Button
                    busy={updating}
                    onClick={submit}
                    type={canSubmit ? 'default' : 'disabled'}
                >{ isNew ? 'Add' : 'Ok' }</Button>
            </Box>
        </>
    )
}