import React, { useState } from 'react';
import { GQL, ImportModel } from 'market-dto';
import { Box, Flex, Icon, FlexVCenter, modal } from 'mui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableField } from './draggable-field';
import { utils } from 'services';
import { ExpanedModal } from '../expanded/expanded-modal';
import { getHeaderLabel } from '../get-header-label';

interface Props {
    readonly model:ImportModel;
    readonly colCfgs:GQL.XlsExportCol[];
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
    readonly emptyById:{[colId:string]:true};
    readonly hideEmptyCols:boolean;
}
export const LeftSideList = ({
    model,
    colCfgs,
    onColCfgsChange,
    selectedColId,
    setSelectedColId,
    emptyById,
    hideEmptyCols
}:Props) => {

    const [ filter, setFilter ] = useState('');

    const moveField = (from:number, to:number) => {
        const fromIndex = colCfgs.findIndex(x => x.colId === from);
        const toIndex = colCfgs.findIndex(x => x.colId === to);
        onColCfgsChange(utils.moveItem(colCfgs, fromIndex, toIndex));
        setSelectedColId(-1);
    }

    const onToggleExlude = (colCfg:GQL.XlsExportCol) => {
        const nextExclude = !colCfg.exclude;
        onColCfgsChange(colCfgs.map(x => {
            return x === colCfg ? {
                ...colCfg,
                exclude: nextExclude
            } : x;
        }))
        setSelectedColId(-1);
    }

    const lowerCaseFilter = filter.trim().toLowerCase();
    const filtered = colCfgs.filter(x => {
        if (hideEmptyCols && emptyById[x.colId]) return false;
        if (lowerCaseFilter === '') return true;
        const label = getHeaderLabel(model, x).toLowerCase();
        return label.includes(lowerCaseFilter);
    })

    const openAddFieldModal = () => {
        modal.open({
            title: 'Add Expanded Column',
            render: <ExpanedModal
                model={model}
                colCfgs={colCfgs}
                onColCfgsChange={onColCfgsChange}
                selectedColId={selectedColId}
                setSelectedColId={setSelectedColId}
            />
        })

        // {
        //     "id": "custom1",
        //     "label": "Custom Field1",
        //     "type": "enum",				// SUBSET OF TheDictionaryItemType ??"currency"|"number"|"text"|"date"|"enum"|"text-array"|"bool" YES!.
    }

    return (
        <Flex height="100%" flexDirection="column">
            <div className="m-xls-field-filter-container">
                <FlexVCenter>
                    <input
                        type="text"
                        className="m-xls-input"
                        placeholder="Filter"
                        value={filter}
                        onChange={x => setFilter(x.target.value)}
                    />
                    <Icon name="plus" ml="half" cursor="pointer" onClick={openAddFieldModal}/>
                </FlexVCenter>
            </div>
            <Box overflow="auto" flex="1">
                <DndProvider backend={HTML5Backend}>
                    { filtered.map(f => (
                        <DraggableField
                            model={model}
                            key={f.colId}
                            colCfg={f}
                            moveField={moveField}
                            onToggleExlude={onToggleExlude}
                            selectedColId={selectedColId}
                            setSelectedColId={setSelectedColId}
                        />
                    )) }
                </DndProvider>
            </Box>
        </Flex>
    )
}