import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';
import { RawProposal, ProposalDetails, toProposalDetails } from 'services/proposal';

const QUERY = gql`
    query ($where: proposal_bool_exp!) {
        proposal(where: $where) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryOutput {
    readonly proposal:[RawProposal];
}

export const fetchProposalDetails = async (sheetId:string):Promise<ProposalDetails|undefined> => {
    // try {
    const result = await query<QueryInput, QueryOutput>(QUERY, { 
        where: {
            sheetId: {
                _eq: sheetId
            }
        }
    })
    if (!result.data?.proposal) return;
    if (result.data.proposal.length !== 1) return;
    return toProposalDetails(result.data.proposal[0]);
    // } catch (err) {
    //     console.log(JSON.stringify(err, null, 4));
    // }
}