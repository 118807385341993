import React from 'react';
import { Text, Box, FlexVCenter, CssGrid, Icon, HoverIconBox, FontSize } from 'mui';
import { SectionHeader } from './section-header';
import { mapping } from 'services';
import { GQL } from 'market-dto';


interface Props {
    readonly readOnly?:boolean;
    readonly showReqMsg:boolean;
    // readonly isNew:boolean;
    readonly fields:mapping.Field[];
    readonly onSelect:(f:mapping.Field) => void;
    readonly highlightField?:keyof GQL.Loan;
}
export const MappedSection = ({ readOnly, showReqMsg, fields, highlightField, onSelect }:Props) => {

    const small = true;
    const fs:FontSize = small ? 'sm' : 'md';
    const fsSmall:FontSize = small ? 'xs' : 'sm';

    return (
        <>
            <SectionHeader
                small={small}
                mt="1"
                // label={isNew ? 'Auto-Mapped Fields' : 'Mapped Fields'}
                label="Mapped Fields"
                desc="Spreadsheet column names appear on the right"
            />
            <CssGrid cols={mapping.ui.fieldLayoutColumns * 2} gap="1">
                { fields.map((f, n) => (
                    <React.Fragment key={n}>
                        <FlexVCenter
                            fg={highlightField === f.id ? "danger" : "default"}
                            justifyContent="flex-end"
                            fontSize={fs}
                            textAlign="right"
                        >
                            { highlightField === f.id && <Icon name="arrow-right" fg="accent" mr="1" /> }
                            { showReqMsg && f.required && <Text fg="faded" fontSize={fsSmall}>(required)&nbsp;&nbsp;</Text> }
                            { f.label }
                        </FlexVCenter>
                        <FlexVCenter fontSize={fs}>
                            <Icon fg="faded" name="arrow-left" mr="1"></Icon>
                            { readOnly && (
                                <Text fg="default">{ f.res.winnerLabel }</Text>
                            ) || (
                                <HoverIconBox
                                    fg={highlightField === f.id ? "loud" : "default"}
                                    icon="edit"
                                    iconFg="faded"
                                    iconOnClick={() => onSelect(f)}
                                >{ f.res.winnerLabel }</HoverIconBox>
                            ) }
                            { highlightField === f.id && <Icon name="arrow-left" fg="accent" mr="1" /> }
                        </FlexVCenter>
                    </React.Fragment>
                )) }
            </CssGrid>
        </>
    )
}
