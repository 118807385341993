import { stripInternals } from '../../client'
import { GQL } from 'market-dto';
import { TemplateAndConfidence, TemplateMatchConfidence } from 'services/tape-importer';

// type TemplateFields = Pick<GQL.Template, 'id'|'note'|'hashes'|'sequenceHashes'|'orderHashes'|'mappedHashes'|'mapping'>;
// export interface TemplateReturnResult extends TemplateFields {
//     readonly associateOrg:Pick<GQL.Org, 'id'|'name'>
// }

// export const RETURNING = `
//     id
//     note
//     hashes
//     sequenceHashes
//     orderHashes
//     mappedHashes
//     mapping
//     associateOrgId
//     associateOrg {
//         id
//         name
//     }
// `;

export const RETURNING = `
    id
    modelType
    note
    hashes
    sequenceHashes
    orderHashes
    mappedHashes
    mapping
    orgIds
    aggregatorName
`;

export const toTemplateAndConfidence = (
    confidence:TemplateMatchConfidence,
    template:GQL.Template
):TemplateAndConfidence => {
    return {
        confidence,
        template: stripInternals(template, true)
    }
}

// export const toTemplateAndConfidence = (confidence:TemplateMatchConfidence, raw:TemplateReturnResult):TemplateAndOrg => {
//     const { __typename, associateOrg, ...template } = raw as any;
//     return {
//         // org: {
//         //     name: associateOrg.name,
//         //     id: associateOrg.id
//         // },
//         confidence,
//         template
//     }
// }

