import { useEffect, useState, useRef } from 'react';
import { marketSettings, sellers, sheetSummaries, version } from 'services';
import { useInterval } from './use-interval';

export const useEssentials = () => {

    const [ isCurrentVersion, setIsCurrentVersion ] = useState<boolean>(true);
    const [ ready, setReady ] = useState(false);
    const isCanceled = useRef(false);

    const setCurrent = (isCurrent:boolean) => {
        if (isCanceled.current) return;
        setIsCurrentVersion(isCurrent);
    }

    const load = async () => {

        // Even though user is authenticated, they cannot do anything until essentaials are loaded!
        // Here, we load em all in parallel.

        // THIS REGISTER TO RESET ON LOGOUT STUFF MUST GO.
        // Instead, zstate constructor will add self to a list.
        // logout will access this list.
        // unless zstate cfg tells us to ignore resetOnLogout, we will do this by default.

        // NOTE: batchImport MUST be moved to zstate as well! It has a behaviorSubject that is persisting
        // upon switching users. Fix this. The only behaviorSubjects should really be zstate/zstateList.

        // user.registerToResetOnLogout([
        //     marketSettings.zMarketSettings,
        //     sellers.zSellers,
        //     sheetSummaries.byDay,
        //     sheets.current,
        //     sheets.filters
        // ]) // TEMPORARY.

        await Promise.all([
            marketSettings.zMarketSettings.fetch(),
            sellers.zSellers.fetchList(),
            sheetSummaries.byDay.fetch(),
            version.isCurrent().then(setCurrent)
        ])
        if (isCanceled.current) return;
        setReady(true);
    }

    // Every 10 min check if this is still the current version of the software
    useInterval(() => {
        version.isCurrent().then(setCurrent);
    }, 10 * 60 * 1000); // 10 min

    useEffect(() => {
        load();
        return () => {
            isCanceled.current = true;
        }
    }, [])

    return {
        ready,
        isCurrentVersion
    }
}