import { query, gql, GQL } from "../../../client";
import { RUN_FIELDS } from './returning';

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryResult {
    readonly run:[GQL.FlowRun];
}

const QUERY =  gql`
    query ($where: where_run_exp) {
        run(where: $where) { ${ RUN_FIELDS } }
    }
`;

export const fetchFlowRun = async (runId:string):Promise<GQL.FlowRun|undefined> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _eq: runId
            }
        }
    }, {
        // delay: 2000 // jeff testing
    })
    const run = result.data.run.length > 0 ? result.data.run[0] : undefined;
    return run ? {
        ...run,
        rows: (run.rows ?? []).sort((a,b) => a.loan.rowNumber - b.loan.rowNumber)
    } : undefined;
}