import React from 'react';
import { NavMenuItem, FlexVCenter, NavTabs, Box, PortalPlaceholder } from 'mui';

export const ImportNavMenu = () => {

    const navItems:NavMenuItem[] = [
		{ label: "Incoming Tapes", to: 'tapes' },
        { label: "Hit Offers", to: 'hits' },
    ]

    return (
        <>
            <FlexVCenter my="2">
                <NavTabs items={navItems} />
                <FlexVCenter flex="1" justifyContent="flex-end" height="btn-row">
                    <PortalPlaceholder id="import-button-area" />
                </FlexVCenter>
            </FlexVCenter>
        </>
    )
}
