import React from 'react';
import { BasicSpacingProps, Box } from '../box/box';
import { Flex } from '../flex/flex';
import { Icon, IconName } from '../icon/icon';

const BasicAlert = ({ children, ...rest }:any) => {
	return <Box
		borderSides="all"
		borderWidth="thick"
		fg="default"
		p="2"
		borderColor="alert"
		roundedEdges="all"
		fontSize="md"
		{...rest}
	>{children}</Box>
}

interface Props extends BasicSpacingProps {
	readonly children?:any;
	readonly icon?:IconName
}

export const Alert = ({ icon, children, ...rest }:Props) => {
	
	if (!icon) return <BasicAlert {...rest}>{children}</BasicAlert>;
	
	return (
		<BasicAlert {...rest}>
			{/* fontSize xl is for the icon */}
			<Flex fontSize="xl">
				<Icon name={icon} fg="default"/>
				<Box ml="2" fontSize="md" fg="loud" flex="1">{children}</Box>
			</Flex>
		</BasicAlert>
	);
}
