import React from 'react';
import { Localhost } from 'mui';
import { user } from 'services';

interface Props {
    readonly children?:React.ReactNode;
}
export const DebugMsg = ({ children }:Props) => {
    if (!user.getDebug()) return null;
    return <Localhost>{ children }</Localhost>
}