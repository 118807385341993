import { query, gql, GQL } from '../../client';
import { RETURNING } from './returning';

const QUERY = gql`
    query checklists($where: checklist_bool_exp!) {
        checklist(where: $where) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryResult {
    readonly checklist:[GQL.Checklist];
}

export const fetchChecklist = async (id:string):Promise<GQL.Checklist> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {
            id: {
                _eq: id
            }
        }
    })
    if (result.data.checklist.length !== 1) throw new Error('could not find checklist');
    const clist = result.data.checklist[0];
    return {
        ...clist,
        tags: clist.tags ?? [] // tmp data fix...remove in future
    }
}
