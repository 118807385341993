import * as storage from '../storage';

const COST_OF_FUNDS_KEY = 'cost-of-funds';

export const DEFAULT_COST_OF_FUNDS = 0.19;
export const DEFAULT_SELLER_MARGIN = 0.15;

// export const getDefaultCostOfFunds = () => Number(storage.get(COST_OF_FUNDS_KEY)) || DEFAULT_COST_OF_FUNDS;
// export const getDefaultSellerMargin = () => DEFAULT_SELLER_MARGIN;

export const setLastCostOfFunds = (val:number) => storage.set(COST_OF_FUNDS_KEY, val);
export const getLastCostOfFunds = () => Number(storage.get(COST_OF_FUNDS_KEY)) || DEFAULT_COST_OF_FUNDS;

export const getCostOfFundsStorageKey = () => COST_OF_FUNDS_KEY;
export const getSellerInvestorMarginStorageKey = (sellerOrgId:string, investor:"fannie"|"freddie") => ['margin', sellerOrgId, investor].join('.');

export const getLastMarginBySellerAndInvestor = (sellerOrgId:string, investor:"fannie"|"freddie") => {
    const k = getSellerInvestorMarginStorageKey(sellerOrgId, investor);
    return Number(storage.get(k)) || DEFAULT_COST_OF_FUNDS;
}

export const setLastMarginBySellerAndInvestor = (sellerOrgId:string, investor:"fannie"|"freddie", val:number) => {
    const k = getSellerInvestorMarginStorageKey(sellerOrgId, investor);
    storage.set(k, val);
}