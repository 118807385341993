import React, { useEffect, useState } from 'react';
import { Box, FlexVCenter, Button, Icon, Text, modal, ModalFooter } from 'mui';
import { GQL } from 'market-dto';
import { rule } from 'services';
import { InvalidJson } from 'components';
import { useZStateList } from 'hooks';

interface Props {
    readonly json:string;
    readonly existingSamples:GQL.ModelSample[];
}
export const UploadModal = ({ json, existingSamples }:Props) => {

    const [ sample, setSample ] = useState<GQL.ModelSample>();
    const [ existingSample, setExistingSample ] = useState<GQL.ModelSample>();
    const [ reasons, setReasons ] = useState<string[]>([]);

    const {
        updating,
        selectNewItem:createEmptySample,
        selectItem,
        updateItem:update,
        commitItem:save
    } = useZStateList(rule.zModelSamples);

    const createNew = async () => {
        if (!sample) throw new Error('Expected sample');
        createEmptySample(); // create empty new modelInfo
        update(sample);
        await save();
        modal.close();
    }

    const replaceSample = async () => {
        if (!sample) throw new Error('Expected sample');
        if (!existingSample) throw new Error('Expected existingSample');
        if (!selectItem(existingSample)) throw new Error('Expected existingSample to be found in zList');
        update(sample);
        await save();
        modal.close();
    }

    useEffect(() => {
        let obj:any;
        try {
            obj = JSON.parse(json);
        } catch (err) {
            setReasons(['Malformed JSON -- could not parse!']);
            return;
        }
        const invalidReasons = rule.isValidModelSample(obj);
        if (invalidReasons.length > 0) {
            setReasons(invalidReasons);
            return;
        }
        const model = rule.getModelById(obj.modelId);
        if (!model) {
            setReasons(['Invalid modelId specified: ' + obj.modelId]);
            return;
        }
        setSample(obj);
        const m = existingSamples.find(x => x.id === obj.id);
        if (m) {
            setExistingSample(m);
        } else {
            setExistingSample(undefined);
        }
    }, [json, existingSamples])

    if (reasons.length > 0) {
        return (
            <>
                <Box mt="2">
                    <InvalidJson reasons={reasons} />
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            type="default"
                            onClick={() => modal.close()}
                        >Ok</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    if (!sample) return <></>;

    if (existingSample) {
        return (
            <>
                <Box mt="2">
                    A sample already exists with the id you have provided!<br/>
                    Do you want to replace <Text fg="loud">{ existingSample.name || 'Untitled Sample' }</Text>?
                </Box>
                <ModalFooter>
                    <FlexVCenter m="2" justifyContent="flex-end">
                        <Button
                            type="secondary"
                            mr="1"
                            onClick={() => modal.close()}
                        >Cancel</Button>
                        <Button
                            busy={updating}
                            type="default"
                            onClick={() => replaceSample()}
                        >Yes, Replace Sample</Button>
                    </FlexVCenter>
                </ModalFooter>
            </>
        )
    }
    if (sample?.id) {
        return (
            <>
                <Box mt="2">
                    Unknown sample id (<Text fg="loud">{ sample.id }</Text>) specified.<br/>
                    If you are trying to upload a new sample, remove the id and try again.
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            type="default"
                            onClick={() => modal.close()}
                        >Ok</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    return (
        <>
            <Box mt="2">
                This will create a NEW sample, <Text fg="loud">{ sample.name }</Text>.<br/>
                Are you sure you want to create a brand new sample?
            </Box>
            <ModalFooter>
                <FlexVCenter m="2" justifyContent="flex-end">
                    <Button
                        type="secondary"
                        mr="1"
                        onClick={() => modal.close()}
                    >Cancel</Button>
                    <Button
                        busy={updating}
                        type="default"
                        onClick={() => createNew()}
                    >Yes, Create It</Button>
                </FlexVCenter>
            </ModalFooter>
        </>
    )
}

