import * as base64 from '../../base-64';
import { sheetMaker, loanModel } from 'market-dto';
import * as api from 'api';
import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { logActivity } from './log-activity';
import { utils } from 'services';

const B64_SIZE_LIMIT = 1500000;

export const apiSanitizeAndUpdateTape = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        logActivity("Sanitizing"),
        updateStatus("SANITIZING_AND_UPDATING_TAPE"),
        switchMap(item => {
            const { rowsAndHeaders, buf, tapeId } = item;
            const { assigned } = item;
            if (!assigned) throw new Error('Expected assigned');
            const { generated } = assigned;
            if (!generated) throw new Error('Expected assigned.generated');
            const { sourceMap:valuesAndSources } = generated;
            if (!tapeId) throw new Error('Expected tapeId');
            if (!rowsAndHeaders || !buf) throw new Error('Expected rowsAndHeaders and buf');
            if (!valuesAndSources) throw new Error('Expected valuesAndSources');
            if (!rowsAndHeaders) throw new Error('Expected rowsAndHeaders');
            // Only AFTER we have mapped TWICE (once with global config, second with template we've identified, if exists..)
            // can we then reconstruct a cleaned up version of the initial sheet, minus the sensitive data!
            const indexes = loanModel.getColumnIndexesToSanitize(valuesAndSources);
            const obs = from(sheetMaker.sanitizeSheetColumns(buf, indexes));
            return obs.pipe(
                switchMap(cleanBuf => {
                    const b64 = base64.arrBufToBase64(cleanBuf);
                    const tapeHash = utils.toHash(b64);
                    return from(api.updateTape(tapeId, {
                        base64Content: b64.length < B64_SIZE_LIMIT ? b64 : 'base64 too big',
                        hash: tapeHash
                    })).pipe(
                        tap(x => {
                            // We assume update hash/b64 worked.
                            const cleaned = loanModel.sanitizeRowsAndHeaders(rowsAndHeaders, valuesAndSources);
                            mergeBatchItem(item, {
                                status: "SANITIZED_AND_UPDATED",
                                rowsAndHeaders: cleaned
                            })
                        })
                    )
                }),
                map(x => item.index)
            )
        })
    )
}
