import { mutate, gql } from "../../client";

type Params = {[k:string]:string|number|boolean};
interface MutateInput {
    readonly data: {
        readonly params: Partial<Params>;
    }
}
interface MutateOutput {
    readonly upsert_marketSetting:{
        readonly returning:[{
            readonly params:Params;
        }];
    }
}

const UPSERT = gql`
    mutation ($data: marketSetting_set_input) {
        upsert_marketSetting(set: $data) {
            errorMessage
            affectedRow
            returning {
                id
                params
            }
        }
    }
`;

export const upsertMarketSettings = async (params:Partial<Params>):Promise<Params|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPSERT, {
        data: {
            params: params
        }
    })
    // const client = getApolloClient(false);
	// const result = await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: UPSERT,
    //     variables: {
    //         data: {
    //             params: params
    //         }
    //     }
    // });
    return result?.data?.upsert_marketSetting.returning[0].params;
}
