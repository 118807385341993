import React from 'react';
import { ImportModel, ModelItem, SrcMap, DeriveSrcMap } from 'market-dto';
import { SrcMapDeriveEnumIn } from './derive-enum-in/src-map-derive-enum-in';
import { SrcMapDeriveMath } from './derive-math';

interface Props {
    readonly model:ImportModel;
    readonly field:ModelItem.AnyType;
    readonly derive:DeriveSrcMap;
    readonly sourceMap:SrcMap[];
    readonly n:number;
}
export const SrcMapDerive = ({ model, field, derive, n, sourceMap }:Props) => {

    const prefix = n === 0 ? 'First,' : 'Then';

    switch (derive.deriveType) {
        case "simple": {
            return <>
                <div>{ prefix } we looked at { derive.srcId }</div>
                { derive.val && (
                    <>
                        <div>It had the value { String(derive.val.raw) }</div>
                        { derive.val.trans !== undefined && <div>And that's the value we used to derive our final value.</div> }
                        { derive.val.trans === undefined && <div>Somehow, that wasn't good enough.</div> }
                    </>
                 ) }
                { !derive.val && <div>But it did not have a value.</div> }
            </>
        }
        case "enum-in": {
            return (
                <SrcMapDeriveEnumIn
                    derive={derive}
                    field={field}
                    model={model}
                    sourceMap={sourceMap}
                    n={n}
                />
            )
        }
        case "math": {
            return <SrcMapDeriveMath
                derive={derive}
                field={field}
                model={model}
                sourceMap={sourceMap}
                n={n}
            />
            // return <div>
            //     <b>TODO: how did we get here? MATH! coming soon...more details about that math!</b>
            //     <textarea readOnly value={JSON.stringify(derive, null, 4)}></textarea>
            // </div>
        }
    }
}

/*
{
    "deriveType": "enum-in",
    "whenEnum": {
        "fieldId": "propertyType",
        "valueIn": [
            "SINGLE_FAMILY"
        ]
    },
    "takeFrom": {
        "enumItself": true
    },
    "val": {
        "raw": "1",
        "trans": "DETACHED",
        "src": {
            "to": "enumFromHintDict"
        }
    }
}
*/