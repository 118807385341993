import React from 'react';
import { Box } from '../box/box';
import { FlexVCenter } from '../flex/flex';

interface Props {
    readonly notFixed?:boolean;
    readonly children?:any;
}
export const TopNavBar = ({ notFixed, children }:Props) => {

    if (notFixed) {
        return (
            <Box bg="card" height="top-nav" overflow="hidden">
                <Box width="main-section" mx="auto" height="100%">
                    <FlexVCenter justifyContent="flex-start" height="100%">
                        { children }
                    </FlexVCenter>
                </Box>
            </Box>
        )
    }
    return (
        <Box pos="relative" zIndex="top-nav-bar">
            <Box bg="card" pos="fixed-top" height="top-nav" overflow="hidden">
                <Box width="main-section" mx="auto" height="100%">
                    <FlexVCenter justifyContent="flex-start" height="100%">
                        { children }
                    </FlexVCenter>
                </Box>
            </Box>
        </Box>
    )
}