import { FetchResult, ApolloQueryResult } from 'apollo-boost';
import { ApiCallOptions } from './types';
import { util } from 'market-dto';

// Purely for debugging.

export const withArtificialDelay = <T>(
    apiCall:Promise<FetchResult<T>|ApolloQueryResult<T>>,
    opts?:ApiCallOptions
):Promise<FetchResult<T>|ApolloQueryResult<T>> => {
    return opts?.delay ? util.sleep(opts.delay).then(() => apiCall) : apiCall;    
}