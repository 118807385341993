import React, { useState } from 'react';
import { Flex, Box, Input, Button, modal, Alert, Textarea } from 'mui';
import { EditableRuleTable } from '../components/param-table.service';
import { translateRowVals } from '../components/import-table.service';
import { htmlTableToEditableRuleTable } from './max-ltv.service';
import Sample from './sample.png';


interface InstructionsProps {
    readonly onReady:()=>any;
}
const Instructions = ({ onReady }:InstructionsProps) => {
    return (
        <Box textAlign="center">
            Before you can import Max Ltv Rules from spreadsheet data, you must prepare the spreadsheet data as follows:<br/>
            <Box my="2">
                <img src={Sample} alt="Sample Sheet Table" /><br/>
            </Box>
            <Box my="2">
                Note: The header names are unimportant, but the order MUST match exactly!
            </Box>
            <Button onClick={onReady}>I'm Ready</Button>
        </Box>
    )
}

interface ImportProps {
    readonly onPaste:(e:React.ClipboardEvent) => any;
    readonly onBack:() => any;
}
const ImportForm = ({ onPaste, onBack }:ImportProps) => {
    return (
        <Flex height="card-md" flexDirection="column">
            <Box>
                <Button icon="arrow-left" type="link" onClick={onBack}>Back</Button>
            </Box>
            <Box my="2" textAlign="center">
                Paste what you have copied from a spreadsheet into the textarea below!    
            </Box>
            <Box my="2" flex="1">
                <Textarea onPaste={onPaste} />
            </Box>
        </Flex>
    )
}

interface ConfirmProps {
    readonly table:EditableRuleTable;
    readonly onConfirm:()=>any;
    readonly onBack:() => any;
}
const Confirmation = ({ table, onConfirm, onBack }:ConfirmProps) => {
    return (
        <Box>
            <Box>
                <Button icon="arrow-left" type="link" onClick={onBack}>Back</Button>
            </Box>
            <Alert my="2" icon="alert-triangle">
                This will wipe all existing Max LTV rules
                and create { table.rows.length } rules.<br/>
                If you are sure this is what you wish to do, click "continue" below.
            </Alert>
            <Box my="2" textAlign="center">
                <Button onClick={onConfirm}>Continue</Button>
            </Box>
        </Box>
    )
}

interface Props {
    readonly editTable:EditableRuleTable;
    readonly onImport:(x:EditableRuleTable) => any;
}
export const ImportModal = ({ onImport, editTable }:Props) => {

    const [ step, setStep ] = useState<number>(1);
    const [ importTable, setImportTable ] = useState<EditableRuleTable>();

    const onPaste = (e:React.ClipboardEvent) => {
        const htmlTable = e.clipboardData.getData('text/html');
        // Convert the html table they just pasted into an EditableRuleTable.
        // Then, translate the values in the rows using global config to fix enums!
        setImportTable(translateRowVals(htmlTableToEditableRuleTable(editTable, htmlTable)));
        setStep(3);
    }

    const confirm = () => {
        if (!importTable) throw 'missing import table';
        onImport(importTable);
        modal.close();
    }

    if (step === 1) return <Instructions onReady={() => setStep(2) } />
    if (step === 2) return <ImportForm onPaste={onPaste} onBack={() => setStep(1) } />
    if (step === 3) return <Confirmation table={importTable!} onConfirm={confirm} onBack={() => setStep(2) } />
    return <div>invalid step</div>
}

