import React from 'react';
import { Box, Flex, Button } from 'mui';
import { GQL, format } from 'market-dto';

interface HeaderItemProps {
	readonly top:string;
	readonly bottom:string;
}
const HeaderItem = ({ top, bottom}:HeaderItemProps) => {
	return <Box p="2">
		<Box fontSize="md" fg="loud">{ top }</Box>
		<Box fontSize="sm" fg="faded">{ bottom }</Box>
	</Box>
}

interface Props {
	readonly onRefresh:() => void;
	readonly refreshing:boolean;
	readonly offer:GQL.Offer;
	readonly basePrice:number;
	readonly loan:Partial<GQL.Loan>;
	readonly investor:"fannie"|"freddie";
}

export const HeatMapModalHeader = ({ offer, loan, basePrice, onRefresh, refreshing, investor }:Props) => {
	return (
		<>
			<Flex flexDirection="row" justifyContent="space-between">
				<HeaderItem top={format.toFourDecimals(basePrice || 0)} bottom="Base" />
				<HeaderItem top={format.toFourDecimals(offer.coupon || 3.0)} bottom="Coupon" />
				<HeaderItem top={format.toFourDecimals(offer.costOfFunds || 1000)} bottom="Cost of Funds" />
				<HeaderItem top={format.datePartOfISO(offer.lockDate)} bottom="Locked Date" />
				<HeaderItem top={format.datePartOfISO(offer.acquisitionDate)} bottom="Acquisition Date" />
				<HeaderItem top={format.toCurrencyWithoutCents(loan.currentBalance)} bottom="Balance" />
				<HeaderItem top={format.datePartOfISO(offer.notificationDate!)} bottom={`${offer.commitmentDays} days to commit`} />
				<HeaderItem top={investor === 'fannie' ? 'Fannie' : 'Freddie'} bottom="Investor" />
			</Flex>
			{ investor === 'fannie' && (
				<Box textAlign="center" pl="half">
					<Button
						type="link"
						size="sm"
						icon="refresh"
						busy={refreshing}
						onClick={onRefresh}
					>Refresh Cash Window</Button>
				</Box>
			) }
		</>
	)
}

