import {
    sheetParser,
    MapResult,
    ImportModel,
    MinimalLoan,
    RowsAndHeaders,
    TabNameAndRows
} from 'market-dto';

export type PreprocessError = "PASSWORD_PROTECTED"|"COULD_NOT_PARSE";

export interface PreprocessResult {
    readonly buf:ArrayBuffer;
    readonly tabNameAndRows:TabNameAndRows[];
    // readonly rowsAndHeaders:RowsAndHeaders;
    // readonly defaultMapResult:MapResult<MinimalLoan>;
}
export const preprocessTape = async (
    model:ImportModel,
    file:File,
    filePassword?:string
):Promise<PreprocessResult|PreprocessError> => {

    try {
        const buf = await file.arrayBuffer();
        const tabNameAndRows = await sheetParser.getTabsAndRowsFromArrayBuffer(buf, filePassword);
        // const mapResult = model.tryMap({ rowsAndHeaders });
        return {
            buf,
            tabNameAndRows
        }
    } catch (err) {
        console.log('pre process tape error', err.message, err.name);
        const msg = String(err.message).trim().toUpperCase();
        if (msg.includes('PASSWORD')) return "PASSWORD_PROTECTED";
        console.log('parsing raw error', err.toString());
        return "COULD_NOT_PARSE";
    }
}
