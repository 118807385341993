import React, { useState, useEffect } from 'react';
import { ExpFieldWithValue } from 'exp';
import { FieldsWithValuesTableFilters } from './fields-with-values-table-filters';
import { FieldsWithValuesTable } from './fields-with-values-table';
import { GQL } from 'market-dto';

const notNothing = (x:any) => typeof x === 'string' || typeof x === 'number';

interface Props {
    readonly hideButtonsWhenPristine?:boolean;
    readonly onClose?:() => void;
    readonly onReset?:() => void;
    readonly onSave?:() => void;
    readonly dirty:boolean;
    readonly updating:boolean;
    readonly sample?:GQL.ModelSample;
    readonly sampleData:any;
    readonly setSampleData:(x:any) => void;
    readonly fieldsWithValues:ExpFieldWithValue[];
}
export const SampleDataContainer = ({
    onClose,
    onReset,
    onSave,
    dirty,
    updating,
    sample,
    sampleData,
    setSampleData,
    fieldsWithValues,
    hideButtonsWhenPristine
}:Props) => {

    const [ filterText, setFilterText ] = useState<string>('');
    const [ filteredFields, setFilteredFields ] = useState<ExpFieldWithValue[]>([]);

    useEffect(() => {
        const txt = filterText.trim().toLowerCase();
        setFilteredFields(fieldsWithValues.filter(x => {
            if (x.id.trim().toLowerCase().indexOf(txt) !== -1) return true;
            if (x.label.trim().toLowerCase().indexOf(txt) !== -1) return true;
            if (notNothing(x.value) && String(x.value).trim().toLowerCase().indexOf(txt) !== -1) return true;
            return false;
        }))
    }, [fieldsWithValues, filterText])

    const onValChange = (fieldId:string, val:any) => {
        setSampleData({
            ...sampleData,
            [fieldId]: val
        })
    }

    return (
        <>            
            <FieldsWithValuesTableFilters
                busy={updating}
                dirty={dirty}
                fields={filteredFields}
                filterText={filterText}
                setFilterText={setFilterText}
                onClose={onClose}
                onReset={onReset}
                onSave={onSave}
                hideButtonsWhenPristine={hideButtonsWhenPristine}
                sample={sample}
            />
            <FieldsWithValuesTable
                fields={filteredFields}
                onValueChange={onValChange}
            />
        </>
    )
}