import React from 'react';
import { Icon, Text } from 'mui';
import { tapeImporter } from 'services';

interface Props {
	readonly batch:tapeImporter.Batch;
	readonly item:tapeImporter.BatchItem;
}
export const ColEligibleCount = ({ item, batch }:Props) => {

	if (tapeImporter.isBeforeStep(batch, "POST_CONFIRM")) return null;
	if (tapeImporter.isBeforeStatus(item, "TEMPLATE_ASSIGNED_AND_CONFIRMED")) return null;

	// only show eligible count if final status
	if (item.eligibleCount !== undefined) {
		return <Text fg="default">{ item.eligibleCount }</Text>
	}

	return <Icon name="loader" fg="loud" spinning={!batch.paused} inline />

}
