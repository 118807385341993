import { ExpNode } from './exp-types';
import { genericWalker, GenericWalkerOpts } from './generic-walker';

// Not sure we'd ever want raw=false, but, in case you do!

export const expNodeToString = (node:ExpNode|null, raw:boolean):string => {
    if (node === null) return '';

    const fn = (x:GenericWalkerOpts<string[]>):string[] => {
        const { node, lvl, fn, acc:arr, childAcc:childArr } = x;

        if (node === null) return arr;

        switch (node.type) {
            case 'ParenScopedNode': {
                if (raw) {
                    return [
                        ...arr,
                        node.rawOpenParen,
                        ...childArr ? childArr : [],
                        node.rawCloseParen
                    ]
                } else {
                    return [
                        ...arr,
                        '(',
                        ...childArr ? childArr : [],
                        ')'
                    ]
                }
            }
            case 'ExpressionNode': {
                return [
                    ...arr,
                    raw ? node.rawOp : ' ' + node.op + ' ',
                    ...childArr ? childArr : []
                ]
            }
            case 'FunctionCallNode': {
                return [
                    ...arr,
                    raw ? node.rawFnName : node.fnName + '(',
                    ...childArr ? childArr : [],
                    raw ? node.rawCloseParen : ')'
                ]
            }
            case 'ArrayNode': {
                if (raw) {
                    return [
                        ...arr,
                        node.rawOpenBracket,
                        ...childArr ? childArr : [],
                        node.rawCloseBracket
                    ]
                } else {
                    return [
                        '[',
                        ...arr,
                        ']'
                    ]
                }
                // return [raw ? node.rawOpenBracket : '[', ...arr, raw ? node.rawCloseBracket : ']']
            }
            case 'ConstantNode': {
                return [...arr, raw ? node.raw : String(node.value)];
            }
            case 'StringConstantNode': {
                return [...arr, raw ? node.raw : String(node.value)];
            }
            case 'IdentifierNode': {
                return [...arr, raw ? node.raw : String(node.value)];
            }
            case 'UnaryNode': {
                return [raw ? node.rawOp : node.op, ...arr];
            }
            case 'ListItemNode': {
                return [...arr, raw ? node.rawPrepend : node.prepend, ...childArr ? childArr : []];
            }
        }
        return arr;
    }

    const strArr = genericWalker({
        parent: null,
        lvl: 0,
        node: node,
        acc: [],
        fn,
        initChildAcc: []
    })

    const str = strArr.join('');
    return raw ? str.padEnd(node.end, ' ') : str;
}



// export const expNodeToStringOld = (node:JeffNode|null, raw:boolean):string => {
//     if (node === null) return '';
//     const strArr = genericWalker(node, (node:JeffNode|null, arr:string[], lvl:number) => {
//         if (node === null) return  arr;

//         switch (node.type) {
//             case 'ParenScopedNode': {
//                 if (raw) {
//                     return [node.rawOpenParen, ...arr, node.rawCloseParen]
//                 } else {
//                     return ['(', ...arr, ')']
//                 }
//             }
//             case 'ExpressionNode': {
//                 return [...arr, raw ? node.rawOp : node.op];
//             }
//             case 'FunctionCallNode': {
//                 return [...arr, raw ? node.rawFnName : node.fnName + '(', ...arr, raw ? node.rawCloseParen : ')'];
//                 // return [raw ? node.rawFnName : node.fnName + '(', ...arr, raw ? node.rawCloseParen : ')'];
//             }
//             case 'ArrayNode': {
//                 return [raw ? node.rawOpenBracket : '[', ...arr, raw ? node.rawCloseBracket : ']']
//             }
//             case 'ConstantNode': {
//                 return [...arr, raw ? node.raw : String(node.value)];
//             }
//             case 'IdentifierNode': {
//                 return [...arr, raw ? node.raw : String(node.value)];
//             }
//             case 'UnaryNode': {
//                 return [raw ? node.rawOp : node.op, ...arr];
//             }
//             case 'ListItemNode': {
//                 return [...arr, raw ? node.rawPrepend : node.prepend];
//             }
//         }
//         return arr;
//     }, [], 0)
//     const str = strArr.join('');
//     return raw ? str.padEnd(node.end, ' ') : str;
// }
