import React from 'react';
import { Box, FlexVCenter, Text } from 'mui';
import { stageCfg } from './stage-cfg';
import { PricingLogInfo } from './types';
import { TimeElapsed } from './time-elapsed';

interface Props {
    readonly item:PricingLogInfo;
}
export const LoanDetails = ({ item }:Props) => {
    return (
        <>
            <Box>
                <FlexVCenter mb="1">
                    <Box fg="default" mr="1">{ item.id }</Box>
                    <Box fg={stageCfg[item.stage].fg}>{ stageCfg[item.stage].label }</Box>
                </FlexVCenter>
                <Box mx="2" fg="default" fontSize="xs">
                    { item.logs.map((log, logIndex) => (
                        <FlexVCenter
                            key={logIndex}
                            borderColor="alert"
                            borderSides="bottom"
                            borderStyle="dashed"
                            borderWidth="thin"
                            pb="half"
                            mb="half"
                        >
                            <Box style={{ width:'100px' }}>
                                { logIndex === 0 && <Text fg="accent">-- start --</Text> }
                                { logIndex > 0 && <TimeElapsed ms={log.tm - item.logs[logIndex-1].tm} /> }
                            </Box>
                            <Box flex="1">
                                { log.msg.length === 0 && (
                                    <Box textStyle="italic" fg="faded">No message</Box>
                                ) }
                                { log.msg.map((msg, msgIndex) => (
                                    <Box key={msgIndex} monoSmall>{ msg }</Box>
                                )) }
                            </Box>
                        </FlexVCenter>
                    )) }
                </Box>
            </Box>
        </>
    )
}