import React from 'react';
import { BasicMenuItem, modal } from 'mui';
import { AdjGroup} from '../components/adj-group';
import { ImportExportModal } from '../components/import-export-modal';
import { GQL } from 'market-dto';
import { labels } from './llpa-config';
import { isValidImportItem } from './is-valid-import-item';
import { buildExportItem  } from './build-export-item';

const tabs:BasicMenuItem[] = GQL.LLPA_TABLES.map(x => ({ label: labels[x], value: x }));

const showImportExportModal = (refreshTab:() => void) => {
    modal.open({
        title: 'Import / Export Agency SRP JSON',
        render: <ImportExportModal
            refreshTab={refreshTab}
            buildExportObject={buildExportItem}
            exportJsonFilename="llpa.json"
            isValidImportJson={isValidImportItem}
            label="LLPA"
            tables={GQL.LLPA_TABLES}
        />
    })
}

export const Llpa = () => <AdjGroup tabs={tabs} onImportExport={showImportExportModal} />
