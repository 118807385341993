import React from 'react';
import { FrmInput, FlexVCenter, Button, modal } from 'mui';
import { pricing } from 'services';
import { useStickyStateWithDefault, useSafeState } from 'hooks';

interface Props {
	readonly onRun:(costOfFunds:number, sellerMargin:number) => void;
	readonly investor:"fannie"|"freddie";
	readonly sellerOrgId:string;
	readonly runLabel:string;
}
export const RunPricingModal = ({ investor, sellerOrgId, runLabel, onRun }:Props) => {

	const [ busy, setBusy ] = useSafeState(false);

	const [ costOfFunds, setCostOfFunds ] = useStickyStateWithDefault(
		pricing.getCostOfFundsStorageKey(),
		pricing.DEFAULT_COST_OF_FUNDS
	)

	const [ sellerMargin, setSellerMargin ] = useStickyStateWithDefault(
		pricing.getSellerInvestorMarginStorageKey(sellerOrgId, investor),
		pricing.DEFAULT_SELLER_MARGIN
	)

	const run = async () => {
		setBusy(true);
		await onRun(costOfFunds, sellerMargin);
		setBusy(false);
		modal.close();
	}

	return (
		<>
			{/* <textarea readOnly value={JSON.stringify(params, null, 4)}></textarea> */}
			<FrmInput
				width="100%"
				label="Cost of Funds (APR)"
				align="right"
				appendIcon="percent"
				autoFocus
				numeric={{ float: true, default: pricing.DEFAULT_COST_OF_FUNDS }}
				value={costOfFunds}
				setValue={setCostOfFunds}
				readOnly={busy}
			/>
			<FrmInput
				width="100%"
				label="Seller Margin (APR)"
				align="right"
				appendIcon="percent"
				numeric={{ float: true, default: pricing.DEFAULT_SELLER_MARGIN }}
				value={sellerMargin}
				setValue={setSellerMargin}
				onEnter={run}
				readOnly={busy}
			/>

			{/* <BoundFrmDate
				width="100%"
				formatLabel={format.toDate}
				label="Settlement Date"
				bindTo={[params, setParams, x => x.settlementDate]}
			/> */}

			<FlexVCenter justifyContent="flex-end" mt="2">
				<Button mr="1" type="link" onClick={modal.close}>Cancel</Button>
				<Button onClick={run} busy={busy}>{ runLabel }</Button>
			</FlexVCenter>
		</>
	)
}
