import { BehaviorSubject } from 'rxjs';


const _panelVisible = new BehaviorSubject<boolean>(false);
export const panelVisible$ = _panelVisible.asObservable();

export const togglePanel = () => {
    // we don't worry about closing. on click outside will close.
    if (_panelVisible.getValue()) {
        closePanel();
    } else {
        openPanel();
    }
}

export const openPanel = () => {
    _panelVisible.next(true);
    document.body.classList.add('body-open-modal');
    const hasVerticalScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
    if (hasVerticalScrollbar) document.body.classList.add('modal-scroll-fix');
}

export const closePanel = () => {
    _panelVisible.next(false);
    // It's not the modal, but the same effect can be had.
    document.body.classList.remove('body-open-modal');
    document.body.classList.remove('modal-scroll-fix');
}