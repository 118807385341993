import React, { useState, useEffect } from 'react';
import { CssGridBox, Box, Button } from 'mui';
import { PriceTag } from 'market-dto';
import { ChildPriceTag } from './child-price-tag';
import { ParentPriceTag } from './parent-price-tag';

const isNullOrZero = (x:number|null) => {
    if (x === null) return true;
    if (x === 0) return true;
    return false;
}

interface PriceTagDetailsProps {
    readonly tag:PriceTag;
    readonly isLast:boolean;
}

export const PriceTagDetails = ({ tag, isLast }:PriceTagDetailsProps) => {

    const [ showAll, setShowAll ] = useState<boolean>(false);
    const [ parents, setParents ] = useState<PriceTag[]>([]);

    // the parent price is composed of child prices. Are any child prices zero?
    // if so, we will hide them by default, and thus need more/less button
    const showMoreLess = tag.tags.some(parent => parent.tags.some(child => child.price === 0));

    useEffect(() => {
        setParents(tag.tags.map(parent => {
            return showAll ? parent : {
                ...parent,
                tags: parent.tags.filter(child => !isNullOrZero(child.price))
            }
        }))
    }, [showAll])

    // Find out which child has the most children!
    const maxKids = parents.map(x => x.tags.length).sort((a,b) => b - a)[0];

    // Because we want to force this into a grid, we need kids in a 2d array: [amount of parents] x [ max kids ]
    const kids:Array<Array<PriceTag | null>> = parents.map(x => {
        // create new padded array
        return x.tags.concat(Array(maxKids - x.tags.length).fill(null));
    })

    const rowNums = Array.from(Array(maxKids).keys()) // creates: [0, 1, 2 .... maxKids]

    return (
        <Box bg="body" px="1" py="1" borderSides="bottom-right-and-left" borderWidth="thick" borderColor="inner-card-bg">
            <CssGridBox cols={parents.length * 2} gap="0">

                { parents.map((x, n) => <ParentPriceTag key={n} tag={x} isLast={n === parents.length-1} /> )}

                <Box
                    style={{ gridColumnEnd: 'span ' + tag.tags.length * 2 }}
                    borderColor="inner-card-bg"
                    borderWidth="thin"
                    borderSides="bottom"
                    borderStyle="dashed"
                />

                { rowNums.map(rowNo => {
                    return (
                        <React.Fragment key={'r' + rowNo}>
                            { kids.map((tags, n) => <ChildPriceTag key={rowNo + '_' + n} tag={tags[rowNo]} isFirstRow={rowNo===0} isLastCol={n === kids.length-1} /> ) }
                        </React.Fragment>
                    )
                })}                
            </CssGridBox>
            { showMoreLess && (
                <Box textAlign="right">
                    <Button size="xs" type="link" onClick={() => setShowAll(!showAll)}>{ showAll ? 'Less' : 'More' }</Button>
                </Box>
            ) }
        </Box>
    )
}
