import { ActivityMonitorJob } from '../types';
import { _jobs } from './states';

export const updateJob = (jobId:string, partialJobData:Partial<ActivityMonitorJob>) => {
    const dict = _jobs.getValue();
    const job = dict[jobId];
    if (!job) throw new Error('Missing job: ' + jobId);
    _jobs.next({
        ...dict,
        [jobId]: {
            ...job,
            ...partialJobData
        }
    })
}