import React from 'react';
import { Box, Flex, FlexVCenter, CssGridBox, Icon, FgColor, BorderColor, Text } from 'mui';
import { format, proposal } from 'services';
import { formatDistanceToNow, parseISO } from 'date-fns';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly loan:proposal.HitLoan;
    readonly refreshingCashWindow?:boolean;
    readonly customizing:boolean;
}
export const HitFieldCashWindow = ({ hitInfo, loan, refreshingCashWindow, customizing }:Props) => {

    const price = loan.cashWindow.price;
    const rtPrice = loan.cashWindow.realtimePrice ?? 0;
    const diff = rtPrice - price;
    const green = '#33ee44';
    const red = '#CC3333';
    // set flag if really small change, show +
    const greenAbove = 0.5;   // set to 0.5 while testing
    const redBelow = -0.5;     // set to 0.5 while testing
    const redOrGreen = diff > greenAbove ? green : diff < redBelow ? red : undefined;
    const asOf = loan.cashWindow.realtimeAsOf ? formatDistanceToNow(parseISO(loan.cashWindow.realtimeAsOf)) : '\u00A0';
    const refreshing = loan.commitmentDays && refreshingCashWindow ? true : false;

    return (
        <FlexVCenter
            p="half"
            m={customizing ? 'half' : '0'}
            fontSize="sm"
            borderWidth="thin"
            borderSides="all"
            borderColor="transparent"
            bg="card"
            cursor="default"
            fg="default"
        >
            <Box flex="1">
                <Box>{ format.toFourDecimals(price) }</Box>
                {/* { rtPrice === 0 && (
                    <Box>{ format.toFourDecimals(price) }</Box>
                ) }
                { rtPrice > 0 && (
                    <Box>{ format.toFourDecimals(rtPrice) }</Box>
                ) } */}
                <Box fg="faded">Cash Window</Box>
            </Box>

            <Box flex="1">

                <FlexVCenter fg="faded" justifyContent="flex-end" style={{ color:redOrGreen }}>
                    { refreshing && (
                        <FlexVCenter fg="faded">
                            <Icon name="loader" spinning  mr="half" />
                            Refreshing
                        </FlexVCenter>
                    ) }
                    { !refreshing && rtPrice > 0 && (
                        <>
                            { diff > greenAbove && (
                                <Icon name="arrow-up" mr="half" />
                            ) }
                            { diff < redBelow && (
                                <Icon name="arrow-down" mr="half" />
                            ) } 
                            <Box>
                                { !redOrGreen && diff > 0 && '+' }
                                { format.toFourDecimals(diff) }
                            </Box>
                        </>
                    ) }
                </FlexVCenter>
                <Box fg="really-faded" textAlign="right">
                    { asOf }
                </Box>
            </Box>
       </FlexVCenter>
    )
}