import { BatchItem } from '../types';

export const canAutoAssign = (item:BatchItem):boolean => {
    // Can we just take the top suggestion?
    const { tapeId, matchResults } = item;
    if (!tapeId) return false;
    if (!matchResults || matchResults.length === 0) return false;

    // do not allow auto assign if there are multiple match results!
    if (matchResults.length > 1) return false;

    const first = matchResults[0];
    const { generated, sellerOrg, aggregatorName, sellerOrgs, matchState } = first;
    if (!generated) return false;
    if (first.type === "agg") {
        if (!aggregatorName) return false;
        if (matchState !== "AGG_EXACTLY_ONE_POSSIBLE_SELLER") return false;
        // make sure sellerOrgs (sellers found that matched sellerName in tape) has one-and-only-one match
        // new: agg doesn't even use sellerOrgs!
        // if (!sellerOrgs || sellerOrgs.length !== 1) return false;
    } else if (first.type === "non-agg") {
        if (!sellerOrg) return false;
    }
    // You are good enough to be taken as-is!
    return true;
}