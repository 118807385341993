import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const MUTATE = gql`
    mutation insertServicer($objects: [servicer_insert_input!]){
        insert_servicer(objects: $objects) {
            ${ RETURNING }
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.Servicer>]
}

interface MutateOutput {
    readonly insert_servicer: {
        readonly returning:[GQL.Servicer]; // expect just one
    }
}

export const insertServicer = async (servicer:Partial<GQL.Servicer>):Promise<GQL.Servicer|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(MUTATE, {
        objects: [servicer]
    })
    const arr = result?.data?.insert_servicer.returning;
    if (!arr) return;
    if (arr.length !== 1) return;
    return arr[0];
}