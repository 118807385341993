import { query, gql, GQL } from "../../../client";
import { FLOW_FIELDS } from './returning';
import { alpha } from 'services';

interface QueryResult {
    readonly flow:GQL.Flow[];
}

const QUERY =  gql`
    query {
        flow(where:{}) { ${ FLOW_FIELDS } }
    }
`;

export const fetchAllFlows = async ():Promise<GQL.Flow[]> => {
    const result = await query<any, QueryResult>(QUERY, {})
    const arr = result.data.flow.map(x => {
        return {
            ...x,
            tags: x.tags ?? [] // ensure it exists.
        }
    }).filter(x => !(x as any).deleted);
    // most recently run at top.
    arr.sort((a,b) => {
        const a1 = a.recentActivity?.executedAt ?? '';
        const b1 = b.recentActivity?.executedAt ?? '';
        if (a1 < b1) return 1;
        if (a1 > b1) return -1;
        return 0;
    })
    return arr.map(flow => alpha.withFlattenedTasks(flow));
}
