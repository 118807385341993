import { mutate, gql, GQL } from "../../client";

interface MutateOutput {
    readonly delete_user: {
        readonly affectedRow:number;
    }
}

interface MutateInput {
    readonly where:GQL.WhereString;
}

const INSERT = gql`
    mutation deleteUser($where: user_bool_exp!) {
        delete_user(where: $where) {
            errorMessage
            affectedRow
        }
    }
`;

export const deleteUser = async (user:GQL.User):Promise<boolean> => {
	const result = await mutate<MutateInput, MutateOutput>(INSERT, {
        where: {
            _id: {
                _eq: user.id
            }
        }
    })
    return result?.data?.delete_user.affectedRow === 1 ? true : false;
}