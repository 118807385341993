import React, { useState } from 'react';
import { CssGrid } from 'mui';
import { OfferRow } from './offer-row';
import { GQL } from 'market-dto';

interface OfferRowsProps {
    readonly loan:GQL.Loan;
    readonly offers:GQL.Offer[];
    readonly canCheck:boolean;
}
export const OfferRows = ({ loan, offers, canCheck }:OfferRowsProps) => {
    return (
        <CssGrid cols={4} gap="0">
            { offers.map((offer, n) => (
                <OfferRow
                    key={n}
                    canCheck={canCheck}
                    offerIndex={n}
                    offer={offer}
                    loan={loan}
                    isLast={n === offers.length-1}
                />
            )) }
        </CssGrid>
    )
}