import { fetchAdjTable } from 'api';
import { GQL } from 'market-dto';
import { LlpaImportExportItem } from './llpa-config';

export const buildExportItem = async () => {
    // Fetch from server every time (until we change this to cache stuff or zstate)
    let result:{[x in GQL.LlpaTableName]?:GQL.AdjTable } = {};
    await Promise.all(GQL.LLPA_TABLES.map(async name => {
        result[name] = await fetchAdjTable(name);
    }))
    return result as LlpaImportExportItem;
}
