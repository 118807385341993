import React, { useState } from 'react';
import { Box, Input, Text, FlexVCenter, Button, Icon, IconButton, IntoPortal } from 'mui';
import { tapeImporter, utils } from 'services';
import { ExpandedState } from './types';

interface Props {
    readonly exState:ExpandedState;
    readonly setExState:(x:ExpandedState) => void;
}
export const AskAgg = ({ exState, setExState }:Props) => {

    const { item } = exState;
    const { tapeId, rowsAndHeaders, defaultMapResult, matchResults, model } = item;
    if (!tapeId || !rowsAndHeaders) throw new Error('Expected tapeId and rowsAndHeaders');

    const [ yesNo, setYesNo ] = useState<boolean|null>(null);
    const [ aggName, setAggName ] = useState<string>('');

    const [ mappedSellerInfo ] = useState(() => {
        if (defaultMapResult) {
            return tapeImporter.getSellerInfoByApplyingOrides(model, rowsAndHeaders, item.sellers, exState.orides);
        } else {
            return {
                distinctSellerNames: [],
                distinctSellerOrgIds: []
            }
        }
    })

    const { distinctSellerNames, distinctSellerOrgIds } = mappedSellerInfo;
    // const aggSuspicionLevel = considerAgg(distinctSellerNames, distinctSellerOrgIds);
    
    const forSellerOrg = distinctSellerOrgIds.length === 1 ? item.sellers.find(s => s.id === distinctSellerOrgIds[0]) : undefined;
    const unknownSellerName = !forSellerOrg && distinctSellerNames.length === 1 ? distinctSellerNames[0] : undefined;

    const continueWithAggName = () => {
        if (!aggName.trim()) return;
        setExState({
            ...exState,
            newAggregatorName: aggName.trim(),
            step: "mapping"
            // tab: "MAPPING"
        })
    }

    // If you answered yes, you can go back to the first question.
    // If you are being asked "yes/no" You can only go back if matches exist.
    const canGoBack = yesNo || (matchResults && matchResults.length > 0);

    const yes = () => {
        setYesNo(true);
    }

    const no = () => {
        setExState({
            ...exState,
            step: "seller-options"
        })
    }

    const back = () => {
        if (!canGoBack) return;
        if (yesNo) {
            setYesNo(null);
        } else {
            setExState({
                ...exState,
                step: "match-options"
            })
        }
    }
    
    return (
        <>
            <IntoPortal id="expanded-title-left">
                { canGoBack && (
                    <Box mr="1">
                        <IconButton
                            onClick={back}
                            name="arrow-left"
                            size="xs"
                        />
                    </Box>
                ) }
            </IntoPortal>
            <IntoPortal id="expanded-title">Tape Source</IntoPortal>

            <Box bg="card" width="card-md" roundedEdges="all" mt="1">

                
                <Box p="1" fontSize="sm">

                    <Box mb="1">
                        Tapes that originate from aggregators must contain a column identifying the seller.
                    </Box>
                    { (forSellerOrg || unknownSellerName) && (
                        <>
                            <Box mb="1">
                                This tape contains a column for the seller,
                                which is an indicator that it was generated by an aggregator.  
                            </Box>
                            { forSellerOrg && (
                                <Box>
                                    The seller identified in this tape is:
                                    &nbsp;
                                    <Text fg="accent">{ forSellerOrg.name }</Text>
                                </Box>
                            ) }
                            { unknownSellerName && (
                                <Box>
                                    The seller identified is not currently in our system.
                                    The name found in the tape is: { unknownSellerName }
                                </Box>
                            ) }
                        </>
                    ) }

                    { (!forSellerOrg && !unknownSellerName) && (
                        <Box>
                            <Box mb="1">
                                This tape does <Text fg="loud">NOT</Text> appear to have originated from an aggregator because no seller column was found.
                            </Box>
                        </Box>
                    ) }

                    { yesNo && (
                        <FlexVCenter
                            mt="1"
                            pt="1"
                            textAlign="center"
                            borderColor="alert"
                            borderSides="top"
                            borderWidth="thin"
                        >
                            <Input
                                autoFocus
                                placeholder="Aggregator Name"
                                size="md"
                                width="100%"
                                value={aggName}
                                setValue={setAggName}
                                onEnter={continueWithAggName}
                            />
                            <Button
                                ml="1"
                                type={aggName.trim() ? 'default' : 'disabled'}
                                onClick={continueWithAggName}
                            >Continue</Button>
                        </FlexVCenter>
                    ) }


                    { yesNo === null && (
                        <>
                            <Box
                                mt="1"
                                pt="1"
                                textAlign="center"
                                borderColor="alert"
                                borderSides="top"
                                borderWidth="thin"
                            >
                                <Box fg="loud">
                                    Did this tape originate from an aggregator?
                                </Box>
                            </Box>
                            <FlexVCenter justifyContent="center" mt="1">
                                <Button
                                    onClick={yes}
                                    type="default"
                                    size="sm"
                                    mr="half"
                                >Yes</Button>
                                <Button
                                    ml="half"
                                    size="sm"
                                    type="default"
                                    onClick={no}
                                >No</Button>
                            </FlexVCenter>
                        </>
                    ) }
                </Box>
            </Box>
        </>

    )

}
