export const pricingModules = [
	{
		"type": "llra",
		"csv": [
			[
				"",
				"",
				"<=50",
				"50.01-55",
				"55.01-60",
				"60.01-65",
				"65.01-70",
				"70.01-75",
				"75.01-80",
				"80.01-85",
				"85.01-90",
				"90.01-95"
			],
			[
				"",
				">=740",
				"‐0.250",
				"‐0.250",
				"‐0.250",
				"‐0.250",
				"‐0.125",
				"0",
				"0.125",
				"0.375",
				"0.875",
				"1.25"
			],
			[
				"",
				"720‐739",
				"‐0.250",
				"‐0.250",
				"‐0.250",
				"‐0.125",
				"0",
				"0.125",
				"0.25",
				"0.5",
				"1",
				"1.375"
			],
			[
				"",
				"700‐719",
				"‐0.125",
				"‐0.125",
				"‐0.125",
				"0",
				"0.125",
				"0.25",
				"0.375",
				"0.625",
				"1.375",
				"NA"
			],
			[
				"",
				"680‐699",
				"0",
				"0",
				"0",
				"0.125",
				"0.25",
				"0.375",
				"0.5",
				"0.75",
				"1.5",
				"NA"
			],
			[
				"",
				"660‐679",
				"0.25",
				"0.25",
				"0.25",
				"0.375",
				"0.5",
				"0.625",
				"0.75",
				"1.25",
				"NA",
				"NA"
			],
			[
				"",
				"640‐659",
				"0.5",
				"0.5",
				"0.5",
				"0.75",
				"0.875",
				"1.25",
				"1.625",
				"2.625",
				"NA",
				"NA"
			],
			[
				"",
				"620‐639",
				"0.75",
				"0.75",
				"0.75",
				"1",
				"1.375",
				"1.875",
				"2.5",
				"3.5",
				"NA",
				"NA"
			],
			[
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				""
			],
			[
				"",
				"40 Year",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375"
			],
			[
				"",
				"30 Year",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25"
			],
			[
				"",
				"7/1 ARM",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125"
			],
			[
				"",
				"10/1 ARM",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25"
			],
			[
				"",
				"Interest Only",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.25",
				"0.25",
				"0.375",
				"0.375",
				"0.375"
			],
			[
				"45.01-50 DTI",
				"Edge",
				"0",
				"0",
				"0",
				"0",
				"0",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"NA"
			],
			[
				"45.01-50 DTI ",
				"Self",
				"0",
				"0",
				"0",
				"0",
				"0.125",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"NA"
			],
			[
				"",
				"NOO Property",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"NA",
				"NA"
			],
			[
				"",
				"2-4 Units",
				"0",
				"0",
				"0",
				"0",
				"0.125",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25"
			],
			[
				"",
				"Cash out Refi",
				"0",
				"0",
				"0",
				"0.125",
				"0.25",
				"0.375",
				"0.375",
				"0.375",
				"NA",
				"NA"
			],
			[
				"",
				"Non-warrantable condos",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25"
			],
			[
				"",
				"Second home",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.125",
				"0.25",
				"0.25",
				"NA",
				"NA"
			],
			[
				"",
				"Ln Amt >=100k and <300k",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25"
			],
			[
				"Smart Vest",
				"No prepay",
				"0.75",
				"0.75",
				"0.75",
				"0.75",
				"0.75",
				"0.75",
				"0.75",
				"NA",
				"NA",
				"NA"
			],
			[
				"Smart Vest",
				"1 yr Prepay",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"0.375",
				"NA",
				"NA",
				"NA"
			],
			[
				"Smart self ",
				"12 mo bank statement",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.375",
				"0.5",
				"0.5",
				"NA"
			],
			[
				"Smart funds",
				"asset qualifier",
				"0",
				"0",
				"0",
				"0",
				"0",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"NA"
			],
			[
				"Smart edge ",
				"1 year income",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.25",
				"0.375",
				"0.5",
				"0.5",
				"0.5"
			]
		],
		"hints": [
			[
				{},
				{},
				{
					"regex": {},
					"source": "<=50",
					"interested": "LTV",
					"upperInclusiveBound": 50
				},
				{
					"regex": {},
					"source": "50.01-55",
					"interested": "LTV",
					"lowerInclusiveBound": 50.01,
					"upperInclusiveBound": 55
				},
				{
					"regex": {},
					"source": "55.01-60",
					"interested": "LTV",
					"lowerInclusiveBound": 55.01,
					"upperInclusiveBound": 60
				},
				{
					"regex": {},
					"source": "60.01-65",
					"interested": "LTV",
					"lowerInclusiveBound": 60.01,
					"upperInclusiveBound": 65
				},
				{
					"regex": {},
					"source": "65.01-70",
					"interested": "LTV",
					"lowerInclusiveBound": 65.01,
					"upperInclusiveBound": 70
				},
				{
					"regex": {},
					"source": "70.01-75",
					"interested": "LTV",
					"lowerInclusiveBound": 70.01,
					"upperInclusiveBound": 75
				},
				{
					"regex": {},
					"source": "75.01-80",
					"interested": "LTV",
					"lowerInclusiveBound": 75.01,
					"upperInclusiveBound": 80
				},
				{
					"regex": {},
					"source": "80.01-85",
					"interested": "LTV",
					"lowerInclusiveBound": 80.01,
					"upperInclusiveBound": 85
				},
				{
					"regex": {},
					"source": "85.01-90",
					"interested": "LTV",
					"lowerInclusiveBound": 85.01,
					"upperInclusiveBound": 90
				},
				{
					"regex": {},
					"source": "90.01-95",
					"interested": "LTV",
					"lowerInclusiveBound": 90.01,
					"upperInclusiveBound": 95
				}
			],
			[
				{},
				{
					"regex": {},
					"source": ">=740",
					"interested": "FICO",
					"lowerInclusiveBound": 740
				},
				{
					"interested": "LLRA",
					"value": -0.25
				},
				{
					"interested": "LLRA",
					"value": -0.25
				},
				{
					"interested": "LLRA",
					"value": -0.25
				},
				{
					"interested": "LLRA",
					"value": -0.25
				},
				{
					"interested": "LLRA",
					"value": -0.125
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.875
				},
				{
					"interested": "LLRA",
					"value": 1.25
				}
			],
			[
				{},
				{
					"regex": {},
					"source": "720‐739",
					"interested": "FICO",
					"lowerInclusiveBound": 720,
					"upperInclusiveBound": 739
				},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{},
				{}
			],
			[
				{},
				{
					"regex": {},
					"source": "700‐719",
					"interested": "FICO",
					"lowerInclusiveBound": 700,
					"upperInclusiveBound": 719
				},
				{
					"interested": "LLRA",
					"value": -0.125
				},
				{
					"interested": "LLRA",
					"value": -0.125
				},
				{
					"interested": "LLRA",
					"value": -0.125
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.625
				},
				{
					"interested": "LLRA",
					"value": 1.375
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{
					"regex": {},
					"source": "680‐699",
					"interested": "FICO",
					"lowerInclusiveBound": 680,
					"upperInclusiveBound": 699
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 1.5
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{
					"regex": {},
					"source": "660‐679",
					"interested": "FICO",
					"lowerInclusiveBound": 660,
					"upperInclusiveBound": 679
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.625
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 1.25
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{
					"regex": {},
					"source": "640‐659",
					"interested": "FICO",
					"lowerInclusiveBound": 640,
					"upperInclusiveBound": 659
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.875
				},
				{
					"interested": "LLRA",
					"value": 1.25
				},
				{
					"interested": "LLRA",
					"value": 1.625
				},
				{
					"interested": "LLRA",
					"value": 2.625
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{
					"regex": {},
					"source": "620‐639",
					"interested": "FICO",
					"lowerInclusiveBound": 620,
					"upperInclusiveBound": 639
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 1
				},
				{
					"interested": "LLRA",
					"value": 1.375
				},
				{
					"interested": "LLRA",
					"value": 1.875
				},
				{
					"interested": "LLRA",
					"value": 2.5
				},
				{
					"interested": "LLRA",
					"value": 3.5
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				}
			],
			[
				{
					"interested": "DTI",
					"source": "45.01-50 DTI",
					"lowerInclusiveBound": 45.01,
					"upperExclusiveBound": 50
				},
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{
					"interested": "DTI",
					"source": "45.01-50 DTI ",
					"lowerInclusiveBound": 45.01,
					"upperExclusiveBound": 50
				},
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.125
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				}
			],
			[
				{
					"interested": "Product",
					"name": "Smart Vest"
				},
				{},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0.75
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Smart Vest"
				},
				{},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Smart self "
				},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Smart funds"
				},
				{},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Smart edge "
				},
				{},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.25
				},
				{
					"interested": "LLRA",
					"value": 0.375
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.5
				},
				{
					"interested": "LLRA",
					"value": 0.5
				}
			]
		],
		"id": "cknqoaob500003w66oqx43ox6"
	},
	{
		"type": "ratesheet",
		"csv": [
			[
				"Product",
				"Rate",
				"Combined",
				"Price"
			],
			[
				"Edge",
				"6",
				"Edge6",
				"105.78"
			],
			[
				"Edge",
				"5.875",
				"Edge5.875",
				"105.75"
			],
			[
				"Edge",
				"5.75",
				"Edge5.75",
				"105.71"
			],
			[
				"Edge",
				"5.625",
				"Edge5.625",
				"105.67"
			],
			[
				"Edge",
				"5.5",
				"Edge5.5",
				"105.62"
			],
			[
				"Edge",
				"5.375",
				"Edge5.375",
				"105.57"
			],
			[
				"Edge",
				"5.25",
				"Edge5.25",
				"105.52"
			],
			[
				"Edge",
				"5.125",
				"Edge5.125",
				"105.46"
			],
			[
				"Edge",
				"5",
				"Edge5",
				"105.39"
			],
			[
				"Edge",
				"4.875",
				"Edge4.875",
				"105.32"
			],
			[
				"Edge",
				"4.75",
				"Edge4.75",
				"105.25"
			],
			[
				"Edge",
				"4.625",
				"Edge4.625",
				"105.16"
			],
			[
				"Edge",
				"4.5",
				"Edge4.5",
				"105.07"
			],
			[
				"Edge",
				"4.375",
				"Edge4.375",
				"104.97"
			],
			[
				"Edge",
				"4.25",
				"Edge4.25",
				"104.65"
			],
			[
				"Edge",
				"4.125",
				"Edge4.125",
				"104.33"
			],
			[
				"Edge",
				"4",
				"Edge4",
				"104.03"
			],
			[
				"Edge",
				"3.875",
				"Edge3.875",
				"103.76"
			],
			[
				"Edge",
				"3.75",
				"Edge3.75",
				"103.48"
			],
			[
				"Edge",
				"3.625",
				"Edge3.625",
				"103.21"
			],
			[
				"Edge",
				"3.5",
				"Edge3.5",
				"102.93"
			],
			[
				"Self",
				"6",
				"Self6",
				"105.87"
			],
			[
				"Self",
				"5.875",
				"Self5.875",
				"105.83"
			],
			[
				"Self",
				"5.75",
				"Self5.75",
				"105.8"
			],
			[
				"Self",
				"5.625",
				"Self5.625",
				"105.75"
			],
			[
				"Self",
				"5.5",
				"Self5.5",
				"105.7"
			],
			[
				"Self",
				"5.375",
				"Self5.375",
				"105.65"
			],
			[
				"Self",
				"5.25",
				"Self5.25",
				"105.59"
			],
			[
				"Self",
				"5.125",
				"Self5.125",
				"105.52"
			],
			[
				"Self",
				"5",
				"Self5",
				"105.45"
			],
			[
				"Self",
				"4.875",
				"Self4.875",
				"105.37"
			],
			[
				"Self",
				"4.75",
				"Self4.75",
				"105.3"
			],
			[
				"Self",
				"4.625",
				"Self4.625",
				"105.2"
			],
			[
				"Self",
				"4.5",
				"Self4.5",
				"104.89"
			],
			[
				"Self",
				"4.375",
				"Self4.375",
				"104.57"
			],
			[
				"Self",
				"4.25",
				"Self4.25",
				"104.25"
			],
			[
				"Self",
				"4.125",
				"Self4.125",
				"103.93"
			],
			[
				"Self",
				"4",
				"Self4",
				"103.63"
			],
			[
				"Self",
				"3.875",
				"Self3.875",
				"103.35"
			],
			[
				"Self",
				"3.75",
				"Self3.75",
				"103.08"
			],
			[
				"Self",
				"3.625",
				"Self3.625",
				"102.8"
			],
			[
				"Trac",
				"7",
				"Trac7",
				"106.28"
			],
			[
				"Trac",
				"6.875",
				"Trac6.875",
				"106.27"
			],
			[
				"Trac",
				"6.75",
				"Trac6.75",
				"106.26"
			],
			[
				"Trac",
				"6.625",
				"Trac6.625",
				"106.24"
			],
			[
				"Trac",
				"6.5",
				"Trac6.5",
				"106.22"
			],
			[
				"Trac",
				"6.375",
				"Trac6.375",
				"106.19"
			],
			[
				"Trac",
				"6.25",
				"Trac6.25",
				"106.17"
			],
			[
				"Trac",
				"6.125",
				"Trac6.125",
				"106.14"
			],
			[
				"Trac",
				"6",
				"Trac6",
				"106.1"
			],
			[
				"Trac",
				"5.875",
				"Trac5.875",
				"106.06"
			],
			[
				"Trac",
				"5.75",
				"Trac5.75",
				"106.01"
			],
			[
				"Vest",
				"6.5",
				"Vest6.5",
				"106.13"
			],
			[
				"Vest",
				"6.375",
				"Vest6.375",
				"106.11"
			],
			[
				"Vest",
				"6.25",
				"Vest6.25",
				"106.09"
			],
			[
				"Vest",
				"6.125",
				"Vest6.125",
				"106.06"
			],
			[
				"Vest",
				"6",
				"Vest6",
				"106.03"
			],
			[
				"Vest",
				"5.875",
				"Vest5.875",
				"105.99"
			],
			[
				"Vest",
				"5.75",
				"Vest5.75",
				"105.95"
			],
			[
				"Vest",
				"5.625",
				"Vest5.625",
				"105.9"
			],
			[
				"Vest",
				"5.5",
				"Vest5.5",
				"105.85"
			],
			[
				"Vest",
				"5.375",
				"Vest5.375",
				"105.79"
			],
			[
				"Vest",
				"5.25",
				"Vest5.25",
				"105.73"
			],
			[
				"Vest",
				"5.125",
				"Vest5.125",
				"105.66"
			],
			[
				"Vest",
				"5",
				"Vest5",
				"105.59"
			],
			[
				"Vest",
				"4.875",
				"Vest4.875",
				"105.51"
			],
			[
				"Vest",
				"4.75",
				"Vest4.75",
				"105.2"
			],
			[
				"Vest",
				"4.625",
				"Vest4.625",
				"104.88"
			],
			[
				"Vest",
				"4.5",
				"Vest4.5",
				"104.56"
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"Vest",
				"4.375",
				"Vest4.375",
				"104.24"
			],
			[
				"Vest",
				"4.25",
				"Vest4.25",
				"103.92"
			],
			[
				"Vest",
				"4.125",
				"Vest4.125",
				"103.6"
			],
			[
				"Vest",
				"4",
				"Vest4",
				"103.28"
			],
			[
				"Vest",
				"3.875",
				"Vest3.875",
				"102.96"
			],
			[
				"Vest",
				"3.75",
				"Vest3.75",
				"102.64"
			],
			[
				"Vest",
				"3.625",
				"Vest3.625",
				"102.32"
			],
			[
				"Vest",
				"3.5",
				"Vest3.5",
				"102"
			],
			[
				"Vest",
				"3.375",
				"Vest3.375",
				"101.68"
			],
			[
				"Vest",
				"3.25",
				"Vest3.25",
				"101.36"
			],
			[
				"Vest",
				"3.125",
				"Vest3.125",
				"101.04"
			],
			[
				"Vest",
				"3",
				"Vest3",
				"100.72"
			],
			[
				"Vest",
				"2.875",
				"Vest2.875",
				"100.4"
			],
			[
				"Vest",
				"2.75",
				"Vest2.75",
				"100.08"
			],
			[
				"Vest",
				"2.625",
				"Vest2.625",
				"99.76"
			],
			[
				"Vest",
				"2.5",
				"Vest2.5",
				"99.44"
			],
			[
				"Vest",
				"2.375",
				"Vest2.375",
				"99.12"
			],
			[
				"Vest",
				"2.25",
				"Vest2.25",
				"98.8"
			],
			[
				"Vest",
				"2.125",
				"Vest2.125",
				"98.48"
			],
			[
				"Vest",
				"2",
				"Vest2",
				"98.16"
			],
			[
				"Vest",
				"1.875",
				"Vest1.875",
				"97.84"
			],
			[
				"Vest",
				"1.75",
				"Vest1.75",
				"97.52"
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"Edge",
				"3.375",
				"Edge3.375",
				"102.93"
			],
			[
				"Edge",
				"3.25",
				"Edge3.25",
				"102.93"
			],
			[
				"Edge",
				"3.125",
				"Edge3.125",
				"102.93"
			],
			[
				"Edge",
				"3",
				"Edge3",
				"102.93"
			],
			[
				"Edge",
				"2.875",
				"Edge2.875",
				"102.93"
			],
			[
				"Edge",
				"2.75",
				"Edge2.75",
				"102.93"
			],
			[
				"Edge",
				"2.625",
				"Edge2.625",
				"102.93"
			],
			[
				"Edge",
				"2.5",
				"Edge2.5",
				"102.93"
			],
			[
				"Edge",
				"2.375",
				"Edge2.375",
				"102.93"
			],
			[
				"Edge",
				"2.25",
				"Edge2.25",
				"102.93"
			],
			[
				"Edge",
				"2.125",
				"Edge2.125",
				"102.93"
			],
			[
				"Edge",
				"2",
				"Edge2",
				"102.93"
			],
			[
				"Edge",
				"1.875",
				"Edge1.875",
				"102.93"
			],
			[
				"Edge",
				"1.75",
				"Edge1.75",
				"102.93"
			],
			[
				"",
				"",
				"",
				""
			],
			[
				"Self",
				"3.5",
				"Self3.5",
				"102.8"
			],
			[
				"Self",
				"3.375",
				"Self3.375",
				"102.8"
			],
			[
				"Self",
				"3.25",
				"Self3.25",
				"102.8"
			],
			[
				"Self",
				"3.125",
				"Self3.125",
				"102.8"
			],
			[
				"Self",
				"3",
				"Self3",
				"102.8"
			],
			[
				"Self",
				"2.875",
				"Self2.875",
				"102.8"
			],
			[
				"Self",
				"2.75",
				"Self2.75",
				"102.8"
			],
			[
				"Self",
				"2.625",
				"Self2.625",
				"102.8"
			],
			[
				"Self",
				"2.5",
				"Self2.5",
				"102.8"
			],
			[
				"Self",
				"2.375",
				"Self2.375",
				"102.8"
			],
			[
				"Self",
				"2.25",
				"Self2.25",
				"102.8"
			],
			[
				"Self",
				"2.125",
				"Self2.125",
				"102.8"
			],
			[
				"Self",
				"2",
				"Self2",
				"102.8"
			],
			[
				"Self",
				"1.875",
				"Self1.875",
				"102.8"
			],
			[
				"Self",
				"1.75",
				"Self1.75",
				"102.8"
			]
		],
		"hints": [
			[
				{
					"interested": "Product",
					"name": "Product"
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"Combined",
				"Price"
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 6
				},
				"Edge6",
				{
					"interested": "Price",
					"value": 105.78
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.875
				},
				"Edge5.875",
				{
					"interested": "Price",
					"value": 105.75
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.75
				},
				"Edge5.75",
				{
					"interested": "Price",
					"value": 105.71
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.625
				},
				"Edge5.625",
				{
					"interested": "Price",
					"value": 105.67
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.5
				},
				"Edge5.5",
				{
					"interested": "Price",
					"value": 105.62
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.375
				},
				"Edge5.375",
				{
					"interested": "Price",
					"value": 105.57
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.25
				},
				"Edge5.25",
				{
					"interested": "Price",
					"value": 105.52
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5.125
				},
				"Edge5.125",
				{
					"interested": "Price",
					"value": 105.46
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 5
				},
				"Edge5",
				{
					"interested": "Price",
					"value": 105.39
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.875
				},
				"Edge4.875",
				{
					"interested": "Price",
					"value": 105.32
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.75
				},
				"Edge4.75",
				{
					"interested": "Price",
					"value": 105.25
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.625
				},
				"Edge4.625",
				{
					"interested": "Price",
					"value": 105.16
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.5
				},
				"Edge4.5",
				{
					"interested": "Price",
					"value": 105.07
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.375
				},
				"Edge4.375",
				{
					"interested": "Price",
					"value": 104.97
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.25
				},
				"Edge4.25",
				{
					"interested": "Price",
					"value": 104.65
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4.125
				},
				"Edge4.125",
				{
					"interested": "Price",
					"value": 104.33
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 4
				},
				"Edge4",
				{
					"interested": "Price",
					"value": 104.03
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.875
				},
				"Edge3.875",
				{
					"interested": "Price",
					"value": 103.76
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.75
				},
				"Edge3.75",
				{
					"interested": "Price",
					"value": 103.48
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.625
				},
				"Edge3.625",
				{
					"interested": "Price",
					"value": 103.21
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.5
				},
				"Edge3.5",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 6
				},
				"Self6",
				{
					"interested": "Price",
					"value": 105.87
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.875
				},
				"Self5.875",
				{
					"interested": "Price",
					"value": 105.83
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.75
				},
				"Self5.75",
				{
					"interested": "Price",
					"value": 105.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.625
				},
				"Self5.625",
				{
					"interested": "Price",
					"value": 105.75
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.5
				},
				"Self5.5",
				{
					"interested": "Price",
					"value": 105.7
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.375
				},
				"Self5.375",
				{
					"interested": "Price",
					"value": 105.65
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.25
				},
				"Self5.25",
				{
					"interested": "Price",
					"value": 105.59
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5.125
				},
				"Self5.125",
				{
					"interested": "Price",
					"value": 105.52
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 5
				},
				"Self5",
				{
					"interested": "Price",
					"value": 105.45
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.875
				},
				"Self4.875",
				{
					"interested": "Price",
					"value": 105.37
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.75
				},
				"Self4.75",
				{
					"interested": "Price",
					"value": 105.3
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.625
				},
				"Self4.625",
				{
					"interested": "Price",
					"value": 105.2
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.5
				},
				"Self4.5",
				{
					"interested": "Price",
					"value": 104.89
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.375
				},
				"Self4.375",
				{
					"interested": "Price",
					"value": 104.57
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.25
				},
				"Self4.25",
				{
					"interested": "Price",
					"value": 104.25
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4.125
				},
				"Self4.125",
				{
					"interested": "Price",
					"value": 103.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 4
				},
				"Self4",
				{
					"interested": "Price",
					"value": 103.63
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.875
				},
				"Self3.875",
				{
					"interested": "Price",
					"value": 103.35
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.75
				},
				"Self3.75",
				{
					"interested": "Price",
					"value": 103.08
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.625
				},
				"Self3.625",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 7
				},
				"Trac7",
				{
					"interested": "Price",
					"value": 106.28
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.875
				},
				"Trac6.875",
				{
					"interested": "Price",
					"value": 106.27
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.75
				},
				"Trac6.75",
				{
					"interested": "Price",
					"value": 106.26
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.625
				},
				"Trac6.625",
				{
					"interested": "Price",
					"value": 106.24
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.5
				},
				"Trac6.5",
				{
					"interested": "Price",
					"value": 106.22
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.375
				},
				"Trac6.375",
				{
					"interested": "Price",
					"value": 106.19
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.25
				},
				"Trac6.25",
				{
					"interested": "Price",
					"value": 106.17
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6.125
				},
				"Trac6.125",
				{
					"interested": "Price",
					"value": 106.14
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 6
				},
				"Trac6",
				{
					"interested": "Price",
					"value": 106.1
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 5.875
				},
				"Trac5.875",
				{
					"interested": "Price",
					"value": 106.06
				}
			],
			[
				{
					"interested": "Product",
					"name": "Trac"
				},
				{
					"interested": "NoteRate",
					"value": 5.75
				},
				"Trac5.75",
				{
					"interested": "Price",
					"value": 106.01
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 6.5
				},
				"Vest6.5",
				{
					"interested": "Price",
					"value": 106.13
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 6.375
				},
				"Vest6.375",
				{
					"interested": "Price",
					"value": 106.11
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 6.25
				},
				"Vest6.25",
				{
					"interested": "Price",
					"value": 106.09
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 6.125
				},
				"Vest6.125",
				{
					"interested": "Price",
					"value": 106.06
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 6
				},
				"Vest6",
				{
					"interested": "Price",
					"value": 106.03
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.875
				},
				"Vest5.875",
				{
					"interested": "Price",
					"value": 105.99
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.75
				},
				"Vest5.75",
				{
					"interested": "Price",
					"value": 105.95
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.625
				},
				"Vest5.625",
				{
					"interested": "Price",
					"value": 105.9
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.5
				},
				"Vest5.5",
				{
					"interested": "Price",
					"value": 105.85
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.375
				},
				"Vest5.375",
				{
					"interested": "Price",
					"value": 105.79
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.25
				},
				"Vest5.25",
				{
					"interested": "Price",
					"value": 105.73
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5.125
				},
				"Vest5.125",
				{
					"interested": "Price",
					"value": 105.66
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 5
				},
				"Vest5",
				{
					"interested": "Price",
					"value": 105.59
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.875
				},
				"Vest4.875",
				{
					"interested": "Price",
					"value": 105.51
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.75
				},
				"Vest4.75",
				{
					"interested": "Price",
					"value": 105.2
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.625
				},
				"Vest4.625",
				{
					"interested": "Price",
					"value": 104.88
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.5
				},
				"Vest4.5",
				{
					"interested": "Price",
					"value": 104.56
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.375
				},
				"Vest4.375",
				{
					"interested": "Price",
					"value": 104.24
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.25
				},
				"Vest4.25",
				{
					"interested": "Price",
					"value": 103.92
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4.125
				},
				"Vest4.125",
				{
					"interested": "Price",
					"value": 103.6
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 4
				},
				"Vest4",
				{
					"interested": "Price",
					"value": 103.28
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.875
				},
				"Vest3.875",
				{
					"interested": "Price",
					"value": 102.96
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.75
				},
				"Vest3.75",
				{
					"interested": "Price",
					"value": 102.64
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.625
				},
				"Vest3.625",
				{
					"interested": "Price",
					"value": 102.32
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.5
				},
				"Vest3.5",
				{
					"interested": "Price",
					"value": 102
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.375
				},
				"Vest3.375",
				{
					"interested": "Price",
					"value": 101.68
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.25
				},
				"Vest3.25",
				{
					"interested": "Price",
					"value": 101.36
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3.125
				},
				"Vest3.125",
				{
					"interested": "Price",
					"value": 101.04
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 3
				},
				"Vest3",
				{
					"interested": "Price",
					"value": 100.72
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.875
				},
				"Vest2.875",
				{
					"interested": "Price",
					"value": 100.4
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.75
				},
				"Vest2.75",
				{
					"interested": "Price",
					"value": 100.08
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.625
				},
				"Vest2.625",
				{
					"interested": "Price",
					"value": 99.76
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.5
				},
				"Vest2.5",
				{
					"interested": "Price",
					"value": 99.44
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.375
				},
				"Vest2.375",
				{
					"interested": "Price",
					"value": 99.12
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.25
				},
				"Vest2.25",
				{
					"interested": "Price",
					"value": 98.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2.125
				},
				"Vest2.125",
				{
					"interested": "Price",
					"value": 98.48
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 2
				},
				"Vest2",
				{
					"interested": "Price",
					"value": 98.16
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 1.875
				},
				"Vest1.875",
				{
					"interested": "Price",
					"value": 97.84
				}
			],
			[
				{
					"interested": "Product",
					"name": "Vest"
				},
				{
					"interested": "NoteRate",
					"value": 1.75
				},
				"Vest1.75",
				{
					"interested": "Price",
					"value": 97.52
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.375
				},
				"Edge3.375",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.25
				},
				"Edge3.25",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3.125
				},
				"Edge3.125",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 3
				},
				"Edge3",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.875
				},
				"Edge2.875",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.75
				},
				"Edge2.75",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.625
				},
				"Edge2.625",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.5
				},
				"Edge2.5",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.375
				},
				"Edge2.375",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.25
				},
				"Edge2.25",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2.125
				},
				"Edge2.125",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 2
				},
				"Edge2",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 1.875
				},
				"Edge1.875",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": "Edge"
				},
				{
					"interested": "NoteRate",
					"value": 1.75
				},
				"Edge1.75",
				{
					"interested": "Price",
					"value": 102.93
				}
			],
			[
				{
					"interested": "Product",
					"name": ""
				},
				{
					"interested": "NoteRate",
					"value": 0
				},
				"",
				{
					"interested": "Price",
					"value": 0
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.5
				},
				"Self3.5",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.375
				},
				"Self3.375",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.25
				},
				"Self3.25",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3.125
				},
				"Self3.125",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 3
				},
				"Self3",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.875
				},
				"Self2.875",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.75
				},
				"Self2.75",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.625
				},
				"Self2.625",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.5
				},
				"Self2.5",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.375
				},
				"Self2.375",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.25
				},
				"Self2.25",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2.125
				},
				"Self2.125",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 2
				},
				"Self2",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 1.875
				},
				"Self1.875",
				{
					"interested": "Price",
					"value": 102.8
				}
			],
			[
				{
					"interested": "Product",
					"name": "Self"
				},
				{
					"interested": "NoteRate",
					"value": 1.75
				},
				"Self1.75",
				{
					"interested": "Price",
					"value": 102.8
				}
			]
		],
		"id": "ratesheet"
	}
]



