import { mutate, gql } from "../../client";

// LP - freddie
// DU - fannie
/*
    run_freddie_pricing(
        sheetId: String
        costOfFunds: Float
        sellerMargin: Float
        settlementDate: ISO8601Date
        simulateTimedOut: Float
        simulateError: String
    ): JSON
*/

const RUN_PRICING = gql`
    mutation runFreddiePricing($sheetId: String, $costOfFunds: Float, $sellerMargin: Float, $simulateTimedOut: Float, $simulateError: String) {
        run_freddie_pricing(sheetId:$sheetId, costOfFunds: $costOfFunds, sellerMargin: $sellerMargin, simulateTimedOut: $simulateTimedOut, simulateError: $simulateError)
    }
`;

interface MutateOutput {
	readonly run_freddie_pricing: {
		readonly stub:string;
		readonly invocationId:string;
	}
}

interface MutateInput {
	readonly sheetId:string;
	readonly costOfFunds:number;
	readonly sellerMargin:number;
	readonly simulateTimedOut?:number;
	readonly simulateError?:string;
}

export const runFreddiePricing = async (
	sheetId:string,
	costOfFunds:number,
	sellerMargin:number,
	simulateTimedOut?:number,
	simulateError?:string
):Promise<string|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(RUN_PRICING, {
		sheetId,
		costOfFunds,
		sellerMargin,
		simulateError,
		simulateTimedOut
	})
	return result?.data?.run_freddie_pricing.invocationId;
}
