import React, { useEffect, useState } from 'react';
import { Alert, Box, FlexVCenter, Flex, Button, Icon, modal } from 'mui';
import { rule, utils } from 'services';
import { useZStateList } from 'hooks';
import { ChecklistItemEdit } from './checklist-item-edit';
import { CheckItem } from 'raccoon-engine';
import { NameAndDescModal } from './name-and-desc-modal';
import { genRandomSampleData, ExpFieldWithValue, toFieldsWithValues } from 'exp';
import { consolidatePreconditions } from './consolidate-preconditions';
import { Loading } from 'components';
import { ExpSubmitValues } from './types';


interface Props {
    readonly id:string;
    readonly onClose:() => void;
}
export const ChecklistEditor = ({ id, onClose }:Props) => {

    const [ sampleId, setSampleId ] = useState('random');
    const [ sampleData, setSampleData ] = useState<any>({});
    const [ fieldsWithValues, setFieldsWithValues ] = useState<ExpFieldWithValue[]>([]);

    // TODO: name these things similarly to how samples zlist are named.
    const {
        updating,
        data:checklists,
        editItem:editList,
        selectItem,
        updateItem,
        resetItem:resetChecklist,
        commitItem:save,
        editItemDirty:dirty,
        hydrating
    } = useZStateList(rule.zChecklists);

    const {
        updating:updatingSample,
        data:samples,
        // editItem:sample,
        clearItem:clearSample,
        selectItem:selectSample,
        updateItem:updateSample,
        commitItem:saveSample,
        editItemDirty:dirtySample
    } = useZStateList(rule.zModelSamples);

    

    useEffect(() => {
        const model = rule.getGQLModelById(editList?.modelId);
        if (!model) return;
        if (sampleId === 'random') {
            clearSample();
            const data = genRandomSampleData(model.schema);
            setSampleData(data);
            setFieldsWithValues(toFieldsWithValues(model.schema, data));
        } else {
            const sample = samples?.find(x => x.id === sampleId)
            if (!sample) throw new Error('WEIRD');
            selectSample(sample);
            const data = sample.data ?? {};
            setSampleData(data);
            setFieldsWithValues(toFieldsWithValues(model.schema, data));
        }
    }, [sampleId, editList?.modelId])

    const onSampleDataChange = (data:any) => {
        const model = rule.getGQLModelById(editList?.modelId);
        if (!model) return;
        setSampleData(data);
        setFieldsWithValues(toFieldsWithValues(model.schema, data));
        if (sampleId !== 'random') {
            updateSample({ data });
        }
    }

    useEffect(() => {
        const clist = checklists?.find(x => x.id === id);
        if (clist) selectItem(clist);
    }, [id])

    // Bring this back, but, on a delay to avoid flickering it.
    if (!editList) return <></> // <Alert mt="2" icon="alert-triangle">Checklist Not Found</Alert>;

    // const updateNameAndDesc = (name:string, desc:string) => {
    //     updateItem({ name, description: desc });
    // }

    const removeItem = (item:CheckItem) => {
        updateItem({ items: editList.items.filter(x => x !== item) });
    }

    const expSubmit = ({ id, description, preConds, exps, editItem, insertAt, conclusion, elseConclusion }:ExpSubmitValues) => {

        const nextPreConds = consolidatePreconditions(preConds);

        const currItems = editList.items;
        if (!editItem) {
            // Add
            const newItem:CheckItem = {
                id,
                description,
                conclusion,
                elseConclusion,
                ...nextPreConds ? { preconditions: nextPreConds } : {},
                expressions: exps
            }
            const nextItems = insertAt === undefined ?
                currItems.concat(newItem) :
                utils.insertItemAtIndex(currItems, insertAt, newItem);

            updateItem({ items: utils.sort(nextItems, x => x.id, "asc") });

        } else {
            // Update
            const nextItems = currItems.map(x => x.id !== editItem.id ? x : {
                id,
                description,
                conclusion,
                elseConclusion,
                ...nextPreConds ? { preconditions: nextPreConds } : {},
                expressions: exps
            });
            updateItem({ items: utils.sort(nextItems, x => x.id, "asc") });
        }
    }

    // We have to deal with the model not being part of the object itself coming from zList.
    const model = rule.getGQLModelById(editList?.modelId);
    if (!model) return <Alert icon="alert-triangle">Checklist has an invalid model associated with it!</Alert>

    const openChecklistModal = () => {
        modal.open({
            title: 'Basic Checklist Info',
            render: <NameAndDescModal checklist={editList} onUpdate={updateItem}/>
        })
    }

    return (
        <Box>
            <FlexVCenter justifyContent="space-between" mb="2">
                <FlexVCenter>
                    <Box fontSize="lg" fg="loud">{ editList.name }</Box>
                    <Box ml="2" fg="faded" textStyle="italic" fontSize="sm">{ editList.description }</Box>
                    <Icon ml="2" name="edit" fg="faded" fgHover="link" cursor="pointer" onClick={openChecklistModal}></Icon>
                </FlexVCenter>
                <Box>
                    <FlexVCenter>
                        <Button size="sm" type="link" onClick={onClose}>Close</Button>
                        { dirty && (
                            <Button size="sm" ml="1" type="secondary" onClick={resetChecklist}>Reset</Button>
                        ) }
                        <Button
                            size="sm"
                            type={dirty ? 'default' : 'disabled'}
                            ml="1"
                            onClick={save}
                            busy={updating}
                        >Save</Button>
                    </FlexVCenter>
                </Box>
            </FlexVCenter>

            <Loading
                loading={hydrating}
                render={
                    <ChecklistItemEdit
                        useMathJs
                        sampleId={sampleId}
                        setSampleId={setSampleId}
                        sampleData={sampleData}
                        setSampleData={onSampleDataChange}
                        fieldsWithValues={fieldsWithValues}
                        // updating={updating}
                        onRemove={removeItem}
                        // onCancel={onClose}
                        // onSave={save}
                        // dirty={dirty}
                        model={model}
                        checklist={editList}
                        onSubmit={expSubmit}
                    />
                }
            />
        </Box>
    )
}