import { format } from 'date-fns';
import { util, GQL } from 'market-dto';
import { getSellerById } from '../../sellers';
import { RunLike } from './types';


const getDateStr = (item:RunLike) => {
    if ("executedAt" in item) {
        return item.executedAt // flowActivity
    } else {
        return item.endedAt; // Run
    }
}

export const getFlowRunSeller = (item:RunLike) => {
    const { sellerSymbol, sellerOrgId } = item;
    const symbol = sellerSymbol.toUpperCase();
    if (!symbol || symbol === 'UNKNOWN' || symbol === 'UNSPECIFIED') {
        // check seller name
        return getSellerById(sellerOrgId)?.name ?? '???';
    }
    return symbol;
}

export const getFlowRunName = (item:RunLike) => {
    const name = getFlowRunSeller(item);
    const dt = util.ensureDate(getDateStr(item));
    // MMM do <-- "Nov 4th" for example
    // MMM d  <-- "Nov 4"
    // aaa   <-- am or pm
    // "Opes - Nov 4, 10:00am "
    return name.trim().toUpperCase() + ' - ' + format(dt, "MMM d, h:mmaaa");
}
