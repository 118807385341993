import { mutate, gql, GQL } from "api/client"

// Note: The mutation insert_sheet expects an ARRAY, even though, we will only send one.

const INSERT = gql`
    mutation insertSheetSummary($objects: [sheetSummary_insert_input!]) {
        insert_sheetSummary(objects: $objects) {
            errorMessage
            affectedRow
        }
    }
`;

interface MutateInput {
    readonly objects:[Partial<GQL.SheetSummary>]
}

export const insertSheetSummary = async (summary:Partial<GQL.SheetSummary>):Promise<any> => {
    // don't care about output for now
	return await mutate<MutateInput, any>(INSERT, { objects: [summary] });
}
