import * as api from 'api';
import { GQL } from 'market-dto';
import { createZStateList, createZState } from '../../z-state';

// const { data, loading } = useApiFetch(() => api.fetchEligibilitiyRulesets(stage.tasks.map(x => x.id)), []);
// const { data, loading } = useApiFetch(() => api.fetchEligibilitiyRulesets(stage.tasks.map(x => x.id)), []);

// strange TS error if you don't specify the generic as GQL.EligibilityRuleset
// it'll infer first one of the union'ed type!
// export const zEligibilityRulesets = createZStateList<GQL.EligibilityRuleset>({
//     apiFetchList: api.fetchEligibilitiyRulesets,
//     apiUpdateListItem: api.updateEligibilityRuleset,
//     isEqual: (a,b) => a.id === b.id
// })

export const zCurrentRun = createZState<GQL.FlowRun>({
    zid: "current-flow-run",
    apiFetch: api.fetchFlowRun
})


export type RunStateStatus = "START_RUN"|"RUNNING"|"FETCH_RESULTS"|"FETCH_XLS_CFG"|"DONE"|"IDLE"|"ERROR";

export interface RunState {
    readonly status:RunStateStatus;
    readonly flowRun?:GQL.FlowRun;
    readonly xlsExport?:GQL.TaskXlsExport;
}
export const zRunState = createZState<RunState>({
    zid: "current-flow-run-state",
    initVal: { status: "IDLE" }
})

// export const zEligibilityClassifiers = createZStateList<GQL.EligibilityClassifier>({
//     apiFetchList: api.fetchEligibilityClassifiers,
//     apiUpdateListItem: api.updateEligibilityClassifier,
//     isEqual: (a,b) => a.id === b.id
// })

export const zFlows = createZStateList({
    apiFetchList: api.fetchAllFlows,
    apiUpdateListItem: api.updateFlow,
    apiRemoveListItem: api.uninstallFlow,
    // and we are expecting the same typing for insertions:
    apiInsertListItem: async (props:any) => { // cannot have typing on this because insert is special
        return await api.installFlow(props.type, props.name);
    },
    isEqual: (a,b) => a.id === b.id
})

export const ensureFlowSelected = (id:string):GQL.Flow|undefined => {
    const { activeItemEditObj, list:flows } = zFlows.getState();
    if (!flows) return;
    if (activeItemEditObj && activeItemEditObj.id === id) return activeItemEditObj;
    const n = flows.findIndex(x => x.id === id);
    if (n > -1) {
        zFlows.selectItemByIndex(n); // sync call
        return zFlows.getState().activeItemEditObj;
    }
}

// export const getFlowByFlowId = (id:string):GQL.Flow|undefined => {
//     return zFlows.get()?.find(x => x.id === id);
// }

// export const addAlias = (id:string, alias:string) => {
//     const lcaseAlias = alias.trim().toLowerCase();
//     const rs = getEligibilityRulesetById(id);
//     if (!seller) throw new Error('Could not find seller');
//     if (seller.aliases.some(a => a.trim().toLowerCase() === lcaseAlias)) return;
//     const newAliases = seller.aliases.concat(lcaseAlias);
//     zSellers.apiUpdateListItem({ ...seller, aliases: newAliases });
// }
