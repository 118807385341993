import React, { ReactElement } from 'react';
import { Box, BoxProps } from '../box/box';
import { Flex } from '../flex/flex';

const mb = "2"; // TODO: maybe a css var + class? becase main-section uses it too.
const cardPadding = "3"

export const Card = ({ children, ...rest }:BoxProps) => {
    return <Box
        bg="card"
        shadow="card"
        p={cardPadding}
        mb={mb}
        roundedEdges="all"
        {... rest}
    >{ children }</Box>
}

export const InnerCard = ({ children, ...rest }:BoxProps) => {
    return <Box
        bg="inner-card"
        shadow="card"
        p={cardPadding}
        mb={mb}
        roundedEdges="all"
        {... rest}
    >{ children }</Box>
}


interface SplitCardHorizontalProps extends BoxProps {
    readonly left:ReactElement;
    readonly right:ReactElement;
}
export const SplitCardHorizontal = ({
    left,
    right,
    ...rest
}:SplitCardHorizontalProps) => {
    return (
        <Box
            display="flex"
            width="100%"
            shadow="card"
            mb={mb}
            {...rest}
        >
            <Box
                bg="card"
                roundedEdges="left"
                children={left}
            />
            <Box
                flex="1"
                bg="inner-card"
                roundedEdges="right"
                children={right}
            />
        </Box>
    )
}

interface SplitCardVerticalProps extends BoxProps {
    readonly top:ReactElement;
    readonly bottom:ReactElement;
}
export const SplitCardVertical = ({
    top,
    bottom,
    ...rest
}:SplitCardVerticalProps) => {
    return (
        <Box
            display="flex"
            // width="100%"
            shadow="card"
            flexDirection="column"
            mb={mb}
            {...rest}
        >
            <Box
                bg="card"
                roundedEdges="top"
                children={top}
            />
            <Box
                flex="1"
                bg="inner-card"
                roundedEdges="bottom"
                children={bottom}
            />
        </Box>
    )
}



interface SplitCardHorizontalProps2 extends BoxProps {
    readonly left:ReactElement;
    readonly topRight:ReactElement;
    readonly bottomRight:ReactElement;
}
export const SplitCardHorizontal2 = ({
    left,
    topRight,
    bottomRight,
    ...rest
}:SplitCardHorizontalProps2) => {
    return (
        <Box
            display="flex"
            width="100%"
            shadow="card"
            mb={mb}
            {...rest}
        >
            <Box
                bg="card"
                roundedEdges="left"
                children={left}
            />
            <Flex flex="1" flexDirection="column">
                <Box
                    bg="card"
                    roundedEdges="top-right"
                    children={topRight}
                />
                <Box
                    flex="1"
                    bg="inner-card"
                    roundedEdges="bottom-right"
                    children={bottomRight}
                />
            </Flex>
        </Box>
    )
}


interface LShapedCardProps extends BoxProps {
    readonly top:ReactElement;
    readonly bottomLeft:ReactElement;
    readonly bottomRight:ReactElement;
    readonly singleBgColor?:boolean; /* might seem strange, but occasionally useful */
}
export const LShapedCard = ({
    top,
    bottomLeft,
    bottomRight,
    singleBgColor,
    ...rest
}:LShapedCardProps) => {
    return (
        <Box
            display="flex"
            // width="100%"
            shadow="card"
            flexDirection="column"
            mb={mb}
            {...rest}
        >
            <Box
                bg="card"
                roundedEdges="top"
                children={top}
            />
            <Flex>
                <Box
                    bg="card"
                    roundedEdges="bottom-left"
                    children={bottomLeft}
                />
                <Box
                    flex="1"
                    bg={singleBgColor ? 'card' : 'inner-card'}
                    roundedEdges="bottom-right"
                    children={bottomRight}
                />
            </Flex>
        </Box>
    )
}
