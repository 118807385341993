import React from 'react';
import { Text, FlexVCenter, Checkbox, Box, Input, Dropdown } from 'mui';
import { sheets } from 'services';
import { useZState } from 'hooks';

interface Props {
    readonly count:number;
}
export const LoanFilters = ({ count }:Props) => {

    const { data:filters } = useZState(sheets.zFilters);
    if (!filters) throw new Error('Expected filters');

    const setFiltersText = (x:string) => {
        sheets.zFilters.update(f => ({ ...f, text: x }))
    }

    // const toggleShowAll = () => {
    //     sheets.filters.update(f => ({ ...f, showAll: !f.showAll }))
    // }

    return (
        <FlexVCenter>

            <Input appendIcon="search" placeholder="Filter Loans" value={filters.text} setValue={setFiltersText}/>

            <Box ml="2">
                <Dropdown
                    // size="sm"
                    width="frm-sm"
                    items={sheets.eligibilityFilterOpts}
                    value={sheets.eligibilityFilterOpts.find(x => x.value === filters.eligibility)}
                    setValue={val => sheets.zFilters.update(f => ({ ...f, eligibility: val }))}
                />
            </Box>
            <Box mx="2" fg="faded">
                <Text fg="default">{ count } </Text>
                Loan{ count !== 1 ? 's' : '' }
            </Box>
            {/* <Checkbox
                label="Hide Ineligible"
                checked={!filters.showAll}
                onToggle={toggleShowAll}
            /> */}
        </FlexVCenter>
    )
}