import { query, gql, GQL } from "../../client";
import { InvestorMarginsDoc } from "market-dto";
import { toInvestorMarginData } from './to-investor-margin-data';
import { InvestorAndMargins } from './types';

interface QueryResult {
	readonly investorMargins: [{
        readonly investorMarginsDoc:InvestorMarginsDoc;
    }];
	readonly investors:GQL.Org[];
}

const QUERY = gql`
    query  {
        investorMargins {
            id
            investorMarginsDoc
        }
        investors: org (where: {orgType : {_eq: "Investor"}}) {
            id
            name
            symbol
            aliases
        }
    }
`;

export const fetchInvestorMargins = async ():Promise<InvestorAndMargins[] | undefined> => {
    const result = await query<any, QueryResult>(QUERY, {});
    // const client = getApolloClient(false);
    // const result = await client.query<QueryResult>({ query: QUERY });
    if (!result) return undefined;
    const { data } = result;
    const dict = data.investorMargins[0].investorMarginsDoc;
    return toInvestorMarginData(data.investors, dict);
}
