import { mutate, gql, GQL } from "../../client";

const INSERT = gql`
    mutation insertLoanSources($loanSources: [loanSource_insert_input!])
    {
        insert_loanSource(objects: $loanSources) {
            errorMessage
            affectedRow
        }
    }
`;

interface MutateInput {
    readonly loanSources:GQL.LoanSource[];
}

interface MutateOutput {
    readonly insert_loanSource: {
        readonly affectedRow:number;
    }
}

export const insertLoanSources = async (loanSources:GQL.LoanSource[]):Promise<boolean> => {
    const result = await mutate<MutateInput, MutateOutput>(INSERT, { loanSources });
    return result?.data?.insert_loanSource.affectedRow === loanSources.length ? true : false;
}
