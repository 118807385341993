import { useApiFetch, useApiUpdate } from 'hooks';
import { InvestorMarginsDoc } from 'market-dto';
import { fetchInvestorMargins, upsertInvestorMargins, toInvestorMarginData } from 'api';

export const useInvestorMargins = () => {

    const { loading, data, setData } = useApiFetch(() => fetchInvestorMargins(), []);
    const { busy:saving, update } = useApiUpdate(async (doc:InvestorMarginsDoc) => {
        if (!doc) return;
        const result = await upsertInvestorMargins(doc);
        setData(result ? toInvestorMarginData(data!, result) : undefined);
        return result;
    });

    return {
        loading,
        investors:data,
        update,
        saving
    }
}
