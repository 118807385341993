import { FieldHint, HintedField } from 'raccoon-engine';
import React from 'react';

interface Args<T extends HintedField>
{
	fieldHint?:FieldHint<T>
}

const getLabel = (field:string) => {
	if (field === "LLPA") return ""
	else if (field === "LLRA") return ""
	else if (field === "Price") return ""
	else if (field === "LTV") return "LT"
	else if (field === "CLTV") return "CL"
	else if (field === "FICO") return "F"
	else if (field === "Term") return "TM"
	else if (field === "DTI") return "DT"
	else if (field === "LoanAmount") return "LA"
	else if (field === "LoanPurpose") return "LP"
	else if (field === "PropertyType") return "PT"
	else if (field === "OccupancyType") return "OT"
	else if (field === "DocType") return "DT"
	else if (field === "Interest") return "IN"
	else if (field === "Product") return "PD"
	else if (field === "NoteRate") return "NR"
	else if (field === "Coupon") return "CP"
	else return "?"
	
}

export const HintDisplay = <T extends HintedField> (props:Args<T>) => {
	const { fieldHint,  } = props
	const { interested } = fieldHint || {}
	const capLetters = getLabel(interested as string)
	const cln = "field-cap " +
		((capLetters === "?") ? "field-cap-unknown" : "")

	return <div className={cln} >{capLetters}
	</div>
}
