import React, { useState } from 'react';
import { Box, FlexVCenter, Flex, Icon, BgColor, SpacingSize } from 'mui';

export interface GenericSummaryItem {
    readonly top?:React.ReactNode;
    readonly bottom?:React.ReactNode;
}

export interface GenericSummaryGroup {
    readonly items:GenericSummaryItem[];
}

const renderItem = (n:number, item:GenericSummaryItem, bottomUpper:boolean) => (
    <Box px="1" py="half" textAlign="left" key={n}>
        <Box>{ item.top }</Box>
        <Box fg="faded" fontSize="sm" ucase={bottomUpper}>{ item.bottom }</Box>
    </Box>
)

const renderGroup = (n:number, group:GenericSummaryGroup, isLast:boolean, bottomUpper:boolean, toggle:()=>void) => (
    <FlexVCenter
        key={n}
        px="1"
        fontSize="md"
        fg="default"
        borderSides={isLast ? undefined : "right"}
        borderColor="inner-card-bg"
        borderWidth="thin"
        onClick={n === 0 ? toggle : undefined}
    >{ group.items.map((x, n) => renderItem(n, x, bottomUpper)) }</FlexVCenter>
)

interface GenericSummaryBarProps {
    readonly groups:GenericSummaryGroup[];
    readonly renderBtnArea?:React.ReactNode;
}
export const GenericSummaryBar = ({ renderBtnArea, groups }:GenericSummaryBarProps) => {

    const [ bottomUpper, setBottomUpper ] = useState(false);
    const toggle = () => {
        // setBottomUpper(!bottomUpper);
    }

    return (
        <Flex bg="card" roundedEdges="all" mb="2" alignItems="stretch">
            { groups.map((x, n) => renderGroup(n, x, n === groups.length-1, bottomUpper, toggle)) }
            <FlexVCenter justifyContent="flex-end" flex="1" mr="2">{ renderBtnArea }</FlexVCenter>
        </Flex>
    )
}