import React, { useState } from 'react';
import { GQL } from 'market-dto';
import { Box, Input, FlexVCenter, Button, Icon, Dropdown, DropdownItem, IntoPortal, IconButton } from 'mui';
import { tapeImporter, utils } from 'services';
import { ExpandedState } from './types';

interface Props {
    readonly exState:ExpandedState;
    readonly setExState:(x:ExpandedState) => void;
}
export const NonAggIdentifySeller = ({ exState, setExState }:Props) => {

    const [ org, setOrg ] = useState<GQL.Org>();

    const [ opts, setOpts ] = useState<DropdownItem[]>(() => {
        // Question: filter out sellers in valid matches we already found?
        // Perhaps not. Perhaps same seller exports multiple formats.
        return exState.item.sellers.map(seller => {
            return {
                label: seller.name,
                value: seller
            }
        })
    })

    const go = () => {
        // this is where i am.
        if (!org) return;
        setExState({
            ...exState,
            newSeller: org,
            step: "mapping"
        })
    }

    const back = () => {
        setExState({
            ...exState,
            step: "ask-agg"
        })
    }

    return (
        <>
            <IntoPortal id="expanded-title-left">
                <Box mr="1">
                    <IconButton
                        onClick={back}
                        name="arrow-left"
                        size="xs"
                    />
                </Box>
            </IntoPortal>

            <IntoPortal id="expanded-title">Tape Source</IntoPortal>

            <Box bg="card" width="card-md" roundedEdges="all" mt="1">

                <Box p="1" fontSize="sm">

                    {/* <Box mb="half">You have indicated that this tape did NOT originate from an aggregator.</Box> */}
                    <Box mb="1">Please identify the seller from which this tape originated.</Box>

                    {/* <Box ml="1" mb="1" fg="faded">Select Seller</Box> */}

                    <FlexVCenter
                        mt="1"
                        pt="1"
                        borderColor="alert"
                        borderSides="top"
                        borderWidth="thin"
                    >

                        <Box flex="1">
                            <Dropdown
                                width="100%"
                                size="sm"
                                value={opts.find(x => x.value === org)}
                                setValue={setOrg}
                                items={opts}
                            />
                        </Box>

                        <Button
                            ml="half"
                            size="sm"
                            type={org ? 'default' : 'disabled'}
                            onClick={go}
                        >Continue</Button>

                    </FlexVCenter>

                </Box>

            </Box>
        </>
    )
}