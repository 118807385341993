import React from 'react';
import { ExpNode, EXP_INVALID_CHAR } from './exp-types';
import { genericWalker, GenericWalkerOpts } from './generic-walker';

const getNextNonSpaceIndex = (s:string, startingAt:number) => {
    for (let i=startingAt; i < s.length; i++) {
        if (s[i] !== ' ') return i;
    }
    return -1;
}

const c = (css:string, flag:boolean) => !flag ? 'ee-node-' + css : 'ee-node-' + css + ' ee-node-active';

const cvalid = (css:string, activeFlag:boolean, isValid:boolean) => {
    return c(css, activeFlag) + (!isValid ? ' ee-invalid' : '');
}

const rendr = (css:string, val:string, active:boolean, valid:boolean) => {
    const n = getNextNonSpaceIndex(val, 0);
    return (
        <>
            { n > 0 && <div>{ ''.padStart(n, ' ') }</div> }
            <div className={cvalid(css, active, valid)}>{ val.trimLeft() }</div>
        </>
    )
}

export const expNodeToHtml = (node:ExpNode|null, activeNode:ExpNode|null):React.ReactElement => {
    if (node === null) return <></>;

    const fn = (x:GenericWalkerOpts<React.ReactElement>):React.ReactElement => {
        const { acc, initChildAcc, fn, lvl, node, childAcc } = x;
        if (node === null) return <></>;
        const active = node === activeNode;
        switch (node.type) {
            case 'ParenScopedNode': {
                // TODO: paren needs valid overall
                const isValid = node.endParenFound;
                return <>
                    {acc}
                    {rendr("paren", node.rawOpenParen, active, isValid)}
                    {childAcc}
                    {rendr("paren", node.rawCloseParen, active, isValid)}
                </>
            }
            case 'ExpressionNode': {
                // TODO: add "isValid" to exprsssion node
                return <>
                    {acc}
                    {rendr("op", node.rawOp, active, node.isValidOp && !node.warning)}
                    {childAcc}
                </>
            }
            case 'FunctionCallNode': {
                const isValid = node.isValidFnName && node.endParenFound && !node.warning;
                return <>
                    {acc}
                    <div>
                        {rendr("fn-name", node.rawFnName, active, isValid)}
                        {childAcc}
                        {rendr("fn-name", node.rawCloseParen, active, isValid)}
                    </div>
                </>
                // FN NEEDS IS VALID FN!!!
                // return <div className="ee-node-fn">{rendr("fn-name", node.rawFnName, active, node.endParenFound)}{acc}{node.rawCloseParen}</div>
            }
            case 'ArrayNode': {
                return <>
                    {acc}
                    <div className={cvalid("array", active, node.endBracketFound)}>
                        {node.rawOpenBracket}
                        {childAcc}
                        {node.rawCloseBracket}
                    </div>
                </>

                // return <div className={cvalid("array", active, node.endBracketFound)}>{node.rawOpenBracket}{acc}{node.rawCloseBracket}</div>
                // return <div>{node.rawOpenBracket}{acc}{node.rawCloseBracket}</div>
            }
            case 'StringConstantNode': {
                return <>{acc}{rendr("val", node.raw, active, node.endQuoteFound)}</>
            }
            case 'ConstantNode': {
                return <>{acc}{rendr("val", node.raw, active, node.value !== EXP_INVALID_CHAR)}</>
            }
            case 'IdentifierNode': {
                return <>{acc}{rendr("val", node.raw, active, node.isValid)}</>
            }
            case 'UnaryNode': {
                return <>{rendr("unary", node.raw, active, true)}</>
            }
            case 'ListItemNode': {
                return <>{acc}{rendr("list-item", node.rawPrepend, active, node.hasValidPrepend)}{childAcc}</>
            }
        }
        return <></>;
    }

    const result = genericWalker({
        parent: null,
        node,
        fn,
        acc: <></>,
        initChildAcc: <></>,
        lvl: 0        
    })
    return <div className="ee-node-root">{result}</div>
}
