import React from 'react';
import { modal, Link, Box, Format } from 'mui';
import { PriceTag } from 'market-dto';
import { LogModal } from './log-modal';

const SPACE = '\u00A0';

interface ChildPriceTagProps {
    readonly tag:PriceTag | null;
    readonly isFirstRow:boolean;
    readonly isLastCol:boolean;
}
export const ChildPriceTag = ({ tag, isFirstRow, isLastCol }:ChildPriceTagProps) => {

    // The first row needs special padding because of the divider above it.
    // (and no, we cannot put margin bottom on it, because we need the grid lines to touch! padding only...)

    const pt = isFirstRow ? '1' : 'half';
    const pb = 'half';

    const Box1 = ({ children }:any) => <Box px="1" pt={pt} pb={pb} fg="default" fontSize="sm">{ children }</Box>
    const Box2 = ({ children }:any) => <Box px="1" pt={pt} pb={pb} fg="faded" fontSize="sm" borderStyle="dashed" borderWidth="thin" borderSides="right" borderColor={isLastCol ? 'transparent' : 'inner-card-bg'}>{ children }</Box>

    if (tag === null) return <>
        <Box1>{ SPACE }</Box1>
        <Box2>{ SPACE }</Box2>
    </>;

    const hasLog = tag.logs && tag.logs.length > 0;

	const clk = () => modal.open({
		render: <LogModal tag={tag} />,
		title: tag.name + ' - details',
        size: "lg",
        noHeaderBg: true
    });

    return (
        <>
            <Box1>
                <Format as="4_decimals">{ tag.price }</Format>
            </Box1>
            <Box2>
                { hasLog && <Link onClick={clk}>{ tag.name }</Link> }
                { !hasLog && tag.name }
            </Box2>

        </>
    )
}
