import React, { useState } from 'react';
import { GQL, ImportModel } from 'market-dto';
import { useApi } from 'hooks';
import * as api from 'api';
import { Loading } from 'components';
import { XlsExportViewer } from '../../../../xls-viewer';

interface Props {
    readonly model:ImportModel;
    readonly flowRun:GQL.FlowRun;
    readonly task:GQL.FlatTask;
}
export const XlsExport = ({ model, flowRun, task }:Props) => {
    
    
    const [ readOnly, setReadOnly ] = useState(false);

    const { taskId } = task;
    const {
        update,
        fetching:loading,
        updating,
        dirty,
        data,
        onChange,
        reset
    } = useApi<GQL.TaskXlsExport>({
        fetch: async () => await api.fetchTaskXlsExport(model, taskId),
        update: async x => await api.updateTaskXlsExport(taskId, x),
        initEditting: true,
        deps: [taskId]
    })
    return (
        <Loading
            loading={loading}
            render={(
                <XlsExportViewer
                    model={model}
                    readOnly={readOnly}
                    onToggleReadonly={() => setReadOnly(!readOnly)}
                    flowRun={flowRun}
                    data={data!}
                    saving={updating}
                    dirty={dirty}
                    onSave={update}
                    onChange={onChange}
                    onReset={reset}
                />
            )}
        />
    )
}