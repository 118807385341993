import React, { useState, useRef, useMemo, useLayoutEffect, useEffect } from 'react';
import { GQL, ImportModel } from 'market-dto';
import { utils } from 'services';
import { XlsExportTableThead } from './xls-export-table-thead';
import { getHeaderLabel } from './get-header-label';

import { getFormattedVal } from './get-formatted-val';
import { useObs } from 'hooks';
import { popOverCfg$, openPopOver, closePopOver } from './components';
import { SrcMapPopOver2 } from './src-map-pop-over';

interface Props {
    readonly model:ImportModel;
    readonly readOnly:boolean;
    readonly flowRun:GQL.FlowRun;
    readonly colCfgs:GQL.XlsExportCol[];
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
    readonly selectedRowNo:number;
    readonly setSelectedRowNo:(n:number) => void;
    readonly setShowFilterPopper:(f:GQL.XlsExportCol, elem:HTMLElement) => void;
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly updateScrollProxy:() => void;
    readonly rows:GQL.FlowRunRow[];
    readonly filteredColCfgs:GQL.XlsExportCol[];
}

export const XlsExportTable = React.forwardRef<HTMLTableElement, Props>(({
    model,
    readOnly,
    flowRun,
    colCfgs,
    selectedColId,
    setSelectedColId,
    selectedRowNo,
    setSelectedRowNo,
    setShowFilterPopper,
    onColCfgsChange,
    updateScrollProxy,
    rows,
    filteredColCfgs
}, forwardMe) => {


    const popOverCfg = useObs(popOverCfg$);

    const cellCss = (colId:number, colNo:number, rowNo:number, isTopHeader?:boolean) => {

        if (popOverCfg && popOverCfg.colIndex == colNo && popOverCfg.rowIndex === rowNo) {
            return 'popped-over';
        }
        const arr = [];
        if (rowNo === selectedRowNo || colId === selectedColId) {
            arr.push('selected');
            if (isTopHeader && popOverCfg) {
                arr.push('force-hover');
            }
        }
        
        // if (byField[fieldId]?.exclude) arr.push('excluded');
        return arr.join(' ');
    }

    const tableRef = useRef<HTMLTableElement>(null);
    const onScrollIntoViewFn = useRef<any>();

    useLayoutEffect(() => {
        // Forward the input ref AND keep it here. We need it.
        if (!tableRef.current) {
            console.log('no sir!');
            return;
        }
        if (forwardMe) {
            if (utils.isFunction(forwardMe)) {
                (forwardMe as any)(tableRef.current);
            } else {
                (forwardMe as any).current = tableRef.current;
            }
        }
        // Here we wish to find init starting point of first row in table to fix it there.
        // Cannot do this with css, sadly, because we already have something fixed to 0.
        // (tbody as pos relative doesn't work!)
        if (tableRef.current.rows.length < 2) return;
        const { height } = tableRef.current.tHead?.getBoundingClientRect() ?? {};
        if (!height) return;
        const tr = tableRef.current.rows[1];

        // special case for top-left "1" cell (note: zindex+1 and left 0)
        const first = tr.cells[0];
        first.style.position = 'sticky';
        first.style.top = height + 'px';
        first.style.zIndex = '10';
        first.style.left = '0px';

        for (let i=1; i < tr.cells.length; i++) {
            const cell = tr.cells[i];
            cell.style.position = 'sticky';
            cell.style.top = height + 'px';
            cell.style.zIndex = '9';
        }
        

        const div = containerRef.current;
        if (!div) return;

        const checkShadow = () => {
            // Don't waste time on IntersectionObserver for this behavior.
            // It's a pain with 2 stickies stacked like this! old school.
            if (div.scrollTop > 2) {
                if (!hasShadow.current) {
                    hasShadow.current = true;
                    tr.classList.add('m-xls-stuck');
                }
            } else {
                
                if (hasShadow.current) {
                    hasShadow.current = false;
                    tr.classList.remove('m-xls-stuck');
                }
            }
        }
        div.addEventListener('scroll', checkShadow);

        return () => {
            div.removeEventListener('scroll', checkShadow);
        }
    }, [])

    const hasShadow = useRef(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollIntoViewTimer = useRef<any>();

    useEffect(() => {
        if (selectedColId === -1) return;
        if (!tableRef.current) return;
        if (!containerRef.current) return;
        const container = containerRef.current;
        const table = tableRef.current;
        const row = table.rows[1];
        const cells = Array.from(row.cells);
        const cell = cells.find(c => c.getAttribute('data-field-id') === String(selectedColId));
        if (!cell) return;
        cell.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' });

        // We're gonna do a little trick here.
        // There is no event which fires upon completion of scrollIntoView, nor do we know where we're headed!
        // So, we'll simply watch the scroll position, and wait for it to stop moving...which is a little subjective.
        // We'll say, watch until you stopped moving for x ms, and then we'll check position, tell proxy about it.


        const checkScrolling = () => {
            // this fires repeatedly...until it stops.
            // once it has stopped for 100 ms, we can assume we are done scrolling into view.
            // it's not bullet proof, but it's good enough.
            // setScrollingIntoView(true);
            clearTimeout(scrollIntoViewTimer.current);
            scrollIntoViewTimer.current = setTimeout(() => {
                // setScrollingIntoView(false);
                updateScrollProxy();
                if (onScrollIntoViewFn.current) {
                    onScrollIntoViewFn.current();
                    onScrollIntoViewFn.current = null;
                }
                container.removeEventListener('scroll', checkScrolling);
            }, 100)
        }
        container.addEventListener('scroll', checkScrolling);
        checkScrolling();
        return () => {
            container.removeEventListener('scroll', checkScrolling);
        }

    }, [selectedColId])


    const rightClick = (e:any, f:GQL.XlsExportCol, row:GQL.FlowRunRow, rowIndex:number, colIndex:number) => {
        // if (readOnly) return;
        const fieldId = f.type === 'MAPPED' ? f.fieldId : '';
        if (!fieldId) return; // how can this happen?
        e.preventDefault();
        
        // only pop it open when scrolling has for sure stopped.

        const openIt = () => {
            openPopOver({
                rowIndex,
                colIndex,
                elem: e.target,
                render: <SrcMapPopOver2
                    sheetId={flowRun.sheetId}
                    loanId={row.loan.id}
                    fieldId={fieldId}
                    model={model}
                />
            })
        }
        
        if (selectedColId === f.colId) {
            // There won't be any scrolling. Open it now.
            openIt();
        } else {
            onScrollIntoViewFn.current = openIt;
            setSelectedColId(f.colId);
        }
    }

    return (
        <div className="xls-table-inner-container m-xls-right-side-main-height" ref={containerRef}>
            <table className="xls-table" ref={tableRef}>
                <XlsExportTableThead
                    readOnly={readOnly}
                    colCfgs={filteredColCfgs}
                    flowRun={flowRun}
                    selectedColId={selectedColId}
                    selectedRowNo={selectedRowNo}
                    setSelectedColId={setSelectedColId}
                    setSelectedRowNo={setSelectedRowNo}
                    setShowFilterPopper={setShowFilterPopper}
                    showingPopFilter={popOverCfg ? true : false}
                    onColCfgsChange={onColCfgsChange}
                />
                <tbody>
                    <tr>
                        <td
                            className="sticky-col num-col"
                            onClick={() => setSelectedRowNo(1)}
                        >1</td>
                        { filteredColCfgs.map((f, colNo) => {
                            return (
                                <td
                                    key={colNo}
                                    data-field-id={String(f.colId)}
                                    className={cellCss(f.colId, colNo, 1)}
                                    
                                >
                                    { getHeaderLabel(model, f) }
                                </td>
                            )
                        }) }
                    </tr>
                    { rows.map((row, rowNo) => (
                        <tr key={rowNo}>
                            <td
                                className="sticky-col num-col"
                                onClick={() => setSelectedRowNo(rowNo+2)}
                            >{ rowNo + 2 }</td>
                            { filteredColCfgs.map((f, colNo) => (
                                <td
                                    key={rowNo + ':' + colNo}
                                    className={cellCss(f.colId, colNo, rowNo + 2)}
                                    onContextMenu={e => rightClick(e, f, row, rowNo + 2, colNo)}
                                >{ getFormattedVal(model, colCfgs, f, row) }</td>   
                            )) }
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>

    )
})

