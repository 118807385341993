import React, { useState } from 'react';
import { mapping } from 'services';
import { ColValMapItem } from './col-val-map-item';

const hideMe = (filterText:string, colName:string, hideEmpty:boolean, val:any) => {
    if (hideEmpty && (val === undefined || val === null || val === '')) return false;
    if (filterText) {
        if (colName.toUpperCase().includes(filterText)) return false;
        return true;
    }
    return false;
}

interface Props {
    readonly field:mapping.Field;
    readonly initField:mapping.Field;
    readonly hideEmpty:boolean;
    readonly items:mapping.ColumnValuesAndMapping[];
    readonly valuesIndex:number;
    readonly confirmIndex:number;
    readonly onClick:(spreadsheetColIndex:number) => void;
    readonly filterText:string;
}
export const ColValMapItems = ({
    field,
    hideEmpty,
    initField,
    items,
    valuesIndex,
    confirmIndex,
    onClick,
    filterText
}:Props) => {

    const [ hoverIndex, setHoverIndex ] = useState<number>(-1);

    return (
        <>
            { items.map((item, index) => {

                const val = item.values[valuesIndex];
                // const hide = hideEmpty && (val === undefined || val === null || val === '');
                const hide = hideMe(filterText, item.colName, hideEmpty, val);
                // item.colName
                return hide ? null : (
                    <ColValMapItem
                        key={index}
                        isMapped={field.res.winner === index}
                        isInitMapped={initField.res.winner === index}
                        item={item}
                        valuesIndex={valuesIndex}
                        onClick={() => onClick(index)}
                        onMouseOver={() => setHoverIndex(index)}
                        onMouseOut={() => setHoverIndex(-1)}
                        isHover={index === hoverIndex}
                        // isConfirmItem={index === confirmIndex}
                        // isConfirming={confirmIndex >= 0}
                    />
                )
            })}
        </>
    )
}

/*
    item,
    valuesIndex,
    isMapped,
    isHover,
    isInitMapped,
    onClick,
    onMouseOut,
    onMouseOver
*/
