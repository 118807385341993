import { GQL, Overrides, OverrideItem } from 'market-dto';

export const setMappingOverrideColumn = (
    overrideDict:Overrides,
    fieldId:string, // had to abandon keyof LoanModel
    colName:string
):Overrides => {
    // They are saying: Have fieldId map from colName
    // However, perhaps that is the global default? if so, remove? no. we'll still override.
    // HMmmm...There is something we could do: have guesser run and see if we get colName as the answer
    // AND that it is the lowest-level answer...but for now, if you specify, even if it would be picked anyway,
    // we will consider it an override.

    if (!colName) alert('can this happen? if so, we need to fix obj creation');
    // if you see that alert, make this like the fucntion below (for translate)

    const ride:OverrideItem = {
        ...overrideDict[fieldId], // preserve translate overrides (if exist)
        fromColumn: colName
    }
    return {
        ...overrideDict,
        [fieldId]: ride
    }
}

export const removeMappingOverrideColumn = (
    overrideDict:Overrides,
    fieldId:string // no longer typed, multiple models
):Overrides => {

    const ride:OverrideItem = overrideDict[fieldId] ?? {};
    const { fromColumn:removeMe, ...rideWithoutFromColumn } = ride;
    return {
        ...overrideDict,
        [fieldId]: rideWithoutFromColumn
    }
}
