import React from 'react';
import { Flex, FlexVCenter } from '../flex/flex';
import { Box, BoxProps } from '../box/box';
import { Icon, IconName } from '../icon/icon';
import { FgColor, FontSize } from '../../types';

interface Props extends BoxProps {
    readonly icon:IconName;
    readonly iconFg?:FgColor;
    readonly iconOnClick?: () => void;
    readonly children:any;
}
export const HoverIconBox = ({ icon, iconFg, iconOnClick, children, ...rest }:Props) => {
    // Why a flex in a flex? To keep the inner flex the size of the content. weird, but, works fine.
    return (
        <Flex>
            <FlexVCenter css="hover-icon-box" {...rest}>
                <Box mr="1">{ children }</Box>
                <Icon
                    name={icon}
                    fg={iconFg}
                    onClick={iconOnClick}
                    cursor={iconOnClick ? 'pointer' : 'default'}
                />
            </FlexVCenter>
        </Flex>
    )
}

// export const HoverIconLink = ({ icon, iconFg, iconOnClick, children, ...props }:Props) => {
//     // Why a flex in a flex? To keep the inner flex the size of the content. weird, but, works fine.
//     return (
//         <Flex>
//             <FlexVCenter css="hover-icon-box" {...props}>
//                 <Box mr="1">{ children }</Box>
//                 <Icon
//                     name={icon}
//                     fg={iconFg}
//                     onClick={iconOnClick}
//                     cursor={iconOnClick ? 'pointer' : 'default'}
//                 />
//             </FlexVCenter>
//         </Flex>
//     )
// }


