import React, { useState, useRef, useLayoutEffect } from 'react';
import { GQL, ImportModel, labels } from 'market-dto';
import { FlexVCenter, Icon, Box, PortalPlaceholder } from 'mui';
import { XlsBtn, PopOverFooter, PopOverHeader } from '../components';
import { getHeaderLabel } from '../get-header-label';
import { MappedField } from './mapped-field';
import { NonMappedField } from './non-mapped-field';

const withTrimmedHeader = (model:ImportModel, colCfg:GQL.XlsExportCol):GQL.XlsExportCol => {
    if (colCfg.isCalc) return colCfg;
    const defaultLabel = labels.byTaskXlsExportField(model, colCfg);
    const trimmedHeader = colCfg.headerLabel === undefined ? '' : colCfg.headerLabel.trim();
    if (!trimmedHeader || trimmedHeader === defaultLabel) {
        const { headerLabel:removeMe, ...nextColCfg } = colCfg;
        return nextColCfg;
    } else {
        return colCfg;
    }
}

interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportCol;
    readonly flowRun:GQL.FlowRun;
    readonly onChange:(x:GQL.XlsExportCol) => void;
    readonly onClose:() => void;
}

export const HeaderPopOver = ({ colCfg, flowRun, model, onChange, onClose }:Props) => {

    const [ editFieldCfg, setEditFieldCfg ] = useState<GQL.XlsExportCol>(colCfg);
    const [ confirmingExclude, setConfirmingExclude ] = useState(false);

    // This can certainly be optimized later...
    const dirty = JSON.stringify(colCfg) !== JSON.stringify(editFieldCfg);
    const isLoanField = colCfg.type === "MAPPED";

    const accept = (fieldCfg:GQL.XlsExportCol) => {
        onChange(withTrimmedHeader(model, fieldCfg));
        onClose();        
    }

    const updateAndClose = () => {
        // Before we just update, let's clean things up.
        // TODO: do we need to clean up (trim, check for override not really being needed) the other labels, enums? Maybe.
        onChange(withTrimmedHeader(model, editFieldCfg))
        onClose();
    }

    const excludeMe = () => {
        onChange({
            ...colCfg,
            exclude: true
        })
        onClose();
    }


    return (
        <>

            <PopOverHeader>{ getHeaderLabel(model, editFieldCfg) }</PopOverHeader>

            { editFieldCfg.type === "MAPPED" && (
                <MappedField
                    model={model}
                    colCfg={editFieldCfg}
                    flowRun={flowRun}
                    onChange={setEditFieldCfg}
                    onAccept={accept}
                    onClose={onClose}
                />
            ) }

            { editFieldCfg.type !== "MAPPED" && (
                <NonMappedField
                    colCfg={editFieldCfg}
                    flowRun={flowRun}
                    onChange={setEditFieldCfg}
                    onAccept={accept}
                    onClose={onClose}
                />
            ) }

            <PopOverFooter>
                <FlexVCenter justifyContent="space-between">
                    <XlsBtn
                        type={dirty ? 'default' : 'disabled'}
                        onClick={updateAndClose}
                    >Update</XlsBtn>                
                    { confirmingExclude && (
                        <FlexVCenter>
                            <XlsBtn
                                type="default"
                                onClick={excludeMe}
                            >Yes, Exclude It</XlsBtn>
                            &nbsp;
                            <XlsBtn
                                type="default"
                                onClick={() => setConfirmingExclude(false)}
                            >Nevermind</XlsBtn>
                        </FlexVCenter>
                    ) }
                    { !confirmingExclude && (
                        <XlsBtn
                            type="default"
                            onClick={() => setConfirmingExclude(true)}
                        >Exclude</XlsBtn>
                    ) }
                </FlexVCenter>
            </PopOverFooter>
        </>
    )

}