import { query, gql } from "../../client";

const QUERY = gql`
    query ($sheetId: String!) {
        tape(where: { id: { _eq: $sheetId }}) {
            base64Content
        }
    }
`;

interface QueryResult {
    readonly tape:[{
        readonly base64Content:string;
    }]
}

interface QueryInput {
    readonly sheetId:string;
}

export const fetchTapeBase64 = async (sheetId:string):Promise<string | undefined> => {
    const result = await query<QueryInput, QueryResult>(QUERY, { sheetId });
    if (result.data.tape.length !== 1) return;
    return result.data.tape[0].base64Content;
}
