import { Button } from "mui"
import { parseCSV } from 'raccoon-engine'
import React, { useState } from 'react';
import { ScaffoldFicoLtv } from './fico-ltv'
import { ScaffoldNoteRate } from "./noteRate"


export const Scaffold = () => {
	const [text, setText] = useState("")
	
	const onPaste = async () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		navigator.clipboard
		         .readText()
		         .then(s => {
			               const csv = parseCSV(s)
			               setText(JSON.stringify(csv))
		               }
		         )
	}
	return <div>
		<Button onClick={onPaste}>
			paste
		</Button>
		
		<textarea name="s" id="" cols={30} rows={10} value={text}/>
		
		<ScaffoldNoteRate/>
		<ScaffoldFicoLtv/>
	</div>
}
