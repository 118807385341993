import { BatchItem } from '../types';
import { zBatch } from '../z-batch';

export const expandItem = (item:BatchItem) => {
    
    // mergeBatchItemByIndex(item.index, { expanded: true });
    // New way: only one expanded at a time.
    zBatch.update(curr => {
        return {
            ...curr,
            items: curr.items.map(x => {
                return (x.index === item.index) ? {
                    ...x,
                    expanded: true
                } : {
                    ...x,
                    expanded: false
                }
            })
        }
    })
}