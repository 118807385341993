import {
	Coordinate, CrossMatchingSetNote, DataTable, InternalPricing, isNumber, MatchingSetNote,
	NUMERICAL_TYPES
} from "raccoon-engine"


/**
 * If return T, means there are some field with significant data on the left of (x,y)
 * @param x
 * @param y
 * @param d
 */
export const hasMaterialCellOnLeft = (x:number, y:number, d:DataTable) => {
	let isMaterial:boolean = false;
	for (let i = 0; i < x; i++) {
		const s = d.getPosition(i, y)
		isMaterial = isMaterial || (!!s)
	}
	return isMaterial
}

/**
 * If return T, means there are some field with significant data on the TOP of (x,y)
 * @param x
 * @param y
 * @param d
 */
export const hasMaterialCellOnTop = (x:number, y:number, d:DataTable) => {
	let isMaterial:boolean = false;
	for (let c = 0; c < y; c++) {
		const s = d.getPosition(x, c)
		isMaterial = isMaterial || (!!s)
	}
	return isMaterial
}


export type OrientationType = "Cell" | "Column" | "Row" | "Corner"

export function guessOrientation (dataTable:DataTable, x:number, y:number) {
	let determination:OrientationType = "Cell"
	if (isNumber(dataTable.getPosition(x, y)) &&
		hasMaterialCellOnLeft(x, y, dataTable) &&
		hasMaterialCellOnTop(x, y, dataTable))
	{ determination = "Corner"}
	if (!hasMaterialCellOnLeft(x, y, dataTable)) {determination = "Row"}
	if (!hasMaterialCellOnTop(x, y, dataTable)) {determination = "Column"}
	return determination
}

export function withoutDigits (s:string) {
	return (s) ? true : !/\d/.test(s)
}

export function findImpacted (orientation:OrientationType, x:number, y:number, dataTable:DataTable)
{
	const coordinates:Coordinate[] = []
	const rows = dataTable.height
	const cols = dataTable.width
	switch (orientation) {
		case "Cell":
			coordinates.push({ x, y });
			break;
		case "Column":
			for (let r = y; r < rows; r++) {
				coordinates.push({ x, y: r })
			}
			break;
		case "Row":
			for (let c = x; c < cols; c++) {
				coordinates.push({ x: c, y })
			}
			break;
		case "Corner":
			for (let c = x; c < cols; c++) {
				for (let r = y; r < rows; r++) {
					coordinates.push({ x: c, y: r })
				}
			}
			break;
	}
	return coordinates
}


export const isPriceValue = (s:any) => NUMERICAL_TYPES.includes(s)


export const findRateSheet = (modules:InternalPricing[]) => {
	return modules.find(m => m.id === "ratesheet")
}

export const findAdjustments = (modules:InternalPricing[]) => {
	return modules.filter(m => m.id !== "ratesheet")
}


export type CrossLookupFunction = (subject:any) => CrossMatchingSetNote[]
export type CrossLookupProvider = () => CrossMatchingSetNote[]
export type RowLookupFunction = (subject:any) => MatchingSetNote[]
