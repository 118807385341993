import { DropdownItem } from 'mui';

export const eligibilityFilterOpts:DropdownItem[] = [
    {
        label: 'Show All',
        value: 'show-all'
    },
    {
        label: 'All Eligible',
        value: 'any-eligible'
    },
    {
        label: 'Fannie Eligible',
        value: 'fannie-only'
    },
    {
        label: 'Freddie Eligible',
        value: 'freddie-only'
    }
]
