import React from 'react';
import { Box, Flex, Text, Button, FlexVCenter, CssGridBox } from 'mui';
import { GQL } from 'market-dto';
import { pricing, utils, format } from 'services';


const getLabel = (arr:pricing.CompareDetail[], n:number) => n < arr.length ? arr[n].label : '-';
const getValue = (arr:pricing.CompareDetail[], n:number) => n < arr.length ? arr[n].value : '-';
const to4 = (x?:number|string, y?:number|string) => {
    if (x === '-') return '-';
    if (typeof x === 'number' && typeof y === 'number') return format.toFourDecimals(x - y);
    if (typeof x === 'number') return format.toFourDecimals(x);
    return x ? format.toFourDecimals(+x) : ''
}


interface Props {
    readonly loan:GQL.Loan;
}
export const AigCompareModal = ({ loan }:Props) => {
    
    const d = pricing.getCompareData(loan);
    if (!d) return <Box p="2">Missing offer data with which to compare!</Box>

    const { mtrade:m, aig:a } = d;

    const maxCashWindowDetails = Math.max(m.cashWindowDetails.length, a.cashWindowDetails.length);

    return (
        <>
            {/* <Flex justifyContent="space-between" mt="1">
                <Box flex="1">MTRADE</Box>
                <Box flex="1">AIG</Box>
            </Flex> */}
            <CssGridBox cols={5} mt="2">
                <Box fg="success" fontWeight="bold">MTRADE</Box>
                <Box></Box>
                <Box fg="success" fontWeight="bold">AIG</Box>
                <Box></Box>
                <Box fg="success" fontWeight="bold">DIFF</Box>

                <Box fg="loud">Price</Box>
                <Box fg="loud">{ to4(m.price) }</Box>
                <Box fg="loud">Price</Box>
                <Box fg="loud">{ to4(a.price) }</Box>
                <Box fg="loud">{ to4(m.price, a.price) }</Box>


                <Box fg="loud">Cash Window</Box>
                <Box fg="loud">{ to4(m.cashWindowPrice) }</Box>
                <Box fg="loud">Cash Window</Box>
                <Box fg="loud">{ to4(a.cashWindowPrice) }</Box>
                <Box fg="loud">{ to4(m.cashWindowPrice, a.cashWindowPrice) }</Box>

                { utils.range(Math.max(m.cashWindowDetails.length, a.cashWindowDetails.length)).map(n => (
                    <React.Fragment key={n}>
                        <Box fg="faded">{ getLabel(m.cashWindowDetails, n) }</Box>
                        <Box fg="default">{ to4(getValue(m.cashWindowDetails, n)) }</Box>
                        <Box fg="faded">{ getLabel(a.cashWindowDetails, n) }</Box>
                        <Box fg="default">{ getValue(a.cashWindowDetails, n) }</Box>
                        <Box></Box>
                    </React.Fragment>
                )) }
                
                <Box fg="loud">Spread</Box>
                <Box fg="loud">{ to4(m.spreadPrice) }</Box>
                <Box fg="loud">Spread</Box>
                <Box fg="loud">{ to4(a.spreadPrice) }</Box>
                <Box fg="loud">{ to4(m.spreadPrice, a.spreadPrice) }</Box>

                { utils.range(Math.max(m.spreadPriceDetails.length, a.spreadPriceDetails.length)).map(n => (
                    <React.Fragment key={n}>
                        <Box fg="faded">{ getLabel(m.spreadPriceDetails, n) }</Box>
                        <Box fg="default">{ to4(getValue(m.spreadPriceDetails, n)) }</Box>
                        <Box fg="faded">{ getLabel(a.spreadPriceDetails, n) }</Box>
                        <Box fg="default">{ getValue(a.spreadPriceDetails, n) }</Box>
                        <Box></Box>
                    </React.Fragment>
                )) }

                <Box fg="loud">LLPA</Box>
                <Box fg="loud">{ to4(m.llpaPrice) }</Box>
                <Box fg="loud">LLPA</Box>
                <Box fg="loud">{ to4(a.llpaPrice) }</Box>
                <Box fg="loud">{ to4(m.llpaPrice, a.llpaPrice) }</Box>

                { utils.range(Math.max(m.llpaDetails.length, a.llpaDetails.length)).map(n => (
                    <React.Fragment key={n}>
                        <Box fg="faded">{ getLabel(m.llpaDetails, n) }</Box>
                        <Box fg="default">{ to4(getValue(m.llpaDetails, n)) }</Box>
                        <Box fg="faded">{ getLabel(a.llpaDetails, n) }</Box>
                        <Box fg="default">{ getValue(a.llpaDetails, n) }</Box>
                        <Box></Box>
                    </React.Fragment>
                )) }

                <Box fg="loud">SRP</Box>
                <Box fg="loud">{ to4(m.srpPrice) }</Box>
                <Box fg="loud">SRP</Box>
                <Box fg="loud">{ to4(a.srpPrice) }</Box>
                <Box fg="loud">{ to4(m.srpPrice, a.srpPrice) }</Box>

                { utils.range(Math.max(m.srpDetails.length, a.srpDetails.length)).map(n => (
                    <React.Fragment key={n}>
                        <Box fg="faded">{ getLabel(m.srpDetails, n) }</Box>
                        <Box fg="default">{ to4(getValue(m.srpDetails, n)) }</Box>
                        <Box fg="faded">{ getLabel(a.srpDetails, n) }</Box>
                        <Box fg="default">{ getValue(a.srpDetails, n) }</Box>
                        <Box></Box>
                    </React.Fragment>
                )) }

            </CssGridBox>
        </>
    )
}