import ApolloClient, { ApolloLink, ApolloQueryResult, DocumentNode, FetchResult } from "apollo-boost"
import { InMemoryCache } from "apollo-cache-inmemory"
import * as user from 'services/user';
import * as urls from 'services/urls';




export const getApolloClient = (withCache:boolean, useThisJwtInstead?:string) => {
	return new ApolloClient({
		uri: urls.getApiUrl(),
		cache: withCache ? new InMemoryCache({ addTypename: false}) : undefined,
		request: (operation) => {
			// if (throwErrorOnPurpose) {
			// 	throwErrorOnPurpose = false;
			// 	throw new Error('Testing throwing error on purpose');
			// }
			const jwt = useThisJwtInstead ?? user.getJwt();
			operation.setContext({
				headers: {
					...(jwt ? { 'Authorization': `Bearer ${ jwt }` } : {})
				}
			})
		}
	})
}

// Not sure what a tyeped client does. One day, look at this.
// export function getTypedClient<T>(withCache: boolean)  {
// 	return new ApolloClient<T>({
// 		uri: url,
// 		cache: withCache ? new InMemoryCache() : undefined,
// 		request: (operation) => {
// 			const jwt = user.getJwt();
// 			operation.setContext({
// 				headers: {
// 					...(jwt ? { 'Authorization': `Bearer ${ jwt }` } : {})
// 				}
// 			})
// 		}
// 	})
// }
