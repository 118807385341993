export const RETURNING = `
    id
    modelType
    sheetId
    fileName
    loanStats
    sellerOrgId
    sellerName
    sheetStatus
    tradeStatus
    createdAt
    updatedAt
    deleted
`;