import React, { useEffect } from 'react';
import { Box, Button, Flex, FlexVCenter, modal } from 'mui';
import { GQL } from 'market-dto';
import { useApiFetch } from 'hooks';
import { fetchTapeBase64 } from 'api';
import { base64, viewLoans, sheets, genSheet, pricing, user } from 'services';
import { HitInfo } from 'services/proposal';
import { PricingInfo } from 'services/pricing';

export const openDownloadModal = (sheet:GQL.LoanModelSheet, pricingInfo:PricingInfo, hitInfo?:HitInfo, trade?:GQL.Trade) => {
    modal.open({
        title: 'Download XLSX',
        size: 'sm',
        render: <DownloadModal sheet={sheet} pricingInfo={pricingInfo} hitInfo={hitInfo} trade={trade} />,
    })
}

interface Props {
    readonly sheet:GQL.LoanModelSheet;
    readonly hitInfo?:HitInfo;
    readonly pricingInfo:PricingInfo;
    readonly trade?:GQL.Trade;
}
export const DownloadModal = ({ sheet, hitInfo, trade, pricingInfo }:Props) => {

    const { data:b64Data, loading, load } = useApiFetch(() => fetchTapeBase64(sheet.id));

    useEffect(() => {
        if (!b64Data) return;
        base64.base64ToDownload(b64Data, sheet.id + '_init.xlsx');
        modal.close();
    }, [b64Data]);


    const downloadOriginal = () => load();

    const downloadLoansAndOffers = async () => {
        // this only works if we have the loan sources loaded!
        // alert('JEFF: need load loan sources before this.');
        await genSheet.genLoansAndOffersSheet(sheet);
        modal.close();
    }

    const downloadTradeSheet = async () => {
        if (!trade || !hitInfo) return;
        await genSheet.genTradeOutputSheet(hitInfo, trade, user.getUser()?.org.name ?? '');
        modal.close();
    }

    const downloadSellerHitSummary = async () => {
        if (!hitInfo) return;
        await genSheet.genSellerConfirmationSheet(sheet.id, hitInfo!);
        modal.close();
    }

    const downloadCommitment = async () => {
        if (!hitInfo) return;
        await genSheet.genCommitmentSummary(sheet.id, hitInfo!);
        modal.close();
    }

    const downloadCompareSheet = async () => {
        await genSheet.genComparisonSheet(sheet);
        modal.close();
    }

    return (
        <Flex mt="1" flexDirection="column" width="100%">
            { sheets.canShowTradeTab(sheet) && (
                <Box mt="1">
                    <Button
                        style={{ width:'100%' }}
                        type="secondary"
                        size="sm"
                        icon="download"
                        onClick={downloadTradeSheet}
                    >Trade</Button>
                </Box>
            ) }
            { sheets.canShowProposalTab(sheet) && (
                <>
                    <Box mt="1">
                        <Button
                            // Hit Summary for Seller View wants each loan (hit loans)
                            style={{ width:'100%' }}
                            type="secondary"
                            size="sm"
                            icon="download"
                            onClick={downloadSellerHitSummary}
                        >Seller Confirmation</Button>
                    </Box>
                    <Box mt="1">
                        <Button
                            style={{ width:'100%' }}
                            type="secondary"
                            size="sm"
                            icon="download"
                            onClick={downloadCommitment}
                        >Investor Commitment</Button>
                    </Box>
                </>
            ) }

            { pricingInfo.hasRunSpreadsheetPricing && (
                <Box mt="1">
                    <Button
                        style={{ width:'100%' }}
                        type="secondary"
                        size="sm"
                        icon="download"
                        onClick={downloadCompareSheet}
                    >Pricing Comparison</Button>
                </Box>
            ) }

            <Box mt="1">
                <Button
                    style={{ width:'100%' }}
                    type="secondary"
                    size="sm"
                    icon="download"
                    onClick={downloadLoansAndOffers}
                >Loans and Offers</Button>
            </Box>
            <Box mt="1">
                <Button
                    style={{ width:'100%' }}
                    type="secondary"
                    size="sm"
                    icon="download"
                    onClick={downloadOriginal}
                    busy={loading}
                >Original Spreadsheet</Button>
            </Box>
        </Flex>
    )
}