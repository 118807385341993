import { query, gql, GQL } from "../../client";
import { SellerMarginsDoc } from "market-dto";
import { toSellerMarginData } from './to-seller-margin-data';
import { SellerMarginData } from './types';

interface QueryResult {
	readonly sellerMargins: [{
        readonly sellerMarginsDoc:SellerMarginsDoc;
    }];
	readonly sellers:GQL.Org[];
}

const QUERY = gql`
    query  {
        sellerMargins {
            sellerMarginsDoc
        }
        sellers: org (where: {orgType : {_eq: "Seller"}}) {
            id
            name
            symbol
            aliases
        }
    }
`;

export const fetchSellerMargins = async ():Promise<SellerMarginData|undefined> => {
    const result = await query<any, QueryResult>(QUERY, {});
    // const client = getApolloClient(false);
    // const result = await client.query<QueryResult>({ query: QUERY });
    if (!result) return;
    const { data } = result;
    return toSellerMarginData(data.sellers, data.sellerMargins[0].sellerMarginsDoc);
}
