import { addMonths, parseISO, isBefore, startOfMonth } from 'date-fns'
import { DaysOfWeek, NthOfMonth } from './types';
import { getMatchingDatesInMonth } from './get-matching-dates-in-month';

export const getMatchingDates = (daysOfWeek:DaysOfWeek, nthOfMonth:NthOfMonth, firstOfLastMonthStr:string):Date[] => {
    const today = new Date();
    const firstOfCurrentMonth = startOfMonth(today);
    const firstOfLastMonth = parseISO(firstOfLastMonthStr);
    const firstOfLastMonthPlusOne = addMonths(firstOfLastMonth, 1);
    let d = firstOfCurrentMonth;
    let result:Date[] = [];
    // advance through the months
    while (isBefore(d, firstOfLastMonthPlusOne)) {
        // This month must be evaluated
        result = result.concat(...getMatchingDatesInMonth(today, daysOfWeek, nthOfMonth, d))
        d = addMonths(d, 1);
    }
    return result;
}