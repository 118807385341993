import React, { useState, useEffect } from 'react';
import { Tabs, BasicMenuItem, Alert, Box } from 'mui';
import { ExpContext, flatJsonSchemaToExpCtx, ExpContextField, ExpFieldWithValue, getEvalStatusFromString } from 'exp';
import { GQL } from 'market-dto';
import { CheckItem } from 'raccoon-engine';
import { getNextSequentialId } from './get-next-sequential-id';
import { ExpSubmitValues } from './types';
import { SampleDataTab } from './sample-data-tab';
import { ItemsTab } from './items-tab';
import { ImportExport } from './import-export';


export interface FieldAndData extends ExpContextField {
    readonly value:any;
}

const tabItems:BasicMenuItem[] = [
    {
        label: 'Checklist Items',
        value: 'items'
    },
    {
        label: 'Sample Data',
        value: 'data'
    },
    {
        label: 'Import/Export',
        value: 'importExport'
    }
]

interface Props {
    readonly sampleId:string;
    readonly setSampleId:(x:string) => void;
    readonly sampleData:any;
    readonly setSampleData:(x:any) => void;
    readonly fieldsWithValues:ExpFieldWithValue[];

    readonly checklist:GQL.Checklist;
    readonly model:GQL.Model;
    readonly useMathJs?:boolean;
    // readonly dirty:boolean;
    // readonly updating:boolean;
    // readonly onCancel:() => void;
    // readonly onSave:() => void;
    readonly onRemove:(removeItem:CheckItem) => void;
    readonly onSubmit:(values:ExpSubmitValues) => void;
}
export const ChecklistItemEdit = ({
    checklist,
    model,
    onSubmit,
    useMathJs,
    onRemove,
    // dirty,
    // onCancel,
    // onSave,
    // updating,
    sampleId,
    setSampleId,
    sampleData,
    setSampleData,
    fieldsWithValues
}:Props) => {

    const [ tab, setTab ] = useState(() => tabItems[0].value);
    const [ editItem, setEditItem ] = useState<CheckItem>();

    // TODO: remove sample data from CTX? Or somehow inject what we have here?
    const [ expCtx, setExpCtx ] = useState<ExpContext>(() => flatJsonSchemaToExpCtx(model.schema, useMathJs));

    useEffect(() => {
        const ctx = flatJsonSchemaToExpCtx(model.schema, useMathJs);
        setExpCtx(ctx);

        // setSampleData(genSampleData(ctx));
    }, [model]);

    const getCloneId = (item:CheckItem) => {
        const seqId = getNextSequentialId(checklist.items.map(x => x.id.trim().toLowerCase()));
        if (seqId) return seqId;
        let n = 2;
        const takenIds = checklist.items
            .filter(item => item.id !== editItem?.id)
            .map(item => item.id.trim().toLowerCase());
        while (takenIds.includes(item.id + ' (' + n + ')')) n++;
        return item.id + ' (' + n + ')';
    }

    const onClone = (item:CheckItem, intoIndex:number) => {
        // find this item, insert a new one right after it.
        const nextId = getCloneId(item);
        const newItem:CheckItem = {
            id: nextId,
            description: item.description ?? '',
            conclusion: item.conclusion ?? {},
            elseConclusion: item.elseConclusion ?? {},
            expressions: item.expressions,
            preconditions: item.preconditions
        }
        onSubmit({
            id:nextId,
            description: item.description ?? '',
            conclusion: newItem.conclusion ?? {},
            elseConclusion: newItem.elseConclusion ?? {},
            preConds: newItem.preconditions,
            exps: newItem.expressions,
            insertAt: intoIndex
        })
        setEditItem(newItem);
    }

    return (
        <>
            {/* { invalidIds.length > 0 && (
                <Alert icon="alert-triangle" mb="2">
                    <Box fg="danger">The following checklist item(s) contain one or more syntax errors:</Box>
                    <Box mono mt="1" fontSize="sm">
                        { invalidIds.join(', ') }
                    </Box>
                </Alert>
            ) } */}

            <Tabs items={tabItems} selected={tab} onSelect={setTab} mb="2" />

            { tab === 'data' && <SampleDataTab
                modelId={model.id}
                sampleData={sampleData}
                sampleId={sampleId}
                setSampleData={setSampleData}
                setSampleId={setSampleId}
                fieldsWithValues={fieldsWithValues}
            /> }
            { tab === 'items' && (
                <ItemsTab
                    checklist={checklist}
                    ctx={expCtx}
                    editItem={editItem}
                    model={model}
                    onClone={onClone}
                    onRemove={onRemove}
                    onSubmit={onSubmit}
                    sampleData={sampleData}
                    setEditItem={setEditItem}
                    fieldsWithValues={fieldsWithValues}
                />
            ) }

            { tab === 'importExport' && (
                <ImportExport setTab={setTab} />
            ) }
        </>
    )
}