import { toastError } from 'mui';

export const toastOnErr = <Fn extends (...args:any[]) => Promise<any>>(apiCall:Fn,	defaultErrMsg:string, pretendError?:boolean):Fn => {
	// All those any types get inferred.  By convention, we Promise resolves to undefined on error.
	const fn = async (...args:any[]) => {
		try {
			if (pretendError) throw new Error('We pretended there was an error to test error handling (toastOnErr');
			return await apiCall(...args);
		} catch (err) {
			// TODO: activity monitor should be told about any api errors.
			console.log('received error', err);
			// If a ToastError was thrown, display the ToastError message, else, the defualt msg
			const msg = err && err.toastMsg ? err.toastMsg : defaultErrMsg;
			toastError('Error', msg);
		}
	}
	return fn as Fn; // force it, sadly
}