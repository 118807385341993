import React, { useState } from 'react';
import { FlatJSONSchemaField } from 'market-dto';
import { Dropdown, Input, Checkbox } from 'mui';

interface Props {
    readonly field:FlatJSONSchemaField;
    readonly value:string|number|boolean;
    readonly setValue:(val:string|number|boolean) => void;
    readonly w100?:boolean;
    readonly updateParentAlways?:boolean; // don't use local val and tell parent on blur/enter, tell parent always!
    readonly boolLabel?:string;
}
export const FlatSchemaSimpleInput = ({ field, value, setValue, w100, boolLabel, updateParentAlways }:Props) => {

    const [ localVal, setLocalVal ] = useState(value);

    const submit = () => {
        if (updateParentAlways) return; // no need. we are telling parent always.
        setValue(localVal);
    }

    const updateLocal = (x:any) => {
        if (updateParentAlways) setValue(x);
        setLocalVal(x);
        console.log('end of update local', x);
    }


    switch (field.type) {
        case 'string': {
            if (field.enum) {
                const items = field.enum.map(x => {
                    return {
                        label: x,
                        value: x
                    }
                })
                return ( 
                    <Dropdown
                        isMulti={false}
                        items={items}
                        size="sm"
                        value={items.find(x => x.value === value)}
                        setValue={updateLocal}
                    /> 
                )
            } else {
                return <Input
                    autoSelect
                    autoFocus
                    size="sm"
                    width={w100 ?'100%':undefined}
                    value={localVal as string}
                    setValue={updateLocal}
                    onEnter={submit}
                    onBlur={submit}
                />
            }
        }
        case 'number': {
            return <Input
                autoSelect
                autoFocus
                size="sm"
                width={w100 ? '100%' : undefined}
                value={localVal as string}
                setValue={updateLocal}
                numeric={{ float: true }}
                onEnter={submit}
                onBlur={submit}
            />
        }
        case 'integer': {
            return <Input
                autoSelect
                autoFocus
                size="sm"
                width={w100 ? '100%' : undefined}
                value={localVal as string}
                setValue={updateLocal}
                numeric={{ int: true }}
                onEnter={submit}
                onBlur={submit}
            />
        }
        case 'boolean': {
            // This needs submit! TODO.
            return <Checkbox
                checked={value as boolean}
                onToggle={updateLocal}
                label={boolLabel}
            />
        }
    }
    throw new Error('Unhandled flat schema type');
    return <></>
}
