import { generateApiJwt } from "api"
import { GQL } from 'market-dto';
import { Alert, Avatar, Button, IconButton, modal, Table, TableHeader } from 'mui';
import React from 'react';
import { ApiJwt } from './api-jwt';
import { UserModal } from './user-modal';

interface Props {
	readonly users?:GQL.User[];
	readonly onChange:() => void;
}

interface Args {
	user?:GQL.User;
}

const ApiToken = (props:Args) => {
	const { user } = props
	const genJwt = async (u:GQL.User) => {
		const resp = await generateApiJwt(u.email)
		const {jwt} = resp
		modal.open({
			           render: <ApiJwt user={u} jwt={jwt}/>,
			           title: `Generate API JWT Token for ${u.email}`,
		           })
	}
	const isApiUser = (user && user.roles && user.roles.includes("api"))
	return (isApiUser) ?
	       <Button onClick={() => genJwt(user!)}>jwt</Button> : <></>
}

export const UserTable = ({ users, onChange }:Props) => {
	
	if (!users) return <Alert>No Users Found!</Alert>
	
	const editUser = (u:GQL.User) => {
		modal.open({
			           render: <UserModal user={u} onSave={onChange}/>,
			           title: 'Edit User'
		           })
	}
	
	const headers:TableHeader[] = [
		{ label: "", alignData: "left", alignHeader: "left" },
		{ label: "Email", alignData: "left", alignHeader: "left" },
		{ label: "First Name", alignData: "left", alignHeader: "left" },
		{ label: "Last Name", alignData: "left", alignHeader: "left" },
		{ label: "Roles", alignData: "left", alignHeader: "left" },
		{ label: "", alignData: "left", alignHeader: "left" },
		{ label: "", alignData: "right", alignHeader: "right" }
	];
	
	const rows = users.map((u:GQL.User) => [
		<Avatar src={u.profilePhoto ?? ''} name={u.firstName + " " + u.lastName}/>,
		u.email,
		u.firstName,
		u.lastName,
		JSON.stringify(u.roles),
		<IconButton name="edit" onClick={() => editUser(u)}/>,
		<ApiToken user={u}/>,
	]);
	
	return <Table rows={rows} headers={headers}/>
}
