import React from 'react';
import { DeriveMathSrcMap, ModelItem, ImportModel, SrcMap } from 'market-dto';

interface Props {
    readonly model:ImportModel;
    readonly field:ModelItem.AnyType;
    readonly derive:DeriveMathSrcMap;
    readonly n:number;
}
export const MathHeader = ({
    model,
    field,
    derive,
    n
}:Props) => {

    const { rightSrcId, leftSrcId, op, leftVal, rightVal, val } = derive;
    const leftField = model.getField(leftSrcId);
    const rightField = model.getField(rightSrcId);
    
    return (
        <>
            { op === "minus" && (
                <div>
                    We tried to calculate the value by subtracting "{ rightField.label }" from "{ leftField.label }"
                </div>
            ) }
            { op === "plus" && (
                <div>
                    We tried to calculate the value by adding "{ leftField.label }" to "{ rightField.label }"
                </div>
            ) }
            { val && val.trans !== undefined && (
                <div>...and it worked!</div>
            ) }
            { val && val.trans === undefined && (
                <div>...but the result wasn't good enough.</div>
            ) }
            { !val && (
                <div>...but we were unable to perform the calculation 🥺</div>
            ) }
        </>
    )
}