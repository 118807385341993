import React, { useState } from 'react';
import {
    Box,
    IconButton,
    usePageSortTable,
    SortableTableHeader,
    TablePageButtons
} from 'mui';
import { GQL, labels } from 'market-dto';

interface SellersTableProps {
    readonly filteredSellers:GQL.Org[];
    readonly onEdit:(org:GQL.Org) => void;
}
export const SellersTable = ({ filteredSellers, onEdit }:SellersTableProps) => {

    const { sortBy, asc, buttons, page, totalPages, setPage, sortedBy, sortedRows, pageSortState:ps } = usePageSortTable<GQL.Org, keyof GQL.Org>({
        rows: filteredSellers,
        keyFn: row => JSON.stringify(row), // any change to anything in row, re-render
        valFn: (row, colId) => {
            if (colId === 'aliases') return row[colId].join(', '); // for sorting, sort as if this were val
            return row[colId];
        },
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 15
        }
    })

    return (
        <Box mb="2">
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render={labels.byOrgField('name')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="aliases" render={labels.byOrgField('aliases')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="symbol" render={labels.byOrgField('symbol')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="externalId" render={labels.byOrgField('externalId')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="externalIdSource" render={labels.byOrgField('externalIdSource')} />
                        <th style={{ width:'1%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((row, n) => (
                        <tr key={row.id}>
                            <td>{ row.name }</td>
                            <td>{ row.aliases.join(', ') }</td>
                            <td>{ row.symbol }</td>
                            <td>{ row.externalId }</td>
                            <td>{ row.externalIdSource }</td>
                            <td className="right"><IconButton name="edit" size="sm" onClick={() => onEdit(row)} /></td>
                        </tr>
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </Box>
    )
}