import React, { useState } from 'react';
import { Button, Box, Dropdown, DropdownItem, FlexVCenter } from 'mui';
import { proposal } from 'services';

interface Props {
    readonly optionalFields:Array<keyof proposal.HitLoan>;
    readonly onAdd:(newField:keyof proposal.HitLoan) => void;
}
export const HitFieldAdd = ({ optionalFields, onAdd }:Props) => {

    const [ adding, setAdding ] = useState(false);
    const [ val, setVal ] = useState<keyof proposal.HitLoan|null>(null);

    const remaining:DropdownItem[] = proposal.OPTIONAL_FIELDS
        .filter(x => !optionalFields.includes(x))
        .map(x => {
            return {
                label: proposal.getHitFieldLabel(x),
                value: x
            }
        })

    const onSelect = (val:keyof proposal.HitLoan) => {
        setVal(val);
    }

    const addField = () => {
        if (val === null) return;
        onAdd(val);
        setAdding(false);
    }

    return (
        <FlexVCenter
            p="half"
            m="half"
            fontSize="sm"
            borderWidth="thin"
            borderSides="all"
            borderColor="transparent"
            bg="inner-card"
            cursor="default"
            justifyContent="center"
            
        >
            { !adding && (
                <Button
                    icon="plus"
                    type="link"
                    size="sm"
                    onClick={() => setAdding(true)}
                >New Field</Button>
            ) }
            { adding && (
                // TODO: add width to dropdown to avoid this
                <FlexVCenter width="100%">
                    <Box flex="1">
                        <Dropdown
                            items={remaining}
                            setValue={onSelect}
                        />
                    </Box>
                    <Button size="sm" ml="1" type="link" onClick={addField}>Add</Button>
                </FlexVCenter>
            ) }
        </FlexVCenter>
    )
}
