import { useSubs } from "hooks"
import { Box } from "mui"
import { IConstruct } from "raccoon-engine"
import React, { useEffect, useState } from 'react';
import { BehaviorSubject, Subject } from "rxjs"
import { SelectionEvent } from './selection-hub'
import { readableDisplay, wording } from "./types";

interface SelectorArgs
{
	construct:IConstruct,
	hub$:Subject<SelectionEvent>,
}

const ConstructSelector = (props:SelectorArgs) => {
	const { hub$, construct: { threshold: { id } } } = props
	const onSelect = () => {
		hub$.next({ kind: "CONDITION", id })
	}
	const display = readableDisplay(props.construct)
	return <div className="candidate-construct" onClick={onSelect}>
		<div className="symbol">+</div>
		<div className="label">{display}</div>
	</div>
}

interface CandidateArgs {
	candidates$:BehaviorSubject<IConstruct[]>
	hub$:Subject<SelectionEvent>
}

export const ConditionSelector = (props:CandidateArgs) => {
	const subs = useSubs()
	const { candidates$, hub$ } = props
	const [constructs, setConstructs] = useState<IConstruct[]>([])
	const [filter, setFilter] = useState("")
	useEffect(() => {
		const sub = candidates$.subscribe((c) => {
			setConstructs(c)
		})
		subs.push(sub)
	}, [candidates$, subs])
	
	useEffect(() => {
		if (filter) {
			setConstructs(candidates$.getValue().filter((c) => c.threshold.display?.toLowerCase().includes(filter)))
		} else {
			setConstructs(candidates$.getValue())
		}
	}, [candidates$, filter])
	
	const onFilterChange = (e:any) => {
		const f = e.target.value
		setFilter(f)
	}
	
	return <Box bg="inner-card" p="2">
		<Box fontWeight="bold" fontSize="sm">{wording.searchTitle}</Box>
		<Box>
			<input onChange={onFilterChange} value={filter} className="search-box"
			       placeholder={wording.search}/>
		</Box>
		{
			constructs.map((construct, idx) => <div key={idx}>
				<ConstructSelector construct={construct}
				                   hub$={hub$}/></div>)
		}
	</Box>
}
