import React, { useState } from 'react';
import { Box, modal, Button, RenderTabs, RenderMenuItem } from 'mui';
import { FieldDetails } from './field-details';
import { FieldTypes } from './field-types';
import { rule } from 'services';

type Tab = "details" | "types";

interface AddFieldModalProps {
    readonly groups:string[];
    readonly allFields:rule.RuleModelField[];
    readonly field:rule.RuleModelField;
    readonly isNew:boolean;
    readonly onSubmit:(item:rule.RuleModelField) => void;
}
export const FieldModal = ({ groups, allFields, field, isNew, onSubmit }:AddFieldModalProps) => {

    const [ tab, setTab ] = useState<Tab>('details');
    const [ editField, setEditField ] = useState(() => field);

    const fieldIdTrimmedLower = editField.fieldId.trim().toLowerCase();
    const fieldIdsTrimmedLower = allFields
        .filter(x => x.index !== field.index)
        .map(x => x.fieldId.trim().toLowerCase());
    const idExists = fieldIdsTrimmedLower.includes(fieldIdTrimmedLower);
    const canSubmit = !(idExists || fieldIdTrimmedLower === '');

    const submit = () => {
        if (!canSubmit) return;
        onSubmit(editField);
        modal.close();
    }

    const tabs:RenderMenuItem<Tab>[] = [
        {
            render: 'Field',
            value: 'details'
        },
        {
            render: 'Type',
            value: 'types'
        }
    ]

    return (
        <Box>
            <RenderTabs
                mb="2"
                onSelect={setTab}
                selectedIndex={tabs.findIndex(t => t.value===tab)}
                items={tabs}
            />

            { idExists && <Box fg="danger" mb="2">Field Id already exists in this model.</Box> }

            { tab === 'details' && (
                <FieldDetails
                    isNew={isNew}
                    onChange={setEditField}
                    groups={groups}
                    allFields={allFields}
                    field={editField}
                />
            ) }
            { tab === 'types' && (
                <FieldTypes field={editField} onChange={setEditField} />
            ) }

            <Box textAlign="right" mt="2">
                <Button
                    type={canSubmit ? 'default' : 'disabled'}
                    onClick={submit}
                >{ isNew ? 'Add Field' : 'Update' }</Button>
            </Box>


        </Box>
    )
}