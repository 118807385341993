import React from "react";
import { GQL } from "market-dto";
import { useApi } from "hooks";
import * as api from "api";
import { Loading } from "components";
import { PricingModuleBody } from "./pricing-module-body";

interface Props {
	readonly flowRun: GQL.FlowRun;
	readonly task: GQL.FlatTask;
}
export const PricingModule = ({ flowRun, task }: Props) => {
	const { taskId } = task;
	const {
		update,
		fetching: loading,
		updating,
		dirty,
		data,
		onChange,
		reset,
	} = useApi<GQL.PricingModule>({
		fetch: async () => await api.fetchPricingModule(taskId),
		update: async (x) => await api.updatePricingModule(taskId, x),
		initEditting: true,
		deps: [taskId],
	});
	return (
		<Loading
			loading={loading}
			render={
				<PricingModuleBody
					flowRun={flowRun}
					data={data!}
					saving={updating}
					dirty={dirty}
					onSave={update}
					onChange={onChange}
					onReset={reset}
				/>
			}
		/>
	);
};
