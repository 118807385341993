const prefix = 'MARKET_';

// This should only do one thing: local storage (or, session storage)

export const set = (key:string, val:any) => {
    localStorage[prefix + key] = JSON.stringify(val);
}

export const get = <T>(key:string):T | undefined => {
    try {
        const result:T = JSON.parse(localStorage[prefix + key]);
        return result;
    } catch (err) {
        return undefined;
    }
}

export const clear = (key:string) => {
    localStorage.removeItem(prefix + key);
}