import { GQL } from "market-dto";
import { createZState } from '../z-state';
import * as api from 'api';
import { toHitInfo } from "../proposal";
import { toPricingInfo } from '../pricing';
import { actLog } from '../activity-monitor';
import { BigSheet } from './types';
import { canShowProposalTab } from './can-show-proposal-tab';
import { canShowTradeTab } from './can-show-trade-tab';
import { autoCheckAllBestOffers } from './auto-check-all-best-offers';

const offersChanged = (prev:GQL.LoanModelSheet|undefined, curr:GQL.LoanModelSheet):boolean => {
    if (!prev) return true;
    const idStr1 = prev.offers.map(x => x.id).join(':');
    const idStr2 = curr.offers.map(x => x.id).join(':');
    return idStr1 !== idStr2;
}


export const zCurrent = createZState({
    zid: 'current-sheet',
    apiFetch: async (sheetId:string):Promise<BigSheet|undefined> => {

        const fetchPropDetails = api.toastOnErr(api.fetchProposalDetails, "Could not fetch proposal details!");

        const sheet = await api.fetchLoanModelSheet(sheetId);
        if (!sheet) return undefined;
        // sheet.sheetSummary.sheetStatus
        const pricingInfo = toPricingInfo(sheet);
        const propDetails = canShowProposalTab(sheet) ? await fetchPropDetails(sheetId) : undefined;
        const hitInfo = propDetails ? toHitInfo(propDetails) : undefined;
        const trade = canShowTradeTab(sheet) ? await api.fetchTradeBySheetId(sheetId) : undefined;

        const bigSheet:BigSheet = {
            sheet,
            pricingInfo,
            hitInfo,
            trade
        }
        return bigSheet;
    },
    onFetch: (prev:BigSheet|undefined, curr:BigSheet|undefined) => {
        if (!curr) return;
        const tapeIdChanged = !prev || !prev.sheet || prev.sheet.tapeId !== curr.sheet.tapeId;
        if (tapeIdChanged) {
            actLog("TAPE_LOADED", "INFO", curr.sheet.tapeId, "Sheet loaded");
        }
        // We need to know if offers have changed, and, we cannot rely on amount of offers!
        if (tapeIdChanged || offersChanged(prev!.sheet, curr.sheet)) {
            // any time total amount of offers changes...
            autoCheckAllBestOffers(curr.sheet);
        }
    }
})
