import { UserInputState, UserDecision2 } from '../types';

export const getUserDecision = (userInput:UserInputState):UserDecision2 => {

    const { selectedMatch, confirmedAggregatorName, confirmedSeller } = userInput;
    if (!confirmedSeller) return "INVALID_CFG";

    if (!selectedMatch) {
        // Must be new template
        return confirmedAggregatorName ? "NEW_AGG" : "NEW_NON_AGG";
    }

    if (selectedMatch.type === "non-agg") {
        const { sellerOrg } = selectedMatch;
        return sellerOrg !== confirmedSeller ? "CLONE_NON_AGG" : "NON_AGG";
    }

    const { aggregatorName } = selectedMatch.template;
    // confirmedAggregatorName not set!
    console.log('AGG NAMES', aggregatorName, confirmedAggregatorName);
    return aggregatorName !== confirmedAggregatorName ? "CLONE_AGG" : "AGG";
}