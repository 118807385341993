import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// I cannot explain it yet. But when i put this in DTO to share it with web, typescript complains.

const inputIsNotNullOrUndefined = <T>(input: null | undefined | T): input is T => {
    return input !== null && input !== undefined;
}
export const isNotNullOrUndefined = <T>() => {
    return (source$: Observable<null | undefined | T>) => source$.pipe(filter(inputIsNotNullOrUndefined));
}
