import React from 'react';
import { Box, Icon, Flex, FlexVCenter } from 'mui';
import { GQL, LoanStats, labels, format } from 'market-dto';
import { GenericSummaryBar, GenericSummaryGroup } from '../generic-summary-bar/generic-summary-bar'
import { useNavigate } from 'react-router-dom';


const getEligibleCount = (stats:LoanStats):number => {
	if (!stats.eligibility) return 0;
	return stats.eligibility['Eligible'] ?? 0;
}

interface Props {
	readonly summary:GQL.SheetSummary;
}
export const HorizontalTapeSummary = ({ summary }:Props) => {

	const navigate = useNavigate();
	const close = () => navigate('/tapes');
	
	const filename = format.shortenFileName(summary.fileName);
	const tapeSum = format.toCurrencyWithoutCents(summary.loanStats.loanAmountSum);
	const productCounts = summary.loanStats.product ?
		Object.entries(summary.loanStats.product).map(arr => {
			return {
				product: arr[0],
				count: arr[1]
			}
		}) : [];
	productCounts.sort((a,b) => a.count - b.count);
	const eligibleCount = getEligibleCount(summary.loanStats);

	const groups:GenericSummaryGroup[] = [
		{ items: [{ top: summary.sheetId, bottom: labels.byLoanField('sheetId') }] },
		{ items: [{ top: filename, bottom: summary.sellerName }] },
		{ items: [{ top: tapeSum, bottom: "Total Tape Balance" }] },
		...productCounts.map((p):GenericSummaryGroup => {
			return {
				items: [{ top: p.count, bottom: p.product }]
			}
		}),
		{ items: [{ top: summary.loanStats.loanCount, bottom: format.plural("Loan", summary.loanStats.loanCount) }] },
		{ items: [{ top: eligibleCount, bottom: "Eligible" }] }
	]

	return <>
		<GenericSummaryBar
			groups={groups}
			renderBtnArea={
				<Icon name="close" fg="faded" fgHover="link" onClick={close} cursor="pointer" mr="1" />
			}
		/>
	</>
}

