import { createZStateList } from '../z-state';
import * as api from 'api';
import { createEmptyChecklist } from './create-empty-checklist';

export const zChecklists = createZStateList({
    // apiFetchList: api.fetchAllChecklists, // OLD: load everything all at once.
    apiFetchDehydratedList: api.fetchAllDehydratedChecklists,
    apiHydrateItem: x => api.fetchChecklist(x.id),
    apiUpdateListItem: api.updateChecklist,
    apiInsertListItem: api.insertChecklist,
    isEqual: (a,b) => a.id === b.id,
    createNewListItem: createEmptyChecklist,
    forceCommitsWhenNoChange: true // when user hits "save" on a non-dirty item, go to server? yes.
})
