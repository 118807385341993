import React, { useState, useEffect } from 'react';
import { Box, FlexVCenter, Button, Icon, DotsLabel, Alert } from 'mui';
import { GQL, getModelByModelType } from 'market-dto';
import { XlsExportViewer } from '../xls-viewer';
import { useNavigate, Navigate } from 'react-router-dom';
import { alpha } from 'services';

interface Props {
    readonly isReRun?:boolean;
    readonly flow:GQL.Flow;
    readonly isNewFlow:boolean;
    readonly runState:alpha.RunState;
}
export const FlowRunStatus = ({ isReRun, runState, flow, isNewFlow }:Props) => {

    const nav = useNavigate();
    const model = getModelByModelType(flow.modelType);
    
    const { status, flowRun, xlsExport} = runState;

    console.log('STATUS', status, "IS NEW", isNewFlow);

    if (status === "DONE") {
        if (!flowRun) return <Alert icon="alert-triangle">Error: Expected Flow Run</Alert>
        if (isNewFlow) return <Navigate to="edit/tasks/1" />
        if (xlsExport) {
            return <XlsExportViewer
                model={model}
                readOnly={true}
                onToggleReadonly={() => nav('edit/tasks/1')}
                flowRun={flowRun}
                data={xlsExport}
                saving={false}
                dirty={false}
                onSave={() => {}}
                onChange={() => {}}
                onReset={() => {}}
            />
        }
        return <Box>TODO - what do we do with a flow run without an xls export?</Box>
    }

    // from user perspective, these are the same
    const running = status === "RUNNING" || status === "FETCH_RESULTS" || status === "START_RUN";

    return (
        <Box
            mx="auto"
            width="card-sm"
            roundedEdges="all"
            p="2"
            borderColor="alert"
            borderSides="all"
            borderWidth="thick"
        >

            <FlexVCenter mb="1">
                <Icon name="check" fg="success" mr="1"/>
                <Box fg="loud">
                    { isReRun ? 'Re-running Tape' : 'Tape Imported' }
                </Box>
            </FlexVCenter>
        
            { running && (
                <FlexVCenter mt="1">
                    <Icon name="loader" fg="default" mr="1" spinning />
                    <Box fg="default">
                        <DotsLabel>{ isNewFlow ? 'Initializing' : 'Processing' }&nbsp;Workflow</DotsLabel>
                    </Box>
                </FlexVCenter>
            ) }

            { !running && (
                <>
                    <FlexVCenter mt="1">
                        <Icon name="check" fg="success" mr="1"/>
                        <Box fg="loud">Workflow&nbsp;{ isNewFlow ? 'Initialized' : 'Processed' }</Box>
                    </FlexVCenter>

                    {/* { isNewFlow && (
                        <Box textAlign="center" mt="1">
                            <Button onClick={() => nav('stages')} type="default">Continue</Button>
                        </Box>
                    ) } */}

                    <FlexVCenter mt="1">
                        { status === "FETCH_XLS_CFG" && (
                            <>
                                <Icon name="loader" fg="default" mr="1" spinning />
                                <Box fg="default">
                                    <DotsLabel>Generating Output</DotsLabel>
                                </Box>
                            </>
                        ) }
                    </FlexVCenter>

                    {/* 
                    { !isNewFlow && (
                        <FlexVCenter mt="1">
                            { status === "FETCH_XLS_CFG" && (
                                <>
                                    <Icon name="loader" fg="default" mr="1" spinning />
                                    <Box fg="default">
                                        <DotsLabel>Generating Output</DotsLabel>
                                    </Box>
                                </>
                            ) }
                        </FlexVCenter>
                    ) } */}
                </>
            ) }
        </Box>
    )
}