import React from 'react';
import { useState, useEffect } from 'react';
import { Input, Box, Button, FlexVCenter, Link, Text, Icon, Dropdown, DropdownItem, LabelValueGrid, Flex } from 'mui';

interface Props {
    readonly aggName:string;
    // readonly fromMatch:boolean; // Did this come from a template match?
    readonly setAggName:(x:string) => void;
    readonly cancel:() => void;
}
export const AggIdentifyAgg = ({
    aggName,
    // fromMatch,
    setAggName,
    cancel
}:Props) => {

    const [ agg, setAgg ] = useState<string>(aggName);

    const ok = () => {
        if (agg.trim()) setAggName(agg.trim());
    }

    // <Flex flexDirection="column" alignItems="center">
    return (
        <Box>
            <Box>Please identify the aggregator below.</Box>
            <FlexVCenter my="half">
                <Input
                    autoFocus
                    size="md"
                    width="frm-md"
                    value={agg}
                    setValue={setAgg}
                    onEnter={ok}
                />
                <Button
                    ml="1"
                    size="sm"
                    onClick={cancel}
                    type="secondary"
                >Cancel</Button>
                <Button
                    ml="1"
                    size="sm"
                    onClick={ok}
                    type={agg.trim() ? 'default' : 'disabled' }
                >Ok</Button>
            </FlexVCenter>
        </Box>
    )
}