import React, { useState } from 'react';
import { Box, Text, Dropdown, DropdownItem, FlexVCenter, Button, Input, modal } from 'mui';
import { GQL } from 'market-dto';
import { utils } from 'services';
import { SrcColCfgAndEntry } from './types';

interface Props {
    readonly colCfgs:GQL.XlsExportCol[];
    readonly src:SrcColCfgAndEntry;
    readonly param:GQL.XlsExportCalcEnumColParam;
    readonly label:string;
    readonly onColCfgsChange:(arr:GQL.XlsExportCol[]) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
}
export const EnumConfirm = ({
    colCfgs,
    src,
    param,
    label,
    onColCfgsChange,
    selectedColId,
    setSelectedColId
}:Props) => {

    const go = () => {
        const maxId = utils.getMax(colCfgs, x => x.colId)!.colId;
        const nextId = maxId + 1;
        const cfg:GQL.XlsExportCalcEnumCol = {
            exclude: false,
            isCalc: true,
            filterVals: [],
            headerLabel: label,
            colId: nextId,
            source: src.cfg.fieldId,
            type: "CALC_ENUM",
            param
        }
        const index = colCfgs.findIndex(x => x.colId === selectedColId);
        if (index === -1) {
            onColCfgsChange(colCfgs.concat(cfg));
        } else {
            onColCfgsChange(utils.insertItemAtIndex(colCfgs, index+1, cfg));
        }
        setSelectedColId(nextId);
        modal.close();
    }

    return (
        <>
            <Box mt="1" pt="1" borderColor="alert" borderSides="top" borderWidth="thin" textAlign="center">
                <Button
                    onClick={go}
                    type={label.trim() ? 'default' : 'disabled'}
                >Confirm &amp; Create</Button>
            </Box>
        </>

    )
}