import React, { useState, useEffect } from 'react';
import { ExpStrToHtml, ExpContext, ExpEvalStatus, getEvalStatusFromString } from 'exp';
import { FlexVCenter, Box } from 'mui';
import { EditorPassFailCols } from './editor-pass-fail-cols';

interface Props {
    readonly expCtx:ExpContext;
    readonly expStr:string;
    readonly runData:any;
    readonly innerLabel?:string;
}
export const ChecklistTableExp = ({ expCtx, expStr, runData, innerLabel }:Props) => {

    const [ evalStatus, setEvalStatus ] = useState<ExpEvalStatus>();

    useEffect(() => {
        setEvalStatus(getEvalStatusFromString(expStr, expCtx, runData));
    }, [expStr, runData])

    return (
        <FlexVCenter justifyContent="space-between" width="100%">
            <FlexVCenter flex="1">
                <ExpStrToHtml exp={expStr} expCtx={expCtx} small allowWrap />
                { innerLabel && <Box fg="faded" fontSize="sm" ml="1">{ innerLabel }</Box> }
            </FlexVCenter>
            { evalStatus && <EditorPassFailCols mx="half" evalStatus={evalStatus} small /> }
        </FlexVCenter>
    )
}
