import { isBefore, getDay, eachDayOfInterval, endOfMonth } from 'date-fns'
import { DaysOfWeek, NthOfMonth } from './types';

export const getMatchingDatesInMonth = (today:Date, daysOfWeek:DaysOfWeek, nthOfMonth:NthOfMonth, firstOfMonth:Date):Date[] => {

    // For this given month, return the dates that meet the day-of-week and nth-of-month criteria
    // There is probably a more clear way of doing this.
    
    let daysFoundSoFar:number[] = [0,0,0,0,0,0,0]; // sun-sat counters
    const result:Date[] = [];
    eachDayOfInterval({ start: firstOfMonth, end: endOfMonth(firstOfMonth) }).forEach(d => {
        const dayOfWeek = getDay(d);
        const checkThisDay = (
            (dayOfWeek === 1 && daysOfWeek.mon) ||
            (dayOfWeek === 2 && daysOfWeek.tue) ||
            (dayOfWeek === 3 && daysOfWeek.wed) ||
            (dayOfWeek === 4 && daysOfWeek.thu) ||
            (dayOfWeek === 5 && daysOfWeek.fri)
        )
        if (checkThisDay) {
            // only increment the days of the week we care about
            daysFoundSoFar = daysFoundSoFar.map((v,n) => n !== dayOfWeek ? v : v+1);
            // nth will be 1 for first one, 2 for second, etc
            const nth = daysFoundSoFar[dayOfWeek];
            const includeMe = (
                (nthOfMonth.first && nth === 1) ||  
                (nthOfMonth.second && nth === 2) ||  
                (nthOfMonth.third && nth === 3) ||  
                (nthOfMonth.fourth && nth === 4)
            )
            if (includeMe && isBefore(today, d)) result.push(d);
        }
    })
    return result;
}