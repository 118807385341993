import React from 'react';
import { GQL, DirectSrcMap, DeriveSrcMap, labels } from 'market-dto';
import { viewLoans } from 'services';
import { Box, Text, FlexVCenter, Icon } from 'mui';

interface DirectMapInfoProps {
    readonly direct:DirectSrcMap;
}
const DirectMapInfo = ({ direct }:DirectMapInfoProps) => {
    // if (direct.cols.length < 1) throw new Error('Exepcted at least one');
    const success = direct.hasOwnProperty('val');
    const colName = direct.cols.length > 0 && direct.cols[0].name;
    
    return <>
        <Box fg="default" fontSize="xs">&laquo;&nbsp;<Text fg="faded" fontSize="xs">{ colName }</Text>&nbsp;&raquo;</Box>
        { success && <FlexVCenter fg="loud" fontSize="xs">
            { direct.val?.raw }
            {/* <Icon name="arrow-left" /> */}
        </FlexVCenter> }
        { !success && <Box fg="default" fontSize="xs">{ direct.val?.raw }</Box> }
    </>
}

interface DerivedMapInfoProps {
    readonly derive:DeriveSrcMap[];
}
const DerivedMapInfo = ({ derive }:DerivedMapInfoProps) => {
    return (
        <Box fontSize="xs">
            { derive.map((s, n) => {
                // This is not yet setup for more than one val/propName (where we have derived on multiple fields)
                if (s.deriveType !== 'simple') return <></>; // TODO!

                const val = s.val?.trans;
                const propName = s.srcId;
                const isSelf = false; // used to be possible
                const success = s.val ? true : false;

                // const val = s.val && s.val.length > 0 ? s.val[0] : null;
                // const propName = s.propName && s.propName.length > 0 ? s.propName[0] : null;
                // const isSelf = propName === derived.id; // no longer possible
                // const success = val && n === derived.source.length-1;
                return <React.Fragment key={n}>
                    { !isSelf && (
                        <>
                            <Box fg="faded">{ labels.byLoanField(propName as any) }</Box>
                            { success && <FlexVCenter fg="loud">
                                { val }
                                {/* &nbsp;<Icon fg="loud" name="arrow-left" /> */}
                            </FlexVCenter> }
                            { !success && <Box fg="default" textStyle="italic">Not used</Box> }
                        </>
                    )}
                    
                </React.Fragment>
            })}
        </Box>
    )
}

interface DirectAndDerivedProps {
    readonly direct:DirectSrcMap;
    readonly derive:DeriveSrcMap[];
}
const DirectAndDerived = ({ direct, derive }:DirectAndDerivedProps) => {
    if (direct.hasOwnProperty('val')) {
        // Direct map SUCCEEDED.
        // Fallback instructions exist, but, were unneeded.
        // Show derived first, because they failed.
        return <>
            <DerivedMapInfo derive={derive} />
            <DirectMapInfo direct={direct} />
        </>
    }
    // We tried to map directly, and succeeded in finding a column.
    // BUT...the value within that column for this particular loan was unsuitable.
    return <>
        <DirectMapInfo direct={direct} />
        <DerivedMapInfo derive={derive} />
    </>
}


interface UnmappedProps {
    readonly reason?:string;
}
const Unmapped = ({ reason }:UnmappedProps) => {
    // the reason is for debugging only.
    return <td style={{ verticalAlign:'top' }}>
        <Text fontSize="xs" fg="really-faded">No source</Text>
    </td>;
}

interface Props {
    readonly row:viewLoans.LoanAndValuesAndSources;
    readonly field:viewLoans.LoanTableFieldConfig;
}
export const LoanTableCol = ({ row, field }:Props) => {
    const d = row.mapInfoByField[field.id];
    if (field.noMap) return <td>&nbsp;</td>;
    if (!d) return <Unmapped reason="missing loan field" />;
    if (d.val === undefined) return <Unmapped reason="mapping empty" />;
    if (!d.direct && !d.derive) return <Unmapped reason="neither direct nor derived" />
    return (
        <td style={{ verticalAlign:'top' }}>
            { d.direct && !d.derive && <DirectMapInfo direct={d.direct} /> }
            { !d.direct && d.derive && <DerivedMapInfo derive={d.derive} /> }
            { d.direct && d.derive && <DirectAndDerived direct={d.direct} derive={d.derive} /> }
        </td>
    )
}
