import { GQL, PriceTag } from 'market-dto';
import { FN_NAME } from './org-ids';
import { DebugCompareData, OrgCompareData, CompareDetail } from './types';
import{ debugColumnsHeaders } from './debug-column-headers';

const getCompareDetailsFromTag = (labelField:keyof PriceTag, tag?:PriceTag):CompareDetail[] => {
    return tag ? tag.tags.filter(x => x.price).map((x):CompareDetail => {
        return {
            label: x[labelField],
            value: x.price
        }
    }) : [];
}

const getFannieCompareData = (loan:GQL.Loan):OrgCompareData|undefined => {
    const offer = loan.offers?.find(x => x.investor === 'Fannie');
    if (!offer) return;
    const ptag = offer.priceTag; // Root price tag
    if (!ptag) return;
    const cw = ptag.tags.find(x => x.type === 'CASH_WINDOW');
    const interest = ptag.tags.find(x => x.type === 'INTEREST_SPREAD');
    const llpa = ptag.tags.find(x => x.type === 'LLPA');
    const srp = ptag.tags.find(x => x.type === 'SRP');
    return {
        price: ptag.price,
        cashWindowPrice: cw?.price,
        cashWindowDetails: getCompareDetailsFromTag("name", cw),
        spreadPrice: interest?.price,
        spreadPriceDetails: getCompareDetailsFromTag("name", interest),
        llpaPrice: llpa?.price,
        llpaDetails: getCompareDetailsFromTag("name", llpa),
        srpPrice: srp?.price,
        srpDetails: getCompareDetailsFromTag("name", srp)
    }    
}

const debugFieldsToCompareDetails = (tag:PriceTag):CompareDetail[] => {
    const obj:any = tag.parameters || {};
    return debugColumnsHeaders.map((header):CompareDetail => {
        const val = obj[header];
        return {
            label: header,
            value: val ? val : ''
        }
    }).filter(x => x.value*1 !== 0)
}


const getFnmaXCompareData = (loan:GQL.Loan):OrgCompareData | undefined => {
    const offer = loan.offers?.find(x => x.investor === FN_NAME);
    if (!offer) return;
    const ptag = offer.priceTag; // Root price tag
    if (!ptag) return;
    const cw = ptag.tags.find(x => x.type === 'CASH_WINDOW');
    const interest = ptag.tags.find(x => x.type === 'INTEREST_SPREAD');
    const llpa = ptag.tags.find(x => x.type === 'LLPA');
    const srp = ptag.tags.find(x => x.type === 'SRP');
    return {
        price: ptag.price,
        cashWindowPrice: cw?.price,
        cashWindowDetails: [...getCompareDetailsFromTag("name", cw), ...debugFieldsToCompareDetails(ptag)],
        spreadPrice: interest?.price,
        spreadPriceDetails: getCompareDetailsFromTag("name", interest),
        llpaPrice: llpa?.price,
        llpaDetails: getCompareDetailsFromTag("name", llpa),
        srpPrice: srp?.price,
        srpDetails: getCompareDetailsFromTag("name", srp)
    }      
}

export const getCompareData = (loan:GQL.Loan):DebugCompareData|undefined => {
    const mtrade = getFannieCompareData(loan);
    const aig = getFnmaXCompareData(loan);
    if (!mtrade || !aig) return undefined;
    return {
        mtrade,
        aig
    }
}