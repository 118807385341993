import React, { useState, useEffect } from 'react';
import { SpinLabel, Tabs, BasicMenuItem, Box } from 'mui';
import { useInvestorMargins } from './useInvestorMargins';
import { ConfigHeader } from '../components/config-header';
import { EditableRuleTable } from '../components/param-table.service';
import { ParamTable } from '../components/param-table';
import { InvestorAndMargins } from 'api';
import { InvestorMarginsDoc } from 'market-dto';

const createParamTable = (inv:InvestorAndMargins):EditableRuleTable => {
    return {
        cols: [
            {
                field: "",
                label: "Investor",
                type: "const"
            },
            {
                field: "",
                label: "Product",
                type: "const"
            },
            {
                field: "",
                label: "Margin",
                type: "float"
            },
        ],
        rows: Object.keys(inv.margins).map(m => {
            const val = (inv.margins as any)[m];
            return {
                deleted:false,
                editting:false,
                vals: [ inv.name, m, val ]
            }
        })
    } 
}
const createParamTables = (investors:InvestorAndMargins[]):EditableRuleTable[] => {
    return investors.map(inv => createParamTable(inv));
}

const paramTablesToInvestorMarginDoc = (tables:Array<EditableRuleTable>, investors:Array<InvestorAndMargins>):InvestorMarginsDoc => {
    // whole lotta mutability...fine for now...
    const doc:InvestorMarginsDoc = {};
    investors.forEach((x, n) => {
        const t = tables[n];
        const dict:any = {};
        t.rows.forEach(x => {
            const key = x.vals[1] as string;
            const val = x.vals[2];
            dict[key] = val;
        });
        doc[x.id] = dict;
    });
    return doc;
}

export const InvestorMargins = () => {

    const { loading, investors, update, saving } = useInvestorMargins();
    const [ tab, setTab ] = useState<number>(0);
    const [ tables, setTables ] = useState<Array<EditableRuleTable>>();
    const [ dirty, setDirty ] = useState<boolean>(false);

    const reset = () => {
        if (!investors) return;
        setTables(createParamTables(investors));
        setDirty(false);
    }

    useEffect(reset, [investors]);

    if (loading || !tables || !investors) return <SpinLabel>Loading...</SpinLabel>;

    const opts:Array<BasicMenuItem> = investors.map((x, n) => {
        return {
            label: x.name,
            value: n
        }
    })

    const onChange = (t:EditableRuleTable) => {
        setTables(tables.map((x, n) => n === tab ? t : x));
        setDirty(true);
    }

    const save = () => {
        const doc = paramTablesToInvestorMarginDoc(tables, investors);
        update(doc);
    }

    return (
        <Box>
            <ConfigHeader
                cancel={reset}
                dirty={dirty}
                save={save}
                saving={saving}
                title="Investor Margins"
            />
            <Tabs my="2" items={opts} onSelect={setTab} selected={tab} />
            <ParamTable table={tables[tab]} onChange={onChange} allowDelete={false} />
        </Box>
    )
}