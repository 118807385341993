import { ChecklistWithModel } from './types';
import { zChecklists } from './z-checklists';
import { getModelById } from './get-model-by-id';

export const getChecklistWithModelById = (id?:string):ChecklistWithModel|undefined => {
    const checklist = zChecklists.get()?.find(x => x.id === id);
    if (!checklist) return undefined;
    return {
        checklist,
        model: getModelById(checklist.modelId)
    }
}
