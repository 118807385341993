import { renewJwt } from 'api';
import { getJwt } from './get-jwt';
import { logout } from './logout';
import { updateJwt } from './update-jwt';
import { isWellFormedJwt } from './is-well-formed-jwt';

export const checkIfJwtIsStillValid = async (failOnPurpose?:boolean):Promise<boolean> => {

    const currJwt = getJwt();
    if (!isWellFormedJwt(currJwt ?? '')) return false; // no need to even ask!

    const result = await renewJwt({ failOnPurpose });
    if (!result) {
        logout();
        return false;
    }
    // We check if the jwt is still valid by creating a new jwt.
    updateJwt(result.jwt);
    return true;
}