import React, { useState } from 'react';
import { IntoPortal } from 'mui';
import { PopOverCfg } from './types';
import { PopOverInner } from './pop-over-inner';
import { useObs } from 'hooks';
import { BehaviorSubject } from 'rxjs';
import { usePopper } from 'react-popper';

const _popOverCfg = new BehaviorSubject<PopOverCfg|null>(null);
export const popOverCfg$ = _popOverCfg.asObservable();
export const openPopOver = (cfg:PopOverCfg) => _popOverCfg.next(cfg);
export const closePopOver = () => _popOverCfg.next(null);

export const PopOverPlaceholder = () => {

    const [ popperElement, setPopperElement ] = useState<HTMLDivElement|null>(null);
    const popOverCfg = useObs(popOverCfg$);

    const { styles, attributes } = usePopper(popOverCfg?.elem, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 0]
                }
            }
        ],
        strategy: 'absolute'
    })

    return (
        <div>
            { popOverCfg && (
                <div
                    className="m-xls-pop-container"
                    onClick={() => closePopOver()}
                >
                    <div
                        onClick={e => e.stopPropagation()}
                        style={{ position:'relative', zIndex: 999999 }}
                    >
                        <div
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                        ><PopOverInner onClose={closePopOver}>{ popOverCfg.render }</PopOverInner></div>
                    </div>
                </div>
            ) }
        </div>
    )
}