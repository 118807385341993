import { AddJobProps, ActivityMonitorJob } from '../types';
import { _jobs, _selectedJobId } from './states';

export const addJob = ({
    jobId,
    jobName,
    label,
    checkStatus,
    timeoutSeconds,
    onComplete,
    serverBatchId,
    selectAfterCreation
}:AddJobProps):string => {
    const dict = _jobs.getValue();
    const newJob:ActivityMonitorJob = {
        jobId,
        jobName,
        label,
        checkStatus,
        onComplete,
        latestPollResult: null,
        active:true,
        status: "BUSY",
        serverBatchId,
        startedAt: Date.now(),
        pollUntil: Date.now() + (timeoutSeconds *  1000)
    }
    _jobs.next({ ...dict, [jobId]:newJob });
    if (selectAfterCreation) _selectedJobId.next(jobId);
    return jobId;
}