import React, { useState } from 'react';
import { Flex, Box, Dropdown, DropdownItem, Text } from 'mui';
import { profit, format, color } from 'services';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import { MultiLineChartHover } from './multi-line-chart-hover';

// '#003f5c'
// '#2f4b7c'
// '#665191'
// '#a05195'
// '#d45087'
// '#f95d6a'
// '#ff7c43'
// '#57ff51'

const c = [
    '#65fb7e',
    '#da9827',
    '#00b2b8',
    '#2a00b8',
    '#b80068'
];

const toMmDd = (x:string) => format.dateToMonthAndDay(format.yyyymmddToDate(x));

const colors = c.map(x => {
    return {
        stroke: x,
        fill: color.shadeColor(x, 60)
    }
})


const getColor = (n:number) => colors[n % colors.length];

interface Props {
    readonly items:profit.LoanAndPriceSheet[];
}
export const MultiLineChart = ({ items }:Props) => {

    const [ data ] = useState(() => profit.toLineChartData(items));

    const [ acqDates, setAcqDates ] = useState<string[]>(() => {
        if (data.defaultAcquisitionDate) {
            return [
                data.keys[0],
                data.defaultAcquisitionDate
            ]
        }
        return [];
        // console.log('does init use state have value from above?', data.maxValAcqDate);
        // return [data.maxValAcqDate];
    })

    const opts = data.keys.map((x):DropdownItem => {
        return {
            label: format.dateToMonthAndDay(format.yyyymmddToDate(x)),
            value: x
        }
    })

    const showMe = data.keys.filter(k => acqDates.includes(k));

    return (
        <>

        <Flex justifyContent="center" flexDirection="column" alignItems="center">
            { data.defaultAcquisitionDate && (
                <Box my="2" style={{ width:'740px' }} textAlign="center" fg="faded">
                    The Fannie offer acquisition date is <Text fg="default">{ toMmDd(data.defaultAcquisitionDate) }</Text>.<br/>
                    The earliest acquisition date is <Text  fg="default">{ toMmDd(data.keys[0]) }</Text>.                    
                </Box>
            ) }
            {/* <textarea readOnly value={JSON.stringify(acqDates, null, 4)}></textarea> */}
            <Box my="2" width="100%" style={{ width:'740px' }} pl="2">
                <Dropdown
                    placeholder="Select Acquisition Date(s)"
                    width="100%"
                    size="sm"
                    isMulti={true}
                    items={opts}
                    vals={acqDates}
                    setValue={setAcqDates}
                />
            </Box>
            <LineChart
                width={930}
                height={400}
                data={data.objArr}
                margin={{ top: 12, right: 50, left: 20, bottom: 0 }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke="#113344" />
                <XAxis
                    dataKey="deliveryDate"
                    type="category"
                    tick={props => <CustomXAxisTick {...props} objArr={data.objArr}/>}
                />
                <YAxis domain={[0, data.maxVal]} type="number" />
                <Legend
                    verticalAlign="top"
                    height={30}
                    formatter={x => toMmDd(x)}
                />
                <Tooltip content={(props:any) => <MultiLineChartHover {...props} />} />
                { showMe.map((yyyymmdd, n) => (
                    <Line
                        isAnimationActive={false}
                        key={yyyymmdd}
                        type="linear"
                        dataKey={yyyymmdd}
                        stroke={getColor(n).stroke}
                        fill={getColor(n).fill}
                    />
                )) }
            </LineChart>
        </Flex>
        </>
    )
}

const CustomXAxisTick = ({ x, y, payload, objArr }:any) => {
    const dt = objArr[payload.index].deliveryDate;
    const val = format.dateToMonthAndDay(dt);
    return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={8} // was 16
            fontSize="0.65em"
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)">{val}</text>
        </g>
    )
}