import React from 'react';
import { Box } from '../box/box';

interface Props {
    readonly children:React.ReactNode;
}
export const MainSection = ({ children }:Props) => {
    return (
        <Box mx="auto" width="main-section" pt="top-nav">
            { children }
        </Box>
    )
}