import { GQL, gql, mutate, stripInternals } from "../../../client";
import { PRICING_MODULE_RETURNING } from './returning';

const UPDATE = gql`
    mutation updatePricingModule($set: pricingModule_update_input!, $where: where_pricingModule_exp) {
        update_pricingModule(set: $set, where: $where) {
            affectedRow
            errorMessage
            returning { ${ PRICING_MODULE_RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.PricingModule>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_pricingModule: {
        readonly returning:[GQL.PricingModule];
    }
}

export const updatePricingModule = async (id:string, data:Partial<GQL.PricingModule>):Promise<GQL.PricingModule|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(data),
        where: {
            id: {
                _eq: id
            }
        }
    })
    return result?.data?.update_pricingModule.returning[0];
}
