import { tapeImporter } from 'services';
import { ExpandedState, ExpandedStep } from './types';
import { setSelected } from './set-selected';
import { setOrideDependents } from './set-oride-dependents';

const getInitStep = (item:tapeImporter.BatchItem):ExpandedStep => {
    // if (item.skipped) return "skipped";
    return item.matchResults!.length === 0 ? "ask-agg" : "match-options"
}

export const getInitExpandedState = (item:tapeImporter.BatchItem):ExpandedState => {

    const { matchResults, rowsAndHeaders } = item;
    if (!matchResults) throw new Error('expected matchResults');
    if (!rowsAndHeaders) throw new Error('Expected rowsAndHeaders');

    const orides = {};
    const orideDeps = setOrideDependents(item, {}, orides);

    // No difference any more. We no longer auto-select top one.
    return {
        item,
        step: getInitStep(item),
        initOrides: {},
        ...orideDeps
    }
}