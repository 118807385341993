import { mutate, query, gql, GQL } from "../../../client";

const MUTATE = gql`
    mutation runFlow($flowId: String, $sheetId: String) {
        sync_run_flow(flowId: $flowId, sheetId: $sheetId)
    }
`;

interface ExecFlowOutput {
    readonly sync_run_flow:{
        readonly batchId:string;
        readonly run:GQL.FlowRun;
    }
}

interface ExecFlowInput {
    readonly flowId:string;
    readonly sheetId:string;
}

export const execFlowWithSheetId = async (flowId:string, sheetId:string):Promise<string|undefined> => {
    const result = await mutate<ExecFlowInput, ExecFlowOutput>(MUTATE, { flowId, sheetId });
    console.log('this is what we got back from exec flow with sheet Id', result);
    return result?.data?.sync_run_flow.batchId;
}
