import React from 'react'
import { Box, FlexVCenter, PortalPlaceholder, NavTabs, NavMenuItem, Button } from 'mui';
import { Pricing } from './pricing/pricing';
import { Advanced } from './advanced/advanced';
import { Loans } from './loans/loans';
import { Route, Navigate, Routes } from 'react-router-dom'; // useRouteMatch
import { Mapping } from './mapping';
import { DebugMsg } from 'components';
import { sheets, urls } from 'services';
import { Commitment } from './commitment/commitment';
import { Confirmation } from './confirmation/confirmation';
import { Trade } from './trade/trade';
import { openDownloadModal } from 'components';
import { Error as TapeError } from './error/error';
import { HitInfo } from 'services/proposal';
import { Charts } from './charts/charts';

const getDefaultTab = (showProposalTab:boolean, showTradeTab:boolean) => {
	if (showTradeTab) return 'trade';
	return showProposalTab ? 'confirmation' : 'pricing';
}

const getConfirmationLabel = (hitInfo?:HitInfo) => {
	if (!hitInfo) return '';
	return hitInfo.hitCount === 0 ? 'Proposal' : 'Confirmation';
}

interface Props {
    readonly details:sheets.BigSheet;
}
export const TapeDetailsRoutes = ({ details }:Props) => {

	const { sheet, hitInfo, trade, pricingInfo } = details;
	const showTradeTab = sheets.canShowTradeTab(sheet);
	const showProposalTab = sheets.canShowProposalTab(sheet);

    const hitsExist = hitInfo && hitInfo.hitCount > 0;
    const showCharts = urls.isLocalhost && pricingInfo.hasRunFanniePricing;
	
    const navItems:NavMenuItem[] = [
		...showProposalTab ? [
			...hitsExist ? [{ label: "Trade", to: "trade" }] : [],
			{ label: getConfirmationLabel(hitInfo), to: "confirmation" },
			...hitsExist ? [{ label: "Commitment", to: "commitment" }] : []
		] : [],
		{ label: "Pricing", to: "pricing" },
		{ label: "Loans", to: "loans" },
        // { label: "Mapping", to: "mapping" },
        ...showCharts ? [{ label: "Charts", to: "charts" }]: []
    ]

	return (
		<>
            <Box mt="2">
                <PortalPlaceholder id="tape-details-top-summary" />
            </Box>

            <FlexVCenter my="2">
                <NavTabs items={navItems} />
                <FlexVCenter flex="1" height="btn-row" justifyContent="flex-end">
                    { pricingInfo && (
                        <Button
                            type="link"
                            ml="1"
                            icon="download"
                            size="sm"
                            onClick={() => openDownloadModal(sheet, pricingInfo, hitInfo, trade)}
                        >XLSX</Button>
                    ) }
                    <PortalPlaceholder id="tape-details-button-area" />
                </FlexVCenter>
            </FlexVCenter>

            <Routes>
                {/* There is a better way to do this. we can have trade check for /new and just do one route. */}
                <Route
                    path="trade"
                    element={<>
                        { (sheet && hitInfo && <Trade sheet={sheet} hitInfo={hitInfo} trade={trade} />)
                            || <TapeError /> }
                    </>}
                />
                <Route
                    path="trade/new"
                    element={<>
                        { (sheet && hitInfo && trade && <Trade sheet={sheet} hitInfo={hitInfo} trade={trade} isNew />)
                            || <TapeError /> }
                    </>}
                />
                {/* There is a better way to do this. we can have proposal check for /importing and just do one route. */}
                <Route
                    path="commitment"
                    element={<>
                        { (sheet && hitInfo && <Commitment sheet={sheet} hitInfo={hitInfo} />)
                            || <TapeError /> }
                    </>}
                />
                <Route
                    path="confirmation/import"
                    element={<>
                        { (sheet && hitInfo && <Confirmation sheet={sheet} hitInfo={hitInfo} importing />)
                        || <TapeError /> }
                    </>}
                />
                <Route
                    path="confirmation"
                    element={<>
                        { (sheet && hitInfo && <Confirmation sheet={sheet} hitInfo={hitInfo} />)
                            || <TapeError /> }
                    </>}
                />
                <Route
                    path="pricing"
                    element={<Pricing sheet={sheet} pricingInfo={pricingInfo} /> }
                />
                <Route path="mapping" element={<Mapping sheet={sheet} /> } />
                <Route path="loans" element={<Loans sheet={sheet} />} />
                <Route path="advanced" element={<Advanced sheet={sheet} /> } />
                <Route path="charts" element={<Charts sheet={sheet} /> } />
                <Route path="*" element={<Navigate to={getDefaultTab(showProposalTab, showTradeTab)} />} />
            </Routes>
            <DebugMsg>sheet status: { sheet?.sheetSummary.sheetStatus }</DebugMsg>
		</>
	)
}
