import React, { useState, useEffect } from 'react';
import { Icon, FlexVCenter } from 'mui';
import { labels, LoanField, GQL } from 'market-dto';
import { LoanTableRow } from './loan-table-row';
import { sheets, viewLoans } from 'services';
import './loan-table.css';

interface Props {
    readonly loansVs:viewLoans.LoanAndValuesAndSources[];
    readonly filters:sheets.LoanFilters;
    readonly asc:boolean;
    readonly sortedBy:LoanField | null;
    readonly sortBy:(fieldId:LoanField) => void;
}
export const LoanTable = ({ loansVs, filters, asc, sortedBy, sortBy }:Props) => {
    const fields = viewLoans.fields;
    return (
        <table className="table table-xs table-no-wrap table-bordered">
        <thead>
            <tr>
                <th style={{ textAlign: 'left' }}>Eligible?</th>
                { fields.map((f, n) => (
                    <th key={n} className="left sortable" onClick={() => sortBy(f.id)} >
                        <FlexVCenter>
                            { sortedBy === f.id && asc && <Icon name="arrow-up" /> }
                            { sortedBy === f.id && !asc && <Icon name="arrow-down" /> }
                            <span>{ labels.byLoanField(f.id) }</span>
                        </FlexVCenter>
                    </th>
                )) }
            </tr>
        </thead>
        <tbody>
            { loansVs.map((row, n) => (
                <LoanTableRow row={row} key={n} fields={fields} filters={filters} />
            ))}
        </tbody>
        </table>
    )
}