import { mutate, gql, GQL } from "../../client";

const MUTATE = gql`
    mutation createTrade($tradeParams: JSON!) {
        cleanroom_create_trade_sync(tradeParams: $tradeParams)
    }
`;
interface MutateInput { 
    readonly tradeParams:GQL.CreateTradeParams;
}
interface MutateOutput { 
    readonly cleanroom_create_trade_sync:GQL.CreateTradeResult;
}

export const createTrade = async (tradeParams:GQL.CreateTradeParams):Promise<GQL.CreateTradeResult | undefined> => {    
    const result = await mutate<MutateInput, MutateOutput>(MUTATE, { tradeParams });
    console.log('RESULT', JSON.stringify(result, null, 4));
    return result?.data?.cleanroom_create_trade_sync;
}
