import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const QUERY = gql`
    query {
        user (where:{ }) { ${ RETURNING } }
    }
`;

interface QueryResult {
    readonly user:GQL.User[];
}

export const fetchAllUsers = async ():Promise<GQL.User[]> => {
    const result = await query<any, QueryResult>(QUERY, {});
    return result.data.user;
}
