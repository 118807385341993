import React, { useState } from "react";
import { differenceInCalendarDays } from "date-fns"
import { CashWindow, format as fmt } from "market-dto";
import { Format, Box, Flex, SpectrumGridItemDetails, Text, Icon, FlexVCenter } from "mui";
import { utils } from "services"

interface DetailsProps {
    readonly label:string;
    readonly value:string | number;
}
const Details = ({ label, value }:DetailsProps) => (
    <Flex fontSize="xs" flex="1">
        <Box flex="3" textAlign="right" fg="faded">{ label }</Box>
        <Box flex="2" textAlign="left" fg="default" ml="1">{ value }</Box>
    </Flex>
)

const plural = (n:number) => n !== 1 ? 's' : '';

interface Props {
    readonly holding:CashWindow.HoldingInterest|null;
    readonly item:SpectrumGridItemDetails;
}
export const HoverItem = ({ holding:data, item }:Props) => {
    // differenceInCalendarDays()
    // bps
    // % next to cash and 
    if (!data) return null;

    const bps = item.val!;
    const daysToAcquire = differenceInCalendarDays(utils.ensureDate(data.acquisitionDate), utils.ensureDate(data.lockedDate));
    const daysToDeliver = differenceInCalendarDays(utils.ensureDate(data.target), utils.ensureDate(data.acquisitionDate));

    return (
        <Box
            bg="inner-card"
            p="1"
            mt="1"
            borderColor="alert"
            borderSides="all"
            borderWidth="thin"
            roundedEdges="all"
            shadow="card"
            style={{ width: '410px' }}
        >
            <Flex
                width="100%"
                alignItems="flex-start"
                mb="1"
                pb="1"
                borderSides="bottom"
                borderColor="alert"
                borderWidth="thin"
            >

                <Box flex="1">
                    <FlexVCenter
                        fontSize="md"                    
                        fg={ bps > 0 ? 'success' : bps === 0 ? 'default' : 'danger' }
                    >
                        { bps > 0 && <Icon name="arrow-up" mr="1" /> }
                        { bps < 0 && <Icon name="arrow-down" mr="1" /> }
                        <Text fontWeight="bold"><Format as="4_decimals">{ bps*100 }</Format></Text>
                        <Text fg="faded">&nbsp;bps</Text>
                    </FlexVCenter>
                    { item.isMax && (
                        <Box fg="success" mt="1">Best Execution</Box>
                    )}
                    {/* { item.isRowMax && !item.isMax && (
                        <Box fg="success" mt="1">Best Execution For this Row!</Box>
                    )} */}
                </Box>

                <Box flex="1">
                    <Box fg="loud" fontSize="sm">{fmt.datePartOfISO(data.acquisitionDate)}</Box>
                    <Box fg="default" fontSize="sm">
                        { daysToAcquire } day{ plural(daysToAcquire)}
                    </Box>
                    <Box fg="faded" fontSize="sm">Acquisition</Box>
                </Box>

                <Box flex="1">
                    <Box fg="loud" fontSize="sm">{fmt.datePartOfISO(data.target)}</Box>
                    <Box fg="default" fontSize="sm">
                        { daysToDeliver } day{ plural(daysToDeliver) }
                    </Box>
                    <Box fg="faded" fontSize="sm">Delivery</Box>
                </Box>

            </Flex>

            <Box px="2">
                <Flex>
                    <Details label="Interest Spread" value={fmt.toFourDecimals(data.interestSpreadPct) + ' %'} />
                    <Details label="Commitment Days" value={data.commitPeriods} />
                </Flex>
                <Flex>
                    <Details label="Cash Window" value={fmt.toFourDecimals(data.couldSellFor) + ' %'} />
                    <Details label="Warehouse Days" value={(data.warehouseDays ?? 0).toString()} />
                </Flex>
                <Flex>
                    <Box flex="1">&nbsp;</Box>
                    <Details label="MBS Days" value={(data.mbsDays ?? 0).toString()} />
                </Flex>
            </Box>

        </Box>
    )
}
