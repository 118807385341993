import React, { useEffect, useState } from 'react';
import { Flex, FlexVCenter, Box, Button, Icon, GradientText, Text, CssGridBox, BgColor, SpacingSize } from 'mui';
import { proposal, format } from 'services';
import { GQL, labels } from 'market-dto';
import { GenericSummaryBar, GenericSummaryGroup } from 'components';

const getCouponDisplayString = (x:proposal.SummaryGroup):string => {
    // it's a coupon, but, we can use noteRate formatter
    if (x.minCoupon === x.maxCoupon) return format.loanValue('noteRate', x.minCoupon);
    return format.loanValue('noteRate', x.minCoupon) + ' - ' + format.loanValue('noteRate', x.maxCoupon);
}

type ByInvestor = {[x:string]:number};


interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly onClose?:() => void;
}
export const TopSummary = ({ hitInfo, onClose }:Props) => {

    const { counts, sheetSummary:summary } = hitInfo;    
    // group by investor, then, sum each investor's unpaid bals
    const byInvestor:ByInvestor = hitInfo.loans.reduce((prev, curr) => {
        if (!curr.investor) return prev;
        if (!prev.hasOwnProperty(curr.investor)) {
            prev[curr.investor] = curr.currentBalance;
        } else {
            prev[curr.investor] += curr.currentBalance;
        }
        return prev;
    }, {} as ByInvestor)

    const groups:GenericSummaryGroup[] = [
        { items: [
            { top: summary.sheetId, bottom: labels.bySheetSummary('sheetId') },
            { top: summary.sellerName, bottom: labels.bySheetSummary('sellerName') },
        ] },
        ...Object.entries(byInvestor).map(arr => {
            // it's a little strange the actual investor name (not an id) is the key into the object.
            return {
                items: [
                    {
                        top: format.toCurrencyWithoutCents(arr[1]),
                        bottom: arr[0]
                    }
                ]
            }
        })
    ]


    return (
        <GenericSummaryBar
            groups={groups}
            renderBtnArea={(
                <FlexVCenter justifyContent="flex-end" flex="1">
                    <Icon name="close" fg="faded" fgHover="link" onClick={onClose} cursor="pointer" mr="1" />
                </FlexVCenter>
            )}
        />
    )
}
