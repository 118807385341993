import React from 'react';
import { Text, Icon, Box, Button, FlexVCenter, modal, Flex } from 'mui';
import { tapeImporter } from 'services';
import { deleteTemplate } from 'api';
import { useApiUpdate } from 'hooks';

const reasons:{[x in tapeImporter.InvalidMatchState]:string} = {
    AGG_TEMPLATE_BUT_MUTLIPLE_SELLERS_IN_TAPE: "Fix tape and try again",
    AGG_TEMPLATE_BUT_NO_SELLER_IN_TAPE: "We don't allow an agg template match if by using the template you either fail to map sellerName, or, map it and get zero distinct sellerNames (empty column). you will have to make a new template.",
    EMPTY_ORG_IDS_ON_TEMPLATE: "Old template, needs removing.",
    MULTIPLE_ORG_IDS_ON_NON_AGG_TEMPLATE: "non-agg templates cannot have more than one seller. delete this bad template.",
    UNKNOWN_ORG_ID_IN_TEMPLATE: "one of the orgids is invalid, which ruins the template."
}

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly processing:boolean;
    readonly advanceStep:() => void;
}
export const DebugArea = ({ batch, processing, advanceStep }:Props) => {

    const { update:apiDelPlate, busy:deleting } = useApiUpdate((id:string) => deleteTemplate(id));

	const deletePlate = async (matchResult:tapeImporter.UnassignedMatchResult) => {
		modal.confirm({
			title: <>
                Remove template?
                <Box fg="loud" mt="1">{ matchResult.template.id }</Box>
            </>,
			ok: async () => {
                await apiDelPlate(matchResult.template.id);        
            }
		})

		// const result = await apiDelPlate(matchResult.template.id);
		// modal.confirm({
		// 	title: result ? <>Template deleted.<br/>Refresh browser.</> : "Error deleting template",
		// 	hideCancel: true
		// })
    }
    
    return (
        <Box
            style={{ marginTop: '120px' }}
            bg="card"
        >
            <FlexVCenter px="2" pt="1" my="1" justifyContent="space-between">

                <Box fg="faded" fontWeight="bold">DEBUG AREA</Box>

                <FlexVCenter>
                    { processing && (
                        <FlexVCenter>
                            <Icon name="loader" spinning={!batch.paused} fg="accent"/>&nbsp;Processing
                        </FlexVCenter>
                    ) }
                    { !processing && <Box fontSize="sm">Halted. Step has completed.<br/>
                        <Button size="xs" onClick={() => advanceStep()}>Begin Next Step</Button>
                    </Box> }
                </FlexVCenter>

                <FlexVCenter>
                    <Box ml="2">Batch step:&nbsp;</Box>
                    <Box fg="accent" mr="2" fontSize="sm">{ batch.step }</Box>
                </FlexVCenter>

                <FlexVCenter>
                    <Button
                        size="xs"
                        type="secondary"
                        icon={batch.paused ? 'play' : 'pause'}
                        onClick={tapeImporter.togglePause}
                    />
                    { batch.paused && (
                        <Button
                            size="xs"
                            type="secondary"
                            ml="1"
                            onClick={tapeImporter.debugStep}
                        >Step</Button>
                    ) }
                </FlexVCenter>

                <Box>
                    { batch.paused && <Box fg="faded" fontSize="sm" mr="1">(UI Paused)</Box> }
                    { !batch.paused && <Box fg="faded" fontSize="sm" mr="1">(UI NOT Paused)</Box> }
                </Box>

            </FlexVCenter>

            {/*
                This slows it down too much.
                <textarea readOnly value={JSON.stringify(batch, null, 4)}></textarea>
            */}

            <table className="table table-sm">
                <thead>
                    <tr>
                        <th className="left">File</th>
                        <th className="left">Batich Item Status</th>
                        <th className="left">Templates that Matched</th>
                        <th className="left">Assigned Template</th>
                    </tr>
                </thead>
                <tbody>
                    { batch.items.map((item, n) => {
                        return <tr key={n}>
                            <td>{ item.file.name }</td>
                            <td>{ item.status }</td>
                            <td>
                                { item.debugMatchResults && item.debugMatchResults.map((m, n) => {
                                    return (
                                        <Box key={n}>
                                            <FlexVCenter key={n}>
                                                <Icon mr="1" name="trash" cursor="pointer" fgHover="loud" onClick={() => deletePlate(m)} />
                                                Template Id:&nbsp;
                                                <Text fg="loud">{ m.template.id }</Text>
                                                &nbsp;&nbsp;
                                                Match State:&nbsp;
                                                <Text fg="accent">{ m.matchState }</Text>
                                            </FlexVCenter>

                                            { m.type === "agg" && (
                                                <Box ml="3">
                                                    <Flex>
                                                        <Box textStyle="italic" fg="faded" mr="1">Aggregator:</Box>
                                                        <Box fg="loud">{ m.aggregatorName }</Box>
                                                    </Flex>
                                                    <Flex>
                                                        <Box textStyle="italic" fg="faded" mr="1">Distinct sellerName in Tape:</Box>
                                                        <Box fg="loud">{ m.sellerNameInTape }</Box>
                                                    </Flex>
                                                    <Flex>
                                                        <Box textStyle="italic" fg="faded" mr="1">Sellers that match sellerName in Tape:&nbsp;</Box>
                                                        <Box fg="loud">{ m.sellerOrgsFromSellerName.map(x => x.name).join(", ") }</Box>    
                                                    </Flex>
                                                    <Flex>
                                                        <Box textStyle="italic" fg="faded" mr="1">Sellers in template orgIds:&nbsp;</Box>
                                                        <Box fg="loud">{ m.sellerOrgsInTemplate.map(x => x.name).join(", ") }</Box>    
                                                    </Flex>
                                                </Box>
                                            ) }

                                            { m.type === "invalid" && (
                                                <Box>
                                                    <Text fg="danger">CAN NOT BE USED&nbsp;</Text>
                                                    <Text fg="faded">{ reasons[m.matchState] ?? 'Unknown invalid reason'}</Text>
                                                </Box>
                                            ) }
                                            { n < item.debugMatchResults!.length - 1 && (
                                                <hr />
                                            ) }
                                        </Box>
                                    )
                                }) }
                            </td>
                            <td>{ item.assigned?.template.id ?? 'N/A' }</td>
                        </tr>
                    }) }
                </tbody>
            </table>
        </Box>
    )
}