import { GQL, gql, mutate, stripInternals } from "../../../client";
import { RETURNING } from './returning';

const UPDATE = gql`
    mutation updateTaskXlsExport($set: xlsExport_update_input!, $where: where_xlsExport_exp) {
        update_xlsExport(set: $set, where: $where) {
            affectedRow
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.TaskXlsExport>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_xlsExport: {
        readonly returning:[GQL.TaskXlsExport];
    }
}

export const updateTaskXlsExport = async (id:string, data:Partial<GQL.TaskXlsExport>):Promise<GQL.TaskXlsExport|undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(data),
        where: {
            id: {
                _eq: id
            }
        }
    })
    return result?.data?.update_xlsExport.returning[0];
}
