import * as api from 'api';
import { GQL, util } from 'market-dto';
// import * as utils from '../utils';
import { downloadJsonFile } from '../downloader';
import { isNotNullOrUndefined } from '../rx-utils';
import { Observable, of, from, EMPTY } from 'rxjs';
import { map, switchMap, tap, filter, publishLast, shareReplay } from 'rxjs/operators';
import {
    JobStatus,
    getJobById,
    // getCurrentJobValue,
    // addDeferredJob,
    actLog,
    updateLatestPollResult,
    updateJobParam
} from '../activity-monitor';
import * as storage from '../storage';
const sample = require('./sample.json');


const getInvestorName = (investor:"fannie"|"freddie") => {
    // This must exist in many places. Eventually throw something like this into label service!
    if (investor === 'fannie') return 'Fannie';
    if (investor === 'freddie') return 'Freddie';
    return 'Unhandled investor';
}

var debugDict:{[x:string]:GQL.BatchStatus[]} = {};
export const checkQueuedPricingJobDone = (
    investor:"fannie"|"freddie",
    jobId:string,
    sheetId:string,
    batchId?:string
):Observable<JobStatus> => {

    const dbug = !!storage.get("pricing-debug");
    debugDict[jobId] = [];
    const name = getInvestorName(investor);
    return of(batchId).pipe(
        switchMap(batchId => {
            if (!batchId) {
                actLog("PRICING", "ERROR", sheetId, name + ' pricing failed to get batch id');
                return of("FAILURE" as JobStatus);
            }
            // updateJobParam(jobId, batchId);
            // how can i get last value?
            console.log('jobId', jobId, batchId);
            const { latestPollResult } = getJobById(jobId) ?? {};
            const lastLogs = latestPollResult?.logs ?? [];
            // console.log('------- start index', lastLogs.length);
            return from(api.checkBatchStatus(batchId, lastLogs.length)).pipe(
                // Why are we asking for all the logs?
                isNotNullOrUndefined(),
                tap(x => {
                    const withOldLogs = {
                        ...x,
                        logs: [...lastLogs, ...(x.logs ?? [])]
                    }
                    if (dbug) debugDict[jobId].push(withOldLogs);
                    updateLatestPollResult(jobId, withOldLogs);
                }),
                map((batchStatus:GQL.BatchStatus):JobStatus => {

                    const total = batchStatus.summary.count ?? 0;
                    const failed = batchStatus.summary.failed ?? 0;
                    const completed = batchStatus.summary.completed ?? 0;

                    if (batchStatus.batchStatus === "FAILED") {
                        // if (failed >= total && total > 0) Every single one failed.
                        actLog("PRICING", "ERROR", sheetId, name + ' pricing ' + batchId);
                        return 'FAILURE';
                    }
                    // if (completed + failed >= total) {
                    if (batchStatus.batchStatus === "COMPLETED") {
                        // Success, even if a few failed.
                        actLog("PRICING", "ACTIVITY_ENDED", sheetId, name + ' pricing ' + batchId);
                        if (dbug) downloadJsonFile(debugDict[jobId], 'pricing-log.json');
                        return 'SUCCESS';
                    }
                    return 'BUSY';
                })
            )
        })
    )
}

let replayIndex = 0; // MUTABLE -- only one replay at a time as a consequence (at least for now)

export const checkReplayPricingJobDone = (replayArr:GQL.BatchStatus[], jobId:string, sheetId:string):Observable<JobStatus> => {

    const name = "replay";
    replayIndex = 0;

    return of(true).pipe(
        switchMap(batchId => {

            if (replayIndex >= replayArr.length) {
                throw new Error('Error - end of replay array unexpectedly found!');
            }
            const x = replayArr[replayIndex];
            replayIndex++;

            return of(x).pipe(
                tap(x => {
                    updateLatestPollResult(jobId, x);
                }),
                map((batchStatus:GQL.BatchStatus):JobStatus => {
                    const total = batchStatus.summary.count ?? 0;
                    const failed = batchStatus.summary.failed ?? 0;
                    const completed = batchStatus.summary.completed ?? 0;
                    if (failed >= total) {
                        // Every single one failed.
                        actLog("PRICING", "ERROR", sheetId, name + ' pricing ' + batchId);
                        return 'FAILURE';
                    }
                    if (completed + failed >= total) {
                        // Success, even if a few failed.
                        actLog("PRICING", "ACTIVITY_ENDED", sheetId, name + ' pricing ' + batchId);
                        return 'SUCCESS';
                    }
                    return 'BUSY';
                })
            )
        })
    )
}