import React, { useState } from 'react';
import { Box, Text, Button, Flex, modal, Input, Alert, LabelValueGrid } from 'mui';
import { GQL } from 'market-dto';
import { useZStateList } from 'hooks';
import { alpha } from 'services';
import { useNavigate } from 'react-router-dom';
import { Tags } from './tags';

interface Props {
    readonly flow:GQL.Flow;
}
export const Details = ({ flow }:Props) => {

	const {
		updateItem,
		updateItemAndCommit,
		updating,
        editItemDirty,
        busy,
        deleting,
        removeSelectedItem
	} = useZStateList(alpha.zFlows);

    const nav = useNavigate();

	const removeFlow = () => {
		if (busy) return;
		modal.confirm({
			title: 'You sure you want to remove this workflow?',
			ok: () => {
				nav('../../..');
				removeSelectedItem();
			}
		})
	}

	const updateTags = (tags:string[]) => updateItem({ tags });

	const save = () => {
		const trimmedAndFiltered = flow.tags.map(x => x.trim()).filter(x => x);
		updateItemAndCommit({ tags: trimmedAndFiltered });
	}
	
    return (
        <Box>
			<Flex>

				<Box
					width="card-sm"
					bg="card"
					p="2"
					borderColor="alert"
					borderSides="all"
					borderWidth="thin"
					roundedEdges="all"
					mr="3"
				>
					<Tags
						items={flow.tags}
						label={<Text fg="default">Tags</Text>}
						onChange={updateTags}
						placeHolder="Enter Tag"
						addTitle="Add New Tag"
						saving={updating}
						onSave={save}
						canSave={editItemDirty}
					/>
				</Box>

				<Box flex="1" fontSize="sm">
					<LabelValueGrid>
						<Box fg="faded">Workflow Name</Box>
						<Box>{ flow.name }</Box>
						<Box fg="faded">Workflow ID</Box>
						<Box>{ flow.id }</Box>
						<Box fg="faded">Created As</Box>
						<Box>{ flow.recipeName }</Box>
						<Box fg="faded">Data Model</Box>
						<Box>{ flow.modelType }</Box>
					</LabelValueGrid>

					<Box mt="3">
						<Button
							icon="trash"
							size="sm"
							type="danger"
							onClick={removeFlow}
							busy={deleting}
						>Remove Flow</Button>
					</Box>
				</Box>

			</Flex>

            {/* <Button
                ml="1"
                size="sm"
                onClick={commitItem}
                busy={updating}
                type={editItemDirty ? 'default' : 'disabled'}
            >Save Tag Changes</Button> */}
			{/* <Box>
				TAGS:<br/>
				<table className="table table-sm">
					<thead>
					<tr>
						<th className="left">Tag</th>
						<th>&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					{flow.tags.map((tag, n) => (
						<tr key={n}>
							<td>{tag}</td>
							<td><Icon name="trash" onClick={() => removeTag(n)}/></td>
						</tr>
					))}
					</tbody>
				</table>
				<FlexVCenter>
					<Input value={newTag} setValue={setNewTag}/>
					<Button ml="1" size="sm" onClick={addTag}>Add</Button>
				</FlexVCenter>
			</Box> */}



        </Box>
    )
}