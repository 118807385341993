import { hintsFromCsv, InternalPricing, IPricingConfig } from "raccoon-engine"


export type InternalPricingFunc = (data:InternalPricing) => void

/**
 * To ensure the content has the internal structure like IPricingConfig
 * @param content
 */
export const ensureStructure = (content:any) => {
	const { modules } = content
	if (!modules) content.modules = []
	return content as IPricingConfig
}

export const addPricingModule = (modules:InternalPricing[], module:InternalPricing) => {
	const found = modules.find(m => m.id === module.id)
	if (found) {
		found.csv = module.csv
		found.hints = module.csv || hintsFromCsv(module.csv)
	} else {
		modules.push(module)
	}
}

/**
 * remove the pricing module form the given collection
 * @param modules
 * @param moduleId
 */
export const removePricingModule = (modules:InternalPricing[], moduleId:string) => {
	const internalRemove = (id:string) => {
		console.log('removing', moduleId, modules.map(m => m.id))
		const found = modules.findIndex(m => m.id === id)
		if (found >= 0) {
			modules.splice(found, 1)
		}
	}
	internalRemove(moduleId)
}






