export const RETURNING = `
    id
    sheetId
    proposalId
    created
    sellerId
    cleanRoomTrade {
        tradeId
        name
        sellerId
    }
    loans {
        loanId
        loanNumber
        created
        info {
            loanFileID
            crLoanNumber
        }
    }
`;