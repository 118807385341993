import { tapeImporter } from 'services';
import { loanModel } from 'market-dto';
import { ExpandedState } from './types';
import { setOrideDependents } from './set-oride-dependents';

export const setSelected = (item:tapeImporter.BatchItem, exState:ExpandedState, sel?:tapeImporter.UnassignedValidMatchResult):ExpandedState => {
    // setting or clearing the match selected.
    if (sel) {
        const orides = loanModel.getOverridesFromTemplate(sel.template);
        const orideDeps = setOrideDependents(item, orides, orides);
        
        console.log('oride deps', JSON.stringify(orideDeps, null, 4));

        return {
            ...exState,
            step: "mapping",
            newSeller: undefined,
            initOrides: orides,
            selected: sel,
            ...orideDeps
        }
    } else {
        return {
            ...exState,
            step: "match-options",
            newSeller: undefined,
            orides: {},
            initOrides: {},
            dirtyOrides:false,
            selected: undefined
        }    
    }
}
