import React from 'react';
import { proposal } from 'services';
import { GQL } from 'market-dto';
import { modal, IntoPortal } from 'mui';
import { useNavigate } from 'react-router-dom';
import { HitInfo } from 'services/proposal';
import { TopSummary } from './top-summary';
import { ProposalView } from './proposal-view';

interface Props {
    readonly sheet:GQL.Sheet;
    readonly hitInfo?:HitInfo;
    readonly importing?:boolean;
}
export const Confirmation = ({ sheet, hitInfo, importing }:Props) => {

    if (!hitInfo) throw new Error('Missing hit info!');

    const navigate = useNavigate();
    
	const close = () => {
        if (importing) {
            navigate('/import/hits');
        } else {
            navigate('/tapes');
        }
    }

    const onTradeCreated = () => {
        modal.close();
        navigate('/tape/' + sheet.id + '/trade/new');
    }
    
    return (
        <>
            <IntoPortal id="tape-details-top-summary">
                <TopSummary
                    hitInfo={hitInfo}
                    onClose={close}
                />
            </IntoPortal>

            <ProposalView
                hitInfo={hitInfo}
                onTradeCreated={onTradeCreated}
                defaultShowHitsOnly={false} // was {trueImport}, this was confusing. now default to show all (even those without hits)
            />
        </>
    )
}