import React, { useEffect } from 'react';
import { proposal, user, genSheet, marketSettings, sellers } from 'services';
import { GQL } from 'market-dto';
import { Box, IntoPortal, Button, modal, Alert } from 'mui';
import { useNavigate } from 'react-router-dom';
import { TradeTable } from './trade-table';
import { TradeSummary } from './trade-summary';
import { SetupErrorModal, CreateTradeModal } from './modals';
import { TopSummary } from '../confirmation/top-summary';

interface Props {
    readonly sheet:GQL.Sheet;
    readonly trade?:GQL.Trade;
    readonly hitInfo?:proposal.HitInfo;
    readonly isNew?:boolean;
}
export const Trade = ({ sheet, hitInfo, trade, isNew }:Props) => {

    if (!hitInfo) throw new Error('Expected hit info');
    // if (!trade) throw new Error('Expected trade');

    const navigate = useNavigate();
    useEffect(() => {
        if (isNew) genSheet.genTradeOutputSheet(hitInfo, trade!, user.getUser()?.org.name ?? '');
    }, [sheet.id, isNew])

    const settings = marketSettings.getMarketSettings();

    const onClose = () => navigate('/tapes');

    const onTradeCreated = () => {
        modal.close();
        navigate('/tape/' + sheet.id + '/trade/new');
    }

    const canContinue = hitInfo.proposalDetails.status === 'HIT' && hitInfo.sheetSummary.sheetStatus === 'TRADING';
    const openContinueModal = () => {
        const crEnv = settings ? settings['cleanroom.environment'] as string : '';
        const seller = sellers.getSellerById(hitInfo.proposalDetails.sellerOrgId);
        const { externalIdSource, externalId } = seller ?? {};
        if (crEnv.trim().toLowerCase() !== externalIdSource?.trim().toLowerCase() || !externalId) {
            console.log('env stuff:', crEnv, externalIdSource, externalId);
            modal.open({
                title: 'Create Trade - ' + hitInfo.proposalDetails.sellerName,
                static: true,
                size: "lg",
                render: <SetupErrorModal
                    crEnv={crEnv}
                    sellerEnv={externalIdSource}
                    sellerEnvId={externalId}
                    sellerName={hitInfo.proposalDetails.sellerName}
                />
            })
        } else {
            modal.open({
                title: 'Create Trade - ' + hitInfo.proposalDetails.sellerName,
                hideCloseIcon: true,
                static: true,
                size: "lg",
                fixAtMaxHeight: true,
                maxHeight: "md",
                render: <CreateTradeModal
                    hitInfo={hitInfo}
                    crEnv={crEnv}
                    hitLoanCount={hitInfo.loans.filter(loan => hitInfo.hitByLoanId[loan.id]).length}
                    counts={hitInfo.counts}
                    onTradeCreated={onTradeCreated}
                />
            })
        }
    }


    return (
        <>
            <IntoPortal id="tape-details-top-summary">
                { !trade && <TopSummary hitInfo={hitInfo} onClose={onClose}></TopSummary> }
                { trade && <TradeSummary hitInfo={hitInfo} trade={trade} onClose={onClose} /> }
            </IntoPortal>

            <IntoPortal id="tape-details-button-area">
                { canContinue && (
                    <>
                    <Button
                        ml="1"
                        type="default"
                        size="sm"
                        onClick={openContinueModal}
                        icon="share"
                    >Clean Room</Button>
                    </>
                ) }
            </IntoPortal>

            <Box my="2">
                { trade && <TradeTable trade={trade} /> }
                { !trade && (
                    <Alert>This tape has not yet been used to create a Clean Room trade.</Alert>
                ) }
            </Box>
        </>
    )
}

/*
{
    "id": "20200914-0004",
    "sheetId": "20200914-0004",
    "proposalId": "ckf2t6ptx000m2voy7d207cjp",
    "created": true,
    "sellerId": null,
    "cleanRoomTrade": {
        "tradeId": 464,
        "name": "wer4ewr345",
        "sellerId": 123,
        "__typename": "tradeInfo"
    },
    "loans": [
        {
            "loanId": "20200914-0004-002",
            "loanNumber": "wruiimbtyrkk",
            "created": true,
            "info": {
                "loanFileID": 26703,
                "__typename": "loanInfo"
            },
            "__typename": "cleanRoomLoan"
        },
        {
            "loanId": "20200914-0004-001",
            "loanNumber": "qfobhtllojcb",
            "created": true,
            "info": {
                "loanFileID": 26704,
                "__typename": "loanInfo"
            },
            "__typename": "cleanRoomLoan"
        }
    ],
    "__typename": "trade"
}
*/