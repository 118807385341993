import React from 'react';
import { GQL } from 'market-dto';
import { Icon } from 'mui';
import { useDrag, useDrop } from 'react-dnd';

interface Props {
    readonly letter:string;
    readonly colCfg:GQL.XlsExportCol;
    readonly showingPopFilter:boolean;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
    readonly setShowFilterPopper:(f:GQL.XlsExportCol, elem:HTMLElement) => void;
    readonly moveField:(from:number, to:number) => void;
    readonly readOnly:boolean;
}

export const XlsExportTableTheadTh = ({
    letter,
    colCfg,
    showingPopFilter,
    selectedColId,
    setSelectedColId,
    setShowFilterPopper,
    moveField,
    readOnly
}:Props) => {

    const { colId } = colCfg;
    const isSelected = colId === selectedColId;

    const dragType = "m-xls-th";

    const [ collectedDragProps, drag, preview ] = useDrag({
        item: { type:dragType, colId },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging()
            }
        }
    })    

    const [ collectedDropProps, drop ] = useDrop({
        accept: dragType,
        collect: (monitor) => {
            return {
                draggingOverMe: monitor.isOver()
            }
        },
        drop: (item, monitor) => {
            const fromColId = (item as any).colId;
            moveField(fromColId, colId);
        }
    })

    const { isDragging } = collectedDragProps;
    const { draggingOverMe } = collectedDropProps;

    const cssArr = [];

    if (isSelected) {
        if (showingPopFilter) {
            cssArr.push('force-hover');
        } else {
            cssArr.push('selected');
        }
    }
    if (draggingOverMe) cssArr.push('dragging-over');
    const css = cssArr.join(' ');

    const dragAndDrop = (el:any) => {
        if (readOnly) return;
        preview(el);
        drop(el);
    }

    const showPop = (evt:React.MouseEvent) => {
        if (readOnly) return;
        evt.stopPropagation();
        const elem = evt.nativeEvent.target as HTMLElement;
        setShowFilterPopper(colCfg, elem);
    }

    return (
        <th
            className={css}
            onClick={() => setSelectedColId(colId)}
            ref={!readOnly ? dragAndDrop : () => {}}
        >
            <div
                ref={drag}
            >
                <div>{ letter }</div>
                { !readOnly && <Icon name="more-horizontal" onClick={showPop} /> }
            </div>
        </th>
    )
}

