import { GQL } from 'market-dto';
import { isValidFannieOffer } from '../fannie';
import { isValidFreddieOffer } from '../freddie';
import { getFilteredOffers } from './get-filtered-offers';


export interface OfferState {
    readonly hasAnyOffers:boolean;
    readonly hasDisplayOffers:boolean;
    readonly hasOnlyInvalidOffers:boolean;
    /** Array of offers, always includes valid offers, includes invalid offers based on filter. null means none found */
    readonly displayOffers:GQL.Offer[];
    /** Array of ineligible reasons. null means eligible. */
    readonly ineligibleReasons:string[]|null;
}

const getInelgibileReasons = (loan:GQL.Loan, hasAnyOffers:boolean, hasDisplayOffers:boolean):string[]|null => {
    
    if (loan.eligibility === 'Eligible') {
        if (!hasAnyOffers) return null; // simply we do not have any.
        if (hasDisplayOffers) return null;
        // We are eligible, we have offers, but we have no display offers.
        // This means, only bad offers
        return ['Loan cannot be sold'];
    }
    return loan.ineligibleFor ?? [];                    // existence of array means NOT eligible.
}

export const getOfferState = (loan:GQL.Loan):OfferState => {
    const allOffers = loan.offers ?? [];
    const hasAnyOffers = allOffers.length > 0;
    const validOffers = allOffers.filter(x => isValidFannieOffer(x) || isValidFreddieOffer(x));
    const displayOffers = getFilteredOffers(loan);
    const hasDisplayOffers = displayOffers.length > 0;
    return {
        hasAnyOffers,
        hasOnlyInvalidOffers: allOffers.length > 0 && validOffers.length === 0,
        // if this has greater than 0, we do NOT show inelgible reasons (even if they exist cuz invalid offer!)
        displayOffers,
        hasDisplayOffers,
        ineligibleReasons: getInelgibileReasons(loan, hasAnyOffers, hasDisplayOffers)
    }
}


// export const getTrueIneligibilityReasons = (loan:GQL.Loan):string[]|null => {
//     // return an array of reasons that it is NOT eligible, or, null (meaning, eligible)
//     // "NA" | "Eligible" | "Ineligible" | "Exception" | "Unrectified" | undefined

//     // if (loan.offers) {

//     // } else {

//     // }

//     const validFreddieOfferExists = loan.offers && loan.offers.some(offer => isValidFreddieOffer(offer));
//     const validFannieOfferExists = loan.offers && loan.offers.some(offer => isValidFannieOffer(offer));

//     // if (!validFreddieOfferExists && !validFannieOfferExists && loan.offers && loan.offers.length > 0) {
//     //     // Neither valid freddie NOR valid fannie offers exist.
//     //     // So, if ONE or more offer does exist, we must show our generic "loan cannot be sold" message.
//     //     // if (badFannieOffer) return ['Loan cannot be sold'];
//     //     return ['Loan cannot be sold'];    
//     // }

//     // const badFannieOffer = loan.offers?.find(offer => isInvalidFannieOffer(offer));
//     // if (badFannieOffer) return ['Loan cannot be sold'];
//     if (loan.eligibility === 'Eligible') return null; // null means eligible
//     return loan.ineligibleFor ?? []; // existence of array means NOT eligible.
// }