import { ExpNode, EXP_INVALID_CHAR } from './exp-types';
import { GenericWalkerOpts, genericWalker } from './generic-walker';

export const getActiveNode = (root:ExpNode|null, caret:number):ExpNode|null => {
    return genericWalker({
        acc: null,
        lvl: 0,
        parent: null,
        node: root,
        fn: (x:GenericWalkerOpts<ExpNode|null>) => {
            const { node:curr, acc, childAcc } = x;
            const best = childAcc ?? acc; // if you have child acc, that's always better (deeper)
            if (curr === null) return best;
            if (caret < curr.start || caret > curr.end) return best;
            // Constants with X cannot ever be activeNode (they are internal placeholders)
            if (curr.type === 'ConstantNode' && curr.value === EXP_INVALID_CHAR) return best;
            if (best === null) return curr;
            if (best.level! > curr.level!) return best;
            return curr;
        }
    })
}