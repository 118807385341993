import React, { useState, useEffect } from 'react';
import {
    usePageSortTable,
    TablePageButtons,
    SortableTableHeader
}  from 'mui';
import { rule } from 'services';
import { useNavigate } from "react-router-dom";

interface Props {
    readonly models:rule.ModelInfo[];
}

export const ModelsTable = ({ models }:Props) => {

    const navigate = useNavigate();

    const { sortBy, asc, buttons, page, totalPages, setPage, sortedBy, sortedRows, pageSortState:ps } = usePageSortTable<rule.ModelInfo, keyof rule.ModelInfo>({
        rows: models ?? [],
        keyFn: row => row.id,
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const load = (row:rule.ModelInfo, n:number) => {
        navigate(row.id);
    }

    if (!models) return null;

    return (
        <>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="refId" render="Ref Id" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render="Name" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="description" render="Description" />
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((row, n) => (
                        <tr key={row.id} onClick={() => load(row, n)} style={{ cursor: 'pointer' }}>
                            <td>{ row.refId }</td>
                            <td>{ row.name }</td>
                            <td>{ row.description }</td>
                        </tr>
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}