import React, { useState, useEffect } from 'react';
import { Box, Link, modal, IntoPortal, Button, FlexVCenter } from 'mui';
import { useNavigate } from 'react-router-dom';
import { GQL, getModelByModelType } from 'market-dto';
import { tapeImporter, actMon, alpha } from 'services';
import { useSafeState, useZState } from 'hooks';
import { FlowLandingTapeImport } from './flow-landing-tape-import';
import { FlowRunWithTapeId } from './flow-run-with-tape-id';
import { SummaryBar } from './summary-bar';

interface Props {
    readonly flow:GQL.Flow;
    readonly closeFlow:() => void;
}
export const FlowLanding = ({ flow, closeFlow }:Props) => {

    const model = getModelByModelType(flow.modelType);
    const nav = useNavigate();
    const edit = () => nav('edit');

    const [ tapeId, setTapeId ] = useSafeState('');
    const [ isNewFlow, setIsNewFlow ] = useState(() => flow.recentActivity === null);

    const { data:batch } = useZState(tapeImporter.zBatch);


    useEffect(() => {
        // flow has changed.
        setIsNewFlow(flow.recentActivity === null);
        tapeImporter.zBatch.clear();
    }, [flow.id])

    const onTapeCreated = (tapeId:string) => {
        if (!tapeId) {
            return;
        }
        setTapeId(tapeId);
    }

    return (
        <>
            <SummaryBar
                closeFlow={closeFlow}
                // Remember, you cannot edit a flow until at least ONE run exits.
                isNewFlow={isNewFlow}
                onEdit={isNewFlow ? undefined : edit}
                readOnly
                flow={flow}
                batch={batch}
                tapeId={tapeId}
            />

            <Box mt="2">
                { !tapeId && (
                    <FlowLandingTapeImport
                        isNewFlow={isNewFlow}
                        batch={batch}
                        model={model}
                        onTapeCreated={onTapeCreated}
                    />
                ) }
                { tapeId && (
                    <FlowRunWithTapeId
                        flow={flow}
                        tapeId={tapeId}
                    />
                ) }
            </Box>
        </>
    )
}