import React, { useState } from 'react';
import { Box, Text, FlexVCenter, IconButton, Button, IntoPortal } from 'mui';
import { ColValMapItems } from './col-val-map-items';
import { mapping } from 'services';

interface Props {
    readonly field:mapping.Field;
    readonly filterText:string;
    readonly initField:mapping.Field;
    readonly hideEmpty:boolean;
    readonly items:mapping.ColumnValuesAndMapping[];
    readonly onChange?:(colIndex:number) => void;
}
export const TabMapping = ({ field, hideEmpty, initField, items, filterText, onChange }:Props) => {
    const [ valuesIndex, setValuesIndex ] = useState<number>(0);
    const [ assignTo, setAssignTo ] = useState<number>(-1);
    const left = () => setValuesIndex(valuesIndex > 0 ? valuesIndex-1 : valuesIndex);
    const right = () => {
        // just look at first row for amount of values--cuz they must be the same for all. it's a safe assumption.
        setValuesIndex(valuesIndex < items[0].values.length-1 ? valuesIndex+1 : valuesIndex);
    }

    const preAssign = (n:number) => {
        if (onChange) onChange(n);
    }
    
    const isMapped = field.res.winner > -1;
    const showSubBox = false;
    return (
        <>
            <IntoPortal id={mapping.ui.subHeaderPortalId}>
                <>
                    <FlexVCenter
                        borderSides="bottom"
                        borderColor="alert"
                        borderWidth="thin"
                        pb="1"
                        mt="1"
                        mb={showSubBox ? "0" : "1"}
                    >
                        <Box flex={mapping.ui.flexSizes[0]} fg="faded" textAlign="right" px="1">Spreadsheet Column Name</Box>
                        <FlexVCenter flex={mapping.ui.flexSizes[1]} fg="faded" px="1">
                            <IconButton name="chevron-left" onClick={left}></IconButton>
                            <Box flex="1" mx="1" textAlign="center">Spreadsheet Data</Box>
                            <IconButton name="chevron-right" onClick={right}></IconButton>
                        </FlexVCenter>
                        <Box flex={mapping.ui.flexSizes[2]} fg="faded" px="1" textStyle="italic">Mapped To</Box>
                    </FlexVCenter>
                </>
            </IntoPortal>
            <Box mt="1">
                <ColValMapItems
                    field={field}
                    filterText={filterText}
                    initField={initField}
                    hideEmpty={hideEmpty}
                    items={items}
                    valuesIndex={valuesIndex}
                    confirmIndex={assignTo}
                    onClick={preAssign}
                />
            </Box>
        </>
    )
}