import React from 'react';
import { FrmInput, FrmInputProps } from './frm-input';
import { useProxyData, BindTo } from '../../use-proxy-data';

interface BoundFrmInputProps<T extends {}> extends FrmInputProps {
    readonly bindTo:BindTo<T>;
}
export const BoundFrmInput = <T extends {}>({ bindTo, ...props }:BoundFrmInputProps<T>) => {
    const proxy = useProxyData(bindTo);
    return <FrmInput value={proxy.value} setValue={proxy.setVal} {...props} />
}
