import { useRef, useEffect } from 'react';

export const usePrevious = <T>(value:T) => {
    // init value will be undefined.
    // usage:  const abc = usePrevious(anything);
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    })
    return ref.current;
}