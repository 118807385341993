import React from 'react';
import { Box, FrmCheckbox } from 'mui';
import { JSONSchema } from 'market-dto';
import { missingTitle } from './schema-ui.service';


interface Props {
    readonly val:boolean;
    readonly schema:JSONSchema;
    readonly setValue:(x:any)=>any;
    readonly editting:boolean;
}
export const SchemaUiItemBool = ({ val, schema, setValue, editting }:Props) => {

    return (
        <Box ml="2">
            <FrmCheckbox
                size="sm"
                readOnly={!editting}
                label={schema.title ? schema.title : missingTitle }
                desc={schema.description}
                checked={val}
                onToggle={setValue}
            />
        </Box>
    );
}