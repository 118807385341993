import React from 'react';
import { FrmDropdown } from 'mui';
import { JSONSchema } from 'market-dto';
import { missingTitle, getItemsFromEnums, KeyValueDict } from './schema-ui.service';

interface Props {
    readonly val:Array<any>;
    readonly schema:JSONSchema;
    readonly setValue:(x:boolean)=>any;
    readonly editting:boolean;
}
export const SchemaUiArray = ({ val, schema, setValue, editting }:Props) => {
    const items = getItemsFromEnums(schema);
    const vals = items.filter(x => val ? val.includes(x.value) : false);
    return (
        <FrmDropdown
            size="sm"
            label={schema.title ? schema.title : missingTitle}
            desc={schema.description}
            subLabel={schema.sample ? 'Example:' : undefined}
            subDesc={schema.sample}
            items={items}
            value={vals}
            isMulti={true}
            setValue={setValue}
            disabled={!editting}
            placeholder={schema.description}
        />
    )
}
