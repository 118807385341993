import React, { useState } from 'react';
import { useZStateList } from 'hooks';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { alpha } from 'services';
import { AutoRun } from './auto-run';
import { FlowLanding } from './flow-landing';
import { Edit } from './edit';

export const FlowRoutes = () => {
	
	const { editItem:flow, clearItem } = useZStateList(alpha.zFlows);
	if (!flow) throw new Error('Expected flow');
	
	const nav = useNavigate();

	const closeFlow = () => {
		nav('..');
		clearItem();
	}
	
	return (
		<Routes>
			<Route path="edit/*" element={<Edit flow={flow} closeFlow={closeFlow} />}/>
			<Route path="" element={<FlowLanding flow={flow} closeFlow={closeFlow} />} />
			<Route path="run/:tapeId" element={<AutoRun flow={flow} closeFlow={closeFlow} />} />
			<Route path="*" element={<Navigate to="" />}/>
		</Routes>
	)
}
