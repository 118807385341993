import * as storage from '../storage';
import * as api from 'api';

// Cypress testing login. Do nothing on failure, the test will realize we aren't logged in.
export const cyLogin = async (email:string, token:string) => {
    const sessionId = await api.insertSession(email);
    if (!sessionId) return;
    const result = await api.updateSession(sessionId, token);
    if (!result || !result.whoAmI || !result.jwt) return;
    storage.set('jwt', result.jwt);
    storage.set('user', result.whoAmI);
}
