import { LoanField, loanModel } from 'market-dto';
import { EditableRuleTable } from './param-table.service';
import { log } from 'services';

const toStr = (x:string|number|undefined|null) => {
    if (x === undefined || x === null) return '';
    return x+'';
}

export const translateRowVals = (t:EditableRuleTable):EditableRuleTable => {
    // Useful when pasting data from spreadsheet into textarea!
    // const config = getMasterConfig();
    return {
        cols: t.cols,
        rows: t.rows.map(row => {
            return {
                ...row,
                vals: t.cols.map((col, colIndex) => {

                    const rawVal = toStr(row.vals[colIndex]);
                    const field = loanModel.getField(col.field as LoanField);
                    // const field = fields.find(f => f.fieldId === col.field);
                    if (field) {
                        log.info('we found', col.field, '[' + rawVal + ']');
                        return loanModel.translateRawValueAsMappedField(rawVal, col.field as LoanField).val;
                    }
                    return rawVal;
                })
            }            
        })
    }
   
}