import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    modal,
    Alert,
    Flex,
    Textarea
} from 'mui';
import { useApiUpdate, useZStateList } from 'hooks';
import * as api from 'api';
import { paste, utils, sellers } from 'services';
import Sample from './import-sample.png';


interface InstructionsProps {
    readonly onReady:()=>any;
}
const Instructions = ({ onReady }:InstructionsProps) => {
    return (
        <Box textAlign="center">
            Before you can import sellers from spreadsheet data, you must prepare the spreadsheet data as follows:<br/>
            <Box my="2">
                <img src={Sample} alt="Sample Sheet Table" /><br/>
            </Box>
            <Box my="2">
                Note: Do not include headers! Just copy and paste rows of data, three columns wide.
            </Box>
            <Button onClick={onReady}>I'm Ready</Button>
        </Box>
    )
}

interface ImportProps {
    readonly onPaste:(e:React.ClipboardEvent) => any;
    readonly onBack:() => any;
}
const ImportForm = ({ onPaste, onBack }:ImportProps) => {
    return (
        <Flex height="card-md" flexDirection="column">
            <Box>
                <Button icon="arrow-left" type="link" onClick={onBack}>Back</Button>
            </Box>
            <Box my="2" textAlign="center">
                Paste what you have copied from a spreadsheet into the textarea below!    
            </Box>
            <Box my="2" flex="1">
                <Textarea onPaste={onPaste} />
            </Box>
        </Flex>
    )
}

interface ConfirmProps {
    readonly data?:api.SellerBulkImportItem[];
    readonly onConfirm:() => void;
    readonly onBack:() => void;
}
const Confirmation = ({ data, onConfirm, onBack }:ConfirmProps) => {
    if (!data) throw new Error('Expected data');

    const { busy, update } = useApiUpdate(async () => {
        const result = await api.bulkSellerImport(data);
        // For now, do nothing with result. Maybe never do anything with it...
        sellers.zSellers.refreshList();
        modal.close();
    })
    
    return (
        <Box>
            <Box>
                <Button icon="arrow-left" type="link" onClick={onBack}>Back</Button>
            </Box>
            <Flex mt="1" textAlign="center" width="100%">
                <Box flex="1" textAlign="right" mr="half">Sellers to import:</Box>
                <Box flex="1" textAlign="left" ml="half" fg="loud" fontWeight="bold">{ data.length }</Box>
            </Flex>
            <Flex mt="1" textAlign="center" width="100%">
                <Box flex="1" textAlign="right" mr="half">Source:</Box>
                <Box flex="1" textAlign="left" ml="half" fg="loud" fontWeight="bold">{ data[0].externalIdSource }</Box>
            </Flex>
            <Box my="2" textAlign="center">
                <Button type="secondary" onClick={onBack}>Cancel</Button>
                <Button ml="1" busy={busy} onClick={update}>Import</Button>
            </Box>
        </Box>
    )
}

interface Props {
    // readonly editTable:EditableRuleTable;
    readonly onImport:() => void;
}
export const ImportModal = ({ onImport }:Props) => {

    const [ step, setStep ] = useState<number>(1);
    const [ err, setErr ] = useState<string>('');
    const [ data, setData ] = useState<api.SellerBulkImportItem[]>();

    const onPaste = (e:React.ClipboardEvent) => {
        
        const htmlTable = paste.getClipboardTable(e);
        if (!htmlTable) {
            setErr('Invalid paste data...please try again');
            return;
        }
        const { rows, headers } = paste.htmlTableStringToRowsAndHeaders(htmlTable);
        // Let's ignore headers for now
        const all = [headers, ...rows];
        if (all.length < 1) {
            setErr('No data found...try again');
            return;
        }
        if (all.some(x => x.length !== 3)) {
            setErr('Expected every row to have exactly three columns');
            return;
        }
        if (all.some(x => x[0] !== all[0][0])) {
            setErr('Expected the same value in every row\'s first column');
            return;
        }
        // TODO: add de-dupe  (Within tape itself)...

        setData(all.map(x => {
            return {
                externalIdSource: x[0],
                externalId: x[1],
                name: x[2]
            }
        }))
        setStep(3);
    }

    const confirm = () => {
        // if (!importTable) throw 'missing import table';
        onImport();
        modal.close();
    }

    if (err) {
        return (
            <Box textAlign="center">
                <Alert>
                    <Box mb="1" fg="danger">{ err }</Box>
                    <Button onClick={() => setErr('')}>Ok</Button>
                </Alert>
            </Box>
        )
    }

    if (step === 1) return <Instructions onReady={() => setStep(2) } />
    if (step === 2) return <ImportForm onPaste={onPaste} onBack={() => setStep(1) } />
    if (step === 3) return <Confirmation data={data} onConfirm={confirm} onBack={() => setStep(2) } />
    return <div>invalid step</div>
}

