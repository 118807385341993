import { useEffect, useState } from 'react';
import { SellerMarginData, fetchSellerMargins, upsertSellerMargins, toSellerMarginData } from 'api';
import { useApiFetch, useApiUpdate } from 'hooks';
import { GQL, SellerMarginsDoc } from 'market-dto';

export interface SellerAndMargin extends GQL.Org { readonly margin:number; }

export const useSellerMargins = () => {

    const [ marginData, setMarginData ] = useState<SellerMarginData>();
    const { loading, data:queryData, setData } = useApiFetch(() => fetchSellerMargins(), []);
    const { busy, update} = useApiUpdate(async (d:SellerMarginData) => {
        // convert margin data to margin doc (a dict)
        if (!d) return;
        const dict:SellerMarginsDoc = {
            default: d.defaultMargin
        };
        d.sellers.forEach(x => dict[x.id] = x.margin);
        // update the dict
        const result = await upsertSellerMargins(dict);
        // now, go right back to margin data format
        setData(toSellerMarginData(marginData!.sellers, result!));
        return result;
    });

    useEffect(() => {
        setMarginData(queryData ? JSON.parse(JSON.stringify(queryData)) : undefined);
    }, [queryData])

    return {
        loading,
        update,
        saving:busy,
        data:marginData     
    }
}
