import { LoanField, loanModel } from "market-dto"
import { DropdownItem } from "mui"

type LoanFieldHints<T> = { [k in LoanField]?: T }

export type SimpleConstructEdit = {
	type: "int" | "float" | "dropdown" | "rate"
	readonly readonlyPlaceholder?: string;
	readonly placeholder?: string;
	readonly hidden?: boolean;
	readonly options?: DropdownItem[];
}

// const optionsProvider = <T>(values: any, mapper: (s: T) => string) => {
// 	return (Array.isArray(values))
// 		? values.map(v => ({ label: mapper(v), value: v }))
// 		: []
// }

export const simpleUiHints: LoanFieldHints<SimpleConstructEdit> = {
	originalBalance: {
		type: 'float',
		placeholder: "Loan Amount"
	},
	loanType: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("loanType")
	},
	docType: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("docType")
	},
	fico: { type: 'int', placeholder: 'FICO Score' },
	dti: { type: 'float', placeholder: 'DTI'},
	ltv: { type: 'float', placeholder: 'LTV'},
	aus: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("aus")
	},
	rateType: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("rateType")
	},
	specialtyProgram: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("specialtyProgram")
	},
	propertyType: {
		type: 'dropdown',
		options: loanModel.getEnumerationDropdownOptions("propertyType")
	}
}

export const getSimpleUiHint = (name: LoanField) => {
	return simpleUiHints[name]
}

