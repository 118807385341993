import { FetchResult, ApolloError, ApolloQueryResult } from 'apollo-boost';
import { ApiCallOptions } from './types';
import { toastError } from 'mui';

const ERROR_DICT:{[x:string]:string} = {
    "RequestEntityTooLarge": "Request size is too large!"
}

// ALL api calls run through this now.

// TODO: let's clean up all the unioned types, make an api result type that does this

export const catchCommonErrors = <T>(
    apiCall:Promise<FetchResult<T>|ApolloQueryResult<T>>,
    opts?:ApiCallOptions
):Promise<undefined|FetchResult<T>> => {
    // some calls, we don't care about seeing errors
    if (opts?.doNotCatchCommonErrors) return apiCall;
    // but some calls, we do! let's catch common ones that we wanna TOAST for.
    return apiCall.catch((x:ApolloError) => {

        console.log('THE ERROR BEFORE WE THROW', JSON.stringify(x, null, 4));
        if (x.graphQLErrors.length < 1) {
            throw x; // rethrow, we didn't handle it
        }

        // Sometimes we do not get this code.
        const isInternalServerError = x.graphQLErrors[0].extensions?.code === 'INTERNAL_SERVER_ERROR';
        if (isInternalServerError) {
            // This happens if the jwt you send is no good.
            // do we wanna handle this somehow in the future? maybe.
            throw x;
        }

        const code = x.graphQLErrors[0].extensions?.exception?.body?.code;

        if (ERROR_DICT.hasOwnProperty(code)) {
            toastError(ERROR_DICT[code]);
        } else {
            throw x; // rethrow, we didn't handle it
        }
    }) as Promise<undefined|FetchResult<T>|ApolloQueryResult<T>>
}
