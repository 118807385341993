import React from 'react';
import { Success } from './success';
import { TakeFromFailedToMap } from './take-from-failed-to-map';
import { TakeFromMappedButNotGoodEnough } from './take-from-mapped-but-not-good-enough';
import { WhenInFailedToMap } from './when-in-failed-to-map';
import { WhenInMappedButValNotIn } from './when-in-mapped-but-val-not-in';
import { WhenEnumInDetails } from './types';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const SrcMapDeriveEnumInDetails = ({ details }:Props) => {
    switch (details.result) {
        case "WHEN_IN_FAILED_TO_MAP": return <WhenInFailedToMap details={details} />
        case "WHEN_IN_MAPPED_BUT_VAL_NOT_IN": return <WhenInMappedButValNotIn details={details} />
        case "TAKE_FROM_FAILED_TO_MAP": return <TakeFromFailedToMap details={details} />
        case "TAKE_FROM_MAPPED_BUT_NOT_GOOD_ENOUGH": return <TakeFromMappedButNotGoodEnough details={details} />        
        case "SUCCESS": return <Success details={details} />
    }
   return <>Unhandled result</>
}