import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { _jobs } from './states';

export const watchJobsBusyByName = (jobNames?:string[]):Observable<boolean> => {
    // if ids not sent: if even a single job is active, return true;
    // if ids sent, if a single one is active, return true.
    return _jobs.pipe(
        map(dict => Object.values(dict).some(x => x.active && (!jobNames || jobNames.includes(x.jobName))))
    )
}
