import { gql } from "apollo-boost";
import { GQL } from 'market-dto';
import { QualifierSet } from "raccoon-engine"
import { mutate, stripInternals } from "../../../client";
import { QUALIFIER_RETURNING } from './qaulifier-returning'

const UPDATE = gql`
    mutation updateLoanProgram($set: programQualifier_update_input!, $where: where_programQualifier_exp) {
        update_programQualifier(set: $set, where: $where) {
            affectedRow
            errorMessage
            returning { ${QUALIFIER_RETURNING} }
        }
    }
`;

interface MutateInput {
	readonly set:Partial<QualifierSet>;
	readonly where:GQL.WhereString;
}

interface MutateOutput {
	readonly update_programQualifier:{
		readonly returning:[QualifierSet];
	}
}

export const setProgramQualifier = async (data:Partial<QualifierSet>):Promise<QualifierSet | undefined> => {
	const { id, ...remaining } = data;
	if (!id) throw new Error('Expected id!');
	const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
		set: stripInternals(remaining), where: { id: { _eq: id } }
	})
	return result?.data?.update_programQualifier.returning[0];
}
