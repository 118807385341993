import React from 'react';
import { Input, Text } from 'mui';
import { Col, ColType, ColSubType } from './adj-table.service';

const editableTypes:Array<ColType> = ['branch-val', 'leaf-val', 'val'];
const editableSubTypes:Array<ColSubType> = ['range-left', 'range-right', 'min-val', 'max-val'];

/*
    leaf range, remove border between, center align...similar to branch
    replace edit button with X when editting
    hover over for the edit buttons
    spinner min max times
*/

interface Props {
    readonly col:Col;
    readonly editting:boolean;
    readonly setValue?:(x:any) => any;
}
export const AdjTableColVal = ({ editting, setValue, col }:Props) => {

    // to edit, you must have a type, that type must be editable, you don't need a subType, but if you have one,
    // it must be editable.

    const canEdit = (!col.subType || editableSubTypes.includes(col.subType)) &&
        (col.type && editableTypes.includes(col.type)) && 
        editting;

    if (canEdit) {
        if (col.subType === 'min-val') {
            return (
                <Input
                    width="sm"
                    size="sm"
                    align="right"
                    value={col.val-1}
                    numeric={{ float:true }}
                    setValue={v => setValue && setValue((v*1)+1)}
                />
            )
        } else {
            return (
                <Input
                    width="sm"
                    size="sm"
                    align="right"
                    value={col.val}
                    numeric={{ float:true }}
                    setValue={setValue}
                />
            )
        }
    }
    if (col.subType === 'min-val') {
        return <>
            <Text fg="faded">&gt; </Text>
            { (col.val*1) - 1 }
        </>
    }
    if (typeof col.val === 'boolean') {
        return col.val ? <>True</> : <>False</>;
    }
    return col.val === 100 ? ' ': col.val;
}
