import React from 'react';
import { Text } from 'mui';
import { tapeImporter } from 'services';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
}
export const ColFileName = ({ batch, item }:Props) => {

    const name = item.file.name;
    /*
        .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
        }
    */

    return (
        <Text
            fontSize="sm"
            fg={item.status === 'SUCCESS' ? 'default' : 'faded'}
            ellipsis
        >{ name }</Text>
    )
}
