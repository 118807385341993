import { Batch } from '../types';
import { mergeBatch } from '../merge-batch';
import { mergeBatchItem } from '../merge-batch-item';
import { concat, of } from 'rxjs';
import { tap, first, map, switchMap, filter, last, catchError } from 'rxjs/operators';
import {
    // generateLoans,
    apiSanitizeAndUpdateTape,
    // checkDistinctSeller,
    // checkUnknownSeller,
    // checkIfAggregator,
    apiInsertSheet,
    apiInsertLoanSources,
    apiFinalizeLoans,
    withBatch
} from '../operators'
import { zBatch } from '../z-batch';

export const finalizeLoans$ = zBatch.state$.pipe(
    filter(x => !!x.data),
    first(),
    map(x => x.data as Batch),
    switchMap(b => {
        console.log('here we go');
        const activeItems = b.items.filter(item => !item.skipped && !item.error);
        return concat(...activeItems.map(item => of(item.index).pipe(
            tap(() => console.log('finalize loans')),
            apiSanitizeAndUpdateTape(), // after this, tapeInfo.rowsAndHeaders is sanitized
            apiInsertSheet(),
            apiInsertLoanSources(),
            apiFinalizeLoans(),
            catchError(err => {
                mergeBatchItem(item, { error:true, errorMsg:String(err) });
                return of(item.index);
            }),
        ))).pipe(
            tap(() => console.log('single item finished')),
        )
    }),
    last(),
    tap(() => {
        console.log('all of them finished.')
        mergeBatch({ step: "DONE" });
    }),
    withBatch()
)
