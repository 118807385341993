import { query, gql, GQL } from "../../client"
import { SAMPLE_FIELDS } from './returning';

const QUERY = gql`
    query samples($where: modelSample_bool_exp!) {
        modelSample(where: $where) {
            ${ SAMPLE_FIELDS }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryResult {
    readonly modelSample:GQL.ModelSample[];
}

export const fetchAllModelSamples = async ():Promise<GQL.ModelSample[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {} // this means ALL OF THEM
    })
    return result.data.modelSample;    
}