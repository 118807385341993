// import { RETURNING as LOAN_FIELDS } from '../../loan/returning';

export const FLOW_FIELDS = `
    id
    modelType
    name
    tags
    inputStage
    outputStage
    eligibilityStage
    loanProgramStage
    pricingStage
    recipeName
    deleted
    recentActivity {
        flowId
        id
        executedAt
        sheetId
        runId
        sellerSymbol
        sellerOrgId
    }    
`;

export const RUN_FIELDS = `
    id
    flowId
    sheetId
    rows
    startedAt
    endedAt
    status
    sellerSymbol
    sellerOrgId
`;

// This is when you don't need all the big data from the run (rows)
export const RUN_LIST_FIELDS = `
    id
    flowId
    sheetId
    startedAt
    endedAt
    status
    sellerSymbol
    sellerOrgId
`;
