// ONCE THIS WORKS, IT WILL MOVE TO MUI

// TODO: take from css var for core colr
export const baselineColor = '#000';
export const aboveBaselineColor = '#4BB543';
export const belowBaselineColor = '#D93E40'

export const getColor = (n:number | null, baseline:number, max:number, min:number):string => {
    if (n === null) return 'transparent';
    if (n === baseline) return baselineColor;
    if (n > baseline) {
        const p = n / max * 100;
        return shadeColor(aboveBaselineColor, p);
    } else {
        const p = n / min * 100;
        return shadeColor(belowBaselineColor, p);
    }
}

export const tintColor = (hexColor:string, percent:number):string => {
    let R = parseInt(hexColor.substring(1,3),16);
    let G = parseInt(hexColor.substring(3,5),16);
    let B = parseInt(hexColor.substring(5,7),16);
    const p = percent / 100;
    R = Math.round(R + (255 - R) * p);
    G = Math.round(G + (255 - G) * p);
    B = Math.round(B + (255 - B) * p);
    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  
    const RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    const GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    const BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
    return "#"+RR+GG+BB;
}

export const shadeColor = (hexColor:string, percent:number):string => {
    let R = parseInt(hexColor.substring(1,3),16);
    let G = parseInt(hexColor.substring(3,5),16);
    let B = parseInt(hexColor.substring(5,7),16);
    const p = percent / 100;
    R = Math.round(R * p);
    G = Math.round(G * p);
    B = Math.round(B * p);
    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  
    const RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    const GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    const BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
    return "#"+RR+GG+BB;
}