import React from 'react';
import { WhenEnumInDetails } from './types';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const WhenInMappedButValNotIn = ({ details }:Props) => {
    return (
        <div>
            But the value of "{ details.whenLabel }" was "{ details.whenVal ?? details.whenValRaw }".
        </div>
    )
}