import React, { useEffect, useState } from "react";
import {
    Box,
    Icon,
    TopNavBar,
    TopNavMenu,
    TopNavMenuItemProps,
    PortalPlaceholder,
    IconButton,
    Avatar,
    Text,
    FlexVCenter,
    PulseIcon,
    PopMenu,
    PopMenuRenderItem
} from 'mui';
import { useZState, useObsWithDefault } from "hooks";
import { useNavigate } from "react-router-dom";
import { MStudioLogo, AlphaLogo } from '../logos';
import { DisplayTime } from "./display-time"
import { actMon, user as userService, urls } from 'services';
import { map } from 'rxjs/operators';

interface Props {
    readonly notFixed?:boolean;
}
export const NavBar = ({ notFixed }:Props) => {
    const navigate = useNavigate();
    const { data:session } = useZState(userService.zUser);
    const { user, jwt } = session ?? {};
    const hasActivity = useObsWithDefault(actMon.activeJobs$.pipe(map(arr => arr.length > 0 ? true : false)), false);

    // Temporarily disable eula
    // const fullyLoggedIn = authState === "LOGGED_IN" || authState === 'OLD_EULA' || authState === 'FIRST_TIME';
    const fullyLoggedIn = user && jwt;
    // const showLogout = authState === 'OLD_EULA' || authState === 'FIRST_TIME' || fullyLoggedIn;
    const showLogout = false;

    const displayName =
		user && user.firstName && user.lastName
			? `${user.firstName} ${user.lastName}`
			: "";
    // const isSupport = user && user.orgId && user.orgId === 'ck4xeab5m001txezp7gaggtkx';
    
    // const isJeff = user?.email === 'jholmes@mtrade.com';

    const menuItems:TopNavMenuItemProps[] = [
        { label: "Tapes", to: 'tapes', matchPath: 'tape*' },
        { label: "Import", to: 'import', matchPath: 'import/*' },
        { label: "Config", to: 'config', matchPath: 'config/*' },
        { label: "Trading Desk", to: 'desk', matchPath: 'desk/*' },
        ...urls.isTryOrLocalhost ? [{ label: <FlexVCenter>
            <Box mr="half">Alpha</Box>
            <AlphaLogo fontSize="lg" />
        </FlexVCenter>, to: 'alpha', matchPath: 'alpha/*' }] : [],
        ...user?.email.endsWith('mtrade.com') ? [{ label: "Rules", to: 'rules', matchPath: 'rules/*' }] : [],
        // ...isJeff ? [{ label: "Jeff", to: 'jeff', matchPath: 'jeff/*' }] : []
    ]

    const items:PopMenuRenderItem[] = [
        {
            render: <FlexVCenter>
                <Icon name="user" mr="1" />
                <Text>Account</Text>
            </FlexVCenter>,
            value: 'acct',
            dividerBelow: true
        },
        {
            render: <FlexVCenter>
                <Icon name="log-out" mr="1" />
                <Text>Logout</Text>
            </FlexVCenter>,
            value: 'logout'
        }
    ]
    
    const onMenuSelect = (x:string) => {
        if (x === 'logout') userService.logout();
        if (x === 'acct') navigate("/account");
    }

    const showActivityMon = true;

	return (
        <TopNavBar notFixed={notFixed}>
            <MStudioLogo />
            <PortalPlaceholder id="sign-in-btn-area" />
            { fullyLoggedIn && <TopNavMenu items={menuItems} cy="top-nav-menu" /> }    
            <FlexVCenter justifyContent="flex-end" flex="1" textAlign="right">
                { fullyLoggedIn && (
                    <>
                        <Box
                            fg="faded"
                            borderSides={showActivityMon ? 'right' : undefined}
                            pr="1"
                            mr={showActivityMon ? '1' : '0'}
                            borderWidth="thin"
                            borderColor="alert"
                        ><DisplayTime/></Box>

                        {/* <FlexVCenter
                            fontSize="sm"
                            bg="body"
                            py="half"
                            px="1"
                            borderSides="all"
                            borderColor="inner-card-bg"
                            borderStyle="solid"
                            borderWidth="thick"
                            roundedEdges="all"
                        >
                            <Box fg="normal">PROD</Box>
                            <Box>
                                <Icon name="share" fg="faded" ml="1" />
                            </Box>
                            
                        </FlexVCenter> */}
                        {/* 
                        <FlexVCenter
                            fontSize="sm"
                            bg="body"
                            py="half"
                            px="1"
                            borderSides="all"
                            borderColor="inner-card-bg"
                            borderStyle="solid"
                            borderWidth="thick"
                            roundedEdges="all"
                        >
                            <Box>
                                <Icon name="alert-triangle" fg="danger" mr="1" />
                            </Box>
                            <Box fg="danger">
                                CIT
                            </Box>
                        </FlexVCenter> */}



                        {/* Wrapped in a span with an id to indicate exception to click-outside-auto-close panel */}
                        { showActivityMon && (
                            <span id="toggle-panel-icon" onClick={actMon.togglePanel}>
                                { !hasActivity && <Icon name="activity" mx="1" fg="faded" fgHover="loud" cursor="pointer" /> }
                                { hasActivity && <PulseIcon name="activity" pulseStyle="primary" mx="1" fg="faded" fgHover="loud" cursor="pointer" /> }
                            </span>
                        )}
                        
                        <PopMenu items={items} onSelect={onMenuSelect} zIndex="top-nav-bar">
                            <Avatar
                                src={user?.profilePhoto}
                                name={displayName}
                                mx="1"
                                cursor="pointer"
                            />
                        </PopMenu>

                    </>
                ) }
                { showLogout && <IconButton name="log-out" onClick={() => userService.logout()} /> }
            </FlexVCenter>
            
        </TopNavBar>
    )
}
