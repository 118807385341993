import { ProposalOfferField, ProposalLoanField } from 'api/endpoints/proposal/returning';
import { GQL } from 'market-dto';


export interface HitSearchState {
    readonly searched:string[];
    // readonly proposal:ProposalDetails;
    readonly hitInfo:HitInfo;
}

export const REQUIRED_FIELDS:Array<keyof HitLoan> = [ // ORDER AFFECTS CARD LAYOUT
    "id",
    "investor",
    "servicerSymbol",
    "offeringPrice",
    "cashWindow", // must be 5th if 5 cols (or 4th if 4 cols)
    "loanNumber",
    "currentBalance",
    "commitmentDays",
    "noteRate",
    "productCode" // must be 10th if 5 cols (or 8th if 4 cols)
]

export const OPTIONAL_FIELDS:Array<keyof HitLoan> = [
    "investorProductName",
    "cltv",
    "ltv",
    "dti",
    "uli",
    "miCoverage",
    "state",
    "units",
    "rateType",
    // "productCode",
    "escrowWaived",
    "loanType",
    "loanPurpose",
    "propertyType"
]
OPTIONAL_FIELDS.sort(); // order affects dropdown only

export const DEFAULT_OPT_FIELDS:Array<keyof HitLoan> = [ // ORDER AFFECTS CARD LAYOUT
    // "productCode",
    // "loanPurpose"
]

// Quick run-time checks to ensure you didn't mess it up by having req AND optional or default optional not optional
REQUIRED_FIELDS.forEach((x:any) => {
    if (OPTIONAL_FIELDS.includes(x)) {
        throw new Error('You have a required hit offer field as both required AND optional');
    }
})
DEFAULT_OPT_FIELDS.forEach((x:any) => {
    if (!OPTIONAL_FIELDS.includes(x)) {
        throw new Error('Unknown optional field in default optional fields array!');
    }
})


export type BoolDict = { [x:string]:boolean }
export type CountByNoteRate = { [noteRate:number]:number }
export type CountByProductCodeByRate = { [productCode:string]:CountByNoteRate }


interface HitLoanCashWindow {
    readonly price:number; // found inside PriceTag
    readonly realtimePrice?:number;
    readonly realtimeAsOf?:string;
}

export interface HitLoan extends Omit<ProposalLoanDetails, "priceTag"> {
    readonly cashWindow:HitLoanCashWindow;
}

export interface SummaryGroup {
    readonly label:string;
    readonly investor:string;
    readonly investorProductId:string;
    readonly investorProductName:string;
    readonly minCoupon:number;
    readonly maxCoupon:number;
    readonly sum:number;
    readonly loanCount:number;
}

export interface HitInfo {
    readonly loans:HitLoan[];
    // readonly counts:CountByProductCodeByRate;
    readonly counts:SummaryGroup[];
    readonly hitByLoanId:BoolDict;
    readonly totalCount:number;
    readonly hitCount:number;
    readonly checkedByLoanId:BoolDict;
    readonly sheetSummary:GQL.SheetSummary;
    readonly proposalDetails:ProposalDetails;
}






/*
    These types are tricky. Be careful. The point is, we don't pull every field (there are many)
    See RETURNING. These line up with RETURNING.
*/

export type ProposalLoanDetails = Pick<GQL.Loan, ProposalLoanField> & Pick<GQL.Offer, ProposalOfferField>;
type BasicProposalDetails = Pick<GQL.Proposal, "id"|"status"|"sellerName"|"sellerOrgId"|"sheetId"|"sheetSummary"|"hitLoanIds">;

// This is how we get it from GQL:
export interface RawProposal extends BasicProposalDetails {
    readonly loans:Array<Pick<GQL.Loan, ProposalLoanField>>;
    readonly offers:Array<Pick<GQL.Offer, ProposalOfferField>>; 
}

// This is how we want it:
export interface ProposalDetails extends BasicProposalDetails {
    readonly loans:ProposalLoanDetails[];
}
