import React from 'react';
import { Box, Icon, Button, BoundFrmInput, DropdownItem, BoundFrmDropdown, modal, FlexVCenter, IconButton, Input } from 'mui';
import { rule } from 'services';
import { useZStateList } from 'hooks';

export const NewChecklistModal = () => {

    const { updating, commitItem, editItem:checklist, updateItem:update, data } = useZStateList(rule.zChecklists);
    const models = rule.getAllModels();
    const existingLists = data ?? [];
    if (!models) throw new Error('Expected models to exist in memory already');
    if (!checklist) throw new Error('Expected checklist');
    

    const modelOpts = models.map((x):DropdownItem => {
        return {
            label: x.name,
            value: x.id
        }
    })
    
    const isNew = true;
    const takenRefId = checklist.refId.trim() && existingLists.some(x => x.refId === checklist.refId.trim());
    const canSubmit = checklist.modelId && checklist.name.trim() && checklist.refId.trim() && !takenRefId;
    

    const submit = async () => {
        if (!canSubmit) return;
        await commitItem();
        modal.close();
    }

    const updateTag = (val:string, n:number) => {
        update({
            ...checklist,
            tags: checklist.tags.map((tag, m) => n === m ? val : tag )
        })
    }

    const removeTag = (n:number) => {
        update({
            ...checklist,
            tags: checklist.tags.filter((a, m) => n !== m)
        })
    }

    const addTag = () => {
        update({
            ...checklist,
            tags: checklist.tags.concat('')
        })
    }

    return (
        <Box mt="2">

            <BoundFrmInput
                width="100%"
                label="Name"
                bindTo={[ checklist, update, x => x.name ]}
            />
            <BoundFrmInput
                width="100%"
                label="Description"
                bindTo={[ checklist, update, x => x.description ]}
            />
            <BoundFrmInput
                width="100%"
                label="Ref ID"
                bindTo={[ checklist, update, x => x.refId ]}
            />
            { takenRefId && (
                <FlexVCenter fg="danger" my="half" fontSize="sm">
                    <Icon fg="accent" name="alert-triangle" mr="half" />
                    RefId already in use
                </FlexVCenter>
            ) }
            <BoundFrmDropdown
                width="100%"
                label="Model"
                isMulti={false}
                items={modelOpts}
                isClearable={true}
                bindTo={[ checklist, update, x => x.modelId ]}
            />

            <FlexVCenter ml="1" mb="1">
                <Box fg="faded">Tags</Box>
                <Box flex="1" textAlign="right">
                    <IconButton size="sm" name="plus" onClick={addTag} />
                </Box>
            </FlexVCenter>

            { checklist.tags.map((tag, n) => (
                <FlexVCenter mb="1" key={n}>
                    <Box flex="1" pr="1">
                        <Input
                            value={tag}
                            setValue={val => updateTag(val, n)}
                            placeholder="Tag"
                        />
                    </Box>
                    <Box textAlign="right">
                        <IconButton size="sm" name="trash" onClick={() => removeTag(n)}/>
                    </Box>
                </FlexVCenter>
            )) }

            <Box textAlign="right" mt="2">
                <Button
                    type={canSubmit ? 'default' : 'disabled'}
                    onClick={submit}
                    busy={updating}
                >{ isNew ? 'Add' : 'Update' }</Button>
            </Box>
        </Box>
    )
}