import React, { useMemo, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Flex, useViewport } from 'mui';
import { GQL, ImportModel } from 'market-dto';
import { XlsExportTable } from './xls-export-table';
import { LeftSideList } from './left-side/left-side-list';
import { XlsExportTableTabs } from './xls-export-table-tabs';
import { XlsExportAddNew } from './xls-export-add-new';
import { closePopOver, openPopOver } from './components';
import { HeaderPopOver } from './header-pop-over';
import { PopOverPlaceholder } from './components';
import { toRowsAndFilteredColCfgs } from './to-rows-and-filterd-col-cfgs';

interface Props {
    readonly hideEmptyCols:boolean;
    readonly model:ImportModel;
    readonly flowRun:GQL.FlowRun;
    readonly tabs:GQL.TaskXlsExportTabConfig[];
    readonly tab:GQL.TaskXlsExportTabConfig;
    readonly onSelectTab:(tabCfg:GQL.TaskXlsExportTabConfig) => void;
    readonly tabNames:string[]; // other tabs (for bottom menu)
    readonly readOnly:boolean;
    readonly onChange:(nextTab:GQL.TaskXlsExportTabConfig) => void;
    readonly onAddNewTab:(newTab:GQL.TaskXlsExportTabConfig) => void;
}
export const XlsExportTableContainer = ({
    hideEmptyCols,
    model,
    flowRun,
    tab,
    tabs,
    readOnly,
    onChange,
    onSelectTab,
    onAddNewTab
}:Props) => {

    const { width, height } = useViewport();
    const [ selectedColId, setSelectedColId ] = useState(-1);
    const [ selectedRowNo, setSelectedRowNo ] = useState(-1);
    const [ adding, setAdding ] = useState(false);

    const measureMeRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>();

    const fieldCfgChange = (colCfg:GQL.XlsExportCol) => {
        if (readOnly) return;
        const nextFieldCfgs = tab.colCfgs.map(f => f.colId === colCfg.colId ? colCfg : f);
        onChange({
            ...tab,
            colCfgs: nextFieldCfgs
        })
    }
    
    const colCfgsChange = (colCfgs:GQL.XlsExportCol[]) => {
        if (readOnly) return;
        onChange({
            ...tab,
            colCfgs
        })
    }

    useEffect(() => {
        if (!measureMeRef.current) return;
        if (!innerRef.current) return;
        const dims = measureMeRef.current.getBoundingClientRect();
        innerRef.current.style.bottom = '0';
        innerRef.current.style.top = dims.top + 'px';
        innerRef.current.style.width = dims.width + 'px';
        innerRef.current.style.position = 'fixed';
        innerRef.current.style.visibility = 'visible';
    }, [width, height])

    const onShowFilterPopper = (f:GQL.XlsExportCol, elem:HTMLElement) => {
        if (readOnly) return;
        openPopOver({
            rowIndex:-1, // doesn't apply here...yet
            colIndex:-1,
            elem,
            render: <HeaderPopOver
                colCfg={f}
                flowRun={flowRun}
                model={model}
                onChange={fieldCfgChange}
                onClose={closePopOver}
            />
        })
        setSelectedColId(f.colId);
    }

    const tableRef = useRef<HTMLTableElement>(null);
    const proxyScrollDivRef = useRef<HTMLDivElement>(null);
    const proxyScrollDivInnerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!tableRef.current) return;
        if (!proxyScrollDivRef.current) return;
        if (!proxyScrollDivInnerRef.current) return;

        const { width } = tableRef.current.getBoundingClientRect();
        const { width:scrollerWidth } = proxyScrollDivRef.current.getBoundingClientRect();
        // If we do do not apply scale transform on the proxy div, it'll appear as if it can continue to scroll,
        // because it is much less wide than the full table...so...to make it appear "you're at the end" of both
        // table and proxy div at same time, we must scale the proxy div like this:
        const scale = scrollerWidth / width;
        proxyScrollDivInnerRef.current.style.width = width + 'px';
        // console.log('  table width', width);
        // console.log('  scroller width', scrollerWidth);
        // console.log('  scale', scale);
        proxyScrollDivInnerRef.current.style.transformOrigin = 'center';
        proxyScrollDivInnerRef.current.style.transform = 'scale(' + scale + ')';

        const scrollFn = () => {
            if (!tableRef.current) return;
            if (!proxyScrollDivRef.current) return;
            // once more, we must scale it to table size...but this time, it's a different multiplier!
            const m = tableRef.current.scrollWidth / proxyScrollDivRef.current.scrollWidth;
            const tableParent = tableRef.current.parentNode as HTMLDivElement;
            if (tableParent) {
                tableParent.scrollLeft = proxyScrollDivRef.current.scrollLeft * m;
            }
        }
    
        proxyScrollDivRef.current.addEventListener('scroll', scrollFn);

        return () => {
            if (proxyScrollDivRef.current) proxyScrollDivRef.current.removeEventListener('scroll', scrollFn);
        }
    }, [tableRef.current, proxyScrollDivRef.current, proxyScrollDivInnerRef.current])

    const updateScrollProxy = () => {
        // the table container scrolled from some other mechanism. update scroll proxy.
        if (!tableRef.current) return;
        if (!proxyScrollDivRef.current) return;
        const m = tableRef.current.scrollWidth / proxyScrollDivRef.current.scrollWidth;
        const tableParent = tableRef.current.parentNode as HTMLDivElement;
        if (tableParent) {
            proxyScrollDivRef.current.scrollLeft = tableParent.scrollLeft / m;
        }
    }

    const { colCfgs } = tab;
    const { rows, filteredColCfgs, emptyById } = useMemo(() => {
        return toRowsAndFilteredColCfgs(flowRun, colCfgs, hideEmptyCols);
    }, [flowRun, colCfgs, hideEmptyCols])

    return (
        <>
            <PopOverPlaceholder />
            <div ref={measureMeRef}>
                <Flex
                    flex="1"
                    overflow="hidden"
                    ref={innerRef}
                    style={{ visibility:'hidden' }}
                >
                    { !readOnly && (
                        <div style={{ width:'180px' }} className="left-side-container">
                            <LeftSideList
                                model={model}
                                selectedColId={selectedColId}
                                setSelectedColId={setSelectedColId}
                                colCfgs={colCfgs}
                                onColCfgsChange={colCfgsChange}
                                emptyById={emptyById}
                                hideEmptyCols={hideEmptyCols}
                            />
                        </div>
                    ) }
                    <div className="m-xls-right-side-container">

                        { adding && (
                            <XlsExportAddNew
                                model={model}
                                tabs={tabs}
                                onCancel={() => setAdding(false)}
                                onAddNewTab={x => {
                                    onAddNewTab(x);
                                    setAdding(false);
                                }}
                            />
                        ) }
                        { !adding && (
                            <XlsExportTable
                                ref={tableRef}
                                model={model}
                                readOnly={readOnly}
                                selectedRowNo={selectedRowNo}
                                setSelectedRowNo={setSelectedRowNo}
                                selectedColId={selectedColId}
                                setSelectedColId={setSelectedColId}
                                setShowFilterPopper={onShowFilterPopper}
                                flowRun={flowRun}
                                colCfgs={tab.colCfgs}
                                onColCfgsChange={colCfgsChange}
                                updateScrollProxy={updateScrollProxy}
                                rows={rows}
                                filteredColCfgs={filteredColCfgs}
                            />
                        ) }
                        <div className="xls-tab-and-horizontal-scroll-area">
                            <XlsExportTableTabs
                                readOnly={readOnly}
                                adding={adding}
                                onSelectTab={onSelectTab}
                                tab={tab}
                                tabs={tabs}
                                onAddNewTab={() => setAdding(true)}
                            />
                            <div className="proxy-scroll-div" ref={proxyScrollDivRef}>
                                <div className="proxy-scroll-inner-div" ref={proxyScrollDivInnerRef}>proxy</div>
                            </div>
                        </div>
                    </div>
                </Flex>
            </div>
        </>

    )
}
