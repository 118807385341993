import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';

// For now, we will use Partial<GQLSheet> even though this contains more than sheet_set_input can accept

const UPDATE = gql`
    mutation updateSheet($set: sheet_set_input, $where: sheet_bool_exp!) {
        update_sheet(set: $set, where: $where) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Sheet>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_sheet:{
        readonly returning:[GQL.Sheet]  // expect just one
    }
}

export const updateSheet = async (sheetId:string, data:Partial<GQL.Sheet>) => {
	const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: {
            ...data
        },
        where: {
            id: {
                _eq: sheetId
            }
        }
    })
    return result?.data?.update_sheet.returning[0];
}
