import { format } from 'market-dto';
import { HitLoan } from './types';

export const formatHitField = (fieldId:keyof HitLoan, val:any) => {
    // Most of these come from label service, but, some are unique to this UI.
    switch (fieldId) {
        case 'offeringPrice': return format.toFourDecimals(val);
        case 'investor': return val;
        case 'investorProductName': return val;
        case 'servicerSymbol': return val;
        case 'cashWindow': return format.toFourDecimals(val);
        // case 'realtimeCashWindowAsOf': return formatDistanceToNow(val); // a few minutes ago, etc
        // case 'realtimeCashWindowPrice': return format.toFourDecimals(val);
        // case 'realtimeCashWindowPrice': return format.toCurrencyWithoutCents(val);
        case 'commitmentDays': return val;
        // Everything else, format as we format loan fields
        case 'cltv':
        case 'currentBalance':
        case 'dti':
        case 'escrowWaived':
        case 'loanNumber':
        case 'id':
        case 'loanPurpose':
        case 'loanType':
        case 'ltv':
        case 'miCoverage':
        case 'noteRate':
        case 'occupancy':
        case 'pmiType':
        case 'productCode':
        case 'investorProductId':
        case 'propertyType':
        case 'rateType':
        case 'specialtyProgram':
        case 'state':
        case 'uli':
        case 'units':
            return format.loanValue(fieldId, val);
    }
    throw new Error('field found without formatter');    
}