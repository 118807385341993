import React, { useState } from 'react';
import { FlexVCenter } from '../flex/flex';
import img from './localhost.png';
import { Icon } from '../icon/icon';
import { BasicSpacingProps } from '../box/box';

// FOR DEBUGGING...anything in <Localhost>asdfsadf</Localhost> will appear, you guessed it, if localhost


// So, this is duplicated. that's fine.
// import { urls } from 'services'; // MUI CANNOT DEPEND ON MARKET WEB SERVICES!
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)


interface Props extends BasicSpacingProps {
    readonly children?:React.ReactNode;
}
export const Localhost = ({
    children,
    py = "0",
    px = "1",
    mb = "1",
    ...spacingProps
}:Props) => {
    const [ open, setOpen ] = useState(true);
    if (!isLocalhost || !children) return null;
    if (!open) return null;
    return (
        <FlexVCenter
            display="inline-flex"
            fontSize="sm"
            borderColor="alert"
            borderWidth="thin"
            borderStyle="solid"
            borderSides="all"
            py={py}
            px={px}
            mb={mb}
            roundedEdges="all"
            style={{ backgroundColor: '#347' }}
            {...spacingProps}
        >
            <img src={img} style={{ marginRight:'8px' }}/>
            { children }
            <Icon ml="1" onClick={() => setOpen(false)} name="close" fgHover="link" fg="default" cursor="pointer" />
        </FlexVCenter>
    )
}