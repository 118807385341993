import React, { useState, useEffect } from 'react';
import { Alert, modal, Box, Button } from 'mui';

interface Props {
    readonly crEnv:string;
    readonly sellerName?:string;
    readonly sellerEnv?:string;
    readonly sellerEnvId?:string;
}
export const SetupErrorModal = ({ crEnv, sellerEnv, sellerEnvId, sellerName }:Props) => {

    return (
        <Box mt="2">
            <Alert icon="alert-triangle">

                The seller's environment has not been correctly configured.<br/>

                <Box textAlign="center" mt="2">
                    <Button onClick={modal.close}>Ok</Button>
                </Box>
            </Alert>
       </Box>
    )
}