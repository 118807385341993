import { LoanAndPriceSheet } from './types';

export type DictOfNumbers = {[x:string]:number};

/*
    This stuff is all for d3 to create a chart.
*/

export const getKeys = (loansAndPriceSheets:LoanAndPriceSheet[]) => loansAndPriceSheets.map(x => x.loan.id);


export const getTicks = (loansAndPriceSheets:LoanAndPriceSheet[]) => loansAndPriceSheets[0].rows[0].length - 1;


export const toChartData = (loansAndPriceSheets:LoanAndPriceSheet[], rowNo:number) => {
    // We need the data in a certain way for d3 charts.
    const result:DictOfNumbers[] = [];
    const xSums:number[] = [];

    // two steps. first, find min val for each loan
    const minByLoan:{[x:string]:number} = {};
    loansAndPriceSheets.forEach(item => {
        minByLoan[item.loan.id] = Math.min(...item.rows[rowNo].filter(x => x !== null) as number[]);
    })

    loansAndPriceSheets[0].rows[rowNo].forEach((_, xIndex) => {
        const obj:any = { _xAxisVal: xIndex };
        xSums[xIndex] = 0;
        loansAndPriceSheets.forEach(item => {
            // const val = (item.rows[rowNo][xIndex] ?? 0) / 100 * item.loan.currentBalance;
            const rawVal:number|null = item.rows[rowNo][xIndex];

            const minBps = minByLoan[item.loan.id]
            const val = rawVal === null ? 0 : ((rawVal-minBps) / 100) * item.loan.currentBalance;

            // find min bps per row, subtract that, per loan.
            // so each loan gets something special subtracted from it.
            // 105, 106, 101 <-- would get 101 subtracted from all.
            // then, take that, divide by 100, * upb.


            
            // const val = rawVal === null ? 0 : rawVal - 106;
            // const val = (item.rows[rowNo][xIndex] ?? 0);
            xSums[xIndex] += val;
            obj[item.loan.id] = val;
        })
        result.push(obj);
    })
    console.log('RESULT', result);
    return {
        chartArr: result,
        maxVal: Math.max(...xSums)
    }
}
