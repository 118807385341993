import React from 'react';
import { parseISO, format as fnsFormat } from 'date-fns'

// Common data formats here. Mainly numbers, but perhaps dates too!
// TODO: merge this with a common service.
// That way, both can take from same underlying formatting


type AsType =
    "currency" |
    "currency_with_cents" |
    "2_decimals" |
    "3_decimals" |
    "4_decimals" |
    "short_date" |
    "date_and_time" |
    "date_and_time_for_logs";

const currencyWithoutCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
    
})

const currencyWithCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

// Lots of financial stuff requires 3 or 4 numbers after decimal point
const twoDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 })
const threeDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 3 })
const fourDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 4 })

const shortDate = (x:any) => {
    const dt = new Date(x);
    return dt.toLocaleDateString();
}

const shortDateAndTime = (x:any) => {
    // as long as the server returns time zone info, this is good, right? that time zone stuff in the ui makes me nervous
    const dt = new Date(x);
    return dt.toLocaleString();
}



const logLikeDateAndTime = (x:any) => {
    const dt = typeof x === 'string' ? parseISO(x) : x;
    return fnsFormat(dt, "dd/MM/yyyy HH:mm:ss");
}

const format = (x:any, as:AsType) => {
    switch (as) {
        case "currency": return currencyWithoutCents.format(x);
        case "currency_with_cents": return currencyWithCents.format(x);
        case "2_decimals": return twoDecimals.format(x);
        case "3_decimals": return threeDecimals.format(x);
        case "4_decimals": return fourDecimals.format(x);
        case "short_date": return shortDate(x);
        case "date_and_time": return shortDateAndTime(x);
        case "date_and_time_for_logs": return logLikeDateAndTime(x);
    }
}

interface Props {
    readonly children:any;
    readonly as:AsType;
}
export const Format = ({ children, as }:Props) => <>{format(children, as)}</>

interface FormatAndPadProps {
    readonly children:any;
    readonly as:AsType;
    readonly padEnd:number;
}
export const FormatAndPadEnd = ({ children, as, padEnd }:FormatAndPadProps) => <>{format(children, as).padEnd(padEnd)}</>

