import React from 'react';
import { Box, BasicSpacingProps } from '../box/box';

// consider two sizes, and have h1,h2,h3,h4,h5 render same as these.

interface Props extends BasicSpacingProps{
    readonly children?:any;
}
export const Heading = ({ children, ...props }:Props) => {
    // if they specify my, mb or m ... we should override mb!
    const mb = props.mb || props.my || props.m || "2"; // default to two margin bottom
    return (
        <Box fg="loud" mb={mb} fontSize="lg" {...props}>{ children }</Box>
    )
}