import { zBatch } from './z-batch';
import { BatchItem } from './types';

export const mergeBatchItem = (item:BatchItem, partialUpdate:Partial<BatchItem>) => {
    if (partialUpdate.status) console.log('status', partialUpdate.status);
    zBatch.update(batch =>  {
        return {
            ...batch,
            items: batch.items.map(x => x.index !== item.index ? x : {
                ...x,
                ...partialUpdate
            })
        }
    })
}

// export const mergeBatchItemByIndex = (index:number, partialUpdate:Partial<BatchItem>) => {
//     zBatch.update(batch =>  {
//         return {
//             ...batch,
//             items: batch.items.map(x => x.index !== index ? x : {
//                 ...x,
//                 ...partialUpdate
//             })
//         }
//     })
// }
