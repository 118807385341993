import { useState, useEffect } from 'react';
import { watchJobsBusyByName } from './jobs';
import { useSafeState } from 'hooks';

export const useJobBusy = (jobName:string) => {

    const [ busy, setBusy ] = useSafeState(false);

    useEffect(() => {
        if (!jobName) return;
        const sub = watchJobsBusyByName([jobName]).subscribe(jobBusy => setBusy(jobBusy));
        return (() => sub.unsubscribe());
    }, [jobName])

    return busy;
}

export const useJobsBusy = (jobNames:string[]) => {

    const [ busy, setBusy ] = useSafeState(false);

    useEffect(() => {
        const sub = watchJobsBusyByName(jobNames).subscribe(jobBusy => setBusy(jobBusy));
        return (() => {
            sub.unsubscribe();
        })
    }, [jobNames])

    return busy;
}