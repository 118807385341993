import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';

interface MutateOutput {
    readonly insert_user: {
        readonly returning:[GQL.User];
    }
}

interface MutateInput {
    readonly objects:[Partial<GQL.User>];
}

const INSERT = gql`
    mutation insertUser($objects: [user_insert_input!]) {
        insert_user(objects: $objects) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

export const insertUser = async (newUser:Partial<GQL.User>):Promise<GQL.User | undefined> => {
	const savedSheet = await mutate<MutateInput, MutateOutput>(INSERT, { objects: [newUser] });
    return savedSheet?.data?.insert_user.returning[0];
}