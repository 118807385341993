import { Batch } from '../types';
import { mergeBatch } from '../merge-batch';
import { forkJoin, of, concat } from 'rxjs';
import { tap, first, map, switchMap, filter, last } from 'rxjs/operators';
import {
    apiInsertTape,
    withBatch
} from '../operators'
import { zBatch } from '../z-batch';
import { findTemplates$ } from './find-templates$';

export const tapeInsert$ = zBatch.state$.pipe(
    filter(x => x.data ? true : false),
    first(),
    map(x => x.data as Batch),
    switchMap(b => {
        const activeItems = b.items.filter(item => !item.skipped && !item.error);
        // forkJoin(items) for parallel, concat(...items) for serial
        return concat(...activeItems.map(item => of(item.index).pipe(
            apiInsertTape(),
        ))).pipe(
            last(), // wait for concat to finish
            switchMap(() => {
                mergeBatch({ step: "SEARCH_TEMPLATES" });
                return findTemplates$
            })
        )
    })    
)
