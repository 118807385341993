import { BatchItem } from '../types';
import { mergeBatchItem } from '../merge-batch-item';
import { mergeBatch } from '../merge-batch';
import { RowsAndHeaders } from 'market-dto';

export const assumeFirstTab = (item:BatchItem) => {

    // This is just for testing.

    const { tabNameAndRows, model } = item;
    if (!tabNameAndRows) throw new Error('Expected tabNameAndRows');

    // Assume 1st tab.
    // Assume 1st row is headers.
    // Assume all else is data.
    const rowsAndHeaders:RowsAndHeaders = {
        rows: tabNameAndRows[0].rows.slice(1),
        headers: tabNameAndRows[0].rows[0],
    }
    // Map with global to get hashes to find overrides!
    const defaultMapResult = model.tryMap({ rowsAndHeaders });
    mergeBatchItem(item, {
        status: "PARSE_SUCCESS",
        rowsAndHeaders,
        loanCount: rowsAndHeaders.rows.length,
        defaultMapResult
    })
    // What you'd actually do is make sure all are ready and then do this:
    mergeBatch({
        step: "TAPE_INSERT"
    })
}