import React, { useState } from 'react';
import { Box, Input, Button, BoundFrmInput, FrmInput, modal } from 'mui';
import { useApiUpdate, useZStateList } from 'hooks';
import { rule } from 'services';

export const ModelModal = () => {

    const { updating, updateItem, editItem, commitItem, isNew } = useZStateList(rule.zModels);
    if (!editItem) throw new Error('Expected editItem');

    const canSubmit = editItem.name.trim() !== '';

    const submit = async () => {
        if (!canSubmit) return;
        await commitItem();
        modal.close();
    }

    return (
        <Box mt="1">
            <BoundFrmInput
                width="100%"
                label="Name"
                bindTo={[ editItem, updateItem, x => x.name ]}
            />
            <BoundFrmInput
                width="100%"
                label="Description"
                bindTo={[ editItem, updateItem, x => x.description ]}
                onEnter={submit}
            />
            <Box textAlign="right" mt="2">
                <Button
                    busy={updating}
                    onClick={submit}
                    type={canSubmit ? 'default' : 'disabled'}
                >{ isNew ? 'Add' : 'Update' }</Button>
            </Box>

        </Box>
    )
}