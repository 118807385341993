import React, { CSSProperties } from 'react';
import { Box, BoxProps } from '../box/box';

/* When you want a full screen image background, and don't wanna think about it. */

interface Props extends BoxProps {
	readonly bgImageSrc:string;
}

export const BgImageBox = ({ children, bgImageSrc, ...rest }:Props) => {

	const styleObj:CSSProperties = {
		backgroundImage: bgImageSrc,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	}

	return (
		<Box style={styleObj} height="main-section" pt="top-nav" {...rest}>{children}</Box>
	);
}
