import React from 'react';
import { Box, BasicSpacingProps } from '../box/box';
import { Flex } from '../flex/flex';
import { BasicMenuItem, RenderMenuItem } from '../../types';


interface TabItemProps {
    readonly item:BasicMenuItem;
    readonly active:boolean;
    readonly onClick:(x:any) => any;
}
const TabItem = ({ item, active, onClick }:TabItemProps) => {
    if (active) {
        return (
            <Box
                fg="loud"
                px="2"
                pb="1"
                borderColor="menu-active"
                borderSides="bottom"
                borderWidth="thin"
                transition="default"
            >{ item.label }</Box>
        );
    }
    if (item.disabled) {
        return (
            <Box
                fg="really-faded"
                // fgHover="faded"
                px="2"
                pb="1"
                cursor="not-allowed"
                borderColor="menu"
                borderSides="bottom"
                borderWidth="thin"
                // borderColorHover="menu-hover"
                transition="default"
                // onClick={() => onClick(item.value)}
            >{ item.label }</Box>
        )    
    }
    return (
        <Box
            fg="faded"
            fgHover="link"
            px="2"
            pb="1"
            cursor="pointer"
            borderColor="menu"
            borderSides="bottom"
            borderWidth="thin"
            borderColorHover="menu-hover"
            transition="default"
            onClick={() => onClick(item.value)}
        >{ item.label }</Box>
    )
     
}

const TabItemNoBorder = ({ item, active, onClick }:TabItemProps) => {
    if (active) {
        return (
            <Box
                fg="loud"
                px="2"
            >{ item.label }</Box>
        );
    }
    return (
        <Box
            fg="faded"
            fgHover="link"
            px="2"
            cursor="pointer"
            transition="default"
            onClick={() => onClick(item.value)}
        >{ item.label }</Box>
    )
     
}

interface Props extends BasicSpacingProps {
    readonly items:BasicMenuItem[];
    readonly selected?:any; /* the value selected */
    readonly onSelect:(value:any) => any;
}

export const Tabs = ({ items, selected, onSelect, ...rest }:Props) => {
    return (
        <Flex {...rest}>
            { items.map((item, n) => (
                <TabItem key={n} active={item.value === selected} item={item} onClick={onSelect} />
            ))}
        </Flex>
    )
}

export const TabsWithoutBorders = ({ items, selected, onSelect, ...rest }:Props) => {
    return (
        <Flex {...rest}>
            { items.map((item, n) => (
                <TabItemNoBorder key={n} active={item.value === selected} item={item} onClick={onSelect} />
            ))}
        </Flex>
    )
}

// Another type of tabs: RenderTabs, where a component is rendered instead of just displaying a label.
// This one also vertically centers and does a bg color for the selected.

interface RenderTabItemProps {
    readonly item:RenderMenuItem<any>;
    readonly active:boolean;
    readonly onClick:(x:any) => any;
}
const RenderTabItem = ({ item, active, onClick }:RenderTabItemProps) => {

    if (active) {
        return (
            <Flex
                fg="loud"
                px="2"
                py="1"

                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                // bg="menu-item-active"
                textAlign="center"
                
                borderColor="menu-active"
                borderSides="bottom"
                borderWidth="thin"
                transition="default"
            >{ item.render }</Flex>
        );
    }
    return (
        <Flex
            fg="faded"
            fgHover="link"
            px="2"
            py="1"

            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"


            cursor="pointer"
            borderColor="menu"
            borderSides="bottom"
            borderWidth="thin"
            borderColorHover="menu-hover"
            transition="default"
            onClick={() => onClick(item.value)}
        >{ item.render }</Flex>
    )
}

// TODO: remove this. as with nav-tabs and table, make label accept string OR React.ReactNode
interface RenderTabsProps extends BasicSpacingProps {
    readonly items:Array<RenderMenuItem<any>>;
    readonly selectedIndex:number; /* the INDEX selected */
    readonly onSelect:(value:any) => any;
}

export const RenderTabs = ({ items, selectedIndex, onSelect, ...rest }:RenderTabsProps) => {
    return (
        <Flex {...rest}>
            { items.map((item, n) => (
                <RenderTabItem
                    key={n}
                    active={n === selectedIndex}
                    item={item}
                    onClick={item.onClick ?? onSelect}
                />
            )) }
        </Flex>
    )

}