import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap, delay } from 'rxjs/operators';
import { preprocessTape } from '../preprocess-tape';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { RowsAndHeaders } from 'market-dto';

export const parseTape = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(), // enforces pause/step
        updateStatus("PARSING_TAPE"),
        switchMap(item => {
            console.log('here we go! password', item.filePassword);

            // THIS IS WHERE I AM.

            return from(preprocessTape(item.model, item.file, item.filePassword)).pipe(
                tap(preprocessResult => {
                    if (typeof preprocessResult === 'string') {
                        if (preprocessResult === "PASSWORD_PROTECTED") {
                            // We currently cannot handle this :(
                            mergeBatchItem(item, { status: "PARSE_NEEDS_PASSWORD" });
                        } else {
                            mergeBatchItem(item, { status: "PARSE_FAILURE" });
                        }
                    } else {

                        const { askAboutHeaders, model } = item;
                        const { tabNameAndRows, buf } = preprocessResult;

                        if (askAboutHeaders) {
                            // Need input from the user.
                            mergeBatchItem(item, {
                                status: "PARSE_NEEDS_TABS_HEADERS_ROWS_SPECIFIED",
                                buf,
                                tabNameAndRows
                            })
                        } else {
                            // Assume 1st tab.
                            // Assume 1st row is headers.
                            // Assume all else is data.
                            const rowsAndHeaders:RowsAndHeaders = {
                                rows: tabNameAndRows[0].rows.slice(1),
                                headers: tabNameAndRows[0].rows[0],
                            }
                            // Map with global to get hashes to find overrides!
                            const defaultMapResult = model.tryMap({ rowsAndHeaders });
                            mergeBatchItem(item, {
                                status: "PARSE_SUCCESS",
                                buf,
                                tabNameAndRows,
                                rowsAndHeaders,
                                loanCount: rowsAndHeaders.rows.length,
                                defaultMapResult
                            })
                        }
                    }
                }),
                map(x => item.index)
            )
        })
    )
}