import { mutate, gql } from "../../client";

// This jwt is well-formed but contains garbage and is expired.
// const badJwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJoYXIiLCJpYXQiOjE0NDc4NjgyODksImV4cCI6MTQ0Nzg2ODI4OSwiYXVkIjoiIiwic3ViIjoiIn0.mt2jdDV12eyx5rL344FbpIKyDuuHBsUToEfM08BWRAU";

export interface Jwts {
    readonly jwt:string;
    readonly firebaseJwt:string;
}

const RENEW_JWT = gql`
    mutation {
        renewJwt
    }
`;

const RENEW_JWT_ONE_MINUTE = gql`
    mutation {
        renewJwt(oneMinute:true)
    }
`;

interface MutateOutput {
    readonly renewJwt:Jwts;
}

interface RenewJwtProps {
    readonly failOnPurpose?:boolean;
    readonly oneMinuteOnly?:boolean;
}

// This does double-duty. It renews the jwt AND we use it to check if curr jwt is valid.
// (This is the FIRST thing we call if user already had a jwt in local storage and returned to website)
export const renewJwt = async (opts?:RenewJwtProps):Promise<Jwts|undefined> => {
    try {
        const mutation = opts?.oneMinuteOnly ? RENEW_JWT_ONE_MINUTE : RENEW_JWT;
        const result = await mutate<any, MutateOutput>(mutation, {}, {
            useThisJwtInstead: opts?.failOnPurpose ? 'gibberish' : undefined
        })
        return result?.data?.renewJwt;
    } catch (err) {
        // console.log('THE ERROR AFTER RETHROWN:', JSON.stringify(err, null, 4));
        if (err.message && typeof err.message === 'string') {
            const msg = (err.message as string).toLowerCase();
            if (msg.indexOf('authentication required') !== -1) {
                return; // This is fine. Swallow the error, return undefined...means we are NOT authenticated.
            }
        }
        // Re-throw it. Cuz, it's something other than jwt expired!
        // throw err;
        // we will log you out on ANY renewJwt or jwt check failure!
    }
}

export const generateApiJwt = async (apiId: string) => {
    const mutationCall = gql(` mutation { generateApiJwt(apiId: "${apiId}") } `);
    try {
        const result = await mutate<any, any>(mutationCall, {})
        return result?.data?.generateApiJwt;
    } catch (err) {
        throw err;
    }
}
