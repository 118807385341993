import React, { useState, useEffect } from 'react';
import { Box, Button, TableLikeGrid, FlexVCenter, Icon, openConfirmModal, Text, Input, Dropdown, DropdownItem } from 'mui';
import { ExpContext, ExpressionEditorState, ExpEvalStatus, getInitExpEditorState, ExpWarning, ExpContextField } from 'exp';
import { FlatSchemaSimpleInput } from '../../components/flat-schema-simple-input';
import { ConclusionItem } from './conclusion-item';
import { ConclusionDict } from './types';

interface Props {
    readonly label:string;
    readonly conclusion:ConclusionDict;
    readonly setConclusion:(x:ConclusionDict) => void;
    readonly expCtx:ExpContext;
}
export const ConclusionSection = ({ label, conclusion, setConclusion, expCtx }:Props) => {

    const [ adding, setAdding ] = useState<boolean>(false);
    const [ newField, setNewField ] = useState<ExpContextField>();
    // const [ newFieldId, setNewFieldId ] = useState<string>('');
    const [ newValue, setNewValue ] = useState<string|boolean|number|null>(null);

    // readonly editItem?:CheckItem;
    // readonly expCtx:ExpContext;

    const selectFieldId = (fieldId:string) => {
        
        const nextField = expCtx.fieldByName[fieldId];
        if (nextField.schema.type === "boolean") {
            setNewValue(true);
        } else {
            setNewValue(null);
        }
        setNewField(nextField);
    }

    const clickPlus = () => {
        setNewValue(null);
        setNewField(undefined);
        setAdding(true);
    }

    const conclusionOptions = expCtx.fieldList.filter(x => x.id.startsWith('_')).map((x):DropdownItem => {
        return {
            label: x.id,
            value: x.id
        }
    })

    const remove = (id:string) => {
        const { [id]:removeMe, ...next } = conclusion;
        setConclusion(next);
    }

    const add = () => {
        if (newValue === null) return;
        if (!newField) return;
        const next = {
            ...conclusion,
            [newField?.id]: newValue
        }
        setConclusion(next);
        setAdding(false);
    }

    const canAdd = newValue !== null && newField && (newField.schema.type === "boolean" || String(newValue).trim());

    // REMEMBER: you are in a CSS grid! so, four divs per row (even if that means empty boxes)
    const arr = Object.entries(conclusion);

    return (
        <>
            <Box style={{ alignSelf:'flex-start'}}>
                <FlexVCenter fg="faded" fontSize="sm" justifyContent="flex-end">
                    { !adding && (
                        <>
                            <Icon
                                name="plus"
                                mr="half"
                                fg="faded"
                                fgHover="link"
                                cursor="pointer"
                                onClick={clickPlus}
                            />
                            
                        </>
                    ) }
                    { label }
                </FlexVCenter>
            </Box>
            <Box>
                { arr.length === 0 && !adding && <Box fg="really-faded" fontSize="sm">None</Box> }
                { arr.map(([id, val]) => (
                    <ConclusionItem
                        key={id}
                        id={id}
                        val={val}
                        expCtx={expCtx}
                        onRemove={() => remove(id)}
                    />
                )) }
            </Box>
            <Box></Box>
            <Box></Box>

            { adding && (
                <>
                    <FlexVCenter fg="accent" fontSize="sm" justifyContent="flex-end">
                        New
                        <Icon name="arrow-right" ml="half" fg="faded" />
                    </FlexVCenter>
                    <FlexVCenter>
                        <Box mr="half" fontSize="sm">
                            <Dropdown
                                placeholder="Conclusion Field"
                                width="frm-sm"
                                size="sm"
                                items={conclusionOptions}
                                value={conclusionOptions.find(x => x.value === newField?.id)}
                                setValue={x => selectFieldId(x)}
                            />
                        </Box>
                        <FlexVCenter ml="half">
                            { newField &&  (
                                <>
                                    { newField.schema.type === "boolean" && (
                                        <Box fg="default" mr="1">
                                            { newValue ? 'Yes' : 'No'}
                                        </Box>
                                    ) }
                                    <FlatSchemaSimpleInput
                                        field={newField.schema}
                                        value={newValue ?? ''}
                                        setValue={setNewValue}
                                        updateParentAlways
                                    />
                                </>
                            ) }
                        </FlexVCenter>
                        {/* <textarea readOnly value={JSON.stringify(newValue, null, 4)}></textarea>  */}
                        <Button
                            ml={newField ? '1' : '0'}
                            size="xs"
                            type="link"
                            onClick={() => setAdding(false)}
                        >Cancel</Button>
                        { newField && (
                            <Button
                                ml="1"
                                size="xs"
                                type={canAdd ? 'default' : 'disabled'}
                                onClick={add}
                            >Add</Button>
                        ) }
                    </FlexVCenter>
                    <Box></Box>
                    <Box></Box>
                </>
            ) }
        </>
    )
}