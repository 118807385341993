import React from 'react';
import { Box, FlexVCenter, Button, Localhost, modal, Alert } from 'mui';
import { FileDropArea } from 'components';
import { tapeImporter, urls, sellers } from 'services';
import { useZState, useStickyStateWithDefault, useObsLoad } from 'hooks';
import { BatchView } from './batch-view';
import { DebugArea } from './debug-area';
import { tap } from 'rxjs/operators';
import { ImportModel } from 'market-dto';

interface Props {
	readonly model:ImportModel;
	readonly onTapeImportComplete?:(batch:tapeImporter.Batch|undefined) => void;
	readonly singleTapesOnly?:boolean;
    readonly askAboutHeaders?:boolean;
}
export const TapeImporter = ({ onTapeImportComplete, singleTapesOnly, model, askAboutHeaders }:Props) => {

    if (askAboutHeaders && !singleTapesOnly) {
        throw new Error('For now, this is not an option. asking about headerse requires single tape drop only. for now.');
    }

	// We are subscribing (via hooks) to two things.
	// The batch itself, directly, and, the process observable (which orchestrates all the importing stuff)
	const { data:batch } = useZState(tapeImporter.zBatch);
	const { load:process, busy:processing, cancel } = useObsLoad(tapeImporter.process$.pipe(
		tap(b => {
			if (b.step === 'DONE') {
				if (onTapeImportComplete) onTapeImportComplete(batch);
			}
		})
	))

	const [ showImportDebugArea ] = useStickyStateWithDefault("debug-import", false);

	const onFilesSelected = (files:File[]) => {
		const sellerOrgs = sellers.zSellers.get();
		if (!sellerOrgs) throw new Error('Expected sellers');
		tapeImporter.addBatch(model, sellerOrgs, files, askAboutHeaders ? true : false);
		process();
	}

	const onCancel = () => {
		tapeImporter.flagAsCanceled();
		cancel();
	}

	const showBatch = batch && batch.items.length > 0 && !batch.canceled;
	const showDebugArea = urls.isLocalhost && showImportDebugArea;

	return (
		<>
			{ !showBatch && (
				<FileDropArea
					cy="tape-drop"
					onDrop={onFilesSelected}
					accept=".xls,.xlsx"
					multi={singleTapesOnly ? false : true}
				/>
			) }
			{ showBatch && batch && (
				<Box>
					<BatchView
						batch={batch}
						onCancel={onCancel}
						processing={processing}
						nextStep={process}
					/>

					{ showDebugArea && (
						<DebugArea
							advanceStep={process}
							batch={batch}
							processing={processing}
						/>
					) }
				</Box>
			) }
		</>
	)
}
