import React, { useState } from 'react';
import { GQL } from 'market-dto';
import { Box, Text, LabelValueGrid, Icon, usePageSortTable, SortableTableHeader, TablePageButtons } from 'mui';
import { labels } from 'market-dto';

interface FlatLoanItem {
    readonly loanFileID?:number;
    readonly crLoanNumber?:string;
    readonly loanNumber:string;
    readonly loanId:string;
    readonly created:boolean;
    readonly errHint?:string[];
    readonly errMsg?:string;
}

interface TradeTableProps {
    readonly trade:GQL.Trade; // a superset of GQL.Trade
}
export const TradeTable = ({ trade }:TradeTableProps) => {

    const { loans } = trade;
    if (!loans) throw new Error('Expected loans array on trade');

    const [ expandedDict, setExpandedDict ] = useState<{[x:string]:boolean}>({});

    const flatLoans = loans.map((loan):FlatLoanItem =>  {
        return {
            loanFileID: loan.info?.loanFileID,
            crLoanNumber: loan.info?.crLoanNumber,
            created: loan.created,
            loanId: loan.loanId,
            loanNumber: loan.loanNumber,
            errHint: loan.info?.errorHint,
            errMsg: loan.info?.errorMessage
        }
    })

    const {
        asc,
        sortedBy,
        sortBy,
        sortedRows,
        page,
        setPage,
        totalPages,
        buttons,
        pageSortState:ps
    } = usePageSortTable<FlatLoanItem, keyof FlatLoanItem>({
        rows: flatLoans,
        valFn: (row, colId) => row[colId],
        keyFn: row => row.loanId,
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const toggle = (id:string) => {
        setExpandedDict({
            ...expandedDict,
            [id]: !expandedDict[id]
        })
    }

    // <Icon name="close" fg="danger" cursor/>
    return (
        <>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="loanId" render="Loan ID" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="loanNumber" render="Loan Number" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="loanFileID" render="Loan File Id" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="crLoanNumber" render="Clean Room Loan Number" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="created" render="Result" align="right" />
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((loan, n) => {
                        return (
                            <React.Fragment key={loan.loanId}>
                                <tr key={n}>
                                    <td>{ loan.loanId }</td>
                                    <td>{ loan.loanNumber }</td>
                                    <td>{ loan.loanFileID }</td>
                                    <td>{ loan.crLoanNumber }</td>
                                    <td className="right">
                                        { loan.created && <Icon name="check" fg="success" inline /> }
                                        { !loan.created && (
                                            <Text fg="danger" onClick={() => toggle(loan.loanId)}>Error</Text>
                                        ) }
                                    </td>
                                </tr>
                                { expandedDict[loan.loanId] && (
                                    <tr className="expanded-tr">
                                        <td colSpan={5}>
                                            <Box monoSmall fg="default">
                                                <>
                                                    <Box my="2" mono style={{ whiteSpace: 'pre-line' }}>{ loan.errMsg}</Box>
                                                    { loan.errHint?.map((msg, n) => <Box key={n} monoSmall style={{ whiteSpace: 'pre-line' }}>{ msg } </Box>) }
                                                </>
                                            </Box>
                                        </td>
                                    </tr>
                                ) }
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>

            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}