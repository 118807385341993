import { FlatJSONSchema } from 'market-dto';
import { ExpContextField, ExpFieldWithValue } from './exp-types';
import { toFieldsFromSchema } from './to-fields-from-schema';


export const toFieldsWithValues = (schema:FlatJSONSchema, sampleData:any):ExpFieldWithValue[] => {
    const fields = toFieldsFromSchema(schema);
    return fields.map((f):ExpFieldWithValue => {
        return {
            ...f,
            type: f.schema.enum ? 'enum' : f.schema.type,
            value: sampleData[f.id]
        }
    })
}