import React, { useState, useRef } from 'react';
import { DropdownItem } from 'mui';
import { GQL, ImportModel, FormatterId } from 'market-dto';

interface Props {
    readonly model:ImportModel;
    readonly formatterOpts:DropdownItem[];
    readonly colCfg:GQL.XlsExportMappedCol;
    readonly onChange:(x:GQL.XlsExportMappedCol) => void;
}
export const FormatNonEnum = ({ model, formatterOpts, colCfg, onChange }:Props) => {

    const { fieldId } = colCfg;
    const defaultFieldFormatterId = model.getFieldFormatterId(fieldId);
    const formatterId = colCfg.formatterId ?? defaultFieldFormatterId;

    return (
        <div>
            {/* <textarea readOnly value={JSON.stringify(fieldCfg, null, 4)}></textarea>
            <textarea readOnly value={theDictionary[fieldId].formatterId}></textarea>
            <textarea readOnly value={formatterId}></textarea> */}
            <select value={formatterId} onChange={x => {
                const val = x.target.value;
                if (val === defaultFieldFormatterId) {
                    const { formatterId:removeMe, ...nextFieldCfg } = colCfg;
                    onChange(nextFieldCfg);
                } else {
                    onChange({
                        ...colCfg,
                        formatterId: val as FormatterId
                    })
                }
            }}>
                { formatterOpts.map(x => <option key={x.value} value={x.value}>{ x.label }</option> ) }
            </select>            
        </div>
    )        
}