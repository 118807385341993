import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { Box } from '../box/box';
import { BasicMenuItem, RenderMenuItem, ZIndex } from '../../types';
import { IntoPortal } from '../portal';
import { PopMenuItem } from './pop-menu-item';

// Strange to not use "useRef", but, if you do that, you get a weird delay.
// All the react-popper documentation uses useState as well for these dom elements. really weird, but, oh well.


// this is going to need some behaviorsubjhect state!
// so that it can be closed by outside of props!

export interface PopMenuRenderItem extends RenderMenuItem<any> {
    readonly dividerBelow?:boolean;
}

type PopState = "opening" | "closing" | "open" | "closed";

interface Props {
    readonly children:React.ReactNode;
    readonly items:PopMenuRenderItem[];
    readonly onSelect?:(val:any) => void;
    readonly zIndex?:ZIndex;
}
export const PopMenu = ({ items, onSelect, children, zIndex }:Props) => {

    const [ hoverDiv, setHoverDiv ] = useState<HTMLDivElement | null>(null);
    const [ refElem, setRefElem ] = useState<HTMLSpanElement | null>(null);
    const [ state, setState ] = useState<PopState>('closed');
    
    const handleClickOutside = (e:any) => {
        if (!hoverDiv) return;
        if (state === 'closed' || state === 'closing') return;
        if (!hoverDiv.contains(e.target)) {
            setState('closing');
        }
    }

    useEffect(() => {
        if (state !== 'closed') {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [state, hoverDiv]);

    const { styles, attributes } = usePopper(
        refElem,
        hoverDiv,
        {
            placement: "bottom",
            modifiers: [
                {
                    name: "offset",
                    enabled: state !== 'closed',
                    options: {
                        offset: [0, 10]
                    }
                }
            ]
        }
    )

    const toggle = () => {
        // if toggle during transition, ignore
        if (state === 'open') {
            setState('closing');
        } else if (state === 'closed') {
            setState('opening');
        }
    }

    const animEnd = () => {
        if (state === 'closing') {
            setState('closed');
        } else if (state === 'opening') {
            setState('open');
        }
    }

    const clk = (item:RenderMenuItem<any>) => {
        if (onSelect) onSelect(item.value);
        setState('closed');
    }

    return <>
        <span ref={setRefElem} onClick={toggle}>{ children }</span>
        <IntoPortal id="$mui-popper">
            { (state !== 'closed') && (
                <Box pos="relative" zIndex={zIndex}>
                    <div
                        className={ state === 'closing' ? 'fade-out' : state === 'opening' ? 'fade-in': '' }
                        onAnimationEnd={animEnd}
                        ref={setHoverDiv}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <Box bg="inner-card" borderColor="card-bg" borderSides="all" borderWidth="thick" shadow="card" mt="1" roundedEdges="all">
                            { items.map((item, n) => <PopMenuItem item={item} key={n} onClick={() => clk(item)} /> )}
                        </Box>
                    </div>
                </Box>
            )}
        </IntoPortal>
    </>
}