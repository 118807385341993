import { interval, forkJoin } from 'rxjs';
import { withLatestFrom, map, filter, exhaustMap, tap } from 'rxjs/operators';
import { _jobs } from './states';
import { flagJobAsInactive } from './flag-job-as-inactive';

const POLL_INTERVAL = 2000; // 3 sec for testing

export const init = () => {
    // THE POLLING SUB!
    interval(POLL_INTERVAL).pipe(
        // tap(() => checkQueue),
        withLatestFrom(_jobs),
        map(([ intervalCount, jobsDict ]) => {
            return Object.values(jobsDict).filter(x => x.active);
        }),
        filter(jobs => jobs.length > 0),
        exhaustMap(jobs => { // interval ticks ignored if previous inner forkjoin still busy
            const obsArr = jobs.map(job => job.checkStatus.pipe(
                tap(jobStatus => {
                    if (jobStatus === "BUSY") {
                        // Still busy? Check for timeout.
                        if (job.pollUntil < Date.now()) {
                            // We have timed out 1601309567030 1601309572030 1601309572463
                            console.log('We have timed out', job.startedAt, job.pollUntil, Date.now());
                            flagJobAsInactive(job.jobId);
                            if (job.onComplete) job.onComplete(job);
                        }
                    } else {
                        // no longer busy. we are done (even if we failed)
                        flagJobAsInactive(job.jobId);
                        if (job.onComplete) job.onComplete(job);
                    }
                })
            ))
            return forkJoin(obsArr);
        })    
    ).subscribe()
}