import React from 'react';
import { WhenEnumInDetails } from './types';

interface Props {
    readonly details:WhenEnumInDetails;
}
export const WhenValuePassed = ({ details }:Props) => {
    return (
        <div>"{ details.whenLabel }" mapped to "{ details.whenVal }"!</div>
    )
}