import React from 'react';
import { Box } from '../box/box';
import { SpacingSize } from '../../types';

interface Props {
    readonly mt?:SpacingSize;
}
export const Divider = ({ mt }:Props) => {
    return (
        <Box borderSides="bottom" borderColor="alert" borderWidth="thin" mb="2" mt={mt} />
    )
} 