import React from 'react';
import { GQL } from 'market-dto';
import { useApiFetch } from 'hooks';
import { Loading } from 'components';
import * as api from 'api';
import { ListRuns  } from './list-runs';


interface Props {
    readonly flow:GQL.Flow;
}
export const Runs = ({ flow }:Props) => {

    const { data, loading } = useApiFetch(async () => {
        return await api.fetchRecentRunListByFlowId(flow.id, 7);
    }, [flow.id])

    const runs = data ?? [];

    return (
        <Loading
            loading={loading}
            render={<ListRuns flow={flow} runs={runs} />}
        />
    )
}
