import React, { CSSProperties } from 'react';
import { getCss } from '../getCss';
import {
    BgColor,
    FgColor,
    Display,
    SpacingSize,
    PTSpacingSize,
    MXSpacingSize,
    Position,
    Height,
    MaxHeight,
    Width,
    AlignItems,
    JustifyContent,
    BorderColor,
    BorderSides,
    BorderWidth,
    Cursor,
    Shadow,
    RoundedEdges,
    FontSize,
    FlexSize,
    FlexDirection,
    Transition,
    TextStyle,
    TextAlign,
    Overflow,
    FlexWrap,
    FontWeight,
    BorderStyle,
    ZIndex,
    Visibility
} from '../../types';


/* a lot of components do not need to inherit ALL the box props, just spacing */
export interface BasicSpacingProps {
    readonly pt?:PTSpacingSize;
    readonly pb?:SpacingSize;
    readonly pr?:SpacingSize;
    readonly pl?:SpacingSize;
    readonly px?:SpacingSize;
    readonly py?:SpacingSize;
    readonly p?:SpacingSize;
    readonly mt?:SpacingSize;
    readonly mb?:SpacingSize;
    readonly mr?:SpacingSize;
    readonly ml?:SpacingSize;
    readonly mx?:MXSpacingSize;
    readonly my?:SpacingSize;
    readonly m?:SpacingSize;
}

export interface BoxProps extends BasicSpacingProps {
    readonly cy?:string;
    readonly children?:any;
    readonly onClick?:(event:any) => any;
    readonly onMouseDown?:(event:React.MouseEvent) => any;
    readonly onMouseUp?:(event:React.MouseEvent) => any;
    readonly onMouseOver?:(event:React.MouseEvent) => any;
    readonly onMouseOut?:(event:React.MouseEvent) => any;
    readonly style?:CSSProperties;

    readonly bg?:BgColor;
    readonly fg?:FgColor;
    readonly fgHover?:FgColor;
    readonly bgHover?:BgColor;
    readonly fontWeight?:FontWeight;
    readonly visibility?:Visibility;

    readonly maxHeight?:MaxHeight;
    readonly height?:Height;
    readonly width?:Width;

    readonly flex?:FlexSize;
    readonly flexDirection?:FlexDirection;
    readonly flexWrap?: FlexWrap;
    readonly justifyContent?:JustifyContent;
    readonly alignItems?:AlignItems;

    readonly pos?:Position;
    readonly display?:Display;

    readonly cursor?:Cursor;
    readonly shadow?:Shadow;
    readonly textAlign?:TextAlign;
    readonly zIndex?:ZIndex;

    readonly borderColor?:BorderColor;
    readonly borderColorHover?:BorderColor;
    readonly borderSides?:BorderSides;
    readonly borderWidth?:BorderWidth;
    readonly borderStyle?:BorderStyle;
    readonly roundedEdges?:RoundedEdges;

    readonly fontSize?:FontSize;
    readonly transition?:Transition;
    readonly textStyle?:TextStyle;
    readonly overflow?:Overflow;

    readonly ucase?:boolean;
    readonly mono?:boolean;
    readonly monoSmall?:boolean;
    readonly showIconsOnHover?:boolean;
    
    readonly css?:string; // please do not use this!

}

export const Box = React.forwardRef(({ children, cy, onClick, onMouseDown, onMouseUp, onMouseOver, onMouseOut, style, ...rest }:BoxProps, ref:any) => {
    const css = getCss(rest);
    return (
        <div
            ref={ref}
            data-cy={cy}
            className={css}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            style={style}
        >{children}</div>
    )
})

