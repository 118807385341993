import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    SlideInAndOut,
    Flex,
    IconButton,
    Text,
    CodeBlock,
    TabsWithoutBorders,
    useVerticalResize
 } from 'mui';
import { actMon } from 'services';
import { useOnClickOutsideExcept, useObsWithDefault } from 'hooks';
import { ActivityLogs } from './activity-logs';
import { JobInfo } from './job-info';
import { CompletedJobs } from './completed-jobs';


const MAX_ACTIVE_DISPLAY = 4; // limited horizontal real estate for jobs tabs, cut 'em off after this

export const ActivityPanel = () => {

    const [ tab, _setTab ] = useState('');
    const visible = useObsWithDefault(actMon.panelVisible$, false);
    const activeJobs = useObsWithDefault(actMon.activeJobs$, []).slice(0, MAX_ACTIVE_DISPLAY)
    const inactiveJobs = useObsWithDefault(actMon.inactiveJobs$, []);
    const selectedJob = useObsWithDefault(actMon.selectedJob$, null);

    const setTab = (toTab:string) => {
        if (toTab === 'inactive') {
            actMon.setSelectedJob('');
            _setTab(toTab);
        } else if (toTab === '') {
            actMon.setSelectedJob(toTab);
            _setTab(toTab);
        } else {
            actMon.setSelectedJob(toTab);
            _setTab(toTab);
        }
    }

    // we can suddenly have a new selected job from outside these components
    useEffect(() => {
        if (selectedJob && selectedJob.jobId !== tab) _setTab(selectedJob.jobId);
    }, [selectedJob, tab])

    const panelRef = useRef<HTMLDivElement>(null);
    const { setHandleRef } = useVerticalResize(panelRef);
    useOnClickOutsideExcept(panelRef, ['toggle-panel-icon'], actMon.closePanel);

    // We only show the top X active jobs (or we'd run out of real estate)
    // So if the selectedJob is NOT within the active job array, make sure we display it first.
    const selectedJobNotInTopX = selectedJob && selectedJob.active && !activeJobs.find(x => x.jobId === selectedJob.jobId);
    const showSelectedJobFirst = selectedJob && (!selectedJob.active || selectedJobNotInTopX);

    const menuItems = [
        {
            label: 'Activity',
            value: ''
        },
        ...showSelectedJobFirst ? [{
            label: selectedJob!.label,
            value: selectedJob!.jobId
        }] : [],
        ...activeJobs.map(job => {
            return {
                label: job.label,
                value: job.jobId
            }
        }),
        ...inactiveJobs.length > 0 ? [{
            label: <>Completed Jobs (<Text fg="faded">{ inactiveJobs.length }</Text>)</>,
            value: 'inactive'
        }] : []
    ]

    return (
        <SlideInAndOut show={visible}>
            <Box
                ref={panelRef}
                pos="fixed-bottom"
                bg="body"
                height="card-lg"
                display="flex"
                flexDirection="column"
            >
                <Box
                    ref={setHandleRef}
                    cursor="resize-vertical"
                    borderSides="top"
                    borderColor="alert"
                    borderWidth="thick"
                ></Box>

                <Flex justifyContent="space-between" alignItems="center" bg="card">
                    <Box flex="1" fg="faded" pl="1">
                        <TabsWithoutBorders
                            selected={tab}
                            onSelect={setTab}
                            items={menuItems}
                        />
                    </Box>
                    <Box p="half">
                        <IconButton name="close" onClick={actMon.closePanel} />
                    </Box>
                </Flex>
                <Box flex="1" style={{ overflowY:'auto', overflowX:'hidden' }}>
                    <CodeBlock bg="body" fontSize="sm" p="1">
                        { selectedJob && <JobInfo job={selectedJob} /> }
                        { !selectedJob && (
                            <>
                                { tab === '' && <ActivityLogs /> }
                                { tab === 'inactive' && !selectedJob && (
                                    <CompletedJobs jobs={inactiveJobs} />
                                ) }
                            </>
                        ) }
                    </CodeBlock>
                </Box>
            </Box>
        </SlideInAndOut>
    )
}