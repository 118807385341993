import { RuleModelField } from './types';

export const createNewField = ():RuleModelField => {
    return {
        index: -1,
        fieldId: '',
        groups: [],
        label: '',
        required: false,
        type: 'string'
    }
}