import React, { useState, useEffect } from 'react';
import { NavTabs, NavMenuItem, Box, Icon } from 'mui';
import { format, sheetSummaries, utils } from 'services';
import { useZState } from 'hooks';
import { isWeekend } from 'date-fns';

interface DaysAgoOptionProps {
    readonly details:sheetSummaries.SheetSummaryDetails[];
    readonly dt:Date;
    readonly isToday:boolean;
}
const DayAgoOption = ({ details, dt, isToday }:DaysAgoOptionProps) => {
    const totalLoans = details.reduce((last, curr ) => {
        const obj = curr.summary.loanStats as any;
        return last + (obj.loanCount ?? obj.count); // *1 cuz we just changed the name!
    }, 0)
    return (
        <>
            <Box fontSize="sm" fg="default" textStyle="underline">{ isToday ? 'Today' : format.dateToMonthAndDay(dt) }</Box>
            <Box fontSize="sm" fg="faded">Tapes: { details.length }, Loans: { totalLoans }</Box>
        </>
    )
}

export const Menu = () => {

    const [ navItems, setNavItems ] = useState<NavMenuItem[]>();
    const [ showAll, setShowAll ] = useState<boolean>(false);
    const { data:byDay, fetching } = useZState(sheetSummaries.byDay);

    useEffect(() => {

        if (!byDay) return;
        
        if (showAll) {
            const dates = utils.getArrayOfDaysBack(sheetSummaries.daysBack);
            const arr = dates.map((dt, n) => {
                const details = byDay[format.dateToYyyyMmDd(dt)] ?? [];
                const isToday = n === dates.length-1;                
                return {
                    dt,
                    details,
                    isToday
                }
            }).filter(x => x.details.length > 0 || !isWeekend(x.dt));
            setNavItems([
                {
                    onSelect: () => setShowAll(false),
                    to: '',
                    absoluteMatchPath: 'never',
                    label: <Box><Icon name="chevron-left"></Icon></Box>,
                },
                ...arr.map((x):NavMenuItem => {
                    return {
                        label: <DayAgoOption details={x.details} dt={x.dt} isToday={x.isToday} />,
                        to: x.isToday ? "" : 'date/' + format.dateToYyyyMmDd(x.dt),
                        exact: x.isToday
                    }
                })
            ])
        } else {
            const today = new Date();
            const details = byDay[format.dateToYyyyMmDd(today)] ?? [];
            setNavItems([
                {
                    onSelect: () => setShowAll(true),
                    to: '',
                    absoluteMatchPath: 'never',
                    label: <Box><Icon name="chevron-right"></Icon></Box>,
                },
                {
                    label: <DayAgoOption details={details} dt={today} isToday={true} />,
                    to: '',
                    exact: true
                }
            ])
        }
    }, [byDay, showAll])

    if (!navItems) return null;
    return (
        <NavTabs
            items={navItems}
            fancy={true}
            mb="2"
        />
    )
}
