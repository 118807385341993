import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Link, modal, IntoPortal } from 'mui';
import { tapeImporter } from 'services';
import { MatchTable } from './match-table';
import { ExpandedState } from './types';

interface Props {
    readonly item:tapeImporter.BatchItem;
    readonly exState:ExpandedState;
    readonly setExState:(x:ExpandedState) => void;
    readonly accept:() => void;
    readonly accepting:boolean;
    readonly skip:() => void;
}
export const MatchOptions = ({ item, exState, setExState, skip, accept, accepting }:Props) => {

    const { matchResults } = item;
    if (!matchResults) throw new Error('Expected matchResults');

    const notListed = () => {
        setExState({
            ...exState,
            selected: undefined,
            dirtyOrides: false,
            initOrides: {},
            orides: {},
            newAggregatorName: undefined,
            newSeller: undefined,
            step: "ask-agg"
        })
    }

    return (
        <>
            <IntoPortal id="expanded-title">
                Probable Tape Mappings
            </IntoPortal>

            <MatchTable item={item} exState={exState} setExState={setExState} />

            <Box mt="2" textAlign="center">
                <Button
                    mr={exState.selected ? "1" : "0"}
                    size="sm"
                    type="secondary"
                    onClick={notListed}
                >Create New Mapping</Button>
                { exState.selected && exState.selected.sellerOrg && (
                    <Button
                        size="sm"
                        type="default"
                        ml="1"
                        onClick={accept}
                        busy={accepting}
                    >Confirm { exState.selected.sellerOrg.name }</Button>
                ) }
            </Box>
            <Box mt="2" textStyle="italic" textAlign="center" fontSize="sm">
                If you are unsure of the mapping or the seller for this particular tape,
                you can <Link onClick={skip}>skip processing this tape</Link>
            </Box>
        </>
    )
}