import React from 'react';
import { FlexVCenter, Box, PortalPlaceholder, Dropdown } from 'mui';
import { rule } from 'services';
import { ExpFieldWithValue } from 'exp';
import { SampleDataContainer } from '../../samples/sample-data-container';
import { useZStateList } from 'hooks';


interface Props {
    readonly modelId:string;
    readonly sampleData:any;
    readonly setSampleData:(x:any) => void;
    readonly sampleId:string;
    readonly setSampleId:(x:string) => void;
    readonly fieldsWithValues:ExpFieldWithValue[];
}
export const SampleDataTab = ({ modelId, sampleId, setSampleId, sampleData, setSampleData, fieldsWithValues }:Props) => {

    // OK, this wasn't well thought out--it's kinda gross.
    // All because of "random"....there's an easier cleaner way
    // I may rewrite without random an then tack random on (instead of having gone the other direction)

    const {
        updating,
        editItemDirty:dirty,
        resetItem:reset,
        commitItem:save,
        editItem:sample
    } = useZStateList(rule.zModelSamples);

    const opts = [
        {
            label: "Random Data",
            value: "random"
        },
        ...rule.getSamplesAsDropdownOpts(modelId)
    ]

    const isRandom = sampleId === 'random';

    return (
        <>
            <FlexVCenter justifyContent="space-between" mb="2">
                <Box flex="1">
                    <PortalPlaceholder id="sample-fields-filter-area" />
                </Box>
                <FlexVCenter flex="1" justifyContent="flex-end">
                    <Dropdown
                        width="frm-sm"
                        size="sm"
                        items={opts}
                        value={opts.find(x => x.value === sampleId)}
                        setValue={id => setSampleId(id)}
                    />
                    <PortalPlaceholder id="sample-fields-button-area" />
                </FlexVCenter>
            </FlexVCenter>

            <SampleDataContainer
                hideButtonsWhenPristine={true}
                dirty={isRandom ? false : dirty}
                updating={isRandom ? false : updating}
                onReset={isRandom ? undefined : reset}
                onSave={isRandom ? undefined : save}
                fieldsWithValues={fieldsWithValues}
                sample={sampleId !== 'random' ? sample : undefined}
                sampleData={sampleData}
                setSampleData={setSampleData}
            />

        </>
    )
}
