import React, { useEffect, useState } from 'react';

export type EnterExitAnimState = "entered" | "exiting" | "entering" | "exited";

interface AnimateOnExitProps {
	readonly show:boolean;
	readonly children:React.ReactNode;
	readonly onAnimStateChange?:(x:EnterExitAnimState) => void;
}

export const SlideInAndOut = ({ show, onAnimStateChange, children }:AnimateOnExitProps) => {

	// Because framer motion is overkill and too confusing.

	const [state, setState] = useState<EnterExitAnimState>('exited');

	useEffect(() => {
		if (show) {
			if (state !== 'entered') setState('entering');
		} else {
			if (state !== 'exited') setState('exiting');
		}
	}, [show])

	useEffect(() => onAnimStateChange && onAnimStateChange(state), [state]);

	const animEnd = () => {
		if (state === 'exiting') {
			setState('exited');
		} else if (state === 'entering') {
			setState('entered');
		}
	}

	const css = state === 'exiting' ? 'slide-down-child' : state === 'entering' ? 'slide-up-child' : '';
	return <div onAnimationEnd={animEnd} className={css}>{state !== 'exited' && children}</div>
}
