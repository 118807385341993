import React, { useState } from 'react';
import { GQL } from 'market-dto';
import { format } from 'services';
import { alpha } from 'services';
import { Input, FlexVCenter, Box, Text } from 'mui';
import { RunsTable } from './runs-table';
import { RunItem } from './types';

interface Props {
    readonly flow:GQL.Flow;
    readonly runs:Array<Omit<GQL.FlowRun, "rows">>;
}
export const ListRuns = ({ flow, runs }:Props) => {

    const [ filterText, setFilterText ] = useState('');

    const s = filterText.trim().toLowerCase();

    const filtered:RunItem[] = runs
        .map((r, n) => {
            return {
                id: r.id,
                status: r.status,
                name: alpha.getFlowRunSeller(r),
                sheetId: r.sheetId,
                startedAt: format.toLogDateAndTime(r.startedAt),
                endedAt: format.toLogDateAndTime(r.endedAt)
            }
        })
        .filter(x => {
            if (s.length < 1) return true;
            if (x.name.toLowerCase().includes(s)) return true;
            if (x.sheetId.includes(s)) return true;
            return false;
        })

    return (
        <>
            <FlexVCenter mb="1">
                <Input
                    appendIcon="search"
                    value={filterText}
                    setValue={setFilterText}
                />
                <Box ml="1">
                    { filtered.length }&nbsp;
                    <Text fg="faded">Runs</Text>
                </Box>
            </FlexVCenter>

            <RunsTable filtered={filtered} />
        </>
    )
}
