import React from 'react';
import { Button, FlexVCenter, IntoPortal } from 'mui';
import { tapeImporter } from 'services';
import { Mapper } from 'components';
import { ExpandedState } from './types';
import { GQL, Overrides } from 'market-dto';
import { MappingHeaderNonAgg } from './mapping-header-non-agg';
import { MappingHeaderAgg } from './mapping-header-agg';
import { setSelected } from './set-selected';
import { getMappingNeeds } from './get-mapping-needs';

const getAggName = (exState:ExpandedState):string => {
    const { selected, newAggregatorName } = exState;
    if (newAggregatorName) return newAggregatorName;
    if (selected && selected.aggregatorName) return selected.aggregatorName;
    return '';
}

const getSeller = (exState:ExpandedState):GQL.Org|undefined => {
    const { selected, newSeller, sellerForAgg } = exState;
    if (newSeller) return newSeller;
    if (exState.sellerForAgg) return sellerForAgg;
    if (selected && selected.sellerOrg) return selected.sellerOrg;
}

interface Props {
    readonly item:tapeImporter.BatchItem;
    readonly exState:ExpandedState;
    readonly setExState:(x:ExpandedState) => void;
    readonly accept:() => void;
    readonly accepting:boolean;
    readonly setOrides:(orides:Overrides) => void;
}
export const Mapping = ({
    item,
    exState,
    setExState,
    accept,
    accepting,
    setOrides
}:Props) => {

    const { tapeId, rowsAndHeaders, sellers, matchResults, model } = item;
    if (!tapeId) throw new Error('expected tapeId');
    if (!rowsAndHeaders) throw new Error('expected rowsAndHeaders');

    const { orides, sellerForAgg, mappedSellerInfo, mappedForSellerOrg, selected } = exState;
    const { distinctSellerNames } = mappedSellerInfo;
    const aggName = getAggName(exState);
    const seller = getSeller(exState);
    const isAgg = aggName ? true : false;
    const isNew = selected ? false : true;
    const { canSave, highlightSellerName } = getMappingNeeds(isAgg, exState);

    const assignAggName = (x:string) => {
        setExState({
            ...exState,
            newAggregatorName: x
        })
    }

    const assignAlias = (x?:GQL.Org) => {
        // This can also clear the assigned seller
        setExState({
            ...exState,
            sellerAliasForAgg: x ? distinctSellerNames[0] : undefined,
            sellerForAgg: x
        })
    }

    const assignNonAggSeller = (x:GQL.Org) => {
        if (selected) throw new Error('Cannot change selller on existing non-agg template.');
        // In the future, cloning a template, taking orides but with a new seller (or agg/seller combo) can be allowed.
        // the rx piping does allow it.
        setExState({
            ...exState,
            newSeller: x
        })
    }

    const showBack = matchResults && matchResults.length > 0;

    const backToMatches = () => {
        setExState(setSelected(item, exState, undefined));
    }

    return (
        <>
            <IntoPortal id="expanded-title">
                { isNew ? 'New Mapping' : 'Existing Mapping' }
            </IntoPortal>

            {/* <textarea readOnly value={JSON.stringify(mappedSellerInfo, null, 4)}></textarea> */}

            { isAgg && (
                <MappingHeaderAgg
                    exState={exState}
                    isNew={isNew}
                    aggName={aggName}
                    distinctSellerNames={distinctSellerNames}
                    sellers={sellers}
                    assignAlias={assignAlias}
                    assignAggName={assignAggName}
                    sellerForAgg={sellerForAgg}
                    mappedForSellerOrg={mappedForSellerOrg}
                />
            ) }
            { !isAgg && (
                <MappingHeaderNonAgg
                    isNew={isNew}
                    seller={seller!}
                    assignSeller={assignNonAggSeller}
                    sellers={sellers}
                />
            ) }
            <Mapper
                model={model}
                highlightField={highlightSellerName ? "sellerName" : undefined}
                rowsAndHeaders={rowsAndHeaders}
                orides={orides}
                setOrides={x => setOrides(x)}
            />
            <FlexVCenter mt="3" justifyContent="center">
                { showBack && (
                    <Button
                        size="sm"
                        type="secondary"
                        icon="arrow-left"
                        onClick={backToMatches}
                        mr="1"
                    >Back to Mapping Options</Button>
                ) }
                <Button
                    size="sm"
                    ml={showBack ? "1" : "0"}
                    busy={accepting}
                    onClick={accept}
                    type={canSave ? 'default' : 'disabled'}
                >Use this Mapping</Button>
            </FlexVCenter>
        </>
    )
}