import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { Icon, Box, Flex, FlexVCenter, Button, Dropdown, DropdownItem, modal, Text } from 'mui';
import { tapeImporter, utils } from 'services';
import { getConfidenceLabel } from './get-confidence-label';

const getProbableSeller = (m:tapeImporter.AggMatchResult):GQL.Org|undefined => {
    if (m.matchState === "AGG_EXACTLY_ONE_POSSIBLE_SELLER") {
        return m.sellerOrgsInBoth[0];
    } else {
        return undefined;
    }
}

const getSellerList = (m:tapeImporter.AggMatchResult, seller:GQL.Org|undefined) => {
    const arr = utils.toDistinct([...m.sellerOrgsInTemplate, ...m.sellerOrgsFromSellerName]);
    return arr.filter(x => !seller || x.id !== seller.id);
}

interface Props {
    readonly matchResult:tapeImporter.AggMatchResult;
    readonly onSelect:(x:tapeImporter.AggMatchResult) => void;
}
export const MatchTableAggRow = ({ matchResult, onSelect }:Props) => {

    const { hashConfidence, aggregatorName, template } = matchResult;

    const probableSeller = getProbableSeller(matchResult);    
    const sellerList = getSellerList(matchResult, probableSeller);
    const unlikelySingleSeller = !probableSeller && sellerList.length === 1 ? sellerList[0] : undefined;

    // If we have a probable seller, show that seller's "external Id", etc
    // If we do not have a probable seller, but, we have only one seller in the list, use that one instead.
    const sellerForDetails = probableSeller ? probableSeller : unlikelySingleSeller;

    return (
        <>
            <td style={{ backgroundColor: '#000', borderTop:'1px solid #000' }}>
                <Button
                    size="xs"
                    type="default"
                    onClick={() => onSelect(matchResult)}
                >Select</Button>
            </td>
            <td>{ aggregatorName }</td>
            <td>
                { probableSeller && (
                    <>
                        <Text fg="loud">{ probableSeller.name }</Text>
                        { sellerList.length > 0 && ', ' }
                        {/* Remember: sellerList EXCLUDES probableSeller  */}
                        <Text>{ sellerList.map(x => x.name).join(', ') }</Text>
                    </>
                ) }                
                { !probableSeller && sellerList.length === 0 && (
                    <FlexVCenter>
                        <Icon name="alert-triangle" fg="accent" mr="half" />
                        <Box fg="faded">Unknown</Box>
                    </FlexVCenter>                    
                ) }
                { !probableSeller && sellerList.length > 0 && (
                    <Text>{ sellerList.map(x => x.name).join(', ') }</Text>
                ) }
            </td>
            <td>{ getConfidenceLabel(hashConfidence) }</td>
            {/* <td>{ sellerForDetails && sellerForDetails.externalId }</td>
            <td>{ sellerForDetails && sellerForDetails.externalIdSource }</td> */}
            <td style={{ maxWidth: '300px'}}>
                { sellerForDetails && sellerForDetails.aliases?.join(', ') }
            </td>
        </>
    )
}