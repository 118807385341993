import {
    MapResult,
    ImportModel,
    MinimalLoan
    
} from 'market-dto';
import { Field } from './types';

export const getFields = (
    model:ImportModel,
    mapped:MapResult<MinimalLoan>
):Field[] => {
    return mapped.directMapResults.map((res):Field => {
        const { fieldId } = res;
        const fieldCfg = model.getField(fieldId);
        return {
            id: fieldId,
            label: fieldCfg.label,
            required: fieldCfg.required,
            cfg: fieldCfg.mapCfg?.direct,
            der: fieldCfg.mapCfg?.derive,
            res
        }
    })
}