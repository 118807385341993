import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Landing } from "./landing/landing";
import { Login } from "./login/login";
import { PreLoginFooter } from "./pre-login-footer";

export const PublicRoutes = () => {
	return (
        <>
            <Routes>
                <Route path="" element={<Landing />} />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <PreLoginFooter />
        </>
	)
}
