import React from 'react';
import { IntoPortal } from 'mui';

interface Props {
    readonly children:React.ReactNode;
}
export const PopOverHeader = ({ children }:Props) => {
    return (
        <IntoPortal id="pop-over-header">{ children }</IntoPortal>
    )
}