import React from 'react';
import { ImportModel, GQL, Overrides, RowsAndHeaders } from 'market-dto';
import { modal, Box } from 'mui';
import { MapModal } from './map-modal/map-modal';
import { mapping } from 'services';
import { MappedSection } from './mapped-section';
import { UnmappedSection } from './unmapped-section';

interface Props {
    readonly model:ImportModel;
    readonly readOnly?:boolean;
    readonly orides:Overrides;
    readonly setOrides:(x:Overrides) => void;
    readonly rowsAndHeaders:RowsAndHeaders;
    readonly highlightField?:keyof GQL.Loan;
}
export const Mapper = ({ readOnly, orides, setOrides, rowsAndHeaders, highlightField, model }:Props) => {

    const { found, notFound, colValMapItems } = mapping.getAppliedMappingResults(model, orides, rowsAndHeaders);

    const editField = (f:mapping.Field) => {
        modal.open({
            render: (
                <MapModal
                    model={model}
                    field={f}
                    orides={orides}
                    items={colValMapItems}
                    rowsAndHeaders={rowsAndHeaders}
                    onUpdate={setOrides}
                />
            ),
            size: 'lg',
            fixAtMaxHeight: true
        })
    }

    return (
        <Box>
            <MappedSection
                highlightField={highlightField}
                readOnly={readOnly}
                showReqMsg={false}
                fields={found}
                onSelect={editField}
            />
            <UnmappedSection
                highlightField={highlightField}
                readOnly={readOnly}
                showReqMsg={false}
                fields={notFound}
                onSelect={editField}
            />
        </Box>
    )
}
