import React from 'react';
import { CodeBlock } from 'mui';
import { PriceTag } from 'market-dto';

interface LogModalProps {
    readonly tag:PriceTag;
}
export const LogModal = ({ tag }:LogModalProps) => {
    const text = tag.logs.join('\n');
    return <CodeBlock>{ text }</CodeBlock>
}
