import React from 'react';
import { NavTabs, NavMenuItem } from 'mui';

export const RuleMainMenu = () => {
    const items:NavMenuItem[] = [
        {
            label: 'Checklists',
            to: 'checklists'
        },
        {
            label: 'Models',
            to: 'models'
        },
        {
            label: 'Samples',
            to: 'samples'
        }
    ]
    return <NavTabs items={items} mb="2" />
}