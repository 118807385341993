import { NEVER } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { zBatch } from '../z-batch';
import { findTemplates$ } from './find-templates$';
import { parseTapes$ } from './parse-tapes$';
import { tapeInsert$ } from './tape-insert$';
import { finalizeLoans$ } from './finalize-loans$';

export const process$ = zBatch.state$.pipe(
    first(),
    switchMap(x => {
        if (!x.data) return NEVER;
        const step = x.data.step;
        console.log('---', step);
        if (step === 'PARSE_TAPES') {
            return parseTapes$;
        } else if (step === 'TAPE_INSERT') {
            return tapeInsert$;
        } else if (step === 'SEARCH_TEMPLATES') {
            return findTemplates$;
        } else if (step === 'POST_CONFIRM') {
            return finalizeLoans$;
        }
        return NEVER;
    })
)
