import { GQL } from 'market-dto';

export const renumberChecklistItems = (clist:Partial<GQL.Checklist>):Partial<GQL.Checklist> => {
    const { items } = clist;
    if (!items) return clist;
    return {
        ...clist,
        items: items.map((x, n) => {
            const id = String(n+1).padStart(4, '0');
            return {
                ...x,
                id
            }
        })
    }
}