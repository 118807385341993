import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

// Just trying this out.  See if i like it.

export const useRxState = <T>(bs:BehaviorSubject<T>):[T, (x:T) => void] => {
    const [ val, setVal ] = useState<T>(bs.getValue())
    useEffect(() => {
        const sub = bs.subscribe(setVal);
        return () => sub.unsubscribe();
    }, [bs]);
    return [ val, (x:T) => bs.next(x) ];
}

