import React, { useEffect, useState } from 'react';
import { Box, Button, Table, TableProps, Icon, modal, CssGridBox, FlexVCenter, TextAlign, SpacingSize, BgColor } from 'mui';

interface ColHeaderProps {
    readonly children:React.ReactNode;
    readonly textAlign?:TextAlign;
    readonly pr?:SpacingSize;
    readonly isLeft?:boolean;
    readonly isRight?:boolean;
    readonly bg?:BgColor;
}
export const ColHeader = ({ bg, children, isLeft, isRight, textAlign="left", pr }:ColHeaderProps) => {
    return (
        <Box
            bg={bg}
            // pr={pr}
            // pb="half"
            // pl="1"
            px="1"
            pt="1"
            pb="half"
            fontSize="sm"
            //borderSides="bottom"
            //borderColor="alert"
            //borderWidth="thin"
            textAlign={textAlign}
            // bg="inner-card"
        >{ children }</Box>
    )
}