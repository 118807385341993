import { useEffect, useState, useRef } from 'react';
import { zState } from 'services';
// import { useDepsChanged } from 'hooks/use-deps-changed';
// import { useGracefulBusy } from './use-graceful-busy';

// const copy = <T>(x:T):T => {
//     return x === undefined ? undefined : JSON.parse(JSON.stringify(x));
// }

interface ZHookCfg<T> {
    readonly fetchArgs?:any[];
    readonly debug?:string;
}

export const useZState = <T>(z:zState.ZState<T>, cfg:ZHookCfg<T> = {}) => {

    // NOTE: we don't enforce this "editting" guy --- it's just there for convenience
    const [ editting, setEditting ] = useState<boolean>(false);
    const [ val, setVal ] = useState<zState.ZStateProperties<T>>(() => z.getState());
    const { fetchArgs } = cfg;
    const aboutToFetch = z.needsFetch(fetchArgs);

    // TODO: TYPE THIS (maybe zState<T, ARGS> ... second type)
    const load = async (...args:any) => {
        // manually trigger it. sure, they could just do zWhatever.fetch(), but, often easier to go through hook
        // so you don't have to remember more syntax.
        console.log('A fetch');
        return await z.fetch(...args);
    }

    const reset = () => z.reset();

    const update = (patch:Partial<T>) => {
        z.update(curr => {
            return {
                ...curr,
                ...patch
            }
        })
    }

    const updateAndCommit = async (patch:Partial<T>, quietlyCommit?:boolean) => {
        update(patch);
        return await z.commitChanges(quietlyCommit);
    }

    const save = async () => {
        return await z.commitChanges();
    }

    const saveWithoutBusyStates = async () => {
        return await z.commitChanges(true);
    }

    useEffect(() => {
        if (!aboutToFetch) return;
        // console.log('B fetch', cfg, aboutToFetch, z.getZid());
        z.fetch(...fetchArgs ?? []);
    }, [aboutToFetch])

    // useEffect(() => {
    //     // This will produce a warning, but it's the correct way to say:
    //     // fetch init when args exist, and fetch subsequently when args change.
    //     if (fetchArgs) z.fetch(...fetchArgs);
    // }, [...fetchArgs ?? []])

    useEffect(() => {
        const sub = z.state$.subscribe(x => {
            if (cfg && cfg.debug) console.log('x-->', cfg.debug, x);
            setVal(x);
        })
        return () => sub.unsubscribe();
    }, [])


    // We don't wanna wait for the api call to be kicked off (in useEffect, after 1st render) to know
    // That we are "fetching". Hence "aboutToFetch".
    // So, we need to say "fetching" before we tell zstate to fetch!
    // AND...we must treat calling apis as "side effects" because in dev mode, it'll run twice...
    // just to rub your nose in it (so you can find where you have things that shouldn't be in render function)
    // Also, what if rxjs subscribe doesn't come in on next render (i've NEVER seen it, perhaps impossible)
    // But by just grabbing z.isFetching(), we bypass that. So, this should work well.
    const fetching = aboutToFetch || z.isFetching();
    const saving = z.isUpdating();
    return {
        editting,
        setEditting,
        load,
        save,
        saveWithoutBusyStates,
        update,
        updateAndCommit,
        reset,
        busy: fetching || saving,
        fetching,
        saving,
        error: val.error,
        data: val.editObj,
        dirty: val.dirty
    }
}

interface ZHookListCfg<T> {
    readonly fetchArgs?:any[];
    readonly debug?:string;
}

export const useZStateList = <T>(z:zState.ZStateList<T>, cfg:ZHookListCfg<T> = {} ) => {

    const [ val, setVal ] = useState<zState.ZStateListProperties<T>>(() => z.getState());
    const { fetchArgs } = cfg;
    // const aboutToFetch = useDepsChanged(fetchArgs);
    const aboutToFetch = z.needsFetch(fetchArgs);

    useEffect(() => {
        // This will produce a warning, but it's the correct way to say:
        // fetch init when args exist, and fetch subsequently when args change.
        if (fetchArgs) z.fetchList(...fetchArgs);
    }, [...fetchArgs ?? []])

    useEffect(() => {
        const sub = z.state$.subscribe(setVal);
        return () => sub.unsubscribe();
    }, [])

    const hydrating = val ? val.hydrating : false;
    const fetching = aboutToFetch || z.isFetching();
    const updating = val ? val.updating : false;
    const deleting = val ? val.deleting : false;
    const error = val ? val.error : false;
    const data = val ? val.list : undefined // TODO: default value? [] ? 
    
    return {
        hydrating,
        fetching,
        updating,
        deleting,
        busy: fetching || updating || deleting,
        error,
        data,
        isNew: val?.activeItemIsNew, // common in modals to wanna know, new or editting existing?
        // NOTE: arrow functions are to maintain the correct "this" within the z object
        resetItem: () => z.resetActiveItem(),
        clearItem: () => z.clearActiveItem(),
        commitItem: () => z.commitDirtyItem(),
        selectItemByIndex: (n:number) => z.selectItemByIndex(n),
        selectItem: (item:T) => z.selectItem(item),
        selectNewItem: () => z.selectNewItem(),
        updateItem: (patch:Partial<T>) => z.updateItem(patch),
        updateItemAndCommit: (patch:Partial<T>) => z.updateItemAndCommit(patch),
        removeSelectedItem: async () => {
            if (!val?.activeItemEditObj) return;
            await z.apiRemoveListItem(val.activeItemEditObj);
        },
        // editItemPristine: val?.active,
        editItem: val?.activeItemEditObj,
        editItemDirty: val?.activeItemDirty ?? false
    }
}



// import { useEffect, useState, useRef } from 'react';
// import { zState } from 'services';
// import { useDepsChanged }from './use-deps-changed';

// // Bring over dirty/reset into this too!
// const copy = <T>(x:T):T => {
//     return x === undefined ? undefined : JSON.parse(JSON.stringify(x));
// }

// interface ZHookCfg<T> {
//     readonly fetchArgs?:any[];
//     readonly debug?:string;
// }

// export const useZState2 = <T>(z:zState.ZState<T>, cfg:ZHookCfg<T> = {}) => {

//     // ARGGHHH....i got here and stopped. I MUST INTRODUCE DIRTY/RESET/EDITTING TO ZSTATE!
//     // z.get()
//     // We should be like use-api

//     const isCancelled = useRef(false);
//     const [ editObj, setEditObj ] = useState<T>();
//     const [ fetchResult, setFetchResult ] = useState<T>();
//     const [ fetching, setFetching ] = useState<boolean>(cfg.fetchArgs ? true : false);
//     // const [ updating, setUpdating ] = useState<boolean>(false);
//     const [ editting, setEditting ] = useState<boolean>(false);
//     const [ dirty, setDirty ] = useState<boolean>(false);

//     const reset = () => {
//         if (isCancelled.current) return;
//         setEditObj(copy(fetchResult))
//         setDirty(false);
//         setEditting(false);
//     }
//     useEffect(reset, [fetchResult]);

//     const load = async () => {
//         if (isCancelled.current) return;
//         setFetching(true);
//         const result = cfg.fetchArgs ? await z.fetch(...cfg.fetchArgs) : await z.fetch();
//         const sdg = z.get();
//         setFetching(false);
//         setFetchResult(result);
//     }

//     const update = async () => {
//         if (isCancelled.current) return;
//         // if (!z.commit) throw new Error('Expected update function but one was not provided');
//         if (!editObj) return;
//         //setUpdating(true);
//         const result = await z.commit(editObj);
//         z.update(() => editObj); // set zstates internal val
//         if (isCancelled.current) return;
//         //setUpdating(false);
//         setFetchResult(editObj); // this will trigger reset
//         return result;
//     }



//     // useEffect(() => {
//     //     // Do NOT load unless dependencies were specified
//     //     // (empty array specified means, run onload, obviously)
//     //     if (cfg.fetchArgs && !cfg.fetchArgs.find(x => !x)) load();
//     // }, cfg.fetchArgs ?? [])


//     const [ val, setVal ] = useState<zState.ZStateProperties<T>>(z.getState());
//     const { fetchArgs } = cfg;
//     const aboutToFetch = useDepsChanged(fetchArgs);


//     useEffect(() => {
//         // This will produce a warning, but it's the correct way to say:
//         // fetch init when args exist, and fetch subsequently when args change.
//         // if (fetchArgs) z.fetch(...fetchArgs);
//         load();
//     }, [...fetchArgs ?? []])

//     useEffect(() => {
//         const sub = z.state$.subscribe(x => {
//             if (cfg && cfg.debug) console.log('x-->', cfg.debug, x);
//             setVal(x);
//         })
//         return () => sub.unsubscribe();
//     }, [])

//     return {
//         // We don't wanna wait for the api call to be kicked off (in useEffect, after 1st render) to know
//         // That we are "fetching". Hence "aboutToFetch".
//         // So, we need to say "fetching" before we tell zstate to fetch!
//         // AND...we must treat calling apis as "side effects" because in dev mode, it'll run twice...
//         // just to rub your nose in it (so you can find where you have things that shouldn't be in render function)
//         // Also, what if rxjs subscribe doesn't come in on next render (i've NEVER seen it, perhaps impossible)
//         // But by just grabbing z.isFetching(), we bypass that. So, this should work well.
//         fetching: aboutToFetch || z.isFetching(),
//         error: val.error,
//         // data: val.data,
//         updating: val.updating,
//         dirty,
//         data:editObj,
//         editting,
//         setEditting,
//     }
// }



// export const useZState = <T>(z:zState.ZState<T>, cfg:ZHookCfg<T> = {}) => {

//     const [ val, setVal ] = useState<zState.ZStateProperties<T>>(() => z.getState());
//     const { fetchArgs } = cfg;
//     const aboutToFetch = useDepsChanged(fetchArgs);

//     // maybe some other time.
//     // useEffect(() => {
//     //     if (cfg.initVal) z.setInitVal(cfg.initVal);
//     // }, [])

//     useEffect(() => {
//         // This will produce a warning, but it's the correct way to say:
//         // fetch init when args exist, and fetch subsequently when args change.
//         if (fetchArgs) z.fetch(...fetchArgs);
//     }, [...fetchArgs ?? []])

//     useEffect(() => {
//         const sub = z.state$.subscribe(x => {
//             if (cfg && cfg.debug) console.log('x-->', cfg.debug, x);
//             setVal(x);
//         })
//         return () => sub.unsubscribe();
//     }, [])

//     return {
//         // We don't wanna wait for the api call to be kicked off (in useEffect, after 1st render) to know
//         // That we are "fetching". Hence "aboutToFetch".
//         // So, we need to say "fetching" before we tell zstate to fetch!
//         // AND...we must treat calling apis as "side effects" because in dev mode, it'll run twice...
//         // just to rub your nose in it (so you can find where you have things that shouldn't be in render function)
//         // Also, what if rxjs subscribe doesn't come in on next render (i've NEVER seen it, perhaps impossible)
//         // But by just grabbing z.isFetching(), we bypass that. So, this should work well.
//         fetching: aboutToFetch || z.isFetching(),
//         error: val.error,
//         data: val.data
//     }
// }

// interface ZHookListCfg<T> {
//     readonly fetchArgs?:any[];
//     readonly debug?:string;
// }

// export const useZStateList = <T>(z:zState.ZStateList<T>, cfg:ZHookListCfg<T> = {} ) => {

//     const [ val, setVal ] = useState<zState.ZStateListProperties<T>>(() => z.getState());
//     const { fetchArgs } = cfg;
//     const aboutToFetch = useDepsChanged(fetchArgs);

//     useEffect(() => {
//         // This will produce a warning, but it's the correct way to say:
//         // fetch init when args exist, and fetch subsequently when args change.
//         if (fetchArgs) z.fetchList(...fetchArgs);
//     }, [...fetchArgs ?? []])

//     useEffect(() => {
//         const sub = z.state$.subscribe(setVal);
//         return () => sub.unsubscribe();
//     }, [])

//     const fetching = aboutToFetch || z.isFetching();
//     const updating = val ? val.updating : false;
//     const error = val ? val.error : false;
//     const data = val ? val.list : undefined // TODO: default value? [] ? 

//     return {
//         fetching: fetching,
//         updating: updating,
//         busy: fetching || updating,
//         error,
//         data
//     }
// }



