import React from 'react';
import { Box } from 'mui';
import { JSONSchema } from 'market-dto';
import { SchemaUiProperties } from './properties';
import { SchemaUiSectionTitle } from './section-title';
import { KeyValueDict } from './schema-ui.service';

interface Props {
    readonly data:KeyValueDict;
    readonly filter:string;
    readonly schema:JSONSchema;
    readonly editting:boolean;
    readonly onChange:(x:any) => any;
}
export const SchemaUiSection = ({ filter, schema, onChange, editting, data }:Props) => {

    const items = Object.entries(schema.properties ?? {}).filter(([id, childSchema]) => {
        if (filter === '') return true;
        if (schema.title && schema.title.toLowerCase().includes(filter)) return true;
        if (childSchema.title && childSchema.title.toLowerCase().includes(filter)) return true;
        if (childSchema.description && childSchema.description.toLowerCase().includes(filter)) return true;
        if (childSchema.sample && childSchema.sample.toLowerCase().includes(filter)) return true;
        return false;
    })

    if (items.length === 0) return null;

    return (
        <Box mb="2">
            <SchemaUiSectionTitle schema={schema} />            
            <SchemaUiProperties items={items} onChange={onChange} data={data} editting={editting} />
        </Box>
    )
}