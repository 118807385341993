import React from 'react';
import { Box, Flex, Button, TableLikeGrid, FlexVCenter, Icon, openConfirmModal, Text, Input, Dropdown, DropdownItem, HoverIconBox } from 'mui';
import { ExpContext, ExpressionEditorState, ExpEvalStatus, getInitExpEditorState, ExpWarning, ExpContextField } from 'exp';

const getVal = (f:ExpContextField, val:string|number|boolean) => {
    if (f.schema.type === "boolean") {
        return val ? 'true' : 'false'
    } else if (f.schema.type === "number") {
        return Number(val).toLocaleString();
    }
    return String(val);
}

interface Props {
    readonly id:string;
    readonly val:string|number|boolean;
    readonly expCtx:ExpContext;
    readonly onRemove?:() => void;
}
export const ConclusionItem = ({ id, val, expCtx, onRemove }:Props) => {

    const field = expCtx.fieldByName[id];

    const item = <FlexVCenter mono fg="default" ml="1" my="half">
        <Box fontSize="xs">{ field.id }</Box>
        <Box mx="1" fg="loud" fontSize="xs">
            <Icon name="arrow-right" />
        </Box>
        <Box fontSize="xs">{ getVal(field, val) }</Box>
    </FlexVCenter>

    if (onRemove) {
        return (
            <HoverIconBox
                icon="trash"
                fontSize="sm"
                iconOnClick={onRemove}
            >{ item }</HoverIconBox>
        )
    } else {
        return item;
    }
}