import React from 'react';
import { Text, DotsLabel, Link, FlexVCenter } from 'mui';
import { tapeImporter } from 'services';

interface Props {
	readonly batch:tapeImporter.Batch;
	readonly item:tapeImporter.BatchItem;
	readonly onLoad:(item:tapeImporter.BatchItem) => void;
}
export const ColTapeId = ({ batch, item, onLoad }:Props) => {

	const { tapeId, status, expanded } = item;

	if (tapeImporter.isFailedStatus(status)) {
		return <Text fg="danger">Import Failed</Text>
	}

	// const needInput = tapeImporter.isUserInputNeededStatus(item.status);

	if (status === "PARSE_NEEDS_PASSWORD") {
		// Dots frozen--we need them to click link
		return <Text fg="faded">Processing...</Text>
	}

	if (tapeId) {
		if (status === 'SUCCESS') {
			return <Link cy="tape-upload-success" onClick={() => onLoad(item)}>{ item.tapeId }</Link>
		} else {
			if (!batch.paused && batch.processing) {
				return <DotsLabel fg="faded">{ item.tapeId }</DotsLabel>;
			} else {
				return <Text fg="faded">{ item.tapeId }</Text>;
			}
		}
	}
	if (batch.paused) return <Text fg="faded">Processing</Text>
	return <DotsLabel fg="faded">Processing</DotsLabel>

}