export const QUALIFIER_RETURNING = `
    id
    name
	tags
	qualifiers {
		qualifiedFor
		classifierType
		constructs {
			threshold
			assertions
			removeOnly
		}
	}
`;
