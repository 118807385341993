import React from 'react';
import { Dropdown } from 'mui'
import { format } from 'services';

interface EnumPicker {
    readonly fieldId: string;
    readonly value: string;
    readonly types: string[];
    readonly onChange: (x:any) => any;
} 
export const EnumPicker = ({ fieldId, value, types, onChange }:EnumPicker) => {

    const items = types.map(x => ({ label: format.loanValue(fieldId, x), value: x }));
    const selected = items.find(x => x.value === value);

    return (
        <Dropdown
            size="sm"
            isMulti={false}
            value={selected}
            items={items}
            isClearable={true}
            setValue={onChange}
        />
    )
}