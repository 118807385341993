import React from 'react';
import { Card, Box, SpinLabel, Button, IntoPortal, modal } from 'mui';
import { fetchAllUsers } from 'api';
import { useApiFetch } from 'hooks';
import { UserTable } from './user-table';
import { UserModal } from './user-modal';

export const Users = () => {

    const { data:users, loading, loadWithoutBusyStates } = useApiFetch(fetchAllUsers, []);

    const openAddUserModal = () => {
        modal.open({
            render: <UserModal user={null} onSave={loadWithoutBusyStates}/>,
            title: 'Add New User'
        })
    }

    return (
        <>
            { loading && <SpinLabel>Loading...</SpinLabel> }
            { !loading && (
                <>
                    <UserTable users={users} onChange={loadWithoutBusyStates}/>
                    <IntoPortal id="desk-portal">
                        <Button type="default" icon="plus" size="sm" onClick={openAddUserModal}>Add User</Button>
                    </IntoPortal>
                </>
            )}
        </>
    );
}