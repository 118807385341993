import React, { useState } from 'react';
import { Alert, Box, Button, SpinLabel, IntoPortal, modal } from 'mui';
import { SummaryTable } from './summary-table';
import { useZState } from 'hooks';
import * as api from 'api';
import { useParams } from 'react-router-dom';
import { format, sheetSummaries, utils } from 'services';
import { RecentTapesButtons } from './recent-tapes-buttons';
import { Loading } from 'components';

export const RecentTapes = () => {

	const { dt:dtStr } = useParams();
	const yyyymmdd = dtStr ? dtStr : format.dateToYyyyMmDd(new Date());
	const { data:byDay, fetching } = useZState(sheetSummaries.byDay);
	const summaries = byDay && yyyymmdd ? (byDay[yyyymmdd] ?? []) : [];
	const [ isZapping, setIsZapping ] = useState<boolean>(false);


	const zap = async () => {
		const ids = summaries.map(x => x.summary.sheetId);
		if (!ids || ids.length === 0) return;
		modal.confirm({
			title: <>
				<Box>Are you sure you want to remove this day's tapes?</Box>
				<Box fg="accent" mt="1">This cannot be undone.</Box>
			</>,
			cancelLabel: 'Nevermind',
			okLabel: 'Yes, Remove All',
			ok: async () => {
				setIsZapping(true);
				await api.zapTapes(ids);
				// await wait(1500); // NOT ENOUGH TIME. WEIRD.
				// await wait(4500); // ALSO NOT ENOUGH TIME
				await utils.sleep(6000); // Try this...6 seconds
				const summaries = await sheetSummaries.byDay.refresh();
				if (summaries) {
					console.log('let us check the summaries...', summaries);
					Object.entries(summaries).forEach(([dayKey, daySummaries]) => {
						if (daySummaries.some(x => (x.summary as any).deleted)) {
							console.log('WE FOUND A DELETD SUMMARY', dayKey, daySummaries);
						} else {
							console.log('No summaries found with truthy deleted flag for day:', dayKey);
						}
					})
				}
				setIsZapping(false);
			}
		})
	}
	if (summaries.length === 0) return <Alert>No Recent Tapes Found!</Alert>;
	return (
		<Loading
			loading={fetching}
			render={
				<>
					<IntoPortal id="tapes-menu-btn-portal">
						<RecentTapesButtons summaries={summaries} yyyymmdd={yyyymmdd} />
					</IntoPortal>
					<SummaryTable details={summaries} yyyymmdd={yyyymmdd} />
					<Box mt="2" textAlign="right">
						<Button
							size="sm"
							icon="trash"
							type="link"
							busy={isZapping}
							onClick={() => zap()}
						>Remove All</Button>
					</Box>
				</>
			}
		/>
	)
}
