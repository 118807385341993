import React, { useEffect, useState } from 'react';
import { tapeImporter, format, utils, urls } from 'services';
import { Box, Button, Table, TableProps, Icon, modal, CssGridBox, FlexVCenter, TextAlign, SpacingSize } from 'mui';
import { ColHeader } from './col-header';
import { useNavigate } from 'react-router-dom';
import { ImportTableRow } from './import-table-row';


interface Props {
    readonly nextStep:() => void;
	readonly batch:tapeImporter.Batch;
}
export const ImportTable = ({ batch, nextStep }: Props) => {

    const navigate = useNavigate();
    const { items } = batch;
    if (items.length === 0) throw new Error('Expected at least 1 item');

    const showConfirmAll = batch.step === 'UI_CONFIRM_TEMPLATES' && tapeImporter.canAutoAssignTheUnassigned(batch);
    const allComplete = batch.items.every(item => item.assigned || item.skipped || item.error);
    const showCheck = tapeImporter.isAfterOrAtStep(batch, "UI_CONFIRM_TEMPLATES");

    const confirmAll = () => {
        // tapeImporter.bulkConfirmSellers(batch);
        tapeImporter.bulkConfirmTopSuggestionsAsIs(batch);
        nextStep();
    }

    const onLoad = (item:tapeImporter.BatchItem) => navigate('/tape/' + item.tapeId);

    // The css grid here is such a special case, we are not generalizing it and making mui component out of it.
    return (
        <Box my="2">
            <div
                style={{
                    display: 'grid',
                    alignContent: 'space-around',
                    gridTemplateColumns: '145px 240px 65px 70px auto 80px',
                    rowGap: '0', //'var(--space1)',
                    columnGap: '0',
                    justifyContent: 'stretch'
                }}
            >
                {/* Column headers */}
                <ColHeader isLeft>Id</ColHeader>
                <ColHeader>File</ColHeader>
                <ColHeader textAlign="right"># Loans</ColHeader>
                <ColHeader>
                    { showCheck && (
                        <FlexVCenter justifyContent="flex-end" height="100%" fontSize="md">
                            <Icon
                                name="check"
                                fg={allComplete ? 'success' : 'really-faded' }
                            />
                        </FlexVCenter>
                    ) }
                </ColHeader>
                <ColHeader>Mapping</ColHeader>
                <ColHeader isRight textAlign="right"># Eligible</ColHeader>

                {/* Columns */}
                { items.map((item, n) => (
                    <ImportTableRow
                        batch={batch}
                        item={item}
                        n={n}
                        nextStep={nextStep}
                        onLoad={onLoad}
                        key={n}
                    />
                )) }

                {/* BEGIN FOOTER - leave those empty divs, we are in a css grid */}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <Box textAlign="left" mt="1">
                    { showConfirmAll && (
                        <Button type="secondary" size="xs" onClick={confirmAll}>
                            { batch.items.length === 1 ? 'Confirm Seller' : 'Confirm All Sellers' }
                        </Button>
                    ) }
                </Box>
                <div></div>
                {/* END FOOTER - leave those empty divs, we are in a css grid */}

            </div>


        </Box>
    )

}
