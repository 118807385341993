import { gql, GQL, query } from "../../../client";
import { RETURNING } from './returning';


interface QueryInput {
	readonly where:GQL.WhereString;
}

interface QueryResult {
	readonly eligibilityClassifier:GQL.EligibilityClassifier[];
}

const QUERY = gql`
    query fetchEligibilityClassifiers($where: where_eligibilityClassifier_exp!) {
        eligibilityClassifier(where:$where) { ${RETURNING} }
    }
`;

export const fetchEligibilityClassifiers = async (ids:string[]):Promise<GQL.EligibilityClassifier[]> => {
	const result = await query<QueryInput, QueryResult>(QUERY, {
		where: {
			id: {
				_in: ids
			}
		}
	})
	return result.data.eligibilityClassifier.map(x => {
		return {
			...x,
			// easiest to ensure they exist at this level than always have to check.
			// we don't know what kinda constructs it is yet (we can find out by looking at classifierType)
			constructs: x.constructs as any ?? [],
			tags: x.tags ?? []
		}
	})
}
