import React, { useState } from 'react';
import { GQL, labels } from 'market-dto';
import { Box, Button, FlexVCenter, BoundFrmInput, modal, BoundFrmCheckbox } from 'mui';
import * as api from 'api';
import { useApiUpdate } from 'hooks';

const canSave = (servicer:Partial<GQL.Servicer>) => {
    // Are these really supposed to be optional? I typed them as optional playground has them as optional.
    if (!servicer.name?.trim()) return false;
    if (!servicer.fannieServicerNumber?.trim()) return false;
    if (!servicer.symbol?.trim()) return false;
    return true;
}

const getEditObj = (isNew:boolean, servicer?:GQL.Servicer):Partial<GQL.Servicer> => {
    if (isNew) {
        return {
            // NO ID
            fannieServicerNumber: '',
            name: '',
            symbol: '',
            fee: 0,
            suspended: false
        }
    } else {
        if (!servicer) throw new Error('servicer must exist is not isNew');
        return servicer;
    }
}

interface EditServicerModal {
    readonly isNew:boolean;
    readonly servicer?:GQL.Servicer;
    readonly refresh:() => void;
}
export const EditServicerModal = ({ isNew, servicer, refresh }:EditServicerModal) => {
    
    const [ editObj, setEditObj ] = useState<Partial<GQL.Servicer>>(() => getEditObj(isNew, servicer));
    const { busy, update } = useApiUpdate(async () => {
        modal.disableClose();
        if (isNew) {
            await api.insertServicer(editObj);
        } else {
            await api.updateServicer(editObj);
        }
        refresh();
        modal.close();
    })
    
    const save = () => {
        if (!canSave(editObj)) return;
        console.log('ok, here is update', JSON.stringify(editObj, null, 4));
        update();
    }

    return (
        <Box>
            <BoundFrmInput
                width="100%"
                label={labels.byServicerField('name')}
                bindTo={[ editObj, setEditObj, x => x.name]}
            />
            <BoundFrmInput
                width="100%"
                label={labels.byServicerField('symbol')}
                bindTo={[ editObj, setEditObj, x => x.symbol]}
            />
            <BoundFrmInput
                width="100%"
                label={labels.byServicerField('fannieServicerNumber')}
                bindTo={[ editObj, setEditObj, x => x.fannieServicerNumber]}
            />
            <BoundFrmInput
                width="100%"
                // align="right"
                prependIcon="dollar-sign"
                numeric={{ float:true }}
                label={labels.byServicerField('fee')}
                bindTo={[ editObj, setEditObj, x => x.fee]}
            />
            <Box mt="1">
                <BoundFrmCheckbox
                    label={labels.byServicerField('suspended')}
                    bindTo={[ editObj, setEditObj, x => x.suspended]}
                />
            </Box>

            <FlexVCenter justifyContent="flex-end" mt="2">
                <Button type="link" onClick={modal.close} mr="1">Cancel</Button>
                <Button
                    busy={busy}
                    type={canSave(editObj) ? 'default' : 'disabled'}
                    onClick={save}
                >{ isNew ? 'Submit' : 'Update' }</Button>
            </FlexVCenter>
        </Box>
    )
}