import React from 'react';
import { Box, Flex, FgColor } from 'mui';
import { SenseOption, SenseOptionIndexes } from './exp-types';

const getFgColor = (opt:SenseOption, active:boolean):FgColor => {
    if (opt.priority === 'match') return 'success';
    if (active) return 'loud';
    if (opt.priority === 'neither') return 'faded';
    return 'default';
}

const niceText = (opt:SenseOption) => {
    if (opt.type === 'enum') return opt.value.substring(1, opt.value.length - 1);
    return opt.value;
}

interface Props {
    readonly opts:SenseOption[];
    readonly indexes:SenseOptionIndexes;
    readonly maxDisplayOpts:number;
    readonly onClick:(opt:SenseOption, optIndex:number) => void;
}
export const SensePopper = ({ opts, indexes, maxDisplayOpts, onClick }:Props) => {

    if (opts.length === 0) throw new Error('Expected at least ONE sense option');

    const { active, start } = indexes;

    const arr = opts.slice(start, start + maxDisplayOpts);

    return (
        <Box
            p="half"
            bg="card"
            style={{
            border: '1px solid #777',
            width: '200px',
            maxHeight: '200px',
            overflow: 'hidden'
        }}>
            { arr.map((opt, n) => {
                const index = start + n;
                return (
                    <Flex
                        justifyContent="space-between"
                        key={n}
                        bg={active === index ? 'inner-card' : 'card'}
                        fg={getFgColor(opt, active == index)}
                        p="half"
                        // occurs BEFORE onblur of current active element
                        onMouseDown={() => onClick(opt, index)}
                        // onClick={() => onClick(opt, index)}
                    >
                        <Box>{ niceText(opt) }</Box>
                        <Box>{ opt.type === 'func' ? 'ƒ' : '' }</Box>
                    </Flex>
                )
            }) }

        </Box>
    )
}
