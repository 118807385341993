import React, { useState, useEffect } from 'react';
import { GQL, getModelByModelType } from 'market-dto';
import { alpha } from 'services';
import { FlowRunStatus } from './flow-run-status';
import { useZState } from 'hooks';


interface Props {
    readonly flow:GQL.Flow;
    readonly tapeId:string;
}
export const FlowRunWithTapeId = ({ flow, tapeId }:Props) => {

    const [ ready, setReady ] = useState(false);
    const [ isNewFlow ] = useState(() => flow.recentActivity === null);

    useEffect(() => {
        // tape import has completed successfully.
        if (!tapeId) return;
        const sub = alpha.runFlow({ flow, tapeId, fetchXlsExportTaskConfig: true }).subscribe();
        setReady(true);
        return () => sub.unsubscribe();
    }, [])

    const { data:runState } = useZState(alpha.zRunState);

    if (!ready) return <></>; // to prevent flicker of old runState (wait for sub to have started)

    return (
        <FlowRunStatus
            isNewFlow={isNewFlow}
            flow={flow}
            runState={runState!}
        />
    )
}