import { mutate, stripInternals, gql, GQL } from "../../client";
import { SAMPLE_FIELDS } from './returning';

const UPDATE = gql`
    mutation updateModelSample($set: modelSample_set_input, $where: modelSample_bool_exp!) {
        update_modelSample(set: $set, where: $where) {
            errorMessage
            affectedRow
            returning {
                ${ SAMPLE_FIELDS }
            }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.ModelSample>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_modelSample: {
        readonly returning:[GQL.ModelSample];
    }
}

export const updateModelSample = async (sample:Partial<GQL.ModelSample>):Promise<GQL.ModelSample|undefined> => {
    if (!sample.id) throw new Error('Expected id');    
	const result =  await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(sample),
        where: {
            id: {
                _eq: sample.id
            }
        }
    })
    return result?.data?.update_modelSample.returning[0];
}
