import { LoanModelEnums, LoanField } from 'market-dto';
import { Eligible, EligibleConstruct } from 'raccoon-engine';

export type RequiredSimpleField = "dti"|"ltv"|"cltv";

export interface SimpleRulesFormData {
    readonly specialtyPrograms:LoanModelEnums.SpecialtyProgram[];
    readonly docTypes:LoanModelEnums.DocType[];
    readonly ausTypes:LoanModelEnums.Aus[];
    // readonly productCodes:ProductCode[];
    readonly loanTypes:LoanModelEnums.LoanType[];
    readonly propertyTypes: LoanModelEnums.PropertyType[]
    readonly rateTypes:LoanModelEnums.RateType[];
    readonly maxLoanAmount:number;
    readonly maxDti:number;
    readonly minFico:number;
    readonly maxLtv:number;
    readonly noteRateMultiplier:number;
    readonly cltvGreaterLtv:boolean;
    readonly allowTpo:boolean;
    readonly requiredFields: RequiredSimpleField[];
}


const getConstructValue = (arr:Array<EligibleConstruct>, id:string):any => {
    return arr.find(x => x.eligibility.id === id)?.eligibility.threshold;
}

const fieldExists = (arr:Array<EligibleConstruct>, id:string):any => {
    return arr.find(x => x.eligibility.id === id) ? true : false;
}

const getConstructRequiredFields = (constructs:EligibleConstruct[]):RequiredSimpleField[] => {
    console.log(constructs);
    return [
        fieldExists(constructs, 'dti-required') ? 'dti' : '',
        fieldExists(constructs, 'ltv-required') ? 'ltv' : '',
        fieldExists(constructs, 'cltv-required') ? 'cltv' : '',
    ].filter(x => x !== '') as RequiredSimpleField[];
}


export const simpleRulesToFormData = (rules:Eligible[]):SimpleRulesFormData => {
    const constructs = rules.map(rule => rule.construct);
    return {
        specialtyPrograms: getConstructValue(constructs, 'specialty-programs'),
        docTypes: getConstructValue(constructs, 'doc-types'),
        ausTypes: getConstructValue(constructs, 'aus-types'),
        rateTypes: getConstructValue(constructs, 'rate-types'),
        loanTypes: getConstructValue(constructs, 'loan-types'),
        propertyTypes: getConstructValue(constructs, 'property-types'),
        maxLoanAmount: getConstructValue(constructs, 'max-loan-amount') as number,
        allowTpo: !!getConstructValue(constructs, 'allow-tpo-flag'),
        cltvGreaterLtv: !!getConstructValue(constructs, 'cltv-greater-ltv'),
        maxDti: getConstructValue(constructs, 'max-dti') as number,
        maxLtv: getConstructValue(constructs, 'max-ltv') as number,
        minFico: getConstructValue(constructs, 'min-fico') as number,
        noteRateMultiplier: getConstructValue(constructs, 'note-rate-multiplier') as number,
        requiredFields: getConstructRequiredFields(constructs)
    }
}

const makeInRule = (fieldId:LoanField, ruleId:string, arr:string[]):Eligible|undefined => {
    if (arr.length === 0) return;
    return new Eligible({
        id: ruleId,
        category: '', // what is this? why is it blank?
        field: fieldId,
        boundary: 'IN',
        threshold: arr
    })
}

const makeMaxRule = (fieldId:LoanField, ruleId:string, val:number):Eligible|undefined => {
    return new Eligible({
        id: ruleId,
        category: '', // what is this? why is it blank?
        field: fieldId,
        boundary: 'MAX',
        threshold: val
    })
}

const makeMinRule = (fieldId:LoanField, ruleId:string, val:number):Eligible|undefined=> {
    return new Eligible({
        id: ruleId,
        category: '', // what is this? why is it blank?
        field: fieldId,
        boundary: 'MIN',
        threshold: val
    })
}

export const formDataToSimpleRules = (data:SimpleRulesFormData):Eligible[] => {
    return [
        makeInRule('specialtyProgram', 'specialty-programs', data.specialtyPrograms),
        makeInRule('aus', 'aus-types', data.ausTypes),
        makeInRule('rateType', 'rate-types', data.rateTypes),
        makeInRule('loanType', 'loan-types', data.loanTypes),
        makeInRule('propertyType', 'property-types', data.propertyTypes),
        makeInRule('docType', 'doc-types', data.docTypes),
        makeMaxRule('originalBalance', 'max-loan-amount', data.maxLoanAmount),
        makeMaxRule('dti', 'max-dti', data.maxDti),
        makeMaxRule('ltv', 'max-ltv', data.maxLtv),
        makeMinRule('fico', 'min-fico', data.minFico),        
        new Eligible({
            id: 'note-rate-multiplier',
            category: '',
            field: 'noteRate',
            boundary: 'MULTIPLE',
            threshold: data.noteRateMultiplier
        }),
        // prevent cltv greater ltv
        ...(data.cltvGreaterLtv ? [new Eligible({
            id: 'cltv-greater-ltv',
            category: '',
            field: 'cltv',
            boundary: 'MAX',
            threshold: 'ltv'
        })] : []),
        ...(data.allowTpo ? [new Eligible({
            id: 'allow-tpo-flag',
            category: '',
            field: 'tpoFlag',
            boundary: 'NOT',
            threshold: true
        })] : []),
        ...(data.requiredFields.map(k => {
            return new Eligible({
                id: k + '-required',
                category: '',
                field: k,
                boundary: 'EXISTS',
                threshold: ''
            })
        }))
    ].filter(x => x) as Eligible[];
}
