import React, { useState } from 'react';
import { Box, Button, FlexVCenter, Icon, Flex, Text, modal, Link, DotsLabel, Input, CssGrid } from 'mui';
import { useZStateList } from 'hooks';
import { alpha } from 'services';
import { GQL } from 'market-dto';
import { NewFlowBlockItem, NewFlowBlocks } from '../blocks';


interface BlockLinkProps {
    readonly label:string;
    readonly onAdd:() => void;
    readonly adding:boolean;
    readonly items:NewFlowBlockItem[];
}
const BlockLink = ({ adding, items, label, onAdd }:BlockLinkProps) => {
    return (
        <Flex my="2">
            <Box p="1" bg="inner-card" mx="1" roundedEdges="all">
                <FlexVCenter mb="0" pl="1">
                    { adding && <DotsLabel>Creating</DotsLabel> }
                    { !adding && <>
                        <Icon
                            cursor="pointer"
                            fg="default"
                            fgHover="link"
                            name="chevron-right"
                            onClick={onAdd}
                        />
                        <Link inverse onClick={onAdd}>{ label }</Link>
                    </> }
                </FlexVCenter>
                <NewFlowBlocks items={items} />
            </Box>
        </Flex>
    )
}


interface NewGuyProps {
    readonly onAdd:(name:string) => void;
}
const NewGuyModal = ({ onAdd }:NewGuyProps) => {
    const [ name, setName ] = useState('');
    const go = () => {
        if (!name.trim()) return;
        onAdd(name);
        modal.close();
    }
    return (
        <FlexVCenter mt="2">
            <Input
                placeholder="Enter Name"
                value={name}
                setValue={setName}
                autoFocus
                onEnter={go}
            />
            <Button
                ml="1"
                onClick={go}
                size="sm"
                type={name.trim() ? 'default' : 'disabled'}
            >Go</Button>
        </FlexVCenter>
    )
}

// ugly for now, in future, once we know what we're doing, move to dto, etc, type it fully, properly.
const labels:{[x:string]:string} = { 
    'eligibility': 'Eligibility',
    'barebones': 'Barebones',
    'cleanroomBulkTrade': 'Barebones (BidTape)',
    'pricing': 'Pricing'
}

interface Props {
    readonly onBack:() => void;
    readonly onAdded:(newFlow:GQL.Flow) => void;
}
export const NewFlow = ({ onBack, onAdded }:Props) => {

    // in the future, we shouldn't hardcode the types. or should we?
    // depends on how special they become.

    const { busy:inserting } = useZStateList(alpha.zFlows);
    const [ addingType, setAddingType ] = useState<string>(''); // to correctly id which one is busy!

    const add = (type:string) => {
        if (inserting) return;
        modal.open({
            render: <NewGuyModal onAdd={async (name) => {
                if (!name.trim()) {
                    modal.close();
                    return;
                }
                setAddingType(type); // for busy states
                const newFlow = await alpha.zFlows.apiInsertListItem({ type, name: name.trim() } as any);
                if (newFlow) onAdded(newFlow);
            }} />,
            title: 'Name for New ' + labels[type] + " Workflow"
        })
    }

    const barebonesBlocks:NewFlowBlockItem[] = [
        { label: 'Import' },
        { label: 'Export' }
    ]

    const eligibilityBlocks:NewFlowBlockItem[] = [
        { label: 'Import' },
        { label: 'Eligibility' },
        { label: 'Loan Program Classification' },
        { label: 'Export' }
    ]

    const pricingBlocks:NewFlowBlockItem[] = [
        { label: 'Import' },
        { label: 'Eligibility' },
        { label: 'Loan Program Classification' },
        { label: 'Pricing' },
        { label: 'Export' }
    ]

    // This is very gross and will be made generic in the future.
    const addingBarebonesBt = inserting && addingType === 'cleanroomBulkTrade';
    const addingBarebones = inserting && addingType === 'barebones';
    const addingEligibility = inserting && addingType === 'eligibility';
    const addingPricing = inserting && addingType === 'pricing';

    return (
        <>
            <FlexVCenter mb="2" justifyContent="space-between">
                <Box fontSize="xl" fg="faded">
                    What type of workflow would you like to create?
                </Box>
                <Button onClick={onBack} type="link" size="sm">Cancel</Button>
            </FlexVCenter>

            <>

                {/* Still needs block link cleaned up, so, can just loop an array of config objects */}

                <BlockLink
                    label="Clean Room Bulk Trade"
                    onAdd={() => add('cleanroomBulkTrade')}
                    adding={addingBarebonesBt}
                    items={barebonesBlocks}
                />

                <BlockLink
                    label="Barebones"
                    onAdd={() => add('barebones')}
                    adding={addingBarebones}
                    items={barebonesBlocks}
                />

                <BlockLink
                    label="Eligbility"
                    onAdd={() => add('eligibility')}
                    adding={addingEligibility}
                    items={eligibilityBlocks}
                />

                <BlockLink
                    label="Pricing"
                    onAdd={() => add('pricing')}
                    adding={addingPricing}
                    items={pricingBlocks}
                />

            </>
        </>
    )
}