import React from 'react';
import { Box, Flex, FlexVCenter, Icon, Text } from 'mui';
import { GQL, ImportModel, labels } from 'market-dto';
import { useDrag, useDrop } from 'react-dnd';

const getDragableDivCss = (
    f:GQL.XlsExportCol,
    isDragging:boolean,
    isHovering:boolean,
    isSelected:boolean
) => {
    // For now, there is only "exclude"
    // return f.exclude ? 'excluded' : '';
    const arr = ['vf']
    if (isDragging) arr.push('dragging');
    if (isHovering) arr.push('dragger-hovering-over');
    if (isSelected) arr.push('selected');
    if (f.isCalc) {
        arr.push('expanded'); // TODO: rename to calc
    } else {
        if (f.exclude) arr.push('excluded');
    }
    return arr.join(' ');
}


interface Props {
    readonly model:ImportModel;
    readonly colCfg:GQL.XlsExportCol;

    readonly moveField:(from:number, to:number) => void;
    // readonly moveFieldByIndex:(from:number, to:number) => void;

    readonly onToggleExlude:(f:GQL.XlsExportCol) => void;
    readonly selectedColId:number;
    readonly setSelectedColId:(x:number) => void;
}
export const DraggableField = ({
    model,
    colCfg,
    moveField,
    onToggleExlude,
    selectedColId,
    setSelectedColId
}:Props) => {


    const { colId } = colCfg;
    const dragType = "xls-x-fld";

    const [ collectedDragProps, drag, preview ] = useDrag({
        item: { type:dragType, colId },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging()
            }
        }
    })    

    const [ collectedDropProps, drop ] = useDrop({
        accept: dragType,
        collect: (monitor) => {
            return {
                isHovering: monitor.isOver()
            }
        },
        drop: (item, monitor) => {
            const fromColId = (item as any).colId;
            // console.log('vertical drag, move', fromColId, 'to', n);
            moveField(fromColId, colId);
            
        }
    })

    const { isDragging } = collectedDragProps;
    const { isHovering } = collectedDropProps;
    const isSelected = colCfg.colId === selectedColId;

    const dragAndDrop = (el:any) => {
        preview(el);
        drop(el);
    }

    const mouseUp = () => setSelectedColId(isSelected ? -1 : colCfg.colId);

    const label = labels.byTaskXlsExportField(model, colCfg);
    const canDrag = true; // customizing && optional;


    // hide icon unless hover.
    // make that export fields label a filter.

    return (
        <div
            className={getDragableDivCss(colCfg, isDragging, isHovering, isSelected)}
            ref={canDrag ? dragAndDrop : () => {}}
            onMouseUp={mouseUp}
        >
            <div ref={drag}>{ label }</div>
            <Icon
                name={colCfg.exclude ? "plus" : "minus"}
                // fg="default"
                //fgHover="link"
                cursor="pointer"
                onClick={() => onToggleExlude(colCfg)}
            />
        </div>
    )
}