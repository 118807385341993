import React, { useLayoutEffect, useEffect, useState } from 'react';

export const useVerticalScrollbarShowing = (ref:React.MutableRefObject<any>, deps:any[]=[]) => {
    const isShowing = () => {
        if (!ref || !ref.current) return false;
        // console.log('ref.current.scrollHeight', ref.current.scrollHeight);
        // console.log('ref.current.clientHeight', ref.current.clientHeight);
        return ref.current.scrollHeight > ref.current.clientHeight;
    }
    const [ showing, setShowing ] = useState(() => isShowing());
    useLayoutEffect(() => {
        if (!ref || !ref.current) return;
        setShowing(isShowing());
    }, deps)
    return showing;
}