export const RETURNING = `
    id
    name
    modelId
    items
    category
    description
    refId
    tags
`;

export const DEHYDRATED_RETURNING = `
    id
    name
    modelId
    category
    description
    refId
    tags
`;

