import React, { useRef } from 'react';
import { useMatch, NavLink as RouterNavLink } from 'react-router-dom';
import { FlexVCenter } from '../flex/flex';

interface Props {
    readonly label:React.ReactNode;
    readonly to:string;
    readonly matchPath:string;
}
export const TopNavMenuItem = ({ label, to, matchPath }:Props) => {

    const active = useMatch(matchPath);
    const link = useRef(null);

    const clk = () => {
        if (link && link.current) {
            // clicking the Flex box should click the link
            (link.current as any).click();
        }
    }

    if (active) {
        return (
            <FlexVCenter
                height="100%"
                fg="loud"
                bg="body"
                px="2"
                mx="1"
            >
                <RouterNavLink to={to}>{ label }</RouterNavLink>
            </FlexVCenter>
        );
    } else {
        return (
            <FlexVCenter
                height="100%"
                fg="faded"
                fgHover="link"
                cursor="pointer"
                px="2"
                mx="1"
                onClick={clk}
            >
                <RouterNavLink ref={link} to={to}>{ label }</RouterNavLink>
            </FlexVCenter>
        );
    }
}