import React, { useState, useEffect } from 'react';
import { loanModel, Overrides, GQL } from 'market-dto';
import { Dropdown, Tabs, Box, Button, Flex, FlexVCenter, Icon, PortalPlaceholder } from 'mui';
import { MatchOptions } from './match-options';
import { tapeImporter, sellers } from 'services';
import { ExpandedState } from './types';
import { Mapping } from './mapping';
import { getInitExpandedState } from './get-init-expanded-state';
import { getOrgString } from './get-org-string';
import { useApiUpdate } from 'hooks';
import { getUiState } from './get-ui-state';
import { setOrideDependents } from './set-oride-dependents';
import { getConfirmedSeller } from './get-confirmed-seller';
import { SellerOptions } from './seller-options';
import { NonAggIdentifySeller } from './non-agg-identify-seller';
import { AskAgg } from './ask-agg';

interface Props {

    readonly item:tapeImporter.BatchItem;
    readonly nextStep:() => void;
}
export const Expanded = ({ item, nextStep }:Props) => {

    const { matchResults, rowsAndHeaders, skipped } = item;
    if (!matchResults) throw new Error('expected matchResults');
    if (!rowsAndHeaders) throw new Error('Expected rowsAndHeaders');

    const [ exState, setExState ] = useState<ExpandedState>(() => getInitExpandedState(item));

    const { orides, dirtyOrides, selected, newAggregatorName, step } = exState;
    const aggName = selected?.aggregatorName || newAggregatorName;
    const uiState = getUiState(exState);

    const skip = () => {
        tapeImporter.collapseItem(item);
        if (tapeImporter.setSkipped(item, true)) nextStep();
    }

    const setOrides = (newOrides:Overrides) => {
        setExState({
            ...exState,
            ...setOrideDependents(item, exState.initOrides, newOrides)
        })
    }
    
    const { update:doAccept, busy:accepting } = useApiUpdate(async () => {

        console.log(uiState, 'we wanna use mapping');

        if (uiState === "MISSING_SOMETHING") return;

        const confirmedSeller = getConfirmedSeller(exState);
        const confirmedAggregatorName = aggName;

        if (!confirmedSeller) throw new Error('Missing confirmed seller!');

        const newTemplate = uiState === "NONE_SELECTED__NEW_NON_AGG" || uiState === "NONE_SELECTED__NEW_AGG";

        const input:tapeImporter.UserInputState = {
            ...(dirtyOrides || newTemplate) ? { editMapping: { overrides: orides, ver: loanModel.version } } : {},
            ...selected ? { selectedMatch: selected } : {},
            confirmedSeller,
            confirmedAggregatorName
        }
        console.log('user input obj', input);

        await tapeImporter.manualAssign(item, input);
        nextStep();
    })

    const unskip = () => {
        tapeImporter.setSkipped(item, false);
    }

    return (
        <>
            {/* <textarea readOnly value={JSON.stringify(exState, null, 4)}></textarea> */}
            <Flex
                style={{ backgroundColor: '#000' }}
                borderColor="alert"
                borderSides="bottom-right-and-left"
                borderWidth="thin"
                p="1"
                alignItems="center"
                flexDirection="column"
            >
                <Flex justifyContent="space-between" width="100%" mb="1">
                    <Box flex="1">&nbsp;</Box>
                    <FlexVCenter flex="3" justifyContent="center">
                        <Flex justifyContent="flex-end" flex="1">
                            <PortalPlaceholder id="expanded-title-left" />
                        </Flex>
                        <Box
                            fg="default"
                            fontSize="sm"
                            borderWidth="thin"
                            borderColor="alert"
                            borderSides="bottom"
                            pb="half"
                        >
                            <PortalPlaceholder id="expanded-title" />
                        </Box>
                        <Box flex="1">&nbsp;</Box>
                    </FlexVCenter>
                    <Flex flex="1" justifyContent="flex-end">
                        <Icon
                            cursor="pointer"
                            name="close"
                            fg="faded"
                            fgHover="link" onClick={() => tapeImporter.toggleExpanded(item)}
                        />
                    </Flex>
                </Flex>

                <Flex mb="1" flexDirection="column" alignItems="center">
                    { skipped && (
                        <Box mb="2" textAlign="center" fontSize="lg">
                            This tape has been skipped and will not be processed.
                            <Box mt="2">
                                <Button
                                    onClick={unskip}
                                    size="sm"
                                    type="link"
                                >It was a mistake. Undo skip.</Button>
                            </Box>
                        </Box> 
                    ) }
                    { !skipped && step === "match-options" && (
                        <MatchOptions
                            item={item}
                            exState={exState}
                            setExState={setExState}
                            skip={skip}
                            accept={doAccept}
                            accepting={accepting}
                        />
                    ) }
                    { !skipped && step === "ask-agg" && (
                        <AskAgg exState={exState} setExState={setExState} />
                    ) }
                    { !skipped && step === "seller-options" && (
                        <NonAggIdentifySeller exState={exState} setExState={setExState} />
                    ) }
                    { !skipped && step === "mapping" && (
                        <Mapping
                            exState={exState}
                            item={item}
                            setOrides={setOrides}
                            setExState={setExState}
                            accepting={accepting}
                            accept={doAccept}
                        />
                    ) }
                </Flex>
            </Flex>
        </>
    )
}
