import { Batch, BatchItem } from '../types';
import { mergeBatch } from '../merge-batch';
import { onAssignedOrSkipped } from './on-assigned-or-skipped';
import { autoAssign, canAutoAssignTheUnassigned } from '../assign';

export const bulkConfirmTopSuggestionsAsIs = (batch:Batch) => {
    if (!canAutoAssignTheUnassigned(batch)) return;
    const nextItems = batch.items.map((x):BatchItem => {
        if (x.assigned || x.error || x.skipped) return x;
        const assigned = autoAssign(x);
        return {
            ...x,
            assigned,
            status: "TEMPLATE_ASSIGNED_AND_CONFIRMED"
        }
    })
    mergeBatch({ items:nextItems });
    return onAssignedOrSkipped();
}