import React, { useState, useEffect } from 'react';
import { Box, Text, Button, modal, FlexVCenter, Input } from 'mui';
import { useZStateList } from 'hooks';
import { rule, downloader } from 'services';
import { ChecklistsTable } from './checklists-table';
import { NewChecklistModal } from './new-checklist-modal';
import { UploadModal } from './upload/upload-modal';

export const ListChecklists = () => {

    const [ filterText, setFilterText ] = useState('');
    const { data:unfilteredChecklists, selectNewItem, clearItem } = useZStateList(rule.zChecklists);

    const unfiltered = rule.toChecklistsWithModels(unfilteredChecklists);

    const txt = filterText.trim().toLowerCase();
    const checklists = unfiltered.filter(x => {
        if (x.model && x.model.name.toLowerCase().includes(txt)) return true;
        const name = x.checklist.name;
        const desc = x.checklist.description ?? '';
        return name.toLowerCase().includes(txt) || desc.toLowerCase().includes(txt);
    })

    const addChecklistModal = () => {
        selectNewItem();
        modal.open({
            title: 'New Checklist',
            render: <NewChecklistModal />,
            onClose: () => {
                console.log('---clear it!');
                clearItem();
            }
        })
    }

    // const onUpload = async (files:File[]) => {
    //     if (!unfilteredChecklists) return;
    //     if (files.length !== 1) return;

    //     const file = files[0];
    //     const json = await file.text();

    //     modal.open({
    //         title: 'Upload Checklist',
    //         size:'md',
    //         render: <UploadModal json={json} existingChecklists={unfilteredChecklists} />
    //     })
    // }

    const downloadTable = () => {
        const rows = checklists.map(x => {
            return [
                x.checklist.refId,
                x.checklist.name,
                x.checklist.description ?? '',
                x.checklist.tags.join(', '),
                x.model?.name ?? ''
            ]
        })
        downloader.downloadCsvFile([
            [
                'Ref Id',
                'Name',
                'Description',
                'Tags',
                'Model'
            ],
            ...rows
        ], 'checklists.csv');
    }

    const openUploadModal = () => {
        modal.open({
            title: 'Upload Checklist',
            size:'lg',
            static: true,
            render: <UploadModal />
        })
    }

    return (
        <Box>
            <FlexVCenter justifyContent="space-between" mb="2">
                <FlexVCenter>
                    <Input
                        placeholder="Filter Checklists"
                        appendIcon="search"
                        value={filterText}
                        setValue={setFilterText}
                    />
                    <Box fg="default" ml="1">
                        { checklists.length }
                        <Text fg="faded">&nbsp;Checklist{ checklists.length === 1 ? '' : 's' }</Text>
                    </Box>
                </FlexVCenter>
                <FlexVCenter>
                    <Button icon="download" type="link" mr="1" onClick={downloadTable}></Button>
                    <Button
                        mr="1"
                        size="sm"
                        type="default"
                        onClick={openUploadModal}
                        icon="upload"
                    >Upload</Button>
                    {/* <FileSelectButton
                            mr="1"
                            size="sm"
                            type="default"
                            onSelect={onUpload}
                            accept=".json"
                            multiple={false}
                            icon="upload"
                    >Upload</FileSelectButton> */}
                    <Button size="sm" icon="plus" onClick={addChecklistModal}>Add Checklist</Button>
                </FlexVCenter>
            </FlexVCenter>
            { <ChecklistsTable checklists={checklists} /> }
        </Box>
    )
}