import { LoanAndPriceSheet } from './types';
import { format } from 'services';

type DictOfNumbers = {[x:string]:number};

/*
    This stuff is all for d3 to create a chart.
*/

// export const getKeys = (loansAndPriceSheets:LoanAndPriceSheet[]) => loansAndPriceSheets.map(x => x.loan.id);
// export const getTicks = (loansAndPriceSheets:LoanAndPriceSheet[]) => loansAndPriceSheets[0].rows[0].length - 1;


export interface LineChartDataSeries {
    readonly label:string;
    readonly vals:{ v:number }[];
}

export interface LineChartData {
    readonly series:LineChartDataSeries[];
    readonly dates:Date[];
}

const createLineChartDataSeries = (
    loansAndPriceSheets:LoanAndPriceSheet[],
    objArr:any[],   // MUTATES
    maxArr:number[], // MUTATES
    acqDateIndex:number,
    acquisitionDates:Date[]
) => {

    // THIS MUTATES objArr and maxArr.
    // Because of the way charting (rechards AND d3) want stuff...
    // I don't like how they want stuff (too many keys instead of just arrays of numbers) but whateva

    const firstLoan = loansAndPriceSheets[0];
    const deliveryDates = firstLoan.deliveryDates; // X AXIS
    const acqDate = acquisitionDates[acqDateIndex];
    const key = format.dateToYyyyMmDd(acqDate);

    // Step 1: find min bps per loan per acq date
    const minBpsPerLoanPerAcqDate:{[x:string]:number} = {};
    loansAndPriceSheets.forEach(item => {
        minBpsPerLoanPerAcqDate[item.loan.id] = Math.min(...item.rows[acqDateIndex].filter(x => x !== null) as number[]);
    })

    // Step 2: Get the values
    let maxVal = 0;
    deliveryDates.forEach((deliveryDate, deliveryDateIndex) => {
        const sum = loansAndPriceSheets.reduce((prev, curr) => {
            // const cols = curr.rows[acqDateIndex];
            const minBps = minBpsPerLoanPerAcqDate[curr.loan.id];
            const rawVal = curr.rows[acqDateIndex][deliveryDateIndex];
            const val = rawVal === null ? 0 : ((rawVal-minBps) / 100) * curr.loan.currentBalance;
            return prev + val;
        }, 0)
        maxVal = sum > maxVal ? sum : maxVal;
        objArr[deliveryDateIndex][key] = sum === 0 ? null : sum.toFixed(2);
    })
    maxArr[acqDateIndex] = maxVal;

}

export const toLineChartData = (loansAndPriceSheets:LoanAndPriceSheet[]) => {

    const firstLoan = loansAndPriceSheets[0];
    // pretty sure you couldn't be here unless you had a fannie offer, so, this should work..but it is gross.
    const firstFannieOffer = firstLoan.loan.offers?.find(x => x.investor === 'Fannie'); // yuck
    const defaultAcquisitionDate = firstFannieOffer?.acquisitionDate;
    const acquisitionDates = firstLoan.acquisitionDates;
    const deliveryDates = firstLoan.deliveryDates; // X AXIS
    
    // These two arrays get modified
    const maxVals = acquisitionDates.map(_ => 0);
    const objArr = deliveryDates.map(dt => ({ deliveryDate: dt }));

    acquisitionDates.forEach((_, n) => createLineChartDataSeries(loansAndPriceSheets, objArr, maxVals, n, acquisitionDates));

    let maxVal = 0;
    let maxValAcqDate = acquisitionDates[0];
    maxVals.forEach((val, n) => {
        // Find max val AND date from which it came
        if (maxVal < val) {
            maxVal = val;
            maxValAcqDate = acquisitionDates[n];
        }
    })

    return {
        // acquisitionDates,
        // keys: acquisitionDates.map(dt => format.dateToMonthAndDay(dt)),
        keys: acquisitionDates.map(dt => format.dateToYyyyMmDd(dt)),
        deliveryDates,
        objArr,
        maxVal: Math.round(maxVal),
        maxValAcqDate: format.dateToYyyyMmDd(maxValAcqDate),
        defaultAcquisitionDate: defaultAcquisitionDate ? format.dateToYyyyMmDd(defaultAcquisitionDate) : null
    }

    // return {
    //     dates: deliveryDates,
    //     series: acquisitionDates.map((_, n) => createLineChartDataSeries(loansAndPriceSheets, n))
    // }

}
