import React from 'react';
import { FrmDropdown, FrmTextarea, FrmInput, Box } from 'mui';
import { JSONSchema } from 'market-dto';
import { missingTitle, getItemsFromOneOf } from './schema-ui.service';


interface Props {
    readonly val:string;
    readonly editting:boolean;
    readonly schema:JSONSchema;
    readonly setValue:(x:string) => any;
}
export const SchemaUiItemString = ({ val, schema, setValue, editting }:Props) => {

    if (schema.oneOf) {
        // A non-multi (single item) dropdown
        const items = getItemsFromOneOf(schema);
        return (
            <Box ml="2">
                <FrmDropdown
                    size="sm"
                    label={schema.title ? schema.title : missingTitle}
                    desc={schema.description}
                    subLabel={schema.sample ? 'Example:' : undefined}
                    subDesc={schema.sample}
                    disabled={!editting}
                    items={items}
                    value={items.find(x => x.value === val)}
                    setValue={setValue}
                />
            </Box>
        )
    } else if (schema.enum) {
        const items = schema.enum.map(x => {
            return {
                label: x,
                value: x
            }
        })
        return (
            <Box ml="2">
                <FrmDropdown
                    size="sm"
                    label={schema.title ? schema.title : missingTitle}
                    desc={schema.description}
                    subLabel={schema.sample ? 'Example:' : undefined}
                    subDesc={schema.sample}
                    disabled={!editting}
                    items={items}
                    value={items.find(x => x.value === val)}
                    setValue={setValue}
                />
            </Box>
        )
    }

    if (schema.multiLine) {
        return (
            <Box ml="2">
                <FrmTextarea
                    size="sm"
                    readOnly={!editting}
                    label={schema.title ? schema.title : missingTitle }
                    desc={schema.description}
                    subLabel={schema.sample ? 'Example:' : undefined}
                    subDesc={schema.sample}
                    value={val ? val : ''}
                    setValue={setValue}
                />
            </Box>
        )
    }

    // ...just a simple string input
    return (
        <Box ml="2">
            <FrmInput
                size="sm"
                readOnly={!editting}
                label={schema.title ? schema.title : missingTitle }
                desc={schema.description}
                subLabel={schema.sample ? 'Example:' : undefined}
                subDesc={schema.sample}
                value={val ? val : ''}
                setValue={setValue}
            />
        </Box>
    )
}