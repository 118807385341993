import React from 'react';
import { Text, FgColor, CssGrid, Box, Flex, Button } from 'mui';
import { NoOffers } from './no-offers';
import { OfferRows } from './offer-rows';
import { LoanRow } from './loan-row';
import { GQL, loanModel } from 'market-dto';
import { useInViewport } from 'hooks';
import { sheets } from 'services';
import { getFilteredOffers, getOfferState } from 'services/loans';
import { DisplayLoanValue } from 'components';

interface LoanCardProps {
    readonly loan:GQL.Loan;
    readonly filters:sheets.LoanFilters;
    readonly canCheck:boolean;
}

const BottomLeft = ({ loan, filters, canCheck }:LoanCardProps) => {
    // You are not direct descendants in a css grid, do whatever you like!
    return (
        <>
            <Flex>
                <Box textAlign="left">
                    <DisplayLoanValue loan={loan} fieldId="loanNumber" filters={filters} />
                </Box>
                <Box flex="1" textAlign="right" fg="loud"> { loan.fico }F</Box>
            </Flex>
            <Flex>
                <Box textAlign="left" ucase>{ loanModel.formatValAs(loan.loanPurpose, 'loanPurpose') }</Box>
                <Box flex="1" textAlign="right" ucase>{ loanModel.formatValAs(loan.loanType, 'loanType') }</Box>
            </Flex>
            <Flex>
                <Box textAlign="left" ucase>{ loanModel.formatValAs(loan.occupancy, 'occupancy') }</Box>
                <Box flex="1" textAlign="right" ucase>{ loanModel.formatValAs(loan.propertyType, 'propertyType') }</Box>
            </Flex>
            <Flex>
                <Box flex="1" textAlign="left" ucase>{ loan.aus === 'UNKNOWN' ? '' : loanModel.formatValAs(loan.aus, 'aus') }</Box>
                <Box flex="1" textAlign="right" ucase>{ loan.state }</Box>
            </Flex>
            {/* <Box flex="1" textAlign="center">
                <Button type="link" size="sm">Compare Offers</Button>
            </Box> */}
        </>
    )
}

// export const LoanCard = React.memo(({ loan }:LoanCardProps) => {

export const LoanCard = ({ loan, filters, canCheck }:LoanCardProps) => {


    

    // const offers = getFilteredOffers(loan);
    // const hasOffers = offers.length > 0;

    // const hasOffers = loan.offers && loan.offers.length > 0;

    // const badReasons = getTrueIneligibilityReasons(loan);

    const { ref, inView } = useInViewport();
    const { ineligibleReasons, hasDisplayOffers, displayOffers } = getOfferState(loan);
    /*
        if global setting to hide, hide.

    */
    
    return (
        <Box mb="2" ref={ref} style={{ minHeight: '120px' }}>
            { inView && <>
                <CssGrid cols={5} gap="0">

                    <LoanRow loan={loan} filters={filters} />

                    <Box p="2" bg="card" fg="faded" textAlign="right" roundedEdges="bottom-left">
                        <BottomLeft loan={loan} filters={filters} canCheck={canCheck} />
                    </Box>
                    <Box
                        style={{ gridColumnEnd: 'span 4' }}
                        bg={hasDisplayOffers ? 'inner-card' : 'card'}
                        roundedEdges="bottom-right"
                    >
                        { !hasDisplayOffers && <NoOffers ineligibleReasons={ineligibleReasons} /> }
                        { hasDisplayOffers && (
                            <OfferRows
                                loan={loan}
                                offers={displayOffers}
                                canCheck={canCheck}
                            />
                        ) }
                    </Box>
                </CssGrid>
            </>}
        </Box>
    )
}
