import { CashWindow } from 'market-dto';
import { HitLoan, HitInfo } from './types';

const updateLoanWithRealtimeCashwindow = (hitLoan:HitLoan, priceSheet:CashWindow.PriceSheet):HitLoan => {
    if (!hitLoan.investorProductId || !hitLoan.commitmentDays) {
        //console.log('missing something', hitLoan);
        return hitLoan;
    }
    const result = CashWindow.cashWindowPriceLookup(
        hitLoan.investorProductId,
        hitLoan.commitmentDays,
        // hitLoan.noteRate,
        hitLoan.coupon ?? 0,
        priceSheet.cashWindow
    )
    if (!result) {
        //console.log('missing something part2', hitLoan.investorProductId, hitLoan.commitmentDays, hitLoan.noteRate, priceSheet.cashWindow);
        return hitLoan;
    }
    return {
        ...hitLoan,
        cashWindow: {
            price: hitLoan.cashWindow.price,
            realtimePrice: result.price,
            realtimeAsOf: result.asOf
        }
    }
}

export const updateWithRealtimeCashwindow = (info:HitInfo, priceSheet:CashWindow.PriceSheet):HitInfo => {
    const nextLoans = info.loans.map(loan => updateLoanWithRealtimeCashwindow(loan, priceSheet));
    return {
        ...info,
        loans: nextLoans
    }
}