// Note: type all returning objects as GQLLoan, even if not fully there.

export const RETURNING = `
    id
    sheetId
    sellerOrgId
    loanNumber
    sellerName
    borrowerName
    productCode
    originalBalance
    currentBalance
    noteRate
    coupon
    term
    otm
    rtm
    age
    balloonTerm
    remainingInterestOnlyPeriod
    amortizationType
    ltv
    cltv
    miCoverage
    fico
    dti
    loanPurpose
    occupancy
    propertyType
    units
    state
    escrowWaived
    aus
    tpo
    fundedDate
    firstPaymentDueDate
    nextPaymentDueDate
    eligibility
    ineligibleFor
    docType
    highBalance
    specialtyProgram
    loanType
    pmiType
    rateType
    armInitFixedTerm
    rowNumber
    rawProductCode
    monthlyIncome
    yearlyIncome
    uli
`;