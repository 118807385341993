import { QualifierSet } from 'raccoon-engine'
import { gql, GQL, query } from "../../../client";
import { QUALIFIER_RETURNING } from "./qaulifier-returning"


interface QueryInput {
	readonly where:GQL.WhereString;
}

interface QueryResult {
	readonly programQualifier:QualifierSet[];
}

const QUERY = gql`
    query fetchProgramQualifier($where: where_programQualifier_exp!) {
        programQualifier(where:$where) { ${QUALIFIER_RETURNING} }
    }
`;

export const fetchQualifierSet = async (id:string):Promise<QualifierSet> => {
	const result = await query<QueryInput, QueryResult>(QUERY, { where: { id: { _eq: id } } })
	const [e, ...rest] = result.data.programQualifier
	return e
}
