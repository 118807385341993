import React from 'react';
import { tapeImporter, urls } from 'services';
import { ImportTable } from './import-table/import-table';
import { Button, Box, FlexVCenter, IntoPortal } from 'mui';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly onCancel:() => void;
    readonly processing:boolean;
    readonly nextStep:() => void;
}
export const BatchView = ({ batch, processing, onCancel, nextStep }:Props) => {

    return (
        <>
            <IntoPortal id="import-button-area">
                <FlexVCenter>
                    { batch.step === "DONE" && (
                        <Button
                            size="sm"
                            type="default"
                            onClick={onCancel}
                        >New Import</Button>
                    ) }
                    { batch.step !== "DONE" && (
                        <Button
                            size="sm"
                            type="link"
                            onClick={onCancel}
                        >Cancel Import</Button>
                    ) }
                </FlexVCenter>
            </IntoPortal>

            <ImportTable batch={batch} nextStep={nextStep} />

        </>
    )
}