import React from 'react';
import { proposal } from 'services';
import { GQL } from 'market-dto';
import { modal, IntoPortal, Alert } from 'mui';
import { useNavigate } from 'react-router-dom';
import { HitInfo } from 'services/proposal';
import { TopSummary } from './top-summary';
import { CommitmentTable } from './commitment-table';


interface Props {
    readonly sheet:GQL.Sheet;
    readonly hitInfo?:HitInfo;
    readonly importing?:boolean;
}
export const Commitment = ({ sheet, hitInfo, importing }:Props) => {

    if (!hitInfo) throw new Error('Missing hit info!');

    // const searchState = proposal.searchState.get();
    // const trueImport = importing && sheet.id === searchState?.hitInfo.sheetSummary.sheetId;

    const navigate = useNavigate();
    
	const close = () => {
        if (importing) {
            // proposal.searchState.update(x => undefined);
            navigate('/import/hits');
        } else {
            navigate('/tapes');
        }
    }

    // const onTradeCreated = () => {
    //     modal.close();
    //     navigate('/tape/' + sheet.id + '/trade/new');
    // }
    // const { data:hitInfo, loading } = useApiFetch(async () => {
    //     const propDetails = await fetchProposalDetails(sheet.id);
    //     if (!propDetails) return;
    //     return proposal.toConfirmHitInfo(propDetails);
    // }, [sheet.id])
    // if (loading || !hitInfo) return <SpinLabel mt="2">Loading...</SpinLabel>
    // hitInfo.hitCount
    
    return (
        <>
            <IntoPortal id="tape-details-top-summary">
                <TopSummary
                    hitInfo={hitInfo}
                    onClose={close}
                />
            </IntoPortal>

            { hitInfo.hitCount === 0 && (
                <Alert>
                    This tape does yet have any hits.
                </Alert>
            ) }
            { hitInfo.hitCount > 0 && (
                <CommitmentTable hitInfo={hitInfo} />
            ) }
        </>
    )
}