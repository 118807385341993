import { mutate, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const DELETE = gql`
    mutation delTemp($where: template_bool_exp!) {
        delete_template(where: $where) {
            errorMessage
            affectedRow
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly delete_template: {
        readonly affectedRow:number;
    }
}

export const deleteTemplate = async (templateId:string):Promise<boolean> => {
	const result = await mutate<MutateInput, MutateOutput>(DELETE, { where: {
        id: {
            _eq: templateId
        }
    }})
    return result?.data?.delete_template.affectedRow === 1 ? true : false;    
}
