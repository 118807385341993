import React from 'react';
import { rule } from 'services';
import { usePageSortTable, TablePageButtons, SortableTableHeader } from 'mui';
import { useNavigate } from 'react-router-dom';

interface DisplayTableItem {
    readonly id:string;
    readonly name:string;
    readonly modelName:string;
}

interface Props {
    readonly samples:rule.SampleWithModel[];
}
export const SamplesTable = ({ samples }:Props) => {

    const {
        sortedRows,
        buttons,
        pageSortState:ps,
        sortBy,
        page,
        setPage,
        totalPages
    } = usePageSortTable<DisplayTableItem, keyof DisplayTableItem>({
        rows: samples.map((x):DisplayTableItem => {
            return {
                id: x.sample.id,
                modelName: x.model?.name ?? '',
                name: x.sample.name
            }
        }),
        // one of these days...make this array of deps...
        keyFn: x => x.id + '^' + x.name + '^' + x.modelName,
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const navigate = useNavigate();
    const load = (id:string) => navigate('/rules/samples/' + id);
    
    return (
        <>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render="Name" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="modelName" render="Model" />
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((row, n) => (
                        <tr key={row.id} onClick={() => load(row.id)} style={{ cursor: 'pointer' }}>
                            <td>{ row.name }</td>
                            <td>{ row.modelName }</td>
                        </tr>
                    )) }
                </tbody>
            </table>
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}