import { GQL, CashWindow, util } from 'market-dto';
import { exploreOptions } from '../fannie';
import { LoanAndPriceSheet } from './types';
import { getLoansWithFannieOffers } from '../loans';
import { isWeekend } from 'date-fns';

const extractBasePrice = (offer: GQL.Offer) => {
	if (!offer.priceTag) {
		console.log('NO PRICE TAG FOUND');
		return 0;
	}
	const cashWindowTag = offer.priceTag.tags.find(x => x.type === 'CASH_WINDOW')
	const interestSpreadTag = offer.priceTag.tags.find(x => x.type === 'INTEREST_SPREAD');
	const result = (cashWindowTag?.price ?? 0) + (interestSpreadTag?.price ?? 0);
	return result;
}

export const toLoansAndPricesSheets = (sheet:GQL.LoanModelSheet, priceSheet:GQL.PriceSheet) => {
    const loans = getLoansWithFannieOffers(sheet);
    const priceSheetDict = util.toDict(priceSheet.cashWindow, x => x.id);


    return loans.map((loan):LoanAndPriceSheet => {
        const fannieOffer = loan.offers?.find(x => x.investor === 'Fannie'); // yuck
        if (!fannieOffer) throw new Error('Expected fannie offer');
        const basePrice = extractBasePrice(fannieOffer);
        const ps = priceSheetDict[fannieOffer.investorProductId ?? ''];
        const holdingInterest:CashWindow.HoldingInterest[][] = exploreOptions(fannieOffer, loan, ps);
        const woWeekends = holdingInterest.map(holdings => holdings.filter(d => !isWeekend(d.target)));

        // We just take the first one's dates. they should all match.
        const acquisitionDates = woWeekends.map(holdings => holdings[0].acquisitionDate);
        const deliveryDates = woWeekends[0].map(x => x.target);

        // console.log('JEFFF', woWeekends);

        const rows = woWeekends.map(holdings => {
            return holdings
                .map(daily => {
                    // We don't want the diff anymore, we want the total
                    const { interestSpreadPct, couldSellFor } = daily;
                    const total = interestSpreadPct + couldSellFor;
                    return isNaN(total) ? null : total;
                    // for heat map, we want diff. for chart, we want total!
                    //const diff = total - basePrice;
                    //return isNaN(diff) ? null : diff;
                })
        })
        return {
            loan,
            holdingInterest,
            productPriceSheet: ps,
            rows,
            basePrice,
            acquisitionDates,
            deliveryDates
        }
    }).filter(x => x.productPriceSheet && x.basePrice > 1) // ensure it exists
}