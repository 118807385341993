import React, { useState } from 'react';
import { Box, IconButton, Link, FlexVCenter, Button, Icon, Text, ModalSubHeader, BgColor } from 'mui';
import { mapping } from 'services';

interface SampleRowProps {
    readonly isMapped:boolean;
    readonly item:mapping.ColumnValuesAndMapping;
    readonly valuesIndex:number;
    readonly onClick:() => void;
    readonly onMouseOver:() => void;
    readonly onMouseOut:() => void;
    readonly isHover:boolean;
    readonly isInitMapped:boolean;
}

const getBg = (isMapped:boolean, isHover:boolean, isInitMapped:boolean):BgColor => {
    if (isInitMapped) return 'card';
    if (isMapped) return 'inner-card';
    return isHover ? 'card' : 'transparent';
}
    
export const ColValMapItem = ({
    item,
    valuesIndex,
    isMapped,
    // isConfirmItem,
    // isConfirming,
    isHover,
    isInitMapped,
    onClick,
    onMouseOut,
    onMouseOver
}:SampleRowProps) => {

    // const bg = getBg(isMapped, isHover, isConfirming, isConfirmItem);
    const bg = getBg(isMapped, isHover, isInitMapped);
    // const showArrow = isConfirmItem || (isHover && !isConfirming);
    const showArrow = isMapped && !isInitMapped;

    return (
        <FlexVCenter
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            cursor={isHover ? 'pointer' : 'default'}
            bg={bg}
        >
            <Box flex={mapping.ui.flexSizes[0]} fg={isMapped ? 'loud' : 'faded'} textAlign="right" p="1">{ item.colName }</Box>
            <FlexVCenter flex={mapping.ui.flexSizes[1]} p="1">
                <Text ellipsis>{ item.values[valuesIndex] }</Text>
                { showArrow && <span style={{ pointerEvents: 'none'}}><Icon name="arrow-left" fg="link" ml="1" /></span> }
            </FlexVCenter>
            <Box
                flex={mapping.ui.flexSizes[2]}
                fg={isMapped ? 'loud' : 'faded'}
                textAlign="left"
                p="1"
                textStyle={isMapped ? undefined : 'italic'}
            >{ item.mappedTo }</Box>
        </FlexVCenter>
    )
}
