import React from 'react';
import { Text, Box, CssGrid, Link, FontSize, FlexVCenter, Icon } from 'mui';
import { SectionHeader } from './section-header';
import { mapping } from 'services';
import { GQL } from 'market-dto';

interface Props {
    readonly readOnly?:boolean;
    readonly showReqMsg:boolean;
    readonly fields:mapping.Field[];
    readonly onSelect:(f:mapping.Field) => void;
    readonly highlightField?:keyof GQL.Loan;
}
export const UnmappedSection = ({ readOnly, showReqMsg, fields, onSelect, highlightField }:Props) => {

    const small = true;
    const fs:FontSize = small ? 'sm' : 'md';
    const fsSmall:FontSize = small ? 'xs' : 'sm';

    return (
        <>
            <SectionHeader
                small={small}
                mt={small ? '2' : '3'}
                label="Failed to Map"
                desc=""
            />

            <CssGrid cols={mapping.ui.fieldLayoutColumns * (readOnly ? 1 : 2)} gap="1">
                { fields.map((f, n) => (
                    <React.Fragment key={n}>

                        
                        <FlexVCenter
                            fg={highlightField === f.id ? "accent" : "default"}
                            justifyContent="flex-end"
                            textAlign="right"
                            fontSize={fs}
                        >
                            { highlightField === f.id && <Icon name="arrow-right" fg="success" mr="1" /> }
                            { showReqMsg && f.required && <Text fg="danger" fontSize={fsSmall}>(required)&nbsp;&nbsp;</Text> }
                            { f.label }
                        </FlexVCenter>
                        
                        { !readOnly && (
                            <FlexVCenter
                                fontSize={fs}
                            >
                                <Link onClick={() => onSelect(f) }>Map This Field</Link>
                                { highlightField === f.id && <Icon name="arrow-left" fg="success" ml="1" /> }
                            </FlexVCenter>
                        ) }
                        
                    </React.Fragment>
                )) }
            </CssGrid>
        </>
    )
}
