import React from 'react';
import { Text, Box, FlexVCenter } from 'mui';
import { GQL, ImportModel } from 'market-dto';

const ORIDE_FIELDS_TO_DISPLAY = 3;

interface Props {
    readonly model:ImportModel;
    readonly template:GQL.Template;
    readonly dupe:boolean;
}
export const OverrideCol = ({ model, template, dupe }:Props) => {

    const orides = template.mapping?.overrides ?? {};
    const orideAmount = Object.keys(orides).length;
    const orideFields = Object.keys(orides).map(k => model.getLabel(k));

    // Zero overrides means you are using global config!
    if (orideAmount === 0) return <td><Text fg="faded" textStyle="italic">None</Text></td>

    const more = orideAmount - ORIDE_FIELDS_TO_DISPLAY;
    const overLimit = orideAmount > ORIDE_FIELDS_TO_DISPLAY;
    const fieldList = overLimit
        ? orideFields.slice(0, ORIDE_FIELDS_TO_DISPLAY).join(', ')
        : orideFields.join(', ')
    return (
        <td>
            <FlexVCenter justifyContent="space-between">
                <Box>
                    <Text fg="faded" pr="1">({ orideAmount })</Text>
                    { fieldList }
                    { overLimit && <Text pl="1" fg="really-faded">+{ more } more</Text> }
                </Box>
                { dupe && <Box fg="accent" fontSize="xs" ml="1" px="1" bg="card">Dupe</Box> }
            </FlexVCenter>
        </td>
    )
}