import { GQL } from 'market-dto';
import { InvalidMatchResult, HashMatchConfidence } from '../types';



export const multipleSellersInAggTape = (
    template:GQL.Template,
    hashConfidence:HashMatchConfidence
):InvalidMatchResult => {
    // This is a problem.
    // This breaks the assumption that the tape itself will have one-and-only one seller in the seller column.
    return {
        type: "invalid",
        matchState: "AGG_TEMPLATE_BUT_MUTLIPLE_SELLERS_IN_TAPE",
        //canBeUsed: false,
        template,
        templateOverrideHash: 'invalid',
        hashConfidence
    }
    
}