import React from 'react';
import { GQL } from 'market-dto';
import { format } from 'services';
import { Checkbox, Box, Icon, usePageSortTable, SortableTableHeader, Flex, TablePageButtons, modal } from 'mui';

interface CalTableProps {
    readonly cals:GQL.SettlementCalendar[];
    readonly onEdit:(cal:GQL.SettlementCalendar) => void;
    readonly checkedDict:{ [x:string]:boolean };
    readonly onToggleChecked:(id:string) => void;
    readonly onToggleAllChecked:(allChecked:boolean) => void;
}
export const CalTable = ({ cals, onEdit, checkedDict, onToggleChecked, onToggleAllChecked }:CalTableProps) => {

    const allChecked = cals.length > 0 && !cals.some(cal => !checkedDict[cal.id]);

    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<GQL.SettlementCalendar, keyof GQL.SettlementCalendar>({
        rows: cals,
        keyFn: row => JSON.stringify(row), // any change to anything in row, re-render
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    return (
        <Box mt="1">
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th style={{ width:'1%' }}>
                            <Checkbox
                                cursor="pointer"
                                checked={allChecked}
                                onToggle={() => onToggleAllChecked(allChecked)}
                            />
                        </th>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="date" render="Date" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="day" render="Day" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="week" render="Week of Month" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="classA" render="Class A" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="classB" render="Class B" />
                        <th style={{ width:'1%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map(cal => (
                        <tr key={cal.id}>
                            <td>
                                <Checkbox
                                    cursor="pointer"
                                    checked={checkedDict[cal.id]}
                                    onToggle={() => onToggleChecked(cal.id)}
                                />
                            </td>
                            <td>{ format.toDate(cal.date) }</td>
                            <td>{ cal.day }</td>
                            <td>{ cal.week }</td>
                            <td>{ cal.classA ? 'Yes' : 'No' } </td>
                            <td>{ cal.classB ? 'Yes' : 'No' } </td>
                            <td>
                                <Flex justifyContent="flex-end">
                                    <Icon
                                        name="edit"
                                        cursor="pointer"
                                        fg="default"
                                        fgHover="link"
                                        onClick={() => onEdit(cal)}
                                    />
                                </Flex>
                            </td>
                        </tr>
                    )) }
                </tbody>
            </table>
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </Box>

    )
}