import { query, gql, GQL } from "../../client";
import { RETURNING, toTemplateAndConfidence } from './returning';
import { TemplateAndConfidence } from 'services/tape-importer';
import { utils } from 'services';
import { Hashes, ModelType } from 'market-dto';

const QUERY = gql`
    query (
        $hashWhere: template_bool_exp!,
        $sequenceWhere: template_bool_exp!,
        $orderWhere: template_bool_exp!,
        $mappedWhere: template_bool_exp!
    ) {
        hash: template(where: $hashWhere) { ${ RETURNING } }
        sequenceHash: template(where: $sequenceWhere) { ${ RETURNING } }
        orderHash: template(where: $orderWhere) { ${ RETURNING } }
        mappedHash: template(where: $mappedWhere) { ${ RETURNING } }
    }
`;

interface QueryResult {
    readonly hash:GQL.Template[];
    readonly sequenceHash:GQL.Template[];
    readonly orderHash:GQL.Template[];
    readonly mappedHash:GQL.Template[];
}

interface QueryInput {
    readonly hashWhere:GQL.WhereWhatever;
    readonly sequenceWhere:GQL.WhereWhatever;
    readonly orderWhere:GQL.WhereWhatever;
    readonly mappedWhere:GQL.WhereWhatever;
}

export const fetchAllMatchingTemplates = async (
    modelType:ModelType,
    { h0, h1, h2, h3 }:Hashes
):Promise<TemplateAndConfidence[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        hashWhere: {
            modelType: {
                _eq: modelType
            },
            hashes: {
                _hasAny: [h0]
            }
        },
        sequenceWhere: {
            modelType: {
                _eq: modelType
            },
            sequenceHashes: {
                _hasAny: [h1]
            }
        },
        orderWhere: {
            modelType: {
                _eq: modelType
            },
            orderHashes: {
                _hasAny: [h2]
            }
        },
        mappedWhere: {
            modelType: {
                _eq: modelType
            },
            mappedHashes: {
                _hasAny: [h3]
            }
        }
    })

    // strip out duplicates of lower confidence
    const arr0 = result.data.hash.map(x => toTemplateAndConfidence("highest", x));
    const arr1 = result.data.sequenceHash.map(x => toTemplateAndConfidence("very-high", x));
    const arr2 = result.data.orderHash.map(x => toTemplateAndConfidence("high", x));
    const arr3 = result.data.mappedHash.map(x => toTemplateAndConfidence("medium-high", x));

    const dict0 = utils.toDict(arr0, x => x.template.id);
    const dict1 = utils.toDict(arr1, x => x.template.id);
    const dict2 = utils.toDict(arr2, x => x.template.id);

    const arrs = [
        arr0,
        arr1.filter(x => !dict0[x.template.id]),
        arr2.filter(x => !dict0[x.template.id] && !dict1[x.template.id]),
        arr3.filter(x => !dict0[x.template.id] && !dict1[x.template.id] && !dict2[x.template.id])
    ]

    return arrs.flat();
}