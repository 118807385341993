import React from 'react';
import { Box } from '../box/box';
import { CssGridBox } from '../css-grid/css-grid';
import { SpectrumGridItem } from './spectrum-grid-item';
import './spectrum-grid.css';
// import { } from 'chroma-js';
import * as d3 from 'd3';

export interface SpectrumGridItemDetails {
    readonly rowIndex:number;
    readonly colIndex:number;
    readonly val:number|null;
    readonly isMax:boolean;
    readonly isRowMax:boolean;
    readonly isBaseline:boolean;
    readonly isMin:boolean;
    readonly isRowMin:boolean;
}

const getMax = (arr:Array<number|null>):number|null => {
    const nums = arr.filter(x => x !== null) as number[];
    if (nums.length === 0) return null;
    return Math.max(...nums);
}

const getMin = (arr:Array<number|null>):number|null => {
    const nums = arr.filter(x => x !== null) as number[];
    if (nums.length === 0) return null;
    return Math.min(...nums);
}

const black = '#000000';
// const gray = '#bbbb00';
// const green = '#33ee44';
// const green2 = '#66ff77';
// const red = '#CC3333';
// const orange = "#ff7733";
// const bluegreen = '#339944';
// const blue = "#446699";

const getColorFn = (minVal:number|null, baseline:number, maxVal:number|null) => {
    if (minVal === null || maxVal === null) return undefined;
    const halfMax = maxVal / 2;
    return d3.scaleLinear<string>()
        .domain([minVal, baseline, halfMax, maxVal])
        .range(["#770000", black, "#004422", "#66ee66"])
        .interpolate(d3.interpolateCubehelix.gamma(1))
}

interface Props {
    readonly xAxis:string[];
    readonly yAxis:string[];
    readonly rows:Array<Array<number|null>>;
    readonly xDividerAfter:number[];
    readonly yDividerAfter:number[];
    readonly baseline?:number;
    readonly renderHover?:(item:SpectrumGridItemDetails) => React.ReactNode;
    readonly onClick?:(item:SpectrumGridItemDetails) => void;
    readonly onHover?:(item:SpectrumGridItemDetails) => void;
}
export const SpectrumGrid = ({
    xAxis,
    yAxis,
    xDividerAfter,
    yDividerAfter,
    rows,
    baseline = 0,
    onHover,
    onClick,
    renderHover
}:Props) => {

    const maxPerRow = rows.map(cols => getMax(cols));
    const minPerRow = rows.map(cols => getMin(cols));
    const maxVal = getMax(maxPerRow);
    const minVal = getMin(minPerRow);
    const getCol = getColorFn(minVal, baseline, maxVal);

    if (maxVal === null || minVal === null) {
        console.log('NO DATA TO DISPLAY (spectrum grid max or min was null)');
        return null;
    }

    const totalColCnt = xAxis.length + 1 + xDividerAfter.length;

    return (
        // <CssGridBox cols={xAxis.length + 1} gap="0" rowGap="0"> // OOPPSS!
        <CssGridBox cols={totalColCnt} gap="0" rowGap="0">
            <div></div> {/* important! leave it. */}
            { xAxis.map((x, colIndex) => (
                <React.Fragment key={colIndex}>
                    <Box textAlign="center" css="spectrum-grid-x-axis-item">{ x }</Box>
                    { xDividerAfter.includes(colIndex) && <div className="spectrum-grid-x-divider"></div> }
                </React.Fragment>
            )) }
            { rows.map((cols, rowIndex) => {
                return <React.Fragment key={rowIndex}>
                    <Box textAlign="right" css="spectrum-grid-y-axis-item">{ yAxis[rowIndex] }</Box>
                    { cols.map((val, colIndex) => {
                        const item:SpectrumGridItemDetails = {
                            val,
                            rowIndex,
                            colIndex,
                            isBaseline: val !== null && baseline === val,
                            isMax: val !== null && val === maxVal,
                            isMin: val !== null && val === minVal,
                            isRowMax: val !== null && val === maxPerRow[rowIndex],
                            isRowMin: val !== null && val === minPerRow[rowIndex]
                        }
                        return <React.Fragment key={rowIndex + '.' + colIndex}>
                            <SpectrumGridItem
                                color={item.val && getCol ? getCol(item.val) : undefined}
                                renderHover={renderHover}
                                item={item}
                                onClick={onClick}
                                onHover={onHover}
                            />
                            { xDividerAfter.includes(colIndex) && <div className="spectrum-grid-x-divider"></div> }
                        </React.Fragment>
                    })}
                    { yDividerAfter.includes(rowIndex) && (
                        <div
                            className="spectrum-grid-y-divider"
                            style={{ gridColumn: '1 / span ' + totalColCnt }}
                        ></div>
                    )}
                    
                </React.Fragment>
            })}
        </CssGridBox>
    )
}
