import React, { useState, useEffect } from 'react';
import { Box, PortalPlaceholder, Icon, FlexVCenter } from 'mui';
import { GQL, ImportModel } from 'market-dto';
import { XlsExportTableContainer } from './xls-export-table-container';
import { XlsBtn } from './components';
import { genFlowRunOutputSheet } from './gen-flow-run-output-sheet';
import { useStickyStateWithDefault, useApiLoad } from 'hooks';
import { closePopOver } from './components';
import './css/index.css';


interface Props {
    readonly model:ImportModel;
    readonly flowRun:GQL.FlowRun;
    readonly data:GQL.TaskXlsExport;
    readonly readOnly:boolean;
    readonly saving:boolean;
    readonly dirty:boolean;
    readonly onReset:() => void;
    readonly onChange:(x:GQL.TaskXlsExport) => void;
    readonly onSave:() => void;
    readonly onToggleReadonly?:() => void;
}
export const XlsExportViewer = ({
    model,
    flowRun,
    data,
    saving,
    dirty,
    readOnly,
    onToggleReadonly,
    onReset,
    onChange,
    onSave
}:Props) => {

    const { config } = data;
    const { tabs } = config;

    const [ hideEmptyCols, setHideEmptyCols ] = useStickyStateWithDefault("hide-empty-cols", false);

    const [ tab, setTab ] = useState<GQL.TaskXlsExportTabConfig>(() => {
        return data.config.tabs[0];
    })

    useEffect(() => {
        // just in case they do back button
        return () => closePopOver();
    }, [])

    const save = () => {
        if (readOnly) return;
        if (saving) return;
        onSave();
    }

    // We have two states: the entire config, and the selected tab cfg.
    // When they update tab cfg, we have to update pointer to the newly update object (setActiveTabCfg)
    
    const onTabChange = (updatedTab:GQL.TaskXlsExportTabConfig) => {
        if (!readOnly) {
            onChange({
                ...data,
                config: {
                    ...data.config,
                    tabs: tabs.map(t => t.tabNo !== updatedTab.tabNo ? t : updatedTab)
                }
            })
        }
        setTab(updatedTab);
    }

    const onAddNewTab = (newTab:GQL.TaskXlsExportTabConfig) => {
        if (readOnly) return;
        onChange({
            ...data,
            config: {
                ...data.config,
                tabs: tabs.concat(newTab)
            }
        })
        setTab(newTab);
    }

    const download = () => {
        genFlowRunOutputSheet(model, hideEmptyCols, flowRun, config);
    }

    //  borderSides="top" borderWidth="thin" borderColor="alert" pt="2" mt="1"
    return (
        <Box>
            {/* <textarea readOnly value={JSON.stringify(data, null, 4)}></textarea> */}
            <div className="m-xls">
                
                { !readOnly && (
                    <>
                        <div className="m-xls-top-bar"> 
                            <div className="m-xls-top-bar-left-side">
                                Configure your output sheet here.
                                Right click cells for mapping source.
                                &nbsp;
                                { onToggleReadonly && (
                                    <a className="m-xls-link" onClick={onToggleReadonly}>Readonly View</a>
                                ) }
                            </div>
                            <div className="m-xls-top-bar-right-side">
                                <XlsBtn
                                    onClick={() => setHideEmptyCols(!hideEmptyCols)}
                                    type="default"
                                >{ hideEmptyCols ? 'Show Empty' : 'Hide Empty'}</XlsBtn>
                                &nbsp;
                                <XlsBtn
                                    onClick={save}
                                    type={dirty ? "default" : "disabled"}
                                    busy={saving}
                                >Save</XlsBtn>
                            </div>
                        </div>
                    </>
                ) }
                { readOnly && (
                    <div className="m-xls-green-strip">
                        <FlexVCenter justifyContent="space-between">
                            <XlsBtn
                                type="white-on-green"
                                onClick={download}
                                icon="download"
                            >Export</XlsBtn>
                            
                            { onToggleReadonly && (
                                <XlsBtn
                                    type="white-on-green"
                                    onClick={onToggleReadonly}
                                    icon="edit"
                                >Edit</XlsBtn>
                            ) }

                            <XlsBtn
                                onClick={() => setHideEmptyCols(!hideEmptyCols)}
                                type="white-on-green"
                            >{ hideEmptyCols ? 'Show Empty' : 'Hide Empty'}</XlsBtn>


                        </FlexVCenter>
                        
                    </div>
                ) }
                <XlsExportTableContainer
                    hideEmptyCols={hideEmptyCols}
                    model={model}
                    readOnly={readOnly}
                    onAddNewTab={onAddNewTab}
                    onSelectTab={setTab}
                    tabs={tabs}
                    tab={tab}
                    flowRun={flowRun}
                    tabNames={tabs.map(x => x.name)}
                    onChange={onTabChange}
                />
            </div>
        </Box>
    )
}
