import React from 'react';
import { GQL } from 'market-dto';

interface Props {
    readonly colCfg:GQL.XlsExportCalcEnumCol;
    readonly flowRun:GQL.FlowRun;
    readonly onAccept:(x:GQL.XlsExportCalcEnumCol) => void;
    readonly onChange:(x:GQL.XlsExportCalcEnumCol) => void;
    readonly onClose:() => void;
}
export const ExpandedEnum = ({
    colCfg,
    flowRun,
    onAccept,
    onChange,
    onClose
}:Props) => {
    return (
        <div>
            todo: make this do something. what was it meant to do?
        </div>
    )
}