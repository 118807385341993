import { GQL } from "market-dto";
import { CheckedOfferIdByLoan } from './types';
import { getLoansBestOffer } from './get-loans-best-offer';
import { zCheckedOffers } from './z-checked-offers';

export const autoCheckAllBestOffers = (sheet:GQL.LoanModelSheet) => {
    const dict:CheckedOfferIdByLoan = {}
    sheet.loans.forEach(loan => dict[loan.id] = getLoansBestOffer(loan));
    // why not the set? why the update?
    zCheckedOffers.update(x => dict);
    // zCheckedOffers.set(dict);
}