import React, { useState } from 'react';
import { modal, Button, Box, SpinLabel } from 'mui';
import * as api from 'api';
import { useApiFetch, useApiUpdate } from 'hooks';
import { utils } from 'services';
import { PopulateModal } from './populate-modal';
import { CalTable } from './cal-table';
import { CalFilters } from './cal-filters';
import { EditModal } from './edit-modal';
import { GQL }from 'market-dto';

export const SettlementCalendar = () => {

    const { loading, data:cals, loadWithoutBusyStates:refresh } = useApiFetch(api.fetchAllSettlementCalendars, []);
    const [ filter, setFilter ] = useState('');
    const [ checkedDict, setCheckedDict ] = useState<{ [x:string]:boolean }>({});
    const { busy:deleting, update:bulkDelete } = useApiUpdate(() => {
        return api.deleteSettlementCalendars(checkedDates.map(x => x.id))
    })

    if (loading) return <SpinLabel>Loading...</SpinLabel>;

    const onEdit = (cal:GQL.SettlementCalendar) => {
        modal.open({
            title: cal.date ?? 'Unknown Date',
            size: 'sm',
            render: <EditModal cal={cal} isNew={false} refresh={refresh} />
        })
    }

    const onAdd = () => {
        modal.open({
            title: 'Add New Settlement Date',
            size: 'sm',
            render: <EditModal cal={null} isNew={true} refresh={refresh} />
        })
    }

    const onBulkAdd = () => {
        modal.open({
            title: 'Populate Settlement Calendar Dates',
            size: 'md',
            render: <PopulateModal refresh={refresh} />
        })
    }

    const toggleChecked = (id:string) => {
        setCheckedDict({
            ...checkedDict,
            [id]: checkedDict[id] ? false : true
        })
    }

    const filteredCals = (cals && cals.filter(c => c.date.includes(filter.trim()))) ?? [];

    const toggleAllChecked = (currAllChecked:boolean) => {
        if (currAllChecked) {
            setCheckedDict({});
        } else {
            setCheckedDict(utils.toBoolDict(filteredCals, x => x.id, x => true));
        }
    }

    const checkedDates = filteredCals.filter(x => checkedDict[x.id]);


    const onBulkDelete = async () => {
        await bulkDelete();
        refresh();
        setCheckedDict({});
    }

    return (
        <>
            <CalFilters
                deleting={deleting}
                filter={filter}
                onFilterChange={setFilter}
                onAdd={onAdd}
                onBulkAdd={onBulkAdd}
                filterCount={filteredCals.length}
                checkedDates={checkedDates}
                onDelete={onBulkDelete}
            />
            { cals && (
                <CalTable
                    cals={filteredCals}
                    onEdit={onEdit}
                    checkedDict={checkedDict}
                    onToggleChecked={toggleChecked}
                    onToggleAllChecked={toggleAllChecked}
                />
             ) }
        </>
    )
}