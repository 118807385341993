import { GQL } from "market-dto";
import React from 'react';

interface Args {
	user:GQL.User
	jwt:string
}

export const ApiJwt = (props:Args) => {
	const { jwt } = props
	return (<>
		<div style={{ overflowWrap: "break-word", width: "480px", margin: "16px" }}>
			{jwt}
		</div>
	</>)
}
