import { query, gql, GQL } from '../../client';
import { RETURNING } from './returning';

const QUERY = gql`
    query checklists($where: checklist_bool_exp!) {
        checklist(where: $where) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryResult {
    readonly checklist:GQL.Checklist[];
}

export const fetchAllChecklists = async ():Promise<GQL.Checklist[]> => {
    const result = await query<QueryInput, QueryResult>(QUERY, {
        where: {}
    })
    return result.data.checklist.map(x => {
        return {
            ...x,
            tags: x.tags ?? [] // some where null initially (this is temporary data fix)
        }
    })
}
