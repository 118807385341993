import React from 'react';
import { Icon, Text, DotsLabel } from 'mui';
import { tapeImporter } from 'services';

interface Props {
    readonly batch:tapeImporter.Batch;
    readonly item:tapeImporter.BatchItem;
}
export const ColLoanCount = ({ batch, item }:Props) => {

    const { status, loanCount } = item;

    if (status === 'PARSING_TAPE' || status === 'AWAITING_PARSING') {
        return <Icon name="loader" fg="loud" spinning={!batch.paused} />
    }

    if (status === 'PARSE_FAILURE') {
        return <Text fg="danger">Unable to Parse</Text>
    }

    return <Text fg={status === 'SUCCESS' ? 'default' : 'faded'}>{ loanCount }</Text>
}
