import { ProposalDetails, HitInfo, HitLoan, BoolDict, CountByProductCodeByRate, SummaryGroup } from './types';
import { util } from 'market-dto';

const addLoanToSummary = (loan:HitLoan, x:SummaryGroup):SummaryGroup => {
    const coupon = loan.coupon ?? 0;
    console.log(loan, loan.coupon);
    const minCoupon:number = x.minCoupon === 0 || x.minCoupon > coupon ? coupon : x.minCoupon;
    const maxCoupon:number = x.maxCoupon === 0 || x.maxCoupon < coupon ? coupon : x.maxCoupon;
    const sum = x.sum + loan.currentBalance;
    const loanCount = x.loanCount + 1;
    return {
        ...x,
        minCoupon,
        maxCoupon,
        sum,
        loanCount
    }
}

const newSummary = (investor:string, investorProductId:string, investorProductName:string):SummaryGroup => {
    return {            
        label: investor + ' / ' + investorProductId,
        investorProductName,
        investor,
        investorProductId,
        minCoupon: 0,
        maxCoupon: 0,
        sum: 0,
        loanCount: 0        
    }
}

const getCounts = (loans:HitLoan[], checkedByLoanId:BoolDict):SummaryGroup[] => {
    const obj = loans.reduce((prev:{[x:string]:SummaryGroup}, loan:HitLoan) => {
        if (!checkedByLoanId[loan.id]) return prev;
        const investorProductName = loan.investorProductName ?? '';
        const investor = loan.investor ?? 'unknown';
        const investorProductId = loan.investorProductId ?? 'unknown';
        const k = investor + '^' + investorProductId;
        // Sum of unpaid balance
        // Range (mix~max) of Coupon
        // . If still has room, add count.
        prev[k] = addLoanToSummary(loan, prev[k] ?? newSummary(investor, investorProductId, investorProductName));
        return prev;        
    }, {})
    return Object.values(obj);
    // return Object.entries(obj).map(arr => arr[1]);
}

// const getCountsOld = (loans:HitLoan[], checkedByLoanId:BoolDict):SummaryGroup[] => {
//     // export const getCounts = (hitInfo:ConfirmHitInfo):CountByProductCodeByRate => {
//     // There are two groups. 15yr loans, and everything else

//     const counts:CountByProductCodeByRate = {};
//     const classA:SummaryGroup = {
//         label: 'Class A',
//         minCoupon: 0,
//         maxCoupon: 0,
//         sum: 0,
//         loanCount: 0
//     }
//     const classB:SummaryGroup = {
//         label: 'Class B',
//         minCoupon: 0,
//         maxCoupon: 0,
//         sum: 0,
//         loanCount: 0
//     }

//     return loans.reduce((prev:SummaryGroup[], loan:HitLoan) => {
//         if (!checkedByLoanId[loan.id]) return prev;
//         const [ classA, classB ] = prev;
//         if (loan.productCode.indexOf('15') > -1) {
//             return [classA, addLoanToSummary(loan, classB)];
//         } else {
//             return [addLoanToSummary(loan, classA), classB];
//         }
//     }, [classA, classB])
// }


export const toHitInfo = (proposalDetails:ProposalDetails):HitInfo => {

    console.log('WE GOT THIS proposalDetails', proposalDetails);

    // searched is list of loan numbers
    const { loans, sheetSummary, hitLoanIds } = proposalDetails;
    // We had performance issues cuz of nested arrays doing "find" and "includes", etc (even just 2 deep!)
    // dict utils to the rescue.
    const hitLoans = loans.filter(x => hitLoanIds.includes(x.id));
    const hitByLoanId = util.toBoolDict(hitLoans, x => x.id, x => true);
    // this may look strange, but, by default, those which are "checked" are those which are "hit"
    const checkedByLoanId = JSON.parse(JSON.stringify(hitByLoanId));
    // Extract what we need from priceTag (and remove it...it's huge!)
    const nextLoans = loans.map((loan):HitLoan => {
        const { priceTag, ...withoutPriceTag } = loan;
        return {
            ...withoutPriceTag,
            cashWindow: {
                price: priceTag ? priceTag.tags[0].price : 0
            }
            // cashWindowPrice: priceTag ? priceTag.tags[0].price : 0
        }
    })
    return {
        loans: nextLoans,
        hitByLoanId,
        totalCount: nextLoans.length,
        hitCount: hitLoans.length,
        checkedByLoanId,
        counts: getCounts(nextLoans, checkedByLoanId),
        sheetSummary: sheetSummary!,
        proposalDetails: proposalDetails
    }
}
