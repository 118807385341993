import { mutate, stripInternals, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const UPDATE = gql`
    mutation updateModel($set: model_set_input, $where: model_bool_exp!) {
        update_model(set: $set, where: $where) {
            errorMessage
            affectedRow
            returning {
                ${ RETURNING }
            }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Model>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_model: {
        readonly returning:[GQL.Model];
    }
}

export const updateModel = async (model:Partial<GQL.Model>):Promise<GQL.Model|undefined> => {
    if (!model.id) throw new Error('Expected id');    
	const result =  await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(model),
        where: {
            id: {
                _eq: model.id
            }
        }
    })
    return result?.data?.update_model.returning[0];
}
