import { BatchItemStatus, BatchItem } from './types';

const orderDict:{[x in BatchItemStatus]:number} = {

    AWAITING_PARSING: 0,

    PARSING_TAPE: 10,
    // Question: Why are all these 20? shouldn't "needs password/tabs specify" come before "success/failure" ?
    PARSE_FAILURE: 20,
    PARSE_SUCCESS: 20,
    PARSE_NEEDS_PASSWORD: 20,
    PARSE_NEEDS_TABS_HEADERS_ROWS_SPECIFIED: 20,


    INSERTING_TAPE: 30,
    INSERT_TAPE_FAILURE: 40,
    INSERT_TAPE_SUCCESS: 40,

    SEARCHING_TEMPLATES: 50,
    TEMPLATES_FOUND: 60,
    NO_TEMPLATES_FOUND: 60,
    NO_VALID_TEMPLATES_FOUND: 60,
    TEMPLATE_ASSIGNED_AND_CONFIRMED: 80,

    // New step: update/insert template if needed
    UPSERTING_TEMPLATE_AS_NEEDED: 90,
    UPSERT_TEMPLATE_FAILURE: 95,
    UPSERT_TEMPLATE_SUCCESS: 95,
    UPSERT_TEMPLATE_NOT_NEEDED: 95,
    
    SANITIZING_AND_UPDATING_TAPE: 100,
    SANITIZED_AND_UPDATED: 110,

    UNKNOWN_SELLER_FOUND: 200,              // At least ONE seller name was populated that did NOT find a known seller
    MULTIPLE_SELLERS_IN_TAPE_ERROR: 210,    // these two are errors!

    INSERTING_SHEET: 300,
    INSERT_SHEET_FAILURE: 310,
    INSERT_SHEET_SUCCESS: 310,

    INSERTING_LOAN_SOURCES: 400,
    INSERT_LOAN_SOURCES_FAILURE: 410,
    INSERT_LOAN_SOURCES_SUCCESS: 410,


    FINALIZING_LOANS: 500,
    FINALIZE_LOANS_FAILURE: 510,
    // FINALIZE_LOANS_SUCCESS: 510,
    SUCCESS: 9999
}

export const isBeforeStatus = (item:BatchItem, status:BatchItemStatus) => {
    const a = orderDict[item.status];
    const b = orderDict[status];
    return a < b;
}

export const isBeforeOrAtStatus = (item:BatchItem, status:BatchItemStatus) => {
    const a = orderDict[item.status];
    const b = orderDict[status];
    return a <= b;
}

export const isAfterStatus = (item:BatchItem, status:BatchItemStatus) => {
    const a = orderDict[item.status];
    const b = orderDict[status];
    return a > b;
}

export const isAfterOrAtStatus = (item:BatchItem, status:BatchItemStatus) => {
    const a = orderDict[item.status];
    const b = orderDict[status];
    return a >= b;
}