import React from 'react';
import { FrmDate, FrmDateProps } from './frm-date';
import { useProxyData, BindTo } from '../../use-proxy-data';

interface BoundFrmDateProps<T extends {}> extends FrmDateProps {
    readonly bindTo:BindTo<T>;
}
export const BoundFrmDate = <T extends {}>({ bindTo, ...rest }:BoundFrmDateProps<T>) => {
    const proxy = useProxyData(bindTo);
    return <FrmDate value={proxy.value} setValue={proxy.setVal} {...rest} />
}
