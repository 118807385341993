export const CONSTRUCT_RETURNING = `
    id
    name
    tags
    classifierType
    constructs {
        threshold
        assertions
        removeOnly
    }
`;
