import React from 'react';
import { Flows } from './flows';
import { Routes, Route, Navigate } from "react-router-dom";

export const Alpha = () => {
    return (
        <Routes>
            <Route path="flow/*" element={<Flows />} />
            <Route path="*" element={<Navigate to="flow" />} />
        </Routes>
   )
}