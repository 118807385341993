import React, { useState } from 'react';
import { Box, Flex, Tabs } from 'mui';
import { useZStateList, useStickyStateWithDefault } from 'hooks';
import { rule } from 'services';
import { UploadModalTabs } from './upload-modal-tabs';
import { UploadModalValidate } from './upload-modal-validate';

export const UploadModal = () => {

    const [ json, setJson ] = useState<string>('');
    const [ submitting, setSubmitting ] = useState<boolean>(false);
    const [ tab, setTab ] = useStickyStateWithDefault("upload-checklist-modal-tab", 'file');

    const setJsonAndSubmit = (x:string) => {
        setJson(x);
        setSubmitting(true);
    }

    const { data:unfilteredChecklists } = useZStateList(rule.zChecklists);
    if (!unfilteredChecklists) return <></>; // must exist.

    return (
        <Box mt="1">
            { submitting && (
                <UploadModalValidate
                    back={() => setSubmitting(false)}
                    backToEdit={() => {
                        setTab('json');
                        setSubmitting(false);
                    }}
                    json={json}
                    existingChecklists={unfilteredChecklists}
                />
            ) }
            { !submitting && (
                <UploadModalTabs
                    tab={tab}
                    setTab={setTab}
                    setJson={setJson}
                    setJsonAndSubmit={setJsonAndSubmit}
                    json={json}
                />
            ) }
        </Box>
    )
}