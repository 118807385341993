import { mutate, gql, GQL } from "../../client"
import { RETURNING } from './returning';
import { RawProposal, ProposalDetails, toProposalDetails } from 'services/proposal';

// This is a function because we are importing from the file which is exporting us...
const getQuery = () => gql`
    mutation addHitOffersSync($loanNumbers: [String], $daysBack:Int, $remainOpen: Boolean) {
        addHitOffers_sync(loanNumbers:$loanNumbers, daysBack:$daysBack, remainOpen:$remainOpen) {
            ${ RETURNING }
        }
    }
`;

interface MutateInput {
    readonly daysBack:number;
    readonly remainOpen:boolean;
    readonly loanNumbers:string[];
}
interface MutateOutput {
    readonly addHitOffers_sync:RawProposal|null;
}

export const addHitOffers = async (loanNumbers:string[], daysBack:number, remainOpen:boolean):Promise<ProposalDetails|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(getQuery(), {
        loanNumbers,
        daysBack,
        remainOpen
    })
    if (!result?.data?.addHitOffers_sync) return;
    console.log(result.data.addHitOffers_sync);
    return toProposalDetails(result.data.addHitOffers_sync);

}
