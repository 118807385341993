import { mutate, gql } from "../../client"
import { SellerBulkImportItem } from './index';

const BULK_INSERT = gql`
    mutation runBcpSellerSync($sellers: [BcpSellerInput]) {
        bcp_seller_sync(sellers:$sellers)
    }
`;

interface MutateInput {
    readonly sellers:SellerBulkImportItem[];
}
interface MutateOutput {
    readonly bcp_seller_sync: SellerBulkImportItem[];
}

export const bulkSellerImport = async (sellers:SellerBulkImportItem[]):Promise<SellerBulkImportItem[]|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(BULK_INSERT, { sellers });
	return result?.data?.bcp_seller_sync;
}
