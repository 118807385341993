import React, { useState, useEffect } from 'react';
import { Box, Link, modal, IntoPortal, Button } from 'mui';
import { ImportModel } from 'market-dto';
import { tapeImporter, actMon, alpha } from 'services';
import { TapeImporter } from 'components';


interface Props {
    readonly model:ImportModel;
    readonly batch?:tapeImporter.Batch;
    readonly isNewFlow:boolean;
    readonly onTapeCreated:(tapeId:string) => void;
}
export const FlowLandingTapeImport = ({ model, isNewFlow, onTapeCreated, batch }:Props) => {

    const onTapeImport = (b:tapeImporter.Batch|undefined) => {
        if (!b || b.items.length !== 1 || !b.items[0].tapeId) {
            onTapeCreated(''); // empty string means error.
            return;
        }
        onTapeCreated(b.items[0].tapeId);
        tapeImporter.zBatch.clear();
    }

    return (
        <Box mt="2">

            <TapeImporter
                model={model}
                onTapeImportComplete={onTapeImport}
                singleTapesOnly
                askAboutHeaders
            />
        </Box>
    )
}