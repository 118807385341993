import * as api from 'api';
import { mergeBatchItem } from '../merge-batch-item';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { getBatchItem } from './get-batch-item';
import { updateStatus } from './update-status';
import { logActivity } from './log-activity';

export const apiInsertSheet = () => (index$:Observable<number>):Observable<number> => {
    return index$.pipe(
        getBatchItem(),
        logActivity("Inserting sheet"),
        updateStatus("INSERTING_SHEET"),
        switchMap(item => {
            const { tapeId, assigned, defaultMapResult, model, rowsAndHeaders } = item;
            if (!tapeId) throw new Error('Expected: tapeId');
            if (!assigned || !assigned.sellerOrg || !assigned.generated) throw new Error('Expected: assigned template with sellerOrg and generated');
            if (!defaultMapResult || !rowsAndHeaders) throw new Error('Expected: defaultMapResult and rowsAndHeaders');
            const { hashes } = defaultMapResult;
            const { h0:hash, h1:sequenceHash, h2:orderHash, h3:mappedHash } = hashes;
        
            return from(api.insertSheet({
                // We should rename them them to h0,h1,h2,h3
                hash, sequenceHash, orderHash, mappedHash,
                fileName: item.file.name,
                headers: rowsAndHeaders.headers,
                tapeId: tapeId,
                modelType: model.modelType,
                templateId: assigned.template.id,
                aggregatorName: assigned.aggregatorName,
                sellerOrgId: assigned.sellerOrg.id
            })).pipe(
                tap(newSheet => {
                    mergeBatchItem(item, { status: newSheet ? "INSERT_SHEET_SUCCESS" : "INSERT_SHEET_FAILURE" });
                }),
                map(x => item.index)
            )
        })
    )
}
