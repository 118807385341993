import React from 'react';
import { tapeImporter } from 'services';
import { Box, FlexVCenter, HoverIconBox } from 'mui';

interface Props {
    readonly matchResult:tapeImporter.UnassignedValidMatchResult;
    readonly item:tapeImporter.BatchItem;
}
export const SingleMatchNonAgg = ({ matchResult, item }:Props) => {
    const { matchState, sellerOrg } = matchResult;
    if (!sellerOrg) throw new Error('Expected sellerOrg');
    if (matchState === "MATCHED_NON_AGG_TEMPLATE") {
        return (
            <HoverIconBox fg="default" icon="edit" iconFg="faded" iconOnClick={() => tapeImporter.toggleExpanded(item)}>
                <FlexVCenter>
                    <Box fg="loud">{ sellerOrg.name }</Box>
                </FlexVCenter>
            </HoverIconBox>
        )
    }
    throw new Error('Unhandled matchState on a single match non-agg: ' + matchState);
}