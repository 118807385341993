import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { downloader, alpha } from 'services';
import { useZState } from 'hooks';
import * as api from 'api';
import { Icon, toastError, FlexVCenter, IconButton, SortableTableHeader, usePageSortTable, TablePageButtons } from 'mui';
import { RunItem } from './types';


interface Props {
    readonly filtered:RunItem[];
}
export const RunsTable = ({ filtered }:Props) => {

    const { data:selectedRun } = useZState(alpha.zCurrentRun);
    const loadRun = (id:string) => alpha.zCurrentRun.fetch(id);
    
    const downloadTape = async (e:React.MouseEvent<Element, MouseEvent>, sheetId:string) => {
        e.stopPropagation();
        const bidTapeSheet = await api.fetchBidTapeModelSheet(sheetId);
        if (bidTapeSheet) {
            const { headers, loanSources, fileName } = bidTapeSheet;
            const rows = loanSources.map(x => x.row);
            downloader.downloadSimpleXLSX(headers, rows, "Loans", "recreated-" + fileName)
        } else {
            toastError('Unable to recreate original tape!');
        }
    }

    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<RunItem, keyof RunItem>({
        rows: filtered,
        keyFn: row => row.id, // nothing changes in completed runs.
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    return (
        <>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render="Seller" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="status" render="Status" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="sheetId" render="Sheet ID" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="startedAt" render="Start" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="endedAt" render="Finish" />
                        <th>Selected</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((item, n) => {
                        const selected = selectedRun && selectedRun.id === item.id;
                        return (
                            <tr
                                key={item.id}
                                style={{ cursor: selected ? 'default' : 'pointer' }}
                                onClick={() => loadRun(item.id)}
                            >

                                <td>{ item.name }</td>
                                <td>{ item.status }</td>
                                <td>
                                    <FlexVCenter>
                                        { item.sheetId }
                                        <IconButton name="download" onClick={(e) => downloadTape(e, item.sheetId)}/>
                                    </FlexVCenter>
                                </td>
                                <td>{ item.startedAt }</td>
                                <td>{ item.endedAt }</td>
                                <td className="center">
                                    { selected && <Icon inline name="check" fg="loud" /> }
                                </td>
                            </tr>
                        )
                    }) }                                
                </tbody>
            </table>
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}
