import { GQL } from 'market-dto';
import { PricingInfo } from './types';
import { FREDDIE_ID, AGENCY_ID, FN_NAME, WF_ID } from './org-ids';
import { getMarketSettings } from '../market-settings';

export const toPricingInfo = (sheet:GQL.LoanModelSheet):PricingInfo => {

    const settings = getMarketSettings();
    const duWaiver:boolean = settings && settings['freddie.duWaiver'] ? true : false;
    const { loans, sheetSummary:{ sheetStatus }} = sheet;
    const hasRunFreddiePricing = loans.some(x => x.offers?.some(off => off.investorId === FREDDIE_ID));
    const hasRunFanniePricing = loans.some(x => x.offers?.some(off => off.investorId === AGENCY_ID && off.investor !== FN_NAME));
    const hasRunSpreadsheetPricing = loans.some(x => x.offers?.some(off => off.investorId === WF_ID));
    // Changed below - we allow finalizing if we have run fannie OR freddie
    const canFinalizeProposal = (hasRunFreddiePricing || hasRunFanniePricing) && sheetStatus === 'PRICING';
    const canRunSpreadsheetPricing = !hasRunSpreadsheetPricing && hasRunFanniePricing && sheetStatus === 'PRICING';
    const canRunFanniePricing = sheetStatus === 'PRICING' && loans.some(x => x.aus === 'DU');
    const canRunFreddiePricing = sheetStatus === 'PRICING' && loans.some(x => x.aus === 'LP' || (x.aus === 'DU' && duWaiver));
    const canComparePricing = hasRunFreddiePricing || hasRunFanniePricing;
    
    return {
        hasRunFanniePricing,
        canFinalizeProposal,
        hasRunSpreadsheetPricing,
        canRunFanniePricing,
        canRunSpreadsheetPricing,
        canRunFreddiePricing,
        hasRunFreddiePricing,
        canComparePricing
    }
}
