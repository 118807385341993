import { mutate } from "../../client";
import { gql } from "apollo-boost";
import { GQL } from 'market-dto';
import { RETURNING } from './returning';

// Just update a loan with a partial. that's it. nothing else.

const UPDATE = gql`
    mutation updateLoan($set: loan_set_input, $where: loan_bool_exp!) {
        update_loan(set: $set, where: $where) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Loan>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_loan: {
        readonly returning:[GQL.Loan];
    }
}

export const updateLoan = async (loanId:string, data:Partial<GQL.Loan>):Promise<GQL.Loan | undefined> => {
    const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: data,
        where: {
            id: {
                _eq: loanId
            }
        }
    })
    // const client = getApolloClient(false);
	// const result = await client.mutate<MutateOutput, MutateInput>({
	// 	mutation: UPDATE,
	// 	variables: {
	// 		set: data,
	// 		where: {
	// 			id: {
	// 				_eq: loanId
	// 			}
	// 		}
	// 	}
    // })
    return result?.data?.update_loan.returning[0];    
}
