export const notUsedEither = true;
// import { RowsAndHeaders } from 'market-dto';
// import { zBatch } from './z-batch';

// export const getRowsAndHeaders = (tapeId:string):RowsAndHeaders|undefined => {
//     const b = zBatch.get();
//     if (!b) throw new Error('Expected batch to exist');
//     const item = b.items.find(x => x.tapeId === tapeId);
//     return item?.tapeInfo?.rowsAndHeaders;
// }
