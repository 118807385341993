import { mutate, gql } from '../../client';

const QUEUE_PRICING = gql`
    mutation queueFreddiePricing($sheetId: String, $costOfFunds: Float, $sellerMargin: Float, $simulateTimedOut: Float, $simulateError: String) {
        queue_freddie_pricing(sheetId:$sheetId, costOfFunds: $costOfFunds, sellerMargin: $sellerMargin, simulateTimedOut: $simulateTimedOut, simulateError: $simulateError)
    }
`;


interface MutateInput {
	readonly sheetId:string;
	readonly costOfFunds:number;
	readonly sellerMargin:number;
	readonly simulateTimedOut?:number;
	readonly simulateError?:string;
}

interface MutateOutput {
    readonly queue_freddie_pricing: {
		readonly batchId:string;
    }
}

export const queueFreddiePricing = async (
	sheetId:string,
	costOfFunds:number,
	sellerMargin:number,
	simulateTimedOut?:number,
	simulateError?:string
) => {
	const result = await mutate<MutateInput, MutateOutput>(QUEUE_PRICING, {
		sheetId,
		costOfFunds,
		sellerMargin,
		simulateError,
		simulateTimedOut
	})
	return result?.data?.queue_freddie_pricing.batchId;
}