import { GQL, OverrideItem, Overrides, TransCfg } from 'market-dto';

export const setMappingOverrideTranslate = (
    overrideDict:Overrides,
    fieldId:string,
    transCfg:TransCfg,
):Overrides => {
    // if they send something with undefined as override, that means, remove it!
    // that can happen. (unlike with column mapping)
    const curr = overrideDict[fieldId];    
    const ride:OverrideItem = {
        ...(curr?.fromColumn ? { fromColumn: curr.fromColumn } : {}),
        ...(transCfg.override ? { translate: transCfg.override } : {})
    }
    return {
        ...overrideDict,
        [fieldId]: ride
    }
}