import { zUser } from './z-user';
import * as storage from '../storage';
import { resetZStates } from '../z-state';

export const logout = () => {
    storage.clear('user');
    storage.clear('jwt');
    zUser.set(null);
    setTimeout(() => {
        // This is a lame hack.
        // Perhaps only needed in dev-mode, where react runs the render twice...though I haven't tested that theory.
        // Basically, don't want any zstate hooks trying to re-fetch cuz last args are suddenly undefined, so,
        // it thinks "gotta load again! args changed!"
        // set timeout of just 0 so it happens after current render.
        resetZStates();
    }, 0)
    
}