import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { Box, Button, FlexVCenter, Alert, Text, Icon, Link, Dropdown, Divider, LabelValueGrid, Flex } from 'mui';
import { AggIdentifySellerGivenAlias } from './agg-identify-seller-given-alias';
import { AggIdentifyAgg } from './agg-identify-agg';
import { getMappingNeeds } from './get-mapping-needs';
import { ExpandedState } from './types';

interface Props {
    readonly exState:ExpandedState;
    readonly aggName:string;
    readonly isNew:boolean;

    // Do we need both?
    readonly mappedForSellerOrg?:GQL.Org;
    // readonly sellerAliasForAgg?:string;
    readonly sellerForAgg?:GQL.Org;

    readonly distinctSellerNames:string[];
    // readonly distinctSellerOrgIds:string[];
    readonly sellers:GQL.Org[];
    readonly assignAlias:(x?:GQL.Org) => void;
    readonly assignAggName:(x:string) => void;
}
export const MappingHeaderAgg = ({
    distinctSellerNames,
    isNew,
    // distinctSellerOrgIds,
    aggName,
    sellers,
    assignAlias,
    sellerForAgg,
    // sellerAliasForAgg,
    mappedForSellerOrg,
    assignAggName,
    exState
}:Props) => {

    const { highlightSellerName, invalidSellerMapped, isUnknownSeller, needsSellerMapped } = getMappingNeeds(true, exState);
    const [ notRightMappedSeller, setNotRightMappedSeller ] = useState(false);
    const [ notRightAgg, setNotRightAgg ] = useState(false);
    const showAssignAliasToSeller = (isUnknownSeller && !sellerForAgg) || notRightMappedSeller;

    const sellerNotRight = () => {
        if (mappedForSellerOrg) {
            // we found the seller through mapping
            setNotRightMappedSeller(true);
        } else if (isUnknownSeller) {
            // we found the seller name--but it didn't match an actual seller
            assignAlias(); // this clears it.
        }
    }

    const onAggNameChange = (x:string) => {
        if (!x.trim()) return;
        setNotRightAgg(false);
        assignAggName(x);
    }

    const onAssignAlias = (x?:GQL.Org) => {
        assignAlias(x);
        setNotRightMappedSeller(false);
    }

    const seller = sellerForAgg || mappedForSellerOrg;

    const showAlert = notRightAgg || highlightSellerName || showAssignAliasToSeller;

    return (
        <Box mx="1" mb="3" width="card-lg">
            <Flex flexDirection="column" alignItems="center">
                <Box fg="faded" fontSize="sm" mb="1">
                    This tape originated from an aggregator.
                </Box>
                {/* <Box fg="default" mb="2">This tape originated from an aggregator.</Box> */}
                {/* { fromMatch && (
                    <Box my="half" fg="default" fontSize="sm">The following is an existing mapping.</Box>
                ) } */}
                <LabelValueGrid>
                    <Box textAlign="right" fg="faded">Aggregator:</Box>
                    <FlexVCenter>
                        <Box fg="loud">{ aggName }</Box>
                        { isNew && (
                            <Box fontSize="sm" ml="1">
                                <Icon name="edit" onClick={() => setNotRightAgg(true)} fgHover="link" fg="default" cursor="pointer" />
                            </Box>
                        ) }
                    </FlexVCenter>
                    { seller && (
                        <>
                            <Box textAlign="right" fg="faded">Seller:</Box>
                            <FlexVCenter>
                                <Box fg="loud">{ seller.name }</Box>
                                <Box fontSize="sm" ml="1">
                                    <Icon name="edit" onClick={sellerNotRight} fgHover="link" fg="default" cursor="pointer" />
                                </Box>
                            </FlexVCenter>
                        </>
                    ) }
                </LabelValueGrid>
            </Flex>

            { showAlert && (
                <Alert py="0" mt="2">
                    { aggName && (
                        <Box py="1">
                            { needsSellerMapped && (
                                <FlexVCenter fg="default">
                                    <Icon name="alert-triangle" mr="1" fg="accent" />
                                    You
                                    &nbsp;
                                    <Text textStyle="underline" fg="loud" fontWeight="bold">must</Text>
                                    &nbsp;
                                    provide mapping for
                                    &nbsp;
                                    <Text fg="accent">Seller Name</Text>
                                </FlexVCenter>
                            ) }
                            { invalidSellerMapped && (
                                <>
                                    <FlexVCenter fg="default">
                                        <Icon name="alert-triangle" mr="1" fg="accent" />
                                        The mapping for
                                        &nbsp;
                                        <Text fg="accent">Seller Name</Text>
                                        &nbsp;
                                        would result in
                                        &nbsp;
                                        <Text fg="loud" fontWeight="bold">{ distinctSellerNames.length }</Text>
                                        &nbsp;
                                        distinct seller names!
                                    </FlexVCenter>
                                    <Box mt="half" textStyle="italic" textAlign="left" fontSize="md" fg="faded">
                                        There must  be one-and-only-one Seller Name in the tape!
                                    </Box>
                                </>
                            ) }
                            { showAssignAliasToSeller && (
                                <AggIdentifySellerGivenAlias
                                    currSeller={seller}
                                    weThinkItIs={mappedForSellerOrg}
                                    alias={distinctSellerNames[0]}
                                    sellers={sellers}
                                    assignAlias={onAssignAlias}
                                />
                            ) }
                            { notRightAgg && showAssignAliasToSeller && (
                                <Divider mt="2" />
                            ) }
                            { notRightAgg && (
                                <AggIdentifyAgg
                                    cancel={() => setNotRightAgg(false)}
                                    setAggName={onAggNameChange}
                                    aggName={aggName}
                                />
                            ) }
                        </Box>
                    ) }
                    { seller && (
                        <Box my="1">
                            Please ensure the following mappings are correct for:&nbsp;
                            <Text fg="loud">{ seller.name }</Text>
                        </Box>
                    ) }
                </Alert>
            ) }
        </Box>
    )
}