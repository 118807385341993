import React, { useState, useEffect } from 'react';
import { Box } from 'mui';
import { GQL } from 'market-dto';
import { addDays } from 'date-fns';
import { ParamsForm } from './params-form';
import { ConfirmSummary } from './confirm-summary';
import { proposal, sheets } from 'services';
import * as api from 'api';
import { useApiUpdate } from 'hooks';
import { CreateTradeError } from './create-trade-error';

const createDefaultInfo = (hitInfo:proposal.HitInfo):GQL.CreateTradeParams => {
    const tradeDeliveryDate = addDays(new Date(), 7).toISOString();
    const settlementDate = addDays(new Date(), 20).toISOString();
    const servicer = hitInfo.loans.length > 0 ? (hitInfo.loans[0].servicerSymbol ??  '') : '';
    return {
        sheetId: hitInfo.proposalDetails.sheetId,
        name: hitInfo.proposalDetails.sellerName ?? '',
        tradeDeliveryDate,
        commitmentType: 'BULK',
        dayCountConvention: GQL.DayCountConvention.Actual360,
        servicer,
        servicing: 'RELEASED',
        settlementDate
    }
}

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly crEnv:string;
    // readonly proposalDetails:ProposalDetails;
    readonly counts:proposal.SummaryGroup[];
    readonly hitLoanCount:number;
    readonly onTradeCreated:() => void;
}

const apiCreateTrade = api.toastOnErr(api.createTrade, 'Could not create trade!');

export const CreateTradeModal = ({ hitInfo, counts, hitLoanCount, crEnv, onTradeCreated }:Props) => {

    const [ createState, setCreateState ] = useState<"INIT"|"CONFIRM"|"ERROR">('INIT');
    const [ params, setParams ] = useState<GQL.CreateTradeParams>(() => createDefaultInfo(hitInfo))
    const {
        busy:creatingTrade,
        result:tradeResults,
        update:createTrade,
    } = useApiUpdate(async () => {
        const results = await apiCreateTrade(params);
        if (results?.created) {
            await sheets.zCurrent.refresh();
            onTradeCreated();
        } else {
            setCreateState('ERROR');
        }
        return results;
    })
    

    return (
        <Box mt="2">
            { createState === 'INIT' && (
                <ParamsForm
                    crEnv={crEnv}
                    params={params}
                    setParams={setParams}
                    onNext={() => setCreateState('CONFIRM')}
                />
            ) }
            { createState === 'CONFIRM' && (
                <ConfirmSummary
                    crEnv={crEnv}
                    params={params}
                    back={() => setCreateState('INIT')}
                    counts={counts}
                    hitLoanCount={hitLoanCount}
                    creatingTrade={creatingTrade}
                    createTrade={createTrade}
                />
            ) }
            { createState === 'ERROR' && (
                <CreateTradeError tradeResults={tradeResults} goBack={() => setCreateState('INIT')} />
            ) }
        </Box>
    )
}