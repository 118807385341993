import { mutate, gql, GQL } from "../../client";

const MUTATE = gql`
    mutation delete($where: where_settlementCalendar_exp) {
        delete_settlementCalendar(where: $where) {
            errorMessage
            affectedRow
        }
    }
`;

interface MutateInput {
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly delete_settlementCalendar: {
        readonly errorMessage?:string;
        readonly affectedRow?:number;
    }
}

export const deleteSettlementCalendars = async (ids:string[]):Promise<boolean> => {
    const result = await mutate<MutateInput, MutateOutput>(MUTATE, {
        where: {
            id: { _in: ids }
        }
    })
    const rows = result?.data?.delete_settlementCalendar.affectedRow ?? 0;
    if (rows === 0) return false;
    return true;
}


