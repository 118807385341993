import React from 'react';
import { Box } from 'mui';
import './m-studio-logo.css';

export const MStudioLogo = () => {
    return (
        <Box mr="2" onClick={() => alert(process.env.REACT_APP_VERSION)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" className="mstudio-logo">

                <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="1865" y1="920" x2="1929" y2="1016">
                    <stop offset="0" style={{ stopOpacity: 1, stopColor: 'var(--logo-grad-1)' }}/>
                    <stop offset="0.478431" style={{ stopOpacity:1, stopColor:'var(--logo-grad-2)' }}/>
                    <stop offset="1" style={{ stopOpacity:1, stopColor: 'var(--logo-grad-3)'}} />
                </linearGradient>

                <path fill="var(--logo-m)" d="M132 1173l0 -150c0,-56 90,-62 90,-3l0 153 61 0 0 -152c0,-53 92,-58 92,-1l0 153 61 0 0 -155c2,-109 -119,-127 -168,-64 -34,-39 -92,-47 -136,-18l0 -10 -61 0 0 247 61 0z"/>
                <path fill="var(--logo-studio)" d="M672 931c38,-23 57,-34 57,-34 -28,-58 -90,-91 -154,-74 -49,12 -76,41 -82,84 -5,38 5,66 31,86 20,14 39,23 58,28 39,9 65,19 78,31 25,24 7,49 -20,60 -54,7 -89,-10 -104,-51 -39,22 -58,33 -58,33 16,50 54,78 114,85 55,6 115,-9 138,-60 24,-53 2,-108 -51,-130 -25,-10 -48,-18 -71,-24 -33,-10 -49,-25 -49,-45 0,-53 101,-41 113,11z"/>
                <path fill="var(--logo-studio)" d="M858 858l0 69 56 0 0 58 -57 1c0,0 0,36 0,107 0,15 12,23 26,23 5,0 15,0 31,0 0,0 0,19 0,57 0,0 -17,0 -49,0 -45,0 -69,-32 -68,-73l0 -115 -45 0 0 -24 106 -103z"/>
                <path fill="var(--logo-studio)" d="M1023 1080l0 -153 -63 0 0 152c0,95 97,127 166,83l0 11 64 0 0 -246 -64 0 0 134c0,84 -103,72 -103,19z"/>
                <path fill="var(--logo-studio)" d="M1438 940c-20,-13 -44,-21 -70,-21 -72,0 -131,59 -131,131 0,72 59,131 131,131 26,0 50,-8 70,-21l0 13 64 0 0 -331 -64 0 0 98zm-137 110c0,-37 30,-68 67,-68 37,0 67,31 67,68 0,37 -30,67 -67,67 -37,0 -67,-30 -67,-67z"/>
                <rect fill="var(--logo-studio)" x="1560" y="928" width="63.3926" height="245.437"/>
                <rect fill="var(--logo-studio)" x="1560" y="842" width="63.3926" height="55.471"/>
                <polygon fill="url(#grad)" points="1929,1016 1929,920 1834,920 1834,984 1865,984 1865,1016 "/>
                <path fill="var(--logo-studio)" d="M1788 985l0 -64c-66,6 -117,62 -117,129 0,71 57,130 129,130 67,0 123,-53 128,-119l-64 0c-5,31 -32,55 -64,55 -37,0 -66,-30 -66,-66 0,-32 23,-60 54,-65z"/>
            </svg>
        </Box>
    )
}