import * as api from 'api';
import { addJob, actLog, genJobId } from '../../activity-monitor';
import { getFlowRunJobName} from './get-flow-run-job-name';
import { GQL } from 'market-dto';
import { checkFlowRunJobDone } from './check-flow-run-job-done2';

const POLLING_TIMEOUT_SECONDS = 60 * 10;

// const apiRunFlow = api.toastOnErr(api.execFlowWithSheetId, "Unable to run Flow at this time");
const apiRunFlow2 = api.toastOnErr(api.execFlowWithSheetId, "Unable to run Flow at this time");

export const addFlowRunJob = async (flow:GQL.Flow, sheetId:string, onComplete?:() => void) => {

    const jobName = getFlowRunJobName(flow, sheetId);
    const jobId = genJobId("flow-" + flow.id + "." + sheetId);
    // const flowRun = await apiRunFlow(flow.id, sheetId);
    const batchId = await apiRunFlow2(flow.id, sheetId);
    // const runId = flowRun?.id ?? '';

    // BATCH ID IS EQUAL TO RUN ID!
    if (!batchId) {
        throw new Error('Failed to create batchID -- todo, change this to a toast.');
    }

    actLog("FLOW_RUN", "ACTIVITY_STARTED", jobName, sheetId);
    addJob({
        label: sheetId,
        // serverBatchId: runId,
        serverBatchId: batchId,
        jobId,
        jobName,
        checkStatus: checkFlowRunJobDone(batchId, jobId, jobName, sheetId),
        timeoutSeconds: POLLING_TIMEOUT_SECONDS,
        onComplete: async () => {
            // await zCurrentRun.fetch(batchId);
            if (onComplete) onComplete();
        }
    })
    return { batchId, jobName };
}
