import { useRef, useEffect, useState } from 'react';

export const useApiUpdate = <T extends any[], K = any>(apiCall:(...args:T) => Promise<K>) => {
    
    // TODO: Why pass output?? we can get it from return of function call!

	// T is required (input parameters for the api call)
	// K is optional (return type)

    const hasRun = useRef(false);
    const isCancelled = useRef(false);
	const [ result, setResult ] = useState<K>();
    const [ busy, setBusy ] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            isCancelled.current = true;
        }
    }, [])

	return {
        hasRun: hasRun.current,
		busy,
        result,
		update: async (...args:T) => {
            if (isCancelled.current) return;
            setBusy(true);
            const result:K = await apiCall(...args);
            if (isCancelled.current) return;
            hasRun.current = true;
			setResult(result);
            setBusy(false);
            return result;
        }
	}
}