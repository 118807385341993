import { GQL } from 'market-dto';
import { BatchItem, UserInputState, UserDecision2 } from '../types';
import { updateTemplate, insertTemplate } from 'api';

export const ensureTemplate = async (
    item:BatchItem,
    userInput:UserInputState,
    decision:UserDecision2
):Promise<GQL.Template|undefined> => {

    const { tapeId, defaultMapResult, model } = item;
    if (!tapeId || !defaultMapResult) throw new Error('Expected tapeId and defaultMapResult');
    const { hashes } = defaultMapResult;

    const { editMapping, selectedMatch, confirmedAggregatorName, confirmedSeller } = userInput;
    if (!confirmedSeller) throw new Error('Cannot have new template without a seller');
    if (!editMapping && !selectedMatch) throw new Error('Must have either new mapping or selected match');

    if (decision === "INVALID_CFG") throw new Error("You were not ready to submit.");

    if (decision === "NEW_AGG" || decision === "NEW_NON_AGG") {
        // easiest case for determining which orgs to add.
        const newTemplate:GQL.Template = {
            id: "new", // this gets removed/overwritten
            modelType: model.modelType,
            mapping: editMapping,
            orgIds: [confirmedSeller.id],
            aggregatorName: confirmedAggregatorName, // will be undefined for non-agg
            hashes: [hashes.h0],
            sequenceHashes: [hashes.h1],
            orderHashes: [hashes.h2],
            mappedHashes: [hashes.h3]
        }
        return await insertTemplate(newTemplate);
    }

    if (!selectedMatch) throw new Error('Expected selectedMatch');


    if (decision === "NON_AGG") {
        // Leave the orgs alone (agg/orgIds) but update mapping if they modified it.
        if (!editMapping) return selectedMatch.template;
        return await updateTemplate(selectedMatch.template.id, { mapping: editMapping });
    }

    if (decision === "AGG") {
        // How do we know if we must add new seller?
        // if (!editMapping) return selectedMatch.template;
        if (!selectedMatch.template.orgIds.includes(confirmedSeller.id)) {
            // we must change orgIds and possibly mapping
            return await updateTemplate(selectedMatch.template.id, {
                ...editMapping ? { mapping: editMapping } : {},
                orgIds: selectedMatch.template.orgIds.concat(confirmedSeller.id)
            })
        } else {
            if (!editMapping) {
                // nothing changed
                return selectedMatch.template;
            } else {
                // only mapping changed
                return await updateTemplate(selectedMatch.template.id, { mapping: editMapping });
            }
        }        
    }

    if (decision === "CLONE_NON_AGG") {
        const newTemplate:GQL.Template = {
            id: "new", // this gets removed/overwritten
            modelType: model.modelType,
            mapping: editMapping ?? selectedMatch.template.mapping,
            orgIds: [confirmedSeller.id],
            hashes: [hashes.h0],
            sequenceHashes: [hashes.h1],
            orderHashes: [hashes.h2],
            mappedHashes: [hashes.h3]
        }
        return await insertTemplate(newTemplate);
    }

    if (decision === "CLONE_AGG") {
        const newTemplate:GQL.Template = {
            id: "new", // this gets removed/overwritten
            modelType: model.modelType,
            mapping: editMapping ?? selectedMatch.template.mapping,
            aggregatorName: confirmedAggregatorName,
            orgIds: [confirmedSeller.id],
            hashes: [hashes.h0],
            sequenceHashes: [hashes.h1],
            orderHashes: [hashes.h2],
            mappedHashes: [hashes.h3]
        }
        return await insertTemplate(newTemplate);
    }

}
