import React, { useState, useRef } from 'react';
import { FlexVCenter, Box, Icon, AnimBox, Input } from 'mui';
import { GQL } from 'market-dto';
import { ToggleSwitch } from '../components';
import { useOnClickOutside } from 'hooks';
import { EnumValLabel } from './types';

interface EditLabelAndIndex {
    readonly index:number;
    readonly label:string;

}
interface Props {
    readonly colCfg:GQL.XlsExportMappedCol;
    readonly onChange:(x:GQL.XlsExportMappedCol) => void;
    readonly enumValLabels:EnumValLabel[];
}
export const FormatEnum = ({ colCfg, onChange, enumValLabels }:Props) => {

    const enumVals = colCfg.useEnumVals ? true : false;
    const setEnumVals = (x:boolean) => {
        onChange({
            ...colCfg,
            useEnumVals: x
        })
    }

    const [ editObj, setEditObj ] = useState<EditLabelAndIndex>({ index: -1, label: '' });

    // TODO: when they click "update" btn, we ignore it when input box showing and close input.
    // ...probably wanna fix that...and apply update.
	const inputAreaRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(inputAreaRef, () => acceptCustom());

    const setCustomLabel = (item:EnumValLabel, val:string) => {
        setEditObj({
            ...editObj,
            label: val
        })
    }

    const keyDown = (e:React.KeyboardEvent) => {
        if (e.key === 'Enter') acceptCustom();
    }

    const acceptCustom = () => {
        const { index, label } = editObj;
        if (index === -1) return;
        const item = enumValLabels[index];
        const trimmed = label.trim();
        if (!trimmed || trimmed === item.label) {
            // remove it. not really an override.
            const { [item.value]:removeMe, ...remaining } = colCfg.customEnumLabels ?? {};
            onChange({
                ...colCfg,
                customEnumLabels: remaining
            })
        } else {
            // they are providing an override to the label.
            onChange({
                ...colCfg,
                customEnumLabels: {
                    ...colCfg.customEnumLabels ?? {},
                    [item.value]: trimmed
                }
            })
        }
        setEditObj({ index:-1, label: '' });
    }

    const cancelEdit = () => {
        setEditObj({ index:-1, label: '' });
    }

    const toggleEdit = (item:EnumValLabel, n:number) => {
        if (enumVals) return;
        if (editObj.index === n) {
            cancelEdit();
            return;
        } else {
            const val = colCfg.customEnumLabels?.[item.value] || item.label;
            setEditObj({ index:n, label:val });
        }
    }

    return (
        <>
            <FlexVCenter justifyContent="space-between" css="m-xls-translate-header">
                <FlexVCenter
                    flex="1"
                    justifyContent="flex-end"
                    css={enumVals ? "m-xls-txt-dark" : "m-xls-txt-light"}
                    cursor={enumVals ? "default" : "pointer"}
                    onClick={() => setEnumVals(true)}
                >
                    Underlying Value
                </FlexVCenter>
                <FlexVCenter style={{ width:'30px'}} justifyContent="center">
                    <ToggleSwitch
                        left={enumVals}
                        onToggle={setEnumVals}
                    />
                </FlexVCenter>
                <FlexVCenter
                    flex="1"
                    justifyContent="flex-start"
                    css={!enumVals ? "m-xls-txt-dark" : "m-xls-txt-light"}
                    cursor={!enumVals ? "default" : "pointer"}
                    onClick={() => setEnumVals(false)}
                >
                    Display Value
                </FlexVCenter>
            </FlexVCenter>
            { enumValLabels.map((item, n) => (
                <React.Fragment key={n}>
                    <FlexVCenter justifyContent="space-between" css="m-xls-translate-item">
                        <FlexVCenter
                            flex="1"
                            justifyContent="flex-end"
                            css={enumVals ? "m-xls-translate-left m-xls-txt-dark" : "m-xls-translate-left m-xls-txt-light"}
                        >
                            { item.value }
                        </FlexVCenter>

                        <FlexVCenter style={{ width:'30px'}} justifyContent="center">
                            <Icon name="arrow-right" />
                        </FlexVCenter>

                        <FlexVCenter
                            flex="1"
                            justifyContent="flex-start"
                            css={!enumVals ? "m-xls-translate-right m-xls-txt-dark" : "m-xls-translate-right m-xls-txt-light"}
                        >
                            <div className={editObj.index === n ? 'm-xls-translate-editing' : undefined}>
                                { colCfg.customEnumLabels?.[item.value] || item.label }
                            </div>
                            &nbsp;
                            <Icon
                                name={ editObj.index === n ? "close" : "edit" }
                                css={ editObj.index === n ? "m-xls-pop-translate-close-icon" : "m-xls-pop-translate-edit-icon" }
                                cursor="pointer"
                                onClick={() => toggleEdit(item, n)}
                            />
                        </FlexVCenter>
                    </FlexVCenter>
                    {  editObj.index === n && (                        
                        <AnimBox anim="expand-height" animDuration={80} animOnInit>
                            <Box css="m-xls-translate-custom-area" ref={inputAreaRef}>
                                <input
                                    autoFocus
                                    onKeyDown={keyDown}
                                    type="text"
                                    value={editObj.label}
                                    onChange={x => setCustomLabel(item, x.target.value)}
                                    className="m-xls-input m-xls-bordered"
                                    placeholder={"Custom label for " + item.label}
                                />
                            </Box>
                        </AnimBox>
                    ) }
                </React.Fragment>
            )) }
        </>
    )
}