import { useRef, useEffect, useState } from 'react';

export const useApiFetch = <T>(apiCall:() => Promise<T | undefined>, deps?:any[] | null, onComplete?:(...args:any) => any) => {

    const isCancelled = useRef(false);
    const hasRun = useRef(false);
    const [ loading, setLoading ] = useState<boolean>(deps ? true : false);
    const [ data, setData ] = useState<T>();

    const load = async () => {
        if (isCancelled.current) {
            console.log('canceled');
            return;
        }
        setLoading(true);
        const result = await apiCall();
        if (isCancelled.current) return;
        hasRun.current = true;
        setData(result);
        setLoading(false);
        if (onComplete) onComplete();
    }

    const loadWithoutBusyStates = async () => {
        if (isCancelled.current) return;
        const result = await apiCall();
        if (isCancelled.current) return;
        setData(result);
        if (onComplete) onComplete();
    }

    useEffect(() => {
        // Do NOT load unless dependencies were specified
        // (empty array specified means, run onload, obviously)
        // If deps exists and we cannot find a falsey one, load!
        if (deps && !deps.find(x => !x)) load();
    }, deps ?? [])

    useEffect(() => {
        return () => {
            isCancelled.current = true;
        }
    }, [])

    return {
        hasRun: hasRun.current,
        loading,
        data,
        load,
        loadWithoutBusyStates,
        setData // you shouldn't do this. it's a hack, used till I address table srp pattern
    }
}