import jwt_decode from 'jwt-decode';

export const hasJwtExpired = (jwt:string|undefined) => {
    if (!jwt) return true;
    try {
        const obj:any = jwt_decode(jwt);
        const expiresAt = obj['exp'];
        if (typeof expiresAt !== 'number') return true;
        const expiresAtMs = expiresAt * 1000; // we get it in epoch seconds
        // looking at computer time is fine, even though they could mess it up.
        // because server won't respond to expired jwts anyway.
        return expiresAtMs < Date.now();
    } catch (err) {
        return true;
    }
}