import React, { useRef } from 'react';
import { Link, Text, Box, FlexVCenter, Icon, DotsLabel } from 'mui';
import { alpha } from 'services';
import { GQL } from 'market-dto';

interface RunRowProps {
    readonly mt:"0"|"1";
    readonly item:alpha.RunLike;
    readonly sellerSymbol?:string;
    readonly dt?:string;
    readonly onSelect:() => void;
    readonly onReRun:() => void;
}
export const RunRow = ({ item, mt, onSelect, onReRun }:RunRowProps) => {

    const name = alpha.getFlowRunName(item);

    return (
        <FlexVCenter
            mt={mt}
            justifyContent="space-between"
            showIconsOnHover
            fg="default"
            fgHover="loud"
        >

            <Link onClick={onSelect}>{ name }</Link>

            <FlexVCenter>
                {/* <Icon
                    name="more-horizontal"
                    fg="link"
                    fgHover="loud"
                    cursor="pointer"
                    mr="1"
                    onClick={onSelect}
                /> */}
                <Icon
                    name="refresh"
                    fg="link"
                    fgHover="loud"
                    cursor="pointer"
                    onClick={onReRun}
                />
            </FlexVCenter>
        </FlexVCenter>
    )
}