import React from 'react';
import { Button, IconButton, modal } from 'mui';
import { genSheet, sheetSummaries, actMon, fannie, freddie } from 'services';
import { PricingModal } from './pricing-modal/pricing-modal';


interface Props {
    readonly summaries:sheetSummaries.SheetSummaryDetails[];
    readonly yyyymmdd:string;
}
export const RecentTapesButtons = ({ summaries, yyyymmdd }:Props) => {

    const pricingBusy = actMon.useJobsBusy([
        ...summaries.map(x => fannie.getFannieJobName(x.summary.sheetId)),
        ...summaries.map(x => freddie.getFreddieJobName(x.summary.sheetId)),
    ])

	const exportAll = async () => {
		const loanCnt = await genSheet.genMergedTapeSheet(yyyymmdd);
		if (loanCnt === 0) {
			// how can this happen? btn is only available if at least one summary exists,
			// yet, we found ZERO loans. Warn the user just in case.
			// I suppose an empty tape could be the only tape of the day.
			alert('Warning!\n\nThere were no loans with which to generate an output spreadsheet.');
		}
    }
    
    const selectedSummaries = summaries.filter(x => x.checked);

	const pricingModal = () => {
		modal.open({
			title: 'Multi-Tape Agency Pricing',
			size: 'md',
			static: true,
			render: <PricingModal
				summaries={selectedSummaries}
				onRun={() => sheetSummaries.setAllCheckedByDay(yyyymmdd, false)}
			/>
		})
	}

	return (
		<>
            <Button
                size="sm"
                mr="1"
                type={selectedSummaries.length === 0 ? 'disabled' : 'default'}
                busy={pricingBusy}
                onClick={pricingModal}
            >Pricing</Button>
            { summaries && summaries.length > 0 && <IconButton name="download" onClick={exportAll}/> }
		</>
	)
}
