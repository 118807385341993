import { GQL, CashWindow } from 'market-dto';
import { getBaseHoldingInterestItem } from './get-base-holding-interest-item';

export const getBasePrice = (offer:GQL.Offer, holdingInterest:CashWindow.HoldingInterest[]):number => {
    const baseItem = getBaseHoldingInterestItem(offer, holdingInterest);
    if (!baseItem) {
        throw new Error('could not find base holding item' + offer.acquisitionDate + ', ' + offer.notificationDate);
        return 0;
    }
    return (baseItem.couldSellFor ?? 0) + (baseItem.interestSpreadPct ?? 0);
}