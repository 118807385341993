import { GQL, ImportModel, format } from 'market-dto';
import { getUnformattedVal } from './get-unformatted-val';

export const getFormattedVal = (
    model:ImportModel,
    fieldCfgs:GQL.XlsExportCol[],
    f:GQL.XlsExportCol,
    row:GQL.FlowRunRow
) => {
    // Notice: we assume a "loan" prefix for fields lacking dots, and pull it from row.loan directly.

    const val = getUnformattedVal(fieldCfgs, f, row);

    if (f.isCalc) {
        return val;
    } else {
        if (f.type === "MAPPED") {
            const field = model.getField(f.fieldId);
            if (field.kind === "enum") {
                // Special case for enums
                if (f.useEnumVals) return val;
                if (!f.customEnumLabels) return model.formatValAs(val, f.fieldId);
                // you get here, they want a custom label.
                return f.customEnumLabels[String(val)] ?? model.formatValAs(val, f.fieldId);
            } else {
                const formatterId = f.formatterId ?? field.formatterId;
                
                return format.formatByFormatterId(val, formatterId);
            }

        } else {
            // New fields, new things we add to export...those MUST have full dot notation as field ids.
            // "loanProgram.program", for example as fieldId, which will pull value from
            // row.loanProgram.program.
            // For now, we have no formatting setup for these non-loan fields.
            // I can't remember, why the null exception here?
            // TODO: either String() in getUnformatedVal or remove this null thing or something...seems odd.
            return val === null ? null : String(val);
            // const obj = utils.getByDotNotation(f.fieldId, row);
            // return obj.found ? String(obj.val) : null;
        }
        
    }
    // if (!f.fieldId.includes(".")) {

    //     const id = f.fieldId as LoanField;
    //     const dictEntry = theDictionary[id];
    //     const val = row.loan[f.fieldId as LoanField];    

    //     if (dictEntry.type === "enum") {
    //         // Special case for enums
    //         if (f.useEnumVals) return val;
    //         if (!f.customEnumLabels) return formatVal(id, val);
    //         // you get here, they want a custom label.
    //         return f.customEnumLabels[String(val)] ?? formatVal(id, val);
    //     } else {
    //         const formatterId = f.formatterId ?? dictEntry.formatterId;
    //         return formatByFormatterId(formatterId, val);
    //     }

    // } else {
    //     // New fields, new things we add to export...those MUST have full dot notation as field ids.
    //     // "loanProgram.program", for example as fieldId, which will pull value from
    //     // row.loanProgram.program.
    //     // For now, we have no formatting setup for these non-loan fields.
    //     const obj = utils.getByDotNotation(f.fieldId, row);
    //     return obj.found ? String(obj.val) : null;
    // }
}