import React from 'react';
import { format } from 'services';
import { HitInfo } from 'services/proposal';

interface Props {
    readonly hitInfo:HitInfo;
}
export const CommitmentTable = ({ hitInfo }:Props) => {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th className="left">Investor</th>
                        <th className="left">Investor Product ID</th>
                        <th className="left">Investor Product Name</th>
                        <th className="right">Total</th>
                        <th className="right">Loan Count</th>
                        <th className="right">Min Coupon</th>
                        <th className="right">Max Coupon</th>
                    </tr>
                </thead>
                <tbody>
                    { hitInfo.counts.map((row, n) => {
                        return <tr key={n}>
                            <td className="left">{ row.investor }</td>
                            <td className="left">{ row.investorProductId }</td>
                            <td className="left">{ row.investorProductName }</td>
                            <td className="right">{ format.toCurrencyWithoutCents(row.sum) }</td>
                            <td className="right">{ row.loanCount }</td>
                            <td className="right">{ format.toThreeDecimals(row.minCoupon) }</td>
                            <td className="right">{ format.toThreeDecimals(row.maxCoupon) }</td>
                        </tr>
                    }) }

                </tbody>
            </table>
        </>
    )
}