import { zBatch } from '../z-batch';
import { mergeBatch } from '../merge-batch';

export const onAssignedOrSkipped = ():boolean => {

    // returning true means: we are all done assigning active templates and we should move to next batch step

    const batch = zBatch.get();
    if (!batch) return false;

    if (batch.step !== 'UI_CONFIRM_TEMPLATES') return false;

    if (batch.items.every(x => x.assigned || x.skipped || x.error)) {
        mergeBatch({ step: "POST_CONFIRM" });
        return true;
    }
    return false;

}