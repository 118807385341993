import { GQL } from 'market-dto';

export const RULE_MODEL_FIELD_TYPES = ["enum", "string", "integer", "number", "boolean"] as const;
export type RuleModelFiedlType = typeof RULE_MODEL_FIELD_TYPES[number];


export interface ModelInfo {
    // We take GQL model, translate it into this (easier to work with)
    readonly id:string;
    readonly refId:string;
    readonly name:string;
    readonly description:string;
    readonly groups:string[];
    readonly fields:RuleModelField[];
}

export interface RuleModelField { // TODO: RENAME
    readonly index:number; // index into allFields array (not filterd, not sorted)
    readonly fieldId:string;
    readonly label:string;
    readonly type:RuleModelFiedlType;
    readonly enums?:ReadonlyArray<string>;
    readonly groups:string[];
    readonly required:boolean;
}

export interface ChecklistWithModel {
    readonly checklist:GQL.Checklist;
    readonly model?:ModelInfo; // Why is this optional? means we checked, it wasn't there. Shouldn't happen in practice.
}

export interface SampleWithModel {
    readonly sample:GQL.ModelSample;
    readonly model?:ModelInfo; // Why is this optional? means we checked, it wasn't there. Shouldn't happen in practice.
}