import { GQL } from 'market-dto';
import { Generated, InvalidMatchResult, HashMatchConfidence } from '../types';

export const noSellersInAggTape = (
    generated:Generated,
    template:GQL.Template,
    hashConfidence:HashMatchConfidence,
):InvalidMatchResult => {
    // no seller name column found....and we've determined this is an agg tape!
    // This is a problem.
    // We COULD allow it, but we will NOT allow it. sellerName is too important for agg templates.
    return {
        type: "invalid",
        generated,
        matchState: "AGG_TEMPLATE_BUT_NO_SELLER_IN_TAPE",
        template,
        templateOverrideHash: 'invalid',
        hashConfidence
    }
}
