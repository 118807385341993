import { util } from 'market-dto';
import { renewJwt } from 'api';
import { getJwt } from './get-jwt';
import { updateJwt } from './update-jwt';
import { logout } from './logout';
import { wasJwtIssuedBeforeTime } from './was-jwt-issued-before-time';

const FIVE_MINUTES_MS = 60 * 1000 * 5;
const TWO_MINUTES_MS = 60 * 1000 * 2;

// Any time user takes an action, we should call this. Throttled, of course.

export const checkExtendSession = util.throttle(async () => {

    const jwt = getJwt();
    const thresholdAgo = Date.now() - FIVE_MINUTES_MS;

    if (!wasJwtIssuedBeforeTime(jwt, thresholdAgo)) {
        console.log('jwt is too new to mess with renewing');
        return;
    }
    const result = await renewJwt();
    // const result = await renewJwt({ oneMinuteOnly: true }); // While testing
    const nextJwt = result?.jwt;
    if (nextJwt) {
        console.log('jwt extended');
        updateJwt(nextJwt);
    } else {
        logout();
    }
}, TWO_MINUTES_MS);

