import React, { useState, useEffect } from 'react';
import { SpinLabel, FlexVCenter, Box, Tabs, BasicMenuItem, Button, IntoPortal } from 'mui';
import { AdjTable } from './adj-table';
import { GQL } from 'market-dto';
import { useApi } from 'hooks';
import { fetchAdjTable, upsertAdjTable } from 'api';
import { AdjustmentTable } from 'raccoon-engine';
import { Loading } from 'components';

interface Props {
    readonly tabs:BasicMenuItem[];
    readonly onImportExport?:(reloadFn:() => void) => void;
}
export const AdjGroup = ({ tabs, onImportExport }:Props) => {

    const [ tab, setTab ] = useState<GQL.AdjTableName>(tabs[0].value);

    const {
        update,
        fetching:loading,
        updating,
        dirty:isDirty,
        data:editTable,
        onChange:setEditTable,
        reset,
        refresh
    } = useApi<GQL.AdjTable>({
        fetch: async () => await fetchAdjTable(tab),
        update: async x => await upsertAdjTable(tab, x),
        deps: [tab]
    })

    const canSave = !loading && isDirty;
    const showSpinner = loading || !editTable;

    const onChange = (t:AdjustmentTable) => {
        if (!editTable) return;
        setEditTable({
            ...editTable,
            table:t
        })
    }

    const importExport = () => {
        if (!onImportExport) return;
        onImportExport(() => refresh());
    }

    return (
        <>
            <IntoPortal id="config-route-top-area">
                <FlexVCenter justifyContent="space-between">
                    <Tabs items={tabs} onSelect={setTab} selected={tab} mb="3" />
                    <Box>
                        { onImportExport && <Button size="sm" type="link" mr="1" onClick={importExport}>Import / Export</Button> }
                        { canSave && (
                            <>
                                <Button size="sm" type="link" mr="1" onClick={reset}>Cancel</Button>
                                <Button size="sm" onClick={update} busy={updating}>Save</Button>
                            </>
                        ) }
                        {/* { !canSave && <Button size="sm" type="disabled">Save</Button> } */}
                    </Box>
                </FlexVCenter>
            </IntoPortal>
            <Loading
                loading={showSpinner}
                render={editTable && <AdjTable table={editTable.table} onChange={onChange} />}
            />
        </>
    )
} 