import React, { useState, useEffect } from 'react';
import { Flex, Icon, FlexVCenter, Button, modal, ModalFooter, ModalSubHeader, Box, LabelValueGrid, Alert } from 'mui';
import { GQL, labels } from 'market-dto';
import { format, proposal, user } from 'services';
import * as api from 'api';
import { useApiUpdate } from 'hooks';


interface Props {
    readonly tradeResults?:GQL.CreateTradeResult;
    readonly goBack:() => void;
}
export const CreateTradeError = ({ tradeResults:trade, goBack }:Props) => {
    return (
        <>
            <ModalSubHeader>
                <Box m="2">
                    <Alert icon="alert-triangle">There was a problem creating the trade.</Alert>
                </Box>
            </ModalSubHeader>

            { trade && (
                <>
                    <Box my="2" mono style={{ whiteSpace: 'pre-line' }}>{ trade.errorMessage}</Box>
                    { trade.errorHint?.map((msg, n) => <Box key={n} monoSmall style={{ whiteSpace: 'pre-line' }}>{ msg } </Box>) }
                </>
            ) }
            
            <ModalFooter>
                <FlexVCenter justifyContent="flex-end" m="2">
                    <Button
                        type="default"
                        size="sm"
                        onClick={goBack}
                        mr="1"
                    >Try Again</Button>
                    <Button
                        type="default"
                        size="sm"
                        onClick={modal.close}
                    >Ok</Button>
                </FlexVCenter>
            </ModalFooter>
       </>
    )
}

