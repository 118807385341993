import React, { useEffect, useState } from 'react';
import { Box, FlexVCenter, Button, Icon, Text, modal, ModalFooter } from 'mui';
import { GQL } from 'market-dto';
import { rule } from 'services';
import { InvalidJson } from 'components';
import { useZStateList } from 'hooks';

interface Props {
    readonly json:string;
    readonly existingModels:rule.ModelInfo[];
}
export const UploadModal = ({ json, existingModels }:Props) => {

    const [ model, setModel ] = useState<GQL.Model>();
    const [ existingModel, setExistingModel ] = useState<rule.ModelInfo>();
    const [ reasons, setReasons ] = useState<string[]>([]);
    const {
        updating,
        selectNewItem:createEmptyModel,
        selectItem,
        updateItem:update,
        commitItem:save
    } = useZStateList(rule.zModels);

    const createNew = async () => {
        if (!model) throw new Error('Expected model');
        createEmptyModel(); // create empty new modelInfo
        update(rule.toModelInfo(model));
        await save();
        modal.close();
    }

    const replaceModel = async () => {
        if (!model) throw new Error('Expected model');
        if (!existingModel) throw new Error('Expected existingModel');
        if (!selectItem(existingModel)) throw new Error('Expected existingModel to be found in zList');
        update(rule.toModelInfo(model));
        await save();
        modal.close();
    }

    useEffect(() => {
        let obj:any;
        try {
            obj = JSON.parse(json);
        } catch (err) {
            setReasons(['Malformed JSON -- could not parse!']);
            return;
        }
        const invalidReasons = rule.isValidModel(obj);
        if (invalidReasons.length > 0) {
            setReasons(invalidReasons);
            return;
        }
        setModel(obj);
        const m = existingModels.find(x => x.id === obj.id);
        if (m) {
            setExistingModel(m);
        } else {
            setExistingModel(undefined);
        }
    }, [json, existingModels])

    if (reasons.length > 0) {
        return (
            <>
                <Box mt="2">
                    <InvalidJson reasons={reasons} />
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            type="default"
                            onClick={() => modal.close()}
                        >Ok</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    if (!model) return <></>;

    if (existingModel) {
        return (
            <>
                <Box mt="2">
                    A model already exists with the id you have provided!<br/>
                    Do you want to replace <Text fg="loud">{ existingModel.name || 'Untitled Model' }</Text>?
                </Box>
                <ModalFooter>
                    <FlexVCenter m="2" textAlign="right" justifyContent="flex-end">
                        <Button
                            type="secondary"
                            mr="1"
                            onClick={() => modal.close()}
                        >Cancel</Button>
                        <Button
                            busy={updating}
                            type="default"
                            onClick={() => replaceModel()}
                        >Yes, Replace Model</Button>
                    </FlexVCenter>
                </ModalFooter>
            </>
        )
    }
    if (model?.id) {
        return (
            <>
                <Box mt="2">
                    Unknown model id (<Text fg="loud">{ model.id }</Text>) specified.<br/>
                    If you are trying to upload a new model, remove the id and try again.
                </Box>
                <ModalFooter>
                    <Box m="2" textAlign="right">
                        <Button
                            type="default"
                            onClick={() => modal.close()}
                        >Ok</Button>
                    </Box>
                </ModalFooter>
            </>
        )
    }
    return (
        <>
            <Box mt="2">
                This will create a NEW model, <Text fg="loud">{ model.name }</Text>.<br/>
                Are you sure you want to create a brand new model?
            </Box>
            <ModalFooter>
                <FlexVCenter m="2" justifyContent="flex-end">
                    <Button
                        type="secondary"
                        mr="1"
                        onClick={() => modal.close()}
                    >Cancel</Button>
                    <Button
                        busy={updating}
                        type="default"
                        onClick={() => createNew()}
                    >Yes, Create It</Button>
                </FlexVCenter>
            </ModalFooter>
        </>
    )
}

