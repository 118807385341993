import { map, distinctUntilChanged } from 'rxjs/operators';
import { zBatch } from '../z-batch';

export const pausedState$ = zBatch.state$.pipe(
    map(x => {
        return {
            paused: x.data?.paused ? true : false,
            stepping: x.data?.stepping ? true : false
            // canceled: x.data?.canceled ? true :false
        }
    }),
    distinctUntilChanged((prev, curr) => {
        return prev.paused === curr.paused
            && prev.stepping === curr.stepping
            // && prev.canceled === curr.canceled
    })
)

