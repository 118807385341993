import React from 'react';
import { SpinLabel, SpacingSize } from 'mui';
import { useGracefulBusy } from 'hooks';

interface LoadingProps {
    readonly loading:boolean;
    readonly render:React.ReactNode;
    readonly hidingRender?:React.ReactNode;
    readonly loadingRender?:React.ReactNode;
    readonly mt?:SpacingSize;
}
export const Loading = ({
    loading,
    render,
    hidingRender,
    loadingRender,
    mt="2"
}:LoadingProps) => {    
    const gracefulBusy = useGracefulBusy(loading);
    if (loading || gracefulBusy) {
        if (!gracefulBusy) return <>{ hidingRender }</>;
        return loadingRender
            ? <>{loadingRender}</>
            : <SpinLabel mt={mt}>Loading...</SpinLabel>
    }
    return <>{render}</>;
}