import { Batch } from '../types';
import { mergeBatch } from '../merge-batch';
import { forkJoin, of, concat, NEVER } from 'rxjs';
import { tap, map, switchMap, filter, delay, first, last, takeWhile } from 'rxjs/operators';
import {
    apiInsertTape,
    withBatchItems,
    parseTape,
    withBatch
} from '../operators'
import { zBatch } from '../z-batch';
import { findTemplates$ } from './find-templates$';
import { tapeInsert$ } from './tape-insert$';
// import { getBatchItem } from './get-batch-item';

export const parseTapes$ = zBatch.state$.pipe(
    filter(x => !!x.data),
    map(x => x.data as Batch),
    first(),
    switchMap(b => {

        // concat(...) for serial
        // forkjoin([]) for parallel

        // tabNameAndRows will exist if we were successful in just getting the data from the sheet.
        // We can ignore those that have tapeInfo.

        const needParsing = b.items.filter(item => !item.skipped && !item.error && !item.tabNameAndRows);

        return forkJoin(needParsing.map(item => of(item.index).pipe(
            // NOPE -- we cannot just insert after parsing!
            // Because we might have to stop for UI .... 
            parseTape(),
            // apiInsertTape() // the big change
        ))).pipe(
            last(),
            withBatch(),
            switchMap(batch => {

                // TODO: perhaps combine needs password with needs tabs/headers/rows?

                if (batch.items.some(x => x.status === "PARSE_NEEDS_PASSWORD")) {
                    mergeBatch({ step: "UI_GET_PASSWORD" });
                    return of(batch); // STOP!
                } else if (batch.items.some(x => x.status === "PARSE_NEEDS_TABS_HEADERS_ROWS_SPECIFIED")) {
                    mergeBatch({ step: "UI_SPECIFY_TABS_HEADERS_ROWS" });
                    return of(batch); // STOP!
                } else {
                    // none of them need UI input, so continue
                    mergeBatch({ step: "TAPE_INSERT" });
                    return tapeInsert$;
                }
            })
        )
    })    
)