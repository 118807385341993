import { mutate, gql, GQL } from "../../client"
import { RETURNING } from './returning';

const INSERT = gql`
    mutation insertOffers($objects: [offer_insert_input!]) {
        insert_offer(objects: $objects) {
            errorMessage
            affectedRow
            returning { ${ RETURNING } }
        }
    }
`;

interface MutateInput {
    readonly objects:GQL.Offer[];
}

interface MutateOutput {
    readonly insert_offer:{
        readonly returning:GQL.Offer[];
    }
}

export const insertOffers = async (offers: GQL.Offer[]) => {
	await mutate<MutateInput, MutateOutput>(INSERT, { objects: offers });
}
