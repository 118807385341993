import React from 'react';
import { NavTabs, NavMenuItem, FlexVCenter, Box, Button, PortalPlaceholder } from 'mui';
import { Route, Routes, Navigate, useResolvedPath, useMatch } from 'react-router-dom';
import { Users } from './users/users';
import { Sellers } from './sellers/sellers';
import { Servicers } from './servicers/servicers';
import { Investors } from './investors/investors';
import { Tools } from './tools/tools';

export const Desk = () => {

    const items:NavMenuItem[] = [
        { label: "Users", to: "users" },
        { label: "Investors", to: "investors" },
        { label: "Sellers", to: "sellers" },
        { label: "Servicers", to: "servicers" },
        // { label: "Mapping", to: "mapping" },
        { label: "Tools", to: "tools" }
    ]

    return (
        <>
            <FlexVCenter my="2" justifyContent="space-between" height="btn-row">
                <NavTabs items={items}  />
                <PortalPlaceholder id="desk-portal"></PortalPlaceholder>
            </FlexVCenter>
            <Routes>
                <Route path="users/*" element={<Users />} />
                <Route path="investors/*" element={<Investors />} />
                <Route path="sellers/*" element={<Sellers />} />
                <Route path="servicers/*" element={<Servicers />} />
                {/* <Route path="mapping/*" element={<Mapping />} /> */}
                <Route path="tools/*" element={<Tools />} />
                <Route path="*" element={<Navigate to="users" />} />
            </Routes>
        </>
    )
}
