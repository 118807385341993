import React from 'react';
import { format } from 'market-dto';
import { actMon } from 'services';
import { Icon, Box, Text, TableLikeCssGrid } from 'mui';

interface CompletedJobsProps {
    readonly jobs:actMon.ActivityMonitorJob[];
}
export const CompletedJobs = ({ jobs }:CompletedJobsProps) => {

    if (jobs.length === 0) return <Box fg="really-faded" textStyle="italic">No completded jobs to see!</Box>;

    return (
        <TableLikeCssGrid cols={6} gap="1" rowGap="half">
            <Box fg="faded">Job</Box>
            <Box fg="faded">Name</Box>
            <Box fg="faded">Started</Box>
            <Box fg="faded">Ended</Box>
            <Box fg="faded">Status</Box>
            <Box fg="faded">&nbsp;</Box>

            { jobs.map((job, n) => (                
                <React.Fragment key={n}>
                    <Box
                        fg="link"
                        fgHover="loud"
                        cursor="pointer"
                        onClick={() => actMon.setSelectedJob(job.jobId)}
                    >{ job.label }</Box>
                    <Box>{ job.jobName }</Box>
                    <Box>{ format.toDateAndTime(job.startedAt) }</Box>
                    <Box>{ job.endedAt ? format.toDateAndTime(job.endedAt) : '???' }</Box>
                    <Box>
                        { job.status === 'TIMEOUT' && <Box fg="danger">Timed out</Box> }
                        { job.status === 'FAILURE' && <Box fg="danger">Error</Box> }
                        { job.status === 'SUCCESS' && <Box fg="success">Success</Box> }
                    </Box>
                    <Box>{ job.param }</Box>
                </React.Fragment>   
            )) }
        </TableLikeCssGrid>
    )
}