import React, { useState, useEffect } from 'react';
import { SpinLabel, Box, IntoPortal } from 'mui';
import { useSellerMargins, SellerAndMargin } from './userSellerMargins';
import { EditableRuleTable } from '../components/param-table.service';
import { ParamTable } from '../components/param-table';
import { ConfigHeader } from '../components/config-header';

const createParamTable = (sellers:Array<SellerAndMargin>):EditableRuleTable => {
    return {
        cols: [
            {
                field: "",
                label: "Seller",
                type: "const"
            },
            {
                field: "",
                label: "Margin",
                type: "float"
            },
        ],
        rows: sellers.map(x => {
            return {
                deleted: false,
                editting: false,
                vals: [ x.name, x.margin ]
            }
        })
    }
}

const paramTableToSellersArray = (t:EditableRuleTable, sellers:Array<SellerAndMargin>):Array<SellerAndMargin> => {
    return sellers.map((x, n) => {
        return {
            ...x,
            margin: t.rows[n].vals[1] as number
        }
    });
}

export const SellerMargins = () => {

    const { loading, data, update, saving } = useSellerMargins();
    const [ workingTable, setWorkingTable ] = useState<EditableRuleTable>();
    const [ dirty, setDirty ] = useState<boolean>(false);

    const reset = () => {
        if (!data) return;
        setWorkingTable(createParamTable(data.sellers));
        setDirty(false);
    }

    useEffect(reset, [data]);

    const onChange = (t:EditableRuleTable) => {
        setWorkingTable(t);
        setDirty(true);
    }

    const save = () => {
        if (!data?.sellers || !workingTable) return;
        const updatedSellers = paramTableToSellersArray(workingTable, data.sellers);
        update({
            defaultMargin: data.defaultMargin, // somehow, update this too.
            sellers: updatedSellers
        });
    }

    if (loading || !workingTable) return <SpinLabel>Loading...</SpinLabel>;

    return (
        <Box pb="2">
            <IntoPortal id="config-route-top-area">
                <ConfigHeader
                    cancel={reset}
                    dirty={dirty}
                    save={save}
                    saving={saving}
                    title="Seller Margins"
                    hideBorder
                />
            </IntoPortal>
            <ParamTable
                table={workingTable}
                allowDelete={false}
                onChange={onChange}
            />
        </Box>
    )
}
