import { GQL } from 'market-dto';
import { utils } from 'services';
import { getCalcEnumVal } from './get-calc-enum-val';

export const getUnformattedVal = (
    fieldCfgs:GQL.XlsExportCol[],
    f:GQL.XlsExportCol,
    row:GQL.FlowRunRow
) => {
    if (f.isCalc) {
        if (f.type === 'CALC_ENUM') {
            return getCalcEnumVal(fieldCfgs, f, row);
        }
        return 'getUnformattedVal: ' + f.type;
    } else {
        if (f.type === "MAPPED") {
            return (row.loan as any)[f.fieldId];
        } else {
            // But, for new fields, new things we add to export...those MUST have full dot notation as field ids.
            // "loanProgram.program", for example as fieldId, which will pull value from
            // row.loanProgram.program.
            // For now, we have no formatting setup for these non-loan fields.
            const obj = utils.getByDotNotation(f.fieldId, row);
            return obj.found ? obj.val : null;
        }
    }
}