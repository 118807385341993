import React from 'react';
import { Box, FlexVCenter } from 'mui';
import { proposal } from 'services';


interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly loan:proposal.HitLoan;
    readonly toggleChecked:(id:string, flag:boolean) => void;
    readonly customizing:boolean;
}
export const HitFieldProductCode = ({ hitInfo, loan, toggleChecked, customizing }:Props) => {

    // Initially we allowed them to uncheck things. That functionality still exists, but, it has been disabled.

    const canCheck = hitInfo.hitByLoanId[loan.id];
    const checked = hitInfo.checkedByLoanId[loan.id];
    const label = proposal.getHitFieldLabel('productCode');
    const val = proposal.formatHitField('productCode', loan['productCode']);
    const val2 = proposal.formatHitField('investorProductId', loan['investorProductId']);

    return (
        <Box
            p="half"
            m={customizing ? 'half' : '0'}
            fontSize="sm"
            borderWidth="thin"
            borderSides="all"
            borderColor="transparent"
            bg="card"
            cursor="default"
        >
            <FlexVCenter fg="default">
                <Box>{ val } / { val2 }</Box>
            </FlexVCenter>

            <FlexVCenter fg="faded">
                { label }
            </FlexVCenter>
        </Box>
    )
}
/*
    { canCheck && (
        <Checkbox
            mr="1"
            checked={checked}
            //onToggle={() => toggleChecked(loan.id, !checked)}
            //cursor="pointer"
        />
    ) }
*/