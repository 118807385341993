import { GQL } from 'market-dto';
import { addFlowRunJob } from './add-flow-run-job';
import { watchJobsBusyByName } from '../../activity-monitor';
import { from, of, Observable } from 'rxjs';
import { switchMap, tap, filter, map } from 'rxjs/operators';
import { zCurrentRun, zFlows, zRunState } from './z-flows';
import { getModelByModelType } from 'market-dto';
import * as api from 'api';

// This needs a state for "step" we are in, in another zState.



// export interface RunFlowResult {
//     readonly flowRun?:GQL.FlowRun;
//     readonly xlsExport?:GQL.TaskXlsExport;
// }
interface RunFlowProps {
    readonly flow:GQL.Flow;
    readonly tapeId:string;
    readonly fetchXlsExportTaskConfig?:boolean;
}
export const runFlow = ({ flow, tapeId, fetchXlsExportTaskConfig }:RunFlowProps):Observable<any> => {

    const model = getModelByModelType(flow.modelType);

    // entity "flow" creates multiple entities "run"
    // The server creates a batchId for each run WHICH IS EQUAL TO RUN ID !!!

    // jobName == what activity monitor calls it (ui)
    // batchId == what the server calls it, also used fo run.id


    // THREE things we wait for!
    // 1. adding job (api call)
    // 2. act mon to finish job (one more api call, currently, but, in future could take several)
    // 3. upon done, once more: final fetch of run.
    // 4. fetch xls export config if needed.

    // Now, we coulda fetched xls config as we waited...future optimization.

    return of(null).pipe(
        tap(() => zRunState.set({ status: "START_RUN" })),
        switchMap(() => addFlowRunJob(flow, tapeId)),
        tap(() => zRunState.set({ status: "RUNNING" })),
        switchMap(x => watchJobsBusyByName([x.jobName]).pipe(
            map(busy => {
                return {
                    runId: x.batchId,
                    busy,
                    jobName: x.jobName
                }
            })
        )),
        filter(x => !x.busy),
        tap(() => zRunState.set({ status: "FETCH_RESULTS" })),
        switchMap(x => zCurrentRun.fetch(x.runId)),
        tap(flowRun => {
            // If user hasn't navigated away,
            zRunState.merge({ flowRun });
            const { activeItemEditObj } = zFlows.getState();
            if (flowRun && activeItemEditObj && activeItemEditObj.id === flow.id) {
                // They might be looking at this flow (in the UI) as its still the current one.
                // We should replace this with an api call which ONLY fetches "recentActivity",
                // and replaces update it that way. But this will do for now.
                zFlows.updateActiveItemQuietly({
                    recentActivity: {
                        executedAt: new Date().toISOString(), // Z time
                        flowId: flow.id,
                        id: 'new',
                        runId: flowRun.id,
                        sheetId: tapeId,
                        sellerSymbol: flowRun.sellerSymbol,
                        sellerOrgId: flowRun.sellerOrgId
                    }
                })
            }
        }),
        switchMap(flowRun => {
            if (!fetchXlsExportTaskConfig) {
                // we are done.
                zRunState.merge({ status: "DONE" });
                return of(flowRun);
            }
            // Find first xls export task id and let's load that config.
            const xlsExportTaskId = flow.outputStage.tasks.find(x => x.kind === 'xlsExport')?.id;
            if (!xlsExportTaskId) {
                zRunState.merge({ status: "DONE" });
                return of(flowRun);
            }
            // You get here, we have something to fetch.
            zRunState.merge({ status: "FETCH_XLS_CFG" });
            return from(api.fetchTaskXlsExport(model, xlsExportTaskId)).pipe(
                tap(xlsExport => {
                    zRunState.merge({ xlsExport, status: "DONE" })
                })
            )
        })
    )
}

/*
    // I used to have this artificial delay 
    // const PRETEND_GENERATING_OUTPUT_TIMES:[number,number] = [600, 1200];
    // const DELAY_AFTER_DISPLAYING_DONE = 400;
    // type RunStatus = "running-flow" | "generating-output" | "ending-delay" | "done";

    // const PRETEND_GENERATING_OUTPUT_TIMES:[number,number] = [600, 1200];
    // const DELAY_AFTER_DISPLAYING_DONE = 400;

    // const rndTime = (range:[number, number]) => Math.floor(Math.random() * range[1] - range[0]) + range[0];    // FIX THIS TRASH:
    useEffect(() => {
        if (runningFlow) return;
        setStatus("generating-output");
        // A little gross. But not enough to get fancy.
        // Before you get nervous: that setTimeout is for UI ONLY PURPOSES
        // (using setTimeout for real logic would make me nervous)
        setTimeout(() => {
            if (isGone()) return;
            if (isNewFlow) {
                // nav('stages');
                return;
            }
            setStatus("ending-delay");
            setTimeout(() => {
                if (isGone()) return;
                setStatus("done");
            }, DELAY_AFTER_DISPLAYING_DONE);
        }, rndTime(PRETEND_GENERATING_OUTPUT_TIMES));

    }, [runningFlow]);
*/