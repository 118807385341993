import React from 'react';
import { MarketSettingSchema } from 'market-dto';
import { JsonSchemaUi, Loading } from 'components';
import { useZState } from 'hooks';
import { marketSettings } from 'services';

export const GeneralSettings = () => {

	const {
		data:settings,
		fetching,
		update:onChange,
		reset,
		save,
		saving,
		dirty,
		editting,
		setEditting
	} = useZState(marketSettings.zMarketSettings);

	return (
		<Loading
			loading={fetching}
			render={
				<JsonSchemaUi
					mb="3"
					data={settings ?? {}}
					onSave={save}
					saving={saving}
					schema={MarketSettingSchema}
					onChange={onChange}
					reset={reset}
					dirty={dirty}
					editting={editting}
					setEditting={setEditting}
					portalId="config-route-top-area"
				/>
			}
		/>
	)
}