import React, { useState, useRef, createRef, useEffect } from 'react';
import { Box } from 'mui';
import { ExpContext, ExpressionEditorState, Editor, ExpEvalStatus, getEvalStatusFromEditorState } from 'exp';
import { EditorPassFailCols } from './editor-pass-fail-cols';

interface Props {
    readonly label:string;
    readonly initVal:string;
    readonly expCtx:ExpContext;
    readonly focus:boolean;
    readonly autoFocus?:boolean;
    readonly onChange?:(expState:ExpressionEditorState, evalStatus:ExpEvalStatus) => void;
    readonly onSubmit?:(tabKey:boolean, ctrlDown:boolean, shiftDown:boolean) => void;
    readonly onFocus?:() => void;
    readonly onBlur?:() => void;
    readonly runData:any;
}
export const EditorContainer = ({ autoFocus, runData, initVal, focus, expCtx, onChange, onSubmit, label, onFocus, onBlur }:Props) => {

    // only place where we hold editor state (parent component should not)
    const [ expState, setExpState ] = useState<ExpressionEditorState>();
    const [ evalStatus, setEvalStatus ] = useState<ExpEvalStatus>({
        canEval: false,
        evalResult: false,
        isBlank: true,
        validSyntax: true
    })

    const [ elem, setElem ] = useState<HTMLInputElement|null>();
    
    useEffect(() => {
        if (!expState) return;
        const status = getEvalStatusFromEditorState(expState, expCtx, runData);
        setEvalStatus(status);
        if (onChange) onChange(expState, status);
    }, [runData])

    useEffect(() => {
        if (focus && elem) elem.focus();
    }, [focus, elem])

    const change = (x:ExpressionEditorState) => {
        const status = getEvalStatusFromEditorState(x, expCtx, runData);
        setEvalStatus(status);
        setExpState(x);
        if (onChange) onChange(x, status);
    }

    const submit = (tabKey:boolean, ctrlDown:boolean, shiftDown:boolean) => {
        if (onSubmit) {
            elem!.blur();
            onSubmit(tabKey, ctrlDown, shiftDown);
        }
    }
    
    return (
        <>
            <Box fg={focus ? 'default' : 'faded'} textAlign="right" fontSize="sm">
                { label }
            </Box>
            <Box>
                <Editor
                    // debug
                    onFocus={onFocus}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    ref={setElem}
                    initVal={initVal}
                    expCtx={expCtx}
                    onChange={change}
                    onSubmit={submit}
                />
            </Box>
            <EditorPassFailCols evalStatus={evalStatus} />
        </>
    )
}

