import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

interface ViewDimensions {
    width:number;
    height:number;
    min:number;
}

const getDims = ():ViewDimensions => {
    let w = window.document.documentElement.clientWidth;
    let h = window.document.documentElement.clientHeight;
    let min = (w <= h) ? w : h;
    return {
        width: w,
        height: h,
        min: min
    }
}

const dims$ = fromEvent(window, 'resize').pipe(
    debounceTime(30),
    map(() => getDims())
)

export const useViewport = () => {
    const [ dims, setDims ] = useState<ViewDimensions>(getDims);
    useEffect(() => {
        const sub = dims$.subscribe(setDims)
        return () => sub.unsubscribe();
    }, [])
    return dims;
}