import React from "react";
import { format, isFirstDayOfMonth } from "date-fns"
import { Flex, SpectrumGrid, SpectrumGridItemDetails } from "mui";
import { HoverItem } from './hover-item';
import { profit, utils } from 'services';

const dateDisplay = (dt:Date) => {
	if (isFirstDayOfMonth(dt)) return format(dt, "MMM dd");
	return format(dt, "dd");
}

const getDividers = (arr:Date[]):number[] => {
	const result:number[] = [];
	arr.forEach((dt, n) => {
		if (n < arr.length-1) {
			const next = arr[n+1];
			if (dt.getMonth() !== next.getMonth()) result.push(n);
		}
	})
	return result;
}

interface Props {
	readonly holdingInfo:profit.HoldingInfo;
}
export const HeatMapContainer = ({ holdingInfo:info }:Props) => {
	
	const yAxisDates = info.aquisitionDates.map(x => utils.ensureDate(x));
	const xAxisDates = info.deliveryDates.map(x => utils.ensureDate(x));

	// determine where to show dividers (between months)
	const xDividers:number[] = getDividers(xAxisDates);
	const yDividers:number[] = getDividers(yAxisDates);

	// Formatted axis values
	const yAxis = yAxisDates.map(dt => dateDisplay(dt));
	const xAxis = xAxisDates.map(dt => format(dt, "dd"));

	// The numer or null row data
	const rows = info.holdingInterest.map(innerArr => {
		return innerArr.map(x => {
			if (x === null) return null;
			const { interestSpreadPct, couldSellFor } = x;
			const total = interestSpreadPct + couldSellFor;
			const diff = total - info.basePrice;
			return diff;
		})
	})

	// console.log('ROWS', rows);

	return (
		<Flex mt="3" fontSize="xs" alignItems="center" justifyContent="center" flexDirection="column">

			{/* <textarea readOnly value={JSON.stringify({
				rows,
				xDividers,
				yDividers,
				xAxis,
				yAxis
			}, null, 4)}></textarea> */}

			<SpectrumGrid
				baseline={0}
				rows={rows}
				xDividerAfter={xDividers}
				yDividerAfter={yDividers}
				xAxis={xAxis}
				yAxis={yAxis}
				renderHover={(item:SpectrumGridItemDetails) =>
					item && <HoverItem
						holding={info.holdingInterest[item.rowIndex][item.colIndex]}
						item={item}
					/>
				}
			/>
		</Flex>
	)
}
