import { gql, mutate } from "../../client"

// Tell the back-end to poll resi for any pools of loans
const RESI_POLL = gql`
    mutation {
        resi_polling_sync
    }
`;

// ...for every pool found by the poll, import the pool
const RESI_IMPORT = gql`
    mutation resiImport($poolId: Int) {
        resi_import(poolId: $poolId)
    }
`;

interface ResiPollingOutput {
    readonly resi_polling_sync:number[];
}

interface ResiImportInput {
    readonly poolId:number;
}

const kickOffResiImport = (poolId:number) => {
    // Do NOT await the mutate below. Just kick it off!
    mutate<ResiImportInput, any>(RESI_IMPORT, { poolId });
}

export const pollForResiPools = async () => {
    const result = await mutate<any, ResiPollingOutput>(RESI_POLL, {});
    const ids = result?.data?.resi_polling_sync;
    if (!ids || ids.length === 0) {
        console.log('no ids');
        return;
    }
    ids.forEach(id => kickOffResiImport(id));
}