import { zMarketSettings } from './z-market-settings';

type Params = {[k:string]:string|number|boolean};

const hasAcctAndPass = (settings:Params, acctKey:string, passKey:string):boolean => {
    const acct = settings[acctKey];
    const pass = settings[passKey];
    if (!acct || !pass) return false;
    return true;
}

export const hasValidCREnvironment = ():boolean => {
    const settings = zMarketSettings.get();
    if (!settings) return false;
    const env = settings['cleanroom.environment'];
    if (!env) return false;
    if (env === 'CIT') {
        return hasAcctAndPass(settings, 'cleanroom.citApiAccount', 'cleanroom.encryptedCitApiPassword');
    } else if (env === 'PROD') {
        return hasAcctAndPass(settings, 'cleanroom.apiAccount', 'cleanroom.encryptedApiPassword');
    }
    return false;
}