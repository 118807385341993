import { GQL, FlatJSONSchema, FlatJSONSchemaField, FlatJSONSchemaType } from 'market-dto';
import { RuleModelField, RuleModelFiedlType, ModelInfo } from './types';

const ruleFieldTypeToJsonSchemaType = (x:RuleModelFiedlType):FlatJSONSchemaType => {
    // WE SHOULD MAKE OUR OWN SCHEMA+VALIDATOR THING!
    if (x === 'enum') return 'string';
    return x;
}

const toJSONSchema = (modelInfo:Partial<ModelInfo>):FlatJSONSchema => {
    const { fields } = modelInfo;
    if (!fields) throw new Error('Missing: fields');
    const properties:{ [key:string]:FlatJSONSchemaField } = {};
    const required = fields.filter(x => x.required).map(x => x.fieldId);
    fields.forEach(f => {
        properties[f.fieldId] = {
            type: ruleFieldTypeToJsonSchemaType(f.type),
            title: f.label,
            tags: f.groups,
            ...f.type === 'enum' ? { enum: f.enums } : {}
        }
    })
    const result:FlatJSONSchema = {
        // ...modelInfo,
        properties,
        required
    }
    return result;
}


// export const toGqlModel = (model:GQL.Model, groups:string[], fields:RuleModelField[]):GQL.Model => {
//     return {
//         ...model,
//         groups,
//         schema: toJSONSchema(model, fields)
//     }
// }

export const toGqlModel = (m:ModelInfo):GQL.Model => {
    return {
        description: m.description,
        groups: m.groups,
        id: m.id,
        name: m.name,
        schema: toJSONSchema(m),
        refId: m.refId
    }
}