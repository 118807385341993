import { ExpContext, ExpNode, ExpOp, EXP_INVALID_CHAR, ExpEvalResult, ExpUnaryOp } from './exp-types';
import { parseExpression } from './parse-expression';
import { genericWalker, GenericWalkerOpts } from './generic-walker';
import { operatorDefByName } from './operator-defs';

export const evalExpFromString = (expStr:string, expCtx:ExpContext, data:any):ExpEvalResult => {
    const { node, warnings } = parseExpression(expStr, expCtx);
    if (warnings.length > 0) return false;
    if (!node) return true;
    return evalExpFromNode(node, expCtx, data);
}

export const evalExpFromNode = (node:ExpNode, expCtx:ExpContext, data:any) => {
    return evalWalk(node, expCtx, data);
}

const retVal = (node:ExpNode, val:ExpEvalResult):ExpEvalResult => {
    node.evalsTo = val;
    return val;
}

export const evalWalk = (rootNode:ExpNode, expCtx:ExpContext, data:any):ExpEvalResult => {

    const accessor = (fieldId:string):string|number|boolean => {
        if (!data.hasOwnProperty(fieldId)) throw new Error('Expected field (' + fieldId + ')');
        return data[fieldId];
    }

    const result = genericWalker({
        acc: 0,
        initChildAcc: [],
        lvl: 0,
        parent: null,
        node: rootNode,
        fn: (x:GenericWalkerOpts<ExpEvalResult>):ExpEvalResult => {
            
            const { acc, initChildAcc, fn, lvl, node, childAcc } = x;
            if (node === null) return 'unknown';
            
            switch (node.type) {
                case 'ConstantNode': {
                    if (node.value === EXP_INVALID_CHAR) throw new Error('Invalid char');
                    return retVal(node, node.value);
                }
                case 'StringConstantNode': {
                    // remove the single quotes
                    if (!node.endQuoteFound) throw new Error('Unterminated string constant');
                    return retVal(node, node.value.substring(1, node.value.length-1));
                }
                case 'IdentifierNode': {
                    return retVal(node, accessor(node.value));
                }
                case 'ExpressionNode': {
                    if (childAcc == undefined) throw new Error('Missing child acc on expresion node');
                    return retVal(node, evalOp(node.op, acc, childAcc));
                }
                case 'FunctionCallNode': {
                    const fn = expCtx.funcByName[node.fnName];
                    if (!fn) throw new Error('Missing function: (' + node.fnName + ')');
                    if (!Array.isArray(childAcc)) throw new Error('Expected childAcc to be an array (' + acc + ') (' + childAcc + ')');
                    if (!fn.evalFn) throw new Error('No eval function! Are you trying to eval using raccoon functions?');
                    return retVal(node, fn.evalFn!(childAcc));
                }
                case 'ArrayNode': {
                    // TODO: ARRAYS -- or, should we get rid of arrays?
                    return 1;
                }
                case 'ParenScopedNode': {
                    if (childAcc === undefined) throw new Error('Paren with invalid child acc');
                    return retVal(node, childAcc);
                }
                case 'ListItemNode': {
                    if (!Array.isArray(acc)) throw new Error('Expected array (' + acc + ', ' + childAcc + ')');
                    if (childAcc === undefined) throw new Error('List item child acc was undefined');
                    return retVal(node, acc.concat(childAcc));
                }
                case 'UnaryNode': {
                    return retVal(node, evalUnaryOp(node.op, acc));
                }
            }
            throw new Error('Unhandled node type');
            return 'unknown';
        }
    })
    return result;
}

const evalOp = (op:ExpOp, left:ExpEvalResult, right:ExpEvalResult) => {
    const opDef = operatorDefByName[op];
    if (!opDef) throw new Error('Unhandled operator: ' + op);
    return opDef.evalFn(left, right);
}

const evalUnaryOp = (op:ExpUnaryOp, item:ExpEvalResult) => {
    if (Array.isArray(item)) throw new Error('Array not allowed as unary operand');
    switch (op) {
        // Right now, there is only ONE unary operator. Maybe we'll add more later.
        case '-': return Number(item)*-1;
    }
}