import { ToastError } from 'api/client';
import { RawProposal, ProposalDetails, ProposalLoanDetails } from './types';

export const toProposalDetails = (raw:RawProposal):ProposalDetails => {
    return {
        id: raw.id,
        status: raw.status,
        sheetId: raw.sheetId,
        sheetSummary: raw.sheetSummary,
        sellerName: raw.sellerName,
        sellerOrgId: raw.sellerOrgId,
        hitLoanIds: raw.hitLoanIds,
        loans: raw.loans.map((rawLoan):ProposalLoanDetails => {
            const offer = raw.offers.find(offer => offer.loanNumber === rawLoan.loanNumber);
            if (!offer) throw new ToastError(`Missing offer for loan number ${ rawLoan.loanNumber } (${ rawLoan.id })`);
            return {
                ...rawLoan,
                ...offer
            }
        })
    }
}