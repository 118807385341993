import { GQL } from 'market-dto';

const fromStage = (stage?:GQL.Stage):GQL.FlatTask[] => {
    if (!stage || !stage.tasks) return [];
    return stage.tasks.map((task):GQL.FlatTask => {
        return {
            stageKind: stage.kind,
            stageName: stage.name,
            taskId: task.id,
            taskKind: task.kind,
            taskName: task.name
        }
    })
}

export const withFlattenedTasks = (flow:GQL.Flow):GQL.Flow => {
    const { outputStage, pricingStage, eligibilityStage, loanProgramStage } = flow;
    // FOR NOW INPUT STAGE IS IGNORED.
    // This order better never change!
    return {
        ...flow,
        tasks: [
            ...fromStage(eligibilityStage),
            ...fromStage(loanProgramStage),
            ...fromStage(pricingStage),
            ...fromStage(outputStage)
        ]
    }
}