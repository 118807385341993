import React, { useEffect, useState } from 'react';
import { Box, Text, FlexVCenter, Checkbox, Button } from 'mui';
import { actMon } from 'services';
import { GQL } from 'market-dto';
import { useStickyStateWithDefault } from 'hooks';
import { NothingYet } from './nothing-yet';
import { getStatus } from './get-status';
import { DictOfLogs } from './types';
import { stageCfg } from './stage-cfg';
import { LoanDetails } from './loan-details';

/*
    {
        "tm": 1609778058615,
        "id": "20210104-0001-018",
        "vm": "j51jn5Va",
        "msg": [
            "userName: mtrade_pmasys2sys",
            "environment: PROD",
            "requestPartyId: SMA718",
            "sellerId: \"223567\"",
            "password: ---",
            "duWaiver: true",
            ""
        ]
    },
*/


interface Props {
    readonly job:actMon.ActivityMonitorJob;
}
export const PricingJob = ({ job }:Props) => {

    const [ dict, setDict ] = useState<DictOfLogs>({});
    const [ showCompleted, setShowCompleted ] = useStickyStateWithDefault('act-mon-pricing-show-completed', true);
    const [ vms, setVms ] = useState<string[]>([]);
    const [ selectedId, setSelectedId ] = useState<string>('');

    useEffect(() => {
        const result = job.latestPollResult as GQL.BatchStatus;
        if (!result) return;
        const logs = result.logs ?? [];
        let obj:DictOfLogs = {};
        let vms:any = {};
        logs.forEach(log => {
            vms[log.vm] = true;
            const curr = obj[log.id];
            if (!curr) {
                obj[log.id] = {
                    id: log.id,
                    // We must always have a stage. This is the first log for this id.
                    stage: log.stage ?? 'init',
                    msg: log.display ?? (log.msg.length > 0 ? log.msg[0] : ''),
                    logs: [log]
                }
            } else {
                obj[log.id] = {
                    ...curr,
                    // Overwrite the stage if we can, else, take last one
                    stage: log.stage ?? curr.stage,
                    msg: log.display ?? (log.msg.length > 0 ? log.msg[0] : ''),
                    // logs: [log, ...curr.logs]
                    logs: [...curr.logs, log]
                }
            }            
        })
        setVms(Object.keys(vms));
        setDict(obj);
    }, [job])

    const result = job.latestPollResult as GQL.BatchStatus;
    if (!result) return <NothingYet />;

    const status = getStatus(result.summary);

    const total = result.summary.count ?? 0;
    const failed = result.summary.failed ?? 0;
    const completed = result.summary.completed ?? 0;
    const started = result.summary.started ?? 0;

    const arr =  Object.values(dict).sort((a,b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
    })
    const logs = arr.filter(log => showCompleted || log.stage !== 'complete');
    const sel = selectedId && dict[selectedId];

    return (
        <>
            <FlexVCenter mb="1" justifyContent="space-between" width="100%">
                <FlexVCenter>
                    { sel && (
                        <Button
                            mr="2"
                            size="xs"
                            type="secondary"
                            icon="arrow-left"
                            onClick={() => setSelectedId('')}>Back</Button>
                    ) }
                    <Box fontWeight="bold">
                        { status === 'COMPLETE' && <Box fg="success">Complete</Box> }
                        { status === 'FAILED' && <Box fg="danger">Failure</Box> }
                        { status === 'AWAITING_START' && <Box fg="default">Queued</Box> }
                        { status === 'BUSY' && <Box fg="loud">In progress</Box> }
                    </Box>
                    <Box ml="2" fg="faded">
                        Total: <Text fg="loud">{ total }</Text>,
                        Started: <Text fg="default">{ started }</Text>,
                        Completed: <Text fg="default">{ completed }</Text>,                    
                        Failed: <Text fg={failed > 0 ? 'danger' : 'default'}>{failed }</Text>
                    </Box>
                </FlexVCenter>
                <FlexVCenter>
                    { (logs.length > 0 || completed > 0) && (
                        <Checkbox
                            mr="2"
                            checked={showCompleted}
                            onToggle={setShowCompleted}
                            label="Show Completed"
                        />
                    ) }
                    { vms.length > 0 && (
                        <>
                            <Text fg="default">VMs</Text>
                            <Text fg="faded">({ vms.length }):</Text>
                            <Text fg="default">{ vms.join(', ') }</Text>
                        </>
                    ) }
                </FlexVCenter>
            </FlexVCenter>
            { sel && <LoanDetails item={sel} /> }
            { !sel && (
                <>
                    { logs.map(log => {
                        return <FlexVCenter key={log.id}>
                            <Box mr="1" fg="link" fgHover="loud" cursor="pointer" onClick={() => setSelectedId(log.id)}>{ log.id }</Box>
                            <Box mr="1" fg={stageCfg[log.stage].fg}>{ stageCfg[log.stage].label }</Box>
                            <Box>{ log.msg }</Box>
                        </FlexVCenter>
                    }) }
                    { logs.length === 0 && completed === 0 && <Box fg="really-faded" textStyle="italic">No logs yet exist for this job.</Box> }
                    { logs.length === 0 && completed > 0 && <Box fg="really-faded" textStyle="italic">All done!</Box> }
                </>
            ) }
        </>
    )
}