import React, { useState, useEffect } from 'react';
import {
    usePageSortTable,
    TablePageButtons,
    SortableTableHeader,
    Text,
    Icon,
    modal
}  from 'mui';
import { GQL } from 'market-dto';
import { rule } from 'services';
import { useNavigate } from "react-router-dom";
import { CloneChecklistModal } from './clone-checklist-modal';


interface TableItem extends GQL.Checklist {
    readonly modelName?:string;
    // readonly itemCount:number;
}

interface Props {
    readonly checklists:rule.ChecklistWithModel[];
}

export const ChecklistsTable = ({ checklists }:Props) => {

    // const match = useRouteMatch();
    const navigate = useNavigate();

    const {
        sortBy,
        asc,
        buttons,
        page,
        totalPages,
        setPage,
        sortedBy,
        sortedRows,
        pageSortState:ps
    } = usePageSortTable<rule.ChecklistWithModel, keyof TableItem>({
        rows: checklists,
        keyFn: row => row.checklist.id,
        valFn: (row, colId) => {
            if (colId === 'modelName') return row.model?.name;
            if (colId === 'tags') return row.checklist.tags.join(', ');
            // if (colId === 'itemCount') return row.checklist.items.length;
            return row.checklist[colId];
        },
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })

    const load = (row:rule.ChecklistWithModel) => {
        navigate(row.checklist.id);
    }

    const openCloneModal = (item:rule.ChecklistWithModel) => {
        modal.open({
            title: 'Clone ' + item.checklist.name,
            render: <CloneChecklistModal item={item.checklist} />
        })
    }

    return (
        <>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="refId" render="Ref Id" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="name" render="Name" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="description" render="Description" />
                        {/* <SortableTableHeader ps={ps} sortBy={sortBy} colId="itemCount" render="Items" /> */}
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="tags" render="Tags" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="modelName" render="Model" />
                        <th style={{ width: '1%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.length === 0 && (
                        <tr>
                            <td colSpan={6}><Text textStyle="italic">No checklists to display</Text></td>
                        </tr>
                    ) }
                    { sortedRows.length > 0 && sortedRows.map(row => (
                        <tr key={row.checklist.id}>
                            <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.checklist.refId }</td>
                            <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.checklist.name }</td>
                            <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.checklist.description }</td>
                            {/* <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.checklist.items.length }</td> */}
                            <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.checklist.tags.join(', ') }</td>
                            <td onClick={() => load(row)} style={{ cursor: 'pointer' }}>{ row.model?.name }</td>
                            <td className="center">
                                <Icon
                                    name="copy"
                                    fg="default"
                                    fgHover="loud"
                                    cursor="pointer"
                                    title="Clone Checklist"
                                    onClick={() => openCloneModal(row)}
                                />
                            </td>
                        </tr>
                    )) }
                </tbody>
            </table>
    
            <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </>
    )
}