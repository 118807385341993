import { GQL } from 'market-dto';
import { NavBlockItem, NavBlockItemChildItem } from './types';

export const flowToNavBlockItems = (flow:GQL.Flow):NavBlockItem[] => {
    // We get one block per stage.
    // We want to base the order of the stages on the task array.
    // const blockCfgs:BlockCfg[] = [];
    const result:NavBlockItem[] = [{
        label: 'Input',
        baseRoute: '',
        childItems: []
    }]
    flow.tasks.forEach((task, taskIndex) => {

        const child:NavBlockItemChildItem = {
            id: String(taskIndex+1),
            label: task.taskName
        }

        const prevBlock = result.length === 0 ? null : result[result.length-1];
        // const prevBlockCfg = blockCfgs.length === 0 ? null : blockCfgs[blockCfgs.length-1];

        if (prevBlock?.kind === task.stageKind) {
            result[result.length-1] = {
                ...prevBlock,
                childItems: prevBlock.childItems.concat(child)
            }
        } else {
            result.push({
                baseRoute: '..',
                childItems: [child],
                label: task.stageName,
                exact: true
            })
        }
    })
    return result;
}