import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { Link, Box, LabelValueGrid, Flex, Icon, FlexVCenter, Alert, Dropdown, DropdownItem, Button } from 'mui';

interface Props {
    readonly seller:GQL.Org;
    readonly isNew:boolean;
    readonly sellers:GQL.Org[];
    readonly assignSeller:(x:GQL.Org) => void;
}
export const MappingHeaderNonAgg = ({ seller, sellers, isNew, assignSeller }:Props) => {

    // Seller is editable if NOT from a match (means, we will create new template)
    const [ sellerNotRight, setSellerNotRight ] = useState(false);
    const [ org, setOrg ] = useState<GQL.Org>();

    const sellerOpts = sellers.filter(x => x.id !== seller.id).map((x):DropdownItem => {
        return {
            label: x.name,
            value: x
        }
    })

    const onAssignSeller = () => {
        if (!org) return;
        setSellerNotRight(false);
        assignSeller(org);
    }

    return (
        <Box mx="1" mb="3" width="card-lg">
            <Flex flexDirection="column" alignItems="center">
                <Box fg="faded" fontSize="sm">
                    This tape did not originate from an aggregator.
                </Box>
                <LabelValueGrid>
                    <Box textAlign="right" fg="faded">Seller:</Box>
                    <FlexVCenter>
                        <Box fg="loud">{ seller.name }</Box>
                        { isNew && (
                            <Box fontSize="sm" ml="1">
                                <Icon
                                    name="edit"
                                    onClick={() => setSellerNotRight(true)}
                                    fgHover="link"
                                    fg="default"
                                    cursor="pointer"
                                />
                            </Box>
                        ) }
                    </FlexVCenter>
                </LabelValueGrid>
            </Flex>

            { sellerNotRight && (
                <Alert py="1" mt="2">
                    <Box fg="default">
                        Please identify the seller below.
                    </Box>
                    <FlexVCenter my="half">
                        <Dropdown
                            width="frm-md"
                            size="sm"
                            value={sellerOpts.find(x => x.value === org)}
                            setValue={setOrg}
                            items={sellerOpts}
                        />
                        <Button
                            ml="1"
                            size="sm"
                            type={org ? 'default' : 'disabled'}
                            onClick={onAssignSeller}
                        >Ok</Button>
                    </FlexVCenter>
                    <Link onClick={() => setSellerNotRight(false)}>Nevermind. { seller.name } is correct.</Link>
                </Alert>
            ) }
        </Box>
    )
}