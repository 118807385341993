import { labels, util, loanModel } from 'market-dto';
import { WhenEligible } from 'raccoon-engine';
// import { htmlTableStringToRowsAndHeaders } from '../components/import-table.service';
import {
    EditableRuleTable,
    EditableRuleTableCol,
    ruleTableRowToWhenEligibleConstruct,
    whenEligibleConstructsToRuleTableRows
} from '../components/param-table.service';
import { paste } from 'services';

// Specific to max-ltv --- provides two things:
// Max LTV construct objects => EditTable
// EditTable => Max LTV construct objects

const isEmpty = (x:any) => x === undefined || x === null || (typeof x === 'string' && x === '');

export const ruleTableToMaxLtvRules = (t:EditableRuleTable):WhenEligible[] => {
    return t.rows
        // not deleted AND you have at least one non-empty value
        .filter(row => !row.deleted && row.vals.find(v => !isEmpty(v)))
        .map((row, n) => ruleTableRowToWhenEligibleConstruct(t.cols,row, 'MAX', 'ltv', 'ltv', n))
        .map(construct => new WhenEligible(construct.assertions.filter(x => x.param !== null), construct.eligibility))
}

export const maxLtvRulesToRuleTable = (rules:WhenEligible[]):EditableRuleTable => {

    const cols:EditableRuleTableCol[] = [
        {
            label: labels.byLoanField('occupancy'),
            field: 'occupancy',
            enums: loanModel.getEnumValues("occupancy"),
            type: 'enum'
        },
        {
            label: labels.byLoanField('loanPurpose'),
            field: 'loanPurpose',
            enums: loanModel.getEnumValues("loanPurpose"),
            type: 'enum'
        },
        {
            label: 'Units',
            field: 'units',
            type: 'int'
        },
        {
            label: 'State',
            field: 'state',
            type: 'enum',
            enums: util.stateAbbrevs
        },
        {
            label: 'Max LTV',
            field: 'ltv',
            type: 'int'
        }
    ]

    return {
        cols: cols,
        rows: whenEligibleConstructsToRuleTableRows(rules.map(x => x.construct), cols)
    }
}

export const htmlTableToEditableRuleTable = (currentTable:EditableRuleTable, s:string):EditableRuleTable => {
    const { rows, headers } = paste.htmlTableStringToRowsAndHeaders(s);
    if (headers.length !== currentTable.cols.length) throw 'Incorrect amount of headers';
    return {
        cols: currentTable.cols,
        rows: rows.map(vals => {
            return {
                deleted: false,
                editting: false,
                vals: vals
            }
        })
    }
}

