import React  from 'react';
import { Box } from '../box/box';
import { FlexVCenter } from '../flex/flex';
import { IconCheckbox, IconCheckboxProps } from '../checkbox/icon-checkbox';
import { SpacingSize, InputSize } from '../../types';

export interface FrmCheckboxProps extends IconCheckboxProps {
    readonly label:string;
    readonly desc?:string;
    readonly mt?:SpacingSize;
    readonly size?:InputSize;
}

export const FrmCheckbox = ({
    label,
    desc,
    mt = "1",    // To override (set to zero) the margin on first item in a card
    ...props
}:FrmCheckboxProps) => {
    // Size does not affect this. But it might in the future.
    const toggle = () => {
        if (props.readOnly) return;
        if (props.onToggle) props.onToggle(!props.checked);
    }
    return (
        <FlexVCenter mt={mt} mb="1">
            <IconCheckbox {...props} ml="half" />
            <Box
                fg="form-label"
                fontSize="md"
                ml="1"
                cursor="pointer"
                onClick={toggle}
            >{ label }</Box>
            { desc && <Box fontSize="sm" fg="form-desc" ml="1">{ desc }</Box> }
        </FlexVCenter>
    )
}