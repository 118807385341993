import React from 'react';
import { useApi2 } from 'hooks';
import { Link, Box, Button, SpinLabel, Heading, FlexVCenter, modal, IntoPortal } from 'mui';
import { maxLtvRulesToRuleTable, ruleTableToMaxLtvRules } from './max-ltv.service';
import { ParamTable } from '../components/param-table';
import { EditableRuleTableRow, EditableRuleTable } from '../components/param-table.service';
import { ImportModal } from './import-modal';
import { WhenEligible } from 'raccoon-engine';
import { ConfigHeader } from '../components/config-header';

import * as api from 'api';

export const MaxLtv = () => {

    const {
        fetching,
        data:table,
        updating,
        update,
        reset,
        dirty,
        onChange
    } = useApi2<WhenEligible[], EditableRuleTable>({
        deps: [], // load immediately
        fetch: api.fetchMaxLtvRules,
        update: api.upsertMaxLtvRules,
        postFetch: rules => maxLtvRulesToRuleTable(rules),
        preUpdate: table => ruleTableToMaxLtvRules(table)
    })

    const importModal = () => {
        modal.open({
            render: <ImportModal editTable={table!} onImport={onChange} />,
            title: 'Import Max LTV Rules',
            size: 'lg'
        })
    }

    const createNewRow = ():EditableRuleTableRow => {
        return {
            deleted: false,
            editting: true,
            // occupancy / purpose / units / state / maxltv
            vals: [null, null, null, null, null]
        }
    }

    if (fetching || !table) return <SpinLabel>Loading</SpinLabel>;

    return (
        <>
        

            <IntoPortal id="config-route-top-area">
                <ConfigHeader
                    cancel={reset}
                    saving={updating}
                    save={update}
                    dirty={dirty}
                    title="Max LTV Rules"
                    hideBorder
                    renderBelow={
                        <Box mt="1">
                            You can also <Link onClick={importModal}>import data from an existing spreadsheet</Link>.
                        </Box>
                    }
                />
            </IntoPortal>
            <Box mb="2">
                {/* <Box my="1">
                    You can also <Link onClick={importModal}>import data from an existing spreadsheet</Link>.
                </Box> */}
                <ParamTable table={table} onChange={onChange} createNewRow={createNewRow} />
            </Box>


        </>
    )
}
