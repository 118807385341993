import jwt_decode from 'jwt-decode';

export const wasJwtIssuedBeforeTime = (jwt:string|undefined, epochTimeInMs:number) => {
    // Invalid jwt counts as 0 (and therefore always return true)
    if (!jwt) return true;
    try {
        const obj:any = jwt_decode(jwt);
        const issuedAt = obj['iat'];
        if (typeof issuedAt !== 'number') return true;
        const issuedAtMs = issuedAt * 1000; // we get it in epoch seconds
        return issuedAtMs < epochTimeInMs;
    } catch (err) {
        return true;
    }
}