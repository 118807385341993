import React, { useState } from 'react';
import { Localhost, FlexVCenter, Box, Button, modal, FileSelectButton } from 'mui';
import { GQL } from 'market-dto';
import { sheets, genSheet, marketSettings, pricing, actMon, fannie, freddie } from 'services';
import { RunPricingModal } from './run-pricing-modal';
import { LoanFilters } from 'components';
import { useApiUpdate, useZState, useObsLoad } from 'hooks';
import { CompareModal } from './compare/compare-modal';
import * as api from 'api';

interface Props {
    readonly sheet:GQL.LoanModelSheet;
    readonly pricingInfo:pricing.PricingInfo;
    readonly count:number;
}
export const FiltersAndButtons = ({ sheet, pricingInfo, count }:Props) => {
    
    const { data:checkedOffers } = useZState(sheets.zCheckedOffers);
    const settings = marketSettings.getMarketSettings();
    
    const { busy:uploadBusy, load:runUpload } = useObsLoad();
    const fannieBusy = actMon.useJobBusy(fannie.getFannieJobName(sheet.id));
    const freddieBusy = actMon.useJobBusy(freddie.getFreddieJobName(sheet.id));

    const uploadForDemo = (files:File[]) => {
        if (uploadBusy) return;
        runUpload(pricing.getUploadPricingObs(sheet, files, true));
        modal.close();
    }

    const uploadForReal = (files:File[]) => {
        // runPricingFromSpreadsheet(files, false);
        if (uploadBusy) return;
        runUpload(pricing.getUploadPricingObs(sheet, files, true));
        modal.close();
    }

    const openFreddiePricingModal = () => {
        modal.open({
            title: 'Pricing Parameters',
            noHeaderBg: true,
            size: 'sm',
            hideCloseIcon: true,
            render: <RunPricingModal
                investor="freddie"
                sellerOrgId={sheet.sellerOrgId}
                onRun={async (costOfFunds, sellerMargin) => {
                    await freddie.enqueueFreddiePricing(sheet.id, costOfFunds, sellerMargin);
                }}
                runLabel="Run Freddie Pricing"
            />
        })
    }

    const openFanniePricingModal = () => {
        modal.open({
            title: 'Pricing Parameters',
            noHeaderBg: true,
            size: 'sm',
            hideCloseIcon: true,
            render: <RunPricingModal
                investor="fannie"
                sellerOrgId={sheet.sellerOrgId}
                onRun={async (costOfFunds, sellerMargin) => {
                    await fannie.enqueueFanniePricing(sheet.id, costOfFunds, sellerMargin);
                }}
                runLabel="Run Fannie Pricing"
            />
        })
    }

    const openWFModal = () => {
        modal.open({
            title: 'Demo Upload',
            render: <Box bg="card" p="2">
                <Box>
                    For ease of testing and demonstration, use the "demo" button and the pricing information in the incoming pricing
                    spreadsheet will randomly be assigned to the loans in the current tape.
                </Box>
                <Box mt="2">
                    To match the pricing information from the incoming pricing spreadsheet with loans in the current tape by loan number,
                    use the "normal" button below.
                </Box>
                <Box textAlign="center" mt="2">
                    <FileSelectButton onSelect={uploadForDemo}>Upload Pricing (demo)</FileSelectButton>
                    <FileSelectButton ml="1" onSelect={uploadForReal}>Upload Pricing (normal)</FileSelectButton>
                </Box>
            </Box>
        })
    }

    const openCompareModal = () => {
        modal.open({
            title: 'Compare Pricing',
            size: 'lg',
            render: <CompareModal sheet={sheet} />
        })
    }

    const { busy:finalizing, update:finalizeBids } = useApiUpdate(async () => {
        if (!checkedOffers) throw new Error('Expected checkedOffers');
        const offerIds = genSheet.getOfferIds(checkedOffers);
        if (!settings) throw new Error('Expected market settings');
        const result = await api.createProposal(sheet.id, offerIds);
        if (result) {
            // await the refresh so that we know we have status correct.
            await sheets.zCurrent.refresh();
            // finally, spit out the sheet.
            genSheet.genBidSheet(sheet, checkedOffers, settings);
        }
        // TODO: toast on error?
    })


    return (
        <>

            <FlexVCenter justifyContent="space-between" mb="2">
                <LoanFilters count={count} />
                <FlexVCenter>

                    { pricingInfo.canComparePricing && (
                        <Button
                            ml="1"
                            size="sm"
                            onClick={openCompareModal}
                        >Compare</Button>
                    ) }
                    { pricingInfo.canRunFreddiePricing && (
                        <Button
                            //type="secondary"
                            ml="1"
                            size="sm"
                            busy={freddieBusy}
                            onClick={openFreddiePricingModal}
                        >Freddie</Button>
                    ) }
                    { pricingInfo.canRunFanniePricing && (
                        <Button
                            //type="secondary"
                            ml="1"
                            size="sm"
                            busy={fannieBusy}
                            onClick={openFanniePricingModal}
                        >Fannie</Button>
                    ) }
                    { pricingInfo.canRunSpreadsheetPricing && (
                        <Button
                            //type="secondary"
                            ml="1"
                            size="sm"
                            onClick={openWFModal}
                            busy={uploadBusy}
                        >{ uploadBusy ? 'Processing' : 'Upload' }</Button>
                    ) }
                    { pricingInfo.canFinalizeProposal && (
                        <Button
                            type="secondary"
                            ml="1"
                            size="sm"
                            busy={finalizing}
                            onClick={finalizeBids}
                        >Finalize</Button>
                    ) }


                </FlexVCenter>
                
            </FlexVCenter>
        </>
    )
}