import { GQL } from 'market-dto';
import { ChecklistWithModel } from './types';
import { getModelById } from './get-model-by-id';

export const toChecklistsWithModels = (lists?:GQL.Checklist[]):ChecklistWithModel[] => {
    if (!lists) return [];
    return lists.map((c):ChecklistWithModel => {
        return {
            checklist: c,
            model: getModelById(c.modelId)
        }
    })
}