import React, { useState, useEffect } from 'react';
import { CssGridBox, Flex, FlexVCenter, Button, modal, BoundFrmInput, Box, Icon, BoundFrmDropdown, BoundFrmDate, ModalFooter } from 'mui';
import { GQL, labels } from 'market-dto';
import { format } from 'services';

interface Props {
    readonly crEnv:string;
    readonly params:GQL.CreateTradeParams;
    readonly setParams:(x:GQL.CreateTradeParams) => void;
    readonly onNext:() => void;
}
export const ParamsForm = ({ params, setParams, onNext, crEnv }:Props) => {

    const canSubmit = params.name.trim() !== '' &&
        params.tradeDeliveryDate.trim() !== '' &&
        params.settlementDate.trim() !== '' &&
        params.servicer.trim() !== '';
    return (
        <>
            <CssGridBox cols={2} gap="2" sameSize>

                <BoundFrmInput
                    width="100%"
                    bindTo={[params, setParams, x => x.name]}
                    label={labels.byTradeParam('name')}
                />
                <BoundFrmInput
                    readOnly
                    width="100%"
                    bindTo={[params, setParams, x => x.sheetId]}
                    label={labels.byTradeParam('sheetId')}
                />
                <BoundFrmDate
                    width="100%"
                    formatLabel={format.toDate}
                    bindTo={[params, setParams, x => x.tradeDeliveryDate]}
                    label={labels.byTradeParam('tradeDeliveryDate')}
                />
                <BoundFrmDate
                    width="100%"
                    formatLabel={format.toDate}
                    bindTo={[params, setParams, x => x.settlementDate]}
                    label={labels.byTradeParam('settlementDate')}
                />
                <BoundFrmDropdown
                    width="100%"
                    isMulti={false}
                    bindTo={[params, setParams, x => x.commitmentType]}
                    items={GQL.COMMITMENT_TYPES.map(x => ({ value: x, label: labels.byCommitmentType(x)}) )}
                    label={labels.byTradeParam('commitmentType')}
                />
                <BoundFrmDropdown
                    width="100%"
                    isMulti={false}
                    bindTo={[params, setParams, x => x.dayCountConvention]}
                    items={GQL.DAY_COUNT_CONVENTION.map(x => ({ value: x, label: labels.byDayCountConvention(x)}) )}
                    label={labels.byTradeParam('dayCountConvention')}
                />
                <BoundFrmInput
                    width="100%"
                    bindTo={[params, setParams, x => x.servicer]}
                    label={labels.byTradeParam('servicer')}
                />
                <BoundFrmDropdown
                    width="100%"
                    isMulti={false}
                    bindTo={[params, setParams, x => x.servicing]}
                    items={GQL.SERVICING_TYPES.map(x => ({ value: x, label: labels.byServicingType(x)}) )}
                    label={labels.byTradeParam('servicing')}
                />
            </CssGridBox>

            <ModalFooter>
                <FlexVCenter justifyContent="flex-end" m="2">
                    <Button type="link" mr="1" size="sm" onClick={modal.close}>Cancel</Button>
                    <Button
                        type={canSubmit ? 'default' : 'disabled'}
                        size="sm"
                        onClick={onNext}
                    >Next</Button>
                </FlexVCenter>
            </ModalFooter>
        </>
    )
}