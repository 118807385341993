import { GQL } from 'market-dto';

export const getBasePrice = (offer:GQL.Offer) => {
	if (!offer.priceTag) {
		console.log('NO PRICE TAG FOUND');
		return 0;
	}
	const cashWindowTag = offer.priceTag.tags.find(x => x.type === 'CASH_WINDOW')
	const interestSpreadTag = offer.priceTag.tags.find(x => x.type === 'INTEREST_SPREAD');
	const result = (cashWindowTag?.price ?? 0) + (interestSpreadTag?.price ?? 0);
	return result;
}