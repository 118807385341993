import { Batch } from '../types';
import { zBatch } from '../z-batch';
import { Observable } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';

export const withBatch = () => <T>(obs$:Observable<T>):Observable<Batch> => {
    return obs$.pipe(
        withLatestFrom(zBatch.state$),
        map(([ x, batchState ]) => {
            const batch = batchState.data;
            if (!batch) throw new Error('Missing batch');
            return batch;
        })
    )
}