import { mutate, gql } from "../../client";
// NOTE: it actually can return GQl.Session, but, not sure i need it.

// Why return id and email? just true or false, right? success or failure?

interface SessionId {
    readonly id:string;
    // readonly email:string;
    // readonly user:GQL.User;
}
const INSERT = gql`
    mutation insertSession($objects: [session_insert_input!]) {
        insert_session(objects: $objects) {
            errorMessage,
            returning {
                id
            }
        }
    }
`;

interface MutateOutput {
    readonly insert_session:{
        readonly errorMessage:string;
        readonly returning:[SessionId];
    }
}

interface MutateInput {
    readonly objects:[{
        readonly email:string;
        readonly token?:string;
    }]
}

export const insertSession = async (email:string, token?:string):Promise<string|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(INSERT, {
        objects: [{
            email,
            token
        }]
    })
    // We are returning SESSION id, not user id!
    if (result?.data?.insert_session.errorMessage) return;
    return result?.data?.insert_session.returning[0].id;
}
