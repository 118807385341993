import React, { useState } from 'react';
import { Input, Box, FlexVCenter, Button, modal } from 'mui';
import { useZStateList } from 'hooks';
import { alpha } from 'services';

export const FlowNameChangeModal = () => {

    const { editItem, updateItem, commitItem, updating } = useZStateList(alpha.zFlows);
    if (!editItem) throw new Error('Expected flow');

    const [ name, setName ] = useState(editItem.name);
    const trimmed = name.trim();

    const submit = async () => {
        if (!trimmed || updating) return;
        updateItem({ name:trimmed });
        await commitItem();
        modal.close();
    }

    return (
        <FlexVCenter mt="2">
            <Input
                autoFocus
                placeholder="Workflow Name"
                value={name}
                setValue={setName}
                onEnter={submit}
            />
            <Button
                ml="1"
                busy={updating}
                type={updating || (trimmed && trimmed !== editItem.name) ? 'default' : 'disabled'}
                onClick={submit}
            >Update</Button>
        </FlexVCenter>
    )
}