import React from 'react';
import { Box, FlexVCenter, SpacingSize } from 'mui';

interface Props {
    readonly small?:boolean;
    readonly label:string;
    readonly desc?:string;
    readonly mt?:SpacingSize;
}
export const SectionHeader = ({ small, label, desc, mt }:Props) => {

    if (small) {
        return (
            <FlexVCenter mt={mt} pb="half" mb="half" borderSides="bottom" borderColor="card-bg" borderWidth="thin">
                <Box fg="default" fontSize="md">{ label }</Box>
                <Box fg="faded" ml="1" fontSize="sm">{ desc }</Box>
            </FlexVCenter>
        )
    } else {
        return (
            <FlexVCenter mt={mt} mb="2" borderSides="bottom" borderColor="card-bg" pb="1">
                <Box fg="loud" fontSize="lg">{ label }</Box>
                <Box fg="faded" ml="1" fontSize="sm">{ desc }</Box>
            </FlexVCenter>
        )
    }

}