import React, { useRef } from 'react';
import { useMatch, NavLink as RouterNavLink, useResolvedPath } from 'react-router-dom';
import { Flex } from '../flex/flex';
import { TabItemProps } from './types';

export const FancyTabItem = ({ item }:TabItemProps) => {

    const { label, to, onSelect, exact, absoluteMatchPath } = item;

    const linkRef = useRef<any>(null);

    // "to" is likely relative
    // Could be "dog" or "../dog" or it could be absolute "/animals/dog"
    // useResolvedPath saves us the trouble of caring.
    const resolvedTo = useResolvedPath(exact ? to : to + '/*');
    const matchAgainst = absoluteMatchPath ? absoluteMatchPath : resolvedTo.pathname;
    const active = useMatch(matchAgainst) ? true : false;

    if (onSelect) {
        return (
            <Flex
                onClick={() => onSelect && onSelect()}
                fg="faded"
                fgHover="link"
                px="2"
                py="1"

                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"

                cursor="pointer"
                borderColor="menu"
                borderSides="bottom"
                borderWidth="thin"
                borderColorHover="menu-hover"
                transition="default"
            >{ label }</Flex>         
        )
    }

    if (active) {
        return (
            <Flex
                fg="loud"
                px="2"
                py="1"

                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg="menu-item-active"
                textAlign="center"
                
                borderColor="menu-active"
                borderSides="bottom"
                borderWidth="thin"
                transition="default"
            >{ label }</Flex>
        );
    }
    return (        
        <Flex
            fg="faded"
            fgHover="link"
            px="2"
            py="1"

            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"

            cursor="pointer"
            borderColor="menu"
            borderSides="bottom"
            borderWidth="thin"
            borderColorHover="menu-hover"
            transition="default"
            onClick={() => {
                if (linkRef && linkRef.current) {
                    linkRef.current.click();
                }
            }}
        >
            <RouterNavLink ref={linkRef} to={to}>{ label }</RouterNavLink>
        </Flex>

    )
}
