import { BehaviorSubject } from 'rxjs';
import { ActivityLogItem, ActivityMonitorJob, ActivityLogCategory, ActivityLogType } from './types';

const _logs = new BehaviorSubject<ActivityLogItem[]>([]);

export const logs = _logs.asObservable();

export const actLog = (category:ActivityLogCategory, type:ActivityLogType, identifier:string, msg:string) => {
    const logs = _logs.getValue();
    _logs.next([ {
        category,
        identifier,
        msg,
        timestamp: Date.now(),
        type
    }, ...logs])
}