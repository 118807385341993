import React from 'react';
import { HighlightText } from 'mui';
import { sheets, format } from 'services';
import { GQL, LoanField } from 'market-dto';

interface Props {
    readonly loan:Partial<GQL.Loan>;
    readonly fieldId:LoanField;
    readonly filters?:sheets.LoanFilters;
}
export const DisplayLoanValue = ({ loan, fieldId, filters }:Props) => {
    const val = format.loanValue(fieldId, loan[fieldId]);
    if (filters && filters.fields.includes(fieldId)) return <HighlightText txt={val} hiTxt={filters.text} />;
    return <>{ val }</>;
}