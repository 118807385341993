import React, { useState, useEffect } from 'react';
import { Box, Input, Button, ModalFooter, modal } from 'mui';
import { tapeImporter } from 'services';

interface Props {
    readonly nextStep:() => void;
	readonly batch:tapeImporter.Batch;
}
export const PasswordModal = ({ nextStep, batch }:Props) => {

    const go = () => {
        tapeImporter.applyPasswords(batch.items[0], 'pig');
        nextStep();
        modal.close();
    }

    return (
        <>
            <Button onClick={go}>Go</Button>
        </>
    )
}