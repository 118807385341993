import React from 'react';
import { EditableRuleTableCol } from './param-table.service';
import { EnumPicker } from './enum-picker';
import { Input } from 'mui';

interface Props {
    readonly col:EditableRuleTableCol;
    readonly val:string | number | undefined | null;
    readonly editting:boolean;
    readonly onChange:(x:string | number) => any;
}
export const ParamTableCol = ({ col, val, editting, onChange }:Props) => {

    const css = col.type === 'int' || col.type === 'float' ? 'right' : undefined;

    if (!editting) {
        return <td className={css}>{ val }</td>
    }

    if (col.type === 'enum') {
        return (
            <td className={css}>
                <EnumPicker
                    fieldId={col.field}
                    onChange={onChange}
                    types={col.enums!!}
                    value={val as string}
                />
            </td>
        );
    } else if (col.type === 'int') {
        return (
            <td className={css}>
                <Input
                    value={val === null ? undefined : val}
                    width="sm"
                    size="sm"
                    align="right"
                    numeric={{ step: 1, int: true }}
                    setValue={onChange}
                />
            </td>
        );
    } else if (col.type === 'float') {
        return (
            <td className={css}>
                <Input
                    value={val === null ? undefined : val}
                    width="sm"
                    size="sm"
                    align="right"
                    numeric={{ float: true }}
                    setValue={onChange}
                />
            </td>
        );
    } else if (col.type === 'const') {
        return <td className={css}>{ val }</td>
    }
    return <td className={css}>Not Yet</td>
    
}
