import React from 'react';
import { Box, Text, Button, FlexVCenter, Icon } from 'mui';
import { GQL, OverrideItem } from 'market-dto';

interface Props {
    readonly onRevertToDefaultMapping:() => void;
    readonly onNeverMapThis:() => void;
    // readonly unmappedOnPurpose:boolean;
    readonly mappedTo:string;
    readonly ride:OverrideItem;
}
export const TabAdvanced = ({
    ride,
    onNeverMapThis,
    onRevertToDefaultMapping,
    // unmappedOnPurpose,
    mappedTo
}:Props) => {
    // mappedTo lets us know what we ended up finding.

    const neverMap = ride.fromColumn === '__DO_NOT_MAP';
    const usedDefault = !neverMap && ride.fromColumn ? false : true;

    const userSpecified = <Text fg="accent">user specificed configuration</Text>;
    const defaultMsg = <Text fg="accent">default mapping configuration</Text>;

    return (
        <Box mt="2">
            <Box mb="2">
                { neverMap && (
                    <>
                        <Box mb="2">
                            This field has been set to <Text fg="accent" textStyle="italic">Never Map</Text>.
                        </Box>

                        <Box mb="2">
                            Fields which appear as unmapped do not necessarily mean they will never map.
                            For instance, a mapping configured against a tape lacking a particular column will show the field as unmapped,
                            but, future tapes may contain columns which <Text textStyle="italic">do</Text> match the default mapping config for the given field,
                            and thus a value would be obtained.
                        </Box>

                        <Box textAlign="center">
                            <Button
                                type="default"
                                size="sm"
                                onClick={onRevertToDefaultMapping}
                            >Revert to default mapping</Button>
                        </Box>
                    </>
                ) }
                { !neverMap && !mappedTo && usedDefault && (
                    <>
                        <Box mb="2">
                            This field did <Text fg="loud">not</Text> find a match using its { defaultMsg }.
                        </Box>
                        <Box mb="2">
                            Future tapes may contain columns which match against the default mapping configuration, and thus would produce mapped values.
                        </Box>

                        <Box textAlign="center">
                            <Button
                                type="default"
                                size="sm"
                                onClick={onNeverMapThis}
                            >Never map this field</Button>
                        </Box>
                    </>
                ) }
                { !neverMap && !mappedTo && !usedDefault && (
                    <>
                        <Box mb="2">
                            This field did <Text fg="loud">not</Text> find a match given a { userSpecified }.
                        </Box>
                        <Box mb="2">
                            Future tapes may contain columns which match against the user specified configuration, and thus would produce mapped values.
                        </Box>

                        <FlexVCenter justifyContent="center">
                            <Button
                                type="default"
                                size="sm"
                                mr="1"
                                onClick={onRevertToDefaultMapping}
                            >Revert to default mapping</Button>
                            
                            <Button
                                type="danger"
                                size="sm"
                                ml="1"
                                onClick={onNeverMapThis}
                            >Never map this field</Button>
                        </FlexVCenter>
                    </>
                ) }

                { !neverMap && mappedTo && (
                    <>
                        <Box mb="2">
                            This field currently maps to <Text fg="loud">{ mappedTo }</Text>
                        </Box>

                        { !usedDefault && (
                            <Box mb="2">
                                This was mapped via a { userSpecified }.
                            </Box>                            
                        ) }
                        { usedDefault && (
                            <Box mb="2">
                                This was mapped by the field's { defaultMsg }.
                            </Box>
                        ) }

                        <Box textAlign="center">
                            <Button
                                type="danger"
                                size="sm"
                                onClick={onNeverMapThis}
                            >Never map this field</Button>
                        </Box>
                    </>
                ) }
            </Box>

        </Box>
    )
}