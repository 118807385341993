import { mutate, gql, GQL } from "../../client"

// For now, we will use Partial<GQLTape> even though this contains more than tape_set_input can accept

export const UPDATE = gql`
    mutation updateTape($set: tape_set_input, $where: tape_bool_exp!) {
        update_tape(set: $set, where: $where) {
            errorMessage
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.Tape>;
    readonly where:GQL.WhereString;
}

export const updateTape = async (tapeId:string, data:Partial<GQL.Tape>) => {
    // We don't care about the output...for now
	await mutate<MutateInput, any>(UPDATE, {
        set: {
            ...data
        },
        where: {
            id: {
                _eq: tapeId
            }
        }
    })
}
