import { TransCfg, EnumHintDict, loanModel } from 'market-dto';
import { MapPair } from './types';

export const updateTransCfgWithEnumPairs = (transCfg:TransCfg, pairs:MapPair[]):TransCfg => {

    if (transCfg.to !== "enumFromHintDict") {
        throw new Error('we expected enumFromHintDict as the translation type');
    }

    const dict = transCfg.enumHintDict;

    if (!dict || typeof dict !=='object' || Array.isArray(dict)) throw new Error('expected dict object (not array)');

    const hintDict:EnumHintDict = {};
    pairs.forEach(pair => {
        const enumVal = pair.enumVal ?? transCfg.defaultValue;
        // TODO: there is a hole in the logic. if x maps to y via globalCfg, user clears y, so, x points to nothing,
        // we don't have a way to remove that. we'll need an "exclude" option on translate which just takes exact array.
        // --> One simple solution: Only allow mapping overrides if default values exist!
        if (!enumVal) return; // TODO: read above!
        const hint = dict[enumVal];
        if (!hint) throw new Error('missing hint in global config!' + JSON.stringify(pair));
        const currentlyMapsTo = loanModel.translateAdHoc(pair.rawVal, {
            ...transCfg,
            override: undefined // this is saying: ignore overrides, use global translation config as-if unchanged
        })
        if (!currentlyMapsTo.translated || currentlyMapsTo.val !== enumVal) {
            if (!hintDict[enumVal]) hintDict[enumVal] = { exact: [] }
            hintDict[enumVal]!.exact!.push(pair.rawVal.toLowerCase());
        }
    })

    if (Object.keys(hintDict).length === 0) {
        return {
            ...transCfg,
            override: undefined // this means, remove the override--we get the same answer as g cfg!
        }
    }

    return {
        ...transCfg,
        override: {
            to: "enumFromHintDict",
            enumHintDict: hintDict
        }
    }
}