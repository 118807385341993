import React from 'react';
import { IconProps } from '../icon/icon';
import { Button } from '../button/button';
import {
    ButtonSize,
    ButtonType
} from '../../types';

export interface IconButtonProps extends IconProps {
    readonly children?:any;
    readonly size?:ButtonSize;
    readonly type?:ButtonType;
}
export const IconButton = ({ onClick, size, type = "link", ...rest}:IconButtonProps) => {
    return (
        <Button
            type={type}
            size={size}
            icon={rest.name}
            onClick={onClick}
        ></Button>
    )
}