/*

    This service is for interfaces and functions translating loans into view models (and back) for
    display in tables AND output spreadsheets.

    The giant config at the bottom controls the order of fields on the UI.

*/

import {
    GQL,
    SrcMap,
    LoanField,
    loanModel,
    util
} from 'market-dto';


// export interface DirectAndDerived {
//     readonly id:string;
//     readonly empty?:boolean;
//     readonly direct?:ValueAndSources;
//     readonly derived?:ValueAndSources;
// }
export type SrcMapByFieldId = { [id in LoanField]?: SrcMap }


export interface LoanAndValuesAndSources {
    readonly loan:GQL.Loan;
    readonly srcMaps:SrcMap[];
    readonly mapInfoByField:SrcMapByFieldId;
}

// const updateDirectAndDerived = (
//     dict:ValsAndSourcesByFieldId,
//     fieldId:LoanField,
//     partial:Partial<DirectAndDerived>
// ):DirectAndDerived => {
//     const curr = dict[fieldId];
//     if (curr) return {
//         ...curr,
//         ...partial
//     }
//     return {
//         id:fieldId,
//         ...partial
//     }
// }


// We often have the same field (from mtrade perspective) TWICE in valsAndSrcs
// (those which map direct AND derive fallback)
// By converting to a dict by fieldId, turning that into an array, we get one item per field in array.
// export const valsAndSrcsToFieldDict = (loanItem:ValueAndSources[]):ValsAndSourcesByFieldId => {

//     const dict:ValsAndSourcesByFieldId = {};
//     loanItem.forEach(valAndSrc => {
//         const fieldId = valAndSrc.id as LoanField;
//         if (valAndSrc.source.length > 0) {
//             if (valAndSrc.source[0].hasOwnProperty('colIndex')) {
//                 // DIRECT
//                 dict[fieldId] = updateDirectAndDerived(dict, fieldId, {
//                     empty: false,
//                     direct: valAndSrc
//                 })
//             } else if (valAndSrc.source[0].propName) {
//                 // DERIVED
//                 dict[fieldId] = updateDirectAndDerived(dict, fieldId, {
//                     empty: false,
//                     derived: valAndSrc
//                 })
//             }
//         } else {
//             // NOT SURE THIS IS POSSIBLE
//             throw new Error('Can this happen?');
//         }
//     })
//     return dict;   
// }

export const toLoansAndValuesAndSources = (loans:GQL.Loan[]):LoanAndValuesAndSources[] => {
    return loans.map(loan => {
        const srcMaps = loan.loanSource?.sourceMap ?? [];
        return {
            loan,
            srcMaps,
            mapInfoByField: util.toDict(srcMaps, x => x.id)
        }
    })
}

export interface LoanTableFieldConfig {
    readonly id:LoanField;
    readonly hide?:boolean;
    readonly enums?:readonly string[];
    readonly noMap?:boolean;
    readonly important?:boolean; // No longer used!
}

const LOAN_TABLE_CFG:{ [id in LoanField]?:Partial<LoanTableFieldConfig> } = {
    id: { important:true },
    loanNumber: { important: true },
    productCode: {important: true, noMap:true },
    loanType: { important: true, enums: loanModel.getEnumValues("loanType") },
    aus: { important: true, enums: loanModel.getEnumValues("aus") },
    docType: { important: true,  enums: loanModel.getEnumValues("docType") },
    currentBalance: { important: true },
    dti: { important: true },
    ltv: { important: true },
    cltv: { important: true },
    fico: { important: true },
    highBalance: { important: true },
    noteRate: { important: true },
    state: { important: true },
    uli:  { important: true },
    monthlyIncome:  { important: true },
    yearlyIncome:  { important: true },
    // "important" ones show first. in fact, unimportant are hidden by default
    sellerName: { important: false },
    rawProductCode: { important: false },
    propertyType: { important: false, enums: loanModel.getEnumValues("propertyType") },
    specialtyProgram: { important: false, enums: loanModel.getEnumValues("specialtyProgram") },
    occupancy: { important: false, enums: loanModel.getEnumValues("occupancy") },
    rateType: { important: false, enums: loanModel.getEnumValues("rateType") },
    loanPurpose: { important: false, enums: loanModel.getEnumValues("loanPurpose") },
    pmiType: { important: false, enums: loanModel.getEnumValues("pmiType") },
    escrowWaived: { important: false },
    term: { important: false },
    units: { important: false },
    originalBalance: { important: false },
    nextPaymentDueDate: { important: false },
    otm: { important: false },
    fundedDate: { important: false },
    firstPaymentDueDate: { important: false },
    armInitFixedTerm: { important: false },
    balloonTerm: { important: false },
    borrowerName: { important: false },    
    miCoverage: { important: false },
    tpo: { important: false },
    remainingInterestOnlyPeriod: { important: false},

    // Hidden loan fields:
    coupon: { hide: true }, // pointless field. the Big Tape maps note rate AND coupon to the same field every single time
    age: { hide: true }, // why do we have this? year built makes much more sense.
    rowNumber: { hide: true },
    scopes: { hide: true },
    sellerOrgId: { hide: true },
    sheetId: { hide: true },
    tags: { hide: true },
    eligibility: { hide:true }, // HIDE because we handle this with special ui
    ineligibleFor: { hide: true } // HIDDEN (we handle this in a special way)
}


// THIS IS WHERE I STOPPED
// modelSourceMap needs to be a service.
// or maybe hmmm...


const ALL_FIELDS = Object.keys(LOAN_TABLE_CFG)
    .map((k:string):LoanTableFieldConfig => {
        return { id: k, ...(LOAN_TABLE_CFG as any)[k] };
    });
export const fields = ALL_FIELDS.filter(x => !x.hide);
export const getFields = () => fields; // because depending on import order, cannot just import constant sometimes

// interface LoanTableViewModelRow {
//     readonly cols:ValueAndSources[];
//     readonly isEligible:boolean;
//     readonly ineligibleFor:string[];
//     readonly expanded:boolean;
// }
// interface LoanTableViewModel {
//     readonly headers:LoanTableViewModelHeader[];
//     readonly rows:LoanTableViewModelRow[];
// }
// interface LoanTableViewModelHeader {
//     readonly top:string;
//     readonly bottom:string;
// }


// const addUsed = (used:ColsUsedPerField, fieldId:LoanField, colName:string):void => {
//     if (used[fieldId]) {
//         if (used[fieldId]!.includes(colName)) return;
//         used[fieldId]!.push(colName);
//     } else {
//         used[fieldId] = [colName];
//     }
// }

// const fromUsed = (fieldId:LoanField, used:ColsUsedPerField):string[] => {
//     // We return an array because we spread it. really, we just want string or no string,
//     // but, we're populating a header array over here!
//     if (!used[fieldId]) return [];
//     return ['from: ' + used[fieldId]!.join(', ')];
// }



// type ColsUsedPerField = { [k in LoanField]?:string[] }
// export const toXlsx = (sheet:GQL.Sheet) => {

//     const raw = toLoansAndValuesAndSources(sheet, sheet.loans);
//     const used:ColsUsedPerField = {}

//     // find out which source columns were used for all fields (direct and derived)
//     raw.forEach(loanAndVs => {
//         fields.forEach(field => {
//             const mapInfo = loanAndVs.mapInfoByField[field.id];
//             if (mapInfo && mapInfo.direct) {
//                 const srcVal = mapInfo.direct.source[0].val;
//                 const srcCol = mapInfo.direct.source[0].colName;
//                 addUsed(used, field.id, srcCol ?? 'unknown');
//             }
//         })
//     })

//     raw.forEach(loanAndVs => {
//         fields.forEach(field => {            
//             const mapInfo = loanAndVs.mapInfoByField[field.id];
//             // Only count derived IF we ended up with a non-null, non-undefined value.
//             const val = loanAndVs.loan[field.id];
//             if (val === null || val === undefined) return;
//             if (mapInfo && mapInfo.derived) {
//                 const propName = mapInfo.derived.source[mapInfo.derived.source.length-1].propName;
//                 if (used[propName as LoanField] && used[propName as LoanField]!.length === 1) {
//                     // this derived field comes from this direct field
//                     addUsed(used, field.id, used[propName as LoanField]![0]);
//                 }
//             }
//         })
//     })
//     // now we know which columns were used, which means, we can generate HEADERS.

//     const headers = fields.map(f => {
//         return [
//             labels.byLoanField(f.id),
//             ...fromUsed(f.id, used)
//         ]
//     }).flat();


//     const rows = raw.map(loanAndVs => {
//         const cols:any[] = [];
//         // Not map/filter because could be null, undefined...so push when we know.
//         fields.forEach(field => {   

//             // 2019-12-01T00:00:00.000Z
//             // if (field.id === 'firstPaymentDueDate') console.log(loanAndVs.loan[field.id]);

//             // DO NOT FORMAT FOR THE OUTPUT SHEET--give them underlying codes!
//             cols.push(loanAndVs.loan[field.id]);
//             // cols.push(format.loanValue(field.id, loanAndVs.loan[field.id]));

//             if (!used[field.id]) return;
//             const mapInfo = loanAndVs.mapInfoByField[field.id];
//             if (!mapInfo) throw new Error('how can you NOT have mapInfo but you DO have used info?');            
//             if (mapInfo.derived) {
//                 cols.push(mapInfo.derived.source[mapInfo.derived.source.length-1].val);
//             } else if (mapInfo.direct) {
//                 cols.push(mapInfo.direct.source[0].val);
//             }
//         })
//         if (cols.length !== headers.length) throw new Error('how cols length differ from headers?');
//         return cols;
//     })

//     const { headers:mergedHeaders, rows:mergedRows } = insertOffersIntoHeadersAndRows(sheet, headers, rows, 1);
//     genSheet.makeSheet(mergedHeaders, mergedRows, 'Loans', sheet.id + '.xlsx');
//     return true;
// }


// interface OfferColsByInvestorId {
//     readonly investor:string;
//     readonly headers:string[];
// }

// const insertOffersIntoHeadersAndRows = (sheet:GQL.Sheet, headers:string[], rows:any[][], n:number) => {
//     // OK, we're about to make an output spreadsheet...
//     // ...but...we want to include ALL the offer data that we have!
//     // Where do we put it? right after n!

//     // But first, let's make our own headers/rows!

//     // find the FIRST offer per investor id
//     const offerColsByInvestor:{ [k:string]:string[] } = {};
//     sheet.loans.forEach(loan => {
//         loan.offers?.forEach(offer => {
//             if (offer.investor && !offerColsByInvestor[offer.investor]) {
//                 const offerCols:string[] = [];
//                 offer.priceTag?.tags.forEach(tag => {                    
//                     // Only special logic: if begins with TBA
//                     offerCols.push(tag.name.trim().toUpperCase().startsWith('TBA') ? 'TBA' : tag.name);
//                 })
//                 offerColsByInvestor[offer.investor] = offerCols;
//             }
//         })
//     })

//     const baseOfferCols = [
//         'Investor',
//         'Offer',
//         'Commitment Days',
//         'Acquisition Date',
//         'Security Date'
//     ]
//     const invOfferCols:OfferColsByInvestorId[] = Object.keys(offerColsByInvestor)
//         .map((investor:string):OfferColsByInvestorId => {
//             return {
//                 investor,
//                 headers: offerColsByInvestor[investor]
//             }
//         })

//     let offerHeaders:string[] = [];
//     // MUTABLE. Gross.
//     for (let i=0; i < invOfferCols.length; i++) {
//         if (i > 0) {
//             offerHeaders = offerHeaders.concat(baseOfferCols.map(x => x + ' ' + (i+1)));
//         } else {
//             offerHeaders = offerHeaders.concat(baseOfferCols);
//         }
//         offerHeaders = offerHeaders.concat(invOfferCols[i].headers);
//     }
    
//     const offerRows:any[][] = sheet.loans.map((loan, loanIndex) => {
//         const arr:any[] = [];
//         for (let i=0; i < invOfferCols.length; i++) {
//             const investor = invOfferCols[i].investor;
//             const offer = loan.offers?.find(x => x.investor === investor);
//             if (!offer) {
//                 for (let j=0; j < baseOfferCols.length; j++) arr.push(null);
//                 invOfferCols[i].headers.forEach(x => arr.push(null));
//             } else {
//                 if (offer.priceTag?.tags.length !== invOfferCols[i].headers.length) {
//                     throw new Error('Offers with matching investorIds should have matching priceTag.tags lengths');
//                 }
//                 arr.push(offer.investor);
//                 arr.push(offer.offeringPrice);
//                 arr.push(offer.commitmentDays);
//                 arr.push(offer.acquisitionDate);
//                 arr.push(offer.securityDate);
//                 offer.priceTag.tags.forEach(x => arr.push(x.price));                
//             }
//         }
//         if (arr.length !== offerHeaders.length) {
//             throw new Error('Mismatched offer data columns / offer header columns!');
//         }
//         return arr;
//     })


//     return {
//         headers: [...headers.slice(0, n), ...offerHeaders, ...headers.slice(n)],
//         rows: rows.map((cols, rowIndex) => {
//             const offerCols = offerRows[rowIndex];
//             return [...cols.slice(0, n), ...offerCols, ...cols.slice(n)]
//         })
//     }

// }

/*
    const headers = ['No', 'Seller Name', 'Loan Number', 'Best Offer', 'Delivery Date', 'Settlement Date'];
    const rows = sheet.loans.map((loan, n) => { 
        const bestOfferIndex = offerByLoanId[loan.id] ?? 0;
        const best = loan.offers && loan.offers.length > 0 ? loan.offers[bestOfferIndex] : undefined;
        return [
            n + 1,
            loan.sellerName ?? sheet.seller.name,
            loan.loanNumber,
            best?.offeringPrice ?? null,
            best?.acquisitionDate ? format.toDate(best?.acquisitionDate) : null,
            best?.securityDate  ? format.toDate(best?.securityDate) : null
        ]                        
    })
*/