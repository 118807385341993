import React from 'react';
import { Link, modal, Text, Icon, SpinLabel, FlexVCenter } from 'mui';
import { useZStateList, useZState } from 'hooks';
import { alpha } from 'services';
import { FlowNameChangeModal } from './flow-name-change-modal';
import { GQL } from 'market-dto';

interface Props {
    readonly flow:GQL.Flow;
    readonly readOnly?:boolean;
}
export const FlowName = ({ flow, readOnly }:Props) => {

    // const { editItem:flow } = useZStateList(alpha.zFlows);
    const { data:run, fetching:loadingRun } = useZState(alpha.zCurrentRun);

    if (!flow) throw new Error('Expected flow');

	const openNameChangeModal = () => {
        if (readOnly) return;
		modal.open({
			title: 'Change Workflow Name',
            size: 'sm',
			render: <FlowNameChangeModal />
		})
	}

    // http://localhost:3000/alpha/flow/ckrw9v2mf00e61kmnhjnp9dvo/stages/export/ckrw7ahz500011kmnao9647ri
    // http://localhost:3000/alpha/flow/ckrw9v2mf00e61kmnhjnp9dvo/runs/ckrxu5nh700h71klfhxakcmvt

    return (
        <>
            { readOnly
                ? <Text>{ flow.name }</Text>
                : <Link onClick={openNameChangeModal}>{ flow.name }</Link>
            }
            { loadingRun && (
                <>
                    <Icon name="arrow-right" ml="2" mr="2" />
                    <SpinLabel fontSize="sm">Loading run</SpinLabel>
                </>
            ) }
            { run && !loadingRun && (
                <>
                    <Icon name="arrow-right" ml="2" mr="1" />
                    <Text fg="faded" fontSize="sm">{ alpha.getFlowRunName(run) }</Text>
                </>
            ) }
        </>

    )
}