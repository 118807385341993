import { BloombergPad, Box, Button, CssGrid, Flex } from 'mui';
import {
	CrossMatchingSetNote, deepScanAsLoan, Entry, HintTable, MatchingSetNote, InternalPricing
} from 'raccoon-engine';
import React, { useState } from 'react';
import { CrossLookupDisplay } from "./cross-lookup-display"
import { CrossLookupProvider, findAdjustments, findRateSheet } from "./utils"


interface MatchingRowArgs {
	note:MatchingSetNote
}

const MatchingRow = (props:MatchingRowArgs) => {
	const { note } = props
	return <Box mt="2"> <CssGrid cols={8}>
		{
			note.notes.map((n, idx) => <Box key={idx}>
				<Box fontSize="sm" fontWeight="thin">{n?.hint?.interested}</Box>
				<Box fontSize="md" fontWeight="bold">{n.matchingTo}</Box>
				<Box fontSize="sm">{n.result}</Box>
			</Box>)
		}
		{
			note.prices?.map((p, idx) => <Box key={idx} pl="1" pr="1" bg="inner-card">
				<Box fontSize="sm" fontWeight="thin">{p.interested}</Box>
				<Box fontSize="md" fontWeight="bold">{p.value}</Box>
			</Box>)
		}
	</CssGrid></Box>
	
	
}


interface GViewerArgs {
	entries:Entry[]
	modules:InternalPricing[]
	onPricing:Function
	onAdjustment:CrossLookupProvider
}

const GViewer = (props:GViewerArgs) => {
	const { entries, onPricing, onAdjustment, modules } = props
	const [pricingNotes, setPricingNotes] = useState<MatchingSetNote[]>()
	const [pricingMessage, setPricingMessage] = useState<string>("")
	const [crossNotes, setCrossNotes] = useState<CrossMatchingSetNote[]>([])
	const onPricingHandler = () => {
		const notes = onPricing()
		console.log("notes", notes)
		if (notes.length === 0) {
			setPricingMessage("Cannot find any matching price records.")
		}
		setPricingNotes(notes)
	}
	const onAdjustmentHandler = () => {
		const lookupNotes = onAdjustment()
		setCrossNotes(lookupNotes)
	}
	const findModule = (id: string) => {
		return modules.find(m => m.id === id)
	}
	return <Box bg="card" p="2">
		<CssGrid cols={8}>
			{
				entries.map((entry, idx) => <BloombergPad size="sm" category={entry[0]} key={idx}
				                                          title={(entry[1] as any).toString()}/>)
			}
		</CssGrid>
		<Flex flexDirection="row" mt="2">
			<Box>
				<Button size="sm" type="default" mr="2" onClick={onPricingHandler}>
					Price
				</Button>
			</Box>
			<Box>
				<Button size="sm" type="default" mr="2" onClick={onAdjustmentHandler}>
					Adjustment
				</Button>
			</Box>
		
		</Flex>
		{(pricingNotes && pricingNotes.length > 0)
		&& pricingNotes!.map((note, idx) =>
			                     <div key={idx}>
				                     <MatchingRow
					                     note={note}/>
			                     </div>
		)
		}
		{
			pricingMessage && <Box bg="inner-card" mt="2" p="3">{pricingMessage}</Box>
		}
		{
			crossNotes && crossNotes.map((notes, idx) => {
				return <Box key={idx}>
					<CrossLookupDisplay table={findModule(notes.id!)!} lookupNotes={notes}/>
				</Box>
			})
		}
	</Box>
}

interface Args {
	subjects:any[]
	modules:InternalPricing[]
}

export const TryRun = (props:Args) => {
	const { subjects, modules } = props
	const pricing = (subject:any) => () => {
		const ratesheet = findRateSheet(modules)
		if (!ratesheet) { return }
		const { hints } = ratesheet
		const lookup = new HintTable({ data: hints })
		return lookup.rowsOnlyLookup(subject)
	}
	const adjustment = (subject:any) => () => {
		const adjustments = findAdjustments(modules)
		const findings:CrossMatchingSetNote[] = adjustments.map(tbl => {
			const lookup = new HintTable({ data: tbl.hints })
			return {...lookup.crossLookup(subject), id: tbl.id}
		})
		return findings
	}
	return <div>
		<Box>
			<Button>Test Run</Button>
		</Box>
		{subjects.map(
			(subject, idx) =>
				<Box key={idx} mt="2">
					<GViewer
						modules={modules}
						onAdjustment={adjustment(subject)} onPricing={pricing(subject)}
						entries={deepScanAsLoan(subject)}/>
				</Box>
		)}
	</div>
}
