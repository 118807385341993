import React from 'react';
import { BoxProps, Box } from '../box/box';

// Just some short-cut components

export const Flex = React.forwardRef(({ children, ...props}:BoxProps, ref:any) => {
    return (
        <Box ref={ref} display="flex" {...props}>{ children }</Box>
    )
})

export const FlexVCenter = React.forwardRef((props:BoxProps, ref:any) => (
    <Box ref={ref} display="flex" alignItems="center" {...props} />
))
