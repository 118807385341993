import { HoldingInfo } from './types';
import { CashWindow } from 'market-dto';
import { getDateRangesFrom2dHoldingArray } from './get-date-ranges-from-2d-holding-array';

export const removeEmptyHoldingRowsAndCols = (info:HoldingInfo):HoldingInfo => {

    if (info.holdingInterest.length < 1) return info;
    
    const emptyColIndexes:{ [x:string]:boolean } = {};
    info.holdingInterest[0].forEach((x, colIndex) => {
        if (!info.holdingInterest.some(innerArr => innerArr[colIndex])) {
            // empty column
            emptyColIndexes['c' + colIndex] = true;
        }
    })

    const nextHoldingInterest = info.holdingInterest.map(innerArr => {
        return innerArr.some(x => x) ? innerArr.filter((x, n) => !emptyColIndexes['c' + n]) : null;
    }).filter(x => x) as Array<Array<CashWindow.HoldingInterest|null>>;

    const { aquisitionDates, deliveryDates } = getDateRangesFrom2dHoldingArray(nextHoldingInterest);

    return {
        ...info,
        aquisitionDates,
        deliveryDates,
        holdingInterest: nextHoldingInterest,
    }

}