import React from 'react';
import { Text, FgColor } from 'mui';

const getFgColor = (ms:number):FgColor => {
    // if (ms < 1000) return "default";
    if (ms > 2000) return "loud";
    return "default";
}

interface NiceTimeResult {
    readonly val:number;
    readonly unit:string;
}
const niceTime = (ms:number):NiceTimeResult => {
    /*
        date fns has something similar but not exact, or, if exact, too hard to figure out.
        Easy enough to code.
    */
   if (ms < 1000) return { val:ms, unit:"ms" };
   const sec = Math.round(ms / 1000); // 2500 becomes 3 sec. good enough.
   if (sec < 60) return { val:sec, unit:"s" };
   const min = Math.round(sec / 60); // 90 sec becomes 2 minutes. good enough.
   return { val:min, unit:"m" };
}

interface Props {
    readonly ms:number;
}
export const TimeElapsed = ({ ms }:Props) => {
    const { val, unit } = niceTime(ms);
    return (
        <>
            <Text fg={getFgColor(ms)}>{ val }</Text>
            <Text fg="faded">&nbsp;{ unit }</Text>
        </>
    )
}
 