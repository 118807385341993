import { GQL } from 'market-dto';
import { PriceTagAndDatesByLoanNumber } from './types';

export const assignPricingForDemo = (byLoanNumber: PriceTagAndDatesByLoanNumber, loans: GQL.Loan[]): PriceTagAndDatesByLoanNumber => {
	// For demo purposes, take the "pricing by loan number" dictionary and the tape's actual loans...
	// and create a new pricing by loan number dictionary using the tape loan numbers.
	const result: PriceTagAndDatesByLoanNumber = {};
	// Filter away those with base price of 0
	const pricingArr = Object.keys(byLoanNumber).map(x => byLoanNumber[x]).filter(x => x.tag.price > 50)
	loans.forEach((loan, n) => {
		// we don't care if this pricing info is for a completely different loan. use it!
		if (n < pricingArr.length) result[loan.loanNumber] = pricingArr[n];
	})
	return result;
}
