import { FetchResult, DocumentNode } from 'apollo-boost';
import { ApiCallOptions } from './types'
import { getApolloClient } from './get-apollo-client';
import { withArtificialDelay } from './with-artificial-delay';
import { catchCommonErrors } from './catch-common-errors';

export const mutate = <Input, Output>(gql:DocumentNode, input:Input, opts?:ApiCallOptions):Promise<undefined|FetchResult<Output>> => {
	// Weird apollo calls it "FetchResult" for mutates
	const client = getApolloClient(opts?.withCache ? true : false, opts?.useThisJwtInstead);
	const prom = client.mutate<Output, Input>({
		mutation: gql,
		variables: input
    })

    // wrap the promise somehow so we catch errors by default.
    return catchCommonErrors(withArtificialDelay(prom, opts));
}