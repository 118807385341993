import { GQL, sheetMaker } from 'market-dto';
import { format, utils } from 'services';
import { HitInfo } from '../proposal';
import { downloadFromArrayBuffer } from '../downloader';

export const genTradeOutputSheet = async (hitInfo:HitInfo, trade:GQL.Trade, investorName:string) => {
    const { loans } = trade;
    if (!loans) throw new Error('expected loans');
    const hitLoanDict = utils.toDict(hitInfo.loans ?? [], x => x.id);
    const headers = ['Loan Number', 'Bid', 'Clean Room Loan Number', 'Delivery Date', 'Settlement Date', 'Investor'];
 
    const sellerName = hitInfo.sheetSummary.sellerName;
    const tradeName = trade.cleanRoomTrade?.name
    const tradeNumber = trade.cleanRoomTrade?.tradeId;
    const tradeDate = format.toDate(new Date());
 
 
    const rows = loans.map((loan, n) => {
        const hitLoan = hitLoanDict[loan.loanId]; 
        if (!hitLoan) throw new Error('missing hit loan');
        return [
            loan.loanNumber,
            hitLoan.offeringPrice,
            loan.info?.crLoanNumber,
            format.toDate(hitLoan.acquisitionDate),
            format.toDate(hitLoan.notificationDate),
            investorName
        ]                   
    })

    const finalRows = [
        [investorName + ' trade confirmation'],
        [],
        ['Trade Number', tradeNumber],
        ['Trade Name', tradeName],
        ['Date', tradeDate],
        [],
        headers,
        ...rows,
    ]
 
    const arrBuf = await sheetMaker.makeSheet(finalRows, "Trade Confirmation");
    const filename = `${ hitInfo.sheetSummary.sheetId }-${ sellerName }-confirmation.xlsx`;
    downloadFromArrayBuffer(arrBuf, filename);
}
