import { mutate, stripInternals, gql, GQL } from "../../client";
import { RETURNING } from './returning';

interface MutateInput {
    readonly set:Partial<GQL.Org>;
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_org: {
        readonly returning:[GQL.Org];
    }
}

const UPDATE = gql`
    mutation updateOrg($set: org_set_input, $where: org_bool_exp!) {
        update_org(set: $set, where: $where) {
            errorMessage
            returning { ${ RETURNING } }
        }
    }
`;

export const updateOrg = async (org:Partial<GQL.Org>):Promise<GQL.Org | undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: stripInternals(org),
        where: {
            id: {
                _eq: org.id
            }
        }
    })
    return result?.data?.update_org.returning[0];
}