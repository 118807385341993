import { mutate, gql, GQL } from '../../client';
import { fetchCashWindow } from './fetch-cash-window';

interface QueryInput {
    readonly products:string[];
}

const MUTATION = gql`
    mutation refreshCashWindow($products: [String]) {
        sync_pool_cash_window(productIds: $products)
    }
`;

export const refreshCashWindow = async (inevstorProductIds:string[]):Promise<GQL.PriceSheet|undefined> => {
    // Ignore result of mutate...
    await mutate<QueryInput, GQL.PriceSheet>(MUTATION, { products: inevstorProductIds });
    // ...and re-fetch from cache
    return fetchCashWindow(inevstorProductIds);
}
