import React, { useState } from 'react';
import { CardTitle, Card, Box, FrmInput, Button, toast, toastError } from 'mui';
import { useNavigate } from "react-router-dom";
import * as api from 'api';
import { useApiUpdate } from "hooks";
import { Session } from './login';
import { validate } from 'services';

interface Props {
    readonly onValidEmail:(x:Session) => void;
}
export const EnterEmail = ({ onValidEmail }:Props) => {

    const [ email, setEmail ] = useState<string>('');
    const { busy, update:insert } = useApiUpdate(api.insertSession);

    const navigate = useNavigate();
    const close = () => navigate("/");

    const submit = async () => {
        if (!validate.isValidEmail(email)) {
            toastError("Invalid Email Address!");
            return;
        }
        if (!validate.isWhiteListed(email)) {
            toastError("Your email address domain has not been whitelisted. Please contact an administrator for assistance.");
			return;
        }
        const sesId = await insert(email);
        if (sesId) {
            toast("Email Sent!", "Check your inbox for your login token.");
            onValidEmail({
                email: email,
                // user: result.user,
                sessionId: sesId
            })
        } else {
            // For now, this is fine. eventually, pull that from the response the server sends
            toastError("User with given email does not exist or account is inactive.");
            setEmail('');
        }
    }

    return (
        <Card mx="auto" width="card-md">

            <CardTitle onClose={close} icon="user">Sign In - Step 1</CardTitle>

            <FrmInput
                cy="enter-email"
                width="100%"
                label="Enter your email below and we will send you a temporary sign in token."
                placeholder="Email Address"
                autoFocus={true}
                value={email}
                setValue={setEmail}
                onEnter={submit}
            />

            <Box mt="2">
                <Button busy={busy} onClick={submit}>Continue</Button>
            </Box>

        </Card>
    );
}