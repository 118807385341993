import React, { CSSProperties } from 'react';
import { getCss } from '../getCss';
import { Cursor, FgColor, FontSize, FontWeight, TextStyle, SpacingSize } from '../../types';

export interface TextProps {
	readonly cy?:string;
	readonly children?:React.ReactNode;
	readonly fg?: FgColor;
	readonly fgHover?: FgColor;
	readonly fontSize?: FontSize;
	readonly cursor?: Cursor;
	readonly textStyle?: TextStyle;
	readonly fontWeight?: FontWeight;
	readonly ellipsis?: boolean;
	readonly css?: string;
	readonly onClick?: () => void;
	readonly padEnd?:number;
	readonly pl?:SpacingSize;
	readonly pr?:SpacingSize;
	readonly px?:SpacingSize;
	// readonly style?:CSSProperties;
}

export const Text = ({ cy, onClick, ellipsis, padEnd, children, ...props }: TextProps) => {
	const textCss = getCss(props) + (ellipsis ? ' text-ellipsis' : '');
	return (
		<span
			data-cy={cy}
			className={textCss}
			onClick={onClick}
		>{ padEnd ? (children as string).padEnd(padEnd) : children}</span>
	)
}

// <span style={{ display: 'inline-block', whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis', maxWidth:'150px'}}>{ headersAndRows.rows[n][headerIndex] }</span>

// const getIndicesOf = (searchStr:string, str:string):number[] => {
//     const searchStrLen = searchStr.length;
// 	if (searchStrLen == 0) return [];
// 	const lsearchStr = searchStr.toLowerCase();
//     let startIndex = 0, index, indices = [];
//     if (!caseSensitive) {
//         str = str.toLowerCase();
//         searchStr = searchStr.toLowerCase();
//     }
//     while ((index = str.indexOf(searchStr, startIndex)) > -1) {
//         indices.push(index);
//         startIndex = index + searchStrLen;
//     }
//     return indices;
// }



interface HighlightTextProps {
	readonly txt:string;
	readonly hiTxt:string;
}
export const HighlightText = ({ txt, hiTxt }:HighlightTextProps) => {
	// This simply inherits fg/bg EXCEPT for whatever ends up being highlighted, which gets "text-hi" css.
	const hiTxtTrimmed = hiTxt.trim();
	if (!hiTxtTrimmed) return <span>{ txt }</span>;
	const re = new RegExp(hiTxtTrimmed, 'gi');	
	const indexes:number[] = [];
	// It's complicated. But it works, and, it preserves the case of the original text.
	// Note the mutable vars with the lets!
	let match;
	while ((match = re.exec(txt)) != null) indexes.push(match.index);
	if (indexes.length === 0) return <span>{ txt }</span>;
	const len = hiTxtTrimmed.length;
	let nextIndex = 0;
	return <>
		{ indexes.map((index, n) => {
			const startAt = nextIndex;
			nextIndex = index + len;
			return (
				<React.Fragment key={n}>
					{ startAt < index && <span>{ txt.substring(startAt, index) }</span> }
					{ <span className="text-hi">{ txt.substr(index, len)}</span> }
				</React.Fragment>
			)
		})}
		{ nextIndex < txt.length && <span>{ txt.substring(nextIndex) }</span> }
	</> 
}