import React from 'react';
import { Box, FlexVCenter, Icon, Text, Button, Checkbox } from 'mui';
import { proposal } from 'services';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly hitsOnly:boolean;
    readonly customizing:boolean;
    readonly setHitsOnly:(x:boolean) => void;
    readonly setCustomizing:(x:boolean) => void;
    readonly refreshingCashWindow:boolean;
    readonly onRefresh:() => void;
}
export const AboveCardsButtonSection = ({ hitInfo, hitsOnly, setHitsOnly, setCustomizing, customizing, refreshingCashWindow, onRefresh }:Props) => {

    const allowShotHitsOnly = hitInfo.totalCount !== hitInfo.hitCount;
    return (
        <FlexVCenter my="1" justifyContent="space-between">
            <FlexVCenter>
                <Box>
                    <Text fg="loud" fontWeight="bold">{ hitInfo.hitCount }</Text>&nbsp;Hit{ hitInfo.hitCount === 1 ? '' : 's'}
                    <Text fg="faded">,&nbsp;&nbsp;</Text>
                    <Text fg="loud" fontWeight="bold">{ hitInfo.totalCount }</Text>&nbsp;Offer{ hitInfo.totalCount as any === 1 ? '' : 's'}
                </Box>

                { allowShotHitsOnly && (
                    <Box mx="2">
                        <Checkbox label="Show Hits Only" checked={hitsOnly} onToggle={setHitsOnly} />
                    </Box>
                ) }

            </FlexVCenter>
            <FlexVCenter>
                { customizing && <Button size="sm" type="default" onClick={() => setCustomizing(false)}>Done Customizing</Button> }
                { !customizing && <Button icon="settings" size="sm" type="link" onClick={() => setCustomizing(true)}>Customize Fields</Button> }
                { refreshingCashWindow && (
                    <FlexVCenter fg="faded">
                        <Icon name="loader" spinning mr="half" />
                        Refreshing Cash window
                    </FlexVCenter>
                ) }
                { !refreshingCashWindow && (
                    <Button size="sm" icon="refresh" iconMr="1" type="link" onClick={onRefresh}>Refresh Cash Window</Button>
                ) }
            </FlexVCenter>
        </FlexVCenter>
    )
}