import React, { useState, useEffect } from 'react';
import { Box, Alert, Flex, FlexVCenter, Button, IntoPortal } from 'mui';
import { GQL } from 'market-dto';
import { LoanTableContainer } from './loan-table-container';
import { sheets } from 'services';
import { LoanFilters, HorizontalTapeSummary, Loading } from 'components';
import { useZState, useApiFetch } from 'hooks';


interface Props {
    readonly sheet:GQL.LoanModelSheet;
}
export const Loans = ({ sheet }:Props) => {

    const { loading:loadingLoanSources } = useApiFetch(async () => {
        // if we have loans but no loan sources on current sheet, load em.
        const withLoanSources = await sheets.ensureLoanSources(sheet);
        if (withLoanSources === sheet) return; // no change
        sheets.zCurrent.update(x => {
            return {
                ...x,
                sheet:withLoanSources 
            }
        })
        // if (sheet.loans && sheet.loans.length > 0 && !sheet.loans[0].loanSource) {
        //     const srcs = await fetchLoanSources(sheet.id);
        //     sheets.mergeLoansWithLoanSources(srcs);
        // }
    }, [sheet.id])


    const { data:filters } = useZState(sheets.zFilters);
    if (!filters) throw new Error('Expected filters');

    const loans = sheets.applyLoanFilters(sheet.loans.map(x => {
        // add an expanded flag to loan object
        return {
            ...x,
            _expanded: false
        }
    }), filters);

    if (!sheet) return <Alert icon="alert-triangle">No Loans Found!</Alert>

    return (
        <Loading loading={loadingLoanSources} render={
            <>
                <IntoPortal id="tape-details-top-summary">
                    { sheet && ( <HorizontalTapeSummary summary={sheet.sheetSummary!} />) }
                </IntoPortal>
                <LoanFilters count={loans.length} />
                <LoanTableContainer
                    sheet={sheet}
                    loans={loans}
                    filters={filters}
                />
            </>
        } />
    )
}