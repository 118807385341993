import { ExpNode } from './exp-types';
import { genericWalker, GenericWalkerOpts } from './generic-walker';

// This could do our typing as well, because, this happens on ALL things.


export const assignIds = (rootNode:ExpNode):ExpNode => {
    /*
        The ids are only used to know when activeNode changed.
        Meaningless other than that.
    */
    let n = 0;
    genericWalker({
        // Don't bother with accumulators in this instance.
        acc: undefined,
        initChildAcc: undefined,
        lvl: 0,
        parent: null,
        node: rootNode,
        fn: (x:GenericWalkerOpts<void>) => {
            if (x.node === null) return;
            // MUTABLE
            n++;
            x.node.level = x.lvl;
            x.node.id = 'L' + x.lvl + '#' + String(n);
            x.node.getParent = () => x.parent;
        }
    })
    return rootNode;
}