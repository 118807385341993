import React from 'react';
import { tapeImporter } from 'services';
import { Text, Link, FlexVCenter } from 'mui';

interface Props {
    readonly item:tapeImporter.BatchItem;
}
export const MultipleMatches = ({ item }:Props) => {
    return (
        <FlexVCenter fontSize="sm">
            <Text fg="default" fontSize="sm" textStyle="italic">Multiple Mappings Found</Text>
            &nbsp;&nbsp;
            <Link fontSize="sm" onClick={() => tapeImporter.expandItem(item)}>Input Required</Link>
        </FlexVCenter>
    )
}