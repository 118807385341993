import { createZState } from '../z-state';
import * as storage from '../storage';
import * as api from 'api';
import { Session } from  './types';
import { toastError } from 'mui';
import { GQL } from 'market-dto';
import jwt_decode from 'jwt-decode';

const getInitVal = ():Session|null => {
    const user = storage.get<GQL.User>('user');
    const jwt = storage.get<string>('jwt');
    if (user && jwt) {
        const result:any = jwt_decode(jwt);
        console.log('JWT EXPIRES', result.exp);
        // TODO: check if jwt is expired!
        return { user, jwt };
    }
    return null;
}

export const zUser = createZState<Session|null>({
    zid: 'user',
    doNotResetOnLogout: true, // we handle this one manually (this is the only exception so far)
    initVal: getInitVal(),
    // It's a little strange, but, the "fetch" is actually logging in.
    // The update is a normal update (like, changing your avatar)
    apiFetch: async (sessionId:string, token:string) => {
        const apiLogin = api.toastOnErr(api.updateSession, "Unknown login error--please try again");
        const result = await apiLogin(sessionId, token);
        if (!result || !result.whoAmI || !result.jwt) {
            toastError('Invalid token!');
            return null;
        }
        const ses:Session = {
            jwt: result.jwt,
            user: result.whoAmI
        }
        storage.set('jwt', ses.jwt);
        storage.set('user', ses.user);
        return ses;
    },
    // onFetch: ses => {
    //     console.log('SES', ses);
    // },
    apiUpdate: async (ses:Partial<Session> | null) => {
        // Only updating user stuff with this.
        if (!ses) return;
        const { user } = ses;
        if (!user) return;
        const apiUpdate = api.toastOnErr(api.updateUser, "Error updating account");
        // TODO: and this applies to all of these...
        // We could do a diff and only send the difference!
        // For now, send entire user object. It's not very big. (even with base64)
        // Only allow user to change certain things.
        const patch = {
            firstName: user.firstName,
            lastName: user.lastName,
            eulaVersion: user.eulaVersion,
            phone: user.phone,
            profilePhoto: user.profilePhoto
        }
        const result = await apiUpdate(user.id, patch);
        storage.set('user', user);
        return result;
    }
})
