import { ActivityPanel, Loading } from 'components';
import { useEssentials, useMonitorSession } from 'hooks';
import { Alert } from 'mui';
import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { CellHost } from "routes/duncan/cell-host"
import { Scaffold } from "routes/duncan/scaffold"
import { urls } from 'services';
import { Account } from './account/account';
import { Alpha } from './alpha/alpha';
import { Config } from './config/config';
import { Desk } from "./desk/desk";
import { Import } from "./import/import";
import { Jeff } from './jeff';
import { Rules } from "./rules/rules";
import { TapeDetails } from './tape-details/tape-details';
import { Tapes } from './tapes/tapes';


export const AuthRoutes = () => {
	
	const { ready, isCurrentVersion } = useEssentials();
	useMonitorSession();
	
	if (!isCurrentVersion) {
		return (
			<Alert icon="alert-triangle" mt="2">
				A newer version of the software has been released.<br/>
				Please refresh your browser to ensure you are up to date.
			</Alert>
		)
	}
	
	return (
		<Loading
			loading={!ready}
			render={
				<>
					<Routes>
						<Route path="import/*" element={<Import/>}/>
						<Route path="rules/*" element={<Rules/>}/>
						<Route path="tapes/*" element={<Tapes/>}/>
						<Route path="tape/:id/*" element={<TapeDetails/>}/>
						<Route path="config/*" element={<Config/>}/>
						<Route path="account/*" element={<Account/>}/>
						<Route path="desk/*" element={<Desk/>}/>
						<Route path="alpha/*" element={<Alpha/>}/>
						{urls.isLocalhost && <Route path="jeff/*" element={<Jeff/>}/>}
						{urls.isLocalhost && <Route path="duncan/*" element={<CellHost/>}/>}
						{urls.isLocalhost && <Route path="scaffold/*" element={<Scaffold/>}/>}
						{/* <Route path="/desk" element={<Desk />} /> */}
						<Route path="*" element={<Navigate to="tapes"/>}/>
					</Routes>
					<ActivityPanel/>
				</>
			}
		/>
	)
}
