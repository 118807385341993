import React from 'react';
import { ImportModel, ModelItem, TakeFromSrcMap, SrcMap, DeriveEnumInSrcMap } from 'market-dto';
import { PopOverFooter, PopOverHeader, XlsBtn, closePopOver } from '../../components';
import { NicePlural } from '../nice-plural';
import { getWhenEnumInDetails } from './get-when-enum-in-details';
import { SrcMapDeriveEnumInDetails } from './src-map-derive-enum-in-details';

// const getTakeFromSourceStr = (val:any, model:ImportModel, takeFrom:TakeFromSrcMap):string => {
//     if ("fieldId" in takeFrom) {
//         return 'We took the value "' + String(val) + '" from "' + model.getField(takeFrom.fieldId).label + '"';
//     }
//     // if you took from enum itself, that's implied.
//     // if you took from a psuedo-field (inline hint), we have nothing to tell you.
//     return 'We found the value "' + String(val) + '"';
// }


interface Props {
    readonly model:ImportModel;
    readonly field:ModelItem.AnyType;
    readonly derive:DeriveEnumInSrcMap;
    readonly sourceMap:SrcMap[];
    readonly n:number;
}
export const SrcMapDeriveEnumIn = ({ model, field, derive, n, sourceMap }:Props) => {

    const details = getWhenEnumInDetails(field, model, derive, sourceMap);
    const { whenValuesLookingFor, whenLabel } = details;
    const lookedFor = whenValuesLookingFor.length === 1 ? 'value ' + whenValuesLookingFor[0] : 'values ' + whenValuesLookingFor.join(', ');

    return <>
        {/* <textarea readOnly value={JSON.stringify(derive, null, 4)}></textarea> */}
        <div>We looked at "{ whenLabel }" for the { lookedFor }</div>
        <div style={{paddingLeft:'12px'}}>
            <SrcMapDeriveEnumInDetails details={details } />
        </div>
    </>
}