import { ExpNode, SenseOption, ExpContext } from './exp-types';

const getOptList = (node:ExpNode, expCtx:ExpContext, fieldsAndFuncOptions:SenseOption[], opOptions:SenseOption[]):SenseOption[] => {
    const enumResults = checkForEnums(node, expCtx);
    if (enumResults) return enumResults;
    if (node.type === 'IdentifierNode') return fieldsAndFuncOptions;
    if (node.type === 'ExpressionNode') return opOptions;
    return []
}

const getLCaseNodeVal = (node:ExpNode):string => {
    if (node.type === 'StringConstantNode') return node.raw.trim().toLowerCase();
    if (node.type === 'IdentifierNode') return node.raw.trim().toLowerCase();
    if (node.type === 'ExpressionNode') return node.rawOp.trim().toLowerCase();
    return '';
}

const enumsFromModel = (model:ExpContext, val:string):SenseOption[]|null => {
    const f = model.fieldByName[val];
    if (!f) return null;
    if (!f.schema.enum) return null;
    return f.schema.enum.map((x:string):SenseOption => {
        return {
            priority: 'neither',
            type: 'enum',
            value: "'" + x + "'"
        }
    })
}

const checkForEnums = (curr:ExpNode, model:ExpContext):SenseOption[]|null => {
    // Look up and see if we're an argument in a function!
    const parent = curr.getParent!();
    if (!parent) return null;
    if (parent.type === 'ListItemNode') {
        const grandParent = parent.getParent!();
        if (!grandParent) return null; // impossible
        if (grandParent.type === 'FunctionCallNode') {
            const firstArg = grandParent.args[0].item;
            if (firstArg === curr) return null; // never on first node!
            if (firstArg.type === 'IdentifierNode' && firstArg.isValid) {
                return enumsFromModel(model, firstArg.value);
            }
        }
    } else if (parent.type === 'ExpressionNode') {
        if (parent.left.type === 'IdentifierNode' && parent.left.isValid) {
            return enumsFromModel(model, parent.left.value);
        }
    }
    return null;
}

const getList = (s:string, all:SenseOption[]):SenseOption[] | null => {

    // assume "all" is sorted already.

    if (s === '') return all;
    const v = s.toLowerCase();
    
    const matches:SenseOption[] = [];
    const startWith:SenseOption[] = [];
    const hasSubStr:SenseOption[] = [];
    const neither:SenseOption[] = [];

    all.forEach(x => {
        const val = x.value.toLowerCase();
        if (val === v) {
            matches.push({ ...x, priority: 'match' });
        } else if (val.startsWith(v)) {
            startWith.push({ ...x, priority: 'startsWith' });
        } else if (val.indexOf(v) > -1) {
            hasSubStr.push({ ...x, priority: 'hasSubStr' });
        } else {
            neither.push({ ...x, priority: 'neither' });
        }
    })

    return [
        ...matches,
        ...startWith,
        ...hasSubStr,
        ...neither
    ]
}

export const getSenseOptions = (curr:ExpNode|null, model:ExpContext, fieldsAndFuncOptions:SenseOption[], opOptions:SenseOption[]) => {
    if (curr === null) return [];
    const list = getOptList(curr, model, fieldsAndFuncOptions, opOptions);
    const val = getLCaseNodeVal(curr);
    return getList(val, list);
}