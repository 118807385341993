import { Box, CssGrid } from "mui";
import { CrossMatchingSetNote, DataTable, HintTable, InternalPricing } from 'raccoon-engine'
import React from 'react';

interface Args {
	lookupNotes:CrossMatchingSetNote
	table:InternalPricing
}

const range = (x:number) => [...Array(x).keys()]

export const CrossLookupDisplay = (props:Args) => {
	const { table } = props
	const { rows, cols } = props.lookupNotes
	const dataTable = new DataTable({ data: table.csv })
	const hintTable = new HintTable({ data: table.hints })
	const getClassNames = (r:number, c:number) => {
		const cln:string [] = ["mono-line", "matched-gap"]
		if (rows.find(row => row.idx === r && row.result === "Match")) {
			cln.push("matched-row")
		}
		if (cols.find(col => col.idx === c && col.result === "Match")) {
			cln.push("matched-col")
		}
		return cln.join(" ")
	}
	const showRow = (r:number) => {
		console.log(`row ${r}`, rows.find(row => row.idx === r))
	}
	const topLeft = hintTable.topLeftData()
	const getCellCln = (r:number, c:number) => {
		return (r >= topLeft!.y && c >= topLeft!.x) ? "table-data" : "table-not-data"
	}
	return <Box>
		<CssGrid cols={dataTable.width} gap="1">
			{
				range(dataTable.height).map((r) => {
					return range(dataTable.width).map(c => {
						return <div
							onClick={() => showRow(r)}
							className={getClassNames(r, c)} key={r * 1000 + c}>
							<Box fontSize="sm">
								<div className={getCellCln(r, c)}>
								{dataTable.getPosition(c, r)}
								</div>
							</Box>
						</div>
					})
				}).flat()
			}
		</CssGrid>
	
	</Box>
}
