import { useResolvedPath, useLocation, matchPath, resolvePath } from 'react-router-dom';

export const useIsAnyRouteActive = (baseOffsetRoute:string, routes:string[], exact?:boolean):boolean => {
    // This is why I hate when things are needlessly in hooks.
    // Have to do a special dance just to work around the
    // inability to call 'em in a loop (or conditionally).
    // Had to look into react router code to figure out how to allow this.
    const { pathname:basePath } = useResolvedPath(baseOffsetRoute);
    const location = useLocation();
    return routes.some(to => {
        // Do you have a headache yet? Good.
        return matchPath(resolvePath(exact ? to : to + '/*', basePath).pathname, location.pathname) ? true : false;
    })
}
