import React, { useRef } from 'react';
import { GQL } from 'market-dto';
import { Box, FlexVCenter, Icon, DotsLabel } from 'mui';
import { useOnClickOutside } from 'hooks';
import { RunRow } from './run-row';
import { useApiFetch, useVerticalScrollbarShowing } from 'hooks';
import * as api from 'api';
import { Loading } from 'components';
import { useNavigate } from 'react-router-dom';
import { FlowListSelection } from './types';

interface Props {
    readonly flow:GQL.Flow;
    readonly onClose:() => void;
    readonly onSelect:(sel:FlowListSelection) => void;
}
export const PopMenu = ({ onClose, flow, onSelect }:Props) => {

    const { recentActivity } = flow;
    const nav = useNavigate();

    // const loadRun = (runId:string) => nav('../' + flow.id + '/runs/' + runId);

    const ref = useRef(null);
    const scrollRef = useRef(null);
    useOnClickOutside(ref, onClose);

    const {
        loading:loadingRuns,
        data:runs
    } = useApiFetch(() => api.fetchRecentRunListByFlowId(flow.id, 3), []);

    const recentRunId = recentActivity?.runId ?? '';
    const hasScrollbar = useVerticalScrollbarShowing(scrollRef, [runs]);

    return (
        <Box
            ref={ref}
            bg="body"
            width="card-sm"
            borderColor="alert"
            borderWidth="thin"
            borderSides="all"
            fontSize="sm"
        >
            <Box borderSides="bottom" borderColor="alert" borderWidth="thin"> 
                <FlexVCenter justifyContent="space-between" p="1" fg="default">
                    Recent Runs
                    <Icon name="close" fg="faded" fgHover="loud" cursor="pointer" onClick={onClose} />
                </FlexVCenter>
            </Box>

            <Box ml="1">
                <Box
                    maxHeight="card-md"
                    overflow="auto"
                    my="1"
                    pr="1"
                    mr={hasScrollbar ? '1' : '0'}
                    ref={scrollRef}
                >

                    { recentActivity && (
                        <RunRow
                            mt="0"
                            item={recentActivity}
                            onReRun={() => onSelect({ flow, runWithTapeId: recentActivity.sheetId })}
                            onSelect={() => onSelect({ flow, runId: recentActivity.runId })}
                        />
                    ) }

                    <Loading
                        loading={loadingRuns}
                        render={
                            <>
                                { runs && runs.filter(r => r.id !== recentRunId).map(r => (
                                    <RunRow
                                        key={r.id}
                                        mt="1"
                                        item={r}
                                        onReRun={() => onSelect({ flow, runWithTapeId: r.sheetId })}
                                        onSelect={() => onSelect({ flow, runId: r.id })}
                                    />
                                )) }
                            </>
                        }
                        loadingRender={
                            <FlexVCenter mt="1" fg="faded">
                                <Icon name="loader" mr="half" spinning />
                                <DotsLabel>Loading</DotsLabel>
                            </FlexVCenter>
                        }
                    />
                </Box>
            </Box>

            
        </Box>
    )
}