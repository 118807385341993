import { FlexSize } from 'mui';

export * from './types';
export * from './get-fields';
// export * from './get-overrides-from-to-map';
// export * from './update-map-config-with-translation-config';
export * from './set-mapping-override-column';
export * from './set-mapping-override-translate';
export * from './get-field';
export * from './get-unmentioned-override-strings';
export * from './update-trans-cfg-with-enum-pairs';
export * from './get-applied-mapping-results';
export * from './to-partial-template';


export const ui = {
    subHeaderPortalId: 'mapper-sub-header',
    fieldLayoutColumns: 3,
    flexSizes: <FlexSize[]> ["2","3","2"] 
}