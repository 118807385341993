import { DropdownItem } from 'mui';
import { getSamplesByModelId } from './get-samples-by-model-id';

export const getSamplesAsDropdownOpts = (modelId:string) => {
    return getSamplesByModelId(modelId).map((x):DropdownItem => {
        return {
            label: x.name,
            value: x.id
        }
    })
}
