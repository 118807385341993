import { query, gql } from "../../client"
import { GQL } from 'market-dto';

const QUERY = gql`
    query ($batchId:String!, $index:Int) {
        sync_batch_status(batchId: $batchId, index: $index)
    }
`;

interface QueryInput {
    readonly batchId:string;
    readonly index:number;
}

interface QueryOutput {
    readonly sync_batch_status:GQL.BatchStatus;
}

export const checkBatchStatus = async (batchId:string, index?:number):Promise<GQL.BatchStatus|undefined> => {
    const result = await query<QueryInput, QueryOutput>(QUERY, { batchId, index:index ?? 0 });
    console.log('check batch status: ', batchId, result);
    return result.data.sync_batch_status;
}
