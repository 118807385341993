import { useResolvedPath, useMatch } from 'react-router-dom';

export const useIsRouteActive = (route:string, exact?:boolean) => {
    // "to" is likely relative
    // Could be "dog" or "../dog" or it could be absolute "/animals/dog"
    // useResolvedPath saves us the trouble of caring.
    const resolvedTo = useResolvedPath(exact ? route : route + '/*');
    return useMatch(resolvedTo.pathname) ? true : false;
}

