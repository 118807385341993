import { mergeBatch } from '../merge-batch';
import { zBatch } from '../z-batch';

export const setStepAfterTemplateCreated = () => {
    // We only do this if any are not assigned
    const batch = zBatch.get();
    if (!batch)  return;
    if (batch.items.every(item => item.assigned || item.skipped || item.error)) {
        mergeBatch({ step: "POST_CONFIRM" });
    } else {
        // only re-examine if we have some that are still unassigned.
        mergeBatch({ step: "SEARCH_TEMPLATES" });
    }
}