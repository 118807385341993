import React from 'react';
import { Box, Flex, FlexVCenter, Icon, Text } from 'mui';
import { proposal } from 'services';
import { useDrag, useDrop } from 'react-dnd';

interface Props {
    readonly hitInfo:proposal.HitInfo;
    readonly fieldId:keyof proposal.HitLoan;
    readonly loan:proposal.HitLoan;
    readonly customizing:boolean;
    readonly optional?:boolean;
    readonly moveField?:(from:string, to:string) => void;
    readonly onRemove?:(fieldId:keyof proposal.HitLoan) => void;
}
export const HitFieldGeneric = ({ hitInfo, fieldId, loan, customizing, optional, moveField, onRemove }:Props) => {

    const dragType = "hf-" + loan.id;

    const [ collectedDragProps, drag, preview ] = useDrag({
        item: { type:dragType, fieldId },
        collect: (monitor) => {
            return {
                // opacity: monitor.isDragging() ? 0.9 : 1,
                isDragging: !!monitor.isDragging()
            }
        }
    })
    

    const [ collectedDropProps, drop ] = useDrop({
        accept: dragType,
        collect: (monitor) => {
            // console.log(fieldId, monitor.isOver());
            return {
                isHovering: monitor.isOver()
            }
        },
        drop: (item, monitor) => {
            const fromFieldId = (item as any).fieldId;
            if (moveField) moveField(fromFieldId, fieldId);
            // console.log('move', fromFieldId, 'to', fieldId);
        }
    })

    const { isDragging } = collectedDragProps;
    const { isHovering } = collectedDropProps;
    const dragAndDrop = (el:any) => {
        preview(el);
        drop(el);
    }

    const label = proposal.getHitFieldLabel(fieldId);
    const val = proposal.formatHitField(fieldId, loan[fieldId]);

    const fgColor1 = isHovering ? 'loud' : 'faded';
    const fgColor2 = isHovering ? 'loud' : 'default';

    const canDrag = customizing && optional;

    const remove = () => {
        if (onRemove) onRemove(fieldId);
    }

    return (
        <Flex
            p="half"
            m={customizing ? 'half' : '0'}
            fontSize="sm"
            ref={canDrag ? dragAndDrop : () => {}}
            borderWidth="thin"
            borderSides="all"
            borderColor={isDragging ? 'alert' : isHovering ? 'link' : 'transparent'}
            bg={canDrag ? 'inner-card' : 'card'}
        >
            <Box flex="1" cursor={canDrag ? 'move' : 'default'} ref={drag}>
                <FlexVCenter fg={fgColor2}>
                    { val ? val : <Text fg="really-faded">NA</Text> }
                </FlexVCenter>
                <FlexVCenter fg={fgColor1} justifyContent="space-between">
                    { label }
                </FlexVCenter>
            </Box>
            { customizing && canDrag && (
                <Box mt="half" mr="half">
                    <Icon name="trash" fg="faded" cursor="pointer" onClick={remove} />
                </Box>
            ) }
        </Flex>
    )
}