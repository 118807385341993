import React, { useRef } from 'react';
import { Box, BoxProps } from '../box/box';
import { FlexVCenter } from '../flex/flex';
import MisterX from './homer-x.png';

/*
    This component can also be a file (single) picker (image only)
    Because it'll likely be common to replace avatar image by clicking avatar, open native file picker.
*/

interface Props extends BoxProps {
	readonly src?:string;
	readonly name:string;
	readonly onSelectFile?:(file:File) => any;
}

export const Avatar = ({ src, name, onSelectFile, ...props }:Props) => {
	
	const fileInput = useRef<HTMLInputElement>(null);
	
	// Normal avatar if not doing double duty as file picker
	if (!onSelectFile) {
		return (
			<Box
				css="avatar"
				{...props}
			><img
				src={src || MisterX}
				alt={name}
				title={name}
			/></Box>
		)
	}
	
	// They want to be able to use avatar as file picker (for replacing avatar)
	// const boxProps = {
	// 	...rest,
	// 	onClick: () => {
	// 		// override onclick in this case.
	// 		if (fileInput && fileInput.current) fileInput.current.click();
	// 	}
	// }

	const click = () => {
		if (fileInput.current) fileInput.current.click();
	}
	
	const onFileUpload = (e:any) => onSelectFile(e.target.files[0]);

	return (
		<>
			<FlexVCenter
				{...props}
				onClick={click}
			><img
				src={src || MisterX}
				alt={name}
				title={name}
			/></FlexVCenter>
			<input
				ref={fileInput}
				hidden
				type="file"
				onChange={onFileUpload}
				multiple={false}
				accept="image/*"
			/>
		</>
	)
}
