import { Box } from "../box/box"
import React from 'react';

interface Args {
	category: string;
	title: string | number | undefined;
	size?: "sm" | "md" | "lg"
}

const padConfig : any = {
	sm : ["xs", "md"],
	md: ["sm", "2xl"],
	lg: ["lg", "3xl"]
}


export const BloombergPad = (props: Args) => {
	const {size, category, title}  = props
	const sizeConfig = padConfig[size || "md"]
	return <Box>
		<Box fontWeight="thin" fontSize={sizeConfig[0]}>{category}</Box>
		<Box fontWeight="bold" fontSize={sizeConfig[1]}>{title}</Box>
	</Box>
}
