import React from 'react';
import { FlexVCenter, Box, Button, Input, Text, modal, FileSelectButton } from 'mui';
import { SampleModal } from './sample-modal';
import { rule } from 'services';
import { useZStateList } from 'hooks';
import { UploadModal } from './upload-modal';

interface Props {
    readonly samples:rule.SampleWithModel[];
    readonly filterText:string;
    readonly setFilterText:(x:string) => void;
}
export const SamplesTableFilters = ({ filterText, setFilterText, samples }:Props) => {
    
    const { clearItem, selectNewItem, data:allSamples } = useZStateList(rule.zModelSamples);

    const addSampleModal = () => {
        selectNewItem();
        modal.open({
            title: 'New Sample',
            render: <SampleModal />,
            onClose: clearItem
        })
    }

    const onUpload = async (files:File[]) => {
        if (!allSamples) return;
        if (files.length !== 1) return;

        const file = files[0];
        const json = await file.text();

        modal.open({
            title: 'Upload Sample',
            size:'md',
            render: <UploadModal json={json} existingSamples={allSamples} />
        })
    }

    return (
        <FlexVCenter justifyContent="space-between" mb="2">
            <FlexVCenter>
                <Input
                    placeholder="Filter Samples"
                    appendIcon="search"
                    value={filterText}
                    setValue={setFilterText}
                />
                <Box fg="default" ml="1">
                    { samples.length }
                    <Text fg="faded">&nbsp;Sample{ samples.length === 1 ? '' : 's' }</Text>
                </Box>
            </FlexVCenter>
            <FlexVCenter>
                <FileSelectButton
                    icon="upload"
                    size="sm"
                    mr="1"
                    type="default"
                    multiple={false}
                    accept=".json"
                    onSelect={onUpload}
                >Upload</FileSelectButton>
                <Button size="sm" icon="plus" onClick={addSampleModal}>Add Sample</Button>
            </FlexVCenter>
        </FlexVCenter>
    )
}