import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box, FlexVCenter, Text, Icon, LabelValueGrid } from 'mui';
import { profit, format } from 'services';
import * as d3 from 'd3';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';


interface ChartGroup {
    readonly count:number;
    readonly value:number;
    readonly minAcqDate:string; // yyyymmdd
    readonly maxAcqDate:string; // yyyymmdd
}

const toMmDd = (x:string) => format.dateToMonthAndDay(format.yyyymmddToDate(x));

// const scheme = d3.schemeAccent;
// const getColor = (n:number) => scheme[n % scheme.length];

interface MultiLineChartHoverProps {
    readonly active:boolean;
    readonly payload:any[];
}

export const MultiLineChartHover = ({ active, payload }:MultiLineChartHoverProps) => {

    if (!active) return null;
    if (!payload || payload.length === 0) return null; // can happen that is briefly null cuz they clear chart, yet, active

    const groups = payload.reduce((prev:{[x:number]:ChartGroup}, curr:any) => {
        const { dataKey:acqDate, value } = curr;        
        if (prev[curr.value]) {
            prev[curr.value] = {
                count: prev[curr.value].count + 1,
                value,
                maxAcqDate: prev[curr.value].maxAcqDate > acqDate ? prev[curr.value].maxAcqDate : acqDate,
                minAcqDate: prev[curr.value].minAcqDate < acqDate ? prev[curr.value].minAcqDate : acqDate
            }
        } else {
            prev[curr.value] = {
                count: 1,
                value,
                maxAcqDate: acqDate,
                minAcqDate: acqDate
            }
        }
        return prev;
    }, {}) as {[value:number]:ChartGroup}


    return <Box bg="card" p="2" roundedEdges="all" shadow="card" fontSize="sm">
        <LabelValueGrid>
            { Object.entries(groups).map(([ value, group ]) => {
                return (
                    <React.Fragment key={value}>
                        { group.count === 1 && (
                            <Box fg="faded">{ toMmDd(group.minAcqDate) }</Box>
                        ) }
                        { group.count > 1 && (
                            <Box fg="faded">
                                { toMmDd(group.minAcqDate) }
                                <Text fg="really-faded">&nbsp;-&nbsp;</Text>
                                { toMmDd(group.maxAcqDate) }
                            </Box>
                        ) }
                        <Box fg="loud">{ value }</Box>
                    </React.Fragment>
                )
            }) }
        </LabelValueGrid>
    </Box>
}



