import { useEffect } from 'react';
import { user } from 'services';
import { useInterval } from './use-interval';

const THIRTY_SECONDS_MS = 30 * 1000;

export const useMonitorSession = () => {

    // There are two things we do
    // 1. check every x MS to see if session has expired (without going to server--just checking jwt exp time vs computer time)
    //      NOTE: yes, they can hack their computer time, but, server will still reject expired jwt.
    //      We might want to somehow determine offset server time vs local time as some future enhancement.
    //      But for now, we're going to trust local computer time.
    //      If a user had computer time a year old, for example, they'd never be able to do ANYTHING in the application
    //      For now, that's fine.
    // 2. on every mouse click or keyboard event, get a new jwt (extend the session) if we haven't done so recently.

    useEffect(() => {
        const check = () => {
            // Not only is checkExtendSession throttled, but, we also check issue time of current jwt.
            // If current jwt was issues within x ms (set to 5 minutes), we do NOT renew jwt.
            user.checkExtendSession(); // Throttled, once called, future calls within x ms are ignored (set to 2 minutes)
        }
        document.addEventListener("mousedown", check);
        document.addEventListener("keydown", check);
        return () => {
            document.removeEventListener("mousedown", check);
            document.removeEventListener("keydown", check);
        }
    }, []);

    // If user logs in, and does nothing...every x ms, check the jwt to see if expired. If expired, log 'em out.
    useInterval(() => {
        // Note: this does not require trip to server--only checking jwt exp time.
        user.checkJwtExpired();
    }, THIRTY_SECONDS_MS)

}