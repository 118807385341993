import React from 'react';
import { Box, CardTitle, Divider, Flex, FlexVCenter, Button, IconButton, IntoPortal } from 'mui';
import { JSONSchema } from 'market-dto';

interface Props {
    readonly schema:JSONSchema;
}
export const SchemaUiSectionTitle = ({ schema }:Props) => {    
    return (
        <FlexVCenter pb="half" mb="half" borderColor="alert" borderSides="bottom" borderWidth="thin">
            <Box fontSize="md" fg="default">{ schema.title ? schema.title : 'UNTITLED' }</Box>
            <Box fg="faded" fontSize="sm" ml="1">{ schema.description }</Box>
        </FlexVCenter>
    )
}