import React from 'react';
import { ImportModel } from 'market-dto';
import { PopOverFooter, PopOverHeader, XlsBtn, closePopOver } from '../components';
import { fetchLoanSource } from 'api';
import { useApi } from 'hooks';
import { SrcMapView } from './src-map-view';

interface Props {
    readonly model:ImportModel;
    readonly sheetId:string;
    readonly loanId:string;
    readonly fieldId:string;
}
export const SrcMapPopOver2 = ({ model, sheetId, loanId, fieldId }:Props) => {

    const f = model.getField(fieldId);
    if (!f) throw new Error('Expected field');

    const {
        fetching,
        data:loanSource
    } = useApi({
        fetch: () => fetchLoanSource(sheetId, loanId),
        deps: []
    })

    return (
        <>
            <PopOverHeader>"{ f.label }", how did we map it?</PopOverHeader>
            { fetching && <>Loading...</> }
            { !fetching && !loanSource && <>Error loading source map.</> }
            { !fetching && loanSource && (
                <SrcMapView
                    model={model}
                    field={f}
                    loanSource={loanSource}
                />
                
            ) }
            <PopOverFooter>
                <XlsBtn type="default" onClick={closePopOver}>Close</XlsBtn>
            </PopOverFooter>
        </>
    )
}
