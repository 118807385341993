import { AdjustmentTable, Delimiters, Assertion, Partition, AdjTableBuilder, AssertionOperator } from 'raccoon-engine';
import { labels } from 'market-dto';


// TODO: all those "as any" when calling the label service tells me we are treating fieldIds are general strings.
// They should be typed to keyof GQL.Loan!

const space = '\u00A0'; // because &nbsp; gets escaped.

export type ColType = "leaf-header" | "val" | "leaf-val" | "branch-header" | "branch-val" | "empty";

export type ColSubType = "range-left" | "range-right" | "assertion" | "category" | "min-val" | "max-val";

export interface Col {
    readonly colSpan?:number;
    readonly rowSpan?:number;
    readonly onChange?:(t:AdjustmentTable, val:any) => AdjustmentTable;
    readonly val:any;
    readonly type?:ColType;
    readonly subType?:ColSubType;
}

export interface UIAdjTable {
    readonly rows:Array<Array<Col>>
}
      
const transpose = (arr:Array<any>) => arr[0].map((col:any, n:number) => arr.map(row => row[n]));

const uiTableToLeaf = (t:AdjustmentTable, u:UIAdjTable):Partition => {
    const leafHeaderRowIndex = u.rows.findIndex(row => row.find(col => col.type === 'leaf-header'));
    const firstValueRowIndex = u.rows.findIndex(row => row.find(col => col.type === 'val'));
    if (leafHeaderRowIndex === -1) return t.leaf;

    const leafRows = u.rows.slice(leafHeaderRowIndex+1, firstValueRowIndex);
    const filteredLeafRows = leafRows.map(cols => cols.filter(col => col.type === 'leaf-val'));

    if (t.leaf.sets.type === 'RANGE') {
        return {
            ...t.leaf,
            sets: {
                ...t.leaf.sets,
                ranges: transpose(filteredLeafRows.map(cols => cols.map(col => col.val)))
            }
        }
    } else if (t.leaf.sets.type === 'CATEGORY') {
        return {
            ...t.leaf,
            sets: {
                ...t.leaf.sets,
                categories: filteredLeafRows[0].map(col => col.val)
            }
        }
    } else if (t.leaf.sets.type === 'ASSERTION') {
        return t.leaf;
        // return {
        //     ...t.leaf,
        //     sets: {
        //         ...t.leaf.sets,
        //         assertions: transpose(filteredLeafRows.map(cols => cols.map(col => ({
        //             ...col.data, // perhaps store the field/param?
        //             param: col.val
        //         }))))
        //     }
        // }
    } else if (t.leaf.sets.type === 'MIN' || t.leaf.sets.type === 'MAX') {
        throw new Error('currently we can only handle MIN and MAX as BRANCHES (not as leaf nodes)');
    }
    // how did you get here?
    return t.leaf;
}

const getMinPartitionFromArray = (p:Partition, colIndex:number, arr:any[][]):Partition => {
    return {
        ...p,
        sets: {
            ...p.sets,
            lowerBounds: arr.map(innerArr => innerArr[colIndex])
        }
    }
}

const getMaxPartitionFromArray = (p:Partition, colIndex:number, arr:any[][]):Partition => {
    return {
        ...p,
        sets: {
            ...p.sets,
            upperBounds: arr.map(innerArr => innerArr[colIndex])
        }
    }
}

const getRangePartitionFromArray = (p:Partition, colIndex:number, arr:any[][]):Partition => {
    // We get a 2d array like this:
    // [ 'blue', 0, 10, 'cat']
    // [ 'red', 11, 20, 'dog' ]
    // And this example, "blue/red" are a category, "cat/dog" are a category, the 2nd and 3rd items the ranges we want:
    // 0 to 10, 11 to 20.
    // colIndex should be "1" because of this.
    return {
        ...p,
        sets: {
            ...p.sets,
            ranges: arr.map(innerArr => [ innerArr[colIndex], innerArr[colIndex+1] ])
        }
    }
}

const getCategoryPartitionFromArray = (p:Partition, colIndex:number, arr:any[][]):Partition => {
    return  {
        ...p,
        sets: {
            ...p.sets,
            categories: arr.map(innerArr => innerArr[colIndex])
        }
    }
}

const getBranchEndingIndexes = (t:AdjustmentTable):Array<number> => {
    // return array of ENDING column indexes for the various branches
    const arr:Array<number> = [];
    for (let i=0; i < t.branches.length; i++) {
        const b = t.branches[i];
        const colsReq = getColsRequiredForBranchDelim(b.sets);
        arr.push(arr.length === 0 ? colsReq : colsReq + arr[arr.length-1]);
    }
    return arr;
}


const getBranchStartingIndexes = (t:AdjustmentTable):Array<number> => {
    // return array of starting column indexes for the various branches
    const arr = getBranchEndingIndexes(t);
    // now we ahve END index array, but we want STARTING.
    // so, just unshift a zero and pop the last one
    arr.unshift(0);
    arr.pop();
    return arr;
}

const getColsRequiredForBranchDelim = (bd:Delimiters) => {
    if (bd.type === 'RANGE') return 2;
    return 1;
}

const uiTableToBranches = (t:AdjustmentTable, u:UIAdjTable):Array<Partition> => {

    const firstValueRowIndex = u.rows.findIndex(row => row.find(col => col.type === 'val'));
    const branchValRows = u.rows.slice(firstValueRowIndex).map(arr => arr.map(col => col.val)) as Array<Array<any>>;
    const startingIndexes:Array<number> = getBranchStartingIndexes(t);

    return t.branches.map((b, branchIndex) => {
        if (b.sets.type === 'RANGE') return getRangePartitionFromArray(b, startingIndexes[branchIndex], branchValRows);
        if (b.sets.type === 'MIN') return getMinPartitionFromArray(b, startingIndexes[branchIndex], branchValRows);
        if (b.sets.type === 'MAX') return getMaxPartitionFromArray(b, startingIndexes[branchIndex], branchValRows);
        if (b.sets.type === 'CATEGORY') return getCategoryPartitionFromArray(b, startingIndexes[branchIndex], branchValRows);
        return b;
    })
}

const uiTableToValues = (t:AdjustmentTable, u:UIAdjTable):Array<Array<any>> => {
    const firstValueRowIndex = u.rows.findIndex(row => row.find(col => col.type === 'val'));
    const firstRow = u.rows[firstValueRowIndex];
    const firstValueColIndex = firstRow.findIndex(col => col.type === 'val');
    return u.rows.slice(firstValueRowIndex).map(cols => cols.slice(firstValueColIndex).map(col => col.val));
}

export const uiAdjTableToAdjustmentTable = (t:AdjustmentTable, u:UIAdjTable):AdjustmentTable => {
    return {
        ...t,
        leaf: uiTableToLeaf(t, u),
        branches: uiTableToBranches(t, u),
        values: uiTableToValues(t, u)
    }
}

export const adjustmentTableToUIAdjTable = (adjTable:AdjustmentTable):UIAdjTable => {

    // First, translate any simple assertions into category delims
    const t:AdjustmentTable = {
        ...adjTable,
        branches: adjTable.branches.map(b => convertSimpleAssertionsToCategory(b))
    }
    // Now ensure the integrity of the working table
    ensureTableIntegrity(t);

    const showLeafHeader = determineShowLeafHeader(t.leaf.sets);

    const branchColumnAmount = t.branches
        .map(b => b.sets)
        .reduce((sum, d:Delimiters) => sum + getColsRequiredForBranchDelim(d), 0);

    const topCols:Array<Col>|null = showLeafHeader ? [
        {
            colSpan: branchColumnAmount,
            rowSpan: rowsNeededForLeafDelimiter(t.leaf.sets),
            val: space,
            type: 'empty'
        },
        {
            val: labels.byLoanField(t.leaf.field as any),
            colSpan: getLeafColumnAmount(t),
            type: 'leaf-header'
        }
    ] : null;

    const rows = [
        topCols,
        ...prependBranchHeaders(t, getLeafRows(t, showLeafHeader)),
        ...getBodyRows(t)
    ].filter(x => x !== null) as Array<Array<Col>>;
    return { rows }
}

const ensureTableIntegrity = (t:AdjustmentTable) => {
    // Check a few assumptions about the data.
    const branchDelims = t.branches.map(x => x.sets);
    // if (branchDelims.find(x => x.type === 'ASSERTION')) throw 'Assertions not allowed in branch delimiters';
    if (t.values.find(innerValsArr => innerValsArr.length !== t.values[0].length)) throw 'Mismatched values inner array lenghts';
    branchDelims.forEach(checkDelims);
    checkDelims(t.leaf.sets);
    const rowLen = t.values.length;
    branchDelims.forEach((d, n) => {
        if (getDelimRowsRequired(d) !== rowLen) {
            throw new Error(`Mismatched array lengths - check your branch delim inner arrays and values outer array lengths ${ d },${ rowLen},${n}`);
        }
    })
    const valColsAmount = t.values[0].length;
    if (getLeafColumnAmount(t) !== valColsAmount) throw 'Leaf delim columns does not match values inner array lengths -- are you missing a column of data?';
}

const getDelimRowsRequired = (d:Delimiters):number => {
    if (d.type === 'ASSERTION') {
        return d.assertions!.length;
    } else if (d.type === 'CATEGORY') {
        return d.categories!.length;
    } else if (d.type === 'RANGE') {
        return d.ranges!.length;
    } else if (d.type === 'MIN') {
        return d.lowerBounds!.length;
    }
    return 0;
}

const checkDelims = (d:Delimiters) => {
    if (d.type === 'ASSERTION') {
        if (!d.assertions) throw 'Delimiter of type ASSERTION is missing assertions array';
        // if (d.assertions.find(arr => arr.length !== d.assertions![0].length)) throw 'Mismatched inner assertion array lengths!';        
    } else if (d.type === 'MIN') {
        if (!d.lowerBounds) throw 'Delimiter of type MIN is missing lowerBounds array';
    } else if (d.type === 'RANGE') {
        if (!d.ranges) throw 'Delimiter of type RANGE is missing range array';
    } else if (d.type === 'CATEGORY') {
        if (!d.categories) throw 'Delimiter of type CATEGORY is missing categories array';
    }
}

const getLeafColumnAmount = (t:AdjustmentTable):number => {
    const d = t.leaf.sets;
    if (d.ranges) return d.ranges.length;
    if (d.assertions) return d.assertions.length;
    if (d.categories) return d.categories.length;
    return 1;
}


const getBodyRows = (t:AdjustmentTable):Array<Array<Col>> => {
    return t.values.map((rowValues, rowIndex) => {
        return [
            ...getBranchCols(t, rowIndex),
            ...(rowValues.map(val => ({ val: val, type: 'val' } as Col)))
        ]
    });
}

const rowsNeededForLeafDelimiter  = (d:Delimiters) => {
    if (d.type === 'ASSERTION') {
        // Assumption: inner assertion arrays are ALWAYS the same length!
        return d.assertions![0].length;
    } else if (d.type === 'RANGE') {
        return 2;
    } else if (d.type === 'CATEGORY') {
        return 1;
    }
    return 1;
}

const getOpLabel = (op:AssertionOperator) => {
    if (op === 'greaterEq') return '>=';
    return op;
}

const prettyAssertion = (a:Assertion) => {
    // good enough for now
    if (a.operator === 'includes') return a.param;
    if (a.operator === 'equal') {
        if (typeof a.param === 'number') {
            return labels.byLoanField(a.field as any) + ': ' + String(a.param);
        } else {
            return a.param;
        }
    }
    if (a.operator === 'notEqual') return 'NOT ' + a.param;
    return labels.byLoanField(a.field as any) + ' ' + getOpLabel(a.operator) + ' ' + a.param;
}

const prettyAssertions = (arr:Assertion[]) => arr.map(a => prettyAssertion(a)).join(', ');


const getLeafRows = (t:AdjustmentTable, showLeafHeaders:boolean):Array<Array<Col>> => {
    if (!showLeafHeaders) return [[]];
    const delims = t.leaf.sets;
    if (delims.type === 'ASSERTION') {
        // const arr = delims.assertions!;
        // transpose the array. this means we MUST have equal size inner arrays!
        const newArr = transpose(delims.assertions!) as Assertion[][];
        return newArr.map(innerArr => {
            return innerArr.map(x => ({
                val: prettyAssertion(x),
                type: 'leaf-val',
                subType: 'assertion'
            } as Col))
        })
    } else if (delims.type === 'RANGE') {
        const arr = delims.ranges!;
        // transpose the array. this means we MUST have equal size inner arrays!
        const newArr = transpose(delims.ranges!) as number[][]; // how do we type this?

        return newArr.map((innerArr, n) => {
            return innerArr.map(x => ({
                val: x,
                type: 'leaf-val',
                subType: n === 0 ? 'range-left' : 'range-right'
            } as Col))
        })
    } else if (delims.type === 'CATEGORY') {
        const arr = delims.categories!;
        return [arr.map(x => ({
            val: x,
            type: 'leaf-val',
            subType: 'category'
        } as Col))];
    } else if (delims.type === 'MIN') {
        const arr = delims.lowerBounds!;
        return [arr.map(x => ({
            val: x,
            type: 'leaf-val',
            subType: 'min-val'
        } as Col))];
    } else if (delims.type === 'MAX') {
        const arr = delims.upperBounds!;
        return [arr.map(x => ({
            val: x,
            type: 'leaf-val',
            subType: 'max-val'
        } as Col))];
    }
    return  [];
}

const getBranchHeaderCols = (t:AdjustmentTable):Array<Col> => {
    return t.branches.map(b => ({
        val: labels.byLoanField(b.field as any),
        colSpan: getColsRequiredForBranchDelim(b.sets),
        type: 'branch-header'
    }));
}

const getBranchCols = (t:AdjustmentTable, rowIndex:number):Array<Col> => {
    const arr:Array<Col | Col[]> =  t.branches.map(b => {
        const delims = b.sets;
        if (delims.type === 'CATEGORY') {
            return {
                val: delims.categories![rowIndex],
                type: 'branch-val',
                subType: 'category'
            }
        } else if (delims.type === 'RANGE') {
            return delims.ranges![rowIndex].map((x, n) => ({
                val: x,
                type: 'branch-val',
                subType: n === 0 ? 'range-left' : 'range-right'
            } as Col))
        } else if (delims.type === 'ASSERTION') {
            return {
                val: prettyAssertions(delims.assertions![rowIndex]),
                type: 'branch-val',
                subType: 'assertion'                
            }
        } else if (delims.type === 'MIN') {
            return {
                val: delims.lowerBounds![rowIndex],
                type: 'branch-val',
                subType: 'min-val'
            }
        } else if (delims.type === 'MAX') {
            return {
                val: delims.upperBounds![rowIndex],
                type: 'branch-val',
                subType: 'max-val'
            }
        }
        return { val: '?' }
    })
    // Ranges are in arrays which must be flattened!
    return arr.flat(); // convert [{val:'cat'},[{val:0},{val:10}]] to [{val:'cat'},{val:0},{val:10}]
}

const prependBranchHeaders = (t:AdjustmentTable, rows:Array<Array<Col>>):Array<Array<Col>> => {
    // we receive leaf rows, which ahve leaf headers...
    // ... but the final item in the array will share an html table row!
    // we must insert the branch headers into that final row.
    // ASSUMPTION: branch headers are always one row tall. just one row.        
    return rows.map((row, rowIndex) => {
        if (rowIndex === rows.length-1) {
            // final one!
            return [
                ...(getBranchHeaderCols(t)),
                ...row
            ]
        } else {
            return row;
        }
    });
}

const convertSimpleAssertionsToCategory = (p:Partition):Partition => {
    if (p.sets.type === 'ASSERTION') {
        if (!p.sets.assertions) return p;
        // Check if every single one in this assertion array is a simple: "field == param" where field is fieldId!
        // If so, turn it into a CATEGORY delim.
        const notSoSimple = p.sets.assertions!.find(innerArr =>
            innerArr.length !== 1 ||
            (innerArr.length === 1 && (
                innerArr[0].operator !== 'equal' ||
                innerArr[0].field !== p.field
            ))
        );
        if (notSoSimple) return p;
        // Ah-ha! We have a simple array of nothing but x == y assertions!
        // In other words, we have a CATEGORY.
        return {
            ...p,
            sets: {
                type: 'CATEGORY',
                name: p.sets.name,
                categories: p.sets.assertions!.map(innerArr => innerArr[0].param)
            }
        } as Partition
    }
    return p;
}

const determineShowLeafHeader = (d:Delimiters):boolean => {
    if (d.type === 'ASSERTION') {
        const notMustExist = d.assertions!.find(arr => arr.find(a => a.operator !== 'exists'));
        if (notMustExist) return true;
        // if nothing but "a must exist", "b must exist" ... hide the header!
        return false;
    }
    return true;
}