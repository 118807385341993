import { mutate, gql, GQL } from "../../client";

const UPDATE = gql`
    mutation updateSession($set: session_set_input, $where: session_bool_exp!) {
        update_session(set: $set, where: $where) {
            errorMessage,
            returning {
                jwt
                whoAmI
            }
        }
    }
`;

interface SessionResult {
    readonly whoAmI:GQL.User;
    readonly jwt:string;
}

interface MutateInput {
    readonly set:{
        readonly token:string;
    };
    readonly where:GQL.WhereString;
}

interface MutateOutput {
    readonly update_session:{
        readonly returning:[SessionResult]
    }
}

// This is how we LOG IN. it's weird to call it "updateSession"
export const updateSession = async (id:string, token:string):Promise<SessionResult|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(UPDATE, {
        set: {
            token
        },
        where: {
            id: {
                _eq: id
            }
        }
    })
    return result?.data?.update_session.returning[0];
}
