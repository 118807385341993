import React, { useState } from 'react';
import { GQL, ImportModel, createNewDefaultTabForXlsExportCfg } from 'market-dto';
import { FlexVCenter, Box } from 'mui';
import { XlsBtn } from './components';

interface Props {
    readonly model:ImportModel;
    readonly onAddNewTab:(newTab:GQL.TaskXlsExportTabConfig) => void;
    readonly onCancel:() => void;
    readonly tabs:GQL.TaskXlsExportTabConfig[];
}
export const XlsExportAddNew = ({ model, tabs, onAddNewTab, onCancel }:Props) => {

    const [ cloneFromIndex, setCloneFromIndex ] = useState(-1);
    const [ newName, setNewName ] = useState('');

    const btnCss = newName.trim() ? "m-xls-btn m-xls-btn-white m-xls-btn-sm" : "m-xls-btn m-xls-btn-disabled m-xls-btn-sm"

    const go = () => {
        const name = newName.trim();
        if (!name) return;
        if (cloneFromIndex === -1) {
            onAddNewTab(createNewDefaultTabForXlsExportCfg(model, name, tabs.length));
        } else {
            onAddNewTab({
                ...tabs[cloneFromIndex],
                name:name,
                tabNo: tabs.length                
            })
        }        
    }

    return (
        <div className="m-xls-adding-area m-xls-right-side-main-height">

            <div style={{ width: '450px' }}>
                <div className="m-xls-heading">New Tab</div>
                <input
                    autoFocus
                    value={newName}
                    onChange={x => setNewName(x.target.value)}
                    type="text"
                    className="m-xls-input m-xls-bordered"
                    placeholder="New Tab Name"
                />
                <FlexVCenter mt="1">
                    <div style={{ whiteSpace: 'nowrap', marginRight:'4px' }}>Clone from Tab:</div>
                    <select
                        value={cloneFromIndex}
                        onChange={x => setCloneFromIndex(+x.target.value)}
                        className="m-xls-input m-xls-bordered"
                    >
                        <option value={-1}></option>
                        { tabs.map((t, n) => <option key={n} value={n}>{ t.name }</option> ) }
                    </select>
                </FlexVCenter>
                <Box mt="2">
                    <XlsBtn
                        type="default"
                        onClick={onCancel}
                    >Cancel</XlsBtn>
                    &nbsp;&nbsp;
                    <XlsBtn
                        type={newName.trim() ? 'default' : 'disabled'}
                        onClick={go}
                    >Create Tab</XlsBtn>
                    {/* <button className={btnCss} onClick={go}>Continue</button> */}
                </Box>
            </div>
        </div>
    )
}