import { CashWindow, format } from 'market-dto';


export const getDateRangesFrom2dHoldingArray = (
    holdingArr:Array<Array<CashWindow.HoldingInterest|null>>
):{
    aquisitionDates: Date[],
    deliveryDates: Date[]
} => {

    // Brute force is good enough.
    // Just go through every item, record every date found.
    // Then, sort em, return 'em.

    const acquisitionDateMap:Map<string, Date> = new Map();
    const deliveryDateMap:Map<string, Date> = new Map();

    holdingArr.forEach(innerArr => {
        innerArr.forEach(item => {
            if (item !== null) {
                acquisitionDateMap.set(format.dateToYyyyMmDd(item.acquisitionDate), item.acquisitionDate);
                deliveryDateMap.set(format.dateToYyyyMmDd(item.target), item.target);
            }
        })
    })

    const aquisitionDates = Array.from(acquisitionDateMap.entries()).sort((a, b) => {
        // oldest to most futuristic
        if (a[0] > b[0]) return 1;
        if (a[0] < b[0]) return -1;
        return 0;
    }).map(arr => arr[1])

    const deliveryDates = Array.from(deliveryDateMap.entries()).sort((a, b) => {
        // oldest to most futuristic
        if (a[0] > b[0]) return 1;
        if (a[0] < b[0]) return -1;
        return 0;
    }).map(arr => arr[1])

    return {
        aquisitionDates,
        deliveryDates
    }
}