import React from 'react';
import { Icon, FlexVCenter } from 'mui';
import { IconName } from 'mui/components/icon/icon';


type XlsBtnType = "white-on-green"|"default"|"disabled";

const getCss = (type:XlsBtnType, busy:boolean) => {
    if (type === "white-on-green") {
        return 'm-xls-white-icon-on-green'; // hack. live with it.
    }
    const arr = ['m-xls-btn'];
    if (type === "default") {
        arr.push("m-xls-btn-white");
    } else if (type === "disabled") {
        arr.push("m-xls-btn-disabled");
    }
    return arr.join(' ');
}

interface Props {
    readonly type:XlsBtnType;
    readonly children?:React.ReactNode;
    readonly onClick?:() => void;
    readonly busy?:boolean;
    readonly icon?:IconName;
}
export const XlsBtn = ({ type, children, onClick, busy, icon }:Props) => {

    return <button
        onClick={() => {
            if (!busy && onClick) onClick();
        }}
        className={getCss(type, false)}
    >
        <FlexVCenter>
            { busy && <span className="m-xls-btn-spinner"><Icon name="loader" spinning /></span> }
            { !busy && icon && <Icon name={icon} mr="half" /> }
            { children }
        </FlexVCenter>
    </button>
}
/*
    <button className="m-xls-white-icon-on-green" onClick={download}>
        <FlexVCenter>
            <Icon name="download" mr="half" />
            Export
        </FlexVCenter>
    </button>
*/