import React, { useState, useEffect } from 'react';
import { GQL } from 'market-dto';
import { Box, Button, FlexVCenter, Link, Text, Icon, Dropdown, DropdownItem, LabelValueGrid, Flex } from 'mui';

interface Props {
    readonly alias:string;
    readonly sellers:GQL.Org[];
    readonly assignAlias:(x?:GQL.Org) => void;
    readonly currSeller?:GQL.Org;
    readonly weThinkItIs?:GQL.Org;
}
export const AggIdentifySellerGivenAlias = ({
    alias,
    sellers,
    assignAlias,
    weThinkItIs,
    currSeller
}:Props) => {

    const [ seller, setSeller ] = useState<GQL.Org|undefined>(currSeller);

    const sellerOpts:DropdownItem[] = sellers.map(seller => {
        return {
            value: seller,
            label: seller.name
        }
    })

    return (
        <>
            { !weThinkItIs && (
                <>
                    <FlexVCenter fg="default">
                        <Icon name="alert-triangle" mr="1" fg="accent" />
                        <Text fg="accent">Seller Name</Text>
                        &nbsp;
                        maps to a column with with an unknown seller:
                        &nbsp;
                        <Text fg="loud" fontWeight="bold">{ alias }</Text>
                    </FlexVCenter>
                    <Box fg="loud" mt="1">
                        Please identify this seller below.
                    </Box>
                </>
            ) }
            { weThinkItIs && (
                <>
                    <FlexVCenter fg="default">
                        The value for 
                        &nbsp;
                        <Text fg="accent">Seller Name</Text>
                        &nbsp;
                        found in the tape:
                        &nbsp;
                        <Text fg="loud" fontWeight="bold">{ alias }</Text>
                    </FlexVCenter>
                    <Box>
                        We have identified this seller as 
                        &nbsp;
                        <Text fg="loud" fontWeight="bold">{ weThinkItIs.name }</Text>
                    </Box>
                    <Box>
                        If this is not right, you can change the association below.
                    </Box>
                </>
            ) }
            <FlexVCenter my="half">
                <Dropdown
                    width="frm-md"
                    size="sm"
                    value={sellerOpts.find(x => x.value === seller)}
                    setValue={setSeller}
                    items={sellerOpts}
                />
                <Button
                    ml="1"
                    size="sm"
                    type={seller ? 'default' : 'disabled'}
                    onClick={() => assignAlias(seller)}
                >Ok</Button>
            </FlexVCenter>
            { weThinkItIs && weThinkItIs !== currSeller && (
                <Link onClick={() => assignAlias(weThinkItIs)}>Actually, it is { weThinkItIs.name }.</Link>
            ) }
            { weThinkItIs && weThinkItIs === currSeller && (
                <Link onClick={() => assignAlias(weThinkItIs)}>Nevermind. { weThinkItIs.name } is correct.</Link>
            ) }
        </>
    )
}