import { mutate, gql } from "../../client";
import { utils } from 'services';

// LP - freddie
// DU - fannie

const RUN_PRICING = gql`
    mutation runFanniePricing($sheetId: String, $costOfFunds: Float, $sellerMargin: Float, $simulateTimedOut: Float, $simulateError: String) {
        run_fannie_pricing(sheetId:$sheetId, costOfFunds: $costOfFunds, sellerMargin: $sellerMargin, simulateTimedOut: $simulateTimedOut, simulateError: $simulateError)
    }
`;

interface MutateOutput {
	readonly run_fannie_pricing: {
		readonly stub:string;
		readonly invocationId:string;
	}
}

interface MutateInput {
	readonly sheetId:string;
	readonly costOfFunds:number;
	readonly sellerMargin:number;
	readonly simulateTimedOut?:number;
	readonly simulateError?:string;
}

export const runFanniePricing = async (
	sheetId:string,
	costOfFunds:number,
	sellerMargin:number,
	simulateTimedOut?:number,
	simulateError?:string
):Promise<string|undefined> => {
	const result = await mutate<MutateInput, MutateOutput>(RUN_PRICING, {
		sheetId,
		costOfFunds,
		sellerMargin,
		simulateError,
		simulateTimedOut
	})
	// console.log('WE REQUESTED FANNIE PRICING--sleep for 3sec before telling anyone');
	// await utils.sleep(3000);
	// console.log('WE REQUESTED FANNIE PRICING, NOW TELL PEOPLE');
	return result?.data?.run_fannie_pricing.invocationId;
}
