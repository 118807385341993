import React from 'react';
import { Box } from '../box/box';
import { toast as reactToast } from 'react-toastify';


export const toast = (title:string, desc?:string) => {

    // TODO: we shouldn't have m-toast, m-toast-body at all...
    // But, it means removing or overriding the default toastify classes.
    // So, for now, just do this.

    if (!desc) {
        reactToast(title, {
            // autoClose: false,
            className: 'm-toast',
            bodyClassName: 'm-toast-body'
        });
    } else {
        reactToast(() => (
            <>
                <Box mb="2" fg="loud">{ title }</Box>
                <Box fg="default">{ desc }</Box>
            </>
        ), {
            className: 'm-toast',
            bodyClassName: 'm-toast-body'
        });    
    }
}


export const toastError = (title:string, desc?:string) => {
    if (!desc) {
        reactToast(title, {
            className: 'm-toast-error',
            bodyClassName: 'm-toast-body'
        });
    } else {
        reactToast(() => (
            <>
                <Box mb="2" fg="loud">{ title }</Box>
                <Box fg="default">{ desc }</Box>
            </>
        ), {
            className: 'm-toast-error',
            bodyClassName: 'm-toast-body'
        });    
    }
}