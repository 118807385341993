import React, { useMemo, useState } from 'react';
import { GQL } from 'market-dto';
import { fetchQualifierSet, setProgramQualifier } from "api"
import { stripInternals } from "api/client"
import { Loading } from "components"
import { useApi, useApiUpdate } from "hooks"
import { Box, Button, Flex, Icon, Input } from "mui"
import { IQualifier } from "raccoon-engine"
import { useParams } from "react-router-dom"
import { cannedConstructs } from "./canned-condition"
import { ConditionEditor } from "./condition-editor"
import { ConditionSelector } from "./condition-selector"
import "./qualifier.scss"
import { createSelectionHubs } from "./selection-hub"
import { wording } from "./types";


const firstFor = (qualifiers:IQualifier[]) => {
	const found = qualifiers.find(qualifier => qualifier.qualifiedFor)
	return (found) ? found.qualifiedFor : ""
}

const DeleteBtn = (props:{ onDelete:Function }) => {
	return (<div className="delete-btn">
		<Icon onClick={() => props.onDelete()} name="trash"/>
	</div>)
}

interface Props {
	readonly task:GQL.FlatTask;
}
export const ProgramQualifier = ({ task }:Props) => {
	// const { taskId } = useParams();
	const { taskId } = task;
	const {
		fetching: loading,
		data: qualifierDoc
	} = useApi({
		           fetch: () => fetchQualifierSet(taskId),
		           deps: [taskId]
	           })
	const { busy: saving, update } = useApiUpdate(setProgramQualifier);
	const [selected, setSelected] = useState("")
	const [programs, setPrograms] = useState<string[]>(["full", "edge"])
	const {
		candidates$,
		program$,
		hub$,
		selected$,
		deleteProgram,
		getCurrentQualifier
	} = useMemo(() => {
		const starter:IQualifier[] = qualifierDoc?.qualifiers || []
		const hub = createSelectionHubs(starter, cannedConstructs)
		const { program$ } = hub
		// populate all programs
		setPrograms(starter.map(q => q.qualifiedFor).filter(p => p))
		
		// activate the first
		const firstProgram = firstFor(starter)
		if (firstProgram) {
			program$.next(firstProgram)
			setSelected(firstProgram)
		}
		return hub
	}, [qualifierDoc])
	
	const [targetProgram, setProgValue] = useState("")
	
	const onSelect = (p:string) => {
		program$.next(p)
		setSelected(p)
	}
	const onAddProgram = (program:string) => {
		setPrograms(programs => {
			programs.push(program)
			setProgValue("")
			return [...programs]
		})
	}
	const cln = (p:string) =>
		(p === selected)
		? 'program program-selected'
		: 'program program-option'
	
	const onSave = async () => {
		const q = getCurrentQualifier().filter(q => q.constructs.length > 0)
		const patch = stripInternals({ ...qualifierDoc, qualifiers: q || [] }, true)
		await update(patch as any)
	}
	
	const onDelete = async () => {
		const newTabs = deleteProgram(selected)
		setPrograms(newTabs)
	}
	
	return <Loading
		loading={loading}
		render={(<Box bg="card" p="2" roundedEdges="all" shadow="card" fontSize="md">
			<Box fg="loud" fontWeight="bold" fontSize={"md"}>{wording.title}</Box>
			<Box fg="form-label" fontWeight="thin"> {wording.description}</Box>
			<Box bg="inner-card" p="2">
				
				
				<Flex justifyContent="space-between" style={{ alignItems: "baseline" }}>
					<div style={{ display: "flex" }}>
						<div className="program program-header">Loan Programs</div>
						<div className="program-layout">
							{
								programs.map((p, idx) => <div className={cln(p)}
								                              onClick={() => onSelect(p)}
								                              key={idx}>{p}
									{(p === selected) && <DeleteBtn onDelete={onDelete}/>}
								</div>)
							}
							<Flex>
								<Input value={targetProgram} placeholder="new program"
								       setValue={val => setProgValue(val)}/>
								<Button size="sm" type="link"
								        onClick={() => {onAddProgram(targetProgram)}}>+</Button>
							</Flex>
						</div>
					
					</div>
					<Box>
						<Button busy={saving} size="sm" type="default"
						        onClick={onSave}>Save</Button>
					</Box>
				
				</Flex>
			
			
			</Box>
			
			<div className="qualifier-panel qualifier-panel-layout">
				
				<ConditionEditor selected$={selected$} hub$={hub$}/>
				<ConditionSelector candidates$={candidates$} hub$={hub$}/>
			</div>
		</Box>)}/>
	
}
