import React from 'react';
import { Text, Box, IntoPortal, FlexVCenter, Alert } from 'mui';
import { mapping } from 'services';
import { TransEnumItem } from './trans-enum-item';
import { loanModel, TransCfg } from 'market-dto';

const getDefaultValueLabel = (defVal?:any, getLabel?:(x:any) => string):string => {
    if (!defVal) return '';
    return getLabel ? getLabel(defVal) : defVal;
}

const toRawAndTranslated = (val:string, transCfg:TransCfg, unmentioned?:boolean):mapping.RawAndTranslated => {
    return {
        raw: val,
        unmentioned,
        // We wanna translations WITHOUT default values being applied (for UI purposes)
        translation: loanModel.translateAdHoc(val, {
            ...transCfg,
            defaultValue: undefined            
        }),
        // What would we get if we had no override?
        defaultTranslation: loanModel.translateAdHoc(val, {
            ...transCfg,
            override: undefined,
            defaultValue: undefined
        })
    }
}

interface Props {
    readonly canEdit:boolean;
    readonly distinctValues:string[];
    readonly enums:string[];
    readonly getLabel:(x:string) => string;
    readonly transCfg:TransCfg;
    readonly onChange:(transCfg:TransCfg) => void;
}
export const TransEnum = ({ enums, getLabel, transCfg, canEdit, distinctValues, onChange }:Props) => {

    // if (!opts.enums) throw new Error('Missing enums');
    // const { enums, getLabel } = opts;

    const unmentionedDistinctVals = mapping.getUnmentionedOverrideStrings(distinctValues, transCfg);
    const mentioned = distinctValues.map(x => toRawAndTranslated(x, transCfg));
    const unmentioned = unmentionedDistinctVals.map(x => toRawAndTranslated(x, transCfg, true));
    const items = [...mentioned, ...unmentioned];

    const onEnumValChange = (item:mapping.RawAndTranslated, enumVal:string) => {
        const rawString = String(item.raw);
        const pairs = items.map((x):mapping.MapPair => {
            return item === x ? {
                enumVal: enumVal,
                rawVal: rawString
            } : {
                enumVal: x.translation.translated ? x.translation.val : '',
                rawVal: x.raw
            }
        })
        console.log(transCfg, pairs);
        const nextTransCfg = mapping.updateTransCfgWithEnumPairs(transCfg, pairs);
        console.log('abc', nextTransCfg);
        onChange(nextTransCfg);
    }

    const defVal = getDefaultValueLabel(transCfg.defaultValue, getLabel);
    return <>

        <IntoPortal id={mapping.ui.subHeaderPortalId}>
            <Box m="2" borderSides="bottom" borderColor="alert" borderWidth="thin" >
                <Box fontSize="sm" mb="2">
                    We do our best to auto-translate values for you, however,
                    there may be times when a seller provides unanticipated values for a given field.
                    Below, you can map such values.
                </Box>
                { defVal && (
                    <Box fontSize="sm" mb="2">
                        Any unmapped values below will be mapped to: <Text fontWeight="bold">{ defVal }</Text>
                    </Box>
                )}
            </Box>
        </IntoPortal>
        { mentioned.length > 0 && (
            <Box>            
                <FlexVCenter pb="1">
                    <Box flex="2" textAlign="right" mr="1" fg="faded">
                        Spreadsheet Values
                    </Box>
                    <Box flex="3" textAlign={canEdit ? 'center' : 'left'} mr="1" fg="faded">
                        {/* must be nested to ensure flexes line up with those that come below */}
                        <Box ml={canEdit ? "0" : "1"}>Map To</Box>
                    </Box>
                </FlexVCenter>
                { mentioned.map(item => (
                    <TransEnumItem
                        canEdit={canEdit}
                        key={item.raw}
                        item={item}
                        canBeBlank={!item.defaultTranslation.val}
                        enums={enums}
                        getLabel={getLabel}
                        onChange={x => onEnumValChange(item, x)}
                    />
                ))}
            </Box>
        )}
        { unmentioned.length > 0 && (
            <Box>            
                <FlexVCenter pb="1">
                    <Box flex="2" textAlign="right" mr="1" fg="faded">
                        Previously Seen Spreadsheet Values
                    </Box>
                    <Box flex="3" textAlign={canEdit ? 'center' : 'left'} mr="1" fg="faded">
                        <Box ml={canEdit ? "0" : "1"}>Map To</Box>
                    </Box>
                </FlexVCenter>
                { unmentioned.map(item => (
                    <TransEnumItem
                        canEdit={canEdit}
                        key={item.raw}
                        item={item}
                        canBeBlank={!item.defaultTranslation.val}
                        enums={enums}
                        getLabel={getLabel}
                        onChange={x => onEnumValChange(item, x)}
                    />
                ))}
            </Box>
        )}

    </>
}