export const RETURNING = `
    id
    modelType
    tapeId
    fileName
    headers
    sellerOrgId
    trace
    hash
    sequenceHash
    orderHash
    mappedHash
    templateId
`;
