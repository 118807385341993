import { zBatch } from '../z-batch';

export const debugStep = () => {
    console.log('debug step');
    zBatch.update(x => {
        return {
            ...x,
            paused: true,
            stepping: true
        }
    })    
}