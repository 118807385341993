import React from 'react';
import { Box, FlexVCenter, Icon }  from 'mui';
import { CheckItem } from 'raccoon-engine';
import { ExpContext } from 'exp';
import { ChecklistTableExp } from './checklist-table-exp';
import { ConclusionItem } from './conclusion-item';
import { ConclusionDict } from './types';

interface Props {
    readonly item:CheckItem;
    readonly setNHover:(x:number) => void;
    readonly onSelect:(item:CheckItem) => void;
    readonly n:number;
    readonly nHover:number;
    readonly confirmClone:(item:CheckItem, itemIndex:number) => void;
    readonly expCtx:ExpContext;
    readonly sampleData:any;
}
export const ChecklistTableRow = ({ item, setNHover, n, onSelect, nHover, confirmClone, expCtx, sampleData }:Props) => {

    const hasConclusion = item.conclusion && Object.keys(item.conclusion).length > 0;
    const hasElseConclusion = item.elseConclusion && Object.keys(item.elseConclusion).length > 0;
    const hasEitherConclusion = hasConclusion || hasElseConclusion;


    const conclusions = item.conclusion ? Object.entries(item.conclusion as ConclusionDict) : [];
    const elseConclusions = item.elseConclusion ? Object.entries(item.elseConclusion as ConclusionDict) : [];

    return (
        <tr key={item.id} className="valign-top">
            <td style={{ width: '12%', paddingRight:0 }}>
                <FlexVCenter
                    justifyContent="space-between"
                    style={{ whiteSpace:'nowrap' }}
                    onMouseOver={() => setNHover(n)}
                    onMouseOut={() => setNHover(-1)}
                >
                    <Box
                        mt="half"
                        fgHover="link"
                        cursor="pointer"
                        onClick={() => onSelect(item)}
                    >{ item.id }</Box>
                    <Box visibility={n === nHover ? 'visible' : 'hidden'}>
                        <Icon
                            ml="half"
                            title="Clone Item"
                            name="copy"
                            cursor="pointer"
                            fg="default"
                            fgHover="loud"
                            onClick={() => confirmClone(item, n)}
                        />
                    </Box>
                </FlexVCenter>
            </td>
            <td>
                { item.preconditions && item.preconditions.map((expStr, n) => {
                    if (n === item.preconditions!.length - 1) {
                        // Only last one should have the border below it.
                        return (
                            <FlexVCenter
                                key={n}
                                borderColor="alert"
                                borderSides="bottom"
                                borderWidth="thin"
                                pb="1"
                                mb="1"
                            >
                                <ChecklistTableExp
                                    expStr={expStr}
                                    innerLabel="Precondition"
                                    expCtx={expCtx}
                                    runData={sampleData}
                                />
                            </FlexVCenter>
                        )
                    } else {
                        return (
                            <ChecklistTableExp
                                key={n}
                                expStr={expStr}
                                innerLabel="Precondition"
                                expCtx={expCtx}
                                runData={sampleData}
                            />
                        )
                    }
                }) }

                { item.expressions.map((expStr, n) => (
                    <ChecklistTableExp
                        key={n}
                        expStr={expStr}
                        expCtx={expCtx}
                        runData={sampleData}
                    />
                )) }

                { hasEitherConclusion && (
                    <Box
                        borderColor="alert"
                        borderSides="top"
                        borderWidth="thin"
                        pt="1"
                        mt="1"
                    >
                        { conclusions.map(([ id, val ]) => (
                            <ConclusionItem key={id} id={id} val={val} expCtx={expCtx} />
                        )) }
                        { elseConclusions.map(([ id, val ]) => (
                            <FlexVCenter key={id}>
                                <Box fontSize="sm" fg="faded" mr="half" textStyle="italic">else</Box>
                                <ConclusionItem id={id} val={val} expCtx={expCtx} />
                            </FlexVCenter>
                        )) }
                    </Box>
                ) }

            </td>
        </tr>
    )
}