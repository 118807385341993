import React from 'react';
import { Box, FlexVCenter } from 'mui';

export const PreLoginFooter = () => {
    return (
        <footer style={{ position:'fixed', bottom: 0, left: 0, right:0 }}>
            <FlexVCenter fg="faded" fontSize="xs" height="top-nav">
                <Box width="100%" textAlign="center">Copyright 2020 © mTrade, LLC. All rights reserved.</Box>
            </FlexVCenter>
        </footer>
    )
}