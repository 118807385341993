import { useRef, useEffect } from 'react';

/*
    Very handy.

    Example:

    const [ abc, setAbc ] = useState<Whatever>();
    const lastAbc = usePrevious(abc);

    // lastAbc will always be the previous value for every render.
    // This is really useful for animations where you wanan do something
    // only the first render something has changed.

*/

export const usePrevious = <T>(value:T) => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    })
    return ref.current;
}