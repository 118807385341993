import React, { useState } from 'react';
import { useApiFetch } from 'hooks';
import { fetchAllInvestors } from 'api';
import {
    SpinLabel,
    Table,
    TableHeader,
    Alert,
    IconButton,
    Button,
    IntoPortal,
    FlexVCenter,
    Box,
    Input,
    modal
} from 'mui';
import { InvestorModal } from './investor.modal';
import { GQL } from 'market-dto';

export const Investors = () => {

    const [ filter, setFilter ] = useState<string>('');
    const { loading, data:orgs, loadWithoutBusyStates:refresh } = useApiFetch(fetchAllInvestors, []);
    if (loading) return <SpinLabel>Loading...</SpinLabel>
    if (!orgs) return null;


    const edit = (org:GQL.Org) => {
        modal.open({
            render: <InvestorModal org={org} onSave={refresh}/>,
            title: 'Investor Info'
        })
    }

    const openAddSellerModal = () => {
        modal.open({
            render: <InvestorModal org={null} onSave={refresh}/>,
            title: 'Investor Info'
        })
    }

    const headers:TableHeader[] = [
        { label: "Name", alignHeader: "left", alignData: "left" },
        { label: "Aliases", alignHeader: "left", alignData: "left" },
        { label: "", alignHeader: "left", alignData: "right" }
    ];
    const rows = orgs.filter(x => {
        return x.name.toLowerCase().includes(filter.toLowerCase());
    }).map(x => {
        return [
            x.name,
            x.aliases.join(', '),
            <IconButton name="edit" size="sm" onClick={() => edit(x)} />
        ]
    });

    return (
        <>
            <IntoPortal id="desk-portal">
                <FlexVCenter height="btn-row">
                    { orgs.length > 0 && (
                        <>
                            <Box fg="default" mr="1">{ rows.length } Investor{rows.length === 1 ? '' : 's'}</Box>
                            <Box mr="1">
                                <Input
                                    appendIcon="search"
                                    placeholder="Search..."
                                    value={filter}
                                    setValue={setFilter}
                                />
                            </Box>
                        </>
                    )}
                    <Button type="default" icon="plus" size="sm" onClick={openAddSellerModal}>Add Investor</Button>
                </FlexVCenter>
            </IntoPortal>

            { orgs.length === 0 && <Alert>No Investors Found!</Alert> }
            { orgs.length > 0 && <Table headers={headers} rows={rows} /> }

        </>
    )
}
