import React from 'react';
import { GQL, getModelByModelType } from 'market-dto';
import { EligibilityClassifier, ProgramQualifier } from './eligibility';
import { PricingModule } from './pricing';
import { XlsExport } from './xls-export';
import { useParams } from 'react-router-dom';
import { Box } from 'mui';
import { NavBlocks, NavBlockItem, flowToNavBlockItems } from '../../../blocks';

interface TaskViewProps {
    readonly task:GQL.FlatTask;
    readonly flow:GQL.Flow;
    readonly flowRun:GQL.FlowRun;
}
const TaskView = ({ flow, task, flowRun }:TaskViewProps) => {
    switch (task.taskKind) {
        case "xlsExport": return <XlsExport task={task} model={getModelByModelType(flow.modelType)} flowRun={flowRun} />
        case "programQualifier": return <ProgramQualifier task={task} />
        case "eligibilityClassifier": return <EligibilityClassifier task={task} />
        case "pricingModule": return <PricingModule flowRun={flowRun} task={task} />
    }
    return <>Unhandled Task Kind</>
}

interface Props {
    readonly flow:GQL.Flow;
    readonly flowRun:GQL.FlowRun;
}
export const Tasks = ({ flow, flowRun }:Props) => {

    const { taskIndex } = useParams();
    const n = Number.parseInt(taskIndex);
    // n starts at 1
    if (isNaN(n) || n < 1 || flow.tasks.length < n) {
        return <>Task Not Found</>
    }
    const task = flow.tasks[n-1]; // starts at 1
    const blockItems = flowToNavBlockItems(flow);

    return (
        <>
            { flow.tasks.length > 1 && (
                <Box borderSides="bottom" borderWidth="thin" borderColor="alert" pb="1" mb="1">
                    <NavBlocks items={blockItems} showDebug={false} setShowDebug={() => {}} />
                </Box>
            ) }
            <TaskView task={task} flow={flow} flowRun={flowRun} />
        </>
    )

}