import React, { useState } from 'react';
import { FlexVCenter, Box, SortableTableHeader, TablePageButtons, usePageSortTable, Flex } from 'mui';
import { GQL, labels } from 'market-dto';
import { pricing, fannie, freddie, format } from 'services';
import './compare.css';

const isValidOffer = (offer:GQL.Offer) => fannie.isValidFannieOffer(offer) || freddie.isValidFreddieOffer(offer);

const hasValidOffers = (loan:GQL.Loan) => {
    if (!loan.offers) return false;
    return loan.offers.some(x => isValidOffer(x));
}

const getInvestorName = (offer:GQL.Offer):string => {
    if (fannie.isValidFannieOffer(offer)) return 'Fannie';
    if (freddie.isValidFreddieOffer(offer)) return 'Freddie';
    return 'Unknown';
}


interface InvestorProps {
    readonly name:string|null;
}
const Investor = ({ name }:InvestorProps) => {
    if (name === 'Fannie') return <Box css="fannie">Fannie</Box>
    if (name === 'Freddie') return <Box css="freddie">Freddie</Box>
    return <></>
}

interface CompareRow {
    readonly loanId:string;
    readonly bestOffer:number;
    readonly bestInvestor:string;
    readonly delta:number|null;
    readonly secondBestOffer:number|null;
    readonly secondBestInvestor:string|null;
}

interface Props {
    readonly sheet:GQL.LoanModelSheet;
}
export const CompareModal = ({ sheet }:Props) => {

    const [ rows ] = useState<CompareRow[]>(() => {
        // Let's put it in row/col 2d array as we'd like
        return sheet.loans.filter(loan => hasValidOffers(loan)).map(loan => {
            const validOffers = loan.offers!.filter(x => isValidOffer(x));
            const sortedOffers = validOffers.sort((a, b) => b.offeringPrice - a.offeringPrice);
            const hasTwo = sortedOffers.length > 1;
            return {
                loanId: loan.id,
                bestOffer: sortedOffers[0].offeringPrice,
                bestInvestor: getInvestorName(sortedOffers[0]),
                delta: hasTwo ? sortedOffers[0].offeringPrice - sortedOffers[1].offeringPrice : null,
                secondBestOffer: hasTwo ? sortedOffers[1].offeringPrice : null,
                secondBestInvestor: hasTwo ? getInvestorName(sortedOffers[1]) : null
            }
        })
    })

    const { sortBy, asc, buttons, page, totalPages, setPage, sortedBy, sortedRows, pageSortState:ps } = usePageSortTable<CompareRow, keyof CompareRow>({
        rows: rows,
        keyFn: row => row.loanId,
        valFn: (row, colId) => row[colId]
        // paging: {
        //     maxButtons: 9,
        //     maxRowsPerPage: 20
        // }
    })

    return (
        <Box mt="2">
            <table className="table table-sm">
                <thead>
                    <tr>
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="loanId" render={labels.byLoanField('id')} />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="bestInvestor" render="Investor" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="bestOffer" render="Offer Price" />
                        {/* <SortableTableHeader ps={ps} sortBy={sortBy} colId="delta" render="Difference" /> */}
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="secondBestInvestor" render="Investor" />
                        <SortableTableHeader ps={ps} sortBy={sortBy} colId="secondBestOffer" render="Offer Price" />
                        
                    </tr>
                </thead>
                <tbody>
                    { sortedRows.map((row, n) => (
                        <tr key={row.loanId}>
                            <td>{ row.loanId }</td>
                            <td><Investor name={row.bestInvestor} /></td>
                            <td>
                                <FlexVCenter>
                                    <Box>{ format.toFourDecimals(row.bestOffer) }</Box>
                                    { row.delta && (
                                        <Box fg="success" fontSize="sm" ml="1">
                                            +{ format.toFourDecimals(row.delta) }
                                        </Box>
                                    ) }
                                </FlexVCenter>
                            </td>
                            {/* <td>{ format.toFourDecimals(row.delta) }</td> */}
                            <td><Investor name={row.secondBestInvestor} /></td>
                            <td>{ format.toFourDecimals(row.secondBestOffer) }</td>
                            
                        </tr>
                    )) }
                </tbody>
            </table>
    
            {/* <TablePageButtons
                buttons={buttons}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            /> */}
        </Box>
    )
}
