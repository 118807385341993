import { mutate, gql, GQL, stripInternals } from "../../client";

const UDPATE = gql`
    mutation updateUser($set: user_set_input, $where: user_bool_exp!) {
        update_user(set: $set, where: $where) {
            errorMessage
            affectedRow
        }
    }
`;

interface MutateInput {
    readonly set:Partial<GQL.User>;
    readonly where:GQL.WhereString;
}

// interface MutateOutput {
//     readonly user:GQL.User;
// }

export const updateUser = async (userId:string, user:Partial<GQL.User>) => {
	await mutate<MutateInput, any>(UDPATE, {
        set: stripInternals(user),
        where: {
            id: {
                _eq: userId
            }
        }
    })
}