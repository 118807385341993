import React, { useState } from 'react';
import { useZState } from "hooks";
import { CardTitle, Card, Box, FrmInput, Button } from 'mui';
import { useNavigate } from "react-router-dom";
import { Session } from './login';
import { user } from 'services';


interface Props {
    readonly loginData:Session;
    readonly reset:() => void;
}
export const EnterToken = ({ loginData, reset }:Props) => {

    const [ token, setToken ] = useState('');
    const navigate = useNavigate();
    const back = () => reset();
    const close = () => navigate("/");

    const { load:login, fetching:busy } = useZState(user.zUser);

    const submit = async () => {
        login(loginData.sessionId, token); // too bad TS couldn't figure out args.
    }

    return (
        <Card mx="auto" width="card-md">

            <CardTitle onClose={close} icon="user">Sign In - Step 2</CardTitle>

            <FrmInput
                cy="enter-token"
                width="100%"
                label="Enter the token you just received!"
                placeholder="Token"
                asPassword={true}
                autoFocus={true}
                value={token}
                setValue={setToken}
                onEnter={submit}
            />

            <Box mt="2">
                <Button busy={busy} onClick={submit}>Continue</Button>
                &nbsp;
                <Button onClick={back} type="link">Re-enter Email Address</Button>
            </Box>

        </Card>
    );
}