import React from 'react';
import { GQL } from 'market-dto';
import { Card, Box, Button, FlexVCenter } from 'mui';
import { useApiFetch } from 'hooks';
// import  { importSheet } from 'api';
import { sheets } from 'services';

interface Props {
    readonly sheet:GQL.Sheet;
}
export const Advanced = ({ sheet }:Props) => {

    // const { loading:isReRunning, load:reRun } = useApiFetch<GQL.SheetSummary>(() => importSheet(sheet.id), null, sheets.zCurrent.refresh);

    return (
        <Box mt="2" >
            {/* <Card mb="2">
                <FlexVCenter>
                    <Box flex="1" textAlign="right" fontSize="lg">Have you made changes to the eligibilty rules?</Box>
                    <Box flex="1">
                        <Button ml="2" busy={isReRunning} onClick={reRun}>Re-run Eligibility Rules</Button>
                    </Box>                    
                </FlexVCenter>                
            </Card> */}
            <Card>
                <FlexVCenter>
                    <Box flex="1" textAlign="right" fontSize="lg">Was this tape imported in error?</Box>
                    <Box flex="1">
                        <Button ml="2" type="danger">Flag this tape for deletion</Button>
                    </Box>                    
                </FlexVCenter>
            </Card>
        </Box>
    )
}