import { ExpFunctionDef } from './exp-types';

const funcs:ExpFunctionDef[] = [
    {
        name: "oneOfThese",
        returnType: "boolean",
        args: [["string", "number"]],
        restArgs: ["string", "number"],
        evalFn: (args:any[]) => {
            if (args.length < 2) throw new Error('Expected at least two args');
            const val = args[0];
            const arr = args.slice(1);
            return arr.some(x => x == val);
        }
    },
    {
        name: "plus1",
        returnType: "number",
        args: [["number"]],
        evalFn: (args:any[]) => {
            if (args.length !== 1) throw new Error('Expected ONE arg');
            const val = args[0];
            return Number(val)+1;
        }   
    },
    {
        name: "getRandom",
        returnType: "number",
        args: [], // ZERO ARGS
        evalFn: (args:any[]) => {
            if (args.length > 0) throw new Error('Expected ZERO args');
            return Math.random();
        }   
    }
]

export const getFuncs = () => funcs;
    