import React, { useState, useEffect } from 'react';
import { Button, modal, Box, FlexVCenter, Flex, Checkbox, CssGridBox, Text, Dropdown, DropdownItem, LabelValueGrid } from 'mui';
import { utils } from 'services';
import { addMonths } from 'date-fns'
import { useApiUpdate } from 'hooks';
import * as api from 'api';
import { GQL, format as fmt } from 'market-dto';
import { DaysOfWeek, NthOfMonth, dayLabels } from './types';
import { NthOfMonthOption } from './nth-of-month-option';
import { getMatchingDates } from './get-matching-dates';


const getFirstOfMonthXMonthsFromNow = (x:number) => {
    // returns the 1st of the month x months from current month
    const today = new Date();
    const firstOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return addMonths(firstOfCurrentMonth, x);
}

interface PopulateModalProps {
    readonly refresh:() => void;
}
export const PopulateModal = ({ refresh }:PopulateModalProps) => {

    const { busy, update } = useApiUpdate(async () => {
        const dates = getMatchingDates(daysOfWeek, nthOfMonth, endAt);
        const cals = dates.map((dt:Date):Partial<GQL.SettlementCalendar> => {
            return {
                classA: true,
                classB: true,
                day: fmt.toDayAbbrev(dt),
                week: fmt.toWeekOfMonth(dt),
                date: fmt.toISO8601DateOnly(dt)
            }
        })
        modal.disableClose();
        await api.upsertSettlementCalendar(cals);
        // await Promise.allSettled(cals.map(cal => api.upsertSettlementCalendar(cal)));
        refresh();
        modal.close();
    })

    const endingAt:DropdownItem[] = utils.range(13).map(n => {
        const dt = getFirstOfMonthXMonthsFromNow(n);
        return {
            label: dt.toLocaleString('default', { month: 'short' }) + ' ' + dt.getFullYear(),
            value: fmt.toISO8601DateOnly(dt)
        }
    })
    endingAt.reverse();

    const [ daysOfWeek, setDaysOfWeek ] = useState<DaysOfWeek>({
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false
    })

    const [ nthOfMonth, setNthOfMonth ] = useState<NthOfMonth>({
        first: false,
        second: false,
        third: false,
        fourth: false
    })

    const [ endAt, setEndAt ] = useState(() => endingAt[0].value);

    const canSubmit = Object.entries(daysOfWeek).find(arr => arr[1])
        && Object.entries(nthOfMonth).find(arr => arr[1])

    const toggleDay = (x:keyof DaysOfWeek, val:boolean) => {
        setDaysOfWeek({
            ...daysOfWeek,
            [x]:val
        })
    }

    const toggleNthOfMonth = (x:keyof NthOfMonth, val:boolean) => {
        setNthOfMonth({
            ...nthOfMonth,
            [x]:val
        })
    }

    const submit = () => {
        if (!canSubmit) return;
        update();
    }

    return (
        <Box mt="2">
            <Box>
                Use this tool to auto-populate your <Text fg="loud">Settlement Calendar</Text>.
            </Box>

            <Box fontSize="sm" fg="faded" my="2">
                Select the day (or days) of the week, and the week (or weeks) of the month from which we will auto-populate your 
                calendar. Once populated, you can modify or remove any of the dates generated.
            </Box>

            <CssGridBox justifyContent="space-evenly" cols={5} sameSize mb="2">
                <Checkbox label={dayLabels['mon']} checked={daysOfWeek.mon} onToggle={x => toggleDay('mon', x)} />
                <Checkbox label={dayLabels['tue']} checked={daysOfWeek.tue} onToggle={x => toggleDay('tue', x)} />
                <Checkbox label={dayLabels['wed']} checked={daysOfWeek.wed} onToggle={x => toggleDay('wed', x)} />
                <Checkbox label={dayLabels['thu']} checked={daysOfWeek.thu} onToggle={x => toggleDay('thu', x)} />
                <Checkbox label={dayLabels['fri']} checked={daysOfWeek.fri} onToggle={x => toggleDay('fri', x)} />
            </CssGridBox>

            <LabelValueGrid>
                <NthOfMonthOption
                    label="1st"
                    checked={nthOfMonth.first}
                    onToggle={x => toggleNthOfMonth('first', x)}
                    daysOfWeek={daysOfWeek}
                />
                <NthOfMonthOption
                    label="2nd"
                    checked={nthOfMonth.second}
                    onToggle={x => toggleNthOfMonth('second', x)}
                    daysOfWeek={daysOfWeek}
                />
                <NthOfMonthOption
                    label="3rd"
                    checked={nthOfMonth.third}
                    onToggle={x => toggleNthOfMonth('third', x)}
                    daysOfWeek={daysOfWeek}
                />
                <NthOfMonthOption
                    label="4th"
                    checked={nthOfMonth.fourth}
                    onToggle={x => toggleNthOfMonth('fourth', x)}
                    daysOfWeek={daysOfWeek}
                />
            </LabelValueGrid>

            <CssGridBox mt="3" cols={2} justifyContent="center" alignItems="center" gap="1" rowGap="half">
                <Box textAlign="right">Every Month Through</Box>
                <Dropdown
                    size="sm"
                    width="frm-sm"
                    value={endingAt.find(x => x.value === endAt)}
                    setValue={setEndAt}
                    items={endingAt}
                />
                <div>&nbsp;</div>
                <Box fg="faded" fontSize="sm">The month selected <b>will</b> be included</Box>
            </CssGridBox>


            <FlexVCenter mt="3" justifyContent="flex-end">
                <Button type="link" onClick={modal.close}>Cancel</Button>
                <Button
                    ml="1"
                    type={canSubmit ? 'default': 'disabled'}
                    onClick={submit}
                    busy={busy}
                >Submit</Button>
            </FlexVCenter>
        </Box>
    )
}