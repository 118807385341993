import { ApolloQueryResult, DocumentNode } from 'apollo-boost';
import { ApiCallOptions } from './types'
import { getApolloClient } from './get-apollo-client';
import { withArtificialDelay } from './with-artificial-delay';
import { catchCommonErrors } from './catch-common-errors';

export const query = <Input, Output>(gql:DocumentNode, input:Input, opts?:ApiCallOptions):Promise<ApolloQueryResult<Output>> => {
	const client = getApolloClient(opts?.withCache ? true : false, opts?.useThisJwtInstead);
	const prom = client.query<Output, Input>({
		query: gql,
		variables: input
    })
    // TODO: fix this typing. It's madness.
    // query can return undefined (on error) so we need to handle that with types.
    return catchCommonErrors(withArtificialDelay(prom, opts)) as Promise<ApolloQueryResult<Output>>;
}