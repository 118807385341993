import { ImportModel, TakeFromSrcMap, SrcMap, DeriveEnumInSrcMap, ModelItem } from 'market-dto';
import { WhenEnumInDetails, WhenEnumInResult } from './types';

const getTakeLabel = ( model:ImportModel, whenLabel:string, takeFrom:TakeFromSrcMap):string => {
    if ("fieldId" in takeFrom) {
        return model.getField(takeFrom.fieldId).label;
    }
    if ("enumItself" in takeFrom) {
        return whenLabel;
    }
    return 'Psuedo-field'; // TODO: explain this somehow
}


export const getWhenEnumInDetails = (
    field:ModelItem.AnyType,
    model:ImportModel,
    derive:DeriveEnumInSrcMap,
    sourceMap:SrcMap[]
):WhenEnumInDetails => {
    // the one that matched...get the source val for it
    const whenCfg = model.getField(derive.whenEnum.fieldId);
    if (!whenCfg || whenCfg.kind !== 'enum') throw new Error('expected when field to exist as enum kind');
    // NOTE: field is not necessarily an enum!    
    
    const whenEnumLabels = whenCfg.labels;
    const whenValuesLookingFor = derive.whenEnum.valueIn.map(x => '"' + whenEnumLabels[x] + '"');
    const whenLabel = whenCfg.label;
    const whenSrcMap = sourceMap.find(x => x.id === derive.whenEnum.fieldId);
    const { val:whenValSrc } = whenSrcMap ?? {};
    if (!whenValSrc) { //  || whenValSrc.trans === undefined
        // the whenIn src fieldId was NOT MAPPED!
        return {
            whenLabel,
            whenEnumLabels,
            whenValuesLookingFor,
            result: "WHEN_IN_FAILED_TO_MAP"
        }
    }
    
    const { takeFrom, val } = derive;
    const whenVal = whenCfg.labels[whenValSrc.trans]; // MUST BE INTERNAL VAL

    if (!takeFrom) {
        // When source map have mapped, but, failed to pass the condition.
        return {
            whenLabel,
            whenEnumLabels,
            whenValuesLookingFor,
            whenVal,
            whenValRaw: whenValSrc.raw,
            result: "WHEN_IN_MAPPED_BUT_VAL_NOT_IN"
        }
    }

    // You get here, you have passed the WHEN, now, eval the takeFrom
    const takeLabel = getTakeLabel(model, whenLabel, takeFrom);

    if (!val) {
        return {
            whenLabel,
            whenEnumLabels,
            whenValuesLookingFor,
            whenVal,
            whenValRaw: whenValSrc.raw,
            takeLabel,
            result: "TAKE_FROM_FAILED_TO_MAP"
        }
    }
    if (val.trans === undefined) {

        const couldHaveBeen = field.kind === "enum" && val.src?.couldHaveBeen ? val.src.couldHaveBeen.map(x => '"' + field.labels[x] + '"') : undefined;
        return {        
            whenEnumLabels,
            whenValuesLookingFor,
            whenLabel,
            whenVal,
            whenValRaw: whenValSrc.raw,
            takeLabel,
            takeVal: val.raw,
            result: "TAKE_FROM_MAPPED_BUT_NOT_GOOD_ENOUGH",
            couldHaveBeen
            // ...val.src?.couldHaveBeen && { couldHaveBeen: val.src.couldHaveBeen.map(x => '"' + field.labels[x] + '"') }
        }
    }
    const finalVal = field.kind === "enum" ? field.labels[val.trans] : val.trans;
    return {        
        whenEnumLabels,
        whenValuesLookingFor,
        whenLabel,
        whenVal,
        whenValRaw: whenValSrc.raw,
        takeLabel,
        takeVal: val.raw,
        finalVal,
        // finalVal: field.labels[val.trans],
        result: "SUCCESS",
        defaultUsed: val.src?.defaultUsed
    }
}