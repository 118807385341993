import React from 'react';
import { Link, Box, Format, modal } from 'mui';
import { LogModal } from './log-modal';
import { PriceTag } from 'market-dto';

interface ParentPriceTagProps {
    readonly tag:PriceTag;
    readonly isLast:boolean;
}
export const ParentPriceTag = React.memo(({ tag, isLast }:ParentPriceTagProps) => {

    const hasLog = tag.logs && tag.logs.length > 0;
	const clk = () => modal.open({
		render: <LogModal tag={tag} />,
		title: tag.name + ' - details',
        size: "lg",
        noHeaderBg: true
    })

    return (
        <Box
            p="1"
            style={{ gridColumnEnd: 'span 2' }}
            borderColor={ isLast ? 'transparent' : 'inner-card-bg' }
            borderSides="right"
            borderWidth="thin"
            borderStyle="dashed"
        >
            <Box fg="default" fontSize="md">
                <Format as="4_decimals">{ tag.price }</Format>
            </Box>
            <Box fg="faded" fontSize="sm" ucase>
                {/* { tag.name } */}
                { hasLog && <Link onClick={clk}>{ tag.name }</Link> }
                { !hasLog && tag.name }
            </Box>
        </Box>
    )
})