import React, { useRef } from 'react';
import { Button, ButtonProps } from '../button/button';

interface Props extends ButtonProps {
    readonly onSelect:(files:Array<File>) => any;
    readonly multiple?:boolean;
    readonly accept?:string;
}
export const FileSelectButton = ({ onSelect, multiple, accept, ...rest }:Props) => {

    const fileInput = useRef<HTMLInputElement>(null);
    // Remember, e.target.files is not a true array (although it feels like one) so, convert it
    const onFileUpload = (e:any) => onSelect(Array.from(e.target.files));
    const upload = () => {
        if (fileInput && fileInput.current) fileInput.current.click();
    }

    const setToEmpty = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // This is a browser hack to so that onchange fires even if they pick the SAME file back-to-back.
        // This has nothing to do with react. It's a browser thang.
        // If upload same file back-to-back, second time, you'd get nothing...without this.
        e.currentTarget.value = '';
    }

    return (
        <>
            <Button onClick={upload} {...rest} />
            <input
                ref={fileInput}
                hidden
                type="file"
                onChange={onFileUpload}
                onClick={setToEmpty}
                multiple={multiple}
                accept={accept}
            />
        </>
    );
}