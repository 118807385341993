import { CashWindow, GQL } from "market-dto"
import { composeOptimumParam } from './compose-optimum-param';

export const exploreOptions = (offer:GQL.Offer, loan:Partial<GQL.Loan>, dailyPrices:CashWindow.ProductPriceSheet) => {
	const optimumParam = composeOptimumParam(offer, loan, dailyPrices);
	const minLeadTime = 7;
	const explorer = new CashWindow.LockAndDeliver(optimumParam, minLeadTime);
	const paramSet = explorer.produceParamSet();
	const deliverySpace = paramSet.map(param => {
		const optimizer = new CashWindow.BestExecution(param);
		return optimizer.populate();
	})
	return deliverySpace;
}