import { query, gql } from "../../client";
import { Eligible } from 'raccoon-engine';

interface SimpleEligibleResult {
    readonly simpleEligible: [{
        readonly rules:Eligible[];
    }]
}

const QUERY = gql`
    query {
        simpleEligible {
            rules
        }
    }
`;

export const fetchSimpleRules = async ():Promise<Eligible[]> => {
    const result = await query<any, SimpleEligibleResult>(QUERY, {});
    return result.data.simpleEligible[0].rules;
}
