import React from 'react';
import { Box } from '../box/box';
import { FlexVCenter } from '../flex/flex';
import { Input, InputProps } from '../input';
import { SpacingSize, InputWidth } from '../../types';

export interface FrmInputProps extends InputProps {
    readonly label:string;
    readonly desc?:string;
    readonly subLabel?:string; // these flip flop colors as normal label+desc
    readonly subDesc?:string;
    readonly mt?:SpacingSize;
    readonly rightSide?:React.ReactNode;
}

export const FrmInput = ({
    label,
    desc,
    subLabel,
    subDesc,
    rightSide,
    mt = "1",    // To override (set to zero) the margin on first item in a card
    ...props
}:FrmInputProps) => {

    const labelMl:SpacingSize = props.size === "sm" ? "half" : "1";
    const controlMt:SpacingSize = props.size === "sm" ? "half" : "1";
    const w:InputWidth = props.width ?? (props.size === 'md' ? "frm-md" : "frm-sm");

    return (
        <Box mt={mt} mb="1">

            <FlexVCenter ml={labelMl}>
                <Box fg="form-label" fontSize="md">{ label }</Box>
                { desc && <Box fontSize="sm" fg="form-desc" ml="1">{ desc }</Box> }
            </FlexVCenter>

            { (subLabel || subDesc) && (
                <FlexVCenter ml={labelMl} fg="form-label" fontSize="sm" textStyle="italic">
                    { subLabel && <Box mr="1" fg="form-desc">{ subLabel }</Box> }
                    { subDesc && <Box fg="form-label">{ subDesc }</Box> }
                </FlexVCenter>
            ) }

            <FlexVCenter mt={controlMt}>
                <Input
                    visibleWhenReadOnly
                    align="left"
                    width={w}
                    {...props}
                />
                { rightSide && <Box ml="1">{ rightSide }</Box> }
            </FlexVCenter>
        </Box>
    )
}

