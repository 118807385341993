import { mutate, query, gql, GQL } from "../../../client";

const MUTATE = gql`
    mutation uninstallFlow($flowId: String) {
        sync_uninstall_flow(flowId: $flowId)
    }
`;

interface UpsertOutput {
    readonly sync_uninstall_flow:GQL.Flow;
}

interface UpsertInput {
    readonly flowId:string;
}
export const uninstallFlow = async (flow:Partial<GQL.Flow>):Promise<boolean> => {
    // why a partial? to work smoothly with zstate list.
    const { id } = flow;
    if (!id) throw new Error('flow.id is requried');
    const result = await mutate<UpsertInput, UpsertOutput>(MUTATE, { flowId:id });
    console.log('UNINSTALL FLOW', result);
    return result ? true : false;
}
