import React from 'react';
import { Box, Text, FlexVCenter, Link, Icon, useIsRouteActive, useIsAnyRouteActive, FgColor } from 'mui';
import { NavBlockItem } from './types';
import { NavLink as RouterNavLink, useNavigate, useLocation, matchPath, resolvePath } from 'react-router-dom';
import { useResolvedPath, useMatch } from 'react-router-dom';





/*
export function useMatch(pattern: PathPattern): PathMatch | null {
  invariant(
    useInRouterContext(),
    // TODO: This error is probably because they somehow have 2 versions of the
    // router loaded. We can help them understand how to avoid that.
    `useMatch() may be used only in the context of a <Router> component.`
  );

  let location = useLocation() as Location;
  return matchPath(pattern, location.pathname);
}
*/



interface BlockChildLinkProps {
    readonly label:React.ReactNode;
    readonly to:string;
    readonly exact?:boolean;
}
export const BlockChildLink = ({ label, to, exact }:BlockChildLinkProps) => {
    const active = useIsRouteActive(to, exact);
    console.log('block child link', label, to, exact);
    return (
        <FlexVCenter
            fontSize="xs"
            fg={active ? 'loud' : 'link'}
            fgHover={active ? 'loud' : 'link'}
            cursor={active ? 'default' : 'pointer'}
        >
            {/* visibility hidden on icon to preserve width taken! */}
            <Icon name="chevron-right" visibility={active ? 'visible' : 'hidden'} />
            { active && <Text>{ label }</Text> }
            { !active && <RouterNavLink to={to}>{ label }</RouterNavLink> }            
        </FlexVCenter>
    )
}

const getLabelFgColor = (active:boolean, childCount:number):FgColor => {
    if (childCount === 0) return 'faded';
    if (childCount !== 1) return 'default';
    return active ? 'loud' : 'link';
}

interface NavBlockProps {
    readonly item:NavBlockItem;
    readonly n:number;
    readonly showArrow:boolean;
}
export const NavBlock = ({ item, n, showArrow }:NavBlockProps) => {

    const ronly = item.childItems.length === 0;
    const onlyOne = item.childItems.length === 1;

    const { baseRoute } = item;
    const routeActive = useIsAnyRouteActive(baseRoute, item.childItems.map(child => child.id));

    const nav = useNavigate();

    const loadFirst = () => {
        nav(baseRoute + '/' + item.childItems[0].id);
    }
    const active = routeActive && !ronly;

    return (
        <>
            <Box
                onClick={onlyOne ? loadFirst : undefined}
                cursor={onlyOne ? "pointer" : undefined}
                bg={active ? 'inner-card' : 'card'}
                bgHover={onlyOne ? "inner-card" : undefined}
                borderColor={active ? 'alert' : 'transparent'}
                borderSides="all"
                borderWidth="thin"
                borderStyle="solid"
                style={{ width:'140px', height: '90px' }}
                p="1"
                m="1"
                fontSize="sm"
                roundedEdges="all"
                justifyContent="center"
            >
                <FlexVCenter mb="half">
                    <Text fg="faded">{n+1}.</Text>
                    &nbsp;
                    <Text fg={getLabelFgColor(active, item.childItems.length)}>{ item.label }</Text>
                </FlexVCenter>
                { item.childItems.length > 1 && (
                    <>
                        { item.childItems.map(child => (
                            <BlockChildLink
                                key={child.id}
                                label={child.label}
                                to={baseRoute + '/' + child.id}
                            />
                        )) }
                    </>
                ) }

            </Box>
            { showArrow && <Icon name="arrow-right" fg="faded" /> }
        </>
    )
}

interface NavBlocksProps {
    readonly items:NavBlockItem[];
    readonly showDebug:boolean;
    readonly setShowDebug:(x:boolean) => void;
}
export const NavBlocks = ({ items, showDebug, setShowDebug }:NavBlocksProps) => {
    return (    
        <>
            <FlexVCenter>
                { items.map((item, n) => (
                    <NavBlock
                        key={n}
                        item={item}
                        n={n}
                        showArrow={n < items.length-1}
                    />
                )) }
                {/* <Box fontSize="sm" onClick={() => setShowDebug(!showDebug)} fg="link" fgHover="loud" style={{ maxWidth:'50px'}}>
                    { showDebug ? 'hide debug area' : 'show debug area' }
                </Box> */}
            </FlexVCenter>
        </>
    )
}