export const RETURNING = `
    id
    lastName
    firstName
    email
    eulaVersion
    phone
    profilePhoto
    roles
    orgId
    active
`;