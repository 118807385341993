export const nobodyUses = true;

// import { DocType, AusType, RateType, LoanType, SpecialtyProgram, PropertyType } from 'market-dto';

// export type EligibilityRequiredField = "dti"|"ltv"|"cltv";

// export interface EligibilityFormData {
//     readonly specialtyPrograms:SpecialtyProgram[];
//     readonly docTypes:DocType[];
//     readonly ausTypes:AusType[];
//     readonly loanTypes:LoanType[];
//     readonly propertyTypes: PropertyType[]
//     readonly rateTypes:RateType[];
//     readonly maxLoanAmount:number;
//     readonly maxDti:number;
//     readonly minFico:number;
//     readonly maxLtv:number;
//     readonly noteRateMultiplier:number;
//     readonly cltvGreaterLtv:boolean;
//     readonly allowTpo:boolean;
//     readonly requiredFields:EligibilityRequiredField[];
// }
