import { Overrides, GQL } from 'market-dto';
import { tapeImporter } from 'services';


interface OrideDeps {
    readonly orides:Overrides;
    readonly mappedSellerInfo:tapeImporter.MappedSellerInfo;
    readonly mappedForSellerOrg?:GQL.Org;
    readonly dirtyOrides:boolean;
}
export const setOrideDependents = (
    item:tapeImporter.BatchItem,
    initOrides:Overrides,
    orides:Overrides
):OrideDeps => {

    // Any time orides change, update exState using the partial this generates
    const { sellers, rowsAndHeaders, model } = item;
    if (!rowsAndHeaders) throw new Error('Expected rowsAndHeaders');

    const mappedSellerInfo = tapeImporter.getSellerInfoByApplyingOrides(model, rowsAndHeaders, sellers, orides);
    const { distinctSellerOrgIds } = mappedSellerInfo;
    // This is probably not what we want.
    // This will default the seller dropdown for aggs to the first one found.
    const mappedForSellerOrg = distinctSellerOrgIds.length === 1 ? item.sellers.find(s => s.id === distinctSellerOrgIds[0]) : undefined;

    return {
        orides,
        mappedSellerInfo,
        mappedForSellerOrg,
        dirtyOrides: JSON.stringify(orides) !== JSON.stringify(initOrides)
    }
}