import React, { useState, useEffect } from 'react';
import { Button, modal, Box, FlexVCenter, Icon, Flex, BoundFrmCheckbox, BoundFrmDate ,Checkbox, CssGridBox, Text, Dropdown, DropdownItem, LabelValueGrid } from 'mui';
import * as api from 'api';
import { GQL, format } from 'market-dto';
import { useApiUpdate } from 'hooks';

const initCal = (cal:GQL.SettlementCalendar|null, isNew:boolean):Partial<GQL.SettlementCalendar> => {
    if (isNew) {
        return {
            date: '',
            day: '',
            week: 0,
            classA: true,
            classB: true
        }
    } else {
        // return a copy
        if (!cal) throw new Error('expected cal');
        return {
            id: cal.id,
            day: cal.day,
            week: cal.week,
            date: cal.date,
            classA: cal.classA,
            classB: cal.classB
        }
    }
}

interface PopulateModalProps {
    readonly refresh:() => void;
    readonly isNew:boolean;
    readonly cal:GQL.SettlementCalendar|null;
}
export const EditModal = ({ isNew, cal, refresh }:PopulateModalProps) => {

    const [ editObj, setEditObj ] = useState(() => initCal(cal, isNew));

    const { busy:deleting, update:removeCal} = useApiUpdate(async () => {
        if (!editObj?.id) throw new Error('tried to delete date that does not exist');
        return await api.deleteSettlementCalendars([editObj.id]);
    })

    const { busy:upserting, update:upsertCal} = useApiUpdate(() => {
        return api.upsertSettlementCalendar([ {
            ...editObj,
            day: format.toDayAbbrev(editObj.date),
            week: format.toWeekOfMonth(editObj.date)
        }])
    })

    const confirmRemove = async () => {
        // For now, just remove it..confirm later.
        // modal.confirm({
        //     title: 'Are you sure you want to remove this date?'
        // })
        modal.disableClose();
        await removeCal();
        refresh();
        modal.close();
    }

    const update = async () => {
        modal.disableClose();
        await upsertCal();
        refresh();
        modal.close();
    }

    // cannot udpate if deleting OR new and no date yet
    const canUpdate = !deleting && !(isNew && !editObj.date);

    return (
        <Box mt="2">

            <Flex flexDirection="column" alignItems="center">
                { isNew && (
                    <BoundFrmDate
                        label="Date"
                        formatLabel={format.toDate}
                        formatVal={format.toDate}
                        bindTo={[ editObj, setEditObj, x => x.date ]}
                    />
                ) }
                <BoundFrmCheckbox
                    label="Class A"
                    bindTo={[ editObj, setEditObj, x => x.classA ]}
                />
                <BoundFrmCheckbox
                    label="Class B"
                    bindTo={[ editObj, setEditObj, x => x.classB ]}
                />
                <FlexVCenter fg="faded" fgHover="link" fontSize="sm" mt="2">
                    <Icon
                        name={deleting ? 'loader' : 'trash'}
                        spinning={deleting}
                        cursor="pointer"
                        onClick={confirmRemove}
                    />
                    <Box ml="1" fontSize="xs" cursor="pointer" onClick={confirmRemove}>Remove Date</Box>
                </FlexVCenter>
            </Flex>

            <FlexVCenter justifyContent="flex-end" mt="3">
                <Button type="link" size="sm" onClick={modal.close}>Cancel</Button>
                <Button
                    type={canUpdate ? 'default' : 'disabled'}
                    ml="1"
                    size="sm"
                    busy={upserting}
                    onClick={update}
                >{ isNew ? 'Submit' : 'Update' }</Button>
            </FlexVCenter>

        </Box>
    )
}
