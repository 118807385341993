import jwt_decode from 'jwt-decode';

// NOTE: this does NOT check if the jwt makes sense, or if the jwt is still authenticated...
// It only checks if the jwt is well-formed.

export const isWellFormedJwt = (x:string):boolean => {
    try {
        jwt_decode(x);
        return true;
    } catch {
        return false;
    }
}