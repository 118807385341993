import { GQL } from 'market-dto';

export const labels:{ [k in GQL.LlpaTableName]:string } = {
    "llpaFeature": "Feature",
    "llpaSubordinateFinancing": "Subordinate",
    "llpaCashoutRisk": "Cash Out",
    "llpaRisk": "Risk"
}

export interface LlpaImportExportItem {
    readonly llpaFeature:GQL.AdjTable;
    readonly llpaSubordinateFinancing:GQL.AdjTable;
    readonly llpaCashoutRisk:GQL.AdjTable;
    readonly llpaRisk:GQL.AdjTable;
}