import { query, gql, GQL } from "../../client";
import { RETURNING } from './returning';

const QUERY = gql`
    query {
        servicer(where: {}) {
            ${ RETURNING }
        }
    }
`;

interface QueryInput {
    readonly where:GQL.WhereString;
}

interface QueryOutput {
    readonly servicer:GQL.Servicer[];
}

export const fetchAllServicers = async ():Promise<GQL.Servicer[]|undefined> => {
    const result = await query<QueryInput, QueryOutput>(QUERY, { 
        where: {} // this means, ALL OF THEM
    })
    return result.data.servicer;
}