import React from 'react';
import { DeriveMathSrcMap, ModelItem, ImportModel, SrcMap } from 'market-dto';
import { MathHeader } from './math-header';
import { MathFieldSrc } from './math-field-src';

interface Props {
    readonly model:ImportModel;
    readonly field:ModelItem.AnyType;
    readonly derive:DeriveMathSrcMap;
    readonly sourceMap:SrcMap[];
    readonly n:number;
}
export const SrcMapDeriveMath = ({
    model,
    field,
    derive,
    sourceMap,
    n
}:Props) => {

    const { rightSrcId, leftSrcId, op, leftVal, rightVal, val } = derive;
    const leftField = model.getField(leftSrcId);
    const rightField = model.getField(rightSrcId);

    
    

    
    return (
        <div>
            <MathHeader
                model={model}
                derive={derive}
                field={field}
                n={n}
            />
            <div style={{ paddingLeft:'12px'}}>
                <MathFieldSrc
                    model={model}
                    side="left"
                    sourceMap={sourceMap}
                    srcField={leftField}
                    srcVal={leftVal}
                />
                <MathFieldSrc
                    model={model}
                    side="right"
                    sourceMap={sourceMap}
                    srcField={rightField}
                    srcVal={rightVal}
                />
            </div>
        </div>
    )
}