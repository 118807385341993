import React, { useEffect, useState } from 'react';
import { Flex, FlexVCenter, Box, Button, Icon, GradientText, Text, CssGridBox, BgColor, SpacingSize } from 'mui';
import { proposal, format } from 'services';
import { GQL, labels } from 'market-dto';
import { GenericSummaryBar, GenericSummaryGroup } from 'components';

interface Props {
    readonly trade:GQL.Trade;
    readonly hitInfo:proposal.HitInfo;
    readonly onClose?:() => void;
}
export const TradeSummary = ({ hitInfo, trade, onClose }:Props) => {

    const summary = hitInfo.proposalDetails;

    const loans = trade.loans ?? [];
    const successfulLoans = loans.filter(x => x.created);

    const groups:GenericSummaryGroup[] = [
        {
            items: [
                { top: summary.sheetId, bottom: labels.bySheetSummary('sheetId') },
                { top: summary.sellerName, bottom: labels.bySheetSummary('sellerName') },
            ]
        },
        {
            items: [
                { top: trade.cleanRoomTrade?.name, bottom: 'Trade Name' },
                { top: trade.cleanRoomTrade?.tradeId, bottom: 'Trade ID' },
                { top: successfulLoans.length, bottom: 'Loans' }
            ]
        }
    ]

    return (
        <GenericSummaryBar
            groups={groups}
            renderBtnArea={(
                <FlexVCenter justifyContent="flex-end" flex="1">
                    <Icon name="close" fg="faded" fgHover="link" onClick={onClose} cursor="pointer" mr="1" />
                </FlexVCenter>
            )}
        />
    )
}