import * as api from 'api';
import { GQL } from 'market-dto';
import { Observable, of, from } from 'rxjs';
import { isNotNullOrUndefined } from '../rx-utils';
import { map, switchMap, tap, publishLast, shareReplay } from 'rxjs/operators';
import { JobStatus, addJob, actLog, updateLatestPollResult, updateJobParam, genJobId } from '../activity-monitor';
import { reloadIfCurrent } from '../sheets';
import { getFannieJobName } from './get-fannie-job-name';
import { checkQueuedPricingJobDone } from '../pricing';

const POLLING_TIMEOUT_SECONDS = 60 * 10;

// const apiRunFanniePricing = api.toastOnErr(api.runFanniePricing, "Unable to run Fannie pricing at this time");
const apiRunFanniePricing2 = api.toastOnErr(api.queueFanniePricing, "Unable to run Fannie pricing at this time");


// export const runFanniePricingDeferred = (sheetId:string, costOfFunds:number, sellerMargin:number) => {
//     const jobId = getFannieJobId(sheetId);
//     addDeferredJob({
//         jobId,
//         onWait: () => actLog("PRICING", "QUEUED", sheetId, 'Fannie pricing'),
//         kickOff: () => {
//             addJob({
//                 jobType: "fannie-pricing",
//                 label: sheetId,
//                 jobId,
//                 checkStatus: createJobObservable(jobId, sheetId, costOfFunds, sellerMargin),
//                 timeoutSeconds: POLLING_TIMEOUT_SECONDS,
//                 onComplete: () => reloadIfCurrent(sheetId)
//             })
//         }
//     })
// }

export const enqueueFanniePricing = async (sheetId:string, costOfFunds:number, sellerMargin:number) => {
    // If you don't wanna wait for interval to grab the invocationId, call this.
    const jobName = getFannieJobName(sheetId);
    const jobId = genJobId("fannie-" + sheetId);
    const batchId = await apiRunFanniePricing2(sheetId, costOfFunds, sellerMargin);
    actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Fannie pricing ' + String(batchId));
    addJob({
        jobId,
        jobName,
        label: sheetId,
        serverBatchId:batchId,
        checkStatus: checkQueuedPricingJobDone('fannie', jobId, sheetId, batchId),
        timeoutSeconds: POLLING_TIMEOUT_SECONDS,
        onComplete: () => reloadIfCurrent(sheetId)
    })
}

// export const runFanniePricing = async (sheetId:string, costOfFunds:number, sellerMargin:number) => {
//     // If you don't wanna wait for interval to grab the invocationId, call this.
//     const jobId = getFannieJobId(sheetId);
//     const invocationId = await apiRunFanniePricing(sheetId, costOfFunds, sellerMargin);
//     actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Fannie pricing ' + String(invocationId));
//     addJob({
//         jobType: "fannie-pricing",
//         label: sheetId,
//         jobId,
//         checkStatus: checkIfDone(jobId, sheetId, invocationId),
//         timeoutSeconds: POLLING_TIMEOUT_SECONDS,
//         onComplete: () => reloadIfCurrent(sheetId)
//     })
// }

// const createJobObservable = (jobId:string, sheetId:string, costOfFunds:number, sellerMargin:number):Observable<JobStatus> => {
//     return requestInvocationId(sheetId, costOfFunds, sellerMargin).pipe(
//         switchMap(invocationId => checkIfDone(jobId, sheetId, invocationId))
//     )
// }

// const requestInvocationId = (sheetId:string, costOfFunds:number, sellerMargin:number):Observable<string|undefined> => {
//     return from(apiRunFanniePricing(sheetId, costOfFunds, sellerMargin)).pipe(
//         tap(invocationId => {
//             actLog("PRICING", "ACTIVITY_STARTED", sheetId, 'Fannie pricing ' + String(invocationId));
//             console.log('You will only see this once. Here is the invocation id:', invocationId);
//         }),
//         shareReplay(1) // <-- read below.
//         // This shareReplay(1) ensures that once we have recieved the invocationId,
//         // we do NOT re-request the invocationId (or we'd be generating endless invocation ids!)
//         // From here on out, it's as if the stream begins, on subsequent polling intervals, AFTER the shareReplay
//     )  
// }

// const checkIfDone2 = (jobId:string, sheetId:string, batchId?:string):Observable<JobStatus> => {
//     return of(batchId).pipe(
//         switchMap(batchId => {
//             if (!batchId) {
//                 actLog("PRICING", "ERROR", sheetId, 'Fannie pricing failed to get batch id');
//                 return of("FAILURE" as JobStatus);
//             }
//             updateJobParam(jobId, batchId);
//             return from(api.checkBatchStatus(batchId)).pipe(
//                 utils.isNotNullOrUndefined(),
//                 tap(x => {
//                     updateLatestPollResult(jobId, x);
//                 }),
//                 map((batchStatus:GQL.BatchStatus):JobStatus => {
//                     const total = batchStatus.summary.count ?? 0;
//                     const failed = batchStatus.summary.failed ?? 0;
//                     const completed = batchStatus.summary.completed ?? 0;
//                     if (failed >= total) {
//                         // Every single one failed.
//                         actLog("PRICING", "ERROR", sheetId, 'Fannie pricing ' + batchId);
//                         return 'FAILURE';
//                     }
//                     if (completed + failed >= total) {
//                         // Success, even if a few failed.
//                         actLog("PRICING", "ACTIVITY_ENDED", sheetId, 'Fannie pricing ' + batchId);
//                         return 'SUCCESS';
//                     }
//                     return 'BUSY';
//                 })
//             )
//         })
//     )
// }


const checkIfDone = (jobId:string, sheetId:string, invocationId?:string):Observable<JobStatus> => {
    return of(invocationId).pipe(
        switchMap(invocationId => {
            if (!invocationId) {
                actLog("PRICING", "ERROR", sheetId, 'Fannie pricing failed to get invocation id');
                return of("FAILURE" as JobStatus);
            }
            updateJobParam(jobId, invocationId);
            return from(api.fetchAgencyPricingStatus(invocationId)).pipe(
                isNotNullOrUndefined(),
                tap((statusAndLogs:api.PricingStatusAndLogs) => {
                    // console.log('HERE WE ARE (status and logs)', statusAndLogs)
                    updateLatestPollResult(jobId, statusAndLogs);
                }),
                map((statusAndLogs:api.PricingStatusAndLogs):JobStatus => {
                    switch (statusAndLogs.execution.status) {
                        // This may seem strange, but, what server tells us simply happens to look a lot like
                        // activity monitor's job statuses.
                        case 'DONE': {
                            actLog("PRICING", "ACTIVITY_ENDED", sheetId, 'Fannie pricing ' + invocationId);
                            return 'SUCCESS';
                        }
                        case 'FAIL': {
                            // console.log('this is server response', JSON.stringify(statusAndLogs, null, 4));
                            const reason = getLastLogLine(statusAndLogs);
                            actLog("PRICING", "ERROR", sheetId, reason ? reason : 'Fannie pricing ' + invocationId);
                            return 'FAILURE';
                        }
                        case 'QUEUE': {
                            return 'BUSY';
                        }
                        case 'START': {
                            return 'BUSY';
                        }
                    }
                    actLog("PRICING", "ERROR", sheetId, 'Unhandled Fannie polling result');
                    return 'FAILURE';
                })
            )
        })
    )
}

const getLastLogLine = (statusAndLogs:api.PricingStatusAndLogs):string => {
    if (statusAndLogs.logs.length > 0) {
        if (statusAndLogs.logs[0].lines && statusAndLogs.logs[0].lines.length > 0) {
            return statusAndLogs.logs[0].lines[0];
        }
    }
    return '';
}

// const getAmIDoneObservable = (jobId:string, sheetId:string, invocationId:string):Observable<JobStatus> => {
//     // How do we know when we're done with fannie pricing? This tells us. It'll get called repeatedly.
//     return of(invocationId).pipe(
//         switchMap(x => api.fetchAgencyPricingStatus(x)),
//         utils.isNotNullOrUndefined(),
//         tap((statusAndLogs:api.PricingStatusAndLogs) => {
//             // console.log('HERE WE ARE (status and logs)', statusAndLogs)
//             updateLatestPollResult(jobId, statusAndLogs);
//         }),
//         map((statusAndLogs:api.PricingStatusAndLogs):JobStatus => {
//             switch (statusAndLogs.execution.status) {
//                 // This may seem strange, but, what server tells us simply happens to look a lot like
//                 // activity monitor's job statuses.
//                 case 'DONE': {
//                     actLog("PRICING", "ACTIVITY_ENDED", sheetId, 'Fannie pricing');
//                     return 'SUCCESS';
//                 }
//                 case 'FAIL': {
//                     // console.log('this is server response', JSON.stringify(statusAndLogs, null, 4));
//                     const reason = getLastLogLine(statusAndLogs);
//                     actLog("PRICING", "ERROR", sheetId, reason ? reason : 'Fannie pricing');
//                     return 'FAILURE';
//                 }
//                 case 'QUEUE': {
//                     return 'BUSY';
//                 }
//                 case 'START': {
//                     return 'BUSY';
//                 }
//             }
//             actLog("PRICING", "ERROR", sheetId, 'Unhandled Fannie polling result');
//             return 'FAILURE';
//         })
//     )
// }
