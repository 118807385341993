import * as api from 'api';
import { GQL } from 'market-dto';
import { isNotNullOrUndefined } from '../../rx-utils';
import { Observable, of, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { JobStatus, actLog, updateLatestPollResult } from '../../activity-monitor';

const logsByBatchId:{[x:string]:any[]} = {};

export const checkFlowRunJobDone = (batchId:string, jobId:string, jobName:string, sheetId:string):Observable<JobStatus> => {
    // jobId always exists. runId may not, however.
    return of(batchId).pipe(
        switchMap(batchId => {
            const logs = logsByBatchId[batchId] ?? [];
            return from(api.checkBatchStatus(batchId, logs.length)).pipe(
                isNotNullOrUndefined(),
                tap(x => {
                    console.log('x', x);
                    const withOldLogs = {
                        ...x,
                        logs: [...logs, ...(x.logs ?? [])]
                    }
                    updateLatestPollResult(jobId, withOldLogs);
                }),
                map((batchStatusDetails:GQL.BatchStatus):JobStatus => {
                    const { batchStatus } = batchStatusDetails;
                    if (batchStatus === "COMPLETED") {
                        actLog("FLOW_RUN", "ACTIVITY_ENDED", sheetId, jobName + ' flow ' + batchId);
                        return 'SUCCESS';                        
                    } else if (batchStatus === "FAILED") {
                        actLog("FLOW_RUN", "ERROR", sheetId, jobName + ' flow ' + batchId);
                        return 'FAILURE';
                    }
                    return "BUSY";
                })
            )
        })
    )
}
