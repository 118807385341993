import { HitLoan, OPTIONAL_FIELDS, DEFAULT_OPT_FIELDS } from './types';
import * as storage from '../storage';

// Optional fields in local storage.

const OPT_FIELD_STORAGE_KEY = 'HIT_LOAN_OPT_FIELDS';

export const getOptFieldsFromStorage = ():Array<keyof HitLoan> => {
    const optHitFields = storage.get(OPT_FIELD_STORAGE_KEY);
    if (!Array.isArray(optHitFields)) return DEFAULT_OPT_FIELDS;
    if (optHitFields.some(x => !OPTIONAL_FIELDS.includes(x))) return DEFAULT_OPT_FIELDS;
    return optHitFields as Array<keyof HitLoan>;
}

export const setOptFieldsStorage = (fields:Array<keyof HitLoan>) => storage.set(OPT_FIELD_STORAGE_KEY, fields);